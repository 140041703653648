import * as React from 'react';

function SvgLavanderia(props) {
  return (
    <svg viewBox='0 0 47 33' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#lavanderia_svg__clip0_2448_23028)'>
        <path
          d='M45.25.05a1.25 1.25 0 00-.94.17 1 1 0 00-.46.68l-1.77 6.88a7.33 7.33 0 01-2.93-.76 10.12 10.12 0 00-4.46-1 9 9 0 00-4.48 1 6.83 6.83 0 01-3.47.77 6.809 6.809 0 01-3.45-.77 10.12 10.12 0 00-4.46-1 9 9 0 00-4.48 1 6.861 6.861 0 01-3.47.77 6.81 6.81 0 01-3.45-.77 9.15 9.15 0 00-3.77-1L2.36.92a1.14 1.14 0 00-.54-.75 1.09 1.09 0 00-.86-.1 1.191 1.191 0 00-.78.5 1.14 1.14 0 00-.15.87l7.69 30.64a1.11 1.11 0 001 .86h28.42a1.211 1.211 0 001.14-.85L46.1 1.45a1.142 1.142 0 00-.85-1.4zm-3.82 10l-5.2 20.63H9.86L4.22 8.42a6.8 6.8 0 012.14.68 10.122 10.122 0 004.47 1 8.89 8.89 0 004.47-1 6.83 6.83 0 013.47-.77 6.829 6.829 0 013.45.77 10.122 10.122 0 004.47 1 8.85 8.85 0 004.47-1 6.861 6.861 0 013.47-.77 6.83 6.83 0 013.45.77c1.053.499 2.187.804 3.35.9v.05z'
          fill='#C4C4C4'
        />
      </g>
      <defs>
        <clipPath id='lavanderia_svg__clip0_2448_23028'>
          <path fill='#fff' d='M0 0h46.15v32.92H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgLavanderia;
