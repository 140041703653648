import { makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { useState } from 'react';
import KitchenLayoutCard from '../Card/KitchenLayoutCard';
import OptionsTabs from '../Tabs/OptionsTabs';
import OptionTab from '../Tabs/OptionTab';
import OptionTabPanel from '../Tabs/OptionTabPanel';

const useStyles = makeStyles((theme) => ({
  kitchenCardSelectorRoot: {
    height: '100%',
    backgroundColor: '#EEEEEE',
  },
}));

const KitchenCardSelector = ({ options, onClick, selectedOption, planimetryImages }) => {
  const classes = useStyles();
  const selectedOptionIndex = options.findIndex((option) => _.isEqual(option.ids, selectedOption));

  const [currentTab, setValue] = useState(selectedOptionIndex > -1 ? selectedOptionIndex : 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.kitchenCardSelectorRoot}>
      <OptionsTabs
        value={currentTab}
        aria-label='tabs'
        variant={'scrollable'}
        scrollButtons='auto'
        onChange={handleChange}
      >
        {options &&
          options.map((option, i) => (
            <OptionTab
              key={i}
              label={option.displayName}
              value={i}
              isActive={_.isEqual(option.ids, selectedOption)}
            />
          ))}
      </OptionsTabs>
      {options &&
        options.map((option, i) => (
          <OptionTabPanel value={currentTab} index={i} key={i}>
            <KitchenLayoutCard
              {...option}
              onClick={() => onClick(option.ids)}
              isSelected={_.isEqual(option.ids, selectedOption)}
              planimetryImages={planimetryImages}
            />
          </OptionTabPanel>
        ))}
    </div>
  );
};

export default KitchenCardSelector;
