import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import PDFMerger from 'pdf-merger-js/browser';
// import PriceDescriptionsPage from './pdfPages/PriceDescriptionPage';
import PriceDescriptionsPage from './pdfPages/PriceDescriptionPageNew';
import PriceInfoPage from './pdfPages/PriceInfoPage';

import RobotoRegular from '../../assets/fonts/Roboto-Regular.ttf';
import RobotoBold from '../../assets/fonts/Roboto-Bold.ttf';
import MillerDisplay from '../../assets/fonts/MillerDisplayItalic.otf';
import CoverPage from './pdfPages/CoverPage';
import SingleStepPage from './pdfPages/SingleStepPage';
import _ from 'lodash';
import SignPage from './pdfPages/SignPage';

Font.register({
  family: 'Roboto',
  fonts: [{ src: RobotoRegular }, { src: RobotoBold, fontWeight: 700 }],
});
Font.register({
  family: 'Miller Display',
  src: MillerDisplay,
});

export const commonStyles = StyleSheet.create({
  page: {
    padding: '1.27cm',
    fontSize: '11pt',
    fontFamily: 'Roboto',
  },
});

const styles = StyleSheet.create({
  page: {
    padding: '1.27cm',
    fontSize: '11pt',
    fontFamily: 'Roboto',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  logoContainer: {
    height: '5%',
    width: '100%',
  },
  logo: {
    height: '100%',
    width: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  titleContainer: {
    height: '10%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  titleContainerPose: {
    height: '10%',
    width: '100%',
  },
  titlePlanimetry: {
    fontSize: '18px',
  },
  configurationName: {
    fontFamily: 'Miller Display',
    fontSize: '24px',
  },
  bold: {
    fontWeight: 700
  },
  bodyContainer: {
    height: '80%',
    width: '100%',
  },
  title: {
    fontSize: '18pt',
  },
  subtitle: {
    fontSize: '14pt',
    marginBottom: '7pt',
  },
  planimetryPageBody: {
    flexDirection: 'row',
    height: '90%',
  },
  planimetry: {
    flexGrow: 1,
    height: '100%',
    position: 'relative',
  },
  legend: {
    width: '100px',
  },
  electricLegend: {
    width: '150px',
  },
  img: { objectFit: 'contain', height: '100%', width: '100%' },
  disclaimer: {
    position: 'absolute',
    bottom: '1.27cm',
    left: '1.27cm',
  },
  imagePageImg: { objectFit: 'contain', height: '90%', width: '100%' },
  kitchenFirstPart: {
    marginTop: '1cm',
    height: '30%',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  kitchenFirstPartImg: {
    height: '100%',
    width: '80%',
    objectFit: 'contain',
  },
  kitchenFirstPartLegend: { height: '100%', width: '20%', objectFit: 'contain' },
  kitchenSecondPart: {
    height: '60%',
    objectFit: 'contain',
  },
  footer: {
    height: '5%',
    width: '100%',
    display: 'flex',
    flexDirection:'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  flexRow: {
    display: 'flex',
    flexDirection:'row'
  },
  flexColumn: {
    display: 'flex',
    flexDirection:'column'
  },
  borderLeftDivider: {
    borderLeft: '1px solid #000',
    paddingLeft: '8px',
    marginLeft: '8px'
  },
  infoPlan: {
    fontSize: '8px',
  }
});

export const ImagePage = ({ title, imgSrc }) => {
  return (
    <Page size='A4' style={commonStyles.page} orientation='landscape'>
      <Text style={styles.title}>{title}</Text>
      <Image style={styles.imagePageImg} src={imgSrc} />
    </Page>
  );
};
export const PlanimetryPage = ({
  title,
  planimetrySrc,
  showKitchenLegend,
  showElectricLegend,
  showPoseLegend,
  apartmentType,
  building,
  floor,
  quadrant,
}) => {
  return (
    <Page size='A4' style={styles.page} orientation='portrait'>
      <View style={styles.logoContainer}>
        <Image style={styles.logo} src={`${process.env.PUBLIC_URL}/assets/images/logo.png`} />
      </View>
      <View style={styles.titleContainerPose}>
        <Text style={styles.titlePlanimetry}>{title}</Text>
      </View>
      <View style={styles.bodyContainer}>
        <View style={styles.planimetryPageBody}>
          <View style={styles.planimetry}>
            {planimetrySrc && <Image style={styles.img} src={planimetrySrc} />}
          </View>
          <View style={showElectricLegend ? styles.electricLegend : styles.legend}>
            {showKitchenLegend && (
              <Image 
                style={styles.img} 
                src={`${process.env.PUBLIC_URL}/assets/images/legenda.png`} 
              />
            )}
            {showElectricLegend && (
              <Image 
                style={styles.img} 
                src={`${process.env.PUBLIC_URL}/legenda-elettrico.png`} 
              />
            )}
            {showPoseLegend && (
              <Image
                style={styles.img}
                src={`${process.env.PUBLIC_URL}/assets/images/legenda-pose.png`}
              />
            )}
          </View>
        </View>
        <View style={styles.infoPlan}>
          <Text>Le informazioni desumibili e le misure presenti nel documento sono indicative e soggette alle tolleranze previste dalla legge e/o a variazioni dovute ad esigenze tecniche di cantiere.</Text>
          <Text>Oltre ai prodotti personalizzabili, saranno previsti all’interno dell’appartamento gli elementi tecnici necessari al funzionamento degli impianti quali: </Text>
          <Text>quadro elettrico, cassette di derivazione, collettori, botole di ispezione fancoil, griglie di mandata e ripresa dell’aria, termostati.</Text>
          <Text>Inoltre, gli impianti saranno soggetti a valutazione da parte della direzione lavori e potrebbero subire variazioni.</Text>
        </View>
      </View>
      <View style={styles.footer}>
        <View style={styles.flexColumn}>
          <Text style={styles.bold}>{apartmentType}</Text>
        </View>
        <View style={styles.flexColumn}>
          <View style={styles.flexRow}>
            <Text>Progetto: </Text>
            <Text style={styles.bold}>{building}</Text>
          </View>
          <View style={styles.flexRow}>
            <Text>Piano: </Text>
            <Text style={styles.bold}>{floor}</Text>
            <Text style={styles.borderLeftDivider}>Quadrante: </Text>
            <Text style={styles.bold}>{quadrant}</Text>
          </View>
        </View>
      </View>
    </Page>
  );
};

export const KitchenViewPage = ({ title, axoImg, planImg }) => {
  return (
    <Page size='A4' style={commonStyles.page}>
      <Text style={styles.title}>{title}</Text>
      <View style={styles.kitchenFirstPart}>
        <Image style={styles.kitchenFirstPartImg} src={planImg} />
        <Image
          style={styles.kitchenFirstPartLegend}
          src={`${process.env.PUBLIC_URL}/assets/images/legenda.png`}
        />
      </View>
      <Image style={styles.kitchenSecondPart} src={axoImg} />
      <View style={styles.infoPlan}>
        <Text>Le informazioni desumibili e le misure presenti nel documento sono indicative e soggette alle tolleranze previste dalla legge e/o a variazioni dovute ad esigenze tecniche di cantiere.</Text>
        <Text>Oltre ai prodotti personalizzabili, saranno previsti all’interno dell’appartamento gli elementi tecnici necessari al funzionamento degli impianti quali: </Text>
        <Text>quadro elettrico, cassette di derivazione, collettori, botole di ispezione fancoil, griglie di mandata e ripresa dell’aria, termostati.</Text>
        <Text>Inoltre, gli impianti saranno soggetti a valutazione da parte della direzione lavori e potrebbero subire variazioni.</Text>
      </View>
    </Page>
  );
};

export const ConfigurationResumePDF = ({
  quotedPlanimetrySrc,
  posedPlanimetrySrc,
  electricPlanimetrySrc,
  answers,
  answersForPrices,
  apartment,
  configurationName,
  bagniRooms,
  building
}) => {
  const noSmartHomeAnswer = answers?.filter((answer) => {
    return (![
      'SmartHome_SmartHome', 
      'MOOD_MOOD', 
      'PORTEARREDO_PROFILO', 
      'PORTEARREDO_VETRO',
      'PAVIMENTOLIVINGECAMERE_FINITURA',
      'PAVIMENTOLIVINGECAMERE_TONALITA',
      'RIVESTIMENTI_TONALITA',
      'RIVESTIMENTI_FINITURA',
      'SANITARI_MODELLO',
      'DOCCIA_MODELLO',
      'VASCA_MODELLO',
      'RUBINETTERIA_MODELLO',
      'PAVIMENTOCUCINA_RELAZIONELIVINGCUCINA',
      'PAVIMENTOCUCINA_FINITURA',
      'PAVIMENTOCUCINA_TONALITA',
      'RIVESTIMENTI_SCHEMARIVESTIMENTO',
      'ACCESSORIDOCCIA_MODELLO',
    ].includes(answer.key) && !answer.name.toLowerCase().includes('nessun'))
  });

  // tutto questo mi serve per varie ragioni, doppiare alcune risposte per avere due cose:
  // 1. questione pavimento cucina
  // 2. questione carta da parati che devo avere anche il foglio con il layout
  // 3. questione rivestimenti bagni devo avere sia la pagina della parete che del pavimento
  let pavimentoSameCucina = -1

  pavimentoSameCucina = answers?.findIndex((answer) => {
    return [
      'PAVIMENTOCUCINA_RELAZIONELIVINGCUCINA'
    ].includes(answer.key)
  })

  const cartaDaParatiAnswer = _.cloneDeep(noSmartHomeAnswer?.filter((answer) => {
    return [
      'CARTAPARATI_CARTAPARATI',
    ].includes(answer.key)
  }));

  const pavimentoAnswer = _.cloneDeep(answers?.find((answer) => {
    return [
      'PAVIMENTOLIVINGECAMERE_FORMATO',
    ].includes(answer.key)
  }));

  if(pavimentoSameCucina !== -1 && answers?.[pavimentoSameCucina]?.isCucinaSame) {
    answers[pavimentoSameCucina].products = pavimentoAnswer.products;
    noSmartHomeAnswer.push(answers[pavimentoSameCucina]);
  }

  const rivestimenti_bagni = _.cloneDeep(answers?.filter((answer) => {
    return [
      'RIVESTIMENTI_FORMATO'
    ].includes(answer.key)
  }));

  rivestimenti_bagni?.map((rivestimento) => {
    rivestimento.key = 'RIVESTIMENTI_PAVIMENTO';
    return rivestimento;
  });

  const ids_rivestimenti_bagni = rivestimenti_bagni?.map((answer) => {
    return noSmartHomeAnswer?.findIndex((noSmartAnswer) => {
      return noSmartAnswer.key === 'RIVESTIMENTI_FORMATO' && noSmartAnswer.roomId === answer.roomId
    })
  });

  let newAnswerArray = noSmartHomeAnswer;
  
  rivestimenti_bagni?.length > 0 ? ids_rivestimenti_bagni?.forEach((indexRiv, index) => {
    newAnswerArray = [
      ...newAnswerArray?.slice(0, indexRiv + index),
      rivestimenti_bagni?.[index],
      ...newAnswerArray?.slice(indexRiv + index)
    ]
  }) : newAnswerArray = noSmartHomeAnswer;

  cartaDaParatiAnswer?.map(cartaParati => {
    cartaParati.key = 'CARTAPARATI_PLANIMETRIA';
    return cartaParati;
  });

  const ids_cartaparati = cartaDaParatiAnswer?.map((answer) => {
    return newAnswerArray?.findIndex((noSmartAnswer) => {
      return noSmartAnswer.key === 'CARTAPARATI_CARTAPARATI' && noSmartAnswer.roomId === answer.roomId
    })
  });

  let newAnswerArray2 = newAnswerArray;

  cartaDaParatiAnswer?.length > 0 ? ids_cartaparati?.forEach((indexCar, index) => {
    newAnswerArray2 = [
      ...newAnswerArray2?.slice(0, indexCar + index),
      cartaDaParatiAnswer?.[index],
      ...newAnswerArray2?.slice(indexCar + index)
    ]
  }) : newAnswerArray2 = newAnswerArray;
  // fine logica sdoppiamento

  const smartHomeAnswer = answers?.filter(
    (answer) =>
      answer.key === 'SmartHome_SmartHome' &&
      answer.description?.toLowerCase().includes('citofono'),
  );

  let defaultElectricalSystemInserted = false;
  let insertDefaultElectricalSystem = false;
  return (
    <Document>
      <CoverPage
        apartmentCode={apartment?.codiceAppartamento}
        building={building}
        stair={apartment?.scala?.value}
        quadrant={apartment?.quadrante}
        floor={apartment?.piano}
        apartmentType={apartment?.apartment.value}
        configurationName={configurationName}
      />
      <PriceDescriptionsPage 
        answers={answersForPrices} 
        bagniRooms={bagniRooms}
        apartmentType={apartment?.apartment.value} 
        building={building}
        floor={apartment?.piano} 
        quadrant={apartment?.quadrante} 
      />
      <SignPage
        apartmentType={apartment?.apartment.value}
        building={building}
        floor={apartment?.piano}
        quadrant={apartment?.quadrante}
      />
      {quotedPlanimetrySrc && (
        <PlanimetryPage
          showKitchenLegend
          title={'Distribuzione Interna'}
          planimetrySrc={quotedPlanimetrySrc}
          apartmentType={apartment?.apartment.value}
          building={building}
          floor={apartment?.piano}
          quadrant={apartment?.quadrante}
        />
      )}
      {newAnswerArray2?.map((answer, i) => {
        insertDefaultElectricalSystem = false;

        if (answer.phase.key === 'Tecnologia' && !defaultElectricalSystemInserted) {
          defaultElectricalSystemInserted = true;
          insertDefaultElectricalSystem = true;
        }

        const electricPage = insertDefaultElectricalSystem && electricPlanimetrySrc;
        return (
          <>
            { electricPage && (
              <SingleStepPage
                apartmentCode={apartment?.codiceAppartamento}
                building={building}
                stair={apartment?.scala?.value}
                quadrant={apartment?.quadrante}
                floor={apartment?.piano}
                apartmentType={apartment?.apartment?.value}
                configurationName={configurationName}
                answer={answer}
                electricPlanimetrySrc={electricPlanimetrySrc}
              />
            )}
            <SingleStepPage
              apartmentCode={apartment?.codiceAppartamento}
              building={building}
              stair={apartment?.scala?.value}
              quadrant={apartment?.quadrante}
              floor={apartment?.piano}
              apartmentType={apartment?.apartment?.value}
              configurationName={configurationName}
              answer={answer}
              bagniRooms={bagniRooms}
            />
          </>
        )
        
        /*
        console.log('risposte: ', answer);
        
        insertDefaultElectricalSystem = false;

        if (answer.phase.key === 'Tecnologia' && !defaultElectricalSystemInserted) {
          defaultElectricalSystemInserted = true;
          insertDefaultElectricalSystem = true;
        }

        let title =
          answer.step.name === answer.substep.name
            ? answer.substep.name
            : `${answer.step.name}: ${answer.substep.name}`;

        switch (answer.key) {
          /*
          case 'SmartHome_SmartHome':
            console.log('ci sono 1');
            const images = [];
            answer.products.forEach((product) => {
              if (product.imageFileUrl) {
                images.push(product.imageFileUrl);
              }
            });

            return (
              <>
                {images.map((el, j) => (
                  <ImagePage key={j} title={title} imgSrc={el} />
                ))}
              </>
            );
            */
           /*
          case 'Cucina_Cucina':
            console.log('ci sono 2');
            const axoImg = answer.fileLabels.filter((el) => el.isAxo)[0]?.url;
            const planImg = answer.fileLabels.filter((el) => el.isPlan)[0]?.url;

            return (
              <KitchenViewPage
                key={i}
                title={title}
                planimetrySrc={answer.planimetrySrc}
                axoImg={axoImg}
                planImg={planImg}
              />
            );

          default:
            console.log('ci sono 3');
            if (answer.key === 'Porte_TipologiaPorte') {
              title += ` - ${
                answer.description?.toLowerCase().includes('battente') ? 'Battenti' : 'Scorrevoli'
              }`;
            }
            return (
              <>
                {insertDefaultElectricalSystem && electricPlanimetrySrc && (
                  <PlanimetryPage
                    showElectricLegend
                    title={'Impianto elettrico capitolato'}
                    planimetrySrc={electricPlanimetrySrc}
                  />
                )}
                <PlanimetryPage key={i} title={title} planimetrySrc={answer.planimetrySrc} />
              </>
            );
        }
        */
      })}
      {electricPlanimetrySrc && !defaultElectricalSystemInserted && (
        <PlanimetryPage
          showElectricLegend
          title={'Impianto elettrico capitolato'}
          planimetrySrc={electricPlanimetrySrc}
          apartmentType={apartment?.apartment.value}
          building={building}
          floor={apartment?.piano}
          quadrant={apartment?.quadrante}
        />
      )}
      {smartHomeAnswer?.length > 0 &&
        smartHomeAnswer.map((answer, i) => {
          const images = [];
          answer.products.forEach((product) => {
            if (product.imageFileUrl) {
              images.push(product.imageFileUrl);
            }
          });

          return (
            <>
              {images.map((el, j) => (
                <ImagePage key={j} imgSrc={el} />
              ))}
            </>
          );
        })
      }
      {posedPlanimetrySrc && (
        <PlanimetryPage
          showPoseLegend
          title={'POSE PAVIMENTAZIONE'}
          planimetrySrc={posedPlanimetrySrc}
          apartmentType={apartment?.apartment.value}
          building={building}
          floor={apartment?.piano}
          quadrant={apartment?.quadrante}
        />
      )}
      <PriceInfoPage
        apartmentType={apartment?.apartment.value}
        building={building}
        floor={apartment?.piano}
        quadrant={apartment?.quadrante}
      />
    </Document>
  );
};

export const mergePDFs = async (pdfs) => {
  const merger = new PDFMerger();

  for (const pdf of pdfs) {
    await merger.add(pdf);
  }

  const mergedPdf = await merger.saveAsBlob();
  return URL.createObjectURL(mergedPdf);
};

export const mergePDFs2 = async (pdfs) => {
  const merger = new PDFMerger();
  for (const pdf of pdfs) {
    await merger.add(pdf);
  }
  const mergedPdf = await merger.saveAsBuffer();
  return mergedPdf;
};
