import { Grid, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import ConfiguratorIntro from '../../../components/configurator/ConfiguratorIntro';
import {
  LuciApplique,
  LuciParete,
  LuciComodino as LuciComodinoIcon,
} from '../../../components/Icons';
import ConfiguratorSelectionLayout from '../../../components/Layout/ConfiguratorSelectionLayout.jsx';
import ConfiguratorTwoColumnsPlanimetryLayout from '../../../layouts/ConfiguratorTwoColumnsPlanimetryLayout';
import { getOption } from '../../../services/optionApi';
import { useStore } from '../../../store/storeUtils';
import _ from 'lodash';
import { getLayoutLayers, prepareOptionData } from '../../../utils/optionsUtils';
import { useParams } from 'react-router';
import { useRef } from 'react';
import { handleSave, singleChoiceSelection } from '../../../utils/selectionUtils';
import DefaultChoiceImgText from '../layout/currentConfiguration/DefaultChoiceImgText';
import { getLayoutImagesByConfigurationId } from '../../../services/layoutApi';
import clsx from 'clsx';
import { resumeAnswerSimple } from '../../../utils/configuratorUtils';
import OptionCard, { StandardOptionCardBody } from '../../../components/Card/OptionCard';
import { getAllImpiantoElettricoLayers } from '../../../utils/layoutUtils';

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: '#EEEEEE',
    width: '100%',
  },
  list: { marginTop: '25px', padding: '0 22px 22px' },
  alertMessage: { marginBottom: '5px' },
  scrollableContent: {
    backgroundColor: '#EEEEEE',
  },
}));

const FirstColumn = ({ defaultOption, onClick, selectedOptions, planimetryImages }) => {
  return (
    defaultOption && (
      <DefaultChoiceImgText
        message="Per ogni comodino è prevista una presa elettrica per lampade da tavolo. Guarda la planimetria dell'impianto elettrico"
        icon={<LuciComodinoIcon />}
        isSelected={_.isEqual(defaultOption.ids, selectedOptions)}
        onClick={() => {
          onClick(defaultOption.ids);
        }}
        planimetryImages={planimetryImages}
        legendImg={`${process.env.PUBLIC_URL}/legenda-elettrico.png`}
      />
    )
  );
};

const SecondColumn = ({
  options,
  selectedOptions,
  onClick,
  cardOnMouseEnter,
  cardOnMouseLeave,
}) => {
  const classes = useStyles();

  const icons = [];
  if (options.some((el) => el.options[0]?.additionalProperties?.aSoffitto)) {
    icons.push({ icon: <LuciParete /> });
  }
  if (options.some((el) => el.options[0]?.additionalProperties?.aParete)) {
    icons.push({ icon: <LuciApplique /> });
  }

  return (
    <ConfiguratorSelectionLayout scrollableContentClasses={clsx(classes.scrollableContent)}>
      <div className={classes.content}>
        <ConfiguratorIntro
          icon={icons}
          title={"Quali punti luce vuoi aggiungere per l'illuminazione del comodino?"}
          badgeText='La tua scelta verrà proposta anche per le altre camere in uno step successivo.'
        />
        <Grid container className={classes.list} spacing={2} justifyContent='center'>
          {options.map((el, i) => (
            <Grid item key={i} xs={6} lg={4}>
              <OptionCard
                {...el.options[0]}
                onClick={() => onClick(el.ids)}
                isSelected={_.difference(el.ids, selectedOptions).length === 0}
                onMouseEnter={cardOnMouseEnter}
                onMouseLeave={cardOnMouseLeave}
                infoPriceMessage={
                  el.options[0]?.additionalProperties?.aParete
                    ? 'Il prezzo comprende la realizzazione di n°1 punto luce e n°1 interruttore per comodino. La fornitura delle lampade è esclusa.'
                    : 'Il prezzo comprende la realizzazione di n°1 punto luce e n°1 interruttore per comodino. La fornitura delle lampade è esclusa.'
                }
              >
                <StandardOptionCardBody
                  {...el.options[0]}
                  message={'Aggiungo punti luce per'}
                  hidePositions
                  name={
                    el.options[0]?.additionalProperties?.aParete
                      ? 'lampade a parete'
                      : 'lampade a soffitto'
                  }
                />
              </OptionCard>
            </Grid>
          ))}
        </Grid>
      </div>
    </ConfiguratorSelectionLayout>
  );
};

const LuciComodinoPrincipale = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const [defaultOption, setDefaultOption] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [images, setImages] = useState([]);
  const [impiantoElettricoImages, setImpiantoElettricoImages] = useState([]);

  const substepIdRef = useRef();
  const oldAnswersRef = useRef();

  const store = useStore();
  const params = useParams();

  useEffect(() => {
    store.setUserCanGoNext(false);
    prepareOptions();
  }, []);

  const prepareOptions = async () => {
    const substepId = store.getSubstepIdByFrontendId(store.currentSubstep);

    let optionData = await getOption(
      store.currentConfiguration?.layout?.id,
      substepId,
      params.configuration,
    );
    substepIdRef.current = optionData?.data.substep.id;

    optionData = prepareOptionData(optionData);

    // Imposto i layer da far vedere
    const layoutImagesResponse = await getLayoutImagesByConfigurationId(params.configuration);
    const layoutImages = layoutImagesResponse.data;

    const optionLayers = getLayoutLayers(optionData);
    const newImages = layoutImages.concat(optionLayers);
    setImages(newImages);

    setOptions(
      optionData.filter((el) => !el.isDefault)?.sort((a, b) => a?.position - b?.position), // Ordinamento opzioni
    );
    const defaultOption = optionData.find((el) => el.isDefault);
    setDefaultOption(defaultOption);

    const impiantoElettricoLayers = await getAllImpiantoElettricoLayers(
      store.currentConfiguration?.layout?.tags,
      params.configuration,
    );

    setImpiantoElettricoImages([
      ...layoutImages.map((el) => el.fileUrl),
      ...impiantoElettricoLayers,
    ]);

    resumeAnswerSimple(store, oldAnswersRef, substepIdRef, setSelectedOptions, setIsLoading);
  };

  useEffect(() => {
    const save = async () => {
      await handleSave(selectedOptions, params.configuration, substepIdRef.current, store);
    };

    if (!_.isEqual(oldAnswersRef.current, selectedOptions)) {
      store.setSubstepSave(save);
    } else {
      store.setSubstepSave(null);
    }
  }, [selectedOptions]);

  const handleUserSelection = (selection) => {
    singleChoiceSelection(selection, selectedOptions, setSelectedOptions, store);
  };

  return (
    <ConfiguratorTwoColumnsPlanimetryLayout
      planimetryImages={images}
      firstColumn={
        <FirstColumn
          defaultOption={defaultOption}
          selectedOptions={selectedOptions}
          onClick={handleUserSelection}
          planimetryImages={impiantoElettricoImages}
        />
      }
      secondColumn={
        <SecondColumn
          options={options}
          selectedOptions={selectedOptions}
          onClick={handleUserSelection}
        />
      }
      isLoading={isLoading}
      firstRowTitle={'IL TUO'}
      secondRowTitle={'APPARTAMENTO'}
      secondColumnFirstRowTitle={'SOLUZIONI'}
      secondColumnSecondRowTitle={'AGGIUNTIVE'}
      noOption={options?.length === 0}
      noOptionIcon={[
        { icon: <LuciParete />, description: 'Lampada a soffitto' },
        { icon: <LuciApplique />, description: 'Lampada applique' },
      ]}
      noOptionText={'Non ci sono  opzioni per il comodino.'}
    />
  );
};

export default LuciComodinoPrincipale;
