import { Grid, makeStyles } from '@material-ui/core';
import IconButton from '../Buttons/IconButton';
import ImageZoomButton from '../Buttons/ImageZoomButton';
import { Gallery, Plus, Ruler, Alert } from '../Icons';
import ImageZoomSlider from '../ImageZoomSlider';
import { CardCurrencyFormatter } from '../../utils/CurrencyFormatter';
import { useState, useRef } from 'react';
import { Popover } from '@material-ui/core';
import InfoPopup, { PhaseInfoContent } from '../InfoPopup';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '22px 20px',
    backgroundColor: theme.palette.color.grey1,
    height: '100%',
    textAlign: 'center',
  },
  list: {
    margin: '25px 0',
  },
  title: {
    fontSize: 15,
    textTransform: 'uppercase',
    marginBottom: '5px',
    '& span': {
      fontWeight: 600,
    },
  },
  description: {
    fontFamily: 'miller',
    fontStyle: 'italic',
    fontSize: 15,
    marginBottom: '20px',
  },
  galleryButtonContainer: {
    marginBottom: '20px',
    width: '100%',
    '& .gallery-button': {
      paddingLeft: '10px',
      fontWeight: 500,
    },
  },
  sliderContainer: {
    width: '100%',
    minHeight: 220,
    position: 'relative',
    marginBottom: '23px',
  },
  priceContainer: {
    width: '100%',
    textAlign: 'left',
    paddingLeft: '10px',
    paddingBottom: '10px',

  },
  price: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: '14px',
    fontSize: '12px',
    lineHeight: '15px',
    color: theme.palette.primary.main,
    '& svg': {
      marginRight: '8px',
      // cursor: 'pointer',
    },
    '& span': {
      paddingTop: '3px',
      whiteSpace: 'nowrap',
      color: '#A15117',
    },
  },
}));

const SelectionCard = ({ 
  name, 
  summary,
  images, 
  onGalleryButtonClick, 
  noZoomImages, 
  noSlider,
  onClick,
  isSelected,
  moodOptions,
  dataSheetFileUrl,
  title,
  priceDiff,
  infoPriceMessage,
  caption
}) => {
  const classes = useStyles();
  const imagesTreated = images?.filter((elg) => (elg?.imageType?.key === 'C' || elg?.imageType?.key === 'V')).map((image) => {
    return image.label;
  })

  const moodName = title?.split('Mood')[1];
  const optionName = title;
  const dataSheet = dataSheetFileUrl;

  const [showInfo, setShowInfo] = useState(false);
  const infoRef = useRef();

  const infoData = {
    phase: 'VALORE AGGIUNTO',
    firstRow: 'Puoi monitorare le tue aggiunte nella sezione Riepilogo',
    secondRow: infoPriceMessage ? (
      <span dangerouslySetInnerHTML={{ __html: infoPriceMessage }} />
    ) : (
      "Selezionandolo aumenterà il prestigio del tuo preventivo. Avrai sempre la possibilità di monitorare le tue aggiunte con valore superiore nella sezione 'Recap scelte'"
    ),
    thirdRow: 'I prezzi sono da intendersi iva esclusa.',
  };

  return (
    <Grid container className={classes.root} direction='column'>
      <Grid item className={classes.sliderContainer}>
        <ImageZoomSlider hideZoom images={imagesTreated} noZoomImages={noZoomImages} noSlider={noSlider}/>
      </Grid>
      {moodOptions && (<Grid item xs='auto' className={classes.title}>
        Mood <span>{moodName}</span>
      </Grid>)}
      {!moodOptions && (<Grid item xs='auto' className={classes.title}>
        <span>{optionName}</span>
      </Grid>)}
      {moodOptions && caption && (
        <Grid item xs className={classes.description}>
          {caption}
        </Grid>
      )}
      {!moodOptions && (
        <Grid item xs className={classes.description}>
          {summary}
        </Grid>
      )}
      
      {priceDiff > 0 && (
      <Grid item xs className={classes.priceContainer}>
        <div className={classes.price}  ref={infoRef}>
          <Alert
            color='currentColor'
            width='15px'
            height='15px'
            onClick={() => {
              setShowInfo(true);
            }}
            style={{cursor: 'pointer'}}
          />
          <span>+ {CardCurrencyFormatter(priceDiff)}</span>
        </div>
        <Popover
          open={showInfo}
          anchorEl={infoRef.current}
          onClose={() => setShowInfo(false)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          PaperProps={{ classes: { root: classes.popover } }}
        >
          <InfoPopup
            body={<PhaseInfoContent {...infoData} />}
            isPopover
            handleClose={() => setShowInfo(false)}
          />
        </Popover>
      </Grid>
      )}
      <Grid item xs='auto' className={classes.galleryButtonContainer}>
        {onGalleryButtonClick && (
        <ImageZoomButton
          onClick={onGalleryButtonClick}
          className='gallery-button'
          imageSrc={Image}
          label={'Guarda la gallery'}
          icon={<Gallery color='#878787' width='19px' height='15px' />}
        />
        )}
        {dataSheet && (
        <ImageZoomButton
          imageSrc={[dataSheetFileUrl]}
          label={'Scheda Tecnica'}
          isPdf
          icon={<Ruler color='#878787' width='15px' height='15px' />}
          className='chiusura-arredo-button no-border-bottom'
        />
        )}
      </Grid>
      <Grid item>
        <IconButton
          text={
            isSelected
              ? 'Selezionato'
              : 'Seleziona'
          }
          icon={<Plus color='currentColor' width='10px' height='10px' />}
          onClick={isSelected ? null : onClick}
          isSelected={isSelected}
        />
      </Grid>
    </Grid>
  );
};

export default SelectionCard;
