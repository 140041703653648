import { Grid, makeStyles } from '@material-ui/core';
import TabStep from './TabStep';

const useStyles = makeStyles((theme) => ({
  tabsContainer: {
    paddingRight: '20px',
    '& .tab-root': {
      maxWidth: '20%',
    },
  },
  configuratorBody: {
    height: 'calc(100% - 60px)',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100% - 50px)',
    },
  },
}));

const TabBar = ({ phases }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.tabsContainer}>
      {phases?.map((el, i) => (
        <Grid item xs key={i} className='tab-root'>
          <TabStep {...el} phaseKey={el.key} />
        </Grid>
      ))}
    </Grid>
  );
};

export default TabBar;
