const faqConfigurations = [
  {
    tabLabel: 'Generali',
    subtitle: 'STAI VISUALIZZANDO',
    boldSubtitle: 'TUTTE LE DOMANDE DELLA SEZIONE GENERALI',
    questions: [
      {
        title: 'Cosa prevede il Capitolato di progetto?',
        answer:
          'Il capitolato di progetto definisce la scelta a tua disposizione, senza costi aggiuntivi, di marche, modelli e formati previsti per tutte le finiture, incluse le porte, gli infissi, i sanitari, la rubinetteria, i pavimenti ed i rivestimenti. Il nostro capitolato include anche il mobile da bagno per il lavabo e lo specchio.',
      },
      {
        title: 'Posso scegliere fornitori diversi da quelli proposti nel configuratore?',
        answer:
          'Per la tua casa abbiamo selezionato materiali e forniture delle primarie aziende presenti sul mercato, in modo da permetterti di avere un ampio ventaglio di scelta. Per questioni contrattuali e logistiche non è possibile avvalersi di fornitori diversi da quelli proposti.',
      },
      {
        title: "Quale Aliquota IVA viene applicata all'importo di extra capitolato?",
        answer:
          "L’IVA dell’extra capitolato corrisponde all'IVA che viene applicata alla compravendita, che è del 4% se si usufruisce delle agevolazioni prima casa oppure del 10%",
      },
      {
        title: "Quando è previsto il pagamento dell'extra capitolato?",
        answer:
          "Il pagamento dell'extra capitolato è previsto secondo quanto stabilito dall’articolo 4 del preliminare che disciplina le varianti.",
      },
    ],
    footerMessage:
      'Consulta anche le sezioni delle domande su Utilizzo configuratore, Layout e Tecnologia.',
  },
  {
    tabLabel: 'Utilizzo configuratore',
    subtitle: 'STAI VISUALIZZANDO',
    boldSubtitle: 'TUTTE LE DOMANDE DELLA SEZIONE UTILIZZO CONFIGURATORE',
    questions: [
      {
        title: 'Come posso iniziare una nuova configurazione del mio appartamento? ',
        answer:
          "All'interno della tua pagina MyAi accedi al Configuratore e nella sezione I Tuoi Appartamenti clicca su Visualizza > Aggiungi nuova configurazione > Start! Inizia la personalizzazione. Da qui potrai partire con la prima fase e proseguire con le successive non appena saranno disponibili. Per vedere tutte le funzionalità del configuratore, guarda il video tutorial.",
      },
      {
        title: "Posso scaricare la planimetria quotata dell'appartamento?",
        answer:
          'Sì, puoi trovare sia la planimetria quotata del tuo appartamento che delle alternative di layout, ove previste.  Clicca sulla planimetria quotata per ingrandirla e utilizza il bottone in basso a destra per scaricarne il contenuto. Per vedere tutte le funzionalità del configuratore, guarda il video tutorial.',
      },
      {
        title: 'Posso scaricare le foto e le schede tecniche dei prodotti?',
        answer:
          'Certo, puoi trovare sia le foto che le schede tecniche dei prodotti, ove previste.  Clicca su Foto o Scheda tecnica per visualizzarle e utilizza il bottone in basso a destra per scaricarne il contenuto. Per vedere tutte le funzionalità del configuratore guarda il video tutorial.',
      },
      {
        title: 'Dove trovo le specifiche tecniche sugli articoli disponibili?',
        answer:
          'In ogni schermata trovi la scheda tecnica dei vari articoli e le foto, ove previsto. Inoltre, cliccando sulla i di fianco al prezzo troverai ulteriori dettagli.',
      },
      {
        title: 'Dove posso trovare il riepilogo delle scelte effettuate?',
        answer:
          'Cliccando il tasto Riepilogo, in alto a sinistra della schermata, puoi monitorare il riepilogo delle scelte effettuate e visionare il valore totale delle opzioni che hai selezionato se hai effettuato aggiunte in extra capitolato',
      },
      {
        title: 'Come posso salvare le mie configurazioni?',
        answer:
          'Per ogni step del configuratore le scelte effettuate si salvano automaticamente quando clicchi sul tasto verde Prosegui. In ogni caso, se vuoi salvare una configurazione in corso e continuarla in un secondo momento, puoi cliccare su Salva e torna alla Home in alto a sinistra.',
      },
      {
        title: 'Posso rinominare le mie configurazioni?',
        answer:
          'Sì, puoi rinominare le configurazioni nella sezione Home: clicca sul tasto Rinomina e poi su Salva per confermare.',
      },
      {
        title: 'Come posso modificare le mie scelte?',
        answer:
          "Per modificare la tua configurazione, spostati nella sottofase in cui è presente la scelta che desideri cambiare. Potrai selezionare un'opzione differente, aggiungere o sottrarre elementi. Ti ricordiamo che dovrai ripetere tutte le scelte effettuate negli step successivi.",
      },
      {
        title: 'Posso duplicare una configurazione?',
        answer:
          'Sì, puoi duplicare le configurazioni. Vai nella sezione Home: clicca sul tasto Duplica configurazione. Potrai creare fino a quattro configurazioni.',
      },
      {
        title: 'Quante configurazioni posso sviluppare per ogni appartamento?',
        answer:
          'Il numero massimo di configurazioni che possono essere sviluppate per ciascun appartamento è 4.',
      },
    ],
    footerMessage: 'Consulta anche le sezioni delle domande Generali, Layout e Tecnologia.',
  },
  {
    tabLabel: 'Layout',
    subtitle: 'STAI VISUALIZZANDO',
    boldSubtitle: 'TUTTE LE DOMANDE DELLA SEZIONE LAYOUT',
    questions: [
      {
        title: 'Posso scegliere la distribuzione interna degli spazi?',
        answer:
          'Le eventuali alternative di Layout dipendono dalla tipologia di appartamento. Nella sezione Layout la sottofase Distribuzione interna mostra le varianti disponibili per il taglio che hai acquistato.',
      },
      {
        title: 'Posso scegliere la distribuzione degli arredi?',
        answer:
          "Nella sezione  Layout > Distribuzione cucina potrai scegliere tra diverse alternative di composizione dei moduli della cucina. Non è prevista la possibilità di modificare la distribuzione degli arredi delle altre stanze, i quali sono posizionati nella planimetria a titolo rappresentativo ed ipotetico. Attenzione! La posizione di alcuni arredi è vincolata all'impianto elettrico ed idrico (ad esempio: il mobile bagno, il mobile tv e alcuni mobili della cucina).",
      },
      {
        title: 'Quando posso scegliere l’arredo del mio appartamento?',
        answer:
          'Durante la fase dedicata, successiva alla personalizzazione dell’appartamento, potrai scegliere tra la selezione di arredi proposti dal nostro partner per la zona giorno e la zona notte',
      },
      {
        title: "Cosa cambia tra un layout e l'altro?",
        answer:
          'I layout si differenziano tra loro per la distribuzione interna degli spazi, ma il perimetro esterno resta lo stesso.  Cliccando sul pulsante Scopri le qualità di questo layout potrai leggere le caratteristiche specifiche di ognuno.',
      },
      {
        title: 'Quali modifiche posso apporre alle porte?',
        answer:
          "I nostri architetti hanno selezionato dei pacchetti di varianti tra cui potrai scegliere. E' possibile, ad esempio, sostituire le porte previste dal capitolato con porte senza coprifilo, scorrevoli o con extra altezza nella sezione Layout > Porte. Per vedere tutte le funzionalità del configuratore guarda il video tutorial.",
      },
      {
        title: 'Posso modificare puntualmente le porte?',
        answer:
          'Sì, puoi modificare puntualmente la tipologia, lo stile e l’altezza delle porte. Negli step LAYOUT: PORTE: TIPOLOGIA, STILE, ALTEZZA, FINITURA potrai modificare puntualmente le porte del tuo appartamento. Le alternative opzionabili sono quelle compatibili con la tua configurazione; le opzioni presenti sono quelle compatibili dal punto di vista architettonico e tecnico per il tuo appartamento.',
      },
      {
        title: 'Posso scegliere diversi layout cucina?',
        answer:
          "Sì, nella sezione Layout > Distribuzione cucina, qualora ne fossero previsti più di uno. In questa sottofase scegli la posizione degli attacchi idrici di lavello e lavastoviglie e dell'impianto elettrico per piano cottura, cappa, lavastoviglie, frigorifero, forno etc. Potrai personalizzare il modello, i moduli e le finiture  nella fase dedicata agli arredi che è successiva alla personalizzazione del tuo appartamento.",
      },
      {
        title: 'Posso selezionare un layout con la cucina chiusa?',
        answer:
          "Sì, nella sezione Layout > Distribuzione cucina saranno proposte diverse alternative di distribuzione degli ambienti. In corrispondenza delle tipologie di stanze comprese nel layout selezionato verifica che sia presente la voce 'Angolo cottura' o 'Cucina'. Nel primo caso non sono previsti sistemi di chiusura, mentre nel secondo è prevista una porta classica o d'arredo.",
      },
      {
        title:
          'Posso personalizzare i moduli della cucina ad esempio scegliendo la tipologia di cassetto o di anta?',
        answer:
          "Nella fase Distribuzione Cucina definisci la posizione degli attacchi idrici di lavello e lavastoviglie e l'impianto elettrico per piano cottura, cappa, lavastoviglie, frigorifero, forno etc. Potrai personalizzare il modello, i moduli e le finiture  nella fase dedicata agli arredi che è successiva alla personalizzazione del tuo appartamento",
      },
      {
        title: 'Posso visionare le dimensioni degli elettrodomestici?',
        answer:
          'Certo, nella sezione Layout > Distribuzione cucina > Scopri le qualità di questa cucina puoi trovare la dimensione degli elettrodomestici. Inoltre, troverai le dimensioni del lavabo e il numero di prese.',
      },
      {
        title: 'Posso selezionare una finitura opaca per le finestre del bagno?',
        answer:
          "Tutte le stanze sono dotate di finestre o porte finestre con vetri trasparenti. Per i bagni c'è la possibilità di sostituirli con vetri opachi nella sezione Layout > Finestre.",
      },
      {
        title: 'Posso inserire una cassaforte?',
        answer:
          'Certamente, nella sezione Layout > Cassaforte potrai inserire la cassaforte e scegliere dove posizionarla tra le opzioni disponibili. Potrai leggere le caratteristiche del prodotto all’interno della scheda tecnica.',
      },
    ],
    footerMessage:
      'Consulta anche le sezioni delle domande Generali, Utilizzo Configuratore e Tecnologia.',
  },
  {
    tabLabel: 'Tecnologia',
    subtitle: 'STAI VISUALIZZANDO',
    boldSubtitle: 'TUTTE LE DOMANDE DELLA SEZIONE TECNOLOGIA',
    questions: [
      {
        title: 'Posso scegliere le lampade con il configuratore?',
        answer:
          'Le lampade sono rappresentate nelle immagini e nelle schede tecniche solo a titolo esemplificativo. La fornitura delle lampade è esclusa anche dal Buono Arredi a meno di luci integrate (come ad esempio per armadi, mobile TV e luci sottopensile in cucina).',
      },
      {
        title: "Posso modificare l'impianto elettrico?",
        answer:
          'Puoi aggiungere delle prese elettriche o dei punti luce nella la sezione Tecnologia del configuratore.',
      },
      {
        title: 'Posso aggiungere puntualmente dei punti luce o delle prese?',
        answer:
          'Sì, i nostri architetti hanno selezionato dei pacchetti di varianti tra cui potrai scegliere. Il ventaglio completo di opzioni è disponibile nella sezione Tecnologia > Luci e Tecnologia > Prese. Per vedere tutte le funzionalità del configuratore guarda il video tutorial.',
      },
      {
        title: 'Posso aggiungere punti luce nella loggia?',
        answer:
          'La loggia sarà illuminata secondo un progetto illuminotecnico degli esterni. I punti luce sono già in numero congruo rispetto alla dimensione della loggia e non è prevista quindi la possibilità di aggiungerne.',
      },
      {
        title: 'Posso aggiungere delle gole luminose?',
        answer:
          'Certo, i nostri architetti hanno selezionato dei pacchetti di varianti tra cui potrai scegliere. Il ventaglio completo di opzioni è disponibile nella sezione Tecnologia > Luci > Gole luminose. Per vedere tutte le funzionalità del configuratore guarda il video tutorial.',
      },
      {
        title: 'Posso aggiungere delle luci da armadio?',
        answer:
          'Certamente, i nostri architetti hanno selezionato dei pacchetti di varianti tra cui potrai scegliere. Il ventaglio completo di opzioni è disponibile nella sezione Tecnologia > Luci > Illuminazione armadi. Per vedere tutte le funzionalità del configuratore guarda il video tutorial.',
      },
      {
        title: 'Posso aggiungere delle prese usb?',
        answer:
          'Sì, i nostri architetti hanno selezionato dei pacchetti di varianti tra cui potrai scegliere. Il ventaglio completo di opzioni è disponibile nella sezione Tecnologia > Prese > Usb. Per vedere tutte le funzionalità del configuratore guarda il video tutorial.',
      },
      {
        title: 'Posso aggiungere delle prese tv?',
        answer:
          'Certo, i nostri architetti hanno selezionato dei pacchetti di varianti tra cui potrai scegliere. Il ventaglio completo di opzioni è disponibile nella sezione Tecnologia > Prese > TV. Per vedere tutte le funzionalità del configuratore guarda il video tutorial.',
      },
      {
        title: 'Sono previste delle opzioni di tecnologia smart per il mio appartamento?',
        answer:
          'Sì, i nostri architetti hanno selezionato dei pacchetti di varianti tra cui potrai scegliere. Il ventaglio completo di opzioni è disponibile nella sezione Tecnologia > Smart Home. Per vedere tutte le funzionalità del configuratore guarda il video tutorial.',
      },
      {
        title: 'Posso sostituire il videocitofono?',
        answer:
          'Certamente, nella sezione Tecnologia > Smart Home è possibile modificare il videocitofono previsto nella tua configurazione di partenza con un modello connesso. Scopri le specifiche del prodotto in extra capitolato cliccando sulla scheda tecnica.',
      },
      {
        title: 'Posso aggiungere delle luci sottopensili?',
        answer:
          'Gli appartamenti sono realizzati con una predisposizione elettrica che consente la successiva installazione di luci sottopensili in cucina. Le predisposizioni fornite dal capitolato comprendono già uno o più punti luce a seconda della distribuzione della cucina.e non è quindi prevista la possibilità di aggiungerne.',
      },
      {
        title: "Posso aggiungere l'antifurto?",
        answer:
          "Gli appartamenti sono realizzati con una predisposizione elettrica che consente la successiva installazione di un impianto d'allarme. La predisposizione inclusa nel capitolato comprende : tastierino d'allarme all'ingresso, centralina dell'allarme, sirena esterna, contatto magnetico per il portoncino d'ingresso all'appartamento.",
      },
    ],
    footerMessage:
      'Consulta anche le sezioni delle domande Generali, Utilizzo Configuratore e Layout.',
  },
  {
    tabLabel: 'Giorno e Notte',
    subtitle: 'STAI VISUALIZZANDO',
    boldSubtitle: 'TUTTE LE DOMANDE DELLA SEZIONE GIORNO E NOTTE',
    questions: [
      {
        title: 'Che cosa si intende per mood?',
        answer:
          'Il mood identifica uno stile per la tua casa. Per ogni mood sono stati selezionati varie alternative di prodotti per la finitura dei pavimenti e dei rivestimenti dei bagni e sono stati combinati diversi accostamenti di colore per rendere lo stile della tua casa unico e affine in tutta la configurazione.',
      },
      {
        title: "Quanti mood posso scegliere?",
        answer:
          'Si può definire un unico mood per tutta la casa. Potrai comunque fare diverse prove di stile in configurazioni diverse, prima di scegliere la configurazione finale. ',
      },
      {
        title: 'Che differenza c’è tra la scelta “FINITURA” e “TONALITA” dei pavimenti? ',
        answer:
          'Nello step PAVIMENTO GIORNO E CAMERE: FINITURA potrai scegliere tra varie alternative: parquet a listoni, parquet maxiplancia, parquet a spina italiana, gres effetto legno, gres effetto marmo, gres effetto cemento e tanti altri effetti. Nello step PAVIMENTO GIORNO E CAMERE: TONALITA’ potrai affinare la scelta della finitura selezionando un colore tra le diverse alternative previste per il tuo mood. ',
      },
      {
        title: 'Posso scegliere il formato dei materiali dei pavimenti e dei rivestimenti?',
        answer:
          'Sì, puoi scegliere il formato dei materiali dei pavimenti e dei rivestimenti. Vai allo step PAVIMENTO GIORNO E CAMERE: FORMATO e seleziona la dimensione per il tuo pavimento. La selezione dei formati è diversa per ogni modello di finitura, in accordo con le disponibilità del fornitore e con le altre scelte disponibili all’interno del mood. ',
      },
      {
        title: 'Posso scegliere il pavimento della cucina uguale a quello della zona living e notte?',
        answer:
          'Sì, puoi scegliere lo stesso pavimento per la zona living e notte anche per la cucina. La scelta è disponibile nello step CUCINA: RELAZIONE LIVING E CUCINA nel caso in cui la tua configurazione presenta il locale cucina in un ambiente separato con porta dal resto del living. Qualora la cucina è un angolo cottura aperto sul soggiorno, la scelta dello step PAVIMENTO GIORNO E CAMERE vale anche per questa zona.',
      },
      {
        title: 'Posso scegliere il pavimento per la zona cucina?',
        answer:
          'Sì, puoi scegliere il pavimento per la cucina nella sezione GIORNO E NOTTE: CUCINA. La scelta è disponibile nel caso in cui la tua configurazione presenta il locale cucina in un ambiente separato con porta dal resto del living.',
      },
      {
        title: 'Il colore delle porte è uguale per tutta la casa?',
        answer:
          'Sì, puoi scegliere il colore delle porte. Vai allo step GIORNO E NOTTE: PORTE: COLORE e seleziona un’opzione tra quelle proposte per il tuo mood. La selezione viene applicata a tutte le porte presenti nella configurazione, ad eccezione di eventuali porte d’arredo e porte grezze selezionate nella sezione LAYOUT.',
      },
      {
        title: 'Posso scegliere le maniglie delle porte?',
        answer:
          'Sì, puoi scegliere le maniglie delle porte. Vai allo step GIORNO E NOTTE: PORTE: MANIGLIE e seleziona un’opzione tra quello proposte per modello e finitura. La scelta verrà applicata a tutte le porte della tua configurazione, ad eccezione di eventuali porte d’arredo selezionate nella sezione LAYOUT.',
      },
      {
        title: 'Posso scegliere le finiture per le porte d’arredo? ',
        answer:
          'Sì, puoi scegliere le finiture delle porte d’arredo. Vai allo step GIORNO E NOTTE: PORTE D’ARREDO: PROFILO e seleziona un’opzione tra quelle proposte per la finitura del profilo. Vai allo step GIORNO E NOTTE: PORTE D’ARREDO: VETRO e seleziona un’opzione tra quelle proposte per la finitura del vetro. Le combinazioni di vetro e profilo sono state definite per essere coerenti con lo stile del mood.',
      },
      {
        title: 'Posso scegliere il colore delle placchette elettriche?',
        answer:
          'Sì, puoi scegliere il colore delle placchette elettriche. Vai allo step GIORNO E NOTTE: ALTRE DOTAZIONI e seleziona un’opzione tra quelle proposte per la finitura delle placchette elettriche. La scelta verrà applicata a tutte le placchette elettriche presenti nella tua casa.',
      },
    ],
    footerMessage:
      'Consulta anche le sezioni delle domande Generali, Utilizzo Configuratore, Layout e Tecnologia.',
  },
  {
    tabLabel: 'Bagno',
    subtitle: 'STAI VISUALIZZANDO',
    boldSubtitle: 'TUTTE LE DOMANDE DELLA SEZIONE BAGNO',
    questions: [
      {
        title: 'Cosa si intende per schema rivestimento?',
        answer:
          'Lo schema rivestimento identifica uno stile per il tuo bagno. Per ogni schema viene definita l’altezza dei rivestimenti da posare a parete. Accedi alla gallery immagini per visualizzare lo schema rivestimento nelle diverse varianti layout di un bagno tipologico (es. bagno con vasca o bagno con doccia).',
      },
      {
        title: "Posso scegliere il pavimento del bagno in continuità con quello della zona living e notte?",
        answer:
          'Sì, puoi scegliere lo stesso pavimento per la zona living e notte anche per il bagno. La scelta è disponibile nello step BAGNO: PAVIMENTO. Vai allo step e seleziona lo stesso prodotto che hai scelto nello step precedente PAVIMENTO GIORNO E CAMERE, trovi il riferimento e l’immagine di quest’ultimo nel riquadro in basso a sinistra della schermata.',
      },
      {
        title: 'Posso scegliere abbinamenti diversi da quelli proposti per le piastrelle del mio bagno?',
        answer:
          'Nello step BAGNO: PARETI sono proposti gli abbinamenti di colore selezionati e verificati per te dagli architetti. Per ogni mood sono stati studiati molteplici soluzioni di stile e tantissimi abbinamenti di tonalità per rendere unica la tua configurazione.',
      },
      {
        title: 'Cosa comprende la composizione del mobile bagno?',
        answer:
          'La composizione del mobile bagno comprende il mobile, eventuali pensili e colonnine, lavabo integrato o d’appoggio, specchiera completa di luce e allaccio elettrico e predisposizioni idriche per la rubinetteria. Scopri i dettagli selezionando la scheda tecnica del prodotto.',
      },
      {
        title: 'Posso scegliere i sanitari sospesi?',
        answer:
          'Sì, puoi scegliere modelli di sanitari sia sospesi che a terra. Vai allo step BAGNO: SANITARI: MODELLO e seleziona un’opzione tra quelle proposte per i sanitari, puoi filtrare le alternative utilizzando il bottone “sospeso” o “a terra”.',
      },
      {
        title: 'Posso scegliere il modello di piatto doccia?',
        answer:
          'Sì, puoi scegliere il piatto doccia. Vai allo step BAGNO: PIATTO DOCCIA: MODELLO e seleziona un’opzione tra quelle proposte per i piatti doccia. Potrai scegliere la finitura nello step successivo BAGNO: PIATTO DOCCIA: FINITURA.',
      },
      {
        title: 'Posso avere la rubinetteria a parete per il lavabo bagno?',
        answer:
          'Sì, puoi scegliere il modello di rubinetteria a parete per il lavabo del tuo bagno. Vai allo step BAGNO: RUBINETTERIA: MODELLO e seleziona un’opzione tra quelle proposte con il titolo “a parete”. Potrai scegliere la finitura nello step successivo BAGNO: RUBINETTERIA: FINITURA.',
      },
      {
        title: 'Posso aggiungere il box doccia?',
        answer:
          'Sì, puoi aggiungere il box doccia alla tua configurazione. Vai allo step BAGNO: BOX DOCCIA e seleziona un’opzione tra quelle proposte. La scelta del box doccia è facoltativa in quanto è un elemento aggiuntivo alla dotazione di capitolato.',
      },
      {
        title: 'Posso aggiungere alla vasca il soffione doccia?',
        answer:
          'Sì, puoi aggiungere alla vasca ad incasso o idromassaggio la rubinetteria doccia, nello step BAGNO: ACCESSORI VASCA: SOFFIONE. L’opzione di rubinetteria proposta è compatibile con il modello e la finitura scelti nello step precedente BAGNO: RUBINETTERIA.',
      },
      {
        title: 'Il mio bagno sarà dotato di scaldasalviette?',
        answer:
          'Sì, ogni locale bagno della tua configurazione è dotato di scaldasalviette elettrico, solitamente posizionato sopra il bidet. Vai allo step BAGNO: ALTRE DOTAZIONI e seleziona un’opzione tra quelle proposte. La scelta del modello e finitura di scaldasalviette è coordinata con la placca di scarico del wc.',
      },
    ],
    footerMessage:
      'Consulta anche le sezioni delle domande Generali, Utilizzo Configuratore, Layout, Tecnologia o Giorno e Notte.',
  },
];

export default faqConfigurations;
