import * as React from 'react';

function SvgLuciSmart(props) {
  return (
    <svg viewBox='0 0 94 95' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M52.275 12.14a1.13 1.13 0 00-1.15 1.16v2.92a1.15 1.15 0 001 1.17c13.73.57 24.8 12.3 25.34 26.84a1.13 1.13 0 001.1 1.12h2.76a1.15 1.15 0 001.1-1.22c-.59-17.36-13.81-31.37-30.2-32'
        fill='#4D4D4D'
      />
      <path
        d='M52.273 0a1.14 1.14 0 00-1.14 1.17v2.92a1.15 1.15 0 001.08 1.17c20 .59 36.2 17.74 36.76 39a1.15 1.15 0 001.1 1.14h2.76a1.141 1.141 0 001.1-1.2C93.323 20.08 74.963.63 52.273 0zM66.014 44.261a1.13 1.13 0 001.1 1.09h2.76a1.14 1.14 0 001.1-1.23c-.58-10.64-8.65-19.2-18.7-19.81a1.13 1.13 0 00-1.16 1.16v2.93a1.16 1.16 0 001 1.17c7.43.53 13.37 6.83 13.88 14.69M31.164 80.57h-16.07a1.279 1.279 0 100 2.55h16.07a1.28 1.28 0 100-2.55zm-8-57.9A22.58 22.58 0 00.274 45.46c0 5.82 2.52 11.68 7.7 17.92.32.38.67.78 1.07 1.23a20.28 20.28 0 014.77 13.19 1.28 1.28 0 002.56 0 22.62 22.62 0 00-5.42-14.9c-.39-.43-.71-.81-1-1.16-4.79-5.74-7.12-11.09-7.12-16.28a20.05 20.05 0 0120.3-20.23 20.052 20.052 0 0120.29 20.23c0 5.19-2.33 10.52-7.11 16.28-.3.35-.62.73-1 1.16a22.62 22.62 0 00-5.42 14.9 1.28 1.28 0 002.56 0 20.28 20.28 0 014.77-13.19c.4-.45.75-.85 1.07-1.23 5.18-6.24 7.7-12.1 7.7-17.92a22.58 22.58 0 00-22.86-22.79h.03zm7.97 65.64h-16.04a1.28 1.28 0 000 2.56h16.07a1.28 1.28 0 100-2.56h-.03zm.03-3.88h-16.07a1.29 1.29 0 000 2.57h16.07a1.29 1.29 0 000-2.57zM25.844 92h-5.43a1.28 1.28 0 000 2.56h5.43a1.28 1.28 0 100-2.56zm-8.19-38.27a.53.53 0 00.73 0 7.22 7.22 0 019.49 0 .53.53 0 00.73 0l.94-.94a.52.52 0 000-.77 9.64 9.64 0 00-12.8 0 .54.54 0 000 .77l.91.94zm3.13 2.17a.519.519 0 00-.06.8l2 2a.53.53 0 00.75 0l2-2a.52.52 0 00-.06-.8 4 4 0 00-4.69 0h.06zm-7.12-6.1a.53.53 0 00.73 0 12.79 12.79 0 0117.35 0 .53.53 0 00.73 0l1-.94a.54.54 0 000-.77 15.19 15.19 0 00-20.67 0 .54.54 0 000 .77l.86.94zm22-3.91a.52.52 0 00.74 0l1-.94a.55.55 0 000-.77 20.76 20.76 0 00-28.53 0 .55.55 0 000 .77l.95.94a.52.52 0 00.74 0 18.35 18.35 0 0125.17 0'
        fill='#4D4D4D'
      />
    </svg>
  );
}

export default SvgLuciSmart;
