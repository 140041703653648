import * as React from 'react';

function SvgBagnoIcon(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 103 78'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M51.9 78c28.167 0 51-2.686 51-6s-22.833-6-51-6c-28.166 0-51 2.686-51 6s22.834 6 51 6z'
        fill='#EEE'
      />
      <path
        d='M81.201 28.4h-.5v-3.8V6.3c0-.3 0-.7-.1-1-.5-3.4-3.8-5.7-7.2-5.2-1.8.3-3.4 1.3-4.3 2.8 0 0 0 .1-.1.1-2.7-.9-5.6.5-6.6 3.2 0 .1 0 .2.1.3l8.9 4.1c.1.1.3 0 .3-.1 1.4-2.3.8-5.2-1.3-6.8v-.1c1.6-2 4.4-2.6 6.6-1.2 1.3.9 2 2.3 2.1 3.8v22.1H29.801c-1.2.2-2 1.2-1.9 2.4.1 1.1 1 1.9 2.1 1.9h.2c-.3 5.1 2.1 10 6.2 12.9l-4.9 7.6c-.1.1 0 .3.1.3H35.001c.3 0 .6-.1.7-.4.9-1.4 1.8-2.8 2.8-4.3l1.1-1.7c1.6.7 3.4 1 5.1 1h26v5c0 .1.1.2.3.3h3.1c.1 0 .2-.1.3-.3v-1.7-4c0-.2 0-.2.2-.3 3.5-1.5 5.9-5 6-8.8.1-1.3.1-2.7.1-4v-2h.2c.3 0 .6 0 .8-.1 1-.2 1.8-1.2 1.7-2.2-.2-.6-1.1-1.5-2.2-1.5zm-8.8 23.6v-3.9l.3-.1v3.9h-.3v.1zm6.5-18v4.1c0 4.5-3.5 8.2-7.9 8.6H44.901c-2.2 0-4.4-.6-6.3-1.7-3.1-1.7-5.4-4.5-6.3-7.9-.4-1.4-.6-2.9-.5-4.4h47.1V34zm-40.8 12.6l-1.6 2.4c-.6.9-1.2 1.8-1.7 2.7-.2.3-.3.3-.5.3l3.6-5.5.2.1zm42.9-15.5h-50.7c-.7 0-.7-.3-.7-.5 0-.3.2-.5.5-.5h51.2c.3 0 .5.2.5.5s-.2.5-.5.5h-.3zm-10.2-22.5l-6.2-2.9c1.2-1.5 3.4-1.8 4.9-.6 1.1.9 1.6 2.2 1.3 3.5z'
        fill={props.color}
      />
      <path
        d='M65.7 9.899c-.1-.1-.3 0-.3.1l-1.4 2.7c-.1.1 0 .3.1.3l1 .5h.1c.1 0 .2-.1.2-.1l1.3-2.6v-.2c0-.1-.1-.1-.1-.1l-.9-.6zM61.001 18.8l-1.3 2.6v.2c0 .1.1.1.1.1l1 .5c.1.1.3 0 .3-.1l1.3-2.6c.1-.1 0-.3-.1-.3l-1-.5c-.1 0-.3 0-.3.1zM58.901 15.5s.1.1.2.1.2 0 .2-.1l1.7-2.4c0-.1.1-.1 0-.2 0-.1 0-.1-.1-.2l-.8-.6c-.1 0-.1-.1-.2 0-.1 0-.1 0-.2.1l-1.8 2.3c-.1.1-.1.3 0 .3l1 .7zM55.9 19.4s.1.1.2.1.1 0 .2-.1l1.8-2.3c0-.1.1-.1 0-.2 0-.1 0-.1-.1-.2l-.9-.7c-.1 0-.1-.1-.2 0-.1 0-.1 0-.2.1L55 18.4c-.1.1-.1.3 0 .3l.9.7zM61.902 11.6h.1c.1 0 .2-.1.3-.1l1.7-2.3c.1-.1.1-.3 0-.3l-.9-.7c-.1-.1-.2-.1-.2-.1-.1 0-.1 0-.2.1l-1.8 2.3c0 .1-.1.1 0 .2 0 .1 0 .1.1.2l.9.7zM67.5 15.9l-.7 2.9c0 .1.1.3.2.3l1.1.3h.2c.1 0 .1-.1.1-.2l.7-2.9c0-.1-.1-.3-.2-.3l-1.1-.2c-.1-.1-.3 0-.3.1zM66.401 20.7l-.7 2.9c0 .1.1.3.2.3l1.1.3h.1c.1 0 .2-.1.2-.2l.7-2.9v-.2c0-.1-.1-.1-.2-.1l-1.1-.2c-.1-.1-.3 0-.3.1zM69.2 14.6h.2c.1 0 .1-.1.1-.2l.6-2.9v-.2c0-.1-.1-.1-.2-.1l-1.1-.2c-.1 0-.3 0-.3.2l-.6 2.9c0 .1.1.3.2.3l1.1.2zM63.1 17.899c.1 0 .2-.1.2-.1l1.3-2.6v-.2c0-.1-.1-.1-.1-.1l-1-.5c-.1-.1-.3 0-.3.1l-1.4 2.6v.2c0 .1.1.1.1.1l1 .5h.2zM67.302 34.2h-15.3v1.9h15.3v-1.9zM75.702 34.299h-6.2v1.8h6.2v-1.8z'
        fill={props.color}
      />
    </svg>
  );
}

export default SvgBagnoIcon;
