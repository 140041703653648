import { makeStyles } from '@material-ui/core';
import { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    width: '100%',
  },
  carousel: {
    width: '100%',
    '& .CarouselItem': {
      '& > div': {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    '& button': {
      // backgroundColor: 'transparent !important',
      '&:hover': {
        opacity: '1 !important',
      },
    },
  },
  image: {
    height: '150px',
  },
  galleryImageFirst: {
    marginLeft: 'auto',
  },
  galleryImageLast: {
    marginRight:'auto',
  },
  galleryImage: {
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center center',
    opacity:'0.3',
    cursor: 'pointer'
  },
  galleryImageActive: {
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center center'
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
  },
  title: {
    fontSize: '15px',
    lineHeight: '18px',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  subtitle: {
    fontFamily: 'miller',
    fontStyle: 'italic',
    fontSize: '15px',
    lineHeight: '18px',
    textAlign: 'center',
  },
  bold: {
    fontWeight: 600,
  },
}));

const Slider = ({ title, subtitle, multipleImages, setProductLabels }) => {
  const [isActive, setIsActive] = useState(0)
  const classes = useStyles();

  useEffect(()=> {
    setIsActive(0);
    setProductLabels(0);
  }, [multipleImages])

  const styleText = (text, reverse = false) => {
    const firstPart = text.substr(0, text.indexOf(' '));
    const secondPart = text.substr(text.indexOf(' ') + 1);
    return (
      <div className={classes.title}>
        <span className={reverse ? classes.bold : ''}>{firstPart}</span>&nbsp;
        <span className={!reverse ? classes.bold : ''}>{secondPart}</span>
      </div>
    );
  };

  const items = multipleImages;

  return (
    <div className={classes.root}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          height: '50px',
          width: '100%',
          gap: '5px',
          overflowX: 'auto',
        }}
      >
        {items.map((item, i) => (
          <img
            key={i}
            src={item}
            alt=''
            className={
              [i===isActive ? classes.galleryImageActive : classes.galleryImage,
              i===0 ? classes.galleryImageFirst : '',
              i=== items.length -1 ? classes.galleryImageLast : '',
              ].join(' ')
            }
            onClick={()=> {
              setIsActive(i);
              setProductLabels(i);
            }}
          />
        ))}
      </div>
      <Carousel
        autoPlay={false}
        indicators={false}
        navButtonsAlwaysVisible={true}
        navButtonsAlwaysInvisible={items.length > 1 ? false : true}
        animation='slide'
        className={classes.carousel}
        next={ (next, active) => {
          setIsActive(next);
          setProductLabels(next);
         }}
        prev={ (prev, active) => {
          setIsActive(prev);
          setProductLabels(prev);
        }}
        index={isActive}
      >
        {items.map((item, i) => (
          <img
            key={i}
            src={item}
            alt=''
            className={classes.image}
          />
        ))}
      </Carousel>
      <div className={classes.textWrapper}>
        {title ? styleText(title) : ''}
        {title && subtitle ? <span className={classes.subtitle}>{subtitle}</span> : ''}
      </div>
    </div>
  );
};

export default Slider;
