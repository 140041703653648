import * as React from 'react';

function SvgDisimpegno(props) {
  return (
    <svg viewBox='0 0 42 43' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#disimpegno_svg__clip0_2448_22982)' fill='#C4C4C4'>
        <path d='M40.818 0h-23.07a.94.94 0 00-.94.94v40.67a.94.94 0 00.94.94h23.07a1 1 0 00.95-.94V.94a1 1 0 00-.95-.94zm-.94 40.67h-21.19V1.89h21.19v38.78z' />
        <path d='M36.07 22.86a1.59 1.59 0 001.471-1.69 1.59 1.59 0 00-3.17.22 1.6 1.6 0 001.7 1.47zM10.792 24c-1.43.62-2.15 2.1-2 4.07l-.32.39a22.249 22.249 0 00-.63-3.56c1-2.53.94-4.69 0-6.39a7.42 7.42 0 00-4.94-3.2.65.65 0 00-.63.28 6.92 6.92 0 00-.49 5.95 6.44 6.44 0 004.9 3.9c.27 1.09.457 2.2.56 3.32a4.71 4.71 0 00-2.67-2.83 5.859 5.859 0 00-4.09-.12.65.65 0 00-.39.65 5.22 5.22 0 003.22 4.24 5.138 5.138 0 001.9.4 3.58 3.58 0 001.5-.31 6.28 6.28 0 01.59 1.63.752.752 0 000 .16v.87h-4.3a.644.644 0 00-.48.22.62.62 0 00-.17.5l.75 7.23a.64.64 0 00.64.58h8.08a.64.64 0 00.65-.58l.74-7.23a.65.65 0 00-.64-.72h-4c.06-.85.08-1.66.07-2.42a5.32 5.32 0 011-1.95c.51.152 1.038.233 1.57.24a3.52 3.52 0 001.88-.5c1.13-.69 1.76-2.05 1.86-4a.65.65 0 00-.32-.59 4.363 4.363 0 00-3.84-.23zm.36 16.68h-6.91l-.61-5.93h8.13l-.61 5.93zm-8.23-19.57a5.81 5.81 0 01.13-4.41 5.87 5.87 0 013.58 2.47 5.07 5.07 0 01.46 3.53 11.13 11.13 0 00-1.57-2.86.64.64 0 00-.91-.08.642.642 0 00-.08.91 11.932 11.932 0 011.63 3.31 4.848 4.848 0 01-3.24-2.83v-.04zm.79 8.39a3.851 3.851 0 01-2.31-2.63 5.24 5.24 0 012.64.28 3.648 3.648 0 012 2.47 3.08 3.08 0 01-2.33-.12zm8.66-1.79a3 3 0 01-2.34.14 2.6 2.6 0 011.28-2.66 2.998 2.998 0 012.27 0 3.19 3.19 0 01-1.21 2.52z' />
      </g>
      <defs>
        <clipPath id='disimpegno_svg__clip0_2448_22982'>
          <path fill='#fff' d='M0 0h41.77v42.55H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgDisimpegno;
