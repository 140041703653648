import * as React from 'react';

function SvgPortaScorrevole(props) {
  return (
    <svg viewBox='0 0 45 77' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#porta-scorrevole_svg__clip0_2089:22623)'>
        <path d='M42.94 2.4H24.48v71.18h18.46V2.4z' fill='#EFEEEE' />
        <path
          d='M0 0v76.73h44.86V0H0zm1.91 74.82V1.9h41v.54H23.8v71.89h.78V3.22h18.37v71.6H1.91z'
          fill='#262626'
        />
        <path
          d='M28.09 36.16a1.8 1.8 0 00-1.265 3.066c.336.336.791.524 1.266.524a1.8 1.8 0 001.8-1.79 1.81 1.81 0 00-1.8-1.8zm0 2.82a1 1 0 110-2 1 1 0 010 2z'
          fill='#262626'
        />
      </g>
      <defs>
        <clipPath id='porta-scorrevole_svg__clip0_2089:22623'>
          <path fill='#fff' d='M0 0h44.85v76.73H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgPortaScorrevole;
