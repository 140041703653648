import * as React from 'react';

function SvgPreseDisimpegno(props) {
  return (
    <svg viewBox='0 0 76 49' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#prese-disimpegno_svg__clip0_2758_23627)' fill='#C4C4C4'>
        <path d='M18.689 1.88h21.2v8.67h1.88V.94c0-.52-.42-.94-.94-.94h-23.08c-.52 0-.94.42-.94.94v40.67c0 .52.42.94.94.94h10.09v-1.88h-9.15V1.88z' />
        <path d='M22.672 21.39c.06.87.82 1.52 1.69 1.46.87-.06 1.53-.82 1.46-1.69-.06-.87-.81-1.53-1.69-1.47-.87.07-1.52.82-1.46 1.7zM10.792 23.99c-1.42.63-2.15 2.11-2.04 4.08-.11.13-.21.26-.31.39-.14-1.36-.36-2.54-.63-3.56.94-2.54.93-4.69-.05-6.4-1.53-2.64-4.8-3.17-4.94-3.19-.25-.04-.5.07-.64.28-.07.1-1.67 2.54-.48 5.94.98 2.82 3.77 3.67 4.89 3.91.24.95.44 2.06.57 3.31-.37-.95-1.14-2.26-2.67-2.82-2.35-.86-4.03-.16-4.1-.13a.66.66 0 00-.39.65c0 .13.28 3.06 3.23 4.25.72.29 1.36.39 1.9.39.64 0 1.14-.14 1.5-.3.46.91.58 1.62.58 1.63 0 .06.03.11.06.16-.01.28-.03.58-.05.87h-4.3c-.19 0-.36.08-.49.21-.12.14-.18.32-.16.5l.74 7.23c.04.34.32.59.65.59h8.08c.33 0 .61-.25.65-.59l.74-7.23a.659.659 0 00-.16-.5.689.689 0 00-.49-.21h-3.96c.05-.85.08-1.66.07-2.43.17-.66.52-1.31 1.02-1.95.37.12.93.25 1.56.25s1.28-.12 1.89-.5c1.13-.7 1.75-2.06 1.86-4.04a.646.646 0 00-.32-.59c-.08-.04-1.83-1.06-3.81-.2zm-7.08 5.5c-1.55-.62-2.11-1.9-2.31-2.62.54-.1 1.47-.15 2.64.28 1.28.47 1.83 1.79 2.04 2.47-.41.17-1.21.34-2.37-.13zm3.38-6.8c-.74-1.85-1.49-2.78-1.56-2.86a.649.649 0 00-.91-.08c-.28.23-.32.64-.09.92.02.02.88 1.09 1.63 3.3-1.05-.34-2.63-1.12-3.23-2.87-.73-2.09-.2-3.71.13-4.41.83.22 2.66.87 3.58 2.47.55.97.7 2.15.45 3.53zm4.67 12.06l-.61 5.93h-6.9l-.61-5.93h8.12zm.62-7.04c-.79.49-1.81.29-2.35.13-.02-.91.16-2.16 1.28-2.65.94-.41 1.81-.21 2.27-.04-.13 1.28-.53 2.14-1.2 2.56zM53.35 27.4h-2.46v6.18h2.46V27.4zM45.948 27.4h-2.46v6.16h2.46V27.4z' />
        <path d='M60.75 20.91c0-.73-.29-1.42-.8-1.93a2.71 2.71 0 00-1.93-.81H38.83c-.73 0-1.42.29-1.94.81a2.7 2.7 0 00-.8 1.93V40.1a2.726 2.726 0 00.8 1.93c.22.22.47.39.74.52.37.18.78.28 1.2.28h19.19c.72 0 1.42-.29 1.93-.8s.8-1.2.8-1.93V20.91zm-4.13 15.07a9.799 9.799 0 01-4.43 3.64c-1.8.74-3.78.94-5.7.56a9.84 9.84 0 01-4.72-2.39 9.444 9.444 0 01-1.88-2.33c-.55-.93-.94-1.96-1.15-3.03-.38-1.92-.18-3.9.56-5.7.17-.41.37-.8.59-1.18a9.44 9.44 0 011.88-2.34c.36-.33.75-.63 1.17-.91a9.81 9.81 0 015.48-1.66c1.29 0 2.58.25 3.77.75 1.2.49 2.29 1.22 3.21 2.13.91.92 1.64 2.01 2.13 3.21.5 1.19.75 2.48.75 3.77 0 1.95-.57 3.86-1.66 5.48zM72.358 29.2h1.11c.14 0 .25-.11.25-.25v-4.74c0-.11 0-.22-.05-.33a.75.75 0 00-.18-.28.75.75 0 00-.28-.18c-.06-.02-.12-.04-.19-.04a.169.169 0 00-.1-.03c-.11-.01-.22 0-.33.05-.1.04-.2.1-.28.18a.75.75 0 00-.18.28c-.04.1-.05.22-.05.3v4.79c0 .14.11.25.25.25h.03zM66.082 29.17h1.05c.14 0 .25-.11.25-.25v-4.74a.75.75 0 00-.22-.6.75.75 0 00-.6-.22c-.08 0-.15.01-.22.04-.01 0-.03.01-.04.01a1 1 0 00-.26.18.77.77 0 00-.17.27c-.04.11-.05.21-.04.3v4.76c0 .14.11.25.25.25zM75.78 31.22a.858.858 0 00-.77-.97H64.43c-.12.01-.23.04-.33.1a.87.87 0 00-.43.52c-.03.12-.04.23-.03.32v6.01c-.01.11 0 .23.03.34.03.11.09.21.16.3.08.09.17.16.27.22.11.05.22.09.35.09h10.59c.11-.01.22-.05.32-.1a.881.881 0 00.4-.52c.03-.11.04-.22.03-.3v-6.01h-.01zM71.32 39.34h-3.17c-.14 0-.25.11-.25.25v1.06c0 .13.11.25.25.25h.81v3.3a2.52 2.52 0 01-.74 1.67c-.44.45-1.04.71-1.65.74h-5.82c-.14 0-.25.11-.25.25v1.06c0 .13.11.25.25.25h5.82c2.45 0 3.95-2 3.95-3.43V40.9h.8c.14 0 .25-.12.25-.25v-1.06c0-.14-.11-.25-.25-.25z' />
      </g>
      <defs>
        <clipPath id='prese-disimpegno_svg__clip0_2758_23627'>
          <path fill='#fff' d='M0 0h75.8v48.17H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgPreseDisimpegno;
