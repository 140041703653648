import { makeAutoObservable } from 'mobx';

class Store {
  userData = {};
  currentApartment = null;
  phases = [];
  currentConfiguration = null;
  currentPhase = '';
  currentStep = '';
  currentRoomId = '';
  currentSubstep = '';
  currentSubstepId = '';
  currentSubSubstep = '';
  configurations = [];
  answers = null;
  selectedLayout = 1;
  currentTab = '';
  substepSave = null;
  infoPopup = {};
  isOneConfigurationConfirmed = false;
  configurationConfirmed = [];
  userCanGoNext = false;
  currentProject = '';
  selectedFormatoCameraTitle = '';
  selectedFormatoCameraImage = '';
  selectedFormatoCucinaTitle = '';
  selectedFormatoCucinaImage = '';
  selectedPavimentiBagno = [];

  constructor() {
    makeAutoObservable(this);
  }
  setUserData(userData) {
    this.userData = userData;
  }
  setCurrentApartment(currentApartment) {
    this.currentApartment = currentApartment;
  }
  setCurrentPhase(currentPhase) {
    this.currentPhase = currentPhase;
  }
  setPhases = async (phases) => {
    this.phases = phases;
  }
  setCurrentStep(currentStep) {
    this.currentStep = currentStep;
  }
  setCurrentRoomId(currentRoomId) {
    this.currentRoomId = currentRoomId;
  }
  setCurrentSubstep(currentSubstep) {
    this.currentSubstep = currentSubstep;
  }
  setCurrentSubstepId(currentSubstepId) {
    this.currentSubstepId = currentSubstepId;
  }
  setCurrentSubSubstep(currentSubSubstep) {
    this.currentSubSubstep = currentSubSubstep;
  }
  setCurrentTab(currentTab) {
    this.currentTab = currentTab;
  }
  setUserCanGoNext(userCanGoNext) {
    this.userCanGoNext = userCanGoNext;
  }
  setInfoPopup(infoPopup) {
    this.infoPopup = infoPopup;
  }
  setSubstepSave(substepSave) {
    this.substepSave = substepSave;
  }
  setConfigurations(configurations) {
    this.configurations = configurations;
  }
  setAnswers(answers) {
    this.answers = answers;
  }
  setIsOneConfigurationConfirmed(isOneConfigurationConfirmed) {
    this.isOneConfigurationConfirmed = isOneConfigurationConfirmed;
  }
  setConfigurationConfirmed(configurationConfirmedObj) {
    this.configurationConfirmed.push(configurationConfirmedObj);
  }
  setCurrentProject(currentProject) {
    this.currentProject = currentProject;
  }
  setSelectedFormatoCameraTitle(selectedFormatoCameraTitle) {
    this.selectedFormatoCameraTitle = selectedFormatoCameraTitle;
  }
  setSelectedFormatoCameraImage(selectedFormatoCameraImage) {
    this.selectedFormatoCameraImage = selectedFormatoCameraImage;
  }
  setSelectedFormatoCucinaTitle(selectedFormatoCucinaTitle) {
    this.selectedFormatoCucinaTitle = selectedFormatoCucinaTitle;
  }
  setSelectedFormatoCucinaImage(selectedFormatoCucinaImage) {
    this.selectedFormatoCucinaImage = selectedFormatoCucinaImage;
  }
  setSelectedPavimentiBagno(selectedPavimentiBagno) {
    this.selectedPavimentiBagno = selectedPavimentiBagno;
  }
  hideInfoPopup() {
    this.setInfoPopup({});
  }

  /* 
    Recuperare i dati della fase corrente
  */
  getCurrentPhaseData() {
    const phaseData = this.phases.find((el) => el.order === this.currentPhase);
    return phaseData;
  }

  getPhaseSubsteps(phase) {
    const subSteps = [];
    phase?.steps.forEach((step) => {
      step.substeps.forEach((subStep) => {
        subSteps.push(subStep);
      });
    });
    return subSteps;
  }

  /* 
    Recuperare il nome della prossima fase.
    Se non presente bisognerà passare alla fase successiva
  */
  getNextPhase() {
    const currentPhaseIndex = this.phases.findIndex((el) => el.order === this.currentPhase);
    if (currentPhaseIndex === -1 || currentPhaseIndex === this.phases.length - 1) {
      return 'completed';
    } else {
      return this.phases[currentPhaseIndex + 1];
    }
  }

  /*
    Recuperare le info del primo substep della fase.
  */
  getFirstSubstepInfo(phaseOrder) {
    const phase = this.phases.find((phase) => phase.order === phaseOrder);
    const step = phase?.steps[0];
    const substep = step?.substeps[0];

    return {
      phase,
      step,
      substep,
    };
  }

  /* 
    Recuperare il nome del prossimo sotto-step.
    Se non presente bisognerà passare alla fase successiva
  */
  getNextInfo() {
    // Cerco un possibile successivo substep per la fase corrente
    // da rivedere
    const nextSubstepId = this.currentSubstep + 1;
    const currentPhase = this?.phases.find((phase) => phase.order === this.currentPhase);
    const nextStep = currentPhase?.steps?.find((step) =>
      step?.substeps.some((substep) => substep?.frontendId === nextSubstepId),
    );
    if (nextStep) {
      const nextSubstep = nextStep?.substeps.find(
        (substep) => substep.frontendId === nextSubstepId,
      );
      return {
        nextStep,
        nextSubstep,
      };
    } else {
      return 'completed';
    }
  }

  isPhaseCompleted(phaseOrder) {
    const currentPhase = this.phases.find((phase) => phase.order === phaseOrder);
    if (!currentPhase) {
      return false;
    }
    const nextStep = currentPhase.steps.find((step) =>
      step.substeps.some((substep) => substep.isAnswered === false),
    );
    if (!nextStep) {
      return true;
    } else {
      return false;
    }
  }

  isAllPhasesCompleted() {
    return this.phases.some((phase) => !this.isPhaseCompleted(phase.order)) ? false : true;
  }
  /* 
    Recupero le info per ricominciare la configurazione dov'era stata salvata l'ultima volta
  */
  getResumeInfo() {
    let firstNotCompletedStep;
    let firstNotCompletedSubstep;
    let isFirstPhaseSubstep = false;
    const firstNotCompletedPhase = this.phases.find((phase) => {
      for (let i = 0; i < phase.steps.length; i++) {
        const step = phase.steps[i];
        const notCompletedSubstepIndex = step.substeps.findIndex((substep) =>
          this.currentRoomId && phase.order === 40
            ? !substep.isAnswered && substep.roomId === this.currentRoomId
            : !substep.isAnswered,
        );

        if (notCompletedSubstepIndex !== -1) {
          firstNotCompletedStep = step;
          firstNotCompletedSubstep = step.substeps[notCompletedSubstepIndex];

          isFirstPhaseSubstep =
            i === 0 && step.substeps[0].id === step.substeps[notCompletedSubstepIndex].id;
          return true;
        }
      }
      return false;
    });

    let resumeInfo;

    if (firstNotCompletedPhase) {
      resumeInfo = {
        phase: firstNotCompletedPhase,
        step: firstNotCompletedStep,
        substep: firstNotCompletedSubstep,
        isFirstPhaseSubstep,
      };
    } else {
      // Se non riesco a trovare informazioni su dove riprendere la configurazione
      // probabilmente ho finito tutti i substep
      const firstSubstepInfo = this.getFirstSubstepInfo(this.phases[0].order);
      resumeInfo = {
        ...firstSubstepInfo,
        allCompleted: true,
      };
    }
    return resumeInfo;
  }

  /* 
    Settaggio e recupero per la configurazione selezionata nella lista dell'appartamento
  */

  setCurrentConfiguration(currentConfiguration) {
    this.currentConfiguration = currentConfiguration;
  }

  getCurrentConfiguration() {
    return this.currentConfiguration;
  }

  getCurrentProject() {
    return this.currentProject;
  }

  getSubstepIdByFrontendId(frontendId) {
    let substepId = null;
    this.phases.forEach((phase) => {
      phase.steps.forEach((step) => {
        step.substeps.forEach((substep) => {
          if (substep.frontendId === frontendId) {
            substepId = substep.id;
          }
        });
      });
    });
    return substepId;
  }

  setLayoutAnswered(substepId) {
    const phases = this.phases.map((phase) => {
      const steps = phase.steps.map((step) => {
        const substeps = step.substeps.map((substep) => {
          let isAnswered;
          if (substep.id === substepId) {
            isAnswered = true;
          }
          return { ...substep, isAnswered };
        });
        return { ...step, substeps };
      });
      return {
        ...phase,
        steps,
      };
    });
    this.setPhases(phases);
  }
}

export default Store;
