import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import ConfiguratorColumnTitle from '../components/Labels/ConfiguratorColumnTitle';
import LoadingAnimation from '../components/LoadingAnimation';
import { FAKE_LOADING_MILLISECONDS } from '../config';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    backgroundColor: '#FFF',

    '& .title-first-column': {
      flexBasis: '40%',
    },
    '& .title-second-column': {
      flexBasis: '60%',
      paddingLeft: 8,
      paddingRight: 8,
    },

    '& .column-container': {
      paddingBottom: 22,
      paddingLeft: 22,
      overflow: 'hidden',
    },

    '& .first-column': {
      flexBasis: '40%',
      paddingRight: 24,
    },
    '& .second-column': {
      flexBasis: '60%',
      paddingLeft: 24,
      paddingRight: 16,
      height: '100%',
      overflow: 'hidden',
    },
  },
  titleContainer: {
    padding: '22px 0 0 22px',
  },
  fullHeight: {
    height: '100%',
  },
}));

const ConfiguratorTwoColumnsLayoutCarta = ({
  firstRowTitle,
  secondRowTitle,
  firstColumn,
  secondColumn,
  isLoading,
  noLoad
}) => {
  const classes = useStyles();

  const [showFakeLoading, setShowFakeLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowFakeLoading(false);
    }, FAKE_LOADING_MILLISECONDS);
  }, []);

  return (firstColumn && secondColumn && (
    <Grid container direction='column' wrap='nowrap' className={classes.root}>
      <Grid item xs='auto' className={classes.titleContainer}>
        <Grid container wrap='nowrap'>
          <Grid item className={clsx('title-first-column')}>
            <ConfiguratorColumnTitle firstRow={firstRowTitle} secondRow={secondRowTitle} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs className={clsx('column-container')}>
        <Grid container className={clsx(classes.fullHeight)}>
          <Grid item className={clsx('first-column')}>
            {firstColumn}
          </Grid>
          <Grid item className={clsx('second-column')}>
            {((showFakeLoading || isLoading) && !noLoad) ? <LoadingAnimation /> : secondColumn}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ));
};

export default ConfiguratorTwoColumnsLayoutCarta;
