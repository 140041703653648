import _ from 'lodash';
import { saveAnswer } from '../services/answerApi';

export const multipleChoiceSelection = (
  newSelection,
  oldSelection,
  defaultSelection,
  setSelection,
  store,
) => {
  let options = [];

  // Se clicco sul valore di default ed era già selezionato non lo restituisco
  if (_.isEqual(newSelection, defaultSelection)) {
    if (!_.isEqual(newSelection, oldSelection)) {
      options = newSelection;
    }
  } else {
    // Se clicco su altri valori, tolgo quello di default
    options = [...oldSelection].filter((el) => !defaultSelection.includes(el));

    // Se era già selezionato, lo rimuovo dall'array
    if (_.difference(newSelection, options).length === 0) {
      options = options.filter((el) => !newSelection.includes(el));
    } else {
      // Altrimenti aggiunge le opzioni
      options = [...options, ...newSelection];
    }
  }

  setSelection(options);

  if (options?.length > 0) {
    store.setUserCanGoNext(true);
  } else {
    store.setUserCanGoNext(false);
  }
};

export const singleChoiceSelection_OLD = (
  newSelection,
  oldSelection,
  defaultSelection,
  setSelection,
  store,
) => {
  // Se clicco sul valore di default
  if (_.isEqual(newSelection, defaultSelection)) {
    setSelection(newSelection);
  } else {
    // Se clicco su altri valori, tolgo quello di default
    let options = [...oldSelection];

    // Se era già selezionato, lo rimuovo dall'array e torno alla selezione di default
    if (_.isEqual(newSelection, oldSelection)) {
      options = defaultSelection;
    } else {
      // Altrimenti aggiunge le opzioni
      options = newSelection;
    }

    setSelection(options);
  }
  store.setUserCanGoNext(true);
};

export const singleChoiceSelection = (newSelection, oldSelection, setSelection, store) => {
  let options = [];

  // Se clicco su un valore diverso dal precedente, lo assegno
  // se è la stessa opzione resetto la selezione
  if (!_.isEqual(newSelection, oldSelection)) {
    // Altrimenti aggiunge le opzioni
    options = newSelection;
  }
  setSelection(options);

  if (options?.length > 0) {
    store.setUserCanGoNext(true);
  } else {
    store.setUserCanGoNext(false);
  }
};

export const handleSave = async (newAnswers, configurationId, substepId, store, packageId, roomId) => {
  try {
    const response = await saveAnswer(configurationId, substepId, newAnswers, packageId, roomId);
    store.setAnswers(response.data);
    store.setUserCanGoNext(false);
    store.setSubstepSave(null);
  } catch (e) {
    console.log(e);
  }
};
