import { Grid, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import OptionCard, { StandardOptionCardBody } from '../../../components/Card/OptionCard';
import ConfiguratorIntro from '../../../components/configurator/ConfiguratorIntro';
import { AdditionalPlug } from '../../../components/Icons';
import ConfiguratorSelectionLayout from '../../../components/Layout/ConfiguratorSelectionLayout';
import PRESE_ROOM_ICONS_MAP from '../../../configs/preseRoomIconsMap';
import ConfiguratorTwoColumnsPlanimetryLayout from '../../../layouts/ConfiguratorTwoColumnsPlanimetryLayout';
import { getLayoutImagesByConfigurationId } from '../../../services/layoutApi';
import { getOption } from '../../../services/optionApi';
import { useStore } from '../../../store/storeUtils';
import {
  handleCardMouseEnterSimple,
  handleCardMouseLeaveSimple,
  resumeAnswerSimple,
} from '../../../utils/configuratorUtils';
import { getAllImpiantoElettricoLayers, getRoomsInfoToShow } from '../../../utils/layoutUtils';
import {
  handleOptionPlanimetryLayers,
  prepareOptionDataExploded,
} from '../../../utils/optionsUtils';
import { handleSave, multipleChoiceSelection } from '../../../utils/selectionUtils';
import DefaultChoiceRoomBalloons from '../layout/currentConfiguration/DefaultChoiceRoomBalloons';

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: '#EEEEEE',
    width: '100%',
    minHeight: '100%',
  },
  list: { padding: 22 },
  alertMessage: { marginBottom: '5px' },
  hidden: { display: 'none' },
}));

const FirstColumn = ({ defaultOption, onClick, selectedOptions, rooms = [], planimetryImages }) => {
  return defaultOption ? (
    <DefaultChoiceRoomBalloons
      text={<>In ogni ambiente sono previste le seguenti prese.</>}
      isSelected={_.isEqual(defaultOption.ids, selectedOptions)}
      onClick={() => {
        onClick(defaultOption.ids);
      }}
      rooms={rooms}
      planimetryImages={planimetryImages}
      planimetryButtonLabel={"Guarda la planimetria dell'impianto elettrico"}
      legendImg={`${process.env.PUBLIC_URL}/legenda-elettrico.png`}
    />
  ) : (
    ''
  );
};

const SecondColumn = ({
  options,
  selectedOptions,
  onClick,
  cardOnMouseEnter,
  cardOnMouseLeave,
}) => {
  const classes = useStyles();

  return (
    <ConfiguratorSelectionLayout>
      <div className={classes.content}>
        <ConfiguratorIntro
          icon={<AdditionalPlug color={'#AFB0AF'} />}
          title={'Dove vuoi aggiungere una presa schuko?'}
          badgeText={'Puoi selezionare una o più posizioni'}
        />
        <Grid container className={classes.list} spacing={2} justifyContent='center'>
          {options.map((el, i) => (
            <Grid item key={i} xs={6} lg={4}>
              <OptionCard
                {...el}
                onClick={() => onClick(el.ids)}
                isSelected={_.difference(el.ids, selectedOptions).length === 0}
                onMouseEnter={cardOnMouseEnter}
                onMouseLeave={cardOnMouseLeave}
                multiple
                white
                infoPriceMessage={
                  'Il prezzo comprende la fornitura e la posa in opera di n°1 presa Schuko, incluso scatola elettrica. '
                }
              >
                <StandardOptionCardBody
                  {...el}
                  message={<>Aggiungo presa schuko nella posizione:</>}
                  plateText={'P-'}
                  name={''}
                />
              </OptionCard>
            </Grid>
          ))}
        </Grid>
      </div>
    </ConfiguratorSelectionLayout>
  );
};

const ImpiantoPreseCucina = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const [defaultOption, setDefaultOption] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [images, setImages] = useState([]);
  const [roomsInfo, setRoomsInfo] = useState([]);
  const [defaultImages, setDefaultImages] = useState(null);

  const substepIdRef = useRef();
  const oldAnswersRef = useRef();
  const showLayerOnHoverRef = useRef(true);
  const baseImagesRef = useRef([]);
  const baseImagesWithOptionsRef = useRef([]);

  const store = useStore();
  const params = useParams();

  useEffect(() => {
    store.setUserCanGoNext(false);
    prepareOptions();
    prepareRoomsInfo();
  }, []);

  const prepareOptions = async () => {
    const substepId = store.getSubstepIdByFrontendId(store.currentSubstep);

    let optionData = await getOption(
      store.currentConfiguration?.layout?.id,
      substepId,
      params.configuration,
    );
    substepIdRef.current = optionData?.data.substep.id;

    optionData = prepareOptionDataExploded(optionData);

    // Imposto i layer da far vedere
    const layoutImagesResponse = await getLayoutImagesByConfigurationId(params.configuration);
    const layoutImages = layoutImagesResponse.data;

    const impiantoElettricoLayers = await getAllImpiantoElettricoLayers(
      store.currentConfiguration?.layout?.tags,
      params.configuration,
    );

    setDefaultImages([...layoutImages.map((el) => el.fileUrl), ...impiantoElettricoLayers]);

    baseImagesRef.current = layoutImages;
    baseImagesWithOptionsRef.current = layoutImages;
    setImages(layoutImages);

    setOptions(
      optionData.filter((el) => !el.isDefault)?.sort((a, b) => a?.position - b?.position), // Ordinamento opzioni
    );
    const defaultOption = optionData.find((el) => el.isDefault);
    setDefaultOption(defaultOption);

    resumeAnswerSimple(store, oldAnswersRef, substepIdRef, setSelectedOptions, setIsLoading);
  };

  const prepareRoomsInfo = () => {
    const roomsInfo = getRoomsInfoToShow(store, PRESE_ROOM_ICONS_MAP, [
      'preseElettriche',
      'preseTv',
    ]);
    setRoomsInfo(roomsInfo);
  };

  useEffect(() => {
    const save = async () => {
      await handleSave(selectedOptions, params.configuration, substepIdRef.current, store);
    };

    if (!_.isEqual(oldAnswersRef.current, selectedOptions)) {
      store.setSubstepSave(save);
    } else {
      store.setSubstepSave(null);
    }
  }, [selectedOptions]);

  const handleUserSelection = (selection) => {
    multipleChoiceSelection(
      selection,
      selectedOptions,
      defaultOption.ids,
      setSelectedOptions,
      store,
    );
  };

  // Gestione delle immagini della planimetria alla selezione di un opzione
  useEffect(() => {
    handleOptionPlanimetryLayers(
      options,
      defaultOption?.ids,
      selectedOptions,
      showLayerOnHoverRef,
      baseImagesRef,
      setImages,
      baseImagesWithOptionsRef,
    );
  }, [selectedOptions]);

  const handleCardMouseEnter = (fileLabels) => {
    handleCardMouseEnterSimple(
      fileLabels,
      showLayerOnHoverRef,
      baseImagesWithOptionsRef,
      setImages,
    );
  };
  const handleCardMouseLeave = () => {
    handleCardMouseLeaveSimple(showLayerOnHoverRef, baseImagesWithOptionsRef, setImages);
  };

  return (
    <ConfiguratorTwoColumnsPlanimetryLayout
      planimetryImages={images}
      firstColumn={
        <FirstColumn
          defaultOption={defaultOption}
          selectedOptions={selectedOptions}
          onClick={handleUserSelection}
          rooms={roomsInfo}
          planimetryImages={defaultImages}
        />
      }
      secondColumn={
        <SecondColumn
          options={options}
          selectedOptions={selectedOptions}
          onClick={handleUserSelection}
          cardOnMouseEnter={handleCardMouseEnter}
          cardOnMouseLeave={handleCardMouseLeave}
        />
      }
      isLoading={isLoading}
      firstRowTitle={'IL TUO'}
      secondRowTitle={'APPARTAMENTO'}
      secondColumnFirstRowTitle={'SOLUZIONI'}
      secondColumnSecondRowTitle={'AGGIUNTIVE'}
      noOption={options?.length === 0}
      noOptionIcon={<AdditionalPlug />}
      noOptionText={'Non puoi aggiungere prese extra per il piano cucina.'}
      optionLayersOnTop
    />
  );
};

export default ImpiantoPreseCucina;
