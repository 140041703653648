import { Grid, makeStyles } from '@material-ui/core';
import AlertBoxCentered from '../../../../components/AlertBoxCentered';
import AlertBoxChaining from '../../../../components/AlertBoxChaining';
import AlertBoxChoiche from '../../../../components/AlertBoxChoice';
import { NoChoice } from '../../../../components/Icons';
import { useRef, useState, useEffect, useLayoutEffect, useCallback } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    // backgroundColor: '#F8F5F3'
  },
  noChoiceContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F8F5F3',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    // objectPosition: 'left bottom',
    position: 'absolute',
    borderRadius: '8px',
  },
  selectedFinituraCamera: {
    height: 10,
    width: 10,
    position: 'absolute',
    bottom: 30,
    left: 10,
  },
  selectedFinituraCucina: {
    height: 10,
    width: 10,
    position: 'absolute',
    bottom: 400,
    left: 10,
  },
  selectedChain: {
    height: 10,
    width: 10,
    position: 'absolute',
    bottom: 0,
    left: 10,
  },
  notSelectedFinitura: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noSelectionIcon: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoBar: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto',
    whiteSpace: 'nowrap',
    paddingTop: '5px',
    height: 200,
    width: 400,
    '& span': {
      paddingTop: '2px',
    },
    '& svg': {
      flexShrink: 0,
      height: '35px',
      width: '35px',
      marginLeft: '2px',
      marginTop: '-1px',
    },
  },
}));

/*
const loadImage = (imageUrl) => {
	const img = new Image();
	console.log('🚀 ~ loadImage ~ img:', img)
	img.src = imageUrl;

	img.onload = () => {
		getContainedSize(img)
		console.log('🚀 ~ loadImage ~ getContainedSize(img):', getContainedSize(img))
	};
	img.onerror = (err) => {
		console.log("img error");
		console.error(err);
	};
};
*/

const BigBox = ({
  defaultImage,
  selectedVirtualImage,
  isChained,
  selectedFormatoCamereImage,
  selectedFormatoCucinaImage,
  moodName,
  isNoChoice,
  noCoicheNeed,
  selectedFormatoCamereTitle,
  selectedFormatoCucinaTitle,
  selectedPavimentoBagnoImage,
  selectedPavimentoBagnoTitle,
  selectedPareteBagnoImage,
  selectedPareteBagnoTitle,
}) => {
  const classes = useStyles();
  const refDivCoicheCamera = useRef(null);
  const refDivCoicheCucina = useRef(null);
  const refImage = useRef(null);
  const [showFirstFiniture, setShowFirstFiniture] = useState(false);
  const [showSecondFiniture, setShowSecondFiniture] = useState(false);
  const [img, setImg] = useState({ width: 0, height: 0 });
  const [image, setImage] = useState(false);
  const [offSet, setOffset] = useState({ left: 0, bottom: 0 });

  function getContainedSize(img) {
    let ratio = img.naturalWidth / img.naturalHeight;
    let width = img.height * ratio;
    let height = img.height;
    if (width > img.width) {
      width = img.width;
      height = img.width / ratio;
    }
    return [width, height];
  }

  useEffect(() => {
    if (selectedFormatoCamereImage && selectedFormatoCamereTitle) setShowFirstFiniture(true);
    if (selectedPavimentoBagnoImage && selectedPavimentoBagnoTitle) setShowFirstFiniture(true);
    if (selectedFormatoCucinaImage && selectedFormatoCucinaTitle) setShowSecondFiniture(true);
    if (selectedPareteBagnoImage && selectedPareteBagnoTitle) setShowSecondFiniture(true);
  }, [
    selectedFormatoCamereImage,
    selectedFormatoCucinaImage,
    selectedFormatoCucinaTitle,
    selectedFormatoCamereTitle,
    selectedPavimentoBagnoImage,
    selectedPavimentoBagnoTitle,
    selectedPareteBagnoImage,
    selectedPareteBagnoTitle,
  ]);

  /*
  useLayoutEffect(() => {
    if(image){
      const realImageSize = getContainedSize(refImage.current);
      console.log('🚀 ~ useEffect ~ realImageSize:', realImageSize[0])
      setRealSzImage({width: realImageSize[0], height: realImageSize[1]})
      const leftOffset = ((refImage.current?.width - realImageSize[0]) / 2) + 10;
      const bottomOffset = ((refImage.current?.height - realImageSize[1])/2) + 30;
      setOffset({left: leftOffset, bottom: bottomOffset})
      console.log('ciao: ', realSizeImage, image, refImage, offSet);
    }

  }, [image, refImage.current?.src, refImage.current?.complete])
  */
  /*
  
  useEffect(() => {
    if(selectedFormatoCamereImage) setShowFirstFiniture(true);
    if(selectedFormatoCucinaImage) setShowSecondFiniture(true);
    if(image && refImage.current?.complete && refImage.current?.width && refImage.current?.height){
      const realImageSize = getContainedSize(refImage.current);
      const leftOffset = ((refImage.current?.width - realImageSize[0]) / 2) + 10;
      const bottomOffset = ((refImage.current?.height - realImageSize[1])/2) + 30;
      setOffset({left: leftOffset, bottom: bottomOffset});
    }
  }, [selectedFormatoCamereImage, selectedFormatoCucinaImage, image]);
  */

  const onRefChange = useCallback(
    (node) => {
      // ref value changed to node
      if (node !== null && node.clientHeight !== null && node.clientWidth !== null) {
        const realImageSize = getContainedSize(node);
        const leftOffset = (node.width - realImageSize[0]) / 2 + 10;
        const bottomOffset = (node.height - realImageSize[1]) / 2 + 30;
        setOffset({ left: leftOffset, bottom: bottomOffset });
        setImg({ ...img, height: realImageSize[1], width: realImageSize[0] });
      }
    },
    [img?.width],
  );

  return (
    <Grid container className={classes.root}>
      {!isNoChoice && (
        <img
          ref={onRefChange}
          src={selectedVirtualImage || defaultImage}
          className={classes.image}
          alt={'virtual'}
          onError={(e) => (e.target.src = defaultImage)}
          onLoad={() => {
            setImage(true);
            setImg(refImage.current);
          }}
        />
      )}
      {isNoChoice && (
        <Grid className={classes.noChoiceContainer}>
          <NoChoice
            color='#C7C7C7'
            width='126px'
            height='126px'
            className={classes.noSelectionIcon}
          />
        </Grid>
      )}
      {/*selectedVirtualImage && isChained && (
        <div ref={refDivChain} className={classes.selectedChain}>
          <AlertBoxChaining
            refdiv={refDivChain}
            selected={selectedVirtualImage}
          />
        </div>
      )*/}
      {showFirstFiniture && !isNoChoice && (
        <div
          ref={refDivCoicheCamera}
          className={classes.selectedFinituraCamera}
          style={{
            bottom: offSet?.bottom,
            left: offSet?.left,
          }}
        >
          <AlertBoxChoiche
            ref={refDivCoicheCamera}
            selected={showFirstFiniture}
            finituraImg={selectedFormatoCamereImage || selectedPavimentoBagnoImage}
            moodName={moodName}
            selectedFinituraTitle={selectedFormatoCamereTitle || selectedPavimentoBagnoTitle}
            isPavimentoBagno={selectedPavimentoBagnoTitle}
          />
        </div>
      )}
      {showSecondFiniture && !isNoChoice && (
        <div
          ref={refDivCoicheCucina}
          className={classes.selectedFinituraCucina}
          style={{
            bottom: offSet?.bottom + 170,
            left: offSet?.left,
          }}
        >
          <AlertBoxChoiche
            ref={refDivCoicheCucina}
            selected={showSecondFiniture}
            finituraImg={selectedFormatoCucinaImage || selectedPareteBagnoImage}
            moodName={moodName}
            isCucina={true}
            selectedFinituraTitle={selectedFormatoCucinaTitle || selectedPareteBagnoTitle}
            isPareteBagno={selectedPareteBagnoTitle}
          />
        </div>
      )}
      {/*!selectedVirtualImage && !isNoChoice && !noCoicheNeed && (
        <div className={classes.notSelectedFinitura}>
          <AlertBoxCentered
            className={classes.infoBar}
            primaryText={`Seleziona una scelta a destra`}
            secondaryText={`per visualizzare l'anteprima`}
            refdiv={refDivNoSelect}
            show={!selectedVirtualImage}
          />
        </div>
      )*/}
    </Grid>
  );
};

export default BigBox;
