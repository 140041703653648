import * as React from "react";

function SvgPlug(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 109 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M83.08 27.43h-3.5v-8.75h3.5v8.75zm-7-18.37a14 14 0 100 28 14 14 0 000-28zm13.61-3.5a3.9 3.9 0 013.89 3.88v27.23a3.9 3.9 0 01-3.89 3.88H62.47a3.9 3.9 0 01-3.88-3.88V9.44a3.9 3.9 0 013.88-3.88h27.22zM72.58 18.68v8.75h-3.5v-8.75M100.826 41.241h3.12v1h-3.12v-1zm-3.64-8.31h10.39a.6.6 0 01.52.67v5.94a.601.601 0 01-.52.67h-10.37a.6.6 0 01-.51-.67v-5.94a.6.6 0 01.51-.67h-.02zm8.33-6.23a.51.51 0 01.52.52v4.68h-1v-4.68a.519.519 0 01.52-.52h-.04zm-6.19 0a.508.508 0 01.52.52v4.68h-1v-4.68a.52.52 0 01.52-.52M93.578 49.55h5.71a2.74 2.74 0 002.6-2.59v-3.65h1v4.15a3.55 3.55 0 01-3.64 3.12h-5.67v-1.03zM3.308.31h29.16a2.49 2.49 0 012.49 2.49v43.4a2.49 2.49 0 01-2.49 2.49H3.308a2.477 2.477 0 01-2.48-2.49V2.79A2.48 2.48 0 013.308.31z"
        fill={props.color}
      />
      <path
        d="M26.978 6.781h-18.6a2.48 2.48 0 00-2.48 2.48v30.47a2.48 2.48 0 002.48 2.48h18.6a2.48 2.48 0 002.48-2.48V9.261a2.48 2.48 0 00-2.48-2.48z"
        fill="#fff"
      />
      <path d="M24.678 36.74h-14.01v1.28h14.01v-1.28z" fill={props.color} />
      <path
        d="M40.938 22.691h-3.36v1.31h3.36v-1.31zM54.748 23.311l-10.92-3.94 2.12 3.32h-2.96v1.31h2.94l-2.1 3.31 10.92-4z"
        fill="#E52421"
      />
    </svg>
  );
}

export default SvgPlug;
