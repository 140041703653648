import { Tab } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const styles = (theme) => ({
  optionTabRoot: {
    clipPath: 'polygon(0 0, 90% 0%, 100% 100%, 0% 100%)',
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    height: '52px',
    width: '100%',
    paddingLeft: '24px',
    paddingRight: '40px',
    backgroundColor: theme.palette.color.brownGrey2,
    cursor: 'pointer',
    lineHeight: '18px',
    userSelect: 'none',
    color: theme.palette.color.grey2,
    minHeight: 'initial',
    '&.selected-tab': {
      backgroundColor: theme.palette.color.white,
      color: theme.palette.primary.main,
    },
  },
  optionTabWrapper: {
    flexDirection: 'row',
    gap: '6px',
    justifyContent: 'flex-start',
  },
  optionTabText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textTransform: 'capitalize',
  },
});

const a11yProps = (index) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
};

const BigTab = (props) => {
  const { classes, isActive, value, label, ...others } = props;
  return (
    <Tab
      classes={{
        root: classes.optionTabRoot,
        selected: 'selected-tab',
        wrapper: classes.optionTabWrapper,
      }}
      value={value}
      label={<span className={classes.optionTabText}>{label}</span>}
      {...a11yProps(value)}
      {...others}
    />
  );
};

export default withStyles(styles)(BigTab);
