import { withStyles } from '@material-ui/styles';

const styles = (theme) => ({
  optionTabPanelRoot: {
    backgroundColor: '#EEEEEE',
    border: '1px solid #C4C4C4',
    height: 'calc(100% - 40px)',
    overflow: 'hidden auto',
  },
});

const OptionTabPanel = (props) => {
  const { classes, children, value, index, ...other } = props;

  return (
    <div
      className={classes.optionTabPanelRoot}
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
    >
      {children}
    </div>
  );
};

export default withStyles(styles)(OptionTabPanel);
