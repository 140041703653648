import * as React from 'react';

function SvgPortaAltezza(props) {
  return (
    <svg viewBox='0 0 253 247' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M188.043 34.17h-123v212.3h123V34.17z' fill='#e8e8e8' />
      <path
        d='M188.343 246.72H64.793V33.92h123.55v212.8zm-123-.5h122.5V34.42h-122.5v211.8z'
        fill='#AFAFAF'
      />
      <path
        d='M77.044 143.839a4.695 4.695 0 01-4.343-2.901 4.699 4.699 0 016.141-6.141 4.701 4.701 0 010 8.685 4.703 4.703 0 01-1.798.357zM64.924 33.79H.344v.5h64.58v-.5zM64.924 246.471H.344v.5h64.58v-.5zM252.623 33.79h-64.58v.5h64.58v-.5zM252.623 246.471h-64.58v.5h64.58v-.5z'
        fill='#AFAFAF'
      />
      <path d='M216.965 34.17h-2v211.26h2V34.17z' fill='#991914' />
      <path
        d='M220.465 244.43h-9v2h9v-2zM220.465 33.17h-9v2h9v-2zM216.652 32.51h-1V31h1v1.51zm0-3.5h-1v-3h1v3zm0-5.08h-1v-3h1v3zm0-5.08h-1v-3h1v3zm0-5.08h-1v-3.1h1v3.1zm0-5.09h-1v-3h1v3zm0-5.08h-1V.51h1V3.6z'
        fill='#991914'
      />
    </svg>
  );
}

export default SvgPortaAltezza;
