import * as React from 'react';

function SvgUsbAngoloCottura(props) {
  return (
    <svg viewBox='0 0 41 46' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.82 2.162a1.082 1.082 0 100-2.162H8.77c-.2 0-.387.055-.547.15a1.08 1.08 0 00.547 2.013h7.05zM23 4.63H5.516a5.515 5.515 0 00-2.61.658l-.258.148-.316.207-.176.13c-.113.087-.222.18-.332.278l-.207.197A5.507 5.507 0 000 10.145v29.439A5.52 5.52 0 005.516 45.1H23v-2.162H5.516a3.35 3.35 0 01-1.457-.334 3.37 3.37 0 01-1.898-3.02v-23.28H23v-2.162H2.16v-3.997l.004-.163c.004-.092.016-.185.027-.278a3.396 3.396 0 01.223-.83 3.357 3.357 0 013.102-2.083H23V4.629zm0 16.911H7.766a1.027 1.027 0 00-.211.019 1.095 1.095 0 00-.7.457 1.08 1.08 0 00.887 1.687v12.321a2.897 2.897 0 002.899 2.899H23v-2.168H10.637a.726.726 0 01-.528-.227.729.729 0 01-.207-.51V23.703H23V21.54zM10.762 11.303a1.595 1.595 0 00.234-.836 1.61 1.61 0 00-1.21-1.556l-.134-.027-.117-.015-.144-.007a1.601 1.601 0 00-1.454.931 1.564 1.564 0 00-.152.674c0 .361.121.695.32.964a1.61 1.61 0 002.657-.128zm7.367.768a1.604 1.604 0 00.879-2.946l-.086-.053-.18-.088a1.561 1.561 0 00-.437-.112l-.176-.01a1.605 1.605 0 00-1.606 1.604c0 .492.223.931.57 1.225a1.58 1.58 0 001.035.38zM41 22.098h-2.943v3.164h1.002v2.45a.972.972 0 01-.234.66L35.33 32.13V19.144h1.202l-.842-1.574-.847-1.57-.842 1.57-.842 1.575h1.216V35.57L31.152 32.1a.942.942 0 01-.187-.303.994.994 0 01-.066-.356v-2.752c.32-.12.59-.356.764-.67.172-.313.236-.681.18-1.04a1.57 1.57 0 00-.487-.92 1.373 1.373 0 00-.924-.365c-.339 0-.667.13-.925.364a1.57 1.57 0 00-.487.92c-.056.36.008.728.18 1.041.173.314.444.55.764.67v2.757c-.001.254.045.506.136.741.09.235.224.448.392.627l3.883 4.181v2.375a2.617 2.617 0 00-1.63 1.071 2.992 2.992 0 00-.53 1.974c.059.706.361 1.363.847 1.841a2.538 2.538 0 001.781.744c.66 0 1.295-.265 1.781-.744a2.922 2.922 0 00.847-1.841 2.992 2.992 0 00-.53-1.974 2.617 2.617 0 00-1.63-1.071v-5.831h.038l4.117-4.458a1.93 1.93 0 00.392-.627c.09-.235.137-.487.136-.741v-2.45H41v-3.165z'
        fill='#C4C4C4'
      />
    </svg>
  );
}

export default SvgUsbAngoloCottura;
