import * as React from 'react';

function SvgIlluminazioneSoggiorno(props) {
  return (
    <svg viewBox='0 0 67 47' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#illuminazione-soggiorno_svg__clip0_2758_23938)' fill='#C4C4C4'>
        <path d='M53.81 39.1c-.01.97-.83 1.76-1.8 1.76H6.99c-.98 0-1.78-.8-1.78-1.78 0-.36.12-.72.32-1.02.19-.18.38-.36.55-.54.04-.05.08-.11.12-.17.71-.9 1.08-1.97 1.08-3.11 0-1.62-.79-3.15-2.1-4.1-.23-.15-.48-.3-.77-.45a.742.742 0 00-.21-.07c-.16-.04-.32-.1-.46-.17A3.054 3.054 0 012 26.69c0-1.68 1.36-3.05 3.04-3.05.55 0 1.06.14 1.49.38l.15.09s.03.02.04.03c.13.09.24.18.36.27.03.02.04.05.07.07 0 .01.11.1.11.1 2.63 2.24 4.14 5.5 4.14 8.96 0 .41-.03.81-.06 1.2-.02.07-.05.13-.05.19 0 .56.44 1 1 1h20.48v-2H13.39c0-.13.01-.26.01-.39 0-4.05-1.77-7.87-4.78-10.43-.05-.05-.1-.09-.18-.16-.01-.01-.02-.02-.03-.02 2.83-3.94 7.33-6.27 12.23-6.27h12.13v-2H20.64c-5.61 0-10.77 2.7-13.96 7.26a5 5 0 00-1.64-.28C2.26 21.64 0 23.91 0 26.69c0 1.95 1.13 3.74 2.89 4.57.24.11.48.2.72.27a3.003 3.003 0 011.67 2.71c0 .7-.24 1.36-.68 1.92-.01.02-.02.03-.04.05-.14.16-.3.3-.45.45-.04.04-.09.08-.12.12a3.78 3.78 0 003 6.08h.47v2.54c0 .55.44 1 1 1h6.02c.56 0 1-.45 1-1v-2.54h28.04v2.54c0 .55.45 1 1 1h6.04c.55 0 1-.45 1-1v-2.54h.45c2.09 0 3.79-1.69 3.8-3.76h-2zm-40.33 5.3H9.46v-1.54h4.02v1.54zm36.08 0h-4.04v-1.54h4.04v1.54z' />
        <path d='M51.958 33.93h-8.06c-.69 0-1.25.57-1.25 1.26 0 .69.56 1.26 1.25 1.26h8.06c.69 0 1.25-.56 1.25-1.26s-.56-1.26-1.25-1.26zM55.45 10.97c-.34-.33-.71-.64-1.09-.91-1.83-1.35-4.06-2.07-6.39-2.07-2.83 0-5.49 1.05-7.49 2.98-2.01 1.93-3.12 4.5-3.12 7.24 0 2.54.97 4.97 2.72 6.84 1.78 2.11 2.61 6.05 2.61 6.08l.17.82h10.3l.02-.99c.07-2.88 2.81-6.06 2.93-6.19 1.59-1.84 2.47-4.17 2.47-6.56 0-1.62-.39-3.18-1.12-4.58-.51-.98-1.18-1.87-2.01-2.66zm-.88 12.46c-.29.33-2.8 3.29-3.33 6.48H44.5c-.35-1.34-1.26-4.28-2.91-6.23a7.95 7.95 0 01-2.2-5.47c0-4.51 3.85-8.19 8.58-8.19s8.57 3.68 8.57 8.19c0 1.9-.7 3.76-1.97 5.22zM49.098 0h-2.27v4.64h2.27V0zM60.26 3.933L57.282 7.49l1.74 1.458 2.98-3.557-1.74-1.458zM66.34 16.24H61.7v2.27h4.64v-2.27z' />
        <path d='M48.049 23.52l-2.91-2.91-1.42 1.41 4.33 4.33 4.09-4.1-1.41-1.41-2.68 2.68z' />
      </g>
      <defs>
        <clipPath id='illuminazione-soggiorno_svg__clip0_2758_23938'>
          <path fill='#fff' d='M0 0h66.34v46.4H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIlluminazioneSoggiorno;
