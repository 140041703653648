import { useEffect, useState, useRef } from 'react';
import GridDetailsContainer from '../../../components/Card/GridDetailsContainer';
// import MaterialDataSheet from '../../../components/MaterialDataSheet';
// import ConfiguratorThreeColumnsLayout from '../../../layouts/ConfiguratorThreeColumnsLayout';
import { makeStyles } from '@material-ui/core';
import { resumeAnswerSimple } from '../../../utils/configuratorUtils';
import { getPackages } from '../../../services/packageApi';
import { useStore } from '../../../store/storeUtils';
import { useParams } from 'react-router';
import { handleSave, singleChoiceSelection } from '../../../utils/selectionUtils';
import _ from 'lodash';
import LoadingAnimation from '../../../components/LoadingAnimation';
import {
//   materialDataSheetOptionsConfiguration,
  packagesRestructuringClassicConfiguration,
} from '../../../configs/bagnoPhasesConfigurations';
import ConfiguratorTwoColumnsLayout from '../../../layouts/ConfiguratorTwoColumnsLayout';
import BigBox from '../giornoNotte/config/BigBox';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '15px',
    textAlign: 'left',
    height: '5%',
  },
  subTitle: {
    fontSize: '11px',
    textAlign: 'left',
    height: '5%',
  },
  secondaryText: {
    color: theme.palette.color.orange,
  },
}));

const FirstColumn = ({
  options,
  selectedOptions,
  selectedPavimentoBagnoImage,
  selectedPavimentoBagnoTitle,
  selectedPareteBagnoImage,
  selectedPareteBagnoTitle,
}) => {
  const optionSelected = options?.find((option) => option?.id === selectedOptions?.[0]);
  const selectedVirtualImage = optionSelected?.virtualImage?.replace(/\s/g, '%20');
  return (
    <BigBox
      defaultImage={`${process.env.PUBLIC_URL}/assets/images/Bagno.jpg`}
      selectedVirtualImage={selectedVirtualImage}
      selectedPavimentoBagnoImage={selectedPavimentoBagnoImage}
      selectedPavimentoBagnoTitle={selectedPavimentoBagnoTitle}
      selectedPareteBagnoImage={selectedPareteBagnoImage}
      selectedPareteBagnoTitle={selectedPareteBagnoTitle}
    />
  );
};
/*
const SecondColumn = ({ options, selectedOptions }) => {
  const materialObject = materialDataSheetOptionsConfiguration(options, selectedOptions);
  return (
    <MaterialDataSheet
      firstRowTitle={'PREVIEW'}
      secondRowTitle={'PRODOTTO'}
      previewBoxText={'un PIATTO DOCCIA'}
      material={materialObject}
    />
  );
};
*/

const ThirdColumn = ({ options, selectedOptions, handleUserSelection }) => {
  const classes = useStyles();
  const containers = [
    {
      title: 'Modello',
      content: options,
    },
  ];
  return (
    <>
      <div className={classes.title}>
        <span>
          Quale <span className={classes.secondaryText}>modello di piatto doccia</span> preferisci per il tuo bagno ?
        </span>
      </div>
      <div className={classes.subTitle}>
        <span>
          Potrai selezionare le finiture nello step successivo.
        </span>
      </div>
      <div className={classes.subTitle}>
        <span>
          Alcune collezioni sono disponibili in un'unica finitura.
        </span>
      </div>
      <div style={{ height: '85%' }}>
        <GridDetailsContainer
          containers={containers}
          onClick={handleUserSelection}
          selectedOptions={selectedOptions}
          numCols={1}
        />
      </div>
    </>
  );
};

const DocciaModello = () => {
  const [packages, setPackages] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [selectedPavimentoBagnoImage, setselectedPavimentoBagnoImage] = useState(null);
  const [selectedPavimentoBagnoTitle, setSelectedPavimentoBagnoTitle] = useState(null);
  const [selectedPareteBagnoImage, setSelectedPareteBagnoImage] = useState(null);
  const [selectedPareteBagnoTitle, setSelectedPareteBagnoTitle] = useState(null);

  const substepIdRef = useRef();
  const oldAnswersRef = useRef();

  const store = useStore();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const configurationId = params.configuration;
  const currentRoomId = store.currentRoomId;

  const settingFiniture = () => {
    const pavimentoBagnoDes = store.selectedPavimentiBagno?.find(room => room.roomId === currentRoomId)?.pavimentoBagnoDes;
    const pareteBagnoDes = store.selectedPavimentiBagno?.find(room => room.roomId === currentRoomId)?.pareteBagnoDes;
    const pavimentoBagnoImg = store.selectedPavimentiBagno?.find(room => room.roomId === currentRoomId)?.pavimentoBagnoImg;
    const pareteBagnoImg = store.selectedPavimentiBagno?.find(room => room.roomId === currentRoomId)?.pareteBagnoImg;
    if(pareteBagnoDes !== ''){
      setSelectedPareteBagnoTitle(pareteBagnoDes);
      setSelectedPareteBagnoImage(pareteBagnoImg);
    }
    if(pavimentoBagnoDes !== ''){
      setSelectedPavimentoBagnoTitle(pavimentoBagnoDes);
      setselectedPavimentoBagnoImage(pavimentoBagnoImg);
    }
  }

  useEffect(() => {
    setIsLoading(true);
    store.setUserCanGoNext(false);
    prepareOptions();
    settingFiniture();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoomId]);

  const prepareOptions = async () => {
    const substepId = store.currentSubstepId;
    const packages = await getPackages(configurationId, substepId, currentRoomId);
    substepIdRef.current = store.currentSubstepId;
    /*
    const { answers } = store;

    setSelectedMoods(answers.find((el) => el.substep.value === 'FINITURA')?.package?.value);
    const selectedFinitura = answers.find((el) => el.substep.value === 'TONALITA')?.package?.value;
    const stepFinituraId = answers.find((el) => el.substep.value === 'TONALITA')?.substep?.id;
    const finiturePackages = await getPackages(configurationId, stepFinituraId);
    setSelectedFinituraImage(
      finiturePackages?.data[0]?.packages
        ?.find((onePackage) => onePackage?.code === selectedFinitura)
        ?.images?.find((image) => image?.imageType?.value === 'Cover')?.label,
    );
    setselectedFinituraProductDescription(
      finiturePackages?.data[0]?.packages?.find(
        (onePackage) => onePackage?.code === selectedFinitura,
      )?.products[0]?.description,
    );
    */
    const packagesRestruct = packagesRestructuringClassicConfiguration(packages, false, false, 'PiattoDocciaModello');
    setPackages(packagesRestruct);
    resumeAnswerSimple(
      store,
      oldAnswersRef,
      substepIdRef,
      setSelectedPackages,
      setIsLoading,
      currentRoomId,
    );
  };

  useEffect(() => {
    const save = async () => {
      await handleSave(
        null,
        params.configuration,
        substepIdRef.current,
        store,
        selectedPackages?.[0],
        currentRoomId,
      );
    };
    if (!_.isEqual(oldAnswersRef.current, selectedPackages)) {
      store.setSubstepSave(save);
    } else {
      store.setSubstepSave(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPackages]);

  const handleUserSelection = (selection) => {
    singleChoiceSelection([selection], [selectedPackages], setSelectedPackages, store);
  };

  return isLoading ? (
    <LoadingAnimation />
  ) : (
    <ConfiguratorTwoColumnsLayout
      firstRowTitle='CONFIGURA'
      secondRowTitle='IL PIATTO DOCCIA'
      firstColumn={
        <FirstColumn
          options={packages}
          selectedOptions={selectedPackages}
          selectedPavimentoBagnoImage={selectedPavimentoBagnoImage}
          selectedPavimentoBagnoTitle={selectedPavimentoBagnoTitle}
          selectedPareteBagnoImage={selectedPareteBagnoImage}
          selectedPareteBagnoTitle={selectedPareteBagnoTitle}
        />
      }
      secondColumn={
        <ThirdColumn
          options={packages}
          selectedOptions={selectedPackages}
          handleUserSelection={handleUserSelection}
        />
      }
    />
  );
};

export default DocciaModello;
