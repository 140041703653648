import * as React from "react";

function SvgTabletCheck(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 69 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M58.354 10.105H3.105C2.495 10.105 2 10.6 2 11.21v37.445c0 .61.495 1.104 1.105 1.104h55.249c.61 0 1.105-.494 1.105-1.104V11.21c0-.61-.495-1.105-1.105-1.105z"
        stroke="#1D1D1B"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx={54.582} cy={30.362} r={2.267} fill={props.color} />
      <path d="M50.427 12.984V46.23" stroke={props.color} />
      <g clipPath="url(#tablet-check_svg__clip0)">
        <path
          d="M58.557 20c5.522 0 10-4.477 10-10s-4.478-10-10-10c-5.523 0-10 4.477-10 10s4.477 10 10 10z"
          fill="#76B82D"
        />
        <path
          d="M57.407 14a.764.764 0 01-.515-.226l-3.325-3.305a.642.642 0 010-.95c.328-.272.75-.272 1.03 0l2.764 2.807 4.776-5.297a.695.695 0 01.984-.046c.28.227.328.68.047.951l-5.245 5.84a.764.764 0 01-.516.227z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="tablet-check_svg__clip0">
          <path fill="#fff" transform="translate(48.557)" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgTabletCheck;
