import { Grid, makeStyles } from '@material-ui/core';
import { Manutenzione } from '../components/Icons';

const useStyles = makeStyles((theme) => ({
  maintenanceRoot: {
    height: '100%',
    backgroundColor: '#F9F3EE',
    '& > div': {
      paddingTop: '60px',
    },
  },
  card: {
    backgroundColor: '#FFF',
    borderRadius: '8px',
    padding: '112px 80px 58px',
    position: 'relative',
    textAlign: 'center',
    lineHeight: '1.125',
  },
  icon: {
    height: '120px',
    top: '-60px',
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  title: {
    fontSize: '48px',
    marginBottom: '16px',
  },
  subtitle: {
    fontSize: '24px',
    marginBottom: '40px',
    fontFamily: 'miller',
    fontStyle: 'italic',
    color: theme.palette.primary.main,
  },
  body: {
    fontSize: '32px',
    borderWidth: '1px 0 1px',
    borderStyle: 'dashed',
    padding: '24px 0',
  },
}));

const MaintenancePage = ({noProject=false}) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.maintenanceRoot} justifyContent='center' alignItems='center'>
      <Grid item xs={9}>
        <Grid container direction='column' className={classes.card}>
          <Manutenzione className={classes.icon} color='#000' />
          {noProject ? (
            <Grid item className={classes.title}>
              Sembra che ci sia un problema con l'applicativo
            </Grid>
          ) : (
            <Grid item className={classes.title}>
              Stiamo <b>migliorando</b> la tua <br /> esperienza online.
            </Grid>
          )}
          <Grid item className={classes.subtitle}>
            {noProject ? 'Torna alla pagina principale che ti ha fornito gli accessi e riprova ad entrare' : 'Per farlo abbiamo messo in pausa il configuratore.'}
            <br />
            Non preoccuparti, le scelte effettuate fino a questo momento sono salvate.
          </Grid>
          <Grid item className={classes.body}>
            {noProject ? 'Grazie per la pazienza' : 'Torneremo operativi nel minor tempo possibile.'}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MaintenancePage;
