import { Grid, makeStyles } from '@material-ui/core';
import ConfiguratorIntro from '../../../components/configurator/ConfiguratorIntro';
import { PortaRasomuro, PortaTelaioVisibile } from '../../../components/Icons';
import ConfiguratorSelectionLayout from '../../../components/Layout/ConfiguratorSelectionLayout.jsx';
import ConfiguratorTwoColumnsPlanimetryLayout from '../../../layouts/ConfiguratorTwoColumnsPlanimetryLayout';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useStore } from '../../../store/storeUtils';
import { getOption } from '../../../services/optionApi';
import { useRef } from 'react';
import { useParams } from 'react-router';
import { prepareOptionDataExploded, handleOptionPlanimetryLayersUpdated } from '../../../utils/optionsUtils';
import { handleSave, multipleChoiceSelection } from '../../../utils/selectionUtils';
import { getLayoutImagesByConfigurationId } from '../../../services/layoutApi';
import clsx from 'clsx';
import {
  handleCardMouseEnterSimple,
  handleCardMouseLeaveSimple,
  resumeAnswerSimple,
} from '../../../utils/configuratorUtils';
import OptionCard, { StandardOptionCardBody } from '../../../components/Card/OptionCard';
import DefaultPorte from './currentConfiguration/DefaultPorte';
import portaBattente from '../../../assets/POR_B_C_80_210.jpg';
import portaRasomuro from '../../../assets/POR_B_R_80_210.jpg';
import pdfPortaB210 from '../../../assets/POR_B_C_80_210.pdf';
import pdfPortaSI210 from '../../../assets/POR_SI_C_80_210.pdf';
import pdfPortaRB210 from '../../../assets/POR_B_R_80_210.pdf';
import pdfPortaRSI210 from '../../../assets/POR_SI_R_80_210.pdf';

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: '#EEEEEE',
    width: '100%',
  },
  list: { marginTop: '25px', padding: '0 22px 22px' },
  alertMessage: { marginBottom: '5px' },
  scrollableContent: {
    backgroundColor: '#EEEEEE',
    '&.no-options': {
      marginTop: '-100px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
}));

const FirstColumn = ({ defaultOption, onClick, selectedOption }) => {
  // Costruisco l'oggetto da visualizzare
  const options = [
    {
      icon: <PortaTelaioVisibile />,
      products: [
        { imageFileUrl: portaBattente, dataSheetFileUrl: pdfPortaB210 },
        { imageFileUrl: portaBattente, dataSheetFileUrl: pdfPortaSI210 },
      ],
    },
  ];

  return (
    defaultOption && (
      <DefaultPorte
        elements={options}
        isSelected={_.isEqual(defaultOption.ids, selectedOption)}
        onClick={() => {
          onClick(defaultOption.ids);
        }}
        message={"Nell'appartamento sono previste porte con coprifilo."}
        subtitle={'Conferma questa soluzione oppure scegli opzioni alternative a lato'}
      />
    )
  );
};

const SecondColumn = ({ options, selectedOption, onClick, cardOnMouseEnter, cardOnMouseLeave }) => {
  const classes = useStyles();

  return (
    <ConfiguratorSelectionLayout
      scrollableContentClasses={clsx(
        classes.scrollableContent,
        options?.length === 0 && 'no-options',
      )}
    >
      <div className={classes.content}>
        <ConfiguratorIntro
          icon={<PortaRasomuro />}
          title={'Quali porte vuoi rendere senza coprifilo?'}
          topLeftTitle={'stile'}
          badgeText={'Potrai personalizzare le finiture in una fase successiva.'}
        />

        <Grid container className={classes.list} spacing={2} justifyContent='center'>
          {options.map((el, i) => (
            <Grid item key={i} xs={6} lg={4}>
              <OptionCard
                {...el}
                onClick={() => onClick(el.ids)}
                isSelected={_.difference(el.ids, selectedOption).length === 0}
                onMouseEnter={cardOnMouseEnter}
                onMouseLeave={cardOnMouseLeave}
                multiple
                infoPriceMessage='Il prezzo comprende la sostituzione della porta con coprifilo con il modello senza coprifilo, inclusa la fornitura e la posa in opera del telaio.'
                products={[
                  { imageFileUrl: portaRasomuro, dataSheetFileUrl: pdfPortaRB210 },
                  { imageFileUrl: portaRasomuro, dataSheetFileUrl: pdfPortaRSI210 },
                ]}
              >
                <StandardOptionCardBody
                  {...el}
                  message={'Seleziono le porte senza coprifilo nella posizione:'}
                  plateText={'P-'}
                  hideName={true}
                />
              </OptionCard>
            </Grid>
          ))}
        </Grid>
      </div>
    </ConfiguratorSelectionLayout>
  );
};

const PorteStile = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const [baseOptions, setBaseOptions] = useState([]);
  const [defaultOption, setDefaultOption] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [images, setImages] = useState([]);

  const substepIdRef = useRef();
  const oldAnswersRef = useRef();
  const showLayerOnHoverRef = useRef(true);
  const baseImagesRef = useRef([]);
  const baseImagesWithOptionsRef = useRef([]);

  const store = useStore();
  const params = useParams();

  useEffect(() => {
    store.setUserCanGoNext(false);
    prepareOptions();
  }, []);

  const prepareOptions = async () => {
    const substepId = store.getSubstepIdByFrontendId(store.currentSubstep);

    let optionData = await getOption(
      store.currentConfiguration?.layout?.id,
      substepId,
      params.configuration,
    );
    substepIdRef.current = optionData?.data.substep.id;
    optionData = prepareOptionDataExploded(optionData);

    // Imposto i layer da far vedere
    const layoutImagesResponse = await getLayoutImagesByConfigurationId(params.configuration);
    const layoutImages = layoutImagesResponse.data;

    setImages(layoutImages);
    baseImagesRef.current = layoutImages;
    baseImagesWithOptionsRef.current = layoutImages;

    setOptions(
      optionData
        .filter((el) => !el.isDefault)
        ?.sort((a, b) => a?.positions[0] - b?.positions[0]), // Ordinamento opzioni
    );
    setBaseOptions(optionData);
    const defaultOption = optionData.find((el) => el.isDefault);
    setDefaultOption(defaultOption);

    resumeAnswerSimple(store, oldAnswersRef, substepIdRef, setSelectedOptions, setIsLoading);
  };

  useEffect(() => {
    let selectedOptionsToSave = selectedOptions;  
    const save = async () => {
      if (options.length > 0 && defaultOption) {
        const optionSelected = options.filter(option => selectedOptions.includes(option?.id));
        const optionsSelectedNumber = optionSelected.length > 0 && optionSelected.map(selectedOpt => selectedOpt?.numbers[0]?.number);
        defaultOption.options.forEach((defaultOpt) => {
          const defNumber = defaultOpt?.numbers[0]?.number;
          const defId = defaultOpt?.id;
          if (optionsSelectedNumber.length > 0 && !optionsSelectedNumber.includes(defNumber)) selectedOptionsToSave.push(defId);
        });
      };
      await handleSave(selectedOptionsToSave, params.configuration, substepIdRef.current, store);
    };

    if (!_.isEqual(oldAnswersRef.current, selectedOptions)) {
      store.setSubstepSave(save);
    } else {
      store.setSubstepSave(null);
    }
  }, [selectedOptions]);

  const handleUserSelection = (selection) => {
    multipleChoiceSelection(
      selection,
      selectedOptions,
      defaultOption.ids,
      setSelectedOptions,
      store,
    );
  };

  // Gestione delle immagini della planimetria alla selezione di un opzione
  useEffect(() => {
    handleOptionPlanimetryLayersUpdated(
      baseOptions,
      defaultOption?.ids,
      selectedOptions,
      showLayerOnHoverRef,
      baseImagesRef,
      setImages,
      baseImagesWithOptionsRef,
    );
  }, [selectedOptions]);

  const handleCardMouseEnter = (fileLabels, tags) => {
    handleCardMouseEnterSimple(fileLabels, showLayerOnHoverRef, baseImagesRef, setImages, tags);
  };
  const handleCardMouseLeave = () => {
    handleCardMouseLeaveSimple(showLayerOnHoverRef, baseImagesWithOptionsRef, setImages);
  };

  return (
    <ConfiguratorTwoColumnsPlanimetryLayout
      planimetryImages={images}
      firstColumn={
        <FirstColumn
          defaultOption={defaultOption}
          selectedOption={selectedOptions}
          onClick={handleUserSelection}
        />
      }
      secondColumn={
        <SecondColumn
          options={options}
          selectedOption={selectedOptions}
          onClick={handleUserSelection}
          cardOnMouseEnter={handleCardMouseEnter}
          cardOnMouseLeave={handleCardMouseLeave}
        />
      }
      isLoading={isLoading}
      firstRowTitle={'IL TUO '}
      secondRowTitle={'APPARTAMENTO'}
      secondColumnFirstRowTitle={'SOLUZIONI'}
      secondColumnSecondRowTitle={'AGGIUNTIVE'}
      noOption={options?.length === 0}
      noOptionIcon={<PortaRasomuro />}
      noOptionText={'Non ci sono opzioni per porte rasomuro.'}
      optionLayersOnTop
    />
  );
};

export default PorteStile;
