import API from './api';

export const getRealApartmentList = async (shortName) => {
  if(shortName) return await API.get(`/External/RealApartment?projectShortName=${shortName}`)
  else return await API.get(`/External/RealApartment`)
};

export const getRealApartment = async (realApartmentId) => {
  return await API.get(`/External/RealApartment/${realApartmentId}`);
};
