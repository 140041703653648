import * as React from 'react';

function SvgCompass(props) {
  return (
    <svg viewBox='0 0 30 55' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M14.99 13.2c1.3 0 2.4-1 2.4-2.3 0-1.3-1-2.4-2.3-2.4-1.3 0-2.4 1-2.4 2.3-.1 1.3 1 2.4 2.3 2.4zM4.29 39.6l-1.5-.4c0 .1-.1.3-.1.4-.2.7-.9 1-1.6.8-.6-.2-1-.8-.8-1.5.2-.9.5-1.8.7-2.7.1-.7.8-1.1 1.5-1 .7.1 1.1.8 1 1.5v.1c0 .1 0 .2-.1.3l1.6.4.9-3c1.6-5.7 3.4-11.5 5.1-17.3l.6-2.1c.1-.2 0-.3-.1-.4-2.1-1.9-2.3-5.2-.4-7.3.4-.4.8-.8 1.4-1.1.5-.2 1-.5 1.6-.6V4.5 1.1c0-.5.4-1 .9-1s1 .4 1 .9v4.8c.5.2 1.1.4 1.6.7 2.5 1.4 3.4 4.5 2 7-.2.4-.5.8-.8 1.1-.2.1-.2.4-.1.6 1.9 6.4 3.8 12.9 5.7 19.3 1.5 5.1 3.1 10.2 4.5 15.3.4 1.5.7 2.9.8 4.4 0-.1-.1-.1-.1-.2-.8-1.2-1.7-2.4-2.5-3.6-.1-.1-.2-.3-.2-.4-1.3-4.4-2.6-8.9-4-13.3-2-6.8-4.1-13.7-6.1-20.5 0-.1-.1-.1-.1-.2-1 .3-2.1.3-3.1.1-.3.9-.5 1.7-.7 2.5l-6.2 20.1c-1.1 3.7-2.2 7.3-3.3 11-.2.5-.4 1-.7 1.4-.7 1.1-1.4 2.3-2.2 3.4.1-.3.1-.7.2-1 .2-1.1.3-2.2.6-3.3.2-1 .6-2 .9-3l2.1-7.2v-.4z'
        fill='#CDC7C1'
      />
    </svg>
  );
}

export default SvgCompass;
