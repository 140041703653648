import axios from 'axios';
import { getHcUserData } from '../../utils/localStorageUtils';

export const ticketRequestJWT = async () => {

  let JWTparams = new URLSearchParams();

  JWTparams.append('grant_type', 'password');
  JWTparams.append('client_id', process.env.REACT_APP_TIKETING_CLIENT_ID);
  JWTparams.append('client_secret', process.env.REACT_APP_TICKETING_CLIENT_SECRET);
  JWTparams.append('username', process.env.REACT_APP_TICKETING_USERNAME);
  JWTparams.append('password', process.env.REACT_APP_TICKETING_PASSWORD);

  let JWTconfig =  {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_TICKET_LOGIN_URL}`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params : JWTparams
  }

  try {
    const response = await axios.request(JWTconfig);
    return response.data?.access_token;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createTicket = async (subject, description, projectShortName, token) => {
  
  let data = JSON.stringify({
    "Status": "Assigned",
    "Reason": "Home Configurator",
    "Origin": "Web",
    "Subject": subject,
    "Priority": "Medium",
    "Description": description,
    "RecordTypeId": "0121v000000FvjCAAS",
    "Visibile_in_Community__c": true,
    "SuppliedEmail": getHcUserData().email,
    "SuppliedName": `${getHcUserData().firstName} ${getHcUserData().lastName}`,
    "Codice_Cantiere_Text__c": projectShortName,
  });
    
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_TICKET_REQUEST_URL}`,
      headers: {
        'Authorization': `Bearer ${token}`, 
        'Content-Type': 'application/json'
      },
      data : data
    };
  
  try {
      const response = await axios.request(config);
      return response.data?.success;
  } catch (error) {
      console.error(error);
      throw error;
  }
};
