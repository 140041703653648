import * as React from "react";

function SvgPhoneUncheck(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 45 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#phone-uncheck_svg__clip0)"
        stroke="#1D1D1B"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M37.264 51.334V3.665c0-1.532-1.225-2.774-2.736-2.774h-24.2c-1.51 0-2.735 1.242-2.735 2.774v47.669c0 1.532 1.225 2.774 2.736 2.774h24.2c1.51 0 2.735-1.242 2.735-2.774z"
          strokeWidth={2.46}
        />
        <path
          d="M34.757 50.01V4.976c0-.984-.786-1.782-1.757-1.782H11.864c-.97 0-1.757.798-1.757 1.782V50.01c0 .984.787 1.782 1.758 1.782H33c.97 0 1.757-.798 1.757-1.782z"
          strokeWidth={0.75}
        />
      </g>
      <path
        d="M2 52.25l20.232-24.162 20.232-24.16"
        stroke="#F08219"
        strokeWidth={5}
      />
      <defs>
        <clipPath id="phone-uncheck_svg__clip0">
          <path
            fill="#fff"
            transform="translate(6.714)"
            d="M0 0h31.429v55H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgPhoneUncheck;
