import * as React from 'react';

function SvgIlluminazioneAngoloCottura(props) {
  return (
    <svg viewBox='0 0 59 60' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#illuminazione-angolo-cottura_svg__clip0_2758_23999)' fill='#C4C4C4'>
        <path d='M8.09 24.92a1.81 1.81 0 103.619 0 1.81 1.81 0 00-3.62 0zM18 24.92a1.81 1.81 0 103.62 0 1.81 1.81 0 00-3.62 0z' />
        <path d='M44.98 41.75v12.27a5.51 5.51 0 01-5.51 5.52H5.51A5.51 5.51 0 010 54.02V24.58a5.51 5.51 0 015.51-5.51h17.87v2.16H5.51a3.345 3.345 0 00-3.35 3.35v4h21.22v2.16H2.16v23.28c0 .89.35 1.74.98 2.37.63.63 1.48.99 2.37.99h33.96c.89 0 1.74-.36 2.37-.99.63-.63.98-1.48.98-2.37V41.75h2.16z' />
        <path d='M35.078 41.749v8.71c0 .4-.33.73-.73.73h-23.71c-.41 0-.74-.33-.74-.73v-12.32h13.19v-2.16H7.758c-.59-.01-1.08.47-1.09 1.07-.01.6.47 1.08 1.07 1.09v12.32c0 .77.31 1.51.85 2.05.54.55 1.28.85 2.05.85h23.71c.77 0 1.5-.3 2.05-.85.55-.55.84-1.28.85-2.05v-8.71h-2.17zM8.771 16.6h7.05c.6 0 1.08-.48 1.08-1.08 0-.6-.48-1.08-1.08-1.08h-7.05c-.6 0-1.08.48-1.08 1.08 0 .6.48 1.08 1.08 1.08zM44.212 33.93h-8.06c-.69 0-1.25.57-1.25 1.26 0 .69.56 1.26 1.25 1.26h8.06c.69 0 1.25-.56 1.25-1.26s-.56-1.26-1.25-1.26zM47.692 10.97c-.34-.33-.71-.64-1.09-.91-1.83-1.35-4.06-2.07-6.39-2.07-2.83 0-5.49 1.05-7.49 2.98-2.01 1.93-3.12 4.5-3.12 7.24 0 2.54.97 4.97 2.72 6.84 1.78 2.11 2.61 6.05 2.61 6.08l.17.82h10.3l.02-.99c.07-2.88 2.81-6.06 2.93-6.19 1.59-1.84 2.47-4.17 2.47-6.56 0-1.62-.39-3.18-1.12-4.58-.51-.98-1.18-1.87-2.01-2.66zm-.88 12.46c-.29.33-2.8 3.29-3.33 6.48h-6.74c-.35-1.34-1.26-4.28-2.91-6.23a7.95 7.95 0 01-2.2-5.47c0-4.51 3.85-8.19 8.58-8.19s8.57 3.68 8.57 8.19c0 1.9-.7 3.76-1.97 5.22zM41.34 0h-2.27v4.64h2.27V0zM52.51 3.931l-2.979 3.557 1.74 1.458 2.98-3.557-1.74-1.458zM58.581 16.24h-4.64v2.27h4.64v-2.27z' />
        <path d='M40.293 23.52l-2.91-2.91-1.41 1.41 4.32 4.33 4.1-4.1-1.42-1.41-2.68 2.68zM23.38 19.07h-.29v2.16h.29v-2.16zM23.38 28.58h-.29v2.16h.29v-2.16z' />
      </g>
      <defs>
        <clipPath id='illuminazione-angolo-cottura_svg__clip0_2758_23999'>
          <path fill='#fff' d='M0 0h58.58v59.54H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIlluminazioneAngoloCottura;
