import { lighten, makeStyles } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50px',
    height: '50px',
  },
  red: {
    border: `1px solid ${theme.palette.color.red}`,
    borderRadius: '50%',
    color: theme.palette.color.red,
    '&:hover': {
      backgroundColor: lighten(theme.palette.color.red, 0.8),
    },
    '&:active': {
      backgroundColor: lighten(theme.palette.color.red, 0.8),
      opacity: 0.7,
    },
  },
}));

const IconRoundFlatButton = ({ icon, color = 'red', onClick }) => {
  const classes = useStyles();

  return (
    <ButtonBase className={`${classes.root} ${classes[color]}`} onClick={onClick} disableRipple>
      {icon}
    </ButtonBase>
  );
};

export default IconRoundFlatButton;
