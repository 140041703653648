import * as React from 'react';

function SvgIlluminazioneCucina(props) {
  return (
    <svg viewBox='0 0 55 50' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#illuminazione-cucina_svg__clip0_2758_24015)' fill='#C4C4C4'>
        <path d='M22.29 33.4c0-.14-.03-.28-.09-.43a1.11 1.11 0 00-1-.63c-.59 0-1.08.47-1.08 1.06v7.07h-3.45l-.63-8.51-.05-.35h-.05a.951.951 0 00-.27-.36c-.23-.2-.51-.28-.8-.26-.56.05-.98.5-.99 1.05v.09l.61 8.34h-3.47L8.79 27.64c-.07-.44-.41-.78-.85-.87-1.92-.39-3.57-1.49-4.63-3.11-1.02-1.56-1.38-3.42-.99-5.23.8-3.81 4.64-6.28 8.55-5.5 3.35.68 5.78 3.59 5.78 6.92 0 .59.49 1.07 1.08 1.07.59 0 1.09-.48 1.09-1.07 0-2.94-1.44-5.7-3.86-7.42a9.417 9.417 0 016.86-2.33l.42.02V7.99l-.37-.03c-3.14-.17-6.16.88-8.47 2.97-.14.12-.27.25-.43.41-4.68-1.87-10.1.25-12.16 4.78a8.971 8.971 0 00-.21 6.97c.89 2.33 2.67 4.16 5 5.18.38.16.77.3 1.18.42l2.24 12.9v6.72c0 .59.49 1.07 1.09 1.07h22.2c.6 0 1.08-.48 1.08-1.07l.04-7.84H22.29V33.4zm8.93 9.2v4.64H11.19V42.6h20.03z' />
        <path d='M39.81 33.93h-8.06c-.69 0-1.25.57-1.25 1.26 0 .69.56 1.26 1.25 1.26h8.06c.69 0 1.25-.56 1.25-1.26s-.56-1.26-1.25-1.26zM43.29 10.97c-.34-.33-.71-.64-1.09-.91-1.83-1.35-4.06-2.07-6.39-2.07-2.83 0-5.49 1.05-7.49 2.98-2.01 1.93-3.12 4.5-3.12 7.24 0 2.54.97 4.97 2.72 6.84 1.78 2.11 2.61 6.05 2.61 6.08l.17.82H41l.02-.99c.07-2.88 2.81-6.06 2.93-6.19 1.59-1.84 2.47-4.17 2.47-6.56 0-1.62-.39-3.18-1.12-4.58-.51-.98-1.18-1.87-2.01-2.66zm-.88 12.46c-.29.33-2.8 3.29-3.33 6.48h-6.74c-.35-1.34-1.26-4.28-2.91-6.23a7.95 7.95 0 01-2.2-5.47c0-4.51 3.85-8.19 8.58-8.19s8.57 3.68 8.57 8.19c0 1.9-.7 3.76-1.97 5.22zM36.95 0h-2.27v4.64h2.27V0zM48.112 3.935l-2.98 3.557 1.741 1.458 2.98-3.557-1.74-1.458zM54.18 16.24h-4.64v2.27h4.64v-2.27z' />
        <path d='M35.9 23.52l-2.91-2.91-1.42 1.41 4.33 4.33 4.09-4.1-1.41-1.41-2.68 2.68z' />
      </g>
      <defs>
        <clipPath id='illuminazione-cucina_svg__clip0_2758_24015'>
          <path fill='#fff' d='M0 0h54.18v49.38H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIlluminazioneCucina;
