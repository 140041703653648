import * as React from 'react';

function SvgDownload2(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 33 43'
      fill='#fff'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g {...props} clipPath='url(#download2_svg__clip0)' fill='#1D1D1B'>
        <path d='M32.5 42.78H.5a.5.5 0 01-.5-.5V.5A.5.5 0 01.5 0h22.56a.51.51 0 01.36.15l9.43 9.47A.46.46 0 0133 10v32.28a.5.5 0 01-.5.5zm-31.5-1h31V10.47h-8.94a.5.5 0 01-.5-.5V1H1v40.78zM23.56 9.47h7.73l-7.73-7.76v7.76z' />
        <path d='M22.138 31.58h-11.28v-2.21h11.28v2.21zm-10.28-1h9.28v-.21h-9.28v.21zm4.64-1.69a1.142 1.142 0 01-.82-.35l-5.42-5.95a1.12 1.12 0 01.82-1.88h.62v-5.45a1.52 1.52 0 011.51-1.51h6.59a1.49 1.49 0 011.49 1.5v5.46h.63a1.09 1.09 0 011 .66 1.12 1.12 0 01-.2 1.22l-5.42 5.94a1.11 1.11 0 01-.82.36h.02zm-5.42-7.18a.11.11 0 00-.11.07.15.15 0 000 .14l5.47 6h.07l5.47-6a.11.11 0 000-.14.1.1 0 00-.1-.07h-1.59v-6.46a.491.491 0 00-.49-.5h-6.6a.5.5 0 00-.5.5v6.46h-1.62z' />
      </g>
      <defs>
        <clipPath id='download2_svg__clip0'>
          <path fill='#fff' d='M0 0h33v42.78H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgDownload2;
