import * as React from "react";

function SvgReset(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#reset_svg__clip0)" fill="#fff">
        <path d="M7.637 13a.402.402 0 01-.287-.12.42.42 0 010-.587.402.402 0 01.287-.122 5.467 5.467 0 003.085-.954 5.647 5.647 0 002.045-2.545c.421-1.037.531-2.177.317-3.277a5.711 5.711 0 00-1.518-2.905A5.515 5.515 0 008.724.937a5.44 5.44 0 00-3.208.322 5.578 5.578 0 00-2.491 2.09 5.76 5.76 0 00-.936 3.15.42.42 0 01-.119.294.402.402 0 01-.574 0 .42.42 0 01-.119-.294c0-1.286.374-2.543 1.073-3.612A6.394 6.394 0 015.206.493a6.237 6.237 0 013.676-.37 6.322 6.322 0 013.258 1.78 6.546 6.546 0 011.74 3.33 6.633 6.633 0 01-.363 3.756 6.473 6.473 0 01-2.344 2.917 6.266 6.266 0 01-3.536 1.095z" />
        <path d="M1.682 6.913H1.63a.415.415 0 01-.27-.154L.082 5.086a.422.422 0 01.07-.584.4.4 0 01.572.072l1.028 1.347 1.37-1.106a.403.403 0 01.456-.045.409.409 0 01.195.255.424.424 0 01-.15.442l-1.698 1.36a.411.411 0 01-.243.086z" />
      </g>
      <defs>
        <clipPath id="reset_svg__clip0">
          <path fill="#fff" d="M0 0h14v13H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgReset;
