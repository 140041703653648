import * as React from "react";

function SvgChefHatLock(props) {
  return (
    <svg viewBox="30 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <path 
          d="M56.2209 4.41049C57.2452 5.4813 58.0428 6.87492 58.4728 8.31579C58.5119 8.55987 58.637 8.76458 58.8325 8.89056C59.0515 9.02441 59.3095 9.0559 59.5519 8.9693C59.7787 8.92205 59.9898 8.75671 60.1149 8.51263C60.2165 8.30791 60.2322 8.06383 60.1618 7.88274C59.8803 6.84343 59.4112 5.81199 58.7465 4.81204C59.4033 4.59158 60.1462 4.48135 60.9516 4.48135C64.7204 4.40261 67.8013 7.55205 67.8638 11.4731C67.8638 12.8982 67.4416 14.2289 66.6987 15.3469H68.7161C69.6075 13.4966 69.8421 11.4022 69.3886 9.45746C69.3886 9.33148 69.3651 9.27637 69.3026 9.19763C68.2626 5.31595 64.9081 2.62318 61.1001 2.62318C59.9507 2.62318 58.7934 2.83577 57.5736 3.30031C53.6092 -0.912057 47.0019 -1.11677 42.7795 2.89089L42.326 3.3633C38.0176 1.47364 33.0758 3.44204 31.0271 7.91423C30.1357 10.048 30.0732 12.4179 30.8551 14.5832C31.6527 16.7799 33.2244 18.5042 35.2808 19.449L35.359 19.4805C35.359 19.4805 35.4372 19.5199 35.492 19.5278C35.8516 19.6931 36.1879 19.8112 36.6023 19.9451L38.6979 32.5901V39.1015C38.6979 39.6527 39.1436 40.007 39.558 40.007H50.7396V38.2118H40.465V33.4168H50.7396V24.1968C50.5832 23.9055 50.286 23.7401 50.0046 23.7401C49.4963 23.7401 49.1444 24.1102 49.1444 24.6456V31.6374H45.6414L45.0237 23.2284C44.9846 22.9685 44.8438 22.7323 44.6562 22.5906C44.4763 22.4568 44.273 22.4095 44.1166 22.4489C43.7022 22.4489 43.3269 22.8662 43.3269 23.3386L43.929 31.7082H40.3008L38.2052 19.1105C38.1114 18.764 37.869 18.4963 37.5171 18.4097C35.75 18.0712 34.2018 17.0082 33.1853 15.4099C32.1922 13.8667 31.8247 12.0242 32.1375 10.1818C32.982 6.35526 36.6179 3.92233 40.2617 4.74905C43.3973 5.37894 45.7196 8.26067 45.7743 11.6069C45.7743 12.1581 46.22 12.5124 46.6344 12.5124C47.1271 12.5124 47.4867 12.1424 47.4867 11.6069C47.4867 8.71734 46.1105 6.04819 43.8117 4.40261C47.3538 0.993351 52.7648 0.977604 56.2443 4.42624" 
          fill={props.color}
        />
        <path 
          d="M67.2529 25.9994H59.676C59.676 25.9285 59.6682 25.8655 59.6682 25.7868V23.283C59.676 21.354 60.9818 19.8501 62.921 19.5352C64.8915 19.2202 66.8932 20.6375 67.1904 22.5822C67.2373 22.8263 67.2451 23.0862 67.2451 23.3381C67.2529 24.1412 67.2529 24.9522 67.2529 25.7553V25.9915V25.9994ZM69.8255 26.0151C69.6065 26.0073 69.4032 26.0151 69.1452 26.0151V23.3145C69.1452 20.4564 67.1669 18.1652 64.2816 17.7006C64.1721 17.6849 64.0548 17.6613 63.9375 17.6455H62.9601C62.5066 17.7479 62.0609 17.8109 61.623 17.9447C59.3632 18.6691 57.7837 20.7792 57.7603 23.1098C57.7447 23.9916 57.7525 24.8656 57.7525 25.7474V26.0073H57.1035C55.8367 26.0073 54.9062 26.9206 54.9062 28.1567V37.8019C54.9062 38.8491 55.5553 39.6522 56.5874 39.9041C56.6421 39.9199 56.689 39.9671 56.736 39.9986H70.1773C70.1773 39.9986 70.2477 39.9278 70.2946 39.9199C71.3268 39.6758 71.9914 38.8648 71.9914 37.8255C71.9992 34.5973 71.9992 31.3692 71.9914 28.141C71.9914 26.9285 71.0609 26.0309 69.8255 26.023" 
          fill={props.color}
        />
      </g>
    </svg>
  );
}

export default SvgChefHatLock;
