import * as React from 'react';

function SvgSmartHomeNo(props) {
  return (
    <svg viewBox='0 0 80 59' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#smart-home-no_svg__clip0_2068_22810)'>
        <path
          d='M61.75 53.16a1.53 1.53 0 01-1.54 1.52H22.03a1.53 1.53 0 01-1.54-1.52V28.61l19.82-16.3a1.27 1.27 0 011.62 0l19.82 16.3v24.55zm8.71-24.66l-9.74-8.06v-10a1.07 1.07 0 00-1.08-1.06h-5a1.08 1.08 0 00-1.08 1.06v4.06L42.92 5.73a2.89 2.89 0 00-3.6 0L11.77 28.5a2.741 2.741 0 00-.36 3.86l.07.08a2.87 2.87 0 003.94.34l2.18-1.8v22.18a4.4 4.4 0 004.43 4.36h38.18a4.4 4.4 0 004.43-4.36V30.98l2.18 1.8a2.87 2.87 0 003.94-.34l.07-.08a2.74 2.74 0 00-.37-3.86zm-39.4 10.51l1.59 1.57a12.1 12.1 0 0116.94 0l1.59-1.57a14.39 14.39 0 00-20.12 0zm-3.68-3.62l1.59 1.57a17.37 17.37 0 0124.3 0l1.59-1.57a19.65 19.65 0 00-27.48 0zm11.1 10.92l2.64 2.62 2.64-2.62a3.77 3.77 0 00-5.28 0zm-3.74-3.69l1.59 1.57a6.84 6.84 0 019.58 0l1.59-1.57a9.12 9.12 0 00-12.76 0z'
          fill='#262626'
        />
        <path d='M77.115.007L-.008 54.998l2.572 3.607 77.123-54.99L77.115.006z' fill='#F0801A' />
      </g>
      <defs>
        <clipPath id='smart-home-no_svg__clip0_2068_22810'>
          <path fill='#fff' d='M0 0h79.68v58.62H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgSmartHomeNo;
