import * as React from 'react';

function SvgSafe(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 121 121'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M120.336.51H.906v106.17h4.14v14.14h28.41v-14.14h54.58v14.14h28.41v-14.14h3.89V.51zM117.846 3v101.19H3.396V3h114.45zM7.536 118.32v-11.64h23.43v11.64H7.536zm83 0v-11.64h23.43v11.64h-23.43z'
        fill='#AFB0AF'
      />
      <path
        d='M106.166 13.08h-91.08v81h91.08v-81zm-88.59 78.54v-76h86.1v76h-86.1z'
        fill='#AFB0AF'
      />
      <path
        d='M60.627 25.12a28.481 28.481 0 00-20.149 48.617A28.48 28.48 0 0089.097 53.59a28.51 28.51 0 00-28.47-28.47zm14.47 50l-2.08-3.06-2.06 1.45 2 3a25.618 25.618 0 01-11.11 3v-3.9h-2.49v3.9a25.689 25.689 0 01-11.15-3.1l2.08-3.08-2.06-1.4-2.15 3.18a26.21 26.21 0 01-7.42-7.67l3.38-2-1.25-2.16-3.36 2a25.66 25.66 0 01-2.75-10.43h4v-2.5h-4a25.76 25.76 0 012.81-10.55l3.28 1.9 1.25-2.19-3.29-1.9a26.212 26.212 0 017.41-7.61l2.07 3.06 2.06-1.4-2-2.95a25.761 25.761 0 0111.1-3.1v3.94h2.54v-3.91a25.73 25.73 0 0111.16 3.13l-2.16 3.09 2.07 1.39 2.15-3.18a26 26 0 017.43 7.67l-3.39 2 1.24 2.15 3.38-2a25.88 25.88 0 012.76 10.44h-4v2.49h4a25.68 25.68 0 01-2.82 10.55l-3.27-1.86-1.24 2.16 3.3 1.91a26.338 26.338 0 01-7.42 7.58v-.04z'
        fill='#AFB0AF'
      />
      <path
        d='M60.626 38.93a14.86 14.86 0 1014.86 14.86 14.88 14.88 0 00-14.86-14.86zm12.36 14.86a12.37 12.37 0 11-12.36-12.37 12.38 12.38 0 0112.36 12.37z'
        fill='#AFB0AF'
      />
    </svg>
  );
}

export default SvgSafe;
