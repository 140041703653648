import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useRef, useState } from 'react';
import IconButton from '../Buttons/IconButton';
import ImageZoomButton from '../Buttons/ImageZoomButton';
import { Camera, Plus } from '../Icons';
import RichTextPopover from '../popovers/RichTextPopover';

const useStyles = makeStyles((theme) => ({
  configurationLayoutRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '15px 14px',
    backgroundColor: theme.palette.color.grey1,
    height: '100%',
    '&.full-height': {
      height: '100%',
    },
    '& .header': {
      width: '100%',
      marginBottom: '8px',
      padding: '0 0 12px',
      borderBottom: `1px solid ${theme.palette.color.grey2}`,
      textAlign: 'center',
      '&.align-left': {
        textAlign: 'left',
      },
    },
    '& .title': {
      fontSize: '15px',
      lineHeight: '18px',
      textTransform: 'uppercase',
    },
    '& .confirm-text': {
      fontSize: '11px',
      textAlign: 'center',
      color: '#000',
      marginBottom: '4px',
      maxWidth: 'unset !important',
    },
    '& .message': {
      paddingTop: '10px',
      fontFamily: 'miller',
      fontStyle: 'italic',
      fontSize: '15px',
      textAlign: 'center',
    },
    '& .planimetry-buttons': {
      display: 'block',
      width: '100%',
      backgroundColor: '#f7f7f7',
      fontSize: '13px',
      marginTop: '16px',
    },
    '& .info-button': {
      cursor: 'pointer',
      textDecoration: 'underline',
      fontSize: '12px',
      minWidth: '20%',
      textAlign: 'right',
      transition: 'color 100ms',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    '& .children': {
      width: '100%',
    },
  },
  actionButton: {
    width: '100%',
    marginTop: '16px',
    paddingTop: '8px',
    color: theme.palette.primary.main,
    borderTop: `1px solid ${theme.palette.color.grey2}`,
    '&.no-border': {
      borderTop: 0,
      marginTop: 0,
    },
    '&.two-buttons': {
      '& > div': {
        maxWidth: '50%',
      },
    },
  },
}));

const ConfigurationLayout = ({
  children,
  title,
  boldTitle,
  buttonText,
  onClick,
  noBottomBorder,
  noButton,
  fullHeight,
  isSelected,
  subtitle = 'Conferma questa soluzione oppure scegli opzioni aggiuntive a lato',
  message,
  planimetryImages,
  planimetryButtonLabel = "Guarda la planimetria dell'impianto elettrico",
  legendImg,
  infoRichText,
  actionButton,
  hideButton,
  infoRichTextButtonLabel,
}) => {
  const classes = useStyles();

  const [showRichText, setShowRichText] = useState(false);
  const richTextRef = useRef();

  return (
    <div className={clsx(classes.configurationLayoutRoot, fullHeight && 'full-height')}>
      <Grid
        container
        className={clsx('header', infoRichText && 'align-left')}
        justifyContent={infoRichText ? 'space-between' : null}
        alignItems={infoRichText ? 'center' : null}
        wrap='nowrap'
      >
        <Grid item xs={infoRichText ? 'auto' : true}>
          <div className='title'>
            {title} <b>{boldTitle}</b>
          </div>
        </Grid>
        {infoRichText && (
          <>
            <Grid
              className='info-button'
              item
              xs='2'
              ref={richTextRef}
              onClick={() => setShowRichText(true)}
            >
              {infoRichTextButtonLabel ? infoRichTextButtonLabel : 'Scopri le qualità'}
            </Grid>
            <RichTextPopover
              show={showRichText}
              onClose={() => setShowRichText(false)}
              anchorEl={richTextRef.current}
              infoRichText={infoRichText}
            />
          </>
        )}
      </Grid>
      <Grid item xs className='children'>
        {children}
      </Grid>
      {message && <div className='message'>{message}</div>}
      {planimetryImages && (
        <ImageZoomButton
          label={planimetryButtonLabel}
          icon={<Camera color='#878787' width='12px' height='12px' />}
          className={'planimetry-buttons'}
          imageSrc={planimetryImages}
          isPlanimetry
          legendImg={legendImg}
        />
      )}
      {!noButton && (
        <Grid
          container
          className={clsx(
            classes.actionButton,
            noBottomBorder && 'no-border',
            actionButton && 'two-buttons',
          )}
          justifyContent={actionButton ? 'space-between' : 'center'}
          alignItems={actionButton ? 'center' : null}
        >
          <Grid item xs='12' className='confirm-text'>
            {subtitle}
          </Grid>
          {actionButton && (
            <Grid item xs='auto'>
              {actionButton}
            </Grid>
          )}

          {!hideButton && (
            <Grid item xs='auto'>
              <IconButton
                text={isSelected ? 'CONFERMATO' : 'CONFERMA'}
                icon={<Plus color='currentColor' width='10px' height='10px' />}
                onClick={onClick}
                isSelected={isSelected}
              />
            </Grid>
          )}
        </Grid>
      )}
    </div>
  );
};

export default ConfigurationLayout;
