import * as React from 'react';

function SvgSoggiorno(props) {
  return (
    <svg viewBox='0 0 60 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#soggiorno_svg__clip0_2448_22990)'>
        <path
          d='M53.972 6.98a5.16 5.16 0 00-1.63.27 16.93 16.93 0 00-14-7.25h-17.73a16.94 16.94 0 00-14 7.26 5 5 0 00-1.64-.28 5.05 5.05 0 00-5.05 5 5.06 5.06 0 002.9 4.57c.226.115.464.205.71.27.138.072.271.152.4.24a3 3 0 011.28 2.47 3 3 0 01-.69 1.92v.06a6.248 6.248 0 01-.45.44.689.689 0 00-.11.13 3.76 3.76 0 00-.79 2.29 3.79 3.79 0 003.79 3.79h.46v2.53a1 1 0 001 1h6a1 1 0 001-1v-2.5h28v2.53a1 1 0 001 1h6a1 1 0 001-1v-2.53h.47a3.79 3.79 0 003.78-3.79 3.75 3.75 0 00-.78-2.29.74.74 0 00-.12-.13c-.15-.15-.31-.29-.45-.45l-.06-.07a3.07 3.07 0 01-.214-3.494c.295-.48.719-.866 1.224-1.116.249-.063.49-.154.72-.27a5.05 5.05 0 002.89-4.57 5 5 0 00-4.91-5.03zm-33.32-5h17.73a14.999 14.999 0 0112.23 6.25l-.07.07a.387.387 0 00-.09.08 13.73 13.73 0 00-4.84 10.47v.4h-32.22v-.4a13.66 13.66 0 00-4.78-10.42l-.17-.16A15 15 0 0120.652 2v-.02zm-7.16 27.73h-4v-1.52h4v1.52zm36.06 0h-4v-1.52h4v1.52zm5.72-15a2.537 2.537 0 01-.46.17l-.19.07a5 5 0 00-2.89 4.55 5.12 5.12 0 001.19 3.26c.167.193.348.373.54.54.204.294.315.642.32 1a1.79 1.79 0 01-1.78 1.79h-45a1.79 1.79 0 01-1.79-1.79 1.8 1.8 0 01.33-1c.19-.17.37-.35.54-.54.048-.052.092-.109.13-.17a5 5 0 00.517-5.408 5.06 5.06 0 00-1.547-1.792 4.99 4.99 0 00-.77-.45.696.696 0 00-.2-.08 2.298 2.298 0 01-.46-.17 3.05 3.05 0 01-1.75-2.76 3 3 0 013.05-3 2.999 2.999 0 011.49.38l.15.08c.12.09.24.18.35.28l.08.07.11.1a11.7 11.7 0 014.13 8.95c.002.405-.022.809-.07 1.21a1.058 1.058 0 000 .19 1 1 0 001 1h34.42a1 1 0 00.99-1.12 10.63 10.63 0 01-.07-1.28 11.7 11.7 0 014.13-8.95l.11-.1.08-.07c.11-.1.23-.19.35-.28l.12-.07a3.14 3.14 0 011.52-.4 3 3 0 013 3 3 3 0 01-1.67 2.86v-.07z'
          fill={props.color}
        />
      </g>
      <defs>
        <clipPath id='soggiorno_svg__clip0_2448_22990'>
          <path fill={props.color} d='M0 0h59.01v31.74H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgSoggiorno;
