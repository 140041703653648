import { Dialog, Grid, makeStyles } from '@material-ui/core';
import Button3D from '../Buttons/Button3D';
import OutlineIconButton from '../Buttons/OutlineIconButton';
import { DocumentDownload, Exit } from '../Icons';
import SvgLetterI from '../Icons/LetterI';

const useStyles = makeStyles((theme) => ({
  areYouSureDialogRoot: {
    backgroundColor: 'transparent',
    '& .paper': {
      backgroundColor: 'transparent',
    },

    '& .dialog-container': {
      padding: 0,
      flexWrap: 'nowrap',
      overflow: 'hidden',
      width: 'calc(100% - 28px)',
    },

    '& .dialog-left-image': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '16px',
      filter: 'drop-shadow(0px 20px 20px rgba(0, 0, 0, 0.5))',
    },
    '& .exit-icon': {
      position: 'absolute',
      right: 0,
      top: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '20px',
      height: '20px',

      backgroundColor: theme.palette.primary.main,
      borderRadius: '50%',
      cursor: 'pointer',
    },

    '& .dialog-content': {
      padding: '24px 0',
      textAlign: 'center',
      '& > div': {
        backgroundColor: '#FFF',
        height: '100%',
        padding: '16px 32px',
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
      },
    },
    '& .info-icon': {
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: '100%',
      aspectRatio: 1,
      padding: 4,
      display: 'inline-flex',
      marginBottom: 16,
      '& path': {
        fill: '#000',
      },
    },
    '& .dialog-title': {
      color: theme.palette.primary.main,
      fontSize: 16,
    },
    '& .dialog-subtitle': {
      fontSize: 14,
      marginBottom: 24,
    },
    '& .dialog-body': {
      borderTop: '1px solid #e0e0e0',
      padding: '24px 0',
      borderBottom: '1px solid #e0e0e0',
      marginBottom: 24,
    },
    '& .pdf-card': {
      border: `1px solid ${theme.palette.primary.main}`,
      padding: '16px 0',
      width: '70%',
      margin: 'auto',
    },
    '& .pdf-card-title': {
      fontSize: 14,
      lineHeight: 1.2,
      marginBottom: 16,
      padding: '0 32px',
    },
    '& .pdf-card-icon': {
      textAlign: 'center',
      '& svg': {
        height: 48,
        width: 'auto',
      },
    },
    '& .pdf-card-name': {
      fontSize: 16,
      marginBottom: 16,
      paddingBottom: 24,
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    '& .pdf-card-download': {
      '& .download-button': {
        borderRadius: 16,
        padding: '8px 24px',
        color: theme.palette.primary.main,
        margin: 'auto',
      },
    },
    '& .dialog-action-cancel': {
      marginTop: 32,
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      fontSize: 12,
      cursor: 'pointer',
    },
  },
}));

const AreYouSureConfirmationDialog = ({
  isOpen,
  handleClose,
  handleConfirm,
  configurationName = 'Configurazione',
  handleDownload,
  isLoading,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={!isLoading ? handleClose : null}
      classes={{ root: classes.areYouSureDialogRoot }}
      PaperProps={{
        elevation: 0,
        classes: {
          root: 'paper',
        },
      }}
    >
      <div onClick={!isLoading ? handleClose : null} className={'exit-icon'}>
        <Exit color='#fff' width='8px' height='8px' />
      </div>
      <Grid container className={'dialog-container'}>
        <Grid item xs={7}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/moodGallery/Mood Special Edition/MOODS_P07.jpg`}
            alt='img for dialog'
            className={'dialog-left-image'}
          />
        </Grid>
        <Grid item xs={5} className={'dialog-content'}>
          <div>
            <div className={'info-icon'}>
              <SvgLetterI />
            </div>
            <div className={'dialog-title'}>Vuoi confermare tutte le scelte</div>
            <div className={'dialog-subtitle italic'}>
              di questa configurazione ed inviarle ad AbitareIn?
            </div>
            <div className={'dialog-body'}>
              <div className='pdf-card'>
                <div className='pdf-card-title'>
                  <b>Scarica e visualizza </b>il PDF prima di continuare
                </div>
                <div className='pdf-card-icon'>
                  <DocumentDownload />
                </div>
                <div className='pdf-card-name italic'>{configurationName}</div>
                <div className='pdf-card-download'>
                  <OutlineIconButton
                    className={'download-button'}
                    text={'SCARICA'}
                    onClick={handleDownload}
                    isLoading={isLoading}
                  />
                </div>
              </div>
            </div>
            <div className='dialog-action'>
              <Button3D
                text={'CONFERMO QUESTA CONFIGURAZIONE'}
                normalWeightText
                onClick={!isLoading ? handleConfirm : null}
              />
              <div className='dialog-action-cancel' onClick={!isLoading ? handleClose : null}>
                Rivedi le scelte di questa configurazione
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default AreYouSureConfirmationDialog;
