import * as React from 'react';

function SvgPuntoAcqua(props) {
  return (
    <svg viewBox='0 0 104 127' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M73.09 29.4H56.44c-2.78 0-4.91-2.02-4.54-4.3l.24-1.46L2.55 12.56c-1.02-.22-1.69-1.14-1.53-2.1l.1-.64C2.13 3.41 8.52-.92 15.41.17l40.1 6.79c.84-.9 2.14-1.46 3.6-1.46h11.32c2.29 0 4.23 1.4 4.54 3.28l2.67 16.32c.37 2.28-1.77 4.3-4.55 4.3zM73.941 35.69v2.66l1.66.29c1.13.2 2.04 1.09 2.25 2.22l.3 1.64h14.08v18.41h-14.08l-.3 1.64a2.766 2.766 0 01-2.25 2.22l-1.66.29v2.66h-17.55v-7.54l-1.88-.11c-.37-.02-.77-.03-1.18-.03-2.11 0-7.39.32-10.69 3.24-2.54 2.25-3.83 6.06-3.83 11.33V86h-17V74.61c0-13.02 5.31-20.36 9.76-24.23 7.35-6.39 16.74-7.35 21.67-7.35.38 0 .75 0 1.09.01l2.05.06v-7.41H73.941zm1.99-2h-21.55v7.35c-.36 0-.74-.02-1.14-.02-5.68 0-15.29 1.15-22.99 7.84-4.77 4.14-10.45 11.96-10.45 25.74v13.39h21V74.6c0-4.67 1.06-7.97 3.16-9.83 2.53-2.24 6.75-2.74 9.36-2.74.39 0 .75.01 1.06.03v7.66h21.55v-2.98a4.78 4.78 0 003.87-3.83h14.42V40.5h-14.42a4.78 4.78 0 00-3.87-3.83v-2.98zM98.94 41.65a1.15 1.15 0 10-2.3 0v20.11a1.15 1.15 0 002.3 0V41.65zM103.659 41.65a1.15 1.15 0 10-2.3 0v20.11a1.15 1.15 0 002.3 0V41.65zM31.36 93.39a.988.988 0 00-.78-.39c-.17 0-.53.05-.78.39-7.39 9.29-11.8 17.14-11.8 20.99 0 6.94 5.65 12.59 12.59 12.59 6.94 0 12.59-5.65 12.59-12.59 0-3.85-4.41-11.7-11.8-20.99h-.02zm-1.73 30.56c-.77 0-1.4-.64-1.4-1.41 0-.77.63-1.41 1.4-1.41 4.58 0 8.3-3.72 8.3-8.3 0-.78.63-1.41 1.41-1.41.78 0 1.41.63 1.41 1.41 0 6.13-5 11.13-11.13 11.13l.01-.01z'
        fill='#5E5E5E'
      />
    </svg>
  );
}

export default SvgPuntoAcqua;
