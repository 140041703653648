import * as React from "react";

function SvgAlertOutlined(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.293 27.4l1.712 1.713v.287l-1.712 1.7h-.287l-1.702-1.7v-.287l1.702-1.712h.287zm1.127-16.65l.149.138v3.275L20.994 25.7l-.138.138h-1.414l-.149-.139-.563-11.536v-3.275l.138-.138h2.552z"
        fill={props.color}
      />
      <path
        d="M20.154 40a20 20 0 1120-20 20.021 20.021 0 01-20 20zm0-37.873A17.873 17.873 0 1038.028 20 17.895 17.895 0 0020.155 2.127z"
        fill={props.color}
      />
    </svg>
  );
}

export default SvgAlertOutlined;
