import * as React from "react";

function SvgPrint(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 28 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.5 26.9v-4.4H3.4C1.5 22.5 0 21 0 19.1v-6.6c0-2 1.5-3.6 3.5-3.6h1V0h18v8.9h1.2c1.8 0 3.3 1.5 3.3 3.3v6.7c.1 1.9-1.4 3.4-3.3 3.5h-1.2v4.5h-18zm18-6.6c.5 0 .8 0 1.2-.1.6 0 1.1-.5 1.1-1.1V19v-6.3c0-1.2-.4-1.5-1.6-1.5H3.6c-1 0-1.4.4-1.5 1.4v5.8c0 1.7.6 2.1 2.3 1.7v-4.4h18v4.6h.1zM20.2 2.2H6.8v6.7h13.4V2.2zM6.8 24.7h13.4V18H6.8v6.7z"
        fill={props.color}
      />
    </svg>
  );
}

export default SvgPrint;
