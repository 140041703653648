import { Tabs } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const styles = {
  optionsTabsIndicator: {
    bottom: 'unset',
    top: '0',
    backgroundColor: '#F08219',
    height: '2px',
  },
  optionsTabsRoot: {
    minHeight: 'unset',
    marginBottom: '-1px',
    backgroundColor: '#FFF',
  },
};

const OptionsTabs = (props) => {
  const { children, classes, ...others } = props;
  return (
    <Tabs
      classes={{
        root: classes.optionsTabsRoot,
        indicator: classes.optionsTabsIndicator,
      }}
      {...others}
    >
      {children}
    </Tabs>
  );
};

export default withStyles(styles)(OptionsTabs);
