import * as React from 'react';

function SvgCucina(props) {
  return (
    <svg viewBox='0 0 44 42' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#cucina_svg__clip0_2448_23009)'>
        <path
          d='M43.17 9.48a9.41 9.41 0 00-9.08-6.77 11.26 11.26 0 00-3.9.7 12.15 12.15 0 00-16.38-.43l-.5.49A9.8 9.8 0 00.8 8.16a8.86 8.86 0 00-.2 6.87 9.06 9.06 0 004.911 5h.09a.42.42 0 00.15.06c.402.162.812.302 1.23.42l2.32 13v6.72a.94.94 0 00.95.92h23a.89.89 0 00.94-.92v-6.67l2.32-13.07a9.33 9.33 0 005.71-4.19 8.648 8.648 0 001-6.63.31.31 0 00-.05-.19zm-10.87 25v4.94h-21v-4.94h21zm-3.099-10.39v-.07a.928.928 0 00-.94-.92.92.92 0 00-.94.83l-.68 8.64h-3.85v-7.19a.93.93 0 00-.94-.92.9.9 0 00-.95.92v7.2h-3.88l-.68-8.65a.999.999 0 00-.41-.67.82.82 0 00-.6-.15.93.93 0 00-.87.93l.67 8.61h-4l-2.32-13a.999.999 0 00-.76-.73 7.36 7.36 0 01-4.8-3.08 7.13 7.13 0 01-1.16-5.4 7.63 7.63 0 019-5.59 7.44 7.44 0 016.1 7.08.929.929 0 00.94.92.902.902 0 00.95-.92 8.9 8.9 0 00-4.07-7.43 10.27 10.27 0 0113.76 0 9.93 9.93 0 012.49 4 .82.82 0 00.4.59 1 1 0 00.79.09.919.919 0 00.62-.47.76.76 0 00.06-.65 10.15 10.15 0 00-1.57-3.16A8.12 8.12 0 0134 4.55a7.43 7.43 0 017.65 7.2 7.26 7.26 0 01-6.1 7 .999.999 0 00-.74.74l-2.32 13h-3.88l.59-8.4z'
          fill={props.color}
        />
      </g>
      <defs>
        <clipPath id='cucina_svg__clip0_2448_23009'>
          <path fill={props.color} d='M0 0h43.52v41.21H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgCucina;
