import API from './api';

export const getConfigurationList = async () => {
  return await API.get(`/External/Configuration`);
};

export const getConfiguration = async (configurationId) => {
  return await API.get(`/External/Configuration/${configurationId}`);
};

export const getConfigurationDetail = async (configurationId) => {
  return await API.get(`/External/Configuration/${configurationId}/Detail`);
};

export const newConfiguration = async (name, apartmentId) => {
  return await API.post(`/External/Configuration`, { name, realApartmentId: apartmentId });
};

export const updateConfiguration = async (configurationId, body) => {
  return await API.put(`/External/Configuration/${configurationId}`, body);
};

export const deleteConfiguration = async (configurationId) => {
  return await API.delete(`/External/Configuration/${configurationId}`);
};

export const getConfigurationPercentage = async (configurationId) => {
  return await API.get(`/External/Configuration/${configurationId}/AnsweredPercentage`);
};

export const saveConfigurationLayout = async (configurationId, layoutId) => {
  return await API.post(`/External/Configuration/${configurationId}/SetLayout`, { layoutId });
};

export const duplicateConfiguration = async (configurationId) => {
  return await API.post(`/External/Configuration/${configurationId}/Copy`);
};

export const confirmConfiguration = async (configurationId) => {
  return await API.post(`/External/Configuration/${configurationId}/Confirm`);
};
