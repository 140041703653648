import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  columnTitle: {
    fontSize: '15px',
    lineHeight: '18px',
    textTransform: 'uppercase',
    '& .bold': {
      fontWeight: '600',
    },
    '&.hide': {
      background: '#EEEEEE',
      '& > div': {
        visibility: 'hidden',
      },
    },
  },
}));

const ConfiguratorColumnTitle = ({ firstRow, secondRow, hide }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.columnTitle, hide && 'hide')}>
      <div>{firstRow}</div>
      <div className={'bold'}>{secondRow}</div>
    </div>
  );
};

export default ConfiguratorColumnTitle;
