import { useEffect, useState, useRef } from 'react';
import GridDetailsContainer from '../../../components/Card/GridDetailsContainer';
import MaterialDataSheet from '../../../components/MaterialDataSheet';
import ConfiguratorThreeColumnsLayout from '../../../layouts/ConfiguratorThreeColumnsLayout';
import { makeStyles } from '@material-ui/core';
import { resumeAnswerSimple } from '../../../utils/configuratorUtils';
import { getPackages } from '../../../services/packageApi';
import { useStore } from '../../../store/storeUtils';
import { useParams } from 'react-router';
import { handleSave, singleChoiceSelection } from '../../../utils/selectionUtils';
import _ from 'lodash';
import LoadingAnimation from '../../../components/LoadingAnimation';
import ConfiguratorTwoColumnsLayout from '../../../layouts/ConfiguratorTwoColumnsLayout';
import BigBox from '../giornoNotte/config/BigBox';
import { Alert } from '../../../components/Icons';
import BoxDocciaCardContainer from '../../../components/Card/BoxDocciaCardContainer';
import {
  materialDataSheetOptionsConfiguration,
  packagesRestructuringClassicConfiguration,
} from '../../../configs/bagnoPhasesConfigurations';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '15px',
    textAlign: 'left',
    height: '5%',
  },
  subTitle: {
    fontSize: '11px',
    textAlign: 'left',
    height: '5%',
  },
  titleRelazione: {
    fontSize: '15px',
    textAlign: 'center',
    padding: '10px',
  },
  secondaryText: {
    color: theme.palette.color.orange,
  },
  icon: {
    marginTop: '25px',
    borderRadius: '50%',
    border: '1px solid' + theme.palette.color.orange,
  },
}));

const FirstColumn = ({
  options,
  selectedOptions,
  selectedPavimentoBagnoImage,
  selectedPavimentoBagnoTitle,
  selectedPareteBagnoImage,
  selectedPareteBagnoTitle,
}) => {
  // const optionSelected = options?.find(option => option?.id === selectedOptions?.[0]);
  // const selectedVirtualImage = optionSelected?.virtualImage?.replace(/\s/g, "%20");
  const materialObject = materialDataSheetOptionsConfiguration(options, selectedOptions);
  const optionSelected = options?.find((option) => option?.id === selectedOptions?.[0]);
  const isNoChoice = optionSelected?.title?.toLowerCase() === 'Nessuno'.toLowerCase();
  return (
    <BigBox
      defaultImage={`${process.env.PUBLIC_URL}/assets/images/Bagno.jpg`}
      selectedVirtualImage={materialObject?.image}
      isNoChoice={isNoChoice}
      selectedPavimentoBagnoImage={selectedPavimentoBagnoImage}
      selectedPavimentoBagnoTitle={selectedPavimentoBagnoTitle}
      selectedPareteBagnoImage={selectedPareteBagnoImage}
      selectedPareteBagnoTitle={selectedPareteBagnoTitle}
    />
  );
};

/*
const FirstColumnRelazione = ({ options, selectedOptions }) => {
  const selectedVirtualImage = options
    ?.find((option) => option.id === selectedOptions?.[0])
    ?.virtualImage?.replace(/\s/g, '%20');
  return (
    <BigBox
      defaultImage={`${process.env.PUBLIC_URL}/assets/images/Bagno.jpg`}
      selectedVirtualImage={selectedVirtualImage}
    />
  );
};
*/

const SecondColumn = ({ options, selectedOptions }) => {
  const materialObject = materialDataSheetOptionsConfiguration(options, selectedOptions);
  const selectedOption = options?.find((option) => option.id === selectedOptions?.[0]);
  const isNoChoice = selectedOption?.title.toLowerCase() === 'Nessuno'.toLowerCase();
  return (
    <MaterialDataSheet
      firstRowTitle={'PREVIEW'}
      secondRowTitle={'PRODOTTO'}
      previewBoxText={'una FINITURA'}
      material={materialObject}
      noImageLayout={true}
      isNoChoice={isNoChoice}
    />
  );
};

/*
const SecondColumnRelazione = ({ options, selectedOptions, handleUserSelection }) => {
  const classes = useStyles();
  return (
    <>
      <div style={{ height: '100%', backgroundColor: '#F0EDEB' }}>
        <div className={classes.titleRelazione}>
          <Alert
            reversed={true}
            color='#F0EDEB'
            height='30px'
            width='30px'
            className={classes.icon}
          />
          <span className={classes.primaryText}>
            <br />
            Nello step precedente hai selezionato nessun box doccia
          </span>
        </div>
        <BoxDocciaCardContainer
          options={options}
          selectedOptions={selectedOptions}
          handleUserSelection={handleUserSelection}
        />
      </div>
    </>
  );
};
*/

const ThirdColumn = ({ options, selectedOptions, handleUserSelection }) => {
  const classes = useStyles();
  const containers = [
    {
      title: 'Finitura',
      content: options,
    },
  ];
  return (
    <>
      <div className={classes.title}>
        <span>
          Quale <span className={classes.secondaryText}>finitura </span> preferisci per il vetro del box doccia ?
        </span>
      </div>
      <div className={classes.subTitle}>
        <span>
          La finitura del profilo è abbinata alla finitura scelta per la rubinetteria.
        </span>
      </div>
      <div style={{ height: '90%' }}>
        <GridDetailsContainer
          containers={containers}
          onClick={handleUserSelection}
          selectedOptions={selectedOptions}
          numCols={1}
        />
      </div>
    </>
  );
};

const AccessoriDocciaFinituraVetro = () => {
  const [packages, setPackages] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [selectedPavimentoBagnoImage, setselectedPavimentoBagnoImage] = useState(null);
  const [selectedPavimentoBagnoTitle, setSelectedPavimentoBagnoTitle] = useState(null);
  const [selectedPareteBagnoImage, setSelectedPareteBagnoImage] = useState(null);
  const [selectedPareteBagnoTitle, setSelectedPareteBagnoTitle] = useState(null);

  const substepIdRef = useRef();
  const oldAnswersRef = useRef();

  const store = useStore();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const configurationId = params.configuration;
  const currentRoomId = store.currentRoomId;

  const settingFiniture = () => {
    const pavimentoBagnoDes = store.selectedPavimentiBagno?.find(room => room.roomId === currentRoomId)?.pavimentoBagnoDes;
    const pareteBagnoDes = store.selectedPavimentiBagno?.find(room => room.roomId === currentRoomId)?.pareteBagnoDes;
    const pavimentoBagnoImg = store.selectedPavimentiBagno?.find(room => room.roomId === currentRoomId)?.pavimentoBagnoImg;
    const pareteBagnoImg = store.selectedPavimentiBagno?.find(room => room.roomId === currentRoomId)?.pareteBagnoImg;
    if(pareteBagnoDes !== ''){
      setSelectedPareteBagnoTitle(pareteBagnoDes);
      setSelectedPareteBagnoImage(pareteBagnoImg);
    }
    if(pavimentoBagnoDes !== ''){
      setSelectedPavimentoBagnoTitle(pavimentoBagnoDes);
      setselectedPavimentoBagnoImage(pavimentoBagnoImg);
    }
  }

  useEffect(() => {
    setIsLoading(true);
    store.setUserCanGoNext(false);
    prepareOptions();
    settingFiniture();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoomId]);

  const prepareOptions = async () => {
    const substepId = store.currentSubstepId;
    const packages = await getPackages(configurationId, substepId, currentRoomId);
    substepIdRef.current = store.currentSubstepId;
    const packagesRestruct = packagesRestructuringClassicConfiguration(packages, false, false, 'AccessoriDocciaFinitura');
    setPackages(packagesRestruct);
    resumeAnswerSimple(
      store,
      oldAnswersRef,
      substepIdRef,
      setSelectedPackages,
      setIsLoading,
      currentRoomId,
    );
  };

  useEffect(() => {
    const save = async () => {
      await handleSave(
        null,
        configurationId,
        substepIdRef.current,
        store,
        selectedPackages?.[0],
        currentRoomId,
      );
    };
    if (!_.isEqual(oldAnswersRef.current, selectedPackages)) {
      store.setSubstepSave(save);
    } else {
      store.setSubstepSave(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPackages]);

  const handleUserSelection = (selection) => {
    singleChoiceSelection([selection], [selectedPackages], setSelectedPackages, store);
  };

  return isLoading ? (
    <LoadingAnimation />
  ) : (
    <ConfiguratorThreeColumnsLayout
      firstRowTitle='CONFIGURA'
      secondRowTitle='LA FINITURA DEL BOX DOCCIA'
      firstColumn={
        <FirstColumn
          options={packages}
          selectedOptions={selectedPackages}
          selectedPavimentoBagnoImage={selectedPavimentoBagnoImage}
          selectedPavimentoBagnoTitle={selectedPavimentoBagnoTitle}
          selectedPareteBagnoImage={selectedPareteBagnoImage}
          selectedPareteBagnoTitle={selectedPareteBagnoTitle}
        />
      }
      secondColumn={<SecondColumn options={packages} selectedOptions={selectedPackages} />}
      thirdColumn={
        <ThirdColumn
          options={packages}
          selectedOptions={selectedPackages}
          handleUserSelection={handleUserSelection}
        />
      }
    />
  )
};

export default AccessoriDocciaFinituraVetro;
