import * as React from 'react';

function SvgGiornoIcon(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 103 78'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M51.9 78c28.167 0 51-2.686 51-6s-22.833-6-51-6c-28.166 0-51 2.686-51 6s22.834 6 51 6z'
        fill='#EEE'
      />
      <path
        d='M81.8 53.9H27.9V0h53.8v53.9h.1zm-14-1.5h12.4v-12l-12.4 12zm-15.3 0h13.2l2.2-2.2-6.4-6.6-9 8.8zm-13.7 0h11.6l17.8-17.3-5.6-5.7-23.8 23zm-9.4 0h7.2l6-5.8-6.4-6.5-6.5 6.3-.3-.3v6.3zm33.2-9.8l6.4 6.6 11.3-10.9V25.5L62.6 42.6zM37.3 39l6.4 6.5 30.1-29.1-6.4-6.5L37.3 39zm-7.9-8v13.5l24.3-23.4-6.8-7L29.4 31zm34.3-2.7l5.6 5.7 11-10.6V12.3l-16.6 16zm-34.3-13v13.6l8-7.8-6.8-7-1.2 1.2zm2.3-2.2l6.8 7L57.8 1.5H44.1L31.7 13.1zm16.3 0l6.8 6.9L73.9 1.5h-14L48 13.1zm20.5-4.3l6.4 6.5 5.4-5.2V1.4h-4.2l-7.6 7.4zM29.4 1.5v11.7L41.9 1.5H29.4z'
        fill={props.color}
      />
    </svg>
  );
}

export default SvgGiornoIcon;
