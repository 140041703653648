import * as React from 'react';

function SvgChiusuraArmadio(props) {
  return (
    <svg viewBox='0 0 86 74' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#chiusura-armadio_svg__clip0_2723_23217)'>
        <path d='M44.928 29.41v42.02h-31.42V12.67h31.42v16.74z' fill='#D3D3D3' />
        <path d='M39.97 43.05v-2H32.11v2h7.86z' fill='#5B5B5B' />
        <path
          d='M79.718 71.43V10.67h-68.21v60.76h-4.11v2h39.53V61.76c1.75.36 4.03.61 6.94.61 6.99 0 10.69-1.46 12.1-2.18.41-.21.61-.69.51-1.13-1.96-8.67-2.4-14.78-2.48-17.62-.02-.48.61-.66.86-.26a39.3 39.3 0 001.39 2.09c.16.21.39.37.65.4 2.22.29 4.16-1.47 5.01-2.41.29-.31.34-.77.14-1.15-.49-.89-1.48-2.74-2.75-5.29-1.83-3.66-4.24-5.26-6.35-5.51-1.5-.18-2.53-.76-3.21-1.36-.39-.35-.99-.29-1.37.07-.71.67-1.99 1.44-4.11 1.44s-3.42-.8-4.16-1.5c-.38-.36-.98-.41-1.37-.06-.44.4-1.03.79-1.8 1.07v-16.3h30.79v60.76h7.88v-2h-5.88zm-34.79-42.02v42.02h-31.42V12.67h31.42v16.74z'
          fill='#5B5B5B'
        />
        <path
          d='M13.51 27.02c7.46 0 13.51-6.05 13.51-13.51S20.97 0 13.51 0 0 6.05 0 13.51s6.05 13.51 13.51 13.51z'
          fill='#76B82D'
        />
        <path
          d='M11 17.64c.09-.09.14-.13.19-.18l8.07-8.07c.04-.04.07-.08.11-.12.37-.36.81-.36 1.16-.02.34.34.34.79-.03 1.15-1.69 1.7-3.39 3.39-5.08 5.09l-3.74 3.74c-.39.39-.87.4-1.26 0l-3.75-3.75c-.21-.21-.34-.46-.25-.77.09-.32.31-.53.63-.61.32-.08.56.06.78.28l3.06 3.06c.05.05.08.12.13.19'
          fill='#fff'
        />
      </g>
      <defs>
        <clipPath id='chiusura-armadio_svg__clip0_2723_23217'>
          <path fill='#fff' d='M0 0h85.59v73.43H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgChiusuraArmadio;
