import * as React from 'react';

function SvgDigitalSign(props) {
  return (
    <svg viewBox='0 0 56 47' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#digital-sign_svg__clip0_3013_23639)' fill='#CECECE'>
        <path d='M41.343 17.945c-.75 2.022-1.555 4.072-2.278 6.12-.911 2.47-1.286 5.098-1.286 7.725 0 1.34-.027 2.706.536 3.993.08.21-.054.368-.107.552-.134.446-.402.893-.43 1.34-.026.551.296.919.831 1.05.456.08.911-.157 1.126-.683.321-.84.723-1.55 1.5-2.18a18.75 18.75 0 005.708-7.908c1.313-3.337 4.662-11.822 7.26-18.469l-8.305-3.047c-1.85 4.65-3.886 9.746-4.556 11.507zM53.107.263c-2.25-.814-4.77.263-5.654 2.47-.268.71-.589 1.497-.937 2.338l8.28 3.048c.347-.815.642-1.577.91-2.26.214-.499.295-1.024.295-1.55C56 2.575 54.902.92 53.107.263zM36.065 39.906c-.644-.367-1.447-.131-1.823.5-.857 1.47-2.545 2.47-4.206 2.47h-.107c-1.715-.053-3.403-1.104-4.207-2.68-.536-1.025-1.313-2.26-2.492-2.575a2.155 2.155 0 00-1.742.262c-.696.42-.964 1.104-1.125 1.498-.348.867-.67 1.76-.965 2.653a8.951 8.951 0 01-2.063-4.702l-.402-3.284-2.01 2.653a18.324 18.324 0 00-3.268 7.094c-1.34-1.025-1.93-3.442-2.01-5.255 2.358-1.024 4.073-2.942 5.225-4.387 2.412-2.995 3.564-5.754 3.483-8.433-.053-1.892-.99-4.02-2.974-4.545-1.956-.5-3.912.788-5.251 3.389a28.767 28.767 0 00-3.135 11.98c-1.5.157-3.189-.394-3.885-1.787-.536-1.103-.402-2.575-.242-3.862.536-3.914 1.5-7.75 2.867-11.428a1.31 1.31 0 00-.803-1.681c-.697-.237-1.474.105-1.715.788a55.725 55.725 0 00-3.001 11.98c-.215 1.681-.402 3.599.482 5.36 1.206 2.442 3.912 3.414 6.323 3.257.295 3.678 2.064 7.303 5.547 7.671l1.313.132.16-1.288c.242-1.734.75-3.415 1.528-4.992a11.575 11.575 0 003.751 4.756l1.608 1.182.482-1.918c.375-1.471.857-2.89 1.42-4.282.134.184.322.473.563.92 1.26 2.443 3.805 4.045 6.51 4.124 2.734.053 5.36-1.419 6.726-3.783a1.285 1.285 0 00-.509-1.787h-.053zm-23.58-14.16c.536-1.025 1.367-2.05 2.037-2.05h.16c.537.132.965 1.025.965 2.05.054 2.023-.91 4.23-2.92 6.752-1.072 1.313-2.063 2.285-3.055 2.969.295-3.39 1.233-6.7 2.787-9.721h.027z' />
      </g>
      <defs>
        <clipPath id='digital-sign_svg__clip0_3013_23639'>
          <path fill='#fff' d='M0 0h56v47H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgDigitalSign;
