import { Accordion, AccordionDetails, AccordionSummary, Grid, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useState } from 'react';
import React from 'react';
import BigTab from '../components/Tabs/BigTab';
import BigTabPanel from '../components/Tabs/BigTabPanel';
import BigTabs from '../components/Tabs/BigTabs';
import faqConfigurations from '../configs/faqConfigurations';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Button3D from '../components/Buttons/Button3D';
import NewTicketRequest from '../components/dialogs/NewTicketRequest';
import { useStore } from '../store/storeUtils';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  faqRoot: {
    height: '100%',
    maxWidth: '1280px',
    margin: 'auto',
    overflow: 'hidden',
    '& .faq-body': { overflow: 'hidden' },
  },
  faqTitle: {
    paddingTop: '8px',
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontSize: '32px',
    fontFamily: 'miller',
    fontStyle: 'italic',
  },
  faqSubtitle: {
    fontSize: '16px',
    paddingTop: '8px',
    textAlign: 'center',
    marginBottom: '32px',
  },
  tabSubtitle: {
    textTransform: 'uppercase',
    lineHeight: 1.2,

    '&.bold': {
      fontWeight: 800,
    },
  },
  accordionsRoot: {
    fontSize: '16px',
    marginTop: '16px',
    '& .MuiAccordion-root': {
      boxShadow: 'none',
      marginBottom: '16px',
      border: `1px solid ${theme.palette.color.grey2}`,
    },
    '& .MuiAccordionSummary-root': {
      '&.Mui-expanded': {
        color: theme.palette.primary.main,
        fontWeight: 800,
        minHeight: 'unset',
      },
    },
    '& .MuiAccordionSummary-content': {
      alignItems: 'center',
      '& svg': {
        marginRight: '8px',
      },
      '&.Mui-expanded': {
        margin: 0,
      },
    },

    '& .MuiAccordionSummary-expandIcon': { color: 'inherit' },
    '& .MuiAccordionDetails-root': {
      padding: '8px 16px 16px',
      '& > div': {
        backgroundColor: 'rgba(196, 196, 196, 0.2)',
        padding: '16px',
      },
    },
  },
  footerFirstRow: {
    paddingTop: '32px',
    fontFamily: 'miller',
    fontStyle: 'italic',
  },
  customButton: {
    marginLeft: '15px',
    borderRadius: '4px',
  },
  footerSecondRow: {
    fontFamily: 'miller',
    fontStyle: 'italic',
    color: theme.palette.primary.main,
    display: 'flex',
    '& .icon': {
      color: '#000',
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: '100%',
      height: '24px',
      width: '24px',
      marginRight: '8px',
      flexShrink: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > svg': {
        width: '70%',
      },
    },
  },
}));

const FaqPage = () => {

  const store = useStore();

  const classes = useStyles();

  const [currentTab, setValue] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [ticketFormOpen, setTicketFormOpen] = useState(false);
  const [succeded, setSucceded] = useState(false);

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
    <Grid container wrap='nowrap' direction='column' className={classes.faqRoot}>
      <Grid item xs='auto'>
        <div className={classes.faqTitle}>Ascoltiamo e rispondiamo alle tue domande.</div>
        <div className={classes.faqSubtitle}>Cerca tra le domande più frequenti.</div>
      </Grid>
      <Grid item xs className={'faq-body'}>
        <BigTabs
          value={currentTab}
          aria-label='tabs'
          variant={'scrollable'}
          scrollButtons='auto'
          onChange={handleChange}
        >
          {faqConfigurations.map((faq, i) => (
            <BigTab key={i} label={`${faq.tabLabel}`} value={i} />
          ))}
        </BigTabs>

        {faqConfigurations.map((faq, i) => (
          <BigTabPanel value={currentTab} index={i} key={i}>
            {faq.subtitle && <div className={classes.tabSubtitle}>{faq.subtitle}</div>}
            {faq.boldSubtitle && (
              <div className={clsx(classes.tabSubtitle, 'bold')}>{faq.boldSubtitle}</div>
            )}
            <div className={classes.accordionsRoot}>
              {faq.questions.map((question, i) => (
                <Accordion
                  key={i}
                  square
                  expanded={expanded == i}
                  onChange={handleChangeAccordion(i)}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <HelpOutlineIcon />
                    {question.title}
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>{question.answer}</div>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
            <div className={classes.footerFirstRow}>
              Se non hai trovato quello che cerchi apri un ticket con la tua richiesta 
              <Button3D
                text={'nuovo ticket'}
                icon={<MailOutlineIcon style={{marginLeft: '5px'}}/>}
                className={classes.customButton}
                color={'orange'}
                onClick={() => setTicketFormOpen(true)}
              />
            </div>
            {
              /*
              <div className={classes.footerFirstRow}>Non hai trovato risposta alla tua domanda?</div>
            <div className={classes.footerSecondRow}>
              <div className={'icon'}>
                <MailOutlineIcon />
              </div>
              <Button3D
                text={'prova'}
                icon={<MailOutlineIcon />}
                reverseTextStyle={true}
              >

              </Button3D>
              <div>
                {`${faq.footerMessage} Se hai un'altra domanda puoi scrivere al nostro Servizio Clienti all'indirizzo email servizio.clienti@portanavigliogrande.com. L'indirizzo è monitorato dal lunedì al venerdì dalle 09:00 alle 18:00. Risponderemo alla tua richiesta entro tre giorni lavorativi.`}
              </div>
            </div>
              */
            }
          </BigTabPanel>
        ))}
      </Grid>
    </Grid>
    <NewTicketRequest
      projectShortName={store.currentProject.shortName}
      isOpen={ticketFormOpen}
      setSucceded={setSucceded}
      setIsOpen={setTicketFormOpen}
      // isLoading={resumePdfLoading}
      // configurationName={store.currentConfiguration?.name}
      handleClose={() => setTicketFormOpen(false)}
      // handleDownload={!layoutLoading && !answersLoading && handleDownloadConfigurationPdf}
      // handleConfirm={() => setTicketFormOpen(true)}
    />
     <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={succeded}
        autoHideDuration={6000}
        onClose={()=> { setSucceded(false)}}
        message="Il ticket è stato inviato con successo!"
      >
        <Alert severity="success">Il ticket è stato inviato con successo!</Alert>
      </Snackbar>
  </>
  );
};

export default FaqPage;
