import BoxAPI from './api';

export const createFolder = async (folderName, parentId) => {
    let data = JSON.stringify({
        "name": folderName,
        "parent": {
          "id": parentId
        }
      });
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://api.box.com/2.0/folders',
        headers: { 
            'Content-Type': 'application/json, application/json'
        },
        data : data
      };
    try {
        const response = await BoxAPI.request(config);
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
};