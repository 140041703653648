import { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import StyleText from '../utils/StyleText';
import IconRound3DButton from './Buttons/IconRound3DButton';
import { Minus, Plus, Reset } from './Icons';
import SvgPanelControl from './Icons/PanelControl';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    position: 'relative',
    width: '231px',
    height: '63px',
    padding: '9px 9px',
    borderRadius: '5px',
    userSelect: 'none',
  },
  background: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  title: {
    fontSize: '10px',
    lineHeight: '12px',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#918B87',
    zIndex: 1,
  },
  button: {
    width: '45px',
    height: '45px',
  },
  buttonReset: {
    position: 'absolute',
    top: '-65%',
    left: '50%',
    transform: 'translate(-50%, 50%)',
    backgroundColor: 'transparent',
  },
}));

const ZoomControl = ({
  onZoomInClick,
  onZoomOutClick,
  onResetClick,
  disableReset,
  disableZoomIn,
  disableZoomOut,
}) => {
  const classes = useStyles();

  const [zoom, setZoom] = useState(0);
  const zoomControl = (control) => {
    if (control === 'plus') setZoom((zoom) => zoom + 1);
    if (control === 'minus') setZoom((zoom) => zoom - 1);
    if (control === 'reset') setZoom(0);
  };

  return (
    <div className={classes.root}>
      <SvgPanelControl className={classes.background} />
      <IconRound3DButton
        icon={<Minus color='#fff' width='9px' height='9px' />}
        disabled={disableZoomOut || zoom === 0}
        className={classes.button}
        onClick={() => {
          onZoomOutClick();
          zoomControl('minus');
        }}
      />

      <span className={classes.title}>{StyleText('Panel control')}</span>
      <IconRound3DButton
        icon={<Plus color='#fff' width='9px' height='9px' />}
        disabled={disableZoomIn}
        className={classes.button}
        onClick={() => {
          onZoomInClick();
          zoomControl('plus');
        }}
      />
      <IconRound3DButton
        color='orange'
        icon={<Reset color='#fff' width='20px' height='20px' />}
        disabled={disableReset || zoom === 0}
        className={clsx(classes.button, classes.buttonReset)}
        ì
        onClick={() => {
          onResetClick();
          zoomControl('reset');
        }}
      />
    </div>
  );
};

export default ZoomControl;
