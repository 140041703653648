import * as React from 'react';

function SvgIlluminazioneAntibagno(props) {
  return (
    <svg viewBox='0 0 59 55' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#illuminazione-antibagno_svg__clip0_2758_23990)' fill='#C4C4C4'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M1 12.12c-.55 0-1 .45-1 1v35.95c0 .55.45 1 1 1h6.78l16.4 4.71a1.004 1.004 0 00.55-1.93L8.19 48.1a.863.863 0 00-.28-.04H2V14.12h5.78l16.4 4.72c.53.15 1.08-.15 1.24-.68.15-.53-.15-1.08-.68-1.24L8.2 12.15a.863.863 0 00-.28-.04H1v.01zm19.98 19.81c.36 0 .69.19.86.5.17.29.33.55.48.79l.02.04c.14.23.29.46.42.68.28.5.5 1.05.5 1.75v.02c-.01.6-.26 1.17-.7 1.58-.43.41-1.01.64-1.61.63-.59 0-1.15-.24-1.57-.66-.42-.42-.66-.98-.67-1.57v-.02c0-.69.2-1.23.47-1.73.13-.24.28-.49.43-.73l.05-.09c.14-.22.28-.45.44-.72.18-.31.51-.5.87-.5l.01.03zm0 5l.02-1c.07 0 .14-.03.19-.08a.25.25 0 00.08-.18c0-.23-.07-.43-.25-.76l-.03-.06-.02.04c-.17.32-.23.53-.23.78 0 .07.03.13.08.17.05.05.11.07.18.08v1l-.02.01zM9.13 40.87c-.55 0-1 .45-1 1s.45 1 1 1h4.96s.09.01.14.01c.05 0 .1 0 .14-.01h15.36c.55 0 1-.45 1-1s-.45-1-1-1H15.24V26.64c0-.37.15-.72.4-.98s.61-.4.97-.4c.37 0 .72.15.97.41.26.26.41.61.41.98v1.45c0 .55.45 1 1 1s1-.45 1-1v-1.45a3.378 3.378 0 00-3.37-3.38 3.383 3.383 0 00-3.38 3.38v6.55h-1.87c-.21-.42-.55-.76-.97-.97v-1.17c0-.55-.45-1-1-1s-1 .45-1 1v1.17c-.42.21-.76.55-.97.97H6.35c-.55 0-1 .45-1 1s.45 1 1 1h1.08c.21.42.55.76.97.97v1.18c0 .55.45 1 1 1s1-.45 1-1v-1.18c.42-.21.76-.55.97-.97h1.87v5.68h-4.1l-.01-.01zm26.36-.56v7.49h-5.06c-.56 0-1 .44-1 1s.44 1 1 1h6.06c.55 0 1-.45 1-1v-8.49h-2z'
        />
        <path d='M44.13 33.93h-8.06c-.69 0-1.25.57-1.25 1.26 0 .69.56 1.26 1.25 1.26h8.06c.69 0 1.25-.56 1.25-1.26s-.56-1.26-1.25-1.26zM47.61 10.97c-.34-.33-.71-.64-1.09-.91-1.83-1.35-4.06-2.07-6.39-2.07-2.83 0-5.49 1.05-7.49 2.98-2.01 1.93-3.12 4.5-3.12 7.24 0 2.54.97 4.97 2.72 6.84 1.78 2.11 2.61 6.05 2.61 6.08l.17.82h10.3l.02-.99c.07-2.88 2.81-6.06 2.93-6.19 1.59-1.84 2.47-4.17 2.47-6.56 0-1.62-.39-3.18-1.12-4.58-.51-.98-1.18-1.87-2.01-2.66zm-.88 12.46c-.29.33-2.8 3.29-3.33 6.48h-6.74c-.35-1.34-1.26-4.28-2.91-6.23a7.95 7.95 0 01-2.2-5.47c0-4.51 3.85-8.19 8.58-8.19s8.57 3.68 8.57 8.19c0 1.9-.7 3.76-1.97 5.22zM41.258 0h-2.27v4.64h2.27V0zM52.429 3.933l-2.98 3.557 1.74 1.458 2.98-3.557-1.74-1.458zM58.5 16.24h-4.64v2.27h4.64v-2.27z' />
        <path d='M40.22 23.52l-2.92-2.91-1.41 1.41 4.33 4.33 4.09-4.1-1.41-1.41-2.68 2.68z' />
      </g>
      <defs>
        <clipPath id='illuminazione-antibagno_svg__clip0_2758_23990'>
          <path fill='#fff' d='M0 0h58.5v54.82H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIlluminazioneAntibagno;
