import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  makeStyles,
  Modal,
} from '@material-ui/core';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Button3D from '../components/Buttons/Button3D';
import ConfigurationCard from '../components/Card/ConfigurationCard';
import { CheckOk, Chevron } from '../components/Icons';
import InfoApartment from '../components/InfoApartment';
import TwoColumnsLayout from '../layouts/TwoColumnsLayout';
import AlertMessage from '../components/AlertMessage';
import { useTheme } from '@material-ui/styles';
import LoadingAnimation from '../components/LoadingAnimation';
import {
  deleteConfiguration,
  duplicateConfiguration,
  getConfigurationList,
  newConfiguration,
  updateConfiguration,
} from '../services/configurationApi';
import { useStore } from '../store/storeUtils';
import { observer } from 'mobx-react';
import InfoPopup, { WarningDeleteConfigurationContent } from '../components/InfoPopup';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    // height: '100%',
    minHeight: '100%',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    [theme.breakpoints.up('xl')]: {
      padding: '30px 130px',
    },
  },
  newConfButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    '&.direction': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  nextButton: {
    maxWidth: 'unset',
    height: '68px',
    whiteSpace: 'nowrap',
    paddingLeft: '110px',
    paddingRight: '110px',
    fontSize: '15px',
    lineHeight: '18px',
    letterSpacing: '0.1em',
    [theme.breakpoints.down('lg')]: {
      height: '60px',
      paddingLeft: '90px',
      paddingRight: '90px',
    },
    [theme.breakpoints.down('md')]: {
      height: '45px',
      paddingLeft: '65px',
      paddingRight: '65px',
    },
  },
  approvedMessage: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
    fontSize: '18px',
    lineHeight: '21px',
  },
  heading: {
    fontSize: '15px',
    lineHeight: '18px',
  },
  details: {
    padding: '40px 40px 70px 70px',
    [theme.breakpoints.down('lg')]: {
      padding: '15px',
    },
  },
  summary: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  alertMessage: {
    marginBottom: '20px',
  },
  moodMessage: {
    marginTop: 96,
    textAlign: 'center',
    fontSize: 18,
    color: '#646363',
    '& > div:first-child': {
      marginBottom: 16,
    },
  },
}));

const ConfigurationsList = ({
  configurations,
  handleNew,
  handleDelete,
  handleUpdate,
  handleDuplicate,
}) => {
  const classes = useStyles();
  const store = useStore();
  const { apartment } = useParams();

  const [apartmentConfigurations, setApartmentConfigurations] = useState([]);
  const [isOneConfirmed, setIsOneConfirmed] = useState(false);

  useEffect(() => {
    const apartmentConfigurations = configurations.filter((el) => el.realApartmentId == apartment);

    setApartmentConfigurations(apartmentConfigurations);
    if (apartmentConfigurations.some((el) => 
      el.confirmationDate || 
      store.configurationConfirmed.some((confirmed) => confirmed.apartment === el.realApartmentId && confirmed.configId === el.id)
    )) {
      setIsOneConfirmed(true);
      store.setIsOneConfigurationConfirmed(true);
      setApartmentConfigurations(apartmentConfigurations.filter((el) => el.confirmationDate || store.configurationConfirmed.some((confirmed) => confirmed.apartment === el.realApartmentId && confirmed.configId === el.id)));
    } else {
      store.setIsOneConfigurationConfirmed(false);
    }
  }, [configurations]);

  return (
    <>
      <Grid container direction={'column'}>
        {apartmentConfigurations.map((el, i) => (
          <Grid key={i} item>
            <ConfigurationCard
              configuration={el}
              {...el}
              apartment={apartment}
              index={i + 1}
              handleDelete={isOneConfirmed ? null : () => handleDelete(el.id)}
              handleUpdate={handleUpdate}
              handleDuplicate={() => handleDuplicate(el.id)}
              isConfigurationsFull={apartmentConfigurations.length >= 4}
              isConfirmed={el?.confirmationDate || store.configurationConfirmed.some((confirmed) => confirmed.apartment === el.realApartmentId && confirmed.configId === el.id)}
              editable={!isOneConfirmed}
            />
          </Grid>
        ))}
        {(process.env.REACT_APP_CONFIG_LIMIT == 0 ||
          apartmentConfigurations.length < process.env.REACT_APP_CONFIG_LIMIT * 1) &&
          !isOneConfirmed && (
            <Grid item className={clsx(classes.newConfButtonContainer)}>
              <Button3D
                className={clsx(classes.newConfButton)}
                text='Aggiungi nuova configurazione'
                onClick={handleNew}
              />
            </Grid>
          )}
      </Grid>
    </>
  );
};

const ConfigurationsSent = ({ configurations }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { apartment } = useParams();

  const partition = (array, condition) =>
    array.reduce(
      ([pass, fail], elem) => (condition(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]]),
      [[], []],
    );

  const [sentConfigurations, incompletedConfigurations] = partition(
    configurations,
    (config) => config.status === 'proposed' || config.status === 'approved',
  );

  const isConfigurationSent = configurations.some(
    (configuration) => configuration.status === 'proposed' || configuration.status === 'approved',
  );

  useEffect(() => {
    const parentElement = document.querySelector('#grid-container');
    let allChildren = parentElement.querySelectorAll('#grid-item');
    const buttonSection = allChildren[1];
    buttonSection.style.minHeight = `${buttonSection.clientHeight}px`;
  }, []);

  const handleAccordionChange = (e, expanded) => {
    const scrollTo = document.querySelector('#grid-container');
    if (expanded) {
      setTimeout(
        () => scrollTo.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' }),
        350,
      );
    }
  };

  return (
    <>
      <Grid
        container
        direction={'column'}
        // spacing={4}
        id='grid-container'
        className={classes.gridContainer}
      >
        {sentConfigurations.map((configuration, i) => (
          <Grid key={i} item id='grid-item'>
            <ConfigurationCard {...configuration} apartment={apartment} id={i} index={i + 1} />
          </Grid>
        ))}
        <Grid item xs className={clsx(classes.newConfButtonContainer, 'direction')} id='grid-item'>
          {configurations.some((configuration) => configuration.status === 'approved') && (
            <div className={classes.approvedMessage}>
              <CheckOk color={theme.palette.secondary.main} width='40px' height='40px' />
              <p>
                La tua configurazione é stata <strong>approvata</strong>
              </p>
            </div>
          )}
          <div className={classes.buttonContainer}>
            <Button3D
              className={clsx(classes.nextButton)}
              text='Start! Inizia la scelta degli arredi'
              normalWeightText
              disabled={
                !configurations.some((configuration) => configuration.status === 'approved')
              }
              disabledText='Conclusa la fase di valutazione potrai procedere con la scelta degli arredi'
            />
          </div>
        </Grid>
        <Grid item className={clsx(classes.accordion)} id='grid-item'>
          <Accordion onChange={handleAccordionChange} id='accordion'>
            <AccordionSummary
              expandIcon={<Chevron color='currentColor' width='15px' height='auto' />}
              aria-controls='panel1a-content'
              id='panel1a-header'
              classes={{ root: classes.summary }}
            >
              <span className={classes.heading}>Qui trovi tutte le tue configurazioni</span>
            </AccordionSummary>
            <AccordionDetails className={classes.details} id='accordion-details'>
              <Grid item>
                <AlertMessage
                  title='Attenzione'
                  subtitle='Hai già inviato una configurazione per l’approvazione.'
                  description='Le altre configurazioni sono congelate fino alla convalida di quella inviata.'
                  className={classes.alertMessage}
                />
              </Grid>
              {incompletedConfigurations.map((configuration, i) => (
                <Grid key={i} item>
                  <ConfigurationCard
                    {...configuration}
                    apartment={apartment}
                    id={i}
                    index={i + 1}
                    isConfigurationSent={isConfigurationSent}
                  />
                </Grid>
              ))}
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
};

const ConfigurationsPage = observer(() => {
  const [isLoading, setIsLoading] = useState(true);
  const store = useStore();
  const history = useHistory();
  const [configurations, setConfigurations] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const configurationToDeleteId = useRef(null);

  useEffect(() => {
    store.setCurrentConfiguration(null);

    setIsLoading(true);

    if (!store.currentApartment) {
      history.replace('/apartments');
    }

    prepareConfigurationsData();
  }, []);

  const prepareConfigurationsData = async () => {
    try {
      const configurationListData = await getConfigurationList();
      setConfigurations(configurationListData.data);
      store.setConfigurations(configurationListData.data);
    } catch (e) {
      console.log(e);
    }

    setIsLoading(false);
  };

  const handleNew = async () => {
    try {
      // Creo una nuova configurazione per l'appartamento
      const newConfigurationName = `Nuova Configurazione`;

      const newConfigurationResponse = await newConfiguration(
        newConfigurationName,
        store.currentApartment.id,
      );
      // recupero id configurazione
      const newConfigurationId = newConfigurationResponse.data.id;

      store.setCurrentConfiguration(newConfigurationResponse.data);

      history.push(`/apartment/${store.currentApartment.id}/${newConfigurationId}/configurator`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteButton = async (id) => {
    configurationToDeleteId.current = id;
    setShowConfirmModal(true);
  };

  const handleDeleteConfiguration = async () => {
    try {
      setIsLoading(true);
      setShowConfirmModal(false);

      await deleteConfiguration(configurationToDeleteId.current);
      await prepareConfigurationsData();
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const handleDuplicate = async (id) => {
    try {
      setIsLoading(true);
      await duplicateConfiguration(id);
      await prepareConfigurationsData();
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const handleUpdate = async (id, name) => {
    try {
      await updateConfiguration(id, { name, realApartmentId: store.currentApartment.id });
      await prepareConfigurationsData();
    } catch (e) {
      console.log(e);
    }
  };

  const isConfigurationSent = configurations.some(
    (configuration) => configuration.status === 'proposed' || configuration.status === 'approved',
  );

  return (
    <>
      <TwoColumnsLayout
        firstColumn={
          <InfoApartment
            firstTitleRow={'Le tue'}
            secondTitleRow={'configurazioni'}
            apartment={store.currentApartment}
            isConfigurationSent={isConfigurationSent}
            hideConfigurations
          />
        }
        secondColumn={
          isLoading ? (
            <LoadingAnimation msg='Caricamento in corso' />
          ) : isConfigurationSent ? (
            <ConfigurationsSent configurations={configurations} />
          ) : (
            <ConfigurationsList
              configurations={configurations}
              handleNew={handleNew}
              handleDelete={handleDeleteButton}
              handleUpdate={handleUpdate}
              handleDuplicate={handleDuplicate}
            />
          )
        }
      />
      <Modal open={showConfirmModal} onClose={() => setShowConfirmModal(false)}>
        <InfoPopup
          body={
            <WarningDeleteConfigurationContent
              handleSubmit={handleDeleteConfiguration}
              handleCancel={() => setShowConfirmModal(false)}
            />
          }
          position='center'
          isWarning
          handleClose={() => setShowConfirmModal(false)}
        />
      </Modal>
    </>
  );
});

export default ConfigurationsPage;
