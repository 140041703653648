import * as React from "react";

function SvgAdditionalPlug(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 91 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M44.972 40.37h-6.41V24.31h6.41v16.06zM32.152 6.72a25.64 25.64 0 1025.64 25.64 25.63 25.63 0 00-25.64-25.64zM57.102.31a7.14 7.14 0 017.1 7.12v49.88a7.13 7.13 0 01-7.1 7.1H7.222a7.14 7.14 0 01-7.12-7.1V7.43A7.15 7.15 0 017.222.31h49.88zm-31.36 24v16h-6.41v-16M77.52 65.671h5.71v1.91h-5.71v-1.91zm-6.66-15.22h19a1.1 1.1 0 01.95 1.23v10.87a1.09 1.09 0 01-.95 1.22h-19a1.088 1.088 0 01-1-1.22v-10.87a1.099 1.099 0 011-1.23zm15.24-11.41a.931.931 0 011 1v8.56h-2v-8.61a.93.93 0 011-1v.05zm-11.41-.05a.931.931 0 011 1v8.56h-1.9v-8.56a.93.93 0 01.95-1M64.203 80.9h10.47a5 5 0 004.75-4.76v-6.66h1.91v7.61c0 2.22-2.41 5.71-6.66 5.71h-10.47v-1.9z"
        fill={props.color}
      />
    </svg>
  );
}

export default SvgAdditionalPlug;
