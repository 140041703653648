import * as React from "react";

function SvgDesktopWindowCheck(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 97 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#desktop-window-check_svg__clip0)">
        <path
          d="M81.404 12.77H3.856a1.55 1.55 0 00-1.552 1.548v56.104a1.55 1.55 0 001.552 1.548h77.548a1.55 1.55 0 001.551-1.548V14.318a1.55 1.55 0 00-1.551-1.548z"
          stroke="#1D1D1B"
          strokeWidth={3.1}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M66.416 76.611H17.692c-.863 0-1.562.7-1.562 1.561v1.521c0 .863.7 1.561 1.562 1.561h48.724c.862 0 1.561-.698 1.561-1.56v-1.522c0-.862-.699-1.56-1.56-1.56z"
          fill="#1D1D1B"
        />
        <path
          d="M77.195 22.055H6.913v45.27h70.282v-45.27z"
          stroke="#C90000"
          strokeMiterlimit={10}
        />
        <path
          d="M69.13 59.201l4.608 4.643M73.738 60.361v3.483h-3.456"
          stroke="#C90000"
          strokeWidth={0.75}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M42.054 19.734c.954 0 1.728-.78 1.728-1.74 0-.962-.774-1.742-1.728-1.742-.955 0-1.728.78-1.728 1.741 0 .962.773 1.741 1.728 1.741z"
          fill="#1D1D1B"
        />
        <path d="M81 22a8 8 0 100-16 8 8 0 000 16z" fill="#76B82D" />
        <path
          d="M80.08 17.2c-.15 0-.3-.073-.412-.182l-2.66-2.644a.513.513 0 010-.76c.263-.218.6-.218.825 0l2.21 2.245 3.822-4.237a.556.556 0 01.786-.037.513.513 0 01.038.761l-4.196 4.672a.611.611 0 01-.412.181z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="desktop-window-check_svg__clip0">
          <path fill="#fff" d="M0 0h97v81H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgDesktopWindowCheck;
