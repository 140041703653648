import * as React from 'react';

function SvgCabinaArmadio(props) {
  return (
    <svg viewBox='0 0 56 41' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#cabinaArmadio_svg__clip0_2448_23026)'>
        <path
          d='M54.27 34.43l-24-17.34v-2.67a9.471 9.471 0 002.551-.82 7.09 7.09 0 003.8-6.8 7.09 7.09 0 00-7-6.74 7.81 7.81 0 00-8.68 6.45 1.152 1.152 0 00.13.92 1.177 1.177 0 00.8.52 1.14 1.14 0 00.95-.15 1.161 1.161 0 00.53-.81 5.34 5.34 0 016-4.49 4.7 4.7 0 014.74 4.33 4.85 4.85 0 01-2.45 4.61 7.25 7.25 0 01-2.64.72h-.93a.32.32 0 00-.32.32v5.68a.331.331 0 00.13.26l24.88 18a1.11 1.11 0 01.45 1.33 1.14 1.14 0 01-1.11.79H3.76a1.18 1.18 0 01-1.12-.82 1.06 1.06 0 01.44-1.29L24.7 22.3a1.26 1.26 0 00.27-1.7 1.321 1.321 0 00-1.71-.27l-21.6 14.11a3.48 3.48 0 00-1.49 4 3.63 3.63 0 003.52 2.51h48.33a3.69 3.69 0 003.51-2.56 3.44 3.44 0 00-1.26-3.96z'
          fill='#C4C4C4'
        />
      </g>
      <defs>
        <clipPath id='cabinaArmadio_svg__clip0_2448_23026'>
          <path fill='#fff' d='M0 0h55.74v40.95H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgCabinaArmadio;
