import {
  PreseAngoloCottura,
  PreseAntibagno,
  PreseBagno,
  PreseCabinaArmadio,
  PreseCucina,
  PreseDisimpegno,
  PreseIngresso,
  PreseLavanderia,
  PreseLettoSingolo,
  PreseLoggia,
  PreseMatrimoniale,
  PreseRipostiglio,
  PreseSoggiorno,
  PreseStudio,
} from '../components/Icons';

const PRESE_ROOM_ICONS_MAP = {
  CUCINA: <PreseCucina />,
  BAGNO: <PreseBagno />,
  'CAMERA SINGOLA': <PreseLettoSingolo />,
  'CAMERA DOPPIA': <PreseMatrimoniale />,
  CAMERA: <PreseMatrimoniale />,
  'CABINA ARMADIO': <PreseCabinaArmadio />,
  LAVANDERIA: <PreseLavanderia />,
  STUDIO: <PreseStudio />,
  RIPOSTIGLIO: <PreseRipostiglio />,
  DISIMPEGNO: <PreseDisimpegno />,
  INGRESSO: <PreseIngresso />,
  LOGGIA: <PreseLoggia />,
  TERRAZZO: <PreseLoggia />,
  SOGGIORNO: <PreseSoggiorno />,
  ANTIBAGNO: <PreseAntibagno />,
  'ANGOLO COTTURA': <PreseAngoloCottura />,
};

export default PRESE_ROOM_ICONS_MAP;
