import { Box, Grid, makeStyles, Modal } from '@material-ui/core';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router';
import ConfigurableMessage from '../../components/ConfigurableMessage';
import Button3D from '../../components/Buttons/Button3D';
import AlertConfigurationEndDate from '../../components/AlertConfigurationEndDate';
import clsx from 'clsx';
import PhaseCard from '../../components/Card/PhaseCard';
import dayjs from 'dayjs';
import itLocale from 'dayjs/locale/it';
import { useStore } from '../../store/storeUtils';
import { useMemo, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import phasesConfig from '../../configs/phasesConfig';
import InfoPopup, { SaveConfirmContent } from '../../components/InfoPopup';
import projectConfig from '../../configs/projectConfig';

const minWidthPhaseCard = 164;

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    background: '#FFFFFF',
    borderRadius: '0px 30px 0px 0px',
    overflow: 'hidden auto',
    padding: '0 187px 50px',

    [theme.breakpoints.down('lg')]: {
      padding: '0 50px 50px',
    },
  },
  header: {
    paddingBottom: '40px',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  title: {
    margin: '24px 0 24px',
    padding: '30px 0 0',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0 0',
    },
  },
  subtitle: {
    marginBottom: '30px',
    fontSize: '20px',
    textAlign: 'center',
    '& > span': {
      padding: '8px 16px 8px',
      borderTop: '1px solid #8a8a8a',
      borderBottom: '1px solid #8a8a8a',
    },
    '& span > span': {
      color: theme.palette.primary.main,
    },
  },
  headerAction: {
    alignSelf: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      '&:nth-child(even)': {
        padding: '0 17px',
      },
      '&:nth-child(odd)': {
        padding: '0 17px',
      },
    },
  },
  nextButton: {
    height: '68px',
    whiteSpace: 'nowrap',
    paddingLeft: '200px',
    paddingRight: '200px',
    maxWidth: 'unset',
    [theme.breakpoints.down('lg')]: {
      height: '60px',
      paddingLeft: '118px',
      paddingRight: '118px',
    },
    [theme.breakpoints.down('md')]: {
      height: '45px',
      paddingLeft: '75px',
      paddingRight: '75px',
    },
  },
  save: {
    borderBottom: '0.7px solid #F08219',
    padding: '6px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginTop: '25px',
    fontSize: '13px',
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
    '& svg': {
      borderRight: '0.7px solid #F08219',
      paddingRight: '10px',
      boxSizing: 'content-box',
    },
    '& span': {
      paddingTop: '2px',
      paddingLeft: '10px',
    },
  },
  progressBar: {
    paddingTop: '10px',
  },
  progressBarItem: {
    height: '7px',
    display: 'flex',
    alignItems: 'center',
    minWidth: minWidthPhaseCard,

    '&.completed': {
      background: 'rgba(118, 184, 45,0.4)',
    },
    '&.hide': {
      display: 'none !important',
    },
  },
  progressLine: {
    borderBottom: '0.7px dashed #000000',
    width: '100%',
  },
  progressLineCircle: {
    minHeight: '15px',
    minWidth: '15px',
    backgroundColor: '#878787',
    borderRadius: '100%',
    display: 'none',
    '&.show': {
      display: 'initial',
    },
    '&.active': {
      backgroundColor: '#F08219',
      display: 'initial',
    },
    '&.completed': {
      backgroundColor: '#76B82D',
      display: 'initial',
    },
  },
  dateInfo: {
    '& > div:first-child': {
      minWidth: `calc(${minWidthPhaseCard}px * 2)`,
    },
  },
  dateInfoEnd: {
    marginLeft: '-64px',
    '& svg:first-child': {
      width: '40px',
      height: 'auto',
    },
    [theme.breakpoints.down('lg')]: {
      marginLeft: '-54px',
      '& svg:first-child': {
        width: '30px',
        height: 'auto',
      },
    },
    [theme.breakpoints.down('md')]: {},
  },
  whiteSpace: { minWidth: `calc(${minWidthPhaseCard}px * 2)` },
  phaseList: {},
  phaseRoot: {
    minWidth: minWidthPhaseCard,
  },
  scrollableContent: {
    [theme.breakpoints.down('md')]: {
      overflow: 'auto hidden',
      width: '100%',
      padding: '5px 0',
    },
  },
  startDate: {
    fontSize: '15px',
    fontWeight: 600,
    marginLeft: '16%',
    marginRight: '-18%',
    '& div:first-child': {
      fontFamily: 'miller',
      fontStyle: 'italic',
      color: '#F08219',
      fontWeight: 'normal',
    },
  },
}));

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const PhaseListPage = observer(() => {
  const classes = useStyles();
  const store = useStore();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { apartment, configuration } = useParams();
  const query = useQuery();

  const [showInfo, setShowInfo] = useState('');
  const [showSaveConfirm, setShowSaveConfirm] = useState(false);
  const [headerMessage, setHeaderMessage] = useState({
    orangeText: 'In pochi semplici passi',
    text: 'potrai personalizzare le finiture e i dettagli della tua casa!',
  });

  const [isAllPhasesCompleted, setIsAllPhasesCompleted] = useState(false);
  const [firstNotCompletedIndex, setFirstNotCompletedIndex] = useState(-1);
  const [phaseList, setPhaseList] = useState(phasesConfig);

  const showGrayCircle = [3];

  const goNextPhase = () => {
    const firstStepInfo = store.getFirstSubstepInfo(
      store.currentPhase ? store.currentPhase : store.phases[0].order,
    );

    store.setCurrentPhase(firstStepInfo.phase.order);
    store.setCurrentStep(firstStepInfo.step.id);
    firstStepInfo.step.roomId ? store.setCurrentRoomId(firstStepInfo.step.roomId) : store.setCurrentRoomId('');
    store.setCurrentSubstep(firstStepInfo.substep.frontendId);
    store.setCurrentSubstepId(firstStepInfo.substep.id);

    history.push(
      `${url}/${firstStepInfo.phase.key}/${firstStepInfo.step.key}/${firstStepInfo.substep.key}`,
    );
  };

  const sendOrderModule = () => {
    history.push(`/apartment/${apartment}/${configuration}/resume`);
  };

  useEffect(() => {
    const completedPhaseOrder = query.get('completedPhaseId');

    const isAllPhasesCompleted = store.isAllPhasesCompleted() || completedPhaseOrder === '40';

    let firstNotCompletedIndex;
    if (completedPhaseOrder) {
      firstNotCompletedIndex = completedPhaseOrder * 0.1;

      if (isAllPhasesCompleted && store.phases?.length !== firstNotCompletedIndex) {
        // Se abbiamo finito tutte le fasi ma non siamo all'ultima fase attiva
        // porto l'utente direttamente alla fase successiva, senza mostrare la lista delle fasi
        goNextPhase();
      } else {
        const phase = phaseList.find((phase) => phase.order === completedPhaseOrder);
        const newPhaseList = phaseList?.map((phase) => {
          return {
            ...phase,
            completed: phase.order * 1 <= completedPhaseOrder * 1,
          };
        });
        setFirstNotCompletedIndex(firstNotCompletedIndex);
        setPhaseList(newPhaseList);
        setHeaderMessage({
          ...phase?.completedPhaseMessage,
        });
      }
    } else {
      const newPhaseList = phaseList?.map((phase) => {
        return {
          ...phase,
          completed: phase.order * 1 < store.currentPhase * 1,
        };
      });
      firstNotCompletedIndex = newPhaseList.findIndex((el) => !el.completed);
      setFirstNotCompletedIndex(firstNotCompletedIndex);
      setPhaseList(newPhaseList);
    }
    setIsAllPhasesCompleted(isAllPhasesCompleted);
  }, []);

  return (
    <>
      <div className={classes.root}>
        <Grid container className={classes.header}>
          <Grid item xs={12} className={classes.title}>
            <ConfigurableMessage {...headerMessage} />
          </Grid>
          <Grid item md={'auto'} lg={12} className={classes.headerAction}>
            <Button3D
              text={
                isAllPhasesCompleted
                  ? 'VAI AL RIEPILOGO'
                  : firstNotCompletedIndex === 0
                  ? 'START! INIZIA LA PERSONALIZZAZIONE!'
                  : 'PASSA ALLA FASE SUCCESSIVA'
              }
              normalWeightText
              className={classes.nextButton}
              onClick={isAllPhasesCompleted ? sendOrderModule : goNextPhase}
            />
          </Grid>
        </Grid>
        <div className={classes.scrollableContent}>
          <Grid container className={classes.dateInfo} wrap='nowrap' spacing={2} justifyContent="center">
            <Grid item xs={10} className={classes.startDate}>
              <div>
                {dayjs(store.currentConfiguration?.creationDate).locale(itLocale).format('DD MMMM')}
              </div>
              <div>INIZIO CONFIGURAZIONE</div>
            </Grid>
            <Grid item xs={2}>
              <AlertConfigurationEndDate
                className={classes.dateInfoEnd}
                firstRow={projectConfig[store.currentProject?.shortName]?.deadline}
                secondRow={
                  <span>
                    <b>TERMINE MASSIMO</b>
                  </span>
                }
                thirdRow={'PER LA CONFIGURAZIONE'}
              />
            </Grid>
          </Grid>

          <Grid container className={classes.progressBar} justifyContent="center" wrap='nowrap'>
            {phaseList.map((el, i) => (
              <Grid
                key={i}
                item
                xs={2}
                className={clsx(
                  classes.progressBarItem,
                  el.completed && 'completed',
                  i*10 === firstNotCompletedIndex && 'active',
                )}
              >
                <div
                  className={clsx(
                    classes.progressLineCircle,
                    'progress-line-circle',
                    i*10 !== 0 ? '' : i*10 === firstNotCompletedIndex ? 'active' : 'completed',
                  )}
                />
                <div className={clsx(classes.progressLine, 'progress-line')} />
                <div
                  className={clsx(
                    classes.progressLineCircle,
                    'progress-line-circle',
                    i*10 === firstNotCompletedIndex - 10 ||
                      (i === phaseList.length - 1 && firstNotCompletedIndex === -1) ||
                      (showGrayCircle.includes(i) && firstNotCompletedIndex === -1)
                      ? 'completed'
                      : showGrayCircle.includes(i)
                      ? 'show'
                      : '',
                  )}
                />
              </Grid>
            ))}
          </Grid>
          <Grid container wrap='nowrap'>
            <Grid item xs={10} className={classes.whiteSpace} />
            <Grid item xs={2}>
              <Box height='30px' bgcolor='#000000' width='1px' margin='10px 0 10px -8px'></Box>
            </Grid>
          </Grid>
          <Grid container className={classes.phaseList} spacing={2} justifyContent="center" wrap='nowrap'>
            {phaseList.map((el, i) => (
              <Grid key={i} item xs={2} className={clsx(classes.phaseRoot)}>
                <PhaseCard
                  {...el}
                  isActive={i*10 === firstNotCompletedIndex}
                  showInfo={showInfo === i}
                  handleOpenInfo={() => setShowInfo(i)}
                  handleCloseInfo={() => setShowInfo('')}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
      <Modal open={showSaveConfirm} onClose={() => setShowSaveConfirm(false)}>
        <InfoPopup
          body={
            <SaveConfirmContent
              handleSubmit={() => setShowSaveConfirm(false)}
              handleCancel={() => setShowSaveConfirm(false)}
            />
          }
          noMaxWidth
          isWarning
          position='center'
          handleClose={() => setShowSaveConfirm(false)}
        />
      </Modal>
    </>
  );
});

export default PhaseListPage;
