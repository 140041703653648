import { makeStyles } from '@material-ui/core';
import React from 'react';
import { CheckOk } from '../Icons';

const useStyles = makeStyles((theme) => ({
  confirmationInfoCardRoot: {
    textAlign: 'center',
    padding: '64px 24px 160px',
    fontFamily: 'miller',
    fontStyle: 'italic',
    fontSize: '18px',
    '& .confirmation-icon': {
      fontSize: 40,
    },
    '& .confirmation-title': {
      marginBottom: 32,
      '& span': {
        color: theme.palette.primary.main,
      },
    },
    '& .confirmation-text': {
      borderTop: '2px solid #dedddd',
      borderBottom: '2px solid #dedddd',
      padding: '24px 16px',
      '& span': {
        cursor: 'pointer',
        textDecoration: 'underline',
        color: theme.palette.primary.main,
      },
    },
  },
}));

const ConfirmationInfoCard = ({ handleConfirmation, hideAction }) => {
  const classes = useStyles();
  return (
    <div className={classes.confirmationInfoCardRoot}>
      <div className='confirmation-icon'>
        <CheckOk />
      </div>
      <div className='confirmation-title'>
        Complimenti, <span>hai completato la configurazione </span> delle prime due sezioni della
        tua nuova casa!
      </div>
      {!hideAction && (
        <div className='confirmation-text'>
          Clicca su <span onClick={handleConfirmation}>CONFERMA QUESTA CONFIGURAZIONE</span> per
          poter proseguire con i prossimi step della personalizzazione in showroom con uno dei
          nostri architetti.
        </div>
      )}
    </div>
  );
};

export default ConfirmationInfoCard;
