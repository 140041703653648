import * as React from "react";

function SvgEye(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.883 4.697C15.758 4.5 12.347 0 8 0 3.653 0 .243 4.5.1 4.697a.51.51 0 000 .606C.243 5.5 3.653 10 8 10s7.758-4.5 7.883-4.697a.51.51 0 000-.606zM8 8.967C4.798 8.967 2.023 5.975 1.195 5 2.031 4.025 4.79 1.033 8 1.033c3.21 0 5.977 2.992 6.805 3.967-.82.975-3.595 3.967-6.805 3.967z"
        fill={props.color}
      />
      <path
        d="M8 1.893c-.626 0-1.239.182-1.76.523A3.12 3.12 0 005.073 3.81a3.05 3.05 0 00-.18 1.795c.122.603.424 1.156.867 1.59.443.435 1.008.731 1.622.851.615.12 1.252.058 1.83-.177a3.156 3.156 0 001.423-1.144c.348-.51.534-1.111.534-1.726 0-.824-.334-1.614-.928-2.197A3.2 3.2 0 008 1.892zm0 5.172c-.416 0-.824-.121-1.17-.348a2.075 2.075 0 01-.776-.927 2.028 2.028 0 01-.12-1.194c.081-.4.282-.769.577-1.057a2.12 2.12 0 011.078-.566c.409-.08.832-.039 1.217.118.385.156.714.42.946.76A2.037 2.037 0 019.49 6.46c-.395.387-.931.605-1.49.605z"
        fill={props.color}
      />
    </svg>
  );
}

export default SvgEye;
