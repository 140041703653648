import * as React from "react";

function SvgTabletOrientationCheck(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 95 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#tablet-orientation-check_svg__clip0)">
        <path
          d="M85.678 39.031l4.042-4.039 3.873 3.88M54.15 78.616l-4.043-4.039 3.883-3.88"
          stroke="#1D1D1B"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M50.107 74.653H74.49c4.044 0 7.923-1.605 10.783-4.463a15.233 15.233 0 004.466-10.775V34.992"
          stroke="#1D1D1B"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M75.99 8.19H2.87c-.808 0-1.463.654-1.463 1.461V59.21c0 .808.655 1.462 1.463 1.462h73.12c.809 0 1.464-.654 1.464-1.462V9.651c0-.807-.655-1.462-1.463-1.462z"
          stroke="#1D1D1B"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx={71} cy={35} r={3} fill={props.color} />
        <path d="M65.5 12v44" stroke={props.color} />
      </g>
      <path d="M77 16a8 8 0 100-16 8 8 0 000 16z" fill="#76B82D" />
      <path
        d="M76.08 11.2c-.149 0-.299-.073-.411-.182l-2.66-2.644a.513.513 0 010-.76c.262-.218.6-.218.824 0l2.21 2.245 3.822-4.237a.556.556 0 01.787-.037.513.513 0 01.037.761l-4.196 4.672a.611.611 0 01-.412.181z"
        fill="#fff"
      />
      <defs>
        <clipPath id="tablet-orientation-check_svg__clip0">
          <path
            fill="#fff"
            transform="translate(0 6.783)"
            d="M0 0h95v73.24H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgTabletOrientationCheck;
