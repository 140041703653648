import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import OptionCard, { StandardOptionCardBody } from '../../../components/Card/OptionCard';
import ConfiguratorIntro from '../../../components/configurator/ConfiguratorIntro';
import { Bath, Doccia } from '../../../components/Icons';
import ConfiguratorSelectionLayout from '../../../components/Layout/ConfiguratorSelectionLayout';
import ConfiguratorTwoColumnsPlanimetryLayout from '../../../layouts/ConfiguratorTwoColumnsPlanimetryLayout';
import { getLayoutImagesByConfigurationId } from '../../../services/layoutApi';
import { getOption } from '../../../services/optionApi';
import { useStore } from '../../../store/storeUtils';
import {
  handleCardMouseEnterSimple,
  handleCardMouseLeaveSimple,
  resumeAnswerSimple,
} from '../../../utils/configuratorUtils';
import { handleOptionPlanimetryLayers, prepareOptionData } from '../../../utils/optionsUtils';
import { handleSave, singleChoiceSelection } from '../../../utils/selectionUtils';
import DefaultChoiceImgText from './currentConfiguration/DefaultChoiceImgText';

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: '#EEEEEE',
    minHeight: '100%',
  },
  list: { marginTop: '50px', padding: '0 22px 22px' },
  alertMessage: { marginBottom: '5px' },
}));

const infoPriceVasca = {
  incasso:
    'Il prezzo comprende la fornitura e la posa in opera della vasca da incasso, incluso la realizzazione del muretto rivestito in gres a completamento del fronte della vasca. Il prezzo è calcolato in sostituzione del piatto doccia e della rubinetteria previste nella tua configurazione di partenza.',
  idromassaggio:
    "Il prezzo comprende la fornitura e la posa in opera della vasca idromassaggio completa di rubinetteria, incluso la realizzazione della componente elettrica costituita da un differenziale collegato al quadro generale dell'appartamento. Il prezzo è calcolato in sostituzione del piatto doccia e della rubinetteria previsti nella tua configurazione di partenza. Potrai personalizzare le finiture e gli optional in una fase successiva.",
  'free standing':
    'Il prezzo comprende la fornitura e la posa in opera della vasca freestanding e della rubinetteria a pavimento. Il prezzo è calcolato in sostituzione del piatto doccia e della rubinetteria previsti nella tua configurazione di partenza. Potrai personalizzare il modello di vasca e rubinetteria in una fase successiva. Clicca su foto e scheda tecnica per visualizzare i modelli disponibili.',
};

const FirstColumn = ({ defaultOption, onClick, selectedOptions }) => {
  return defaultOption ? (
    <DefaultChoiceImgText
      message='Nel bagno indicato è prevista una doccia.'
      icon={<Doccia />}
      onClick={() => {
        onClick(defaultOption.ids);
      }}
      isSelected={_.isEqual(defaultOption.ids, selectedOptions)}
      subtitle='Conferma questa soluzione oppure scegli opzioni alternative a lato'
      infoRichText={defaultOption?.options[0]?.longDescription}
      infoRichTextButtonLabel={'Scopri le qualità di questo layout'}
    />
  ) : (
    ''
  );
};

const SecondColumn = ({
  options,
  selectedOptions,
  onClick,
  cardOnMouseEnter,
  cardOnMouseLeave,
}) => {
  const classes = useStyles();

  return (
    <ConfiguratorSelectionLayout>
      <div className={clsx(classes.content)}>
        <ConfiguratorIntro
          icon={<Bath />}
          title={
            'In sostituzione della doccia, vuoi personalizzare il tuo bagno scegliendo una vasca?'
          }
          badgeText={'Potrai personalizzare le finiture in una fase successiva.'}
        />

        <Grid container className={clsx(classes.list)} spacing={2} justifyContent='center'>
          {options.map((el, i) => (
            <Grid item key={i} sm={6} md={6} lg={4}>
              <OptionCard
                {...el}
                onClick={() => onClick(el.ids)}
                isSelected={_.isEqual(el.ids, selectedOptions)}
                onMouseEnter={cardOnMouseEnter}
                onMouseLeave={cardOnMouseLeave}
                infoPriceMessage={
                  el.options[0]?.priceDescription
                    ? el.options[0]?.priceDescription
                    : infoPriceVasca[el.options[0]?.description?.toLowerCase()]
                }
                infoRichText={el.options[0]?.longDescription}
                infoRichTextButtonLabel={'Scopri le qualità di questo layout'}
              >
                <StandardOptionCardBody
                  {...el}
                  message={`Seleziono il modello `}
                  name={`VASCA ${el.options[0]?.description}`}
                  hidePositions
                />
              </OptionCard>
            </Grid>
          ))}
        </Grid>
      </div>
    </ConfiguratorSelectionLayout>
  );
};

const SecondoBagno = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const [defaultOption, setDefaultOption] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [images, setImages] = useState([]);

  const substepIdRef = useRef();
  const oldAnswersRef = useRef();
  const showLayerOnHoverRef = useRef(true);
  const baseImagesRef = useRef([]);
  const bathroom2TagRef = useRef();

  const store = useStore();
  const params = useParams();

  useEffect(() => {
    store.setUserCanGoNext(false);
    prepareOptions();
  }, []);

  const prepareOptions = async () => {
    const substepId = store.getSubstepIdByFrontendId(store.currentSubstep);

    let optionData = await getOption(
      store.currentConfiguration?.layout?.id,
      substepId,
      params.configuration,
    );
    substepIdRef.current = optionData?.data.substep.id;

    optionData = prepareOptionData(optionData);

    // Imposto i layer da far vedere
    const layoutImagesResponse = await getLayoutImagesByConfigurationId(params.configuration);
    const layoutImages = layoutImagesResponse.data;

    const bathroom2 = store.currentConfiguration?.layout?.rooms?.find(
      (el) => el.name === 'BAGNO 2',
    );
    baseImagesRef.current = layoutImages;

    bathroom2?.tags.forEach((tag) => {
      tag?.fileLabels.forEach((fileLabel) => {
        if (fileLabel.url.toLowerCase().includes('vasca')) {
          layoutImages.push(fileLabel.url);
          bathroom2TagRef.current = [fileLabel.url];
        }
      });
    });
    setImages(layoutImages);

    setOptions(
      optionData
        .filter((el) => !el.isDefault)
        ?.sort((a, b) => a?.positions[0] - b?.positions[0]), // Ordinamento opzioni
    );
    const defaultOption = optionData.find((el) => el.isDefault);
    setDefaultOption(defaultOption);

    resumeAnswerSimple(store, oldAnswersRef, substepIdRef, setSelectedOptions, setIsLoading);
  };

  const handleUserSelection = (selection) => {
    singleChoiceSelection(selection, selectedOptions, setSelectedOptions, store);
  };

  // Gestione del salvataggio
  useEffect(() => {
    const save = async () => {
      await handleSave(selectedOptions, params.configuration, substepIdRef.current, store);
    };
    if (!_.isEqual(oldAnswersRef.current, selectedOptions)) {
      store.setSubstepSave(save);
    } else {
      store.setSubstepSave(null);
    }
  }, [selectedOptions]);

  // Gestione delle immagini della planimetria alla selezione di un opzione
  useEffect(() => {
    handleOptionPlanimetryLayers(
      options,
      defaultOption?.ids,
      selectedOptions,
      showLayerOnHoverRef,
      baseImagesRef,
      setImages,
      null,
      bathroom2TagRef.current,
      'B',
      defaultOption,
    );
  }, [selectedOptions]);

  const handleCardMouseEnter = (fileLabels) => {
    handleCardMouseEnterSimple(
      fileLabels,
      showLayerOnHoverRef,
      baseImagesRef,
      setImages,
      bathroom2TagRef.current,
      'B',
    );
  };
  const handleCardMouseLeave = () => {
    handleCardMouseLeaveSimple(
      showLayerOnHoverRef,
      baseImagesRef,
      setImages,
      bathroom2TagRef.current,
    );
  };

  return (
    <ConfiguratorTwoColumnsPlanimetryLayout
      planimetryImages={images}
      optionLayersOnTop
      firstColumn={
        <FirstColumn
          defaultOption={defaultOption}
          selectedOptions={selectedOptions}
          onClick={handleUserSelection}
        />
      }
      secondColumn={
        <SecondColumn
          options={options}
          selectedOptions={selectedOptions}
          onClick={handleUserSelection}
          cardOnMouseEnter={handleCardMouseEnter}
          cardOnMouseLeave={handleCardMouseLeave}
        />
      }
      firstRowTitle={'IL TUO '}
      secondRowTitle={'APPARTAMENTO'}
      secondColumnFirstRowTitle={'SOLUZIONI'}
      secondColumnSecondRowTitle={'ALTERNATIVE'}
      isLoading={isLoading}
      noOption={options?.length === 0}
      noOptionIcon={<Bath />}
      noOptionText={'Non puoi sostituire le porte battenti con porte scorrevoli.'}
    />
  );
};

export default SecondoBagno;
