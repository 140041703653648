import * as React from "react";

function SvgMinus(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 8 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.2.898H.967"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
      />
    </svg>
  );
}

export default SvgMinus;
