import { makeStyles } from '@material-ui/core';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import clsx from 'clsx';
import SvgLeftChevron from './Icons/LeftChevron';
import { useRef, useState } from 'react';
import ImageZoom from './ImageZoom';
import IconRound3DButton from './Buttons/IconRound3DButton';
import { MagnifyingGlass } from './Icons';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    '& .slick-list,.slick-slider,.slick-track, .slick-slide > div': {
      height: '100%',
    },
  },
  elementRoot: {
    position: 'relative',
    height: '100%',
    outline: 'none',
    backgroundColor: theme.palette.color.white,
    '& img': {
      height: '100%',
      width: '100%',
      objectFit: 'contain',
    },
  },
  elementRootCursor: {
    cursor: 'zoom-in',
  },
  viewName: {
    position: 'absolute',
    top: '15px',
    left: 0,
    padding: '6px 15px 3px',
    backgroundColor: theme.palette.color.white,
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '18px',
    textTransform: 'uppercase',
  },
  arrow: {
    padding: 0,
    transform: 'translate(0, -50%)',
    cursor: 'pointer',
    fontSize: 0,
    lineHeight: 0,
    position: 'absolute',
    top: '50%',
    background: '#676766',
    border: '1px solid #FFFFFF',
    height: '30px',
    width: '30px',
    display: 'flex',
    alignItems: 'center',
    zIndex: 2,

    '&.next': {
      right: '0',
      '& svg': {
        transform: 'rotate(180deg)',
      },
    },
  },
  zoomIconContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: '57px',
    width: '57px',
    // backgroundColor: '#EBE4E0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Arrow = (props) => {
  const classes = useStyles();

  const { onClick, isNext } = props;
  return (
    <div className={clsx(classes.arrow, isNext && 'next')} onClick={onClick}>
      <SvgLeftChevron height='12px' width='auto' color='#FFF' />
    </div>
  );
};

const ImageZoomSlider = ({ images, hideZoom, viewName, noZoomImages, noSlider }) => {
  const classes = useStyles();

  const [showZoom, setShowZoom] = useState(false);
  const currentImage = useRef(0);

  const handleAfterChange = (i) => {
    currentImage.current = i;
  };

  const sliderSetting = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: noSlider ? undefined : <Arrow isNext />,
    prevArrow: noSlider ? undefined : <Arrow />,
    draggable: false,
    afterChange: handleAfterChange,
  };

  return (
    <div className={classes.root}>
      {noSlider &&
        ( <div className={noZoomImages ? classes.elementRoot : clsx(classes.elementRoot, classes.elementRootCursor)} onClick={() => noZoomImages ? setShowZoom(false) : setShowZoom(true)}>
            {viewName && <span className={classes.viewName}>Vista</span>}
            <img src={images[0]} alt='' />
          </div>
        )}
      {!noSlider && (
      <Slider {...sliderSetting}>
        {images?.map((el, i) => (
          <div className={noZoomImages ? classes.elementRoot : clsx(classes.elementRoot, classes.elementRootCursor)} key={i} onClick={() => noZoomImages ? setShowZoom(false) : setShowZoom(true)}>
            {viewName && <span className={classes.viewName}>Vista {i + 1}</span>}
            <img src={el} alt='' />
          </div>
        ))}
      </Slider>
      )}
      {!hideZoom && (
        <div className={classes.zoomIconContainer}>
          <IconRound3DButton
            onClick={() => setShowZoom(true)}
            className={classes.zoomIcon}
            icon={<MagnifyingGlass height='100%' width='100%' color='#000' />}
          />
        </div>
      )}
      {showZoom && (
        <ImageZoom
          images={images}
          startIndexImage={currentImage.current}
          allowDownload
          handleClose={() => setShowZoom(false)}
        />
      )}
    </div>
  );
};

export default ImageZoomSlider;
