import { useEffect, useState, useRef } from 'react';
import GridDetailsContainer from '../../../components/Card/GridDetailsContainer';
import ConfiguratorTwoColumnsLayout from '../../../layouts/ConfiguratorTwoColumnsLayout';
import BigBox from './config/BigBox';
import { makeStyles } from '@material-ui/core';
import { resumeAnswerSimple } from '../../../utils/configuratorUtils';
import { getPackages } from '../../../services/packageApi';
import { useStore } from '../../../store/storeUtils';
import { useParams } from 'react-router';
import { handleSave, singleChoiceSelection } from '../../../utils/selectionUtils';
import _ from 'lodash';
import LoadingAnimation from '../../../components/LoadingAnimation';
import { packagesRestructuringClassicConfiguration } from '../../../configs/giornoNottePhasesConfigurations';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '15px',
    textAlign: 'left',
    height: '5%',
  },
  subTitle: {
    fontSize: '11px',
    textAlign: 'left',
    height: '5%',
  },
  titleCucina: {
    fontSize: '15px',
    textAlign: 'left',
    height: '8%',
  },
  subTitleCucina: {
    fontSize: '11px',
    textAlign: 'left',
    height: '7%',
  },
  secondaryText: {
    color: theme.palette.color.orange,
  },
}));

const FirstColumn = ({
  options,
  selectedOptions,
  selectedFormatoCamereImage,
  isCucina,
  selectedFinituraTitle,
}) => {
  const selectedVirtualImage = options
    ?.find((option) => option.id === selectedOptions?.[0])
    ?.virtualImage?.replace(/\s/g, '%20');
  // const isChained = options?.find((option) => option.id === selectedOptions?.[0])?.isChained;
  return (
    <BigBox
      defaultImage={
        isCucina
          ? `${process.env.PUBLIC_URL}/assets/images/Cucina.jpg`
          : `${process.env.PUBLIC_URL}/assets/images/Soggiorno.jpg`
      }
      selectedVirtualImage={selectedVirtualImage}
      // isChained={isChained}
      selectedFormatoCamereImage={isCucina ? selectedFormatoCamereImage : null}
      selectedFormatoCamereTitle={isCucina ? selectedFinituraTitle : null}
    />
  );
};

const SecondColumn = ({
  options,
  selectedOptions,
  handleUserSelection,
  selectedMoods,
  isCucina,
}) => {
  const containers = [
    {
      title: 'Finiture ' + selectedMoods,
      content: options,
    },
  ];
  return (
    <>
      <SecondColumnText
        isCucina={isCucina}
        style={isCucina ? { height: '15%' } : { height: '10%' }}
      />
      <div style={isCucina ? { height: '93%' } : { height: '95%' }}>
        <GridDetailsContainer
          containers={containers}
          onClick={handleUserSelection}
          selectedOptions={selectedOptions}
          numCols={1}
        />
      </div>
    </>
  );
};

const SecondColumnText = ({ isCucina }) => {
  const classes = useStyles();
  const cucinaPrimartyText = (
    <span>
      Quale <span className={classes.secondaryText}>finitura</span> scegli per il{' '}
      <span className={classes.secondaryText}>pavimento </span>della cucina?
    </span>
  );
  const livingPrimaryText = (
    <span>
      Quale <span className={classes.secondaryText}>finitura</span> scegli per il{' '}
      <span className={classes.secondaryText}>pavimento</span> di{' '}
      <span className={classes.secondaryText}>living e camere</span>?
    </span>
  );
  /*
  const cucinaSecondaryText = (
    <span className={classes.secondaryText}>
      Le finiture di seguito proposte, sono selezionate per essere accostate al pavimento della tua
      zona living / notte.
    </span>
  );
  const livingSecondaryText = (
    <span className={classes.secondaryText}>
      <Chains color='#F08219' height='12px' width='12px' /> Le scelte con questa icona determinano
      anche le scelte della cucina.
    </span>
  );
  */
  return (
    <div className={isCucina ? classes.titleCucina : classes.title}>
      {isCucina ? cucinaPrimartyText : livingPrimaryText}
    </div>
  );
};

const FinitureGiornoNotte = () => {
  const [packages, setPackages] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [selectedMoods, setSelectedMoods] = useState();
  const [selectedFormatoCamereImage, setSelectedFormatoCamereImage] = useState(null);
  const [selectedFormatoCamereTitle, setSelectedFormatoCamereTitle] = useState(null);
  const [isCucina, setIsCucina] = useState(false);

  const substepIdRef = useRef();
  const oldAnswersRef = useRef();

  const store = useStore();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const configurationId = params.configuration;
  const { answers } = store;
  const substepId = store.currentSubstepId;

  const settingFiniture = () => {
    const stepFinituraId = answers.find((el) => el.substep.value === 'FINITURA' && el.package.value)
      ?.substep?.id;
    const storedFinitureTitle = store.selectedFormatoCameraTitle;
    const storedFinituraImage = store.selectedFormatoCameraImage;
    if (storedFinitureTitle !== '') {
      setSelectedFormatoCamereTitle(storedFinitureTitle);
      setSelectedFormatoCamereImage(storedFinituraImage);
      if (stepFinituraId && substepId && substepId !== stepFinituraId) {
        setIsCucina(true);
      }
    }
  };

  useEffect(() => {
    store.setUserCanGoNext(false);
    settingFiniture();
    prepareOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prepareOptions = async () => {
    const packages = await getPackages(configurationId, substepId);
    setSelectedMoods(answers.find((el) => el.substep.value === 'MOOD')?.package?.value);
    /*
    const selectedFinitura = answers.find((el) => el.substep.value === 'FINITURA')?.package?.value;
    let stepFinituraId;
    let finiturePackages;
    if (selectedFinitura) {
      stepFinituraId = answers.find((el) => el.substep.value === 'FINITURA')?.substep?.id;
      if (substepId !== stepFinituraId) {
        setMoodAndFinituraConfiguration(answers, setSelectedMoods, setSelectedFinituraImage, finiturePackages, setSelectedFinituraTitle)
        finiturePackages = await getPackages(configurationId, stepFinituraId);
        setSelectedFinituraImage(
          finiturePackages?.data[0]?.packages
            ?.find((onePackage) => onePackage?.code === selectedFinitura)
            ?.images?.find((image) => image?.imageType?.value === 'Cover')
            ?.label?.replace(/\s/g, '%20'),
        );
        setSelectedFinituraTitle(
          finiturePackages?.data[0]?.packages
            ?.find((onePackage) => onePackage?.code === selectedFinitura)
            ?.title
        );
      }
    }
    */
    substepIdRef.current = store.currentSubstepId;
    const packagesRestruct = packagesRestructuringClassicConfiguration(
      packages,
      false,
      false,
      false,
      isCucina ? 'FinituraCucina' : 'FinituraCamere',
    );
    setPackages(packagesRestruct);
    resumeAnswerSimple(store, oldAnswersRef, substepIdRef, setSelectedPackages, setIsLoading);
  };

  useEffect(() => {
    const save = async () => {
      await handleSave(null, configurationId, substepIdRef.current, store, selectedPackages?.[0]);
    };
    if (!_.isEqual(oldAnswersRef.current, selectedPackages)) {
      store.setSubstepSave(save);
    } else {
      store.setSubstepSave(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPackages]);

  const handleUserSelection = (selection) => {
    singleChoiceSelection([selection], [selectedPackages], setSelectedPackages, store);
  };

  return isLoading ? (
    <LoadingAnimation />
  ) : (
    <ConfiguratorTwoColumnsLayout
      firstRowTitle='CONFIGURA'
      secondRowTitle={
        selectedFormatoCamereImage
          ? 'IL PAVIMENTO DELLA CUCINA'
          : 'I PAVIMENTI DI LIVING E CAMERE DA LETTO'
      }
      firstColumn={
        <FirstColumn
          options={packages}
          selectedOptions={selectedPackages}
          selectedFormatoCamereImage={isCucina && selectedFormatoCamereImage}
          selectedFinituraTitle={isCucina && selectedFormatoCamereTitle}
          isCucina={isCucina}
        />
      }
      secondColumn={
        <SecondColumn
          options={packages}
          selectedOptions={selectedPackages}
          selectedMoods={selectedMoods}
          handleUserSelection={handleUserSelection}
          isCucina={isCucina}
        />
      }
      options={packages}
    />
  );
};

export default FinitureGiornoNotte;
