import * as React from 'react';

function SvgNoCassaforte(props) {
  return (
    <svg viewBox='0 0 82 64' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#no-cassaforte_svg__clip0_2068:22634)'>
        <path
          d='M69.78 2.58H11.46v51.88h2v6.86h14.13v-6.86h26.171v6.86h14.12v-6.86h1.89l.01-51.88zm-3.429 57.2h-11v-5.32h11v5.32zm-40.29 0h-11v-5.32h11v5.32zM68.24 4.12v48.83H13V4.12h55.24z'
          fill='#3a3a3a'
        />
        <path
          d='M62.9 8.68H18.34v39.68H62.9V8.68zm-43 38.13V10.22h41.46v36.59H19.9z'
          fill='#3a3a3a'
        />
        <path
          d='M40.621 14.53a14 14 0 100 28 14 14 0 000-28zm7.07 24.22l-1-1.48-1.28.87 1 1.41a12.37 12.37 0 01-5 1.38v-1.89h-1.54v1.91a12.24 12.24 0 01-5-1.4l1-1.47-1.28-.87-1 1.54a12.541 12.541 0 01-3.33-3.44l1.63-.94-.83-1.36-1.61.94a12.421 12.421 0 01-1.23-4.66h1.92v-1.54h-1.92a12.29 12.29 0 011.26-4.72l1.58.92.77-1.33-1.58-.92a12.49 12.49 0 013.32-3.4l1 1.47 1.28-.86-1-1.41a12.33 12.33 0 015-1.38v1.89h1.54v-1.9a12.27 12.27 0 015 1.39l-1 1.48 1.28.86 1-1.54a12.54 12.54 0 013.39 3.44l-1.64.95.77 1.33 1.63-.94a12.41 12.41 0 011.24 4.67h-1.91v1.54h1.91a12.28 12.28 0 01-1.25 4.72l-1.59-.92-.77 1.34 1.6.92a12.68 12.68 0 01-3.36 3.4z'
          fill='#3a3a3a'
        />
        <path
          d='M40.619 21.23a7.39 7.39 0 10-.02 14.781 7.39 7.39 0 00.02-14.78zm0 1.55a5.84 5.84 0 110 11.68 5.84 5.84 0 010-11.68z'
          fill='#3a3a3a'
        />
        <path d='M78.524-.023L-.008 60.389l2.701 3.51L81.225 3.489 78.524-.023z' fill='#F0801A' />
      </g>
      <defs>
        <clipPath id='no-cassaforte_svg__clip0_2068:22634'>
          <path fill='#fff' d='M0 0h81.25v63.9H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgNoCassaforte;
