import * as React from 'react';

function SvgIlluminazioneCabinaArmadio(props) {
  return (
    <svg viewBox='0 0 69 44' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#illuminazione-cabina-armadio_svg__clip0_2758_24063)' fill='#C4C4C4'>
        <path d='M20.288 12.91a1.083 1.083 0 001.37-.89c.31-2.6 2.81-4.48 5.6-4.17 2.31 0 4.21 1.74 4.4 4.03.12 1.77-.78 3.46-2.29 4.27-.81.39-1.64.62-2.45.68h-.86c-.17 0-.3.13-.3.3v5.27c0 .09.05.19.12.24l11.46 8.29V28.1l-9.26-6.68v-2.49c.82-.14 1.57-.38 2.36-.76 2.39-1.29 3.73-3.72 3.53-6.32-.14-3.45-3-6.2-6.48-6.26-3.91-.46-7.53 2.25-8.07 6-.08.28-.04.59.13.85.17.27.46.46.73.48l.01-.01zM48.399 41.31H3.489c-.46 0-.88-.31-1.04-.76-.16-.46 0-.95.41-1.2l20.09-13.12c.48-.37.6-1.08.25-1.59-.39-.46-1.09-.58-1.59-.25L1.539 37.5c-1.28.84-1.83 2.3-1.38 3.74.44 1.39 1.75 2.33 3.27 2.33h44.9c1.48 0 2.76-.94 3.25-2.38.04-.1.07-.2.09-.32h-2.4c-.19.26-.53.43-.88.43l.01.01zM54.439 33.93h-8.06c-.69 0-1.25.57-1.25 1.26 0 .69.56 1.26 1.25 1.26h8.06c.69 0 1.25-.56 1.25-1.26s-.56-1.26-1.25-1.26zM57.918 10.97c-.34-.33-.71-.64-1.09-.91-1.83-1.35-4.06-2.07-6.39-2.07-2.83 0-5.49 1.05-7.49 2.98-2.01 1.93-3.12 4.5-3.12 7.24 0 2.54.97 4.97 2.72 6.84 1.78 2.11 2.61 6.05 2.61 6.08l.17.82h10.3l.02-.99c.07-2.88 2.81-6.06 2.93-6.19 1.59-1.84 2.47-4.17 2.47-6.56 0-1.62-.39-3.18-1.12-4.58-.51-.98-1.18-1.87-2.01-2.66zm-.88 12.46c-.29.33-2.8 3.29-3.33 6.48h-6.74c-.35-1.34-1.26-4.28-2.91-6.23a7.95 7.95 0 01-2.2-5.47c0-4.51 3.85-8.19 8.58-8.19s8.57 3.68 8.57 8.19c0 1.9-.7 3.76-1.97 5.22zM51.567 0h-2.27v4.64h2.27V0zM62.737 3.93l-2.98 3.556 1.741 1.458 2.98-3.557-1.74-1.458zM68.808 16.24h-4.64v2.27h4.64v-2.27z' />
        <path d='M50.52 23.52l-2.91-2.91-1.41 1.41 4.32 4.33 4.1-4.1-1.41-1.41-2.69 2.68z' />
      </g>
      <defs>
        <clipPath id='illuminazione-cabina-armadio_svg__clip0_2758_24063'>
          <path fill='#fff' d='M0 0h68.81v43.58H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIlluminazioneCabinaArmadio;
