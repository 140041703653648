import * as React from "react";

function SvgDesktopWindowUncheck(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 101 79"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#desktop-window-uncheck_svg__clip0)">
        <path
          d="M87.09 10.19H2.885c-.93 0-1.684.702-1.684 1.569v56.864c0 .867.754 1.57 1.684 1.57h84.207c.93 0 1.684-.703 1.684-1.57V11.76c0-.867-.754-1.57-1.684-1.57z"
          stroke="#1D1D1B"
          strokeWidth={3.1}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M71.482 74.72H18.493c-.938 0-1.698.682-1.698 1.523v1.484c0 .84.76 1.522 1.698 1.522h52.989c.938 0 1.698-.681 1.698-1.522v-1.484c0-.84-.76-1.522-1.698-1.522zM44.987 16.982c.994 0 1.8-.76 1.8-1.698s-.806-1.698-1.8-1.698c-.993 0-1.799.76-1.799 1.698s.806 1.698 1.8 1.698z"
          fill="#1D1D1B"
        />
        <path
          d="M52.785 21.51H9.597v41.889h43.188v-41.89z"
          stroke="#C90000"
          strokeWidth={0.73}
          strokeMiterlimit={10}
        />
        <path d="M1 78.758L94 6.999" stroke="#F08219" strokeWidth={5} />
      </g>
      <defs>
        <clipPath id="desktop-window-uncheck_svg__clip0">
          <path fill="#fff" d="M0 0h101v79H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgDesktopWindowUncheck;
