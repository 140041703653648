import _ from 'lodash';
import { getFileNameFromUrl } from './stringUtils';

export const prepareOptionData = (optionData) => {
  let response = [];
  let price = 0;

  const optionGroupPrefixes = optionData?.data.optionGroupPrefixes;

  if (optionGroupPrefixes) {
    optionGroupPrefixes.forEach((optionGroupPrefix) => {
      const optionGroups = optionGroupPrefix.optionGroups;

      const optionGroupsMapped = optionGroups.map((optionGroup) => {
        const ids = optionGroup.options.map((option) => option.id);
        let positions = [];
        let products = [];
        let fileLabels = [];
        let rooms = [];
        price = 0;
        optionGroup.options.forEach((option) => {
          if (option.numbers) {
            option.numbers.forEach((number) => {
              positions.push(number.number);
            });
          }
          if (option.roomFrom) {
            rooms.push(option.roomFrom?.value)
          }
          price += option.price * 1;
          products = products.concat(option.products);
          fileLabels = fileLabels.concat(option.fileLabels);
        });

        positions = positions.sort((a, b) => a - b);

        return {
          ...optionGroup,
          ids,
          positions,
          price,
          products,
          fileLabels,
          rooms,
        };
      });

      response = response.concat(optionGroupsMapped);
    });
  }
  return response;
};

export const prepareOptionDataExploded = (optionData, groupByRoom) => {
  const optionGroupPrefixes = optionData?.data.optionGroupPrefixes;
  const optionsExploded = [];

  if (optionGroupPrefixes) {
    optionGroupPrefixes.forEach((optionGroupPrefix) => {
      const optionGroups = optionGroupPrefix.optionGroups;
      optionGroups.forEach((optionGroup) => {
        optionGroup.options.forEach((option) => {
          let positions = [];

          if (option.numbers) {
            option.numbers.forEach((number) => {
              positions.push(number.number);
            });
          }

          positions = positions.sort((a, b) => a - b);

          optionsExploded.push({
            ids: optionGroup.isDefault ? optionGroup.options.map((option) => option.id) : [option.id],
            positions,
            ...optionGroup,
            ...option,
          });
        });
      });
    });
  }

  const groupedOptionsExploded = [];
  if (groupByRoom) {
    optionsExploded.forEach((option) => {
      const index = groupedOptionsExploded.findIndex(
        (el) => el?.roomFrom?.id === option?.roomFrom?.id,
      );
      if (index !== -1) {
        groupedOptionsExploded[index] = {
          ...groupedOptionsExploded[index],
          ids: groupedOptionsExploded[index].ids.concat(option.ids),
          positions: groupedOptionsExploded[index].positions.concat(option.positions),
          fileLabels: groupedOptionsExploded[index].fileLabels.concat(option.fileLabels),
          price: groupedOptionsExploded[index].price * 1 + option.price,
        };
      } else {
        groupedOptionsExploded.push(option);
      }
    });
  }

  return groupByRoom ? groupedOptionsExploded : optionsExploded;
};

export const getLayoutLayers = (optionData) => {
  let layoutLayers = [];

  optionData.forEach((el) => {
    el.options.forEach((option) => {
      option.fileLabels.forEach((fileLabel) => {
        layoutLayers.push(fileLabel.url);
      });
    });
  });

  return layoutLayers;
};

export const getTagsLayers = (optionData) => {
  const tagsLayers = [];

  optionData.forEach((el) => {
    el.options.forEach((option) => {
      option.tags.forEach((tag) => {
        tag.fileLabels.forEach((fileLabel) => {
          tagsLayers.push(fileLabel.url);
        });
      });
    });
  });

  return tagsLayers;
};

export const groupRowsByKey = (rows, answerKey, useNameToMerge, usePartOfName = '', useDefault, useNotDefault) => {
  let newRows = rows;

  const keyAnswers = useDefault ? 
    rows.filter((el) => el.key === answerKey && el.isDefault) : 
    useNotDefault ? 
    rows.filter((el) => el.key === answerKey && !el.isDefault) :  
    rows.filter((el) => el.key === answerKey)
  if (keyAnswers.length > 0) {
    const newKeyAnswers = [];
    keyAnswers.forEach((answer) => {
      const answerIndex = !useNameToMerge
        ? usePartOfName !== ''
        ? newKeyAnswers.findIndex((el) => {
          return el.name.includes(usePartOfName)
        })
        : newKeyAnswers.findIndex((el) => el.description === answer.description)
        : newKeyAnswers.findIndex((el) => el.name === answer.name);

      if (answerIndex >= 0) {
        const oldNumbers = newKeyAnswers[answerIndex].numbers;
        const oldFileLabels = newKeyAnswers[answerIndex].fileLabels;
        const oldTag = newKeyAnswers[answerIndex]?.tags;
        const oldPositions = Array.isArray(newKeyAnswers[answerIndex].positions)
          ? newKeyAnswers[answerIndex].positions
          : [newKeyAnswers[answerIndex].positions];

        newKeyAnswers[answerIndex].numbers = oldNumbers.concat(answer.numbers);
        newKeyAnswers[answerIndex].positions = oldPositions.concat([answer.position]);
        newKeyAnswers[answerIndex].isDefault = answer.isDefault;
        newKeyAnswers[answerIndex].price += answer.price;
        newKeyAnswers[answerIndex].fileLabels = oldFileLabels.concat(answer.fileLabels);
        newKeyAnswers[answerIndex].tags = oldTag?.concat(answer.tags);
      } else {
        newKeyAnswers.push(answer);
      }
    });

    newRows = useDefault ? 
    rows.filter((el) => el.key !== answerKey || (el.key === answerKey && !el.isDefault)) : 
    useNotDefault ? 
    rows.filter((el) => el.key !== answerKey || (el.key === answerKey && el.isDefault)) : 
    rows.filter((el) => el.key !== answerKey);
    newRows = [...newRows, ...newKeyAnswers];

    newRows.sort((a, b) => {
      if(a.phase?.id === b.phase?.id) {
        if(a.step?.id === b.step?.id) {
          return a.substep?.id - b.substep?.id
        }
        return a.step?.id - b.step?.id
      }
      return a.phase?.id - b.phase?.id
    });
  }

  return newRows;
};

// DA RIVEDERE
export const handleDoorsTypologyOptionPlanimetryLayersUpdated = (
  options,
  defaultIds,
  selectedOptionIds,
  showLayerOnHoverRef,
  baseImagesRef,
  setImages,
  baseImagesWithOptionsRef,
  tags = [],
  removeImagesStartByLetter,
  defaultOption,
) => {
  const baseImages = getRemoveImagesStartByLetter(baseImagesRef.current, removeImagesStartByLetter);

  if (selectedOptionIds?.length === 0) {
    showLayerOnHoverRef.current = true;
    const defaultOptions = options.filter((el) => el.isDefault).map(corrisp => corrisp.fileLabels 
    ?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)
    .map((el) => el.url)[0]);
    const notDefaultOptions = options.filter((el) => !el.isDefault).map(corrisp => corrisp.fileLabels 
    ?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)
    .map((el) => el.url)[0]);
    const notDefaultOptionsTags = options.filter((el) => !el.isDefault)?.map((elt) => elt.tags?.map(corrisp => corrisp.fileLabels 
      ?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)
      .map((el) => el.url)[0])[0]);

    const needToIns= [];
    defaultOptions?.forEach(urlToIns => {
      let isIn = false;
      baseImagesWithOptionsRef?.current?.forEach(urlToControls => {
        if(urlToIns === (_.isString(urlToControls) ? urlToControls : urlToControls?.fileUrl)){
          isIn = true; 
        }
      })
        if(!isIn) needToIns.push(urlToIns);
    });
    baseImagesWithOptionsRef.current = baseImagesWithOptionsRef.current?.filter((elemento, index) => (!notDefaultOptions?.includes(_.isString(elemento) ? elemento : elemento?.fileUrl) && !notDefaultOptionsTags?.includes(_.isString(elemento) ? elemento : elemento?.fileUrl)));
    baseImagesWithOptionsRef.current = baseImagesWithOptionsRef.current.concat(needToIns);
    setImages(baseImagesWithOptionsRef.current);
  } else if (_.isEqual(defaultIds, selectedOptionIds)) {
    const notDefaultOptions = options.filter((el) => !el.isDefault).map(corrisp => corrisp.fileLabels 
      ?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)
      .map((el) => el.url)[0]);
    const notDefaultOptionsTags = options.filter((el) => !el.isDefault)?.map((elt) => elt.tags?.map(corrisp => corrisp.fileLabels 
      ?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)
      .map((el) => el.url)[0])[0]);
    // Se è selezionata l'opzione di default, la logica del hover su card va riattivata
    showLayerOnHoverRef.current = true;
    // Bisogna rimuovere il layer dell'opzione scelta precedentemente

    let newImages = [];
    const defaultOptions = options.filter((el) => el.isDefault);

    newImages = newImages.concat(defaultOptions?.map(corrisp => corrisp.fileLabels
      ?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)
      .map((el) => el.url)[0]));

    const optionTags = defaultOptions?.map(corrisp => corrisp.tags)?.map((tag) => {
      return tag?.map((elt) => elt?.fileLabels
      ?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)
      .map((el) => el.url)[0]);
    });

    optionTags?.forEach(optionTag => newImages = newImages.concat(optionTag));
    
    baseImagesWithOptionsRef.current = baseImagesWithOptionsRef.current?.filter((elemento, index) => (!notDefaultOptions?.includes(_.isString(elemento) ? elemento : elemento?.fileUrl) && !notDefaultOptionsTags?.includes(_.isString(elemento) ? elemento : elemento?.fileUrl)))
    setImages(
      baseImagesWithOptionsRef.current.concat(tags).concat(newImages)
    );
  } else {
    if (!baseImagesWithOptionsRef?.current) {
      // Se è selezionata un opzione diversa, bisogna impostare il suo layer sulla planimetria
      showLayerOnHoverRef.current = false;

      // Bisogna impostare il layer dell'opzione scelta
      const option = options?.find((el) => _.difference(el.ids, selectedOptionIds).length === 0);

      const optionTags = option?.tags?.map((tag) => {
        return tag?.fileLabels
          ?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)
          .map((el) => el.url);
      });
        
      const baseImages = getRemoveImagesStartByLetter(
        baseImagesRef.current,
        removeImagesStartByLetter,
      );

      setImages(
        removeImagesStartByLetter
          ? baseImages.concat(tags).concat(optionTags)
          : baseImagesRef.current.concat(tags).concat(optionTags),
      );
    } else {
      const selectedOptions = options?.filter(
        (el) => _.difference(el.ids, selectedOptionIds).length === 0,
      );
      const selectedOptionsPos = selectedOptions.map(selected => JSON.stringify(selected.positions))
      const defaultOptions = options.filter((el) => el.isDefault);
      const notDefaultOptions = options.filter((el) => !el.isDefault);
      
      const selectedButDefault = defaultOptions
      ?.filter(def => selectedOptionsPos.includes(JSON.stringify(def.positions)))
      .map(corrisp => corrisp.fileLabels 
        ?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)
        .map((el) => el.url)[0]);

      const selectedNeedToDefault = defaultOptions
      ?.filter(def => !selectedOptionsPos.includes(JSON.stringify(def.positions)))
      .map(corrisp => corrisp.fileLabels 
        ?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)
        .map((el) => el.url)[0]);

      const notSelectedOptions = notDefaultOptions
      ?.filter(def => !selectedOptionsPos.includes(JSON.stringify(def.positions)))
      .map(corrisp => corrisp.fileLabels 
        ?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)
        .map((el) => el.url)[0]);

      let newImages = [];

      selectedOptions?.forEach((option) => {

        const optionImages = option?.fileLabels
        ?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)
        .map((el) => el.url);
        newImages = newImages.concat(optionImages);

        const optionTags = option?.tags?.map((tag) => {
          return tag?.fileLabels
          ?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)
          .map((el) => el.url);
        });

        optionTags.forEach(optionTag => newImages = newImages.concat(optionTag));

        const baseImages = getRemoveImagesStartByLetter(
          baseImagesRef.current,
          removeImagesStartByLetter,
        );

        // setImages(
        //   removeImagesStartByLetter
        //     ? baseImages.concat(optionImages).concat(tags)
        //     : baseImagesRef.current.concat(optionImages).concat(tags),
        // );

        baseImagesWithOptionsRef.current = removeImagesStartByLetter
          ? baseImages.concat(newImages)
          : baseImagesRef.current.concat(newImages);
        const needToIns= [];
        selectedNeedToDefault.forEach(urlToIns => {
          let isIn = false;
          baseImagesWithOptionsRef.current.forEach(urlToControls => {
            if(urlToIns === (_.isString(urlToControls) ? urlToControls : urlToControls?.fileUrl)) isIn = true;
          })
          if(!isIn) needToIns.push(urlToIns);
        });
        baseImagesWithOptionsRef.current = baseImagesWithOptionsRef.current?.filter(urlToDel => (!selectedButDefault.includes(_.isString(urlToDel) ? urlToDel : urlToDel?.fileUrl) && !notSelectedOptions.includes(_.isString(urlToDel) ? urlToDel : urlToDel?.fileUrl)))
        setImages(baseImagesWithOptionsRef.current.concat(tags).concat(needToIns));
      });
    }
  }
};

export const handleOptionPlanimetryLayersUpdated = (
  options,
  defaultIds,
  selectedOptionIds,
  showLayerOnHoverRef,
  baseImagesRef,
  setImages,
  baseImagesWithOptionsRef,
  tags = [],
  removeImagesStartByLetter,
  defaultOption,
) => {
  const baseImages = getRemoveImagesStartByLetter(baseImagesRef.current, removeImagesStartByLetter);

  if (selectedOptionIds?.length === 0) {
    showLayerOnHoverRef.current = true;
    if (baseImagesWithOptionsRef) {
      baseImagesWithOptionsRef.current = baseImagesRef.current;
    }
    setImages(removeImagesStartByLetter ? baseImages : baseImagesRef.current);
  } else if (_.isEqual(defaultIds, selectedOptionIds)) {
    // Se è selezionata l'opzione di default, la logica del hover su card va riattivata
    showLayerOnHoverRef.current = true;

    if (baseImagesWithOptionsRef) {
      baseImagesWithOptionsRef.current = baseImagesRef.current;
    }
    // Bisogna rimuovere il layer dell'opzione scelta precedentemente
    // baseImagesRef.current = baseImagesRef.current.concat(tags);

    let newImages = [];
    const defaultOptions = options.filter((el) => el.isDefault);

    /*
    const optionImages = defaultOptions?.fileLabels
      ?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)
      .map((el) => el.url);
    newImages = newImages.concat(optionImages);
    */

    defaultOptions.forEach((defaultOp) => {
      const addingFileLabels = defaultOp?.fileLabels?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)
      .map((el) => el.url);
      if(addingFileLabels.length > 0 ) newImages = newImages.concat(addingFileLabels);
    });

    defaultOptions.forEach((defaultOp) => {
      const optionTags = defaultOp?.tags?.map((tag) => {
        return tag?.fileLabels
        ?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)
        .map((el) => el.url);
      });
      optionTags?.forEach(optionTag => newImages = newImages.concat(optionTag));
    });

    baseImagesWithOptionsRef.current = baseImagesRef.current.concat(tags).concat(newImages)

    /*
    const optionTags = defaultOptions?.tags?.map((tag) => {
      return tag?.fileLabels
      ?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)
      .map((el) => el.url);
    });
    optionTags?.forEach(optionTag => newImages = newImages.concat(optionTag));    
    */

    setImages(
      removeImagesStartByLetter
        ? [...baseImages, ...newImages, ...tags]
        : [...baseImagesRef.current, ...newImages, ...tags],
    );
  } else {
    if (!baseImagesWithOptionsRef?.current) {
      // Se è selezionata un opzione diversa, bisogna impostare il suo layer sulla planimetria
      showLayerOnHoverRef.current = false;

      // Bisogna impostare il layer dell'opzione scelta
      const option = options?.find((el) => _.difference(el.ids, selectedOptionIds).length === 0);
      const optionImages = option?.fileLabels
        ?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)
        .map((el) => el.url);

        const optionTags = option?.tags?.map((tag) => {
          return tag?.fileLabels
            ?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)
            .map((el) => el.url);
        });

      const baseImages = getRemoveImagesStartByLetter(
        baseImagesRef.current,
        removeImagesStartByLetter,
      );

      setImages(
        removeImagesStartByLetter
          ? baseImages.concat(optionImages).concat(tags).concat(optionTags)
          : baseImagesRef.current.concat(optionImages).concat(tags).concat(optionTags),
      );
    } else {
      const selectedOptions = options?.filter(
        (el) => _.difference(el.ids, selectedOptionIds).length === 0,
      );
      let newImages = [];

      selectedOptions?.forEach((option) => {
        
        const optionImages = option?.fileLabels
          ?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)
          .map((el) => el.url);
        newImages = newImages.concat(optionImages);        

        const optionTags = option?.tags?.map((tag) => {
          return tag?.fileLabels
          ?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)
          .map((el) => el.url);
        });

        optionTags.forEach(optionTag => newImages = newImages.concat(optionTag));

        const baseImages = getRemoveImagesStartByLetter(
          baseImagesRef.current,
          removeImagesStartByLetter,
        );

        // setImages(
        //   removeImagesStartByLetter
        //     ? baseImages.concat(optionImages).concat(tags)
        //     : baseImagesRef.current.concat(optionImages).concat(tags),
        // );

        baseImagesWithOptionsRef.current = removeImagesStartByLetter
          ? baseImages.concat(newImages)
          : baseImagesRef.current.concat(newImages);
        setImages(baseImagesWithOptionsRef.current.concat(tags));
      });
    }
  }
};

export const handleOptionPlanimetryLayers = (
  options,
  defaultIds,
  selectedOptionIds,
  showLayerOnHoverRef,
  baseImagesRef,
  setImages,
  baseImagesWithOptionsRef,
  tags = [],
  removeImagesStartByLetter,
  defaultOption,
) => {
  const baseImages = getRemoveImagesStartByLetter(baseImagesRef.current, removeImagesStartByLetter);

  if (selectedOptionIds?.length === 0) {
    showLayerOnHoverRef.current = true;
    if (baseImagesWithOptionsRef) {
      baseImagesWithOptionsRef.current = baseImagesRef.current;
    }
    setImages(removeImagesStartByLetter ? baseImages : baseImagesRef.current);
  } else if (_.isEqual(defaultIds, selectedOptionIds)) {
    // Se è selezionata l'opzione di default, la logica del hover su card va riattivata
    showLayerOnHoverRef.current = true;

    if (baseImagesWithOptionsRef) {
      baseImagesWithOptionsRef.current = baseImagesRef.current;
    }
    // Bisogna rimuovere il layer dell'opzione scelta precedentemente

    const optionImages = defaultOption?.fileLabels
      ?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)
      .map((el) => el.url);

    setImages(
      removeImagesStartByLetter
        ? [...baseImages, ...optionImages, ...tags]
        : [...baseImagesRef.current, ...tags],
    );
  } else {
    if (!baseImagesWithOptionsRef?.current) {
      // Se è selezionata un opzione diversa, bisogna impostare il suo layer sulla planimetria
      showLayerOnHoverRef.current = false;

      // Bisogna impostare il layer dell'opzione scelta
      const option = options?.find((el) => _.difference(el.ids, selectedOptionIds).length === 0);
      const optionImages = option?.fileLabels
        ?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)
        .map((el) => el.url);

      const baseImages = getRemoveImagesStartByLetter(
        baseImagesRef.current,
        removeImagesStartByLetter,
      );

      setImages(
        removeImagesStartByLetter
          ? baseImages.concat(optionImages).concat(tags)
          : baseImagesRef.current.concat(optionImages).concat(tags),
      );
    } else {
      const selectedOptions = options?.filter(
        (el) => _.difference(el.ids, selectedOptionIds).length === 0,
      );
      let newImages = [];

      selectedOptions?.forEach((option) => {
        
        const optionImages = option?.fileLabels
          ?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)
          .map((el) => el.url);
        newImages = newImages.concat(optionImages);

        const baseImages = getRemoveImagesStartByLetter(
          baseImagesRef.current,
          removeImagesStartByLetter,
        );

        // setImages(
        //   removeImagesStartByLetter
        //     ? baseImages.concat(optionImages).concat(tags)
        //     : baseImagesRef.current.concat(optionImages).concat(tags),
        // );

        baseImagesWithOptionsRef.current = removeImagesStartByLetter
          ? baseImages.concat(newImages)
          : baseImagesRef.current.concat(newImages);
        setImages(baseImagesWithOptionsRef.current.concat(tags));
      });
    }
  }
};

export const handleDoorsTypologyOptionPlanimetryLayers = (
  options,
  defaultIds,
  selectedOptionIds,
  showLayerOnHoverRef,
  baseImagesRef,
  setImages,
  defaultOption,
  baseLayoutLayersRef,
) => {
  if (selectedOptionIds?.length === 0) {
    showLayerOnHoverRef.current = true;
  } else if (_.isEqual(defaultIds, selectedOptionIds)) {
    // Se è selezionata l'opzione di default, la logica del hover su card va riattivata
    showLayerOnHoverRef.current = false;

    const optionImages = defaultOption?.fileLabels
      ?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)
      .map((el) => el.url);

    const baseImages = [...baseImagesRef.current];
    baseImages.shift();

    const images = [
      { fileUrl: baseLayoutLayersRef.current[defaultOption.value], order: 1 },
      ...baseImages,
    ];

    setImages([...images, ...optionImages]);
  } else {
    // Se è selezionata un opzione diversa, bisogna impostare il suo layer sulla planimetria
    showLayerOnHoverRef.current = false;

    // Bisogna impostare il layer dell'opzione scelta
    const option = options?.find((el) => _.difference(el.ids, selectedOptionIds).length === 0);

    const optionImages = option?.fileLabels
      ?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)
      .map((el) => el.url);

    const baseImages = [...baseImagesRef.current];
    baseImages.shift();

    const images = [
      { fileUrl: baseLayoutLayersRef?.current[option.value], order: 1 },
      ...baseImages,
    ];

    setImages([...images, ...optionImages]);
  }
};

export const getRemoveImagesStartByLetter = (images, letter) => {
  let baseImages = [];
  if (letter) {
    baseImages = images.filter((el) => {
      if (el.fileUrl) {
        const fileName = getFileNameFromUrl(el.fileUrl);
        if (fileName[0] === letter) {
          return false;
        }
      }

      return true;
    });
  }
  return baseImages;
};
