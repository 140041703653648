import { Grid, makeStyles } from '@material-ui/core';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router';
import { observer } from 'mobx-react-lite';
import SideMenu from '../../components/SideMenu/SideMenu';
import FinitureGiornoNotte from './giornoNotte/FinitureGiornoNotte';
import TonalitaGiornoNotte from './giornoNotte/TonalitaGiornoNotte';
import FormatoGiornoNotte from './giornoNotte/FormatoGiornoNotte';
import MoodGiornoNotte from './giornoNotte/MoodGiornoNotte';
import RelazioneLivingCucina from './giornoNotte/RelazioneLivingCucina';
import CartaparatiGiornoNotte from './giornoNotte/CartaparatiGiornoNotte';
import ColorePorteGiornoNotte from './giornoNotte/ColorePorteGiornoNotte';
import ManigliaPorteGiornoNotte from './giornoNotte/ManigliaPorteGiornoNotte';
import SistemaChiusuraProfilo from './giornoNotte/SistemaChiusuraProfilo';
import SistemaChiusuraVetro from './giornoNotte/SistemaChiusuraVetro';
import AltreDotazioniPlacchette from './giornoNotte/AltreDotazioniPlacchette';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    '& .sidebar-container': {
      height: '100%',
    },
    '& > div': {
      height: '100%',
    },
  },
}));

const GiornoNottePage = observer(() => {
  const { path } = useRouteMatch();
  const classes = useStyles();

  const giornoNotteSubsteps = [
    { stepKey: 'Mood', substepKey: 'Mood', component: <MoodGiornoNotte /> },
    { stepKey: 'PAVIMENTOLIVINGECAMERE', substepKey: 'FINITURA', component: <FinitureGiornoNotte /> },
    { stepKey: 'PAVIMENTOLIVINGECAMERE', substepKey: 'TONALITA', component: <TonalitaGiornoNotte /> },
    { stepKey: 'PAVIMENTOLIVINGECAMERE', substepKey: 'FORMATO', component: <FormatoGiornoNotte /> },
    { stepKey: 'PAVIMENTOCUCINA', substepKey: 'RELAZIONELIVINGCUCINA', component: <RelazioneLivingCucina /> },
    { stepKey: 'PAVIMENTOCUCINA', substepKey: 'FINITURA', component: <FinitureGiornoNotte /> },
    { stepKey: 'PAVIMENTOCUCINA', substepKey: 'TONALITA', component: <TonalitaGiornoNotte /> },
    { stepKey: 'PAVIMENTOCUCINA', substepKey: 'FORMATO', component: <FormatoGiornoNotte /> },
    { stepKey: 'CARTAPARATI', substepKey: 'CARTAPARATI', component: <CartaparatiGiornoNotte /> },
    { stepKey: 'PORTE', substepKey: 'COLORE', component: <ColorePorteGiornoNotte /> },
    { stepKey: 'PORTE', substepKey: 'MANIGLIA', component: <ManigliaPorteGiornoNotte /> },
    { stepKey: 'PORTEARREDO', substepKey: 'PROFILO', component: <SistemaChiusuraProfilo /> },
    { stepKey: 'PORTEARREDO', substepKey: 'VETRO', component: <SistemaChiusuraVetro /> },
    { stepKey: 'ALTREDOTAZIONI', substepKey: 'PLACCHETTEELETTRICHE', component: <AltreDotazioniPlacchette /> },
  ];

  return (
    <Grid container className={classes.root} wrap='nowrap'>
      <Grid item xs className={'configurator-body'}>
        <Switch>
          {giornoNotteSubsteps.map((el) => (
            <Route
              key={`${el.stepKey}/${el.substepKey}`}
              path={`${path}/${el.stepKey}/${el.substepKey}`}
            >
              {el.component}
            </Route>
          ))}
        </Switch>
      </Grid>
      <Grid item xs='auto' className='sidebar-container'>
        <SideMenu />
      </Grid>
    </Grid>
  );
});

export default GiornoNottePage;
