import * as React from "react";

function SvgAlertTriangle(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M39.343 26.198L23.88 2.86C22.619.89 19.76.283 17.659 1.495c-.672.38-1.176.834-1.512 1.364L.514 26.2c-1.009 1.59-.504 3.637 1.26 4.546.589.303 1.261.455 1.85.455h32.61c2.017 0 3.698-1.516 3.698-3.334-.084-.607-.252-1.137-.589-1.668zm-3.11 2.804H3.709c-.673 0-1.177-.454-1.177-1.06 0-.228.084-.38.168-.531L18.164 4.07c.588-.909 1.849-1.136 2.857-.606.252.152.505.38.673.607l15.464 23.263c.336.53.168 1.137-.42 1.516-.084.075-.336.151-.504.151z"
        fill={props.color}
      />
      <path
        d="M19.938 20.466c-.672 0-1.176-.455-1.176-1.061v-7.426c0-.606.504-1.061 1.176-1.061.673 0 1.177.455 1.177 1.06v7.427c0 .606-.504 1.06-1.177 1.06zM19.937 24.71c.975 0 1.765-.713 1.765-1.591 0-.88-.79-1.592-1.765-1.592-.975 0-1.765.713-1.765 1.592 0 .878.79 1.591 1.765 1.591z"
        fill={props.color}
      />
    </svg>
  );
}

export default SvgAlertTriangle;
