import { Grid, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import ConfiguratorIntro from '../../../components/configurator/ConfiguratorIntro';
import { PortaScorrevole, SwingDoor } from '../../../components/Icons';
import ConfiguratorSelectionLayout from '../../../components/Layout/ConfiguratorSelectionLayout.jsx';
import ConfiguratorTwoColumnsPlanimetryLayout from '../../../layouts/ConfiguratorTwoColumnsPlanimetryLayout';
import { getOption } from '../../../services/optionApi';
import { useStore } from '../../../store/storeUtils';
import DefaultPorte from './currentConfiguration/DefaultPorte';
import _ from 'lodash';
import {
  handleDoorsTypologyOptionPlanimetryLayersUpdated,
  prepareOptionDataExploded,
} from '../../../utils/optionsUtils';
import { useParams } from 'react-router';
import { useRef } from 'react';
import { handleSave, multipleChoiceSelection } from '../../../utils/selectionUtils';
import { getLayoutImagesByConfigurationId } from '../../../services/layoutApi';
import clsx from 'clsx';
import {
  handleCardMouseEnterSimpleTypology,
  handleCardMouseLeaveSimpleTypology,
  resumeAnswerSimple,
} from '../../../utils/configuratorUtils';
import OptionCard, { StandardOptionCardBody } from '../../../components/Card/OptionCard';
import portaBattente from '../../../assets/POR_B_C_80_210.jpg';
import pdfPortaB210 from '../../../assets/POR_B_C_80_210.pdf';
import pdfPortaSI210 from '../../../assets/POR_SI_C_80_210.pdf';
import portaNotDef from '../../../assets/POR_SI_C_80_210.jpg';

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: '#EEEEEE',
    width: '100%',
  },
  list: { marginTop: '25px', padding: '0 22px 22px' },
  alertMessage: { marginBottom: '5px' },
  scrollableContent: {
    backgroundColor: '#EEEEEE',
  },
}));

const FirstColumn = ({
  defaultOption,
  onClick,
  selectedOptions,
  cardOnMouseEnter,
  cardOnMouseLeave,
}) => {
  // Recupero le tipologie di opzioni di default
  let typologies = defaultOption?.options.map((el) => el.description);
  typologies = [...new Set(typologies)];

  const positionsByTypology = {};
  const productsByTypology = {};
  const fileLabelsByTypology = {};
  const tagsByTypology = {};
  typologies.forEach((typology) => {
    positionsByTypology[typology] = [];
    productsByTypology[typology] = [];
    fileLabelsByTypology[typology] = [];
    tagsByTypology[typology] = [];
    const optionsByTypology = defaultOption?.options.filter((el) => el.description === typology);

    optionsByTypology.forEach((option) => {
      const numbers = option.numbers.map((number) => number.number);
      positionsByTypology[typology] = [...positionsByTypology[typology], ...numbers];
    });
    optionsByTypology.forEach((option) => {
      productsByTypology[typology] = [...productsByTypology[typology], ...option.products];
    });
    optionsByTypology.forEach((option) => {
      tagsByTypology[typology] = [...tagsByTypology[typology], ...option.tags];
    });
  });

  // Costruisco l'oggetto da visualizzare
  const defaultPorteTipologiaEPosizione = typologies?.map((typology) => {
    return {
      title: typology,
      description: 'Disposte nelle seguenti posizioni:',
      positions: positionsByTypology[typology],
      products: [
        { imageFileUrl: portaBattente, dataSheetFileUrl: pdfPortaB210 },
      ],
      icon: typology === 'Porta Battente' ? <SwingDoor color= 'black' /> : <PortaScorrevole />,
      fileLabels: fileLabelsByTypology[typology],
      tags: tagsByTypology[typology],
      cardOnMouseEnter,
      cardOnMouseLeave,
    };
  });

  return (
    defaultOption && (
      <DefaultPorte
        elements={defaultPorteTipologiaEPosizione}
        defaultImage={`${process.env.PUBLIC_URL}/assets/icons/standard-glass.svg`}
        isSelected={_.isEqual(defaultOption.ids, selectedOptions)}
        onClick={() => {
          onClick(defaultOption.ids);
        }}
        plateText={'P-'}
        subtitle={'Conferma questa soluzione oppure scegli opzioni alternative a lato'}
      />
    )
  );
};

const SecondColumn = ({
  options,
  selectedOptions,
  onClick,
  cardOnMouseEnter,
  cardOnMouseLeave,
}) => {
  const classes = useStyles();

  return (
    <ConfiguratorSelectionLayout scrollableContentClasses={clsx(classes.scrollableContent)}>
      <div className={classes.content}>
        <ConfiguratorIntro
          icon={<PortaScorrevole />}
          title={'Quali porte a battente vuoi rendere scorrevoli?'}
          topLeftTitle={'tipologia'}
          badgeText={'Potrai personalizzare le finiture in una fase successiva.'}
          badgeTextOrange={'Per ragioni tecniche alcune porte rimarranno a battente. '}
        />
        <Grid container className={classes.list} spacing={2} justifyContent='center'>
          {options.map((el, i) => (
            <Grid item key={i} xs={6} lg={4}>
              <OptionCard
                {...el}
                onClick={() => onClick(el.ids)}
                isSelected={_.difference(el.ids, selectedOptions).length === 0}
                onMouseEnter={cardOnMouseEnter}
                onMouseLeave={cardOnMouseLeave}
                multiple
                products={[
                  { imageFileUrl: portaNotDef, dataSheetFileUrl: pdfPortaSI210 },
                ]}
                infoPriceMessage='Il prezzo comprende la sostituzione della porta a battente con la porta scorrevole, inclusa la fornitura e la posa in opera del cassonetto interno muro.'
              >
                <StandardOptionCardBody
                  {...el}
                  message={'Seleziono le porte scorrevoli nella posizione:'}
                  plateText={'P-'}
                  hideName={true}
                />
              </OptionCard>
            </Grid>
          ))}
        </Grid>
      </div>
    </ConfiguratorSelectionLayout>
  );
};

const PorteTipologiaPosizione = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const [defaultOption, setDefaultOption] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [images, setImages] = useState([]);
  const [baseOptions, setBaseOptions] = useState([]);

  const substepIdRef = useRef();
  const oldAnswersRef = useRef();
  const showLayerOnHoverRef = useRef(true);
  const baseImagesRef = useRef([]);
  const baseImagesWithOptionsRef = useRef([]);

  const store = useStore();
  const params = useParams();

  useEffect(() => {
    store.setUserCanGoNext(false);
    prepareOptions();
  }, []);

  const prepareOptions = async () => {
    const substepId = store.getSubstepIdByFrontendId(store.currentSubstep);

    let optionData = await getOption(
      store.currentConfiguration?.layout?.id,
      substepId,
      params.configuration,
    );
    substepIdRef.current = optionData?.data?.substep?.id;

    optionData = prepareOptionDataExploded(optionData);

    // Imposto i layer da far vedere
    const layoutImagesResponse = await getLayoutImagesByConfigurationId(params.configuration);
    const layoutImages = layoutImagesResponse.data;

    setOptions(
      optionData
        .filter((el) => !el.isDefault)
        ?.sort((a, b) => a?.positions[0] - b?.positions[0]), // Ordinamento opzioni
    );
    setBaseOptions(optionData);
    const defaultOption = optionData.find((el) => el.isDefault);
    setDefaultOption(defaultOption);

    baseImagesRef.current = layoutImages;

    setImages(layoutImages);

    baseImagesWithOptionsRef.current = layoutImages;

    resumeAnswerSimple(store, oldAnswersRef, substepIdRef, setSelectedOptions, setIsLoading);
  };

  useEffect(() => {
    let selectedOptionsToSave = selectedOptions;  
    const save = async () => {
      if (options.length > 0 && defaultOption) {
        const optionSelected = options.filter(option => selectedOptions.includes(option?.id));
        const optionsSelectedNumber = optionSelected.length > 0 && optionSelected.map(selectedOpt => selectedOpt?.numbers[0]?.number);
        defaultOption.options.forEach((defaultOpt) => {
          const defNumber = defaultOpt?.numbers[0]?.number;
          const defId = defaultOpt?.id;
          if (optionsSelectedNumber.length > 0 && !optionsSelectedNumber.includes(defNumber)) selectedOptionsToSave.push(defId);
        });
      };
      await handleSave(selectedOptionsToSave, params.configuration, substepIdRef.current, store);
    };

    if (!_.isEqual(oldAnswersRef.current, selectedOptionsToSave)) {
      store.setSubstepSave(save);
    } else {
      store.setSubstepSave(null);
    }
  }, [selectedOptions]);

  const handleUserSelection = (selection) => {
    multipleChoiceSelection(
      selection,
      selectedOptions,
      defaultOption.ids,
      setSelectedOptions,
      store,
    );
  };

  // Gestione delle immagini della planimetria alla selezione di un opzione
  useEffect(() => {
    handleDoorsTypologyOptionPlanimetryLayersUpdated(
      baseOptions,
      defaultOption?.ids,
      selectedOptions,
      showLayerOnHoverRef,
      baseImagesRef,
      setImages,
      baseImagesWithOptionsRef,
    );
  }, [selectedOptions]);

  const handleCardMouseEnter = (fileLabels, tags) => {
    handleCardMouseEnterSimpleTypology(
      baseOptions,
      fileLabels,
      showLayerOnHoverRef,
      baseImagesWithOptionsRef,
      setImages,
      tags,
    );
  };
  const handleCardMouseLeave = () => {
    handleCardMouseLeaveSimpleTypology(baseOptions, selectedOptions, defaultOption?.ids, showLayerOnHoverRef, baseImagesWithOptionsRef, setImages);
  };

  return (
    <ConfiguratorTwoColumnsPlanimetryLayout
      planimetryImages={images}
      firstColumn={
        <FirstColumn
          defaultOption={defaultOption}
          selectedOptions={selectedOptions}
          onClick={handleUserSelection}
          cardOnMouseEnter={handleCardMouseEnter}
          cardOnMouseLeave={handleCardMouseLeave}
        />
      }
      secondColumn={
        <SecondColumn
          options={options}
          selectedOptions={selectedOptions}
          onClick={handleUserSelection}
          cardOnMouseEnter={handleCardMouseEnter}
          cardOnMouseLeave={handleCardMouseLeave}
        />
      }
      isLoading={isLoading}
      firstRowTitle={'IL TUO '}
      secondRowTitle={'APPARTAMENTO'}
      secondColumnFirstRowTitle={'SOLUZIONI'}
      secondColumnSecondRowTitle={'AGGIUNTIVE'}
      noOption={options?.length === 0}
      noOptionIcon={<PortaScorrevole />}
      noOptionText={'Non puoi sostituire le porte battenti con porte scorrevoli.'}
      optionLayersOnTop
    />
  );
};

export default PorteTipologiaPosizione;
