import { Grid, makeStyles } from '@material-ui/core';
import '../styles/loadingAnimation.css';
import clsx from 'clsx';
import Button3D from './Buttons/Button3D';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  animationRoot: {
    position: 'relative',
    textAlign: 'center',
    display: 'flex',
  },
  label: {
    fontFamily: 'miller',
    fontSize: '18px',
    display: 'block',
    fontStyle: 'italic',
    marginBottom: '15px',
  },
}));

const ErrorPage = ({ text, disableRefresh }) => {
  const classes = useStyles();

  return (
    <Grid container justifyContent='center' alignItems='center' className={classes.root}>
      <Grid
        container
        direction='column'
        alignItems='center'
        className={clsx('loading-animation', classes.animationRoot)}
      >
        <Grid item>
          <ErrorOutlineIcon fontSize='large' />
        </Grid>
        <Grid item className={classes.label}>
          {text ? text : 'Aggiorna la pagina per ricominciare...'}
        </Grid>
        {!disableRefresh && (
          <Grid item>
            <Button3D text={'Aggiorna'} onClick={() => window.location.reload()} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ErrorPage;
