import * as React from 'react';

function SvgWindowOpacity(props) {
  return (
    <svg viewBox='0 0 119 139' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path fill='#D1D1D1' d='M0 0h119v138.87H0z' />
      <path d='M119 138.87H0V0h119v138.87zm-115-4h111V4H4v130.87z' fill='#AFAFAF' />
      <path
        d='M76.048 101.761l-36.97-55.16.2-.14 36.97 55.16-.2.14zM79.72 88.39L42.75 33.23l.2-.14 36.97 55.16-.2.14z'
        fill='#AFAFAF'
      />
    </svg>
  );
}

export default SvgWindowOpacity;
