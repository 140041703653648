import * as React from "react";

function SvgMagnifyingGlass(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.413 2.496c-1.691 0-3.061 1.352-3.061 3.02 0 1.667 1.37 3.018 3.06 3.018 1.692 0 3.062-1.351 3.062-3.019 0-1.667-1.37-3.019-3.061-3.019zm1.055 3.45h-.602v.594a.415.415 0 01-.829 0v-.594h-.602a.415.415 0 01-.415-.409c0-.223.188-.409.415-.409h.602V4.54a.415.415 0 01.829 0v.594h.602c.227 0 .415.185.415.408a.41.41 0 01-.415.404z"
        fill={props.color}
      />
      <path
        d="M8.415 0C5.33 0 2.823 2.474 2.823 5.515c0 .856.198 1.662.552 2.387-.039.027-.077.054-.116.087L.971 9.945a1.156 1.156 0 00-.116 1.652c.238.267.564.403.901.403.276 0 .553-.093.78-.289l2.281-1.956.017-.017a5.622 5.622 0 003.586 1.287c3.083 0 5.592-2.475 5.592-5.515 0-3.041-2.514-5.51-5.597-5.51zm0 9.777c-2.382 0-4.322-1.913-4.322-4.262 0-2.349 1.94-4.262 4.322-4.262 2.381 0 4.32 1.913 4.32 4.262 0 2.349-1.939 4.262-4.32 4.262z"
        fill={props.color}
      />
    </svg>
  );
}

export default SvgMagnifyingGlass;
