import * as React from 'react';

function SvgPreseLavanderia(props) {
  return (
    <svg viewBox='0 0 73 47' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#prese-lavanderia_svg__clip0_2758_23704)' fill='#C4C4C4'>
        <path d='M10.829 10.07c1.53.06 3.09-.29 4.48-1.02 1.08-.56 2.28-.83 3.46-.77 1.16-.06 2.36.21 3.45.77 1.31.66 2.9 1.02 4.47 1.02 1.54.06 3.09-.29 4.48-1.02a6.82 6.82 0 013.46-.77c1.16-.06 2.36.21 3.45.77.97.49 2.05.8 3.28.94h2.58l2.19-8.57c.13-.64-.26-1.24-.88-1.37a1.25 1.25 0 00-.94.17c-.24.16-.41.4-.46.67l-1.77 6.89c-1.11-.07-2.07-.32-2.93-.76-1.31-.66-2.89-1.03-4.46-1.03-1.54-.05-3.09.3-4.48 1.03-1.09.56-2.28.83-3.47.77-1.16.06-2.36-.21-3.45-.77-1.31-.66-2.9-1.03-4.46-1.03-1.54-.05-3.09.3-4.48 1.03-1.09.56-2.29.83-3.47.77-1.17.06-2.36-.21-3.45-.77-1.11-.59-2.42-.95-3.77-1.02L2.359.9c-.06-.31-.26-.58-.53-.75-.27-.15-.6-.19-.87-.1-.33.06-.6.24-.77.5-.17.25-.23.56-.16.87l7.7 30.64c.13.51.56.86 1.02.86.04 0 .08 0 .11-.01h20.38v-2.28H9.859L4.219 8.37c.8.12 1.51.34 2.14.68 1.31.66 2.9 1.02 4.47 1.02zM49.83 25.35h-2.47v6.18h2.47v-6.18zM39.96 25.35v6.16h2.47v-6.16h-2.47z' />
        <path d='M57.229 18.86c0-.73-.29-1.42-.8-1.93-.52-.52-1.21-.81-1.93-.81h-19.2c-.72 0-1.42.29-1.93.8-.51.52-.8 1.21-.81 1.94v19.19c.01.73.3 1.42.81 1.93s1.21.8 1.93.8h19.2c.72 0 1.41-.29 1.92-.8.52-.51.81-1.21.81-1.93V18.86zm-4.13 15.07a9.969 9.969 0 01-4.43 3.64c-1.8.74-3.79.94-5.7.56a9.87 9.87 0 01-5.05-2.7 9.329 9.329 0 01-1.81-2.52 8.91 8.91 0 01-.83-2.28c-.03-.08-.04-.17-.06-.25-.38-1.92-.19-3.9.56-5.7a9.844 9.844 0 012.94-3.92 9.331 9.331 0 012.88-1.6c1.05-.38 2.17-.57 3.3-.57a9.61 9.61 0 013.77.75c1.2.49 2.29 1.22 3.2 2.13.92.92 1.65 2.01 2.14 3.21.5 1.19.75 2.48.75 3.77 0 1.95-.58 3.86-1.66 5.48zM68.839 27.14h1.11c.14 0 .25-.11.25-.25v-4.74c0-.11 0-.22-.05-.33a.75.75 0 00-.18-.28.75.75 0 00-.47-.22.169.169 0 00-.1-.03c-.11-.01-.22 0-.33.05-.1.04-.2.1-.28.18a.75.75 0 00-.18.28c-.04.1-.05.22-.05.3v4.79c0 .14.11.25.25.25h.03zM62.558 27.12h1.05c.14 0 .25-.12.25-.25v-4.74a.696.696 0 00-.05-.32.624.624 0 00-.17-.28.75.75 0 00-.28-.18.86.86 0 00-.32-.05c-.09.01-.18.03-.27.06-.09.05-.18.11-.25.18a.65.65 0 00-.17.27c-.04.11-.05.21-.04.3v4.76c0 .13.11.25.25.25zM72.26 35.18v-6.01a.858.858 0 00-.17-.64.881.881 0 00-.6-.33H60.91a.834.834 0 00-.6.32.87.87 0 00-.16.3c-.03.11-.04.23-.03.31v6.01c-.01.12 0 .24.03.35a.87.87 0 00.43.52c.1.05.22.08.35.09h10.59a.867.867 0 00.57-.32c.07-.09.12-.19.15-.3a.64.64 0 00.02-.3zM67.8 37.29h-3.17c-.14 0-.25.11-.25.25v1.06c0 .13.11.25.25.25h.81v3.3a2.52 2.52 0 01-.74 1.67c-.45.45-1.04.71-1.65.74h-5.82c-.14 0-.25.11-.25.25v1.06c0 .13.11.25.25.25h5.82c2.45 0 3.95-2 3.95-3.43v-3.84h.8c.14 0 .25-.12.25-.25v-1.06c0-.14-.11-.25-.25-.25z' />
      </g>
      <defs>
        <clipPath id='prese-lavanderia_svg__clip0_2758_23704'>
          <path fill='#fff' d='M0 0h72.27v46.12H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgPreseLavanderia;
