import { makeStyles, Popover } from '@material-ui/core';
import clsx from 'clsx';
import dayjs from 'dayjs';
import itLocale from 'dayjs/locale/it';
import { useRef, useState } from 'react';
import { useStore } from '../store/storeUtils';
import { Alert, AlertTriangle } from './Icons';
import InfoPopup, { ComingSoonContent } from './InfoPopup';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '16px',
    // maxWidth: '450px',
    padding: '4px 0',
  },
  icon: {
    color: theme.palette.primary.main,
    '&.alert': {
      height: '16px !important',
      width: '16px !important',
    },
  },
  alertMessage: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '18px',
    borderLeft: `2px solid ${theme.palette.primary.main}`,
  },
  date: {
    marginBottom: '4px',
    fontFamily: 'miller',
    fontStyle: 'italic',
    fontSize: '15px',
    lineHeight: '18px',
    color: theme.palette.primary.main,
  },
  text: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '12px',
    fontSize: '15px',
    lineHeight: '18px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
      lineHeight: '16px',
    },
  },
  info: {
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      width: '15px',
      height: '15px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '13px',
      height: '13px',
    },
  },
  popover: {
    backgroundColor: 'rgba(0,0,0,0) !important',
    borderTopLeftRadius: '90px',
  },
}));

const AlertConfigurationEndDate = ({ date, className, firstRow, secondRow, thirdRow }) => {
  const classes = useStyles();

  const [showInfo, setShowInfo] = useState(false);

  const infoRef = useRef();

  const lastDate = dayjs(date).locale(itLocale).format('DD MMMM YYYY');

  return (
    <div className={clsx(classes.root, className)}>
      <AlertTriangle color='currentColor' width='30px' height='30px' className={classes.icon} />
      <div className={classes.alertMessage}>
        <span className={classes.date}>{firstRow ? firstRow : lastDate}</span>
        <div className={classes.text}>
          {date && (
            <span>
              <strong>Termine massimo</strong> <br /> per la configurazione
            </span>
          )}
          {secondRow && (
            <span>
              {secondRow} <br /> {thirdRow ? thirdRow : ''}
            </span>
          )}
          <span ref={infoRef}>
            <Alert
              color='currentColor'
              width='16px'
              height='16px'
              className={clsx(classes.icon, classes.info, 'alert')}
              onClick={() => setShowInfo(true)}
            />
          </span>

          <Popover
            open={showInfo}
            anchorEl={infoRef.current}
            onClose={() => setShowInfo(false)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            PaperProps={{ classes: { root: classes.popover } }}
          >
            <InfoPopup
              body={<ComingSoonContent phase={'la tecnologia'} />}
              isPopover
              handleClose={() => setShowInfo(false)}
            />
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default AlertConfigurationEndDate;
