import * as React from "react";

function SvgUser(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 51 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M44.516 50H5.508C2.47 50 0 47.53 0 44.516V43.02c0-7.17 5.84-13.01 13.01-13.01l5.58.095a15.363 15.363 0 0012.844 0l.427-.096h5.176c7.17 0 13.01 5.84 13.01 13.01v1.497C50 47.53 47.532 50 44.517 50zM13.01 32.004c-6.077 0-10.992 4.938-10.992 10.992v1.496a3.496 3.496 0 003.49 3.49h39.008a3.496 3.496 0 003.49-3.49v-1.496c0-6.078-4.939-10.992-10.993-10.992h-4.962a17.369 17.369 0 01-14.102 0H13.01zM25 29.012c-8 0-14.506-6.505-14.506-14.506C10.494 6.506 16.999 0 25 0c8 0 14.506 6.505 14.506 14.506 0 8.001-6.505 14.506-14.506 14.506zm0-27.018c-6.885 0-12.512 5.603-12.512 12.512 0 6.885 5.603 12.488 12.512 12.512 6.885 0 12.512-5.603 12.512-12.512 0-6.909-5.627-12.512-12.512-12.512z"
        fill={props.color}
      />
    </svg>
  );
}

export default SvgUser;
