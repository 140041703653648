import { getFileNameFromUrl } from './stringUtils';
import _ from 'lodash';

export const handleCardMouseEnterSimple = (
  fileLabels,
  showLayerOnHoverRef,
  baseImagesRef,
  setImages,
  tags = [],
  removeImagesStartByLetter,
  rooms,
) => {
  if (showLayerOnHoverRef.current) {
    let optionImages = fileLabels
      ?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)
      .map((el) => el.url);

    let optionsTags = [];
    if (tags.length > 0) {
      tags.forEach((tag) => {
        if (tag?.fileLabels) optionsTags.push(...tag?.fileLabels?.filter((el) => !el?.isAxo && !el?.isPlan && !el?.isElett).map((el) => el?.url));
      })
    }

    let baseImages = [];
    if (removeImagesStartByLetter) {
      baseImages = baseImagesRef.current.filter((el) => {
        if (el.fileUrl) {
          const fileName = getFileNameFromUrl(el.fileUrl);
          if (fileName[0] === removeImagesStartByLetter) {
            return false;
          }
        }

        return true;
      });
    }
    setImages(
      removeImagesStartByLetter
        ? baseImages.concat(optionImages).concat(optionsTags).concat(rooms)
        : baseImagesRef.current.concat(optionImages).concat(optionsTags).concat(rooms),
    );
  }
};

// DA RIVEDERE
export const handleCardMouseEnterSimpleTypology = (
  baseOptions,
  fileLabels,
  showLayerOnHoverRef,
  baseImagesRef,
  setImages,
  tags = [],
) => {
  const defaultOptions = baseOptions?.filter((el) => el.isDefault);
  const notDefaultOptions = baseOptions?.filter((el) => !el.isDefault);
  const selectedPositionsNotDefault = JSON.stringify(notDefaultOptions.find(el => JSON.stringify(el.fileLabels) === JSON.stringify(fileLabels))?.positions);
  const selectedPositionsDefault = defaultOptions.map(el => JSON.stringify(el?.positions));
  const notDefault = !!selectedPositionsNotDefault;
  const defaultToDel = notDefault
  ? defaultOptions
    ?.filter(def => (selectedPositionsNotDefault === JSON.stringify(def.positions)))
      .map(corrisp => corrisp.fileLabels?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)
          ?.map((el) => el.url)[0])
  : notDefaultOptions
    ?.filter(def => !selectedPositionsNotDefault?.includes(JSON.stringify(def.positions)))
      .map(corrisp => corrisp.fileLabels)?.map(elt => elt?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)?.map((el) => el.url)[0])

  const defaultToDelTags = notDefault
  ? defaultOptions?.filter(def => selectedPositionsNotDefault?.includes(JSON.stringify(def.positions)))
    .map(corrisp => corrisp.tags
      ?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)
        ?.map((el) => el.fileLabels?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)[0]?.url)[0])
  : notDefaultOptions?.filter(def => selectedPositionsDefault?.includes(JSON.stringify(def.positions)))
    .map(corrisp => corrisp.tags
      ?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)
        ?.map((el) => el.fileLabels?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)[0]?.url)[0]);
  const defaultToIns = notDefault ? [] :
    defaultOptions
      .map(corrisp => corrisp.fileLabels
        ?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)
          ?.map((el) => el.url)[0]);

  if (showLayerOnHoverRef?.current) {

    let optionImages = notDefault ? fileLabels
      ?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)
      ?.map((el) => el.url) : defaultToIns;

    let optionsTags = [];
    if (tags?.length > 0) {

      tags?.forEach((tag) => {
        if (tag?.fileLabels) optionsTags.push(...tag?.fileLabels?.filter((el) => !el?.isAxo && !el?.isPlan && !el?.isElett).map((el) => el?.url));
      })
    }

    setImages(baseImagesRef.current?.filter((elemento, index) => (!defaultToDel?.includes(_.isString(elemento) ? elemento : elemento?.fileUrl) && !defaultToDelTags?.includes(_.isString(elemento) ? elemento : elemento?.fileUrl))).concat(optionImages).concat(optionsTags),
    );
  }
};

export const handleCardMouseLeaveSimple = (
  showLayerOnHoverRef,
  baseImagesRef,
  setImages,
  tags = [],
) => {
  if (showLayerOnHoverRef.current) {
    setImages(baseImagesRef.current.concat(tags));
  }
};

// DA RIVEDERE
export const handleCardMouseLeaveSimpleTypology = (
  baseOptions,
  selectedOptionIds,
  defaultIds,
  showLayerOnHoverRef,
  baseImagesWithOptionsRef,
  setImages,
) => {
  if (showLayerOnHoverRef?.current) {
    const selectedOptions = baseOptions?.filter(
      (el) => _.difference(el.ids, selectedOptionIds).length === 0,
    );
    const defaultOptions = baseOptions.filter((el) => el.isDefault);
    const notDefaultOptions = baseOptions.filter((el) => !el.isDefault);
    const selectedOptionsPos = selectedOptions.map(selected => JSON.stringify(selected.positions));
    if (_.isEqual(defaultIds, selectedOptionIds)) {
      const defaultInsert = defaultOptions.map((el) => el.fileLabels?.filter(elt => !elt.isAxo && !elt.isPlan && !elt.isElettrico).map(eltc => eltc.url)[0]);
      const defaultInsertTag = defaultOptions.map((el) => el.tags?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico).map((el) => el.fileLabels
      ?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)[0]?.url)[0]);
      setImages(baseImagesWithOptionsRef.current.concat(defaultInsert).concat(defaultInsertTag));
    } else {
      const notSelectedOptions = defaultOptions
      ?.filter(def => !selectedOptionsPos.includes(JSON.stringify(def.positions)))
        .map(corrisp => corrisp.fileLabels 
          ?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)
            .map((el) => el.url)[0]);
      const notSelectedOptionsNotDefault = notDefaultOptions
      ?.filter(def => selectedOptionsPos.includes(JSON.stringify(def.positions)))
        .map(corrisp => corrisp.fileLabels 
          ?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)
            .map((el) => el.url)[0]);
      const notSelectedOptionsTags = notDefaultOptions
        ?.filter(def => selectedOptionsPos.includes(JSON.stringify(def.positions))).map(corrisp => corrisp.tags
          ?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico).map((el) => el.fileLabels
            ?.filter((el) => !el.isAxo && !el.isPlan && !el.isElettrico)[0]?.url)[0])
      const needToIns= [];
      notSelectedOptions?.forEach(urlToIns => {
        let isIn = false;
        baseImagesWithOptionsRef.current.forEach(urlToControls => {
          if(urlToIns === (_.isString(urlToControls) ? urlToControls : urlToControls?.fileUrl)) isIn = true;
        })
        if(!isIn) needToIns.push(urlToIns);
      });
      notSelectedOptionsNotDefault?.forEach(urlToIns => {
        let isIn = false;
        baseImagesWithOptionsRef.current.forEach(urlToControls => {
          if(urlToIns === (_.isString(urlToControls) ? urlToControls : urlToControls?.fileUrl)) isIn = true;
        })
        if(!isIn) needToIns.push(urlToIns);
      });
      notSelectedOptionsTags?.forEach(urlToIns => {
        let isIn = false;
        baseImagesWithOptionsRef.current.forEach(urlToControls => {
          if(urlToIns === (_.isString(urlToControls) ? urlToControls : urlToControls?.fileUrl)) isIn = true;
        })
        if(!isIn) needToIns.push(urlToIns);
      });
      setImages(baseImagesWithOptionsRef.current.concat(needToIns));
    }
  }
};

export const resumeAnswerSimple = async (
  store,
  oldAnswersRef,
  substepIdRef,
  setSelectedOptions,
  setIsLoading,
  roomId,
) => {
  const { answers } = store;
  const substepAnswers = answers.filter((el) => (el.substep.id === substepIdRef.current && (roomId ? el.room?.id === roomId : true)));

  if (substepAnswers.length > 0) {
    const selectedOptions = substepAnswers.map((el) => el?.option?.id !== 0 ? el.option.id : el.package?.id);
    oldAnswersRef.current = selectedOptions;
    setSelectedOptions(selectedOptions);
    store.setUserCanGoNext(true);
  }
  setIsLoading(false);
};
