import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { isDesktop, isMobile } from 'react-device-detect';
import ConfiguratorColumnTitle from '../components/Labels/ConfiguratorColumnTitle';
import LoadingAnimation from '../components/LoadingAnimation';

const useStyles = makeStyles((theme) => ({
  ConfiguratorThreeColumnsLayoutRoot: {
    width: '100%',
    height: '100%',
    position: 'relative',
    backgroundColor: '#FFF',

    '& .column-container': {
      paddingBottom: 22,
      paddingLeft: 22,
      overflow: 'hidden',
    },
    '& .two-columns-container': {
      flexBasis: '70%',
      '& > div': {},
    },
    '& .first-column': {
      flexBasis: '50%',
      paddingRight: 8,
    },
    '& .second-column': {
      flexBasis: '50%',
      paddingLeft: 8,
      paddingRight: 8,
    },
    '& .third-column': {
      flexBasis: '30%',
      paddingLeft: 8,
      paddingRight: 8,
      height: '100%',
      overflow: 'hidden',
      '&.padding-top': {
        paddingTop: '51px',
      },
    },

    '&.switch-width': {
      '& .first-column': {
        flexBasis: '45%',
      },
      '& .second-column': {
        flexBasis: '55%',
      },
    },
    [theme.breakpoints.down('lg')]: {
      '& .second-column-title': {
        backgroundColor: '#F0EDEB',
        paddingTop: 17,
        paddingLeft: 17,
        borderBottom: '0.5px solid #C4C4C4',
      },

      '& .third-column.padding-top': {
        paddingTop: '0 !important',
      },
    },

    '&.mobile': {
      [theme.breakpoints.down('lg')]: {
        '& .two-columns-container > div': {
          flexDirection: 'column',
          flexWrap: 'nowrap',
        },
        '& .first-column': {
          paddingBottom: 12,
        },
        '& .second-column': {
          paddingLeft: 0,
          paddingTop: 12,
        },
        '& .third-column': {
          height: '100%',
          overflow: 'hidden',
          '&.padding-top': {
            paddingTop: 0,
          },
        },
      },
      [theme.breakpoints.down('sm')]: {
        '& .column-container': {
          overflow: 'hidden',
        },
        '& .two-columns-container': {
          height: '100%',
          overflow: 'hidden auto',
        },
      },
    },
    '&.desktop': {
      [theme.breakpoints.down('lg')]: {
        '& .column-container': {
          overflow: 'hidden',
        },
        '& .two-columns-container': {
          height: '100%',
          overflow: 'hidden',
        },
        '& .first-column,.second-column ': {
          height: '100%',
          overflow: 'hidden auto',
        },
      },
    },
  },
  titleContainer: {
    padding: '22px 0 0 22px',
  },
  fullHeight: {
    height: '100%',
  },
}));

const ConfiguratorThreeColumnsLayoutFormato = ({
  firstColumn,
  secondColumn,
  thirdColumn,
  switchWidth,
  firstRowTitle,
  secondRowTitle,
  firstRowSecondColumnTitle,
  secondRowSecondColumTitle,
  isLoading,
}) => {
  const classes = useStyles();

  /*
  const [showFakeLoading, setShowFakeLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowFakeLoading(false);
    }, FAKE_LOADING_MILLISECONDS);
  }, []);
  */

  return (
    <Grid
      container
      direction='column'
      wrap='nowrap'
      className={clsx(
        classes.ConfiguratorThreeColumnsLayoutRoot,
        isDesktop && 'desktop',
        isMobile && 'mobile',
        switchWidth && 'switch-width',
      )}
    >
      <Grid item xs='auto' className={classes.titleContainer}>
        <ConfiguratorColumnTitle firstRow={firstRowTitle} secondRow={secondRowTitle} />
      </Grid>
      <Grid item xs className={clsx('column-container')}>
        <Grid container className={classes.fullHeight}>
          <Grid item className={clsx('two-columns-container')}>
            <Grid container className={clsx(classes.fullHeight)}>
              <Grid item xs='auto' className={clsx('first-column')}>
                {firstColumn}
              </Grid>
              <Grid item xs='auto' className={clsx('second-column')}>
                <Grid container direction='column' wrap='nowrap' className={classes.fullHeight}>
                  {(firstRowSecondColumnTitle || secondRowSecondColumTitle) && (
                    <Grid item xs='auto' className={clsx('second-column-title')}>
                      <ConfiguratorColumnTitle
                        firstRow={firstRowSecondColumnTitle}
                        secondRow={secondRowSecondColumTitle}
                      />
                    </Grid>
                  )}
                  <Grid item xs>
                    {secondColumn}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            className={clsx(
              'third-column',
              (firstRowSecondColumnTitle || secondRowSecondColumTitle) && 'padding-top',
            )}
          >
            {isLoading ? <LoadingAnimation /> : thirdColumn}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConfiguratorThreeColumnsLayoutFormato;
