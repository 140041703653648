import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  header: {
    width: '100%',
  },
  content: {
    overflow: 'hidden auto',
  },
}));

const ConfiguratorSelectionLayout = ({
  fixedHeader,
  scrollableContent,
  children,
  scrollableContentClasses,
}) => {
  const classes = useStyles();

  return (
    <Grid container direction='column' className={classes.root}>
      <Grid item xs='auto' className={classes.header}>
        {fixedHeader}
      </Grid>
      <Grid item xs className={clsx(classes.content, scrollableContentClasses)}>
        {scrollableContent}
        {children}
      </Grid>
    </Grid>
  );
};

export default ConfiguratorSelectionLayout;
