import * as React from "react";

function SvgDuplicate(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 46 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M44.022 55.5H8.903V8H33.14v10c0 .5.494 1 .989 1h9.893v36.5zM42.538 17h-7.42v-7l2.473 2.5 1.979 2 2.968 2.5zM46 18c0-.5 0-.5 0 0l-6.43-6.5v-10c0-1-.495-1.5-.99-1.5H.99C.494 0 0 .5 0 1v49c0 .5.495 1 .99 1h5.44v5c0 .5.495 1 .99 1h37.59c.495 0 .99-.5.99-1V18z"
        fill={props.color}
      />
    </svg>
  );
}

export default SvgDuplicate;
