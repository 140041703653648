import * as React from "react";

function SvgRename(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 58 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#rename_svg__clip0)">
        <path
          d="M35.183 30.758a11.61 11.61 0 004.542-5.726c.865-2.358.957-4.938.26-7.353a11.65 11.65 0 00-4.123-6.044A11.321 11.321 0 0029 9.31a11.32 11.32 0 00-6.862 2.324 11.65 11.65 0 00-4.124 6.044 11.835 11.835 0 00.262 7.353 11.609 11.609 0 004.541 5.726 20.93 20.93 0 00-9.177 5.772 21.456 21.456 0 00-5.143 9.647 26.986 26.986 0 01-5.613-13.681A27.147 27.147 0 015.3 17.876a26.595 26.595 0 019.699-11.065A25.96 25.96 0 0129 2.701a25.96 25.96 0 0114.002 4.11 26.595 26.595 0 019.7 11.065 27.148 27.148 0 012.414 14.62 26.986 26.986 0 01-5.612 13.681 21.337 21.337 0 00-5.131-9.66 20.81 20.81 0 00-9.19-5.76zM46.711 49.22c-4.825 4.494-11.13 6.986-17.671 6.986-6.542 0-12.846-2.492-17.671-6.986l-.559-.528A18.763 18.763 0 0116.91 37.11 18.17 18.17 0 0129 32.484a18.17 18.17 0 0112.091 4.625 18.763 18.763 0 016.099 11.584l-.479.447v.08zM29 29.825a8.631 8.631 0 01-4.853-1.498 8.856 8.856 0 01-3.218-3.988 9.029 9.029 0 01-.497-5.135 8.935 8.935 0 012.39-4.55 8.691 8.691 0 014.474-2.432 8.598 8.598 0 015.047.506 8.77 8.77 0 013.92 3.273 9.018 9.018 0 01.807 8.338 8.898 8.898 0 01-1.893 2.883 8.727 8.727 0 01-2.834 1.926 8.607 8.607 0 01-3.343.677zM29 0a28.652 28.652 0 00-16.111 4.972A29.397 29.397 0 002.208 18.21a29.973 29.973 0 00-1.65 17.044A29.661 29.661 0 008.493 50.36a28.851 28.851 0 0014.848 8.073 28.545 28.545 0 0016.756-1.678 29.112 29.112 0 0013.015-10.866A29.862 29.862 0 0058 29.5c-.01-7.82-3.07-15.318-8.505-20.848C44.058 3.122 36.688.011 29 0"
          fill={props.color}
        />
      </g>
      <defs>
        <clipPath id="rename_svg__clip0">
          <path fill="#fff" d="M0 0h58v59H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgRename;
