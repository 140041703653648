import { makeStyles, Tab, Tabs } from '@material-ui/core';
import { useState } from 'react';
import LayoutCard from '../Card/LayoutCard';
import OptionsTabs from '../Tabs/OptionsTabs';
import OptionTab from '../Tabs/OptionTab';
import OptionTabPanel from '../Tabs/OptionTabPanel';

const useStyles = makeStyles((theme) => ({
  layoutCardSelectorRoot: {
    height: '100%',
    backgroundColor: '#EEEEEE',
  },
}));

const LayoutCardSelector = (props) => {
  const { layouts, onClick, selectedOption } = props;

  const classes = useStyles();

  const selectedLayoutIndex = layouts.findIndex((layout) => layout.id === selectedOption);

  const [currentTab, setValue] = useState(selectedLayoutIndex > -1 ? selectedLayoutIndex : 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.layoutCardSelectorRoot}>
      <OptionsTabs
        value={currentTab}
        aria-label='tabs'
        variant={'scrollable'}
        scrollButtons='auto'
        onChange={handleChange}
      >
        {layouts &&
          layouts.map((layout, i) => (
            <OptionTab
              key={i}
              label={`LAYOUT ${layout.layoutCode}${layout.layoutVariantCode}`}
              value={i}
              isActive={layout.id === selectedOption}
            />
          ))}
      </OptionsTabs>
      {layouts &&
        layouts.map((layout, i) => (
          <OptionTabPanel value={currentTab} index={i} key={i}>
            <LayoutCard
              {...layout}
              onClick={() => onClick(layout.id)}
              isSelected={layout.id === selectedOption}
            />
          </OptionTabPanel>
        ))}
    </div>
  );
};

export default LayoutCardSelector;
