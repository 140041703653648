import * as React from 'react';

function SvgAlert(props) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 15 15' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx={7.5} cy={7.5} fill='currentColor' r={7.5} />
      <path
        d='M5.836 10.9V10h1.07V7.1h-1.07v-.9h2.14v3.9h.972v.9H5.836v-.1zM7.1 5.2c-.097 0-.194-.1-.194-.2-.098-.1-.098-.2-.098-.3 0-.2.098-.4.195-.5a.732.732 0 01.875-.1l.195.2c.097.1.097.2.097.3 0 .1 0 .2-.097.3-.098.2-.195.3-.292.3-.097.1-.195.1-.292.1-.097 0-.292 0-.389-.1z'
        fill={props.reversed ? '#000' : '#fff'}
      />
    </svg>
  );
}

export default SvgAlert;
