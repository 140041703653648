import * as React from 'react';

function SvgNoChoice(props) {
	return (
    <svg 
			width="126" 
			height="126" 
			viewBox="0 0 126 126" 
			fill="none" 
			xmlns="http://www.w3.org/2000/svg"
			{...props}
    >
			<path 
				fill-rule="evenodd" 
				clip-rule="evenodd" 
				d="M18.4523 18.4523C30.2671 6.63748 46.2914 0 63 0C79.7086 0 95.7329 6.63748 107.548 18.4523C119.363 30.2671 126 46.2914 126 63C126 79.7086 119.363 95.7329 107.548 107.548C95.7329 119.363 79.7086 126 63 126C46.2914 126 30.2671 119.363 18.4523 107.548C6.63748 95.7329 0 79.7086 0 63C0 46.2914 6.63748 30.2671 18.4523 18.4523ZM28.8884 22.035L103.965 97.1116C111.908 87.5735 116.308 75.5136 116.308 63C116.308 48.8619 110.691 35.3029 100.694 25.3057C90.6971 15.3086 77.1381 9.69225 63 9.69225C50.4864 9.69225 38.4265 14.0921 28.8884 22.035ZM97.1116 103.965L22.035 28.8884C14.0921 38.4265 9.69225 50.4864 9.69225 63C9.69225 77.1381 15.3086 90.6971 25.3057 100.694C35.3029 110.691 48.8619 116.308 63 116.308C75.5136 116.308 87.5735 111.908 97.1116 103.965Z" 
				fill={props.color}
			/>
    </svg>
  );
}

export default SvgNoChoice;
