import * as React from 'react';

function SvgIlluminazioneLettoSingolo(props) {
  return (
    <svg viewBox='0 0 63 53' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#illuminazione-letto-singolo_svg__clip0_2758_23981)' fill='#C4C4C4'>
        <path d='M47.33 42.28H3.82v-6.6c0-1.73 1.4-3.15 3.12-3.15h20.79v-2.01H12.67v-2.68c0-1.84 1.5-3.35 3.33-3.35h11.73v-1.94H16.07c-2.91 0-5.27 2.37-5.27 5.29v2.68H7.78V18.37c-.07-3.45 2.72-6.32 6.21-6.38h13.74v-1.95H13.99c-4.57 0-8.29 3.74-8.29 8.34v12.3c-2.28.54-3.89 2.58-3.89 5v6.6H.5c-.28 0-.5.22-.5.5v4.87c0 .27.22.5.5.5h1.31v3.42c0 .27.22.5.5.5h4.9c.27 0 .5-.23.5-.5v-3.42h32.48v3.42c0 .27.22.5.5.5h4.83c.28 0 .5-.23.5-.5v-3.42h1.31c.28 0 .5-.23.5-.5v-4.87c0-.28-.22-.5-.5-.5zM5.7 50.05H3.82v-1.9H5.7v1.9zm38.32 0h-1.89v-1.9h1.89v1.9zm1.88-3.92H1.94v-1.9H45.9v1.9z' />
        <path d='M47.95 33.93h-8.06c-.69 0-1.25.57-1.25 1.26 0 .69.56 1.26 1.25 1.26h8.06c.69 0 1.25-.56 1.25-1.26s-.56-1.26-1.25-1.26zM51.43 10.97c-.34-.33-.71-.64-1.09-.91-1.83-1.35-4.06-2.07-6.39-2.07-2.83 0-5.49 1.05-7.49 2.98-2.01 1.93-3.12 4.5-3.12 7.24 0 2.54.97 4.97 2.72 6.84 1.78 2.11 2.61 6.05 2.61 6.08l.17.82h10.3l.02-.99c.07-2.88 2.81-6.06 2.93-6.19 1.59-1.84 2.47-4.17 2.47-6.56 0-1.62-.39-3.18-1.12-4.58-.51-.98-1.18-1.87-2.01-2.66zm-.88 12.46c-.29.33-2.8 3.29-3.33 6.48h-6.74c-.35-1.34-1.26-4.28-2.91-6.23a7.95 7.95 0 01-2.2-5.47c0-4.51 3.85-8.19 8.58-8.19s8.57 3.68 8.57 8.19c0 1.9-.7 3.76-1.97 5.22zM45.079 0h-2.27v4.64h2.27V0zM56.253 3.935l-2.98 3.557 1.74 1.458 2.98-3.557-1.74-1.458zM62.32 16.24h-4.64v2.27h4.64v-2.27z' />
        <path d='M44.03 23.52l-2.91-2.91-1.41 1.41 4.32 4.33 4.1-4.1-1.42-1.41-2.68 2.68z' />
      </g>
      <defs>
        <clipPath id='illuminazione-letto-singolo_svg__clip0_2758_23981'>
          <path fill='#fff' d='M0 0h62.32v52.07H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIlluminazioneLettoSingolo;
