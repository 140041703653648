import { useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Button3D from '../components/Buttons/Button3D';
import IconButton from '../components/Buttons/IconButton';
import IconSquareButton from '../components/Buttons/IconSquareButton';
import IconRoundFlatButton from '../components/Buttons/IconRoundFlatButton';
import IconRound3DButton from '../components/Buttons/IconRound3DButton';
import TabBar from '../components/Tabs/TabBar';
import Card from '../components/Card/Card';
import SideMenu from '../components/SideMenu/SideMenu';
import DetailsContainer from '../components/Card/DetailsContainer';
import SelectionCard from '../components/Card/SelectionCard';
import BottomTabBar from '../components/Tabs/BottomTabBar';
import ApartmentCard from '../components/Card/ApartmentCard';
import ConfigurationCard from '../components/Card/ConfigurationCard';
import pdf from '../assets/pdf.pdf';

import {
  Add,
  AdditionalPlug,
  Camera,
  Cart,
  Compose,
  Download,
  Eye,
  Format,
  MagnifyingGlass,
  OutlinedHeart,
  PlanimetryCircled,
  Print,
  Ruler,
  Shutters,
  SlidingDoor,
  SwingDoor,
  Trash,
  Usb,
} from '../components/Icons';
import FurnitureCounterContainer from '../components/Layout/FurnitureCounterContainer';
import ConfigurationLayout from '../components/Layout/ConfigurationLayout';
import MoodSummary from '../components/MoodSummary';
import SubtitledImage from '../components/Layout/SubtitledImage';
import ContentContainer from '../components/Layout/ContentContainer';
import MoodContainer from '../components/Card/MoodContainer';
import SummaryContainer from '../components/SummaryContainer';
import IconTitle from '../components/IconTitle';
import MaterialSelection from '../components/MaterialSelection';
import MaterialDataSheet from '../components/MaterialDataSheet';
import ConfigurableMessage from '../components/ConfigurableMessage';
import NoSelectionCard from '../components/Card/NoSelectionCard';
import FinalStepMessage from '../components/FinalStepMessage';
import AlertConfigurationEndDate from '../components/AlertConfigurationEndDate';
import dayjs from 'dayjs';
import itLocale from 'dayjs/locale/it';
import AlertMessage from '../components/AlertMessage';
import ConfigurationTable from '../components/Layout/ConfigurationTable';
import InfoPopup from '../components/InfoPopup';
import InfoBar from '../components/InfoBar';
import CustomDialog from '../components/CustomDialog';
import ZoomControl from '../components/ZoomControl';
import InfoDialogBagno from '../components/InfoDialogBagno';
import VideoViewer from '../components/VideoViewer';
import PdfReader from '../components/PdfReader';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const DevComponents = () => {
  const buttonAction = () => {
    console.log('bottone premuto');
  };
  const elements = [
    {
      title: 'Infisso 2',
      image: {
        name: 'opaque-glass',
        subtitle: 'Vetro trasparente',
        look: 'normal',
      },
    },
    {
      title: 'Infisso 3',
      image: {
        name: 'opaque-glass',
        subtitle: 'Vetro trasparente',
        look: 'normal',
      },
    },
  ];

  const moods = [
    {
      title: 'MOOD 1',
      nStars: 3,
      moods: [{ name: 'mood 2.1', color: '#534545' }],
      infoText: 'Scopri la configurazione esemplificativa.',
    },
    {
      title: 'MOOD 2',
      nStars: 3,
      moods: [
        { name: 'mood 2.1', color: '#C19696' },
        { name: 'mood 2.1', color: '#999999' },
      ],
      infoText: 'Scopri la configurazione esemplificativa.',
    },
    {
      title: 'MOOD 3',
      nStars: 2,
      moods: [{ name: 'mood 2.1', color: '#B8A5A5' }],
      infoText: 'Scopri la configurazione esemplificativa.',
    },
    {
      title: 'MOOD 4',
      nStars: 1,
      moods: [{ name: 'mood 2.1', color: '#999999' }],
      infoText: 'Scopri la configurazione esemplificativa.',
    },
    {
      title: 'MOOD 5',
      nStars: 3,
      moods: [{ name: 'mood 2.1', color: '#676766' }],
      infoText: 'Scopri la configurazione esemplificativa.',
    },
  ];

  const date = dayjs().locale(itLocale).format('DD MMMM YYYY');

  const configData = {
    header: ['', 'Punti presa', 'Punti luce', 'Prese tv', 'Prese dati'],
    body: [
      {
        room: 'Camera',
        values: [4, 4, 2, 1],
      },
      {
        room: 'Camera 2',
        values: [3, 3, 2, 1],
      },
      {
        room: 'Bagno',
        values: [2, 2, 1, 0],
      },
      {
        room: 'Bagno 2',
        values: [2, 2, 1, 0],
      },
    ],
  };

  const doorCards = [
    {
      icon: <SwingDoor color='currentColor' width='70px' height='100%' />,
      gallery:
        'https://www.outletarredamento.it/img/porte/porta-liscia-moderna-garofoli-a-prezzi-outlet_n6_669696.jpg',
      title: 'Porte battenti',
      subtitle: 'h2.10',
      description: 'Disposte nelle seguenti posizioni:',
      extraElements: ['p-1', 'p-2', 'p-3', 'p-4'],
    },
    {
      icon: <SlidingDoor color='currentColor' width='70px' height='100%' />,
      gallery:
        'https://ristrutturaconmade.it/wp-content/uploads/2019/09/controtelaio-porta-scorrevole.jpg',
      title: 'Porte scorrevoli',
      subtitle: 'h2.10',
      description: 'Disposte nelle seguenti posizioni:',
      extraElements: ['p-5', 'p-6'],
    },
  ];

  const mat = {
    name: 'Nome marca',
    product: 'Prodotto',
    info: ['Materiale', 'Formato 50x50cm', '100% Made in Italy'],
    code: '05 6589 26554',
    icon: <Format width='60px' height='60px' />,
    dataSheet: `${process.env.PUBLIC_URL}/assets/images/scheda_tecnica.png`,
  };

  const menu = [
    { title: 'Foto', icon: <Camera color='currentColor' width='15px' height='15px' /> },
    { title: 'Scheda tecnica', icon: <Ruler color='currentColor' width='15px' height='15px' /> },
  ];
  const details = [
    {
      title: 'Lorem Ipsum 1',
      image: `${process.env.PUBLIC_URL}/assets/images/box-oggetto.png`,
      price: '500',
      Image: `${process.env.PUBLIC_URL}/assets/images/planimetria.png`,
      scheda_tecnica: `${process.env.PUBLIC_URL}/assets/images/scheda_tecnica.png`,
    },
    {
      title: 'Lorem Ipsum 2',
      image: `${process.env.PUBLIC_URL}/assets/images/box-oggetto.png`,
      price: '500',
      Image: `${process.env.PUBLIC_URL}/assets/images/planimetria.png`,
      scheda_tecnica: `${process.env.PUBLIC_URL}/assets/images/scheda_tecnica.png`,
    },
    {
      title: 'Lorem Ipsum 3',
      image: `${process.env.PUBLIC_URL}/assets/images/box-oggetto.png`,
      price: '500',
      Image: `${process.env.PUBLIC_URL}/assets/images/planimetria.png`,
      scheda_tecnica: `${process.env.PUBLIC_URL}/assets/images/scheda_tecnica.png`,
    },
    {
      title: 'Lorem Ipsum 4',
      image: `${process.env.PUBLIC_URL}/assets/images/box-oggetto.png`,
      price: '500',
      Image: `${process.env.PUBLIC_URL}/assets/images/planimetria.png`,
      scheda_tecnica: `${process.env.PUBLIC_URL}/assets/images/scheda_tecnica.png`,
    },
    {
      title: 'Lorem Ipsum 5',
      image: `${process.env.PUBLIC_URL}/assets/images/box-oggetto.png`,
      price: '500',
      Image: `${process.env.PUBLIC_URL}/assets/images/planimetria.png`,
      scheda_tecnica: `${process.env.PUBLIC_URL}/assets/images/scheda_tecnica.png`,
    },
    {
      title: 'Lorem Ipsum 6',
      image: `${process.env.PUBLIC_URL}/assets/images/box-oggetto.png`,
      price: '500',
      Image: `${process.env.PUBLIC_URL}/assets/images/planimetria.png`,
      scheda_tecnica: `${process.env.PUBLIC_URL}/assets/images/scheda_tecnica.png`,
    },
    {
      title: 'Lorem Ipsum 7',
      image: `${process.env.PUBLIC_URL}/assets/images/box-oggetto.png`,
      price: '500',
      Image: `${process.env.PUBLIC_URL}/assets/images/planimetria.png`,
      scheda_tecnica: `${process.env.PUBLIC_URL}/assets/images/scheda_tecnica.png`,
    },
    {
      title: 'Lorem Ipsum 8',
      image: `${process.env.PUBLIC_URL}/assets/images/box-oggetto.png`,
      price: '500',
      Image: `${process.env.PUBLIC_URL}/assets/images/planimetria.png`,
      scheda_tecnica: `${process.env.PUBLIC_URL}/assets/images/scheda_tecnica.png`,
      infoSpecification: 'Possibilitá di piatto doccia filopavimento',
    },
    {
      title: 'Lorem Ipsum 9',
      image: `${process.env.PUBLIC_URL}/assets/images/box-oggetto.png`,
      price: '500',
      Image: `${process.env.PUBLIC_URL}/assets/images/planimetria.png`,
      scheda_tecnica: `${process.env.PUBLIC_URL}/assets/images/scheda_tecnica.png`,
    },
  ];

  const [open, setOpen] = useState(false);
  const openSomething = () => setOpen(true);
  const closeSomething = () => setOpen(false);

  return (
    <Box bgcolor='#FDD' height='100%' width='100%'>
      {/* <ConfigurationLayout
        title='la tua configurazione di partenza'
        buttonText='Seleziona questa scelta'
        buttonAction={buttonAction}
      >
        <FurnitureCounterContainer />
        <SubtitledImage
          image='no-closure'
          subtitle='Non hai selezionato nessun sistema di chiusura cucina'
          look='italic'
        />
        <ContentContainer elements={elements} />
        <ConfigurationTable data={configData} />
      </ConfigurationLayout> */}
      {/* <MoodContainer moods={moods} /> */}
      {/* <SummaryContainer /> */}
      {/* <IconTitle
        icon={<OutlinedHeart color='currentColor' />}
        title='Seleziona il mood che preferisci. Dal mood selezionato potrai scegliere i dettagli dei pavimenti nei diversi ambienti.'
      /> */}
      {/* <MaterialSelection /> */}
      {/* <MaterialDataSheet type='formato' material={mat} /> */}
      {/* <ConfigurableMessage text='hai concluso la personalizzazione del tuo stile nella zona giorno e notte!' /> */}
      {/* <FinalStepMessage /> */}
      {/* <NoSelectionCard
        title='Nessun rivestimento'
        description='Scegli la seguente opzione se NON si desiderano rivestimenti'
      /> */}
      {/* <AlertConfigurationEndDate date={date} /> */}
      {/* <AlertMessage
        title='Attenzione'
        subtitle='Le porte non selezionabili non possono essere modificate per ragioni tecniche.'
        description='Potrai personalizzare mood, materiali e colori delle tue porte in una fase successiva.'
      />
      <AlertMessage
        title='Attenzione'
        subtitle=''
        description='Potrai personalizzare mood, materiali e colori della tua cucina in una fase successiva.'
      /> */}
      {/* <Button3D text='Visualizza appartamento' color='orange' /> */}
      {/* <Button3D text='Continua le tue scelte' color='green' reverseTextStyle /> */}
      {/* <Button3D
        text='Invia modulo d’ordine'
        disabled
        reverseTextStyle
        disabledText='Completa tutte le scelte per poter continuare.'
      /> */}
      {/* <Button3D
        text='Continua le tue scelte'
        disabled
        disabledText='Completa tutte le scelte per poter continuare.'
      /> */}
      {/* <IconButton
        text='Visualizza appartamento'
        icon={<Add color='currentColor' width='100%' height='100%' D />}
      /> */}
      {/* <IconButton
        text='Visualizza appartamento'
        icon={<Eye color='currentColor' width='100%' height='100%' />}
        filled
      /> */}
      {/* <IconSquareButton
        text='Scarica'
        icon={<Download color='currentColor' width='30px' height='30px' />}
      /> */}
      {/* <IconSquareButton
        text='Stampa'
        icon={<Print color='currentColor' width='30px' height='30px' />}
      /> */}
      {/* <IconRoundFlatButton icon={<Trash color='currentColor' width='50%' height='50%' />} /> */}
      {/* <IconRound3DButton icon={<MagnifyingGlass color='#000' width='100%' height='100%' />} /> */}
      {/* <TabBar /> */}
      {/* <div style={{ display: 'flex', gap: '50px', width: '70%' }}>
        <Card
          Name=''
          Image={`${process.env.PUBLIC_URL}/assets/images/planimetria.png`}
          // scheda_tecnica={`${process.env.PUBLIC_URL}/assets/images/scheda_tecnica.png`}
          Tags={['sale']}
          Price={500.0}
          icon={<Shutters color='currentColor' width='38px' height='38px' />}
          title='Tapparelle smart'
          whiteMode={true}
          sideTitle={false}
          bottomTitle={true}
        />
        <Card
          Name=''
          // Image={`${process.env.PUBLIC_URL}/assets/images/planimetria.png`}
          scheda_tecnica={`${process.env.PUBLIC_URL}/assets/images/scheda_tecnica.png`}
          Tags={[]}
          Price={500.0}
          icon={<Usb color='currentColor' width='38px' height='38px' />}
          description='Aggiungi porte USB in soggiorno e in camera da letto'
          whiteMode={true}
          sideTitle={false}
          bottomTitle={false}
        />
        <Card
          Name=''
          Image={`${process.env.PUBLIC_URL}/assets/images/planimetria.png`}
          scheda_tecnica={`${process.env.PUBLIC_URL}/assets/images/scheda_tecnica.png`}
          Tags={['Recommended']}
          Price={500.0}
          icon={<AdditionalPlug color='currentColor' width='38px' height='38px' />}
          title='Prese aggiuntive lavanderia'
          description='Aggiungi prese nella lavanderia'
          whiteMode={true}
          sideTitle={true}
          bottomTitle={false}
        />
        <Card
          Name=''
          Image={`${process.env.PUBLIC_URL}/assets/images/planimetria.png`}
          scheda_tecnica={`${process.env.PUBLIC_URL}/assets/images/scheda_tecnica.png`}
          Tags={[]}
          Price={500.0}
          whiteMode={false}
          sideTitle={false}
          bottomTitle={true}
        />
      </div> */}
      {/* <SideMenu /> */}
      {/* <DetailsContainer title='Mood metropolitan' numCols='3' details={details} /> */}
      {/* <SelectionCard menu={menu} /> */}
      {/* <BottomTabBar /> */}
      {/* <ApartmentCard status='completed' /> */}
      {/* <ConfigurationCard index='1' status='progressing' confValue='50' /> */}
      {/* <MoodSummary /> */}
      {/* <InfoBar
        title='I tuoi appartamenti'
        icon={<Compose color='currentColor' width='40px' height='40px' />}
      />
      <InfoBar
        title='Le tue configurazioni'
        icon={<Plus color='currentColor' width='40px' height='40px' />}
        extraInfo
      /> */}
      {/* <div style={{ display: 'flex', alignItems: 'flex-start', gap: '50px' }}>
        <InfoPopup warning={true} />
        <InfoPopup warning={false} date={date} />
      </div> */}
      {/* <>
        <Button3D text='Open dialog' onClick={openDialog} />
        {openDialog && (
          <CustomDialog
            image={`${process.env.PUBLIC_URL}/assets/images/dialog.png`}
            title="Hai scelto solo un'opzione"
            subtitle='Puoi aggiungerne altre!'
            labelButton1='AGGIUNGI ALTRE OPZIONI'
            button1OnClick={() => console.log('Bottone 1 cliccato')}
            labelButton2='CONFERMO LA MIA SCELTA'
            button2OnClick={() => console.log('Bottone 2 cliccato')}
            open={open}
            handleClose={closeDialog}
          />
        )}
      </> */}
      {/* <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zoom: 4 }}>
        <ZoomControl />
      </div> */}
      <div style={{ position: 'relative', height: '100%' }}>
        {/* <Button3D text='Open dialog' onClick={openSomething} />
        {open && (
          <InfoDialogBagno
            height='120 cm'
            images={[
              'https://1.bp.blogspot.com/-hX-7W-MXdxw/YGcRpTi_eqI/AAAAAAAANv0/dVpgjU0CtzI9HmTJO3SV4-rBsipNnBMAwCLcBGAsYHQ/s600/01.jpg',
              'https://media.lavorincasa.it/post/17/16128/gallery/10480/intonaco-ruvido.jpg',
            ]}
            open={open}
            handleClose={closeSomething}
          />
        )} */}
        {/* <Button3D text='Open video' onClick={openSomething} />
        {open && (
          <VideoViewer
            urlVideo={'https://www.w3schools.com/html/mov_bbb.mp4'} //{`${process.env.PUBLIC_URL}/assets/video/video.mp4`}
            handleClose={closeSomething}
          />
        )} */}
        <PdfReader urlPdf={pdf} />
      </div>
    </Box>
  );
};

export default DevComponents;
