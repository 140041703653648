import * as React from "react";

function SvgDownload(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 23 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.2 9.744h-6.3v-9.4H6.4v9.4H.1l11.1 11 11-11zM0 26.944h22.2v-3.1H0v3.1z"
        fill={props.color}
      />
    </svg>
  );
}

export default SvgDownload;
