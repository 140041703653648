import { getHcUserData } from './localStorageUtils';

export const parseJWT = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const isJwtExpired = () => {
  try {
    const userData = getHcUserData();
    if (!userData || Object.keys(userData)?.length === 0) {
      return true;
    }
    const expiration = parseJWT(userData.jwtToken).exp;
    if (Date.now() >= expiration * 1000) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log('Error isJwtExpired', error);
    return true;
  }
};
