import { makeStyles } from '@material-ui/core';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useEffect, useState } from 'react';
import ImageZoom from './ImageZoom';
import IconRound3DButton from './Buttons/IconRound3DButton';
import { MagnifyingGlass } from './Icons';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    backgroundColor: '#FFF',
  },
  elementRoot: {
    height: '100%',
    width: '100%',
    outline: 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    cursor: 'zoom-in',
    '& img': {
      height: '100%',
      width: '100%',
      objectFit: 'contain',
    },
    '& div, img': {},
  },

  zoomIconContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: '70px',
    width: '70px',
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '0.5px solid #999',
    borderTop: '0.5px solid #999',
  },
}));

const PlanimetryZoom = ({ images, optionLayersOnTop, rotate, notZoom, refe, onlyZoom=false, setLayout }) => {
  const classes = useStyles();

  const [imagesToShow, setImagesToShow] = useState([]);
  const [areaToShow, setAreaToShow] = useState([]);
  const [isZoomed, setZoomed] = useState(onlyZoom);

  useEffect(() => {
    if (images?.length >= 1 && _.isPlainObject(images[0])) {
      // Metto in ordine i layer, portando quello delle finestre (order + alto) in cima a tutti
      const greaterOrder = images.reduce((previousValue, currentValue) => {
        if (_.isPlainObject(currentValue)) {
          return currentValue?.order < previousValue ? previousValue : currentValue?.order;
        }
        return previousValue;
      });
      const optionLayers = images.filter((image) => _.isString(image));
      const bottomLayer = images.filter((image) => image?.order && image?.order !== greaterOrder);
      const topLayer = images.filter((image) => image?.order === greaterOrder);

      let imagesToShow;

      if (optionLayersOnTop) {
        imagesToShow = [...bottomLayer, ...topLayer, ...optionLayers];
      } else {
        imagesToShow = [...bottomLayer, ...optionLayers, ...topLayer];
      }
      let indexArea = [];

      imagesToShow.forEach((el, index) => ((!_.isString(el) && el.isArea) ? indexArea.push(index) : indexArea.push(undefined)));
      setAreaToShow(indexArea);
      setImagesToShow(imagesToShow.map((el) => (_.isString(el) ? el : el?.fileUrl)));
    } else {
      setImagesToShow(images);
    }
  }, [images]);
  const handleStyle = (areaToShow) => {
    if(rotate){
      if(areaToShow) {
        return {opacity:"0.2", transform: 'rotate(90deg)'}
      } else return {transform: 'rotate(90deg)'}
    } else if (areaToShow) {
      if(rotate){
        return {opacity:"0.2", transform: 'rotate(90deg)'}
      } else  return {opacity:"0.2"}
    }
  }
  if(onlyZoom) return(
    <div ref={refe} className={classes.root}>
      <ImageZoom
          images={imagesToShow}
          isPlanimetry
          handleClose={() => {
            setZoomed(false)
            setLayout(false)
          }}
          allowDownload
      />
    </div>
  )

  return (
    <div ref={refe} className={classes.root}>
      {Array.isArray(images) ? (
        imagesToShow.map((el, i) => (
          <div className={classes.elementRoot} key={i} onClick={() => setZoomed(true)}>
            <img src={el} alt='' style={handleStyle(areaToShow[i])} />
          </div>
        ))
      ) : (
        <div className={classes.elementRoot} onClick={() => setZoomed(true)}>
          <img src={imagesToShow} alt='' />
        </div>
      )}
      {notZoom ? null : (<div className={classes.zoomIconContainer}>
        <IconRound3DButton
          onClick={() => setZoomed(true)}
          className={classes.zoomIcon}
          icon={<MagnifyingGlass height='100%' width='100%' color='#000' />}
        />
      </div>)}
      {isZoomed && (
        <ImageZoom
          images={imagesToShow}
          isPlanimetry
          handleClose={() => setZoomed(false)}
          allowDownload
        />
      )}
    </div>
  );
};

export default PlanimetryZoom;
