import { darken, Grid, makeStyles, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { newConfiguration } from '../services/configurationApi';
import { useStore } from '../store/storeUtils';
import { Configurations, User, PlusCircled, ArrowBack, Download, Print } from './Icons';
import IconSquareButton from '../components/Buttons/IconSquareButton';

const useStyles = makeStyles((theme) => ({
  infoApartmentRoot: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    backgroundColor: theme.palette.color.white,
    '& .user-info': {
      padding: '18px 20px',
      '& .user-info-name': {
        alignItems: 'center',
      },
    },

    '& .resume-button': {
      marginBottom: '10px',
      '& > div': {
        marginRight: '5px',
        '&:last-child': {
          marginLeft: '5px',
        },
      },
    },
  },
  headerContainer: {
    position: 'sticky',
    top: 0,
    left: 0,
    backgroundColor: theme.palette.color.white,
    zIndex: 1,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '16px',
    padding: '25px 20px 18px',
  },
  circle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '81px',
    height: '81px',
    border: '1px solid #000',
    borderRadius: '50%',
    flexShrink: 0,
    [theme.breakpoints.down('lg')]: {
      width: '43px',
      height: '43px',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  title: {
    paddingTop: '9px',
    [theme.breakpoints.down('lg')]: {
      paddingTop: 0,
    },
    '& .first-part': {
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '18px',
      textTransform: 'uppercase',
      [theme.breakpoints.down('lg')]: {
        fontSize: '13px',
        lineHeight: '16px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
        lineHeight: '14px',
      },
    },
    '& .second-part': {
      fontFamily: 'miller',
      fontStyle: 'italic',
      fontWeight: 'normal',
      fontSize: '25px',
      lineHeight: '30px',
      textTransform: 'capitalize',
      color: theme.palette.primary.main,
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        lineHeight: '24px',
      },
    },
  },
  content: {
    // position: 'absolute',
    // bottom: 0,
    // left: 0,
    // right: 0,
    maxHeight: '100%',
    padding: '15px 26px 50px',
    '& .info-content': {
      marginBottom: '30px',
    },
    '& .info-content:first-child': {
      marginBottom: '30px',
    },
    '& .info-content:last-child': {
      marginBottom: 0,
    },
    [theme.breakpoints.down('lg')]: {
      // padding: '150px 14px 50px',
    },
    [theme.breakpoints.down('sm')]: {
      // padding: '150px 14px 50px',
    },
  },
  infoTitle: {
    width: '100%',
    paddingBottom: '8px',
    fontSize: '12px',
    lineHeight: '14px',
    textTransform: 'uppercase',
    borderBottom: '1px solid #000',
    '& .first-part': {
      fontWeight: 500,
    },
    '& .second-part': {
      fontWeight: 600,
    },
  },
  infoContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '13px',
    marginTop: '15px',
    paddingLeft: '37px',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '24px',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '12px',
      paddingLeft: '18px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '14px 0 14px 14px',
    },
  },
  infoContentHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '4px',
  },
  userHeadingTitle: {
    fontSize: '12px',
    lineHeight: '14px',
    textTransform: 'uppercase',
  },
  userHeadingContent: {
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '18px',
  },
  aptHeadingTitle: {
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '16px',
  },
  aptHeadingContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '2px',
    fontWeight: 500,
    textTransform: 'uppercase',
    color: '#8E8E8E',
    '& span:first-child': {
      fontSize: '12px',
      lineHeight: '14px',
      whiteSpace: 'nowrap',
    },
    '& span:last-child': {
      fontWeight: 600,
      fontSize: '13px',
      lineHeight: '16px',
    },
  },
  configurations: {
    display: 'flex',
    flexDirection: 'column',
    gap: '9px',
    marginTop: '18px',
    [theme.breakpoints.only('xl')]: {
      paddingLeft: '37px',
    },
  },
  configuration: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '11px',
    padding: '4px 20px',
    backgroundColor: '#F9F9F9',
    cursor: 'pointer',
    '&.selected': {
      backgroundColor: darken('#F9F9F9', 0.1),
      pointerEvents: 'none',
    },
    [theme.breakpoints.only('lg')]: {
      padding: '4px 16px',
    },
    [theme.breakpoints.down('md')]: {
      gap: '9px',
      padding: '4px 6px',
    },
    [theme.breakpoints.down('sm')]: {
      gap: '6px',

      padding: '4px 3px',
    },
  },
  configurationName: {
    fontFamily: 'miller',
    fontStyle: 'italic',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '22px',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('lg')]: {
      fontSize: '15px',
      lineHeight: '18px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
      lineHeight: '16px',
    },
    '&.new': {
      fontSize: '15px',
      lineHeight: '18px',
      [theme.breakpoints.only('xl')]: {
        fontSize: '18px',
        lineHeight: '22px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '13px',
        lineHeight: '16px',
      },
    },
  },
  configurationPre: {
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    color: '#8E8E8E',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
      lineHeight: '14px',
    },
  },
  configurationNumber: {
    fontWeight: '750',
    fontSize: '18px',
    lineHeight: '22px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '15px',
      lineHeight: '18px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
      lineHeight: '16px',
    },
  },
  comeBack: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '10px',
    padding: '12px 16px',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    position: 'absolute',
    bottom: '0',
    [theme.breakpoints.down('md')]: {
      padding: '12px 6px',
    },
    '& span': {
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '14px',
      textDecoration: 'underline',
    },
  },
  selectedApt: {
    marginTop: '13px',
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '18px',
    textTransform: 'uppercase',
    color: '#000',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
      lineHeight: '16px',
    },
  },
}));

const InfoApartment = ({
  firstTitleRow,
  secondTitleRow,
  thirdTitleRow,
  apartment,
  isConfigurationSent,
  handleDownload,
  handlePrint,
  hideConfigurations,
  isDownloadLoading,
  notAllowedFinish,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const store = useStore();

  const handleNewConfiguration = async () => {
    try {
      // Creo una nuova configurazione per l'appartamento
      const newConfigurationName = `Nuova Configurazione`;

      const newConfigurationResponse = await newConfiguration(newConfigurationName, apartment.id);
      // recupero id configurazione
      const newConfigurationId = newConfigurationResponse.data.id;

      store.setCurrentApartment(apartment);
      store.setCurrentConfiguration(newConfigurationResponse.data);

      history.push(`/apartment/${apartment.id}/${newConfigurationId}/configurator`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={classes.infoApartmentRoot}>
      <div className={classes.headerContainer}>
        <UserContent title='Le tue informazioni' />
        <div className={classes.header}>
          <div className={classes.circle}>
            <Configurations
              color='currentColor'
              width={smallScreen ? '18px' : '35px'}
              height={smallScreen ? '18px' : '35px'}
            />
          </div>
          <div className={classes.title}>
            {firstTitleRow && <div className='first-part'>{firstTitleRow}</div>}
            {secondTitleRow && <div className='second-part'>{secondTitleRow}</div>}
            {thirdTitleRow && <div className='third-part'>{thirdTitleRow}</div>}
          </div>
          {apartment && <div className={classes.selectedApt}>{apartment.name}</div>}
        </div>
      </div>
      {(handleDownload || handlePrint) && (
        <Grid container justifyContent='center' className={'resume-button'}>
          {handleDownload && !notAllowedFinish &&(
            <Grid item xs='auto'>
              <IconSquareButton
                text='Scarica'
                icon={<Download color='currentColor' width='25px' height='25px' />}
                onClick={handleDownload}
                isLoading={isDownloadLoading}
              />
            </Grid>
          )}
          <Grid item xs='auto'>
            {handlePrint && (
              <IconSquareButton
                text='Stampa'
                icon={<Print color='currentColor' width='25px' height='25px' />}
                onClick={handlePrint}
              />
            )}
          </Grid>
        </Grid>
      )}

      <div className={classes.content}>
        {apartment && !hideConfigurations && (
          <ApartmentContent
            title='Il tuo appartamento'
            content={apartment}
            isConfigurationSent={isConfigurationSent}
            handleNewConfiguration={handleNewConfiguration}
          />
        )}
      </div>
      {apartment && (
        <div className={classes.comeBack} onClick={() => history.push('/apartments')}>
          <ArrowBack color='currentColor' width='18px' height='8px' />
          <span>Torna ai tuoi appartamenti</span>
        </div>
      )}
    </div>
  );
};

export default InfoApartment;

const UserContent = observer(({ title }) => {
  const classes = useStyles();
  const store = useStore();
  const { userData } = store;

  const styleText = (text) => {
    const parts = text.split(' ');
    const secondPart = parts.pop();
    const firstPart = parts.join(' ');

    return (
      <>
        <span className='first-part'>{firstPart}</span>{' '}
        <span className='second-part'>{secondPart}</span>
      </>
    );
  };

  return (
    <div className='user-info'>
      <div className={classes.infoTitle}>{styleText(title)}</div>
      <div className={clsx(classes.infoContent, 'user-info-name')}>
        <User color='#8E8E8E' width='24px' height='24px' />
        <div className={classes.infoContentHeader}>
          <div className={classes.userHeadingContent}>
            {userData.firstName} {userData.lastName}
          </div>
        </div>
      </div>
    </div>
  );
});

const ApartmentContent = observer(
  ({ title, content, isConfigurationSent, handleNewConfiguration }) => {
    const classes = useStyles();
    const store = useStore();
    const history = useHistory();
    const { apartment } = useParams();

    const selectedConfiguration = store.getCurrentConfiguration();

    const openSelectedConfiguration = (configuration) => {
      store.setCurrentConfiguration(configuration);
      history.push(`/apartment/${apartment}/${configuration.id}/resume`);
    };

    const [configurations, setConfigurations] = useState([]);

    useEffect(() => {
      const configurations = store.configurations.filter((el) => el.realApartmentId == apartment);
      setConfigurations(configurations);
    }, [store.configurations]);

    const styleText = (text) => {
      const parts = text.split(' ');
      const secondPart = parts.pop();
      const firstPart = parts.join(' ');

      return (
        <>
          <span className='first-part'>{firstPart}</span>{' '}
          <span className='second-part'>{secondPart}</span>
        </>
      );
    };

    const styleConfigName = (name) => {
      const parts = name.split(' ');
      const secondPart = parts.pop();
      const firstPart = parts.join(' ');

      return (
        <>
          {firstPart} <span className={classes.configurationNumber}>{secondPart}</span>
        </>
      );
    };

    return (
      <div className='info-content'>
        <div className={classes.infoTitle}>{styleText(title)}</div>
        {/* <div className={classes.infoContent}>
          <HomeOutlined color='#8E8E8E' width='24px' height='24px' />
          <div className={classes.infoContentHeader}>
            <div className={classes.aptHeadingTitle}>{content.apartment.value}</div>
            <div className={classes.aptHeadingContent}>
              <span>Modulo d'ordine</span>
              <span>{content.contractId}</span>
            </div>
          </div>
        </div> */}
        <div className={classes.configurations}>
          {configurations.map((configuration, i) => (
            <div
              key={i}
              className={clsx(
                classes.configuration,
                selectedConfiguration?.id === configuration.id && 'selected',
              )}
              onClick={() => openSelectedConfiguration(configuration)}
            >
              <span className={classes.configurationPre}>Apri</span>
              <span className={classes.configurationName}>
                {styleConfigName(configuration.name)}
              </span>
            </div>
          ))}
          {!isConfigurationSent &&
            (process.env.REACT_APP_CONFIG_LIMIT == 0 ||
              configurations.length < process.env.REACT_APP_CONFIG_LIMIT * 1) &&
              !store.isOneConfigurationConfirmed && (
                <div className={classes.configuration} onClick={handleNewConfiguration}>
                  <PlusCircled color='#8E8E8E' width='16px' height='16px' />
                  <span className={clsx(classes.configurationName, 'new')}>Nuova configurazione</span>
                </div>
            )
          }
        </div>
      </div>
    );
  },
);
