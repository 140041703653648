import { makeStyles } from '@material-ui/core';
import { useState } from 'react';
import { Download, Print } from './Icons';
import InfoBox from './InfoBox';
import IconSquareButton from './Buttons/IconSquareButton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    width: '100%',
    height: '100%',
    // padding: '32px 20px 22px',
    overflowY: 'auto',
    backgroundColor: theme.palette.color.white,
    // [theme.breakpoints.down('lg')]: {
    //   padding: '25px 17px 22px',
    // },
    // [theme.breakpoints.down('md')]: {
    //   padding: '11px 8px',
    // },
    // [theme.breakpoints.down('sm')]: {
    //   padding: '22px 12px',
    // },
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'sticky',
    top: 0,
    left: 0,
    backgroundColor: theme.palette.color.white,
    zIndex: 1,
    padding: '32px 20px',
    [theme.breakpoints.down('lg')]: {
      padding: '25px 17px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '11px 8px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '22px 12px',
    },
    '& .circle': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '83px',
      minWidth: '83px',
      height: '83px',
      marginRight: '16px',
      border: `1px solid ${theme.palette.color.black}`,
      borderRadius: '50%',
      [theme.breakpoints.down('lg')]: {
        display: 'none',
      },
    },
    '& .title': {
      width: '100%',
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '18px',
      textTransform: 'uppercase',
      color: theme.palette.color.black,
    },
  },
  content: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    maxHeight: '100%',
    padding: '250px 20px 22px',

    [theme.breakpoints.down('lg')]: {
      padding: '250px 17px 22px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '250px 8px 11px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '250px 12px 22px',
    },
  },
  selectedConfiguration: {
    display: 'flex',
    flexDirection: 'column',
    '& .title': {
      fontFamily: 'miller',
      fontStyle: 'italic',
      fontSize: '25px',
      lineHeight: '30px',
      color: theme.palette.primary.main,
      textTransform: 'capitalize',
      [theme.breakpoints.down('md')]: {
        fontSize: '20px',
        lineHeight: '24px',
      },
    },
    '& .subtitle': {},
    '& .buttons': {
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'center',
      marginTop: '22px',
    },
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& .first-part': {
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '18px',
      color: theme.palette.color.black,
      textTransform: 'uppercase',
      [theme.breakpoints.down('md')]: {
        fontSize: '13px',
        lineHeight: '16px',
      },
    },
    '& .second-part': {
      fontFamily: 'miller',
      fontStyle: 'italic',
      fontSize: '25px',
      lineHeight: '30px',
      color: theme.palette.primary.main,
      textTransform: 'capitalize',
      [theme.breakpoints.down('md')]: {
        fontSize: '20px',
        lineHeight: '24px',
      },
    },
  },
}));

const InfoBar = ({ title, icon, extraInfo }) => {
  const classes = useStyles();

  const [selectedConfiguration, setSelectedConfiguration] = useState(null);

  const styleTitle = (title) => {
    const parts = title.split(' ');
    const secondPart = parts.pop();
    const firstPart = parts.join(' ');

    return (
      <>
        <span className='first-part'>{firstPart}</span>
        <span className='second-part'>{secondPart}</span>
      </>
    );
  };

  const fakeData = [
    { category: '', content: '' },
    { category: '', content: '' },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {!selectedConfiguration ? <div className='circle'>{icon}</div> : ''}
        <div className='title'>
          {selectedConfiguration ? (
            <div className={classes.selectedConfiguration}>
              <div className={classes.title}>
                <span className='first-part'>Stai visualizzando la</span>
                <span className='second-part'>{selectedConfiguration}</span>
              </div>
              <div className='buttons'>
                <IconSquareButton
                  text='Scarica'
                  icon={<Download color='currentColor' width='22px' height='22px' />}
                />
                <IconSquareButton
                  text='Stampa'
                  icon={<Print color='currentColor' width='22px' height='22px' />}
                />
              </div>
            </div>
          ) : (
            <div className={classes.title}>{styleTitle(title)}</div>
          )}
        </div>
      </div>
      <div className={classes.content}>
        <InfoBox
          data={fakeData}
          extraInfo={extraInfo}
          selectedConfiguration={selectedConfiguration}
          setSelectedConfiguration={setSelectedConfiguration}
        />
      </div>
    </div>
  );
};

export default InfoBar;
