import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Plate from '../Card/Plate';
import SubtitledImage from './SubtitledImage';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
    overflow: 'auto hidden',
    width: '100%',
    height: '100%',
    '&.no-justify-center': {
      justifyContent: 'initial',
    },
  },
  element: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    '& .plate': {
      marginBottom: '4px',
    },
  },
  subtitle: {
    fontFamily: 'miller',
    fontStyle: 'italic',
    fontSize: '15px',
    lineHeight: '18px',
    textAlign: 'center',
    padding: '8px 35px 0',
  },
}));

const ContentContainer = ({
  elements,
  defaultImage,
  text,
  plateText,
  unit,
  dimensionName,
  subtitle,
  hideSubtitle,
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={clsx(classes.root, elements?.length > 4 && 'no-justify-center')}>
        {elements?.map((element, i) => {
          const positions = element.numbers?.map((el) => el.number);
          return (
            <div key={i} className={classes.element}>
              {positions ? (
                <div className={'plate'}>
                  {positions.map((el, i) => (
                    <Plate key={i} title={`${plateText}${el}`} />
                  ))}
                </div>
              ) : (
                ''
              )}
              <SubtitledImage
                image={element.image ? element.image : defaultImage}
                icon={element.icon}
                subtitle={!hideSubtitle ? (text ? text : element.name) : null}
                look={element.look}
                positions={element.positions}
                unit={unit}
                dimension={element[dimensionName]}
              />
            </div>
          );
        })}
      </div>
      {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
    </>
  );
};

export default ContentContainer;
