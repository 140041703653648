import * as React from 'react';

function SvgVideoCitofonoConnesso(props) {
  return (
    <svg viewBox='0 0 122 91' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M113.175 90.85H8.965a8.08 8.08 0 01-8.07-8.07V8.07A8.08 8.08 0 018.965 0h104.21a8.08 8.08 0 018.07 8.07v74.71a8.081 8.081 0 01-8.07 8.07zM8.965 3.09a5 5 0 00-5 5v74.69a5 5 0 005 5h104.21a5 5 0 005-5V8.07a5 5 0 00-5-5l-104.21.02z'
        fill='#4E4D4D'
      />
      <path
        d='M61.604 81.55h-45.71a8.08 8.08 0 01-8.07-8.07V17.37a8.08 8.08 0 018.07-8.07h45.7a8.09 8.09 0 018.08 8.07v56.11a8.09 8.09 0 01-8.07 8.07zm-45.71-69.17a5 5 0 00-5 5v56.1a5 5 0 005 5h45.7a5 5 0 005-5V17.37a5 5 0 00-5-5l-45.7.01zm87.71 25.85h-17.92v-3.09h17.93l-.01 3.09zm0-20.33h-17.92v-3.09h17.93l-.01 3.09zM109.405 33.15h-29.51v-3.09h29.51v3.09zm5.45-5h-40.41V25.1h40.45l-.04 3.05zm-5.45-4.84h-29.51v-3.04h29.51v3.04zM103.824 70.21a4.65 4.65 0 01-4.59-3.92 4.64 4.64 0 01-9.17 0 4.64 4.64 0 110-1.44 4.64 4.64 0 019.17 0 4.64 4.64 0 114.59 5.36zm0-6.2a1.563 1.563 0 00-1.554.929 1.55 1.55 0 000 1.251 1.557 1.557 0 001.554.93 1.558 1.558 0 001.021-2.614 1.558 1.558 0 00-1.021-.496zm-9.18 0a1.56 1.56 0 101.56 1.56 1.559 1.559 0 00-1.56-1.57v.01zm-9.17 0a1.56 1.56 0 101.56 1.56 1.559 1.559 0 00-1.56-1.57v.01z'
        fill='#4E4D4D'
      />
      <path
        d='M36.893 48.15a9.26 9.26 0 119.26-9.26 9.27 9.27 0 01-9.26 9.26zm0-15.43a6.17 6.17 0 106.17 6.17 6.18 6.18 0 00-6.17-6.17zM55.335 62h-3.08a7.72 7.72 0 00-7.72-7.72h-15.36a7.72 7.72 0 00-7.72 7.72h-3.08a10.81 10.81 0 0110.8-10.8h15.36a10.81 10.81 0 0110.8 10.8z'
        fill='#4E4D4D'
      />
    </svg>
  );
}

export default SvgVideoCitofonoConnesso;
