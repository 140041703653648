import { useEffect, useState, useRef } from 'react';
import GridDetailsContainer from '../../../components/Card/GridDetailsContainer';
import MaterialDataSheet from '../../../components/MaterialDataSheet';
import ConfiguratorThreeColumnsLayout from '../../../layouts/ConfiguratorThreeColumnsLayout';
import { makeStyles } from '@material-ui/core';
import { resumeAnswerSimple } from '../../../utils/configuratorUtils';
import { getPackages } from '../../../services/packageApi';
import { useStore } from '../../../store/storeUtils';
import { useParams } from 'react-router';
import { handleSave, singleChoiceSelection } from '../../../utils/selectionUtils';
import _ from 'lodash';
import LoadingAnimation from '../../../components/LoadingAnimation';
// import ConfiguratorTwoColumnsLayout from '../../../layouts/ConfiguratorTwoColumnsLayout';
// import { Alert } from '../../../components/Icons';
// import DocciaCardContainer from '../../../components/Card/DocciaCardContainer';
import {
  materialDataSheetOptionsConfiguration,
  packagesRestructuringClassicConfiguration,
} from '../../../configs/bagnoPhasesConfigurations';
import BigBox from '../giornoNotte/config/BigBox';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '15px',
    textAlign: 'left',
    height: '5%',
  },
  titleRelazione: {
    fontSize: '15px',
    textAlign: 'center',
    padding: '10px',
  },
  secondaryText: {
    color: theme.palette.color.orange,
  },
  primaryText: {
    textAlign: 'center',
    padding: '10px',
  },
  icon: {
    marginTop: '25px',
    borderRadius: '50%',
    border: '1px solid' + theme.palette.color.orange,
  },
}));

const FirstColumn = ({
  options,
  selectedOptions,
  selectedPavimentoBagnoImage,
  selectedPavimentoBagnoTitle,
  selectedPareteBagnoImage,
  selectedPareteBagnoTitle,
}) => {
  const materialObject = materialDataSheetOptionsConfiguration(options, selectedOptions);
  // const optionSelected = options?.find(option => option?.id === selectedOptions?.[0]);
  // const selectedVirtualImage = optionSelected?.virtualImage?.replace(/\s/g, "%20");
  return (
    <BigBox
      defaultImage={`${process.env.PUBLIC_URL}/assets/images/Bagno.jpg`}
      selectedVirtualImage={materialObject?.image}
      selectedPavimentoBagnoImage={selectedPavimentoBagnoImage}
      selectedPavimentoBagnoTitle={selectedPavimentoBagnoTitle}
      selectedPareteBagnoImage={selectedPareteBagnoImage}
      selectedPareteBagnoTitle={selectedPareteBagnoTitle}
    />
  );
};

const SecondColumn = ({ options, selectedOptions }) => {
  const materialObject = materialDataSheetOptionsConfiguration(options, selectedOptions);
  return (
    <MaterialDataSheet
      firstRowTitle={'PREVIEW'}
      secondRowTitle={'PRODOTTO'}
      previewBoxText={'una FINITURA'}
      material={materialObject}
      noImageLayout={true}
    />
  );
};

const ThirdColumn = ({ options, selectedOptions, handleUserSelection }) => {
  const classes = useStyles();
  const containers = [
    {
      title: 'Finitura',
      content: options,
    },
  ];
  return (
    <>
      <div className={classes.title}>
        <span className={classes.primaryText}>
          Quale <span className={classes.secondaryText}>finitura</span> preferisci per il tuo{' '}
          <span className={classes.secondaryText}>piatto doccia </span>?
        </span>
      </div>
      <div style={{ height: '95%' }}>
        <GridDetailsContainer
          containers={containers}
          onClick={handleUserSelection}
          selectedOptions={selectedOptions}
          numCols={1}
        />
      </div>
    </>
  );
};

const DocciaFinitura = () => {
  const [packages, setPackages] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [selectedPavimentoBagnoImage, setselectedPavimentoBagnoImage] = useState(null);
  const [selectedPavimentoBagnoTitle, setSelectedPavimentoBagnoTitle] = useState(null);
  const [selectedPareteBagnoImage, setSelectedPareteBagnoImage] = useState(null);
  const [selectedPareteBagnoTitle, setSelectedPareteBagnoTitle] = useState(null);

  const substepIdRef = useRef();
  const oldAnswersRef = useRef();

  const store = useStore();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const configurationId = params.configuration;
  const currentRoomId = store.currentRoomId;

  const settingFiniture = () => {
    const pavimentoBagnoDes = store.selectedPavimentiBagno?.find(room => room.roomId === currentRoomId)?.pavimentoBagnoDes;
    const pareteBagnoDes = store.selectedPavimentiBagno?.find(room => room.roomId === currentRoomId)?.pareteBagnoDes;
    const pavimentoBagnoImg = store.selectedPavimentiBagno?.find(room => room.roomId === currentRoomId)?.pavimentoBagnoImg;
    const pareteBagnoImg = store.selectedPavimentiBagno?.find(room => room.roomId === currentRoomId)?.pareteBagnoImg;
    if(pareteBagnoDes !== ''){
      setSelectedPareteBagnoTitle(pareteBagnoDes);
      setSelectedPareteBagnoImage(pareteBagnoImg);
    }
    if(pavimentoBagnoDes !== ''){
      setSelectedPavimentoBagnoTitle(pavimentoBagnoDes);
      setselectedPavimentoBagnoImage(pavimentoBagnoImg);
    }
  }

  useEffect(() => {
    setIsLoading(true);
    store.setUserCanGoNext(false);
    prepareOptions();
    settingFiniture();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoomId]);

  const prepareOptions = async () => {
    const substepId = store.currentSubstepId;
    const packages = await getPackages(configurationId, substepId, currentRoomId);
    substepIdRef.current = store.currentSubstepId;
    const { answers } = store;
    const selectedFinituraBagnoId = answers.find((el) => el.substep?.value === 'PAVIMENTO' && el.room?.id === currentRoomId)?.substep?.id;
    const finitureBagnoPackage = await getPackages(configurationId, selectedFinituraBagnoId, currentRoomId);
    const selectedFinituraBagnoPackage = answers.find((el) => el.substep?.value === 'PAVIMENTO' && el.room?.id === currentRoomId)?.package?.value;
    const selectedFinituraBagno = finitureBagnoPackage.data[0]?.packages?.find((onePackage) => onePackage?.code === selectedFinituraBagnoPackage);
    /*
    const { answers } = store;

    setSelectedMoods(answers.find((el) => el.substep.value === 'FINITURA')?.package?.value);
    const selectedFinitura = answers.find((el) => el.substep.value === 'TONALITA')?.package?.value;
    const stepFinituraId = answers.find((el) => el.substep.value === 'TONALITA')?.substep?.id;
    const finiturePackages = await getPackages(configurationId, stepFinituraId);
    setSelectedFinituraImage(
      finiturePackages?.data[0]?.packages
        ?.find((onePackage) => onePackage?.code === selectedFinitura)
        ?.images?.find((image) => image?.imageType?.value === 'Cover')?.label,
    );
    setselectedFinituraProductDescription(
      finiturePackages?.data[0]?.packages?.find(
        (onePackage) => onePackage?.code === selectedFinitura,
      )?.products[0]?.description,
    );
    */
    const packagesRestruct = packagesRestructuringClassicConfiguration(packages, false, false, 'PiattoDocciaFinitura');
    const packageFilopavId = packagesRestruct.findIndex(singlePackage => singlePackage.code === 'AI_PIADOCPAV');
    if(packageFilopavId !== -1) {
      const finitureProductId = selectedFinituraBagno?.products?.findIndex(product => product?.productPackageType?.value === 'RIVBAGNO_FILOPAVIMENTO')
      packagesRestruct[packageFilopavId].coverImage = selectedFinituraBagno?.images?.find(image => image?.imageType?.value === 'Cover')?.label;
      packagesRestruct[packageFilopavId].productDescriptionTitle = Object.values(
        Object.fromEntries(
          Object.entries(selectedFinituraBagno?.products[finitureProductId]).filter(
            ([key, value]) => 
              key.includes('techInfo') && 
              key.includes('Title') && 
              !key.includes('1') && 
              (value !== null || value !== '')
          )
        )
      );
      packagesRestruct[packageFilopavId].productDescription = Object.values(
        Object.fromEntries(
          Object.entries(selectedFinituraBagno?.products[finitureProductId]).filter(
            ([key, value]) => 
              key.includes('techInfo') && 
              !key.includes('Title') && 
              !key.includes('1') && 
              (value !== null || value !== '')
          )
        )
      )
    }
    setPackages(packagesRestruct);
    resumeAnswerSimple(
      store,
      oldAnswersRef,
      substepIdRef,
      setSelectedPackages,
      setIsLoading,
      currentRoomId,
    );
  };

  useEffect(() => {
    const save = async () => {
      await handleSave(
        null,
        configurationId,
        substepIdRef.current,
        store,
        selectedPackages?.[0],
        currentRoomId,
      );
    };

    if (!_.isEqual(oldAnswersRef.current, selectedPackages)) {
      store.setSubstepSave(save);
    } else {
      store.setSubstepSave(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPackages]);

  const handleUserSelection = (selection) => {
    singleChoiceSelection([selection], [selectedPackages], setSelectedPackages, store);
  };

  return isLoading ? (
    <LoadingAnimation />
  ) : (
    <ConfiguratorThreeColumnsLayout
      firstRowTitle='CONFIGURA'
      secondRowTitle='LA FINITURA DEL PIATTO DOCCIA'
      firstColumn={
        <FirstColumn
          options={packages}
          selectedOptions={selectedPackages}
          selectedPavimentoBagnoImage={selectedPavimentoBagnoImage}
          selectedPavimentoBagnoTitle={selectedPavimentoBagnoTitle}
          selectedPareteBagnoImage={selectedPareteBagnoImage}
          selectedPareteBagnoTitle={selectedPareteBagnoTitle}
        />
      }
      secondColumn={<SecondColumn options={packages} selectedOptions={selectedPackages} />}
      thirdColumn={
        <ThirdColumn
          options={packages}
          selectedOptions={selectedPackages}
          handleUserSelection={handleUserSelection}
        />
      }
    />
  )
};

export default DocciaFinitura;
