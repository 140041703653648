import GridDetailsContainer from '../../../components/Card/GridDetailsContainer';
import MaterialDataSheet from '../../../components/MaterialDataSheet';
import ConfiguratorThreeColumnsLayout from '../../../layouts/ConfiguratorThreeColumnsLayout';
import { useEffect, useState, useRef } from 'react';
import { resumeAnswerSimple } from '../../../utils/configuratorUtils';
import { getPackages } from '../../../services/packageApi';
import { useStore } from '../../../store/storeUtils';
import { useParams } from 'react-router';
import { handleSave, singleChoiceSelection } from '../../../utils/selectionUtils';
import _ from 'lodash';
import LoadingAnimation from '../../../components/LoadingAnimation';
import BigBox from './config/BigBox';
import { makeStyles, Grid } from '@material-ui/core';
import {
  materialDataSheetOptionsConfiguration,
  packagesRestructuringClassicConfiguration,
} from '../../../configs/giornoNottePhasesConfigurations';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '15px',
    textAlign: 'left',
    height: '5%',
  },
  secondaryText: {
    color: theme.palette.color.orange,
  },
  filters: {
    width: '100%',
    margin: '10px 0',
    padding: '10px 0',
    textAlign: 'center',
    zIndex: 1,
    fontSize: 10,
    overflowX: 'auto',
    overflowY: 'hidden',
    height: '12%',
  },
  filter: {
    width: '80%',
    padding: '5px 5px',
    border: `1px solid #CDC7C1`,
    textAlign: 'center',
    zIndex: 1,
    fontSize: 12,
    borderRadius: '10px',
    overflow: 'scroll',
    margin: '0px 5px',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    '&.selected-filter': {
      backgroundColor: '#CDC7C1',
    },
  },
}));

const FirstColumn = ({
  options,
  selectedOptions,
  selectedFormatoCamereImage,
  selectedFormatoCamereTitle,
  selectedFormatoCucinaImage,
  selectedFormatoCucinaTitle,
}) => {
  const selectedVirtualImage = options
    ?.find((option) => option.id === selectedOptions?.[0])
    ?.virtualImage?.replace(/\s/g, '%20');
  return (
    <BigBox
      defaultImage={`${process.env.PUBLIC_URL}/assets/images/Soggiorno.jpg`}
      selectedVirtualImage={selectedVirtualImage}
      selectedFormatoCamereImage={selectedFormatoCamereImage}
      selectedFormatoCucinaImage={selectedFormatoCucinaImage}
      selectedFormatoCamereTitle={selectedFormatoCamereTitle}
      selectedFormatoCucinaTitle={selectedFormatoCucinaTitle}
    />
  );
};

const SecondColumn = ({ options, selectedOptions }) => {
  const materialDataSheetOptions = materialDataSheetOptionsConfiguration(options, selectedOptions);
  return (
    <MaterialDataSheet
      firstRowTitle={'PREVIEW'}
      secondRowTitle={'PRODOTTO'}
      previewBoxText={'un PRODOTTO'}
      material={materialDataSheetOptions}
    />
  );
};

const ThirdColumn = ({
  options,
  selectedOptions,
  handleUserSelection,
  filters,
  setFilter,
  filter,
}) => {
  const classes = useStyles();
  const optionsFiltered = options?.filter((option) =>
    filter === 'Tutte le collezioni' ? option : option?.filter === filter,
  );
  const containers = [
    {
      title: 'Preferenza Vetro',
      content: optionsFiltered,
    },
  ];

  return (
    <>
      <div className={classes.title}>
        <span>
          Quale tipologia di <span className={classes.secondaryText}>vetro</span> preferisci per la{' '}
          <span className={classes.secondaryText}>porta d'arredo</span>?
        </span>
      </div>
      <Grid item xs='auto' className={classes.filters}>
        Filtri di Visualizzazione:
        <br></br>
        <br></br>
        <Grid container direction='row' justifyContent='left' alignItems='center' wrap='nowrap'>
          {filters?.map((singleFilter, i) => (
            <Grid item>
              <span
                className={clsx(classes.filter, singleFilter === filter && 'selected-filter')}
                onClick={() => setFilter(singleFilter)}
              >
                {singleFilter}
              </span>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <div style={{ height: '83%' }}>
        <GridDetailsContainer
          containers={containers}
          onClick={handleUserSelection}
          selectedOptions={selectedOptions}
          numCols={1}
        />
      </div>
    </>
  );
};

const SistemaChiusuraVetro = () => {
  const [packages, setPackages] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [selectedFormatoCamereImage, setSelectedFormatoCamereImage] = useState(null);
  const [selectedFormatoCamereTitle, setSelectedFormatoCamereTitle] = useState(null);
  const [selectedFormatoCucinaImage, setSelectedFormatoCucinaImage] = useState(null);
  const [selectedFormatoCucinaTitle, setSelectedFormatoCucinaTitle] = useState(null);
  const [filter, setFilter] = useState('Tutte le collezioni');
  const [filters, setFilters] = useState([]);

  const substepIdRef = useRef();
  const oldAnswersRef = useRef();

  const store = useStore();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const configurationId = params.configuration;

  const settingFiniture = () => {
    const storedFinitureCamereTitle = store.selectedFormatoCameraTitle;
    const storedFinituraCamereImage = store.selectedFormatoCameraImage;
    const storedFinituraCucinaTitle = store.selectedFormatoCucinaTitle;
    const storedFinituraCucinaImage = store.selectedFormatoCucinaImage;
    if (storedFinitureCamereTitle !== '') {
      setSelectedFormatoCamereTitle(storedFinitureCamereTitle);
      setSelectedFormatoCamereImage(storedFinituraCamereImage);
    }
    if (storedFinituraCucinaTitle !== '') {
      setSelectedFormatoCucinaTitle(storedFinituraCucinaTitle);
      setSelectedFormatoCucinaImage(storedFinituraCucinaImage);
    }
  };

  useEffect(() => {
    store.setUserCanGoNext(false);
    prepareOptions();
    settingFiniture();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prepareOptions = async () => {
    const substepId = store.currentSubstepId;
    const packages = await getPackages(configurationId, substepId);
    substepIdRef.current = store.currentSubstepId;
    /*
    const { answers } = store;

    setSelectedMoods(answers.find((el) => el.substep.value === 'MOOD')?.package?.value);
    const selectedFinitura = answers.find((el) => el.substep.value === 'FINITURA')?.package?.value;
    const stepFinituraId = answers.find((el) => el.substep.value === 'FINITURA')?.substep?.id;
    const finiturePackages = await getPackages(configurationId, stepFinituraId);
    setSelectedFinituraImage(
      finiturePackages?.data[0]?.packages
        ?.find((onePackage) => onePackage?.code === selectedFinitura)
        ?.images?.find((image) => image?.imageType?.value === 'Cover')
        ?.label?.replace(/\s/g, '%20'),
    );
    */
    let filters = packages?.data[0]?.filters;
    filters.unshift('Tutte le collezioni');
    setFilters(filters);
    const packagesRestruct = packagesRestructuringClassicConfiguration(
      packages,
      true,
      false,
      false,
      'PorteArredoVetro',
    );
    setPackages(packagesRestruct);
    resumeAnswerSimple(store, oldAnswersRef, substepIdRef, setSelectedPackages, setIsLoading);
  };

  useEffect(() => {
    const save = async () => {
      await handleSave(null, configurationId, substepIdRef.current, store, selectedPackages?.[0]);
    };

    if (!_.isEqual(oldAnswersRef.current, selectedPackages)) {
      store.setSubstepSave(save);
    } else {
      store.setSubstepSave(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPackages]);

  const handleUserSelection = (selection) => {
    singleChoiceSelection([selection], [selectedPackages], setSelectedPackages, store);
  };

  return isLoading ? (
    <LoadingAnimation />
  ) : (
    <ConfiguratorThreeColumnsLayout
      firstRowTitle='CONFIGURA'
      secondRowTitle="LE PORTE D'ARREDO"
      firstColumn={
        <FirstColumn
          options={packages}
          selectedOptions={selectedPackages}
          selectedFormatoCamereImage={selectedFormatoCamereImage}
          selectedFormatoCamereTitle={selectedFormatoCamereTitle}
          selectedFormatoCucinaImage={selectedFormatoCucinaImage}
          selectedFormatoCucinaTitle={selectedFormatoCucinaTitle}
        />
      }
      secondColumn={<SecondColumn options={packages} selectedOptions={selectedPackages} />}
      thirdColumn={
        <ThirdColumn
          options={packages}
          selectedOptions={selectedPackages}
          handleUserSelection={handleUserSelection}
          filters={filters}
          setFilter={setFilter}
          filter={filter}
        />
      }
    />
  );
};

export default SistemaChiusuraVetro;
