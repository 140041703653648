import { Popper } from '@material-ui/core';
import { useEffect, useState, forwardRef } from 'react';
import { InfoPopupChoiceData, InfoPopupChoiceSmall, InfoPopupChoiceDataSmall, InfoPopupChoice } from './InfoPopup';

const AlertBoxChoiche = forwardRef((props, ref) => {
  const { selected, finituraImg, moodName, isCucina, selectedFinituraTitle, isPavimentoBagno, isPareteBagno } = props;
  const [showInfoBig, setShowInfoBig] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    if(selected && finituraImg) setShowInfo(true);
  }, [finituraImg, selected, ref]) 

  return (
    <>
      <Popper
        open={showInfoBig && ref?.current}
        anchorEl={ref.current}
        onClose={() => setShowInfoBig(true)}
        placement={"top-start"}
      >
        <InfoPopupChoice
          body={
            <InfoPopupChoiceData 
              finituraImg={finituraImg} 
              moodName={moodName}
              selectedFinituraTitle={selectedFinituraTitle}
              isCucina={isCucina}
              handleClose={() => {
                setShowInfoBig(false);
                setShowInfo(true);
              }}
              isPavimentoBagno={isPavimentoBagno}
              isPareteBagno={isPareteBagno}
            />}
          isPopover
        />
      </Popper>
      <Popper
        open={showInfo && ref.current}
        anchorEl={ref.current}
        onClose={() => setShowInfoBig(true)}
        placement="top-start"
      >
        <InfoPopupChoiceSmall
          body={
            <InfoPopupChoiceDataSmall 
              finituraImg={finituraImg}
              isCucina={isCucina}
              handleClose={() => {
                setShowInfo(false);
                setShowInfoBig(true);
              }} 
              isPavimentoBagno={isPavimentoBagno}
              isPareteBagno={isPareteBagno}
            />
          }
          isPopover
        />
      </Popper>
    </>
  );
});

export default AlertBoxChoiche;
