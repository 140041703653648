import { useEffect, useState, useRef } from 'react';
import ConfiguratorTwoColumnsLayoutNew from '../../../layouts/ConfiguratorTwoColumnsLayoutNew';
import BigBox from './config/BigBox';
// import { makeStyles } from '@material-ui/core';
// import { Alert } from '../../../components/Icons';
import { resumeAnswerSimple } from '../../../utils/configuratorUtils';
import { getPackages } from '../../../services/packageApi';
import { useStore } from '../../../store/storeUtils';
import { useParams } from 'react-router';
import { handleSave, singleChoiceSelection } from '../../../utils/selectionUtils';
import _ from 'lodash';
import LoadingAnimation from '../../../components/LoadingAnimation';
import KitchenCardContainer from '../../../components/Card/KitchenCardContainer';
import { packagesRestructuringClassicConfiguration } from '../../../configs/giornoNottePhasesConfigurations';

/*
const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '15px',
    textAlign: 'center',
    padding: '10px',
  },
  primaryText: {
    textAlign: 'center',
    padding: '10px',
  },
  secondaryText: {
    color: theme.palette.color.orange,
  },
  icon: {
    marginTop: '25px',
    borderRadius: '50%',
    border: '1px solid' + theme.palette.color.orange,
  },
}));
*/

const FirstColumn = ({ options, selectedOptions }) => {
  const selectedVirtualImage = options
    ?.find((option) => option.id === selectedOptions?.[0])
    ?.virtualImage?.replace(/\s/g, '%20');
  const isChained = options?.find((option) => option.id === selectedOptions?.[0])?.isChained;
  return (
    <BigBox
      defaultImage={`${process.env.PUBLIC_URL}/assets/images/Cucina_Relazione.png`}
      selectedVirtualImage={selectedVirtualImage}
      isChained={isChained}
      noCoicheNeed={true}
    />
  );
};

const SecondColumn = ({ options, selectedOptions, handleUserSelection }) => {
  return (
    <>
      <div style={{ height: '100%', backgroundColor: '#F0EDEB' }}>
        <KitchenCardContainer
          options={options}
          selectedOptions={selectedOptions}
          handleUserSelection={handleUserSelection}
        />
      </div>
    </>
  );
};

const RelazioneLivingCucina = () => {
  const [packages, setPackages] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);

  const substepIdRef = useRef();
  const oldAnswersRef = useRef();

  const store = useStore();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const configurationId = params.configuration;

  useEffect(() => {
    store.setUserCanGoNext(false);
    prepareOptions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prepareOptions = async () => {
    const substepId = store.currentSubstepId;
    const packages = await getPackages(configurationId, substepId);
    substepIdRef.current = store.currentSubstepId;
    const packagesRestruct = packagesRestructuringClassicConfiguration(
      packages,
      false,
      false,
      true,
    );
    setPackages(packagesRestruct);
    resumeAnswerSimple(store, oldAnswersRef, substepIdRef, setSelectedPackages, setIsLoading);
  };

  useEffect(() => {
    const save = async () => {
      await handleSave(null, configurationId, substepIdRef.current, store, selectedPackages?.[0]);
    };
    if (!_.isEqual(oldAnswersRef.current, selectedPackages)) {
      store.setSubstepSave(save);
    } else {
      store.setSubstepSave(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPackages]);

  const handleUserSelection = (selection) => {
    singleChoiceSelection([selection], [selectedPackages], setSelectedPackages, store);
  };

  return isLoading ? (
    <LoadingAnimation />
  ) : (
    <ConfiguratorTwoColumnsLayoutNew
      firstRowTitle='Configura'
      secondRowTitle='il pavimento della cucina'
      firstColumn={<FirstColumn options={packages} selectedOptions={selectedPackages} />}
      secondColumn={
        <SecondColumn
          options={packages}
          selectedOptions={selectedPackages}
          handleUserSelection={handleUserSelection}
        />
      }
      options={packages}
    />
  );
};

export default RelazioneLivingCucina;
