import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Plate from './Plate';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: ({ position }) => position,
    alignItems: 'center',
    width: '100%',
    '& > div': {
      marginRight: '5px',
      marginBottom: '5px',
      '&:last-child': {
        marginRight: 0,
      },
    },
    '&.small': {
      '& > div': {
        marginRight: '2px',
        marginBottom: '2px',
        '&:last-child': {
          marginRight: 0,
        },
      },
    },
  },
}));

const PlateContainer = ({ elements = [], position = 'center', plateText = '', small }) => {
  const classes = useStyles({ position });

  return (
    <Grid container className={clsx(classes.root, small && 'small')}>
      {elements.map((element, i) =>
        element ? (
          <Grid key={i} item>
            <Plate title={`${plateText}${element}`} small={small} />
          </Grid>
        ) : null,
      )}
    </Grid>
  );
};

export default PlateContainer;
