import { makeStyles, Grid } from '@material-ui/core';
import OptionCard, { StandardOptionCardBody } from '../../components/Card/OptionCard';
import { ChefHatLock, ChefHatLockNo } from '../Icons';
import _ from 'lodash';


const useStyles = makeStyles((theme) => ({
    root: {
      height: '79%',
      maxHeight: '80%',
      padding: '50px 25px',
      backgroundColor:'#F0EDEB',
    },
  }));

const NoDepOptions = ({options, selectedOptions, onClick}) => {
  const firstOption = options.find(option => option?.code?.includes('FPAV'));
  const secondOption = options.find(option => option?.code?.includes('FNOPAV'));

  return (
    <Grid container spacing={2} justifyContent='center'>
      <Grid item xs={6} lg={6}>
        <OptionCard
          {...firstOption}
          onClick={() => onClick(firstOption?.id)}
          isSelected={_.difference([firstOption?.id], selectedOptions).length === 0}
          doubleChanging
          // needToKnow={'il pavimento della cucina risulterà uguale a quello di living e camere'}
          textOnButton={'seleziona'}
          infoPriceMessage='Il prezzo è riferito alla metratura del locale cucina'
        >
          <StandardOptionCardBody
            {...firstOption}
            message={'Hai selezionato un pavimento in legno per la zona giorno e notte. Applico lo stesso materiale per il locale cucina'}
            icon={<ChefHatLock
              color='black'
              width='15px'
              height='40px'
            />}
            hideName={true}
          />
        </OptionCard>
      </Grid>
      <Grid item xs={6} lg={6}>
        <OptionCard
          {...secondOption}
          onClick={() => onClick(secondOption.id)}
          isSelected={_.difference([secondOption.id], selectedOptions).length === 0}
          doubleChanging
          isNewCreate
          textOnButton={'inizia la configurazione'}
          // needToKnow={'modificherai la configurazione del pavimento della cucina'}
          infoPriceMessage='Il prezzo è riferito alla metratura del locale cucina'
          noPossiblePrice
        >
          <StandardOptionCardBody
            {...secondOption}
            message={'Cambio stile per il locale cucina'}
            icon={<ChefHatLockNo
              color='black'
              width='15px'
              height='40px'
            />}
            hideName={true}
          />
        </OptionCard>
      </Grid>
    </Grid>
  )
}

const DepOptions = ({options, selectedOptions, onClick}) => {
  const firstOption = options.find(option => option?.code?.includes('FPAV'));

  return (
    <Grid container spacing={2} justifyContent='center'>
      <Grid item xs={8} lg={8}>
        <OptionCard
          {...firstOption}
          onClick={() => onClick(firstOption?.id)}
          singleChanging
          isSelected={_.difference([firstOption?.id], selectedOptions).length === 0}
          textOnButton={'conferma questa scelta'}
          infoPriceMessage='Il prezzo è riferito alla metratura del locale cucina.'
        >
          <StandardOptionCardBody
            {...firstOption}
            message={'Hai selezionato un pavimento in gres per la zona giorno e notte, questo prodotto verrà posato anche nel locale cucina'}
            icon={<ChefHatLock
              color='black'
              width='15px'
              height='40px'
            />}
            hideName={true}
          />
        </OptionCard>
      </Grid>
    </Grid>
  )
}

const KitchenCardContainer = ({options, selectedOptions, handleUserSelection}) => {
  const classes = useStyles();
  const noDep = options?.length > 1;

  return (
    <div className={classes.root}>
      {noDep && (
        <NoDepOptions 
          options={options}
          selectedOptions={selectedOptions}
          onClick={handleUserSelection} />
      )}
      {!noDep && (
        <DepOptions 
          options={options} 
          selectedOptions={selectedOptions} 
          onClick={handleUserSelection} />
      )}
    </div>
  )
};

export default KitchenCardContainer;