import * as React from "react";

function SvgChevron(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 9 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 1l3.411 3 3.541-3"
        stroke={props.color}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </svg>
  );
}

export default SvgChevron;
