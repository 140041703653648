import { useState, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import AlertBox from '../../../components/AlertBox';
import ConfiguratorColumnTitle from '../../../components/Labels/ConfiguratorColumnTitle';
import MoodDetails from '../../../components/MoodDetails';

const useStyles = makeStyles((theme) => ({
  bigImageRoot: {
    position: 'relative',
    height: '100%',
    width: '100%',
    '& .info-bar': {
      position: 'absolute',
      bottom: -10,
      whiteSpace: 'nowrap',
      left: '2%',
      padding: '5px 15px',
      width: '96%',
      minHeight: 33,
      textAlign: 'center',
      '& span': {
        paddingTop: '2px',
        width: '100%',
        whiteSpace: 'break-spaces',
      },
      '& svg': {
        flexShrink: 0,
        height: '20px',
        width: '20px',
        marginLeft: '2px',
        marginTop: '-1px',
      },
    },
  },
  containerImage:{
    width: '100%',
    position: 'relative',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& > div': {
      position: 'absolute',
      bottom: 8,
      left: 8,
    },
  },
  image: {
    height: '100%',
    maxWidth: '100%',
    position: 'absolute',
    borderRadius: '8px',
  },
  containerImagePreview: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignContent:'center',
    alignItems: 'center',
    backgroundColor: '#F0EDEB',
    borderRadius: '8px',
    position: 'relative'
  },
  imagePreview: {
    width: '100%',
    backgroundPosition: 'center',
    position: 'relative',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat', 
    height: '100%',
    '& > div': {
      position: 'absolute',
      bottom: 8,
      left: 8,
    },
  },
  selectedFinitura: {
    height: 240,
    width: 210,
    position: 'absolute',
    bottom: -1,
    left: -1,
    background: '#FFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '30px',
    [theme.breakpoints.down('lg')]: {
      height: 170,
      width: 150,
      paddingBottom: 20,
    },
    [theme.breakpoints.down('sm')]: {
      height: 155,
      width: 125,
    },
    '& img': {
      height: '80%',
      width: '80%',
      objectFit: 'cover',
    },
  },
}));

const loadImage = (setImageDimensions, imageUrl) => {
	const img = new Image();
	img.src = imageUrl;

	img.onload = () => {
		setImageDimensions({
			height: img.height,
			width: img.width
		});
	};
	img.onerror = (err) => {
		console.log("img error");
		console.error(err);
	};
};

const BigImage = ({ firstRowTitle, secondRowTitle, moods, image, selectedFinitura, selectedMoods, selectedFinituraProductDescription, isNewNoPreviewLAyout }) => {
  const classes = useStyles();
  const [imageDimensions, setImageDimensions] = useState({});

  useEffect(() => {
    loadImage(setImageDimensions, image);
  }, [image]);

  if (isNewNoPreviewLAyout) {
    return (
      <div className={classes.containerImagePreview}>
        <img src={image} alt={''} className={classes.image} />
      </div>
  );
}

  return (
    <Grid
      container
      direction='column'
      className={clsx(classes.bigImageRoot)}
    >
      {firstRowTitle && (
        <Grid item xs='auto'>
          <ConfiguratorColumnTitle firstRow={firstRowTitle} secondRow={secondRowTitle} />
        </Grid>
      )}

      <div className={classes.containerImage} >
        <img src={image} alt={'Virtual'} className={classes.image} />
        <div>{moods && moods.map((el, i) => <MoodDetails key={i} {...el} isInfo />)}</div>
      </div>
      {/*selectedFinitura && (
        <>
          <div className={classes.selectedFinitura}>
            <img src={selectedFinitura} alt='selected-finitura' />
          </div>
          <AlertBox
            className={'info-bar'}
            noIcon
            text={`LA VIRTUAL FA RIFERIMENTO A ${selectedMoods} | ${selectedFinituraProductDescription}`}
          />
        </>
      )*/}
      {selectedFinitura && (
        <>
          <AlertBox
            className={'info-bar'}
            noIcon
            text={`LA VIRTUAL FA RIFERIMENTO A ${selectedMoods} | ${selectedFinituraProductDescription}`}
          />
        </>
      )}
    </Grid>
  );
};

export default BigImage;
