import * as React from 'react';

function SvgUsbLavanderia(props) {
  return (
    <svg viewBox='0 0 40 33' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.83 6.02a10.12 10.12 0 014.17.866v2.55a10.11 10.11 0 01-.78-.336 6.829 6.829 0 00-3.45-.77 6.83 6.83 0 00-3.47.77 8.89 8.89 0 01-4.47 1 10.12 10.12 0 01-4.47-1 6.8 6.8 0 00-2.14-.68l5.64 22.26H23v2.26H8.72a1.11 1.11 0 01-1-.86L.03 1.44A1.14 1.14 0 01.18.57a1.19 1.19 0 01.78-.5 1.09 1.09 0 011.209.418c.093.129.158.276.19.432l1.3 5.1c1.314.058 2.6.4 3.77 1a6.81 6.81 0 003.45.77 6.861 6.861 0 003.47-.77 9 9 0 014.48-1zm18.227 4.078H40v3.164h-1.006v2.45c0 .255-.045.507-.136.742-.091.235-.224.448-.392.627l-4.117 4.458h-.038v5.83c.649.127 1.23.509 1.63 1.072.4.564.59 1.268.53 1.974a2.922 2.922 0 01-.847 1.841 2.539 2.539 0 01-1.78.744c-.66 0-1.295-.265-1.782-.744a2.922 2.922 0 01-.847-1.841c-.058-.706.13-1.41.53-1.974.4-.563.982-.945 1.63-1.071v-2.375l-3.883-4.18a1.932 1.932 0 01-.392-.628 2.046 2.046 0 01-.136-.741v-2.757a1.459 1.459 0 01-.763-.67 1.637 1.637 0 01-.181-1.04 1.57 1.57 0 01.487-.92c.258-.236.586-.365.925-.365.338 0 .666.13.924.364.259.235.431.562.487.92.056.36-.008.728-.18 1.042-.173.313-.444.55-.764.669v2.752c0 .122.022.243.066.356a.942.942 0 00.187.303l3.223 3.471V7.144H32.16l.842-1.574.842-1.57.847 1.57.842 1.574H34.33V20.13l3.495-3.758a.972.972 0 00.233-.66v-2.45h-1v-3.164z'
        fill='#C4C4C4'
      />
    </svg>
  );
}

export default SvgUsbLavanderia;
