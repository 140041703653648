import { makeStyles } from '@material-ui/core';
import { CloseRound } from '../Icons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '32px 20px 22px',
    backgroundColor: theme.palette.color.grey1,
    border: '1px solid #CDC7C1',
    borderTop: '10px solid #CDC7C1',
  },
  title: {
    marginBottom: '12px',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',
  },
  noSelection: {
    position: 'relative',
    width: '70px',
    height: '70px',
    color: theme.palette.primary.main,
    '& .image': {
      position: 'relative',
      opacity: '0.65',
      backgroundColor: '#F0EDEB',
      borderRadius: '5px',
    },
    '& .icon': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '5px',
      color: theme.palette.primary.main,
    },
  },
  text: {
    marginTop: '22px',
    fontFamily: 'miller',
    fontStyle: 'italic',
    fontSize: '15px',
    lineHeight: '18px',
    textAlign: 'center',
    color: '#CDC7C1',
  },
}));

const NoSelectionCard = ({ title, description }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <span className={classes.title}>{title}</span>
      <div className={classes.noSelection}>
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/box-oggetto.png`}
          alt=''
          className='image'
        />
        <CloseRound color='currentColor' width='26px' height='26px' className='icon' />
      </div>
      <span className={classes.text}>{description}</span>
    </div>
  );
};

export default NoSelectionCard;
