import * as React from 'react';

function SvgCarichiSmart(props) {
  return (
    <svg viewBox='0 0 138 97' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M19.799 49.128l4.48 4.48a2.53 2.53 0 003.49.08 60.66 60.66 0 0182.21 0 2.53 2.53 0 003.5-.08l4.48-4.48a2.53 2.53 0 00.742-1.834 2.52 2.52 0 00-.812-1.806 72.06 72.06 0 00-98 0 2.531 2.531 0 00-.08 3.64h-.01zm-18.58-18.58l4.47 4.46a2.53 2.53 0 003.53.05 87 87 0 01119.31 0 2.53 2.53 0 003.53-.05l4.48-4.47a2.537 2.537 0 00-.06-3.63 98.38 98.38 0 00-135.21 0 2.54 2.54 0 00-.05 3.63v.01zm56.26 55.78l9.61 9.68a2.53 2.53 0 003.57 0l9.63-9.63a2.52 2.52 0 00-.28-3.82 18.89 18.89 0 00-22.27 0 2.53 2.53 0 00-.26 3.77zm-19-18.55l4.47 4.47a2.52 2.52 0 003.45.13 34.31 34.31 0 0145 0 2.51 2.51 0 003.44-.13l4.48-4.47a2.53 2.53 0 00-.09-3.66 45.69 45.69 0 00-60.68 0 2.52 2.52 0 00-.07 3.66z'
        fill='#4D4D4D'
      />
    </svg>
  );
}

export default SvgCarichiSmart;
