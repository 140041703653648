import * as React from 'react';

function SvgNoPresaComandata(props) {
  return (
    <svg viewBox='0 0 178 121' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#no-presa-comandata_svg__clip0_2764_23408)' fill='#3A3A3A'>
        <path d='M66.36 63.204a3.87 3.87 0 003.858-3.871 3.87 3.87 0 00-3.859-3.872 3.87 3.87 0 00-3.859 3.872 3.87 3.87 0 003.86 3.871zM63.29 48.811v.321c.188 1.55 1.506 2.758 3.068 2.758 1.563 0 2.937-1.247 3.05-2.909V46.47c.508-.66.79-1.473.79-2.341a3.87 3.87 0 00-3.858-3.872 3.87 3.87 0 00-3.86 3.872c0 .85.283 1.661.791 2.323v2.38l.019-.02zM69.428 69.87l-.038-.32a3.065 3.065 0 00-3.05-2.74c-1.581 0-2.936 1.247-3.05 2.909v2.512a3.87 3.87 0 003.069 6.214 3.87 3.87 0 003.86-3.872c0-.85-.283-1.662-.791-2.323V69.87z' />
        <path d='M78.18 87.36c2.993 0 5.422-2.436 5.422-5.44V36.764c0-3.002-2.429-5.439-5.422-5.439H54.554c-2.993 0-5.421 2.437-5.421 5.44V81.92c0 3.002 2.428 5.439 5.421 5.439H78.18zm-25.301-5.44V36.764c0-.925.753-1.661 1.657-1.661H78.16c.923 0 1.657.755 1.657 1.662V81.92c0 .925-.753 1.662-1.657 1.662H54.536a1.662 1.662 0 01-1.657-1.662zM127.727 87.36c2.993 0 5.422-2.436 5.422-5.44V36.764c0-3.002-2.429-5.439-5.422-5.439h-23.626c-2.993 0-5.421 2.437-5.421 5.44V81.92c0 3.002 2.428 5.439 5.421 5.439h23.626zm-25.301-5.44V36.764c0-.925.753-1.661 1.657-1.661h23.625c.922 0 1.657.755 1.657 1.662V81.92c0 .925-.753 1.662-1.657 1.662h-23.625a1.662 1.662 0 01-1.657-1.662z' />
        <path d='M109.731 80.466h12.35c2.277 0 4.141-1.87 4.141-4.155a1.89 1.89 0 00-1.882-1.89c-1.036 0-1.883.85-1.883 1.89a.378.378 0 01-.376.377h-12.35a.378.378 0 01-.376-.377 1.891 1.891 0 00-1.883-1.89 1.89 1.89 0 00-1.882 1.89c0 2.285 1.864 4.154 4.141 4.154z' />
        <path d='M164.08 11.002H17.809c-1.074 0-1.92.869-1.92 1.945v20.246c0 1.077.865 1.946 1.92 1.946 1.054 0 1.92-.87 1.92-1.946v-18.32H162.14v88.936H40.549 24.812c-1.638-.056-3.257-.774-4.443-1.964-1.205-1.209-1.901-2.833-1.996-4.533v-8.914h2.184a.676.676 0 00.678-.68v-2.852a.676.676 0 00-.678-.68H12.03a.676.676 0 00-.677.68v2.852c0 .378.3.68.677.68h2.146v10.369c0 3.852 4.029 9.254 10.618 9.254h15.662c.245 0 .433-.132.565-.321h123.06c1.073 0 1.92-.869 1.92-1.946V12.947c0-1.076-.866-1.945-1.92-1.945zM6.233 56.801h2.993a.676.676 0 00.678-.68V43.203a2.21 2.21 0 00-.113-.83 2.122 2.122 0 00-.47-.756 1.893 1.893 0 00-.735-.472 2.063 2.063 0 00-.885-.133.61.61 0 00-.263.076c-.17 0-.339.057-.508.113a2.04 2.04 0 00-.735.472 1.97 1.97 0 00-.47.756c-.113.283-.15.585-.113.869v12.786c0 .377.301.68.677.68l-.056.037z' />
        <path d='M26.129 56.725a.676.676 0 00.678-.68V43.221a2.068 2.068 0 00-.113-.812 2.51 2.51 0 00-.452-.736 2.031 2.031 0 00-.697-.491c-.244-.114-.452-.133-.734-.17-.301 0-.565.019-.828.132a2.29 2.29 0 00-.753.453 2.48 2.48 0 00-.49.756c-.112.264-.131.585-.112.887v12.767c0 .378.3.68.677.68h2.824v.038zM30.517 80.976c.358-.02.64-.114.941-.246a4.35 4.35 0 00.734-.585c.188-.246.32-.51.433-.831.075-.303.113-.586.057-.926V62.165c.056-.208.018-.548-.057-.831a3.162 3.162 0 00-.433-.831c-.188-.208-.433-.434-.734-.567a1.796 1.796 0 00-.885-.264H2.11c-.697.075-1.243.397-1.62.888a2.201 2.201 0 00-.451 1.7v16.223c-.057.207-.019.51.056.812.076.32.207.585.396.83.188.209.395.435.677.567.264.17.565.246.866.303h28.483v-.02z' />
      </g>
      <path d='M173.356.884L13.352 114.88l3.695 5.187L177.051 6.071 173.356.884z' fill='#F0801A' />
      <defs>
        <clipPath id='no-presa-comandata_svg__clip0_2764_23408'>
          <path fill='#fff' transform='translate(0 11.002)' d='M0 0h166v97H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgNoPresaComandata;
