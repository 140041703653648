import { Grid, makeStyles, useTheme, Divider } from '@material-ui/core';
import { CartaParati, InfoCircled2 } from './Icons';
import Button3D from './Buttons/Button3D';
import PlanimetryZoom from './PlanimetryZoom';
import { useStore } from '../store/storeUtils';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    maxHeight: '100%',
    // backgroundColor:'white'
    backgroundColor: '#EBE4E0',
    // padding: '30px'
  },
  button: {
    width: '100%',
    maxWidth: 'unset !important',
    height: '40px !important',
    //marginBottom: '10px',
    fontSize: '13px',
  },
}));

const InfoColumn = ({ setFirstStep, planimetryImages, layoutReference, bagnoPage }) => {
  const classes = useStyles();
  const theme = useTheme();
  const store = useStore();
  const hideFirstStep = () => {
    setFirstStep(false);
  }

  const currentRoomId = store.currentRoomId;
  const rooms = store.currentConfiguration?.layout?.rooms;
  const currentRoom = rooms.find(room => room.id === currentRoomId);
  // const bagniRooms = store?.currentConfiguration?.layout?.rooms?.filter((room) => room?.roomType?.name === 'BAGNO');

  return (
    <div className={classes.root}>
      <Grid container direction='column' spacing={1} >
        <Grid item xs={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft:'20px', paddingRight:'20px', paddingTop:'20px'}}>
            {bagnoPage ? <InfoCircled2 width='30px' height='30px' color='#F08219'/> : <CartaParati/>}
        </Grid>
        {bagnoPage ? 
        <Grid item xs={12} style={{ textAlign: 'center', paddingLeft:'20px', paddingRight:'20px' }}>
          {/*<p style={{fontFamily: 'miller', fontStyle: 'italic', fontSize: '12px'}}>La tua casa presenta <b style={{color: theme.palette.color.orange}}>{bagniRooms?.length} {bagniRooms?.length > 1 ? 'bagni' : 'bagno'}</b></p>*/}
          <p style={{fontFamily: 'miller', fontStyle: 'italic', fontSize: '20px'}}>Inizia la configurazione del <b style={{color: theme.palette.color.orange}}>{currentRoom?.name}</b></p>
        </Grid> :
        <Grid item xs={10} style={{ paddingLeft:'20px', paddingRight:'20px'}}>
          <p style={{fontFamily: 'miller', fontStyle: 'italic', fontSize: '28px', lineHeight: '32px'}}>Nella fase successiva hai la possibilità di decorare alcune pareti della tua casa con bellissime <b style={{color: theme.palette.color.orange}}>carte da parati</b></p>
          <p>* Se non desideri la carta da parati seleziona 'Nessuno'.</p>
        </Grid>
        }
        {
          // <Divider style={{width: '100%', marginTop:'20%'}}/>
        }
        <Grid item xs={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <div style={{position:'relative', minWidth:'200px', minHeight:'200px', width:'100%', height:'100%'}}>
            <PlanimetryZoom
              images={planimetryImages}
              notZoom={true}
              reference={layoutReference}
              // rotate={bagnoPage}
            />
          </div>
          {
          //<div style={{fontSize: '12px'}}><Gallery /> <span> Guarda la gallery rappresentativa della carta da parati.</span></div>
          }
        </Grid>
        <Grid item xs={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: bagnoPage ?'10px 20px 10px 20px' : '5px'}}>
            <Button3D
              text={'INIZIA LA CONFIGURAZIONE'}
              className={classes.button}
              onClick={hideFirstStep}
            />
        </Grid>
      </Grid>
    </div>
  );
};

export default InfoColumn;
