import API from './api';

export const getLayout = async (configurationId) => {
  return await API.get(`/External/Layout?configurationId=${configurationId}`);
};

export const getLayoutImagesById = async (layoutId) => {
  return await API.get(`/External/Layout/FileLabelUrlsByLayoutId?layoutId=${layoutId}`);
};

export const getLayoutImagesByConfigurationId = async (configurationId) => {
  return await API.get(
    `/External/Layout/FileLabelUrlsByConfigurationId?configurationId=${configurationId}`,
  );
};

export const getDoorsFileLabelUrlsByLayoutId = async (layoutId) => {
  return await API.get(`/External/Layout/DoorsFileLabelUrlsByLayoutId?layoutId=${layoutId}`);
};

export const getCartaDaParatiLayout = async (layoutId) => {
  return await API.get(`/External/Layout/AdditionalInfo?layoutId=${layoutId}`);
};
