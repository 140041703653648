import { makeStyles, Popover } from '@material-ui/core';
import React from 'react';
import { InfoPopupRichText } from '../InfoPopup';

const useStyles = makeStyles((theme) => ({
  popover: {
    backgroundColor: 'rgba(0,0,0,0) !important',
    borderTopLeftRadius: '90px',
  },
}));

const RichTextPopover = ({ show, onClose, anchorEl, infoRichText }) => {
  const classes = useStyles();

  return (
    <Popover
      open={show}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      PaperProps={{ classes: { root: classes.popover } }}
    >
      <InfoPopupRichText body={infoRichText} isPopover handleClose={onClose} />
    </Popover>
  );
};

export default RichTextPopover;
