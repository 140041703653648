import * as React from 'react';

function SvgSend(props) {
  return (
    <svg viewBox='0 0 37 36' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#send_svg__clip0_3005_23672)'>
        <path
          d='M15.73 25.35v5.2c1.04-1.42 2.04-2.78 3.05-4.16-1.04-.36-2.03-.7-3.05-1.05M4.07 18.91c.11.05.18.08.26.1 3.23 1.1 6.45 2.2 9.68 3.32.21.07.3-.01.42-.14 5.11-5.49 10.23-10.97 15.34-16.47.07-.07.18-.12.15-.31-8.61 4.52-17.21 9.01-25.85 13.51m12.55 4.3c.09.03.13.05.17.06 2.3.79 4.6 1.57 6.91 2.36 2.16.74 4.31 1.48 6.46 2.21.16.05.32.17.36-.15.25-1.79.52-3.57.79-5.36.42-2.81.83-5.63 1.25-8.44.44-2.97.89-5.93 1.33-8.9.01-.08.05-.18-.03-.27-5.74 6.16-11.48 12.31-17.24 18.49zM0 18.82c.23-.46.64-.7 1.08-.93C12.27 12.05 23.45 6.21 34.63.37c.22-.11.42-.24.63-.37h.72c.69.37.85.91.74 1.68-.72 4.63-1.4 9.26-2.08 13.9-.61 4.1-1.21 8.2-1.82 12.31-.08.57-.17 1.14-.26 1.7-.14.84-.79 1.23-1.61.95-1.24-.42-2.48-.84-3.73-1.27-1.95-.67-3.91-1.33-5.85-2.01-.24-.09-.35-.03-.49.16l-5.28 7.2c-.29.39-.64.65-1.14.6-.64-.07-1.03-.54-1.03-1.24v-6.21c0-.97-.01-1.94 0-2.91 0-.26-.09-.35-.31-.43-4.02-1.37-8.03-2.75-12.06-4.1-.49-.16-.83-.43-1.05-.88v-.65l-.01.02z'
          fill='#3C3C3B'
        />
      </g>
      <defs>
        <clipPath id='send_svg__clip0_3005_23672'>
          <path fill='#fff' d='M0 0h36.76v35.24H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgSend;
