import { lighten, makeStyles } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import '../../styles/circleLoader.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '72px',
    backgroundColor: 'transparent',
    color: theme.palette.color.black,
    fontWeight: 700,
    fontSize: '13px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    transition: 'all 0.3s ease-in-out',
    padding: '10px 0',
  },
  orange: {
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.7),
    },
    '&:active': {
      opacity: 0.7,
    },
  },
  green: {
    border: `1px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: lighten(theme.palette.secondary.main, 0.7),
    },
    '&:active': {
      opacity: 0.7,
    },
  },
  text: {
    // padding: '12px 0 7px',
    color: theme.palette.color.black,
  },
}));

const IconSquareButton = ({ text, icon, color = 'orange', onClick, isLoading }) => {
  const classes = useStyles();
  return (
    <ButtonBase className={`${classes.root} ${classes[color]}`} onClick={onClick} disableRipple>
      {isLoading ? (
        <div className='loader-1 center'>
          <span></span>
        </div>
      ) : (
        <div>{icon}</div>
      )}

      <div className={classes.text}>{text}</div>
    </ButtonBase>
  );
};

export default IconSquareButton;
