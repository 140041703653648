import {
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Gear } from '../Icons';
import { deviceType } from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
  configurationTableRoot: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: '10px',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  circle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '55px',
    height: '55px',
    backgroundColor: '#CDC7C1',
    border: '1px solid #fff',
    borderRadius: '50%',
  },
  table: {
    textAlign: 'center',
    color: '#8E8E8E',
    borderSpacing: '4px',
    borderCollapse: 'separate',
    '& th,td': {
      fontSize: '12px',
      lineHeight: '14px',
    },
    '& th': {
      textTransform: 'uppercase',
      border: 0,
      color: '#8E8E8E',
      fontFamily: 'p22-underground',
    },
    '& tr ': {
      height: '15px',
    },
    '& tr td': {
      backgroundColor: '#FFF',
      whiteSpace: ({ deviceType }) => deviceType === 'browser' && 'nowrap',
      border: 0,
      padding: 2,
      fontFamily: 'p22-underground',
    },
    '& tr td:first-child': {
      backgroundColor: 'initial',
      color: '#8E8E8E',
      textTransform: 'lowercase',
      whiteSpace: ({ deviceType }) => deviceType === 'browser' && 'nowrap',
    },
    '& tr td:last-child': {
      padding: 2,
    },
  },
  tableHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
  tableRowHeader: {
    alignSelf: 'center',
    whiteSpace: ({ deviceType }) => deviceType === 'browser' && 'nowrap',
    textAlign: 'start',
    minWidth: '25%',
  },
  value: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '2px',
    backgroundColor: theme.palette.color.white,
    color: theme.palette.color.black,
    [theme.breakpoints.down('sm')]: {
      height: '28px',
    },
  },
}));

const ConfigurationTable = ({ data }) => {
  const classes = useStyles({ deviceType });

  return (
    <div className={classes.configurationTableRoot}>
      <div className={classes.icon}>
        <div className={classes.circle}>
          <Gear color='currentColor' width='33px' height='33px' />
        </div>
      </div>
      <TableContainer>
        <Table className={classes.table} size='small'>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align='center'>Punti presa</TableCell>
              <TableCell align='center'>Punti luce</TableCell>
              <TableCell align='center'>Prese tv</TableCell>
              <TableCell align='center'>Prese dati</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, i) => (
              <TableRow key={i}>
                <TableCell>{row.name}</TableCell>
                <TableCell align='center'>{row.preseElettriche}</TableCell>
                <TableCell align='center'>{row.puntiLuce}</TableCell>
                <TableCell align='center'>{row.preseTv}</TableCell>
                <TableCell align='center'>{row.preseDati}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ConfigurationTable;
