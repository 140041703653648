import { useState, useRef } from 'react';
import { makeStyles, Popper, ClickAwayListener, Grid } from '@material-ui/core';
import { Alert, Exit } from './Icons';
import clsx from 'clsx';
import { isDesktop, isMobile } from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
  materialSelectionRoot: {
    display: 'flex',
    alignItems: 'center',
    gap: '30px',
    width: '100%',
    height: '100%',
    padding: '32px 25px',
    flexWrap: 'nowrap',

    '& .material-img': {
      width: '167px',
      height: '167px',
      backgroundColor: '#000',
    },

    '& .material-description': {
      display: 'flex',
      flexDirection: 'column',
      gap: '33px',
      fontWeight: '500',
      textTransform: 'uppercase',
      [theme.breakpoints.down('md')]: {
        gap: '15px',
      },
      '& .text': {
        fontSize: '15px',
        lineHeight: '18px',
        [theme.breakpoints.down('md')]: {
          fontSize: '13px',
          lineHeight: '16px',
        },
      },
      '& .info': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        gap: '9px',
        fontSize: '15px',
        lineHeight: '18px',
        color: '#5E5E5E',
        [theme.breakpoints.down('md')]: {
          fontSize: '13px',
          lineHeight: '16px',
        },
        '& .more-info': {
          display: 'flex',
          flexDirection: 'column',
          '& .read': {
            textTransform: 'initial',
            textDecoration: 'underline',
            cursor: 'pointer',
          },
        },
      },
    },

    '&.desktop': {
      [theme.breakpoints.down('lg')]: {
        height: '116px',
        flexGrow: 0,
        padding: '0 !important',
        gap: 15,
        '& .text-container': {
          height: '100%',
        },
        '& .material-description': {
          gap: 'unset',
          height: '100%',
          justifyContent: 'space-around',
          '& .text': {
            fontSize: 13,
            lineHeight: '15px',
          },
          '& .info': {
            fontSize: 13,
            lineHeight: '15px',
          },
        },
        '& .material-img': {
          width: '92px',
          height: '92px',
        },
      },
    },
    '&.mobile': {
      [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '0 16px',
        justifyContent: 'flex-start',
        '& .material-img': {
          width: '147px',
          height: '147px',
        },
        '& .material-description': {
          gap: '15px',
          paddingBottom: 15,
        },
      },
      [theme.breakpoints.down('md')]: {
        '& .material-description': {
          gap: '15px',

          '& .text': {
            fontSize: '13px',
            lineHeight: '16px',
          },
          '& .info': {
            fontSize: '13px',
            lineHeight: '16px',
          },
        },
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0 16px',
        justifyContent: 'unset',
        '& .material-img': {
          width: '100px',
          height: '100px',
        },
      },
    },
  },

  popper: {
    position: 'relative',
    width: '260px',
    height: '130px',
    padding: '24px 12px 8px',
    fontSize: '13px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    color: '#FFF',
    borderRadius: '5px',
    '& .header': {
      display: 'flex',
      flexDirection: 'row',
      gap: '4px',
      fontWeight: '600',
    },
    '& .content': {
      fontWeight: '400',
      textTransform: 'initial',
    },
    '& .exit': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: '8px',
      right: '8px',
      width: '15px',
      height: '15px',
      backgroundColor: '#000',
      color: theme.palette.primary.main,
      borderRadius: '50%',
      cursor: 'pointer',
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    height: '100%',
    overflow: 'hidden auto',
  },
}));

const MaterialSelection = ({ product, className }) => {
  const classes = useStyles();

  const [openPopper, setOpenPopper] = useState(false);
  const popperRef = useRef(null);

  const handleClick = () => setOpenPopper(!openPopper);

  return (
    <>
      <Grid
        container
        className={clsx(
          classes.materialSelectionRoot,
          isDesktop && 'desktop',
          isMobile && 'mobile',
          className,
        )}
      >
        <Grid item xs={'auto'} className={classes.firstPart}>
          <div
            className={'material-img'}
            style={{ backgroundImage: `url(${product.image})` }}
          ></div>
        </Grid>

        <Grid item xs='auto' className={'text-container'}>
          <div className={'material-description'}>
            <div className='text'>
              Questo è lo stile del <strong>{product.title} da te configurato</strong>
            </div>
            <div className='info'>
              <Alert color='currentColor' />
              <div className='more-info'>
                <span>Immagine di riferimento</span>
                <span className='read' onClick={handleClick} ref={popperRef}>
                  Leggi di piú
                </span>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      {openPopper && (
        <ReadMorePopper
          popperRef={popperRef}
          openPopper={openPopper}
          setOpenPopper={setOpenPopper}
        />
      )}
    </>
  );
};

export default MaterialSelection;

const ReadMorePopper = ({ popperRef, openPopper, setOpenPopper }) => {
  const classes = useStyles();

  const id = openPopper ? 'popper' : undefined;
  const onClickAway = () => setOpenPopper(false);

  return (
    <Popper
      id={id}
      open={openPopper}
      anchorEl={popperRef.current}
      placement='top-start'
      transition
      popperOptions={{
        modifiers: {
          offset: {
            enabled: true,
            offset: '-24px, 24px',
          },
        },
      }}
      className={classes.popper}
    >
      <ClickAwayListener onClickAway={onClickAway}>
        <>
          <div className='exit' onClick={onClickAway}>
            <Exit color='currentColor' width='6px' height='6px' />
          </div>
          <div className={classes.contentContainer}>
            <div className='header'>
              <Alert color='currentColor' reversed />
              <span>Immagini di riferimento</span>
            </div>
            <span className='content'>
              Le immagini illustrate sono da considerarsi come riferimenti, per cui il prodotto dal
              vivo potrebbe risultare con piccole varianti.
            </span>
          </div>
        </>
      </ClickAwayListener>
    </Popper>
  );
};
