import BoxAPI from './api';

export const listFolder = async (folderId) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://api.box.com/2.0/folders/${folderId}`,
      };
    
    try {
        const response = await BoxAPI.request(config);
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
};