import * as React from "react";

function SvgExit(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 7 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path stroke={props.color} strokeLinecap="round" d="M7 0L0 7M7 7L0 0" />
    </svg>
  );
}

export default SvgExit;
