
import { useEffect, useState, useRef } from 'react';
import GridDetailsContainer from '../../../components/Card/GridDetailsContainer';
import MaterialDataSheet from '../../../components/MaterialDataSheet';
import ConfiguratorThreeColumnsLayoutFormato from '../../../layouts/ConfiguratorThreeColumnsLayoutFormato';
import { makeStyles } from '@material-ui/core';
import { resumeAnswerSimple } from '../../../utils/configuratorUtils';
import { getPackages } from '../../../services/packageApi';
import { useStore } from '../../../store/storeUtils';
import { useParams } from 'react-router';
import { handleSave, singleChoiceSelection } from '../../../utils/selectionUtils';
import _ from 'lodash';
import LoadingAnimation from '../../../components/LoadingAnimation';
import {
  materialDataSheetOptionsConfiguration,
  secondMaterialDataSheetOptionsConfiguration,
  packagesRestructuringDoubleConfiguration,
  setPavimentoBagnoConfiguration,
} from '../../../configs/bagnoPhasesConfigurations';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '15px',
    textAlign: 'left',
    height: '5%',
  },
  secondaryText: {
    color: theme.palette.color.orange,
  },
}));

const FirstColumn = ({
  options,
  selectedOptions,
}) => {
  const firstMaterialObject = materialDataSheetOptionsConfiguration(options, selectedOptions);
  const secondMaterialObject = secondMaterialDataSheetOptionsConfiguration(options, selectedOptions);
  const materialToLoad = firstMaterialObject?.rivOrPav === 'Pavimento' ? firstMaterialObject : secondMaterialObject;
    return (
      <MaterialDataSheet
        firstRowTitle={'PREVIEW'}
        secondRowTitle={'PAVIMENTO'}
        previewBoxText={"un FORMATO"}
        material={materialToLoad}
      />
    )
};

const SecondColumn = ({options, selectedOptions}) => {
  const secondMaterialObject = secondMaterialDataSheetOptionsConfiguration(options, selectedOptions);
  const firstMaterialObject = materialDataSheetOptionsConfiguration(options, selectedOptions);
  const materialToLoad = firstMaterialObject?.rivOrPav === 'Rivestimento' ? firstMaterialObject : secondMaterialObject;
  return (
    <MaterialDataSheet
      firstRowTitle={'PREVIEW'}
      secondRowTitle={'RIVESTIMENTO'}
      previewBoxText={"un FORMATO"}
      material={materialToLoad}
    />
  );
};

const ThirdColumn = ({options, selectedOptions, handleUserSelection}) => {
  const classes = useStyles();
  const containers = [{
      title: 'Formato',
      content: options,
    }];
  return (
    <>
      <div className={classes.title}>
        <span>
          Quale <span className={classes.secondaryText}>formato</span> voui impostare per il tuo <span className={classes.secondaryText}>Bagno </span>?
        </span>
      </div>
      <div style={{ height: '95%' }}>
        <GridDetailsContainer 
          containers={containers} 
          onClick={handleUserSelection} 
          selectedOptions={selectedOptions} 
          numCols={1} 
        />
      </div>
    </>
  );
};

const FormatoBagno = () => {
  const [packages, setPackages] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);

  const substepIdRef = useRef();
  const oldAnswersRef = useRef();

  const store = useStore();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const configurationId = params.configuration;
  const currentRoomId = store.currentRoomId;

  useEffect(() => {
    setIsLoading(true);
    store.setUserCanGoNext(false);
    prepareOptions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoomId]);

  const prepareOptions = async () => {
    const substepId = store.currentSubstepId;
    const packages = await getPackages(configurationId, substepId, currentRoomId);
    substepIdRef.current = store.currentSubstepId;
    const packagesRestruct =  packagesRestructuringDoubleConfiguration(packages, 'FormatoBagno');
    setPackages(packagesRestruct);
    resumeAnswerSimple(store, oldAnswersRef, substepIdRef, setSelectedPackages, setIsLoading, currentRoomId);
  }

  useEffect(() => {
    const save = async () => {
      await handleSave(null, configurationId, substepIdRef.current, store, selectedPackages?.[0], currentRoomId);
      const selectedFormati = packages.find(pac => pac.id === selectedPackages?.[0])// ?.preview?.replace(/\s/g, '%20');
      let selectedFormatiPreviewPavimento;
      let selectedFormatiDescriptionPavimento;
      let selectedFormatiPreviewRivestimento;
      let selectedFormatiDescriptionRivestimento;
      if (selectedFormati?.rivOrPav === 'Pavimento' ) {
       selectedFormatiPreviewPavimento = selectedFormati?.preview?.replace(/\s/g, '%20');
       selectedFormatiDescriptionPavimento = selectedFormati?.productName;
       selectedFormatiPreviewRivestimento = selectedFormati?.secondProductPreview?.replace(/\s/g, '%20');
       selectedFormatiDescriptionRivestimento = selectedFormati?.secondProductName;
      } else {
       selectedFormatiPreviewRivestimento = selectedFormati?.preview?.replace(/\s/g, '%20');
       selectedFormatiDescriptionRivestimento = selectedFormati?.productName;
       selectedFormatiPreviewPavimento = selectedFormati?.secondProductPreview?.replace(/\s/g, '%20');
       selectedFormatiDescriptionPavimento = selectedFormati?.secondProductName;
      }

      setPavimentoBagnoConfiguration(
        store,
        currentRoomId,
        selectedFormatiPreviewPavimento,
        selectedFormatiDescriptionPavimento,
        selectedFormatiPreviewRivestimento,
        selectedFormatiDescriptionRivestimento
      )

      /*
      const selectedPavimentiBagno = store.answers.filter((el) => el.substep.value === 'PAVIMENTO');
	    const selectedParetiBagno = store.answers.filter((el) => el.substep.value === 'PARETI');
	    const selectedFormatoBagno = store.answers.filter((el) => el.substep.value === 'FORMATO' && el.room.id );
      const formatoSubStepId = selectedFormatoBagno.find(el => el.room?.id === currentRoomId)?.substep?.id;
      const pavimentoSubStepId = selectedPavimentiBagno.find(el => el.room?.id === currentRoomId)?.substep?.id;
      const pareteSubStepId = selectedParetiBagno.find(el => el.room?.id === currentRoomId)?.substep?.id;
      // const formatoPackages = await getPackages(params.configuration, formatoSubStepId, currentRoomId);
      // const pavimentoPackages = await getPackages(params.configuration, pavimentoSubStepId, currentRoomId);
      // const paretePackages = await getPackages(params.configuration, pareteSubStepId, currentRoomId);
      const configurationResponse = store.currentConfiguration;
      setPavimentoBagnoConfiguration(
        configurationResponse,
        formatoPackages,
        pavimentoPackages,
        paretePackages,
        store,
      );
      */
    };
    if (!_.isEqual(oldAnswersRef.current, selectedPackages)) {
      store.setSubstepSave(save);
    } else {
      store.setSubstepSave(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPackages]);

  const handleUserSelection = (selection) => {
    singleChoiceSelection([selection], [selectedPackages], setSelectedPackages, store);
  };

  return (isLoading ? <LoadingAnimation /> :
    <ConfiguratorThreeColumnsLayoutFormato
      firstRowTitle='CONFIGURA'
      secondRowTitle='IL FORMATO PER IL RIVESTIMENTO E PAVIMENTO'
      firstColumn={
        <FirstColumn
          options={packages}
          selectedOptions={selectedPackages}
        />
      }
      secondColumn={
        <SecondColumn
          options={packages}
          selectedOptions={selectedPackages}
        />
      }
      thirdColumn={
        <ThirdColumn
          options={packages}
          selectedOptions={selectedPackages}
          handleUserSelection={handleUserSelection}
        />
      }
    />
  );
};

export default FormatoBagno;
