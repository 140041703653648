import * as React from 'react';

function SvgChiusuraCucinaNew(props) {
  return (
    <svg viewBox='0 0 87 75' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#chiusura-cucina-new_svg__clip0_2723_23226)'>
        <path d='M46.32 31.28v40.99H14.91V13.51h31.41v17.77z' fill='#D3D3D3' />
        <path d='M41.362 43.43v-1.54h-7.85v2h7.85v-.46z' fill='#5B5B5B' />
        <path
          d='M81.121 72.26V11.51h-68.21v60.76h-6.88v2h42.29V62.13h15.77v-4.49h-15.77v-1.63l5.39-.04v-6.55c0-.49.35-.84.85-.84.41 0 .86.32.86.84v6.49h3.53l.61-7.85c0-.4.4-.76.86-.76.41 0 .85.32.85.84v.06l-.61 7.77h3.53l2.11-11.84c.09-.34.34-.59.67-.67h.02c3.21-.59 5.54-3.27 5.54-6.37-.06-3.66-3.16-6.61-6.95-6.54-.81 0-1.55.1-2.22.31.67.93 1.15 1.9 1.43 2.87.07.17.06.4-.05.6-.12.22-.33.38-.56.42-.24.08-.51.05-.73-.08a.75.75 0 01-.36-.53c-.43-1.35-1.15-2.75-2.26-3.66-5.48-4.46-12.51-.01-12.51-.01V13.51h30.8v60.76h7.88v-2h-5.88v-.01zm-34.8-40.98v40.99h-31.41V13.51h31.41v17.77z'
          fill='#5B5B5B'
        />
        <path
          d='M13.51 27.02c7.46 0 13.51-6.05 13.51-13.51S20.97 0 13.51 0 0 6.05 0 13.51s6.05 13.51 13.51 13.51z'
          fill='#76B82D'
        />
        <path
          d='M11 17.64c.09-.09.14-.13.19-.18l8.07-8.07c.04-.04.07-.08.11-.12.37-.36.81-.36 1.16-.02.34.34.34.79-.03 1.15-1.69 1.7-3.39 3.39-5.08 5.09l-3.74 3.74c-.39.39-.87.4-1.26 0l-3.75-3.75c-.21-.21-.34-.46-.25-.77.09-.32.31-.53.63-.61.32-.08.56.06.78.28l3.06 3.06c.05.05.08.12.13.19'
          fill='#fff'
        />
      </g>
      <defs>
        <clipPath id='chiusura-cucina-new_svg__clip0_2723_23226'>
          <path fill='#fff' d='M0 0h87v74.27H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgChiusuraCucinaNew;
