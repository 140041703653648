import API from './api';

export const getPackages = async (configurationId, substepId, roomId) => {
  let apiGetString;
  if(roomId) {
    apiGetString = `/External/Package/GetPackages?configurationId=${configurationId}&substepId=${substepId}&roomId=${roomId}`
  } else {
    apiGetString = `/External/Package/GetPackages?configurationId=${configurationId}&substepId=${substepId}`
  }
  return await API.get(apiGetString);
};