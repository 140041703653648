import * as React from "react";

function SvgGallery(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#gallery_svg__clip0)" fill="#878787">
        <path d="M15.078 6.643c-.581-.696-1.259-1.49-1.646-1.988-.387-.398-.871-.696-1.452-.696-.678 0-1.355.298-2.033.994-.58.597-1.549 1.69-2.13 2.187-.096.199-.387.298-.677.298-.194 0-.387-.099-.581-.198l-.484-.398c0-.1-.097-.1-.194-.199-.387-.397-.87-.795-1.355-.795-.387 0-.774.199-1.065.596-.484.398-.968.895-1.355 1.293V.778h12.875v5.865h.097zm-13.36 4.175h13.844V.281H1.719v10.537z" />
        <path d="M8.688 3.96c0 .993-.774 1.788-1.742 1.788S5.203 4.953 5.203 3.96c0-.994.775-1.79 1.743-1.79.968.1 1.742.896 1.742 1.79z" />
        <path d="M.75 1.673v10.14H14.4l.483-.498H1.234V1.175l-.484.498z" />
      </g>
      <defs>
        <clipPath id="gallery_svg__clip0">
          <path
            fill="#fff"
            transform="translate(.75 .281)"
            d="M0 0h14.714v11.531H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgGallery;
