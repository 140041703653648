import { lighten, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3px 8px 0',
    height: '30px',
    backgroundColor: theme.palette.color.red,
    color: theme.palette.color.white,
    border: `1px solid ${lighten(theme.palette.color.red, 0.4)}`,
    borderRadius: '20px',
    textTransform: 'uppercase',
    fontWeight: 750,
    lineHeight: 1,
    '& span': {
      whiteSpace: 'nowrap',
    },
    '&.small': {
      fontSize: '11px',
      padding: '3px 5px 0',
      height: '25px',
      minWidth: '35px',
    },
  },
}));

const Plate = ({ title, small }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, small && 'small')}>
      <span>{title}</span>
    </div>
  );
};

export default Plate;
