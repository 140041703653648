import { makeStyles } from '@material-ui/core';

const usePageStyle = makeStyles(() => ({
  root: {
    height: '100%',
    '& .configurator-body': {
      maxWidth: 'calc(100% - 80px)',
    },
    '& .sidebar-container': {
      height: '100%',
    },
    '& > div': {
      height: '100%',
    },
  },
}));

export default usePageStyle;
