/**
 * a number formatter for currency
 */
export const CardCurrencyFormatter = (value, currency = 'EUR', locale = 'it-IT') => {
  value = value ? value : 0;

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: value % 1 === 0 ? 0 : 2,
    maximumFractionDigits: value % 1 === 0 ? 0 : 2,
  }).format(value);
};

const CurrencyFormatter = (value, currency = 'EUR', locale = 'it-IT') => {
  value = value ? value : 0;

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: value % 1 === 0 ? 0 : 2,
    maximumFractionDigits: value % 1 === 0 ? 0 : 2,
  }).format(value);
};

export const ResumeCurrencyFormatter = (value, currency = 'EUR', locale = 'it-IT') => {
  value = value ? value : 0;

  if (value === 0) {
    return '- €';
  }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: value % 1 === 0 ? 0 : 2,
    maximumFractionDigits: value % 1 === 0 ? 0 : 2,
  }).format(value);
};

export default CurrencyFormatter;
