import * as React from "react";

function SvgLetterI(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 9 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.227 16.92v-2.16h2.69v-6.9H.357V5.71h5.25v9.05h2.45v2.16H.227zm3-13.38a1.7 1.7 0 01-.61-.58 1.57 1.57 0 01-.22-.79A1.53 1.53 0 012.887 1a1.65 1.65 0 011.21-.45 1.58 1.58 0 01.82.22c.257.139.477.338.64.58a1.47 1.47 0 010 1.58 1.79 1.79 0 01-.64.58 1.69 1.69 0 01-1.67 0l-.02.03z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgLetterI;
