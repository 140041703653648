import API from './api';

export const getPhases = async (configurationId) => {
  return await API.get(`/External/Phase?configurationId=${configurationId}`);
};

export const getNextSubStep = async (configurationId, currentSubstepId, roomId) => {
  if(roomId) return await API.get(`/External/Phase/GetNextSubStep?configurationId=${configurationId}&currentSubstepId=${currentSubstepId}&roomId=${roomId}`);
  return await API.get(`/External/Phase/GetNextSubStep?configurationId=${configurationId}&currentSubstepId=${currentSubstepId}`);
};
