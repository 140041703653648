import * as React from 'react';

function SvgIlluminazioneLoggia(props) {
  return (
    <svg viewBox='0 0 58 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#illuminazione-loggia_svg__clip0_2758_23947)' fill='#C4C4C4'>
        <path d='M22.36 1.46C21.45 1.77 0 9.04 0 18.6c0 .55.44 1 1 1h20.55v7.25h-6.87c-.56 0-1 .44-1 1s.44 1 1 1h6.87v9.97c0 .55.45 1 1 1 .32 0 .61-.15.79-.39V17.6H2.11C3.45 11.34 16.93 5.43 22.68 3.46c.21.07.43.15.66.24V1.57c-.17-.06-.29-.1-.34-.12-.21-.07-.43-.07-.64 0v.01z' />
        <path d='M13.838 32.03a.997.997 0 00-.97-.75h-6.25l-1.64-7.17a1 1 0 10-1.95.45l1.77 7.73-1.3 6.32c-.11.54.24 1.07.78 1.18.54.11 1.07-.24 1.18-.78l1.18-5.73h5.46l1.49 5.78c.12.45.52.75.97.75.08 0 .17 0 .25-.03.53-.14.86-.68.72-1.22l-1.68-6.53h-.01zM42.802 33.93h-8.06c-.69 0-1.25.57-1.25 1.26 0 .69.56 1.26 1.25 1.26h8.06c.69 0 1.25-.56 1.25-1.26s-.56-1.26-1.25-1.26zM46.281 10.97c-.34-.33-.71-.64-1.09-.91-1.83-1.35-4.06-2.07-6.39-2.07-2.83 0-5.49 1.05-7.49 2.98-2.01 1.93-3.12 4.5-3.12 7.24 0 2.54.97 4.97 2.72 6.84 1.78 2.11 2.61 6.05 2.61 6.08l.17.82h10.3l.02-.99c.07-2.88 2.81-6.06 2.93-6.19 1.59-1.84 2.47-4.17 2.47-6.56 0-1.62-.39-3.18-1.12-4.58-.51-.98-1.18-1.87-2.01-2.66zm-.88 12.46c-.29.33-2.8 3.29-3.33 6.48h-6.74c-.35-1.34-1.26-4.28-2.91-6.23a7.95 7.95 0 01-2.2-5.47c0-4.51 3.85-8.19 8.58-8.19s8.57 3.68 8.57 8.19c0 1.9-.7 3.76-1.97 5.22zM39.93 0h-2.27v4.64h2.27V0zM51.104 3.933L48.125 7.49l1.74 1.458 2.98-3.557-1.74-1.458zM57.171 16.24h-4.64v2.27h4.64v-2.27z' />
        <path d='M38.892 23.52l-2.91-2.91-1.42 1.41 4.33 4.33 4.09-4.1-1.41-1.41-2.68 2.68z' />
      </g>
      <defs>
        <clipPath id='illuminazione-loggia_svg__clip0_2758_23947'>
          <path fill='#fff' d='M0 0h57.17v39.81H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIlluminazioneLoggia;
