import { darken, makeStyles } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import clsx from 'clsx';
import '../../styles/circleLoader.css';

const useStyles = makeStyles((theme) => ({
  iconButtonRoot: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.palette.color.white,
    color: theme.palette.color.black,
    transition: 'all 0.2s ease-in-out',
    padding: '8px 10px',
    border: `1px solid ${theme.palette.primary.main}`,
    '&.no-background': {
      backgroundColor: 'initial',
    },
    '& .text': {
      margin: '0 auto',
      fontSize: '11px',
      fontWeight: 600,
      paddingTop: '2px',
      '&.hidden': {
        visibility: 'hidden',
      },
    },
    '& .icon': {
      color: ({ filled }) => (filled ? theme.palette.color.white : theme.palette.primary.main),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '8px',
    },
    '&:hover': {
      backgroundColor: `${darken(theme.palette.color.white, 0.1)}`,
    },
    '& .loader-1': {
      position: 'absolute',
      top: 3,
    },
  },
}));

const OutlineIconButton = ({ text, icon, onClick, className, noBackground, isLoading }) => {
  const classes = useStyles();

  return (
    <ButtonBase
      className={clsx(classes.iconButtonRoot, className, noBackground && 'no-background')}
      onClick={onClick}
    >
      {icon ? <span className={'icon'}>{icon}</span> : ''}
      <span className={clsx('text', isLoading && 'hidden')}>{text}</span>
      {isLoading && (
        <div className='loader-1 center'>
          <span></span>
        </div>
      )}
    </ButtonBase>
  );
};

export default OutlineIconButton;
