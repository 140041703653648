import * as React from "react";

function SvgSwingDoor(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 72 117"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.059 117s.152-77.695 0-116.238V0l47.944 7.77V108.62l-.611.152C52.68 111.516 23.059 117 23.059 117zM24.433 1.676s0 30.926.152 56.824v56.672s12.215-2.59 45.196-7.77V8.836l-45.348-7.16z"
        fill={props.color}
      />
      <path
        d="M30.848 54.082c-.916 0-1.832 1.676-1.832 3.961s.916 3.96 1.832 3.96 1.832-1.675 1.832-3.96-.916-3.961-1.832-3.961zM17.254 108.316v1.676H0V6.398h17.254v1.676H1.68V108.47h15.574v-.153z"
        fill={props.color}
      />
    </svg>
  );
}

export default SvgSwingDoor;
