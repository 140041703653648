import { useEffect, useState, useRef } from 'react';
import GridDetailsContainer from '../../../components/Card/GridDetailsContainer';
import MaterialDataSheet from '../../../components/MaterialDataSheet';
import ConfiguratorThreeColumnsLayout from '../../../layouts/ConfiguratorThreeColumnsLayout';
import BigBox from './config/BigBox';
import { makeStyles } from '@material-ui/core';
import { resumeAnswerSimple } from '../../../utils/configuratorUtils';
import { getPackages } from '../../../services/packageApi';
import { useStore } from '../../../store/storeUtils';
import { useParams } from 'react-router';
import { handleSave, singleChoiceSelection } from '../../../utils/selectionUtils';
import _ from 'lodash';
import LoadingAnimation from '../../../components/LoadingAnimation';
import {
  materialDataSheetOptionsConfiguration,
  packagesRestructuringClassicConfiguration,
  setMoodAndFinituraConfiguration,
} from '../../../configs/giornoNottePhasesConfigurations';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '15px',
    textAlign: 'left',
    height: '5%',
  },
  subTitle: {
    fontSize: '11px',
    textAlign: 'left',
    height: '5%',
  },
  titleCucina: {
    fontSize: '15px',
    textAlign: 'left',
    height: '8%',
  },
  subTitleCucina: {
    fontSize: '11px',
    textAlign: 'left',
    height: '7%',
  },
  secondaryText: {
    color: theme.palette.color.orange,
  },
}));

const FirstColumn = ({ options, selectedOptions, isCucina }) => {
  const materialDataSheetOptions = materialDataSheetOptionsConfiguration(options, selectedOptions);
  return (
    <BigBox
      defaultImage={
        isCucina
          ? `${process.env.PUBLIC_URL}/assets/images/Cucina.jpg`
          : `${process.env.PUBLIC_URL}/assets/images/Soggiorno.jpg`
      }
      selectedVirtualImage={materialDataSheetOptions.image}
    />
  );
};

const SecondColumn = ({ options, selectedOptions }) => {
  const materialDataSheetOptions = materialDataSheetOptionsConfiguration(options, selectedOptions);
  return (
    <MaterialDataSheet
      firstRowTitle={'PREVIEW'}
      secondRowTitle={'PRODOTTO'}
      previewBoxText={'un FORMATO'}
      material={materialDataSheetOptions}
      noImageLayout={true}
    />
  );
};

const ThirdColumnText = ({ isCucina }) => {
  const classes = useStyles();
  const cucinaPrimartyText = (
    <span>
      Quale <span className={classes.secondaryText}>Formato</span> preferisci per il{' '}
      <span className={classes.secondaryText}>pavimento </span>della cucina?
    </span>
  );
  const livingPrimaryText = (
    <span>
      Quale <span className={classes.secondaryText}>Formato</span> preferisci per il{' '}
      <span className={classes.secondaryText}>pavimento</span> di{' '}
      <span className={classes.secondaryText}>living e camere</span>?
    </span>
  );
  const cucinaSecondaryText = (
    <span className={classes.secondaryText}>
      Alcune collezioni sono disponibili in un unico formato.
    </span>
  );
  const livingSecondaryText = (
    <span className={classes.secondaryText}>
      Alcune collezioni sono disponibili in un unico formato.
    </span>
  );
  return (
    <>
      <div className={isCucina ? classes.titleCucina : classes.title}>
        {isCucina ? cucinaPrimartyText : livingPrimaryText}
      </div>
      <div className={isCucina ? classes.subTitleCucina : classes.subTitle}>
        {isCucina ? cucinaSecondaryText : livingSecondaryText}
      </div>
    </>
  );
};

const ThirdColumn = ({ options, selectedOptions, handleUserSelection, isCucina }) => {
  const containers = [
    {
      title: 'Formato',
      content: options,
    },
  ];
  return (
    <>
      <ThirdColumnText
        isCucina={isCucina}
        style={isCucina ? { height: '20%' } : { height: '15%' }}
      />
      <div style={isCucina ? { height: '80%' } : { height: '85%' }}>
        <GridDetailsContainer
          containers={containers}
          onClick={handleUserSelection}
          selectedOptions={selectedOptions}
          numCols={1}
        />
      </div>
    </>
  );
};

const FormatoGiornoNotte = () => {
  const [packages, setPackages] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [isCucina, setIsCucina] = useState(false);

  const substepIdRef = useRef();
  const oldAnswersRef = useRef();

  const store = useStore();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const configurationId = params.configuration;
  const { answers } = store;
  const substepId = store.currentSubstepId;

  const settingFiniture = () => {
    const stepFinituraId = answers.find((el) => el.substep.value === 'FINITURA' && el.package.value)
      ?.substep?.id;
    const storedFinitureTitle = store.selectedFormatoCameraTitle;
    if (storedFinitureTitle !== '') {
      if (stepFinituraId && substepId && substepId !== stepFinituraId) {
        setIsCucina(true);
      }
    }
  };

  useEffect(() => {
    store.setUserCanGoNext(false);
    prepareOptions();
    // settingFiniture();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prepareOptions = async () => {
    const packages = await getPackages(configurationId, substepId);
    substepIdRef.current = store.currentSubstepId;
    const stepName = packages?.data?.[0]?.stepName;
    setIsCucina(
      stepName?.toLowerCase()?.includes('cucina') || stepName?.toLowerCase()?.includes('cucine'),
    );
    /*
    const { answers } = store;
    setSelectedMoods(answers.find((el) => el.substep.value === 'FINITURA' && el.package.value)?.package?.value);
    setIsCucina(answers.filter((el) => el.substep.value === 'FINITURA' && el.package.value)?.length > 1);
    const selectedFinitura = answers.find((el) => el.substep.value === 'TONALITA')?.package?.value;
    const stepFinituraId = answers.find((el) => el.substep.value === 'TONALITA')?.substep?.id;
    const finiturePackages = await getPackages(configurationId, stepFinituraId);
    // setIsCucina(finiturePackages?.data[0]?.packages?.length > 1);
    setSelectedFinituraImage(
      finiturePackages?.data[0]?.packages
        ?.find((onePackage) => onePackage?.code === selectedFinitura)
        ?.images?.find((image) => image?.imageType?.value === 'Cover')?.label,
    );
    setselectedFinituraProductDescription(
      finiturePackages?.data[0]?.packages?.find(
        (onePackage) => onePackage?.code === selectedFinitura,
      )?.products[0]?.description,
    );
    */
    const packagesRestruct = packagesRestructuringClassicConfiguration(
      packages,
      false,
      false,
      false,
      isCucina ? 'FormatoCucina' : 'FormatoCamere',
    );
    setPackages(packagesRestruct);
    resumeAnswerSimple(store, oldAnswersRef, substepIdRef, setSelectedPackages, setIsLoading);
  };

  useEffect(() => {
    const save = async () => {
      await handleSave(null, configurationId, substepIdRef.current, store, selectedPackages?.[0]);
      /*
      console.log('🚀 ~ save ~ selectedPackages?.[0]:', selectedPackages?.[0], packages)
      const selectedFiniture = answers.filter((el) => el.substep.value === 'FINITURA' && el.package.value);
      const selectedFinituraCamere = selectedFiniture?.[0]?.package?.value;
	    const selectedFinituraCucina = selectedFiniture?.[1]?.package?.value;
      const selectedFinituraCamereId = selectedFiniture?.[0]?.substep?.id;
      const selectedFinituraCucinaId = selectedFiniture?.[1]?.substep?.id;
      */
      const previewSelected = packages
        .find((pac) => pac.id === selectedPackages?.[0])
        ?.preview?.replace(/\s/g, '%20');
      const descriptionSelected = packages.find(
        (pac) => pac.id === selectedPackages?.[0],
      )?.materiotecaName;
      if (isCucina) {
        store.setSelectedFormatoCucinaImage(previewSelected);
        store.setSelectedFormatoCucinaTitle(descriptionSelected);
      } else {
        store.setSelectedFormatoCameraImage(previewSelected);
        store.setSelectedFormatoCameraTitle(descriptionSelected);
      }
      /*
      if(selectedFinituraCamere){
        const finiturePackages = await getPackages(configurationId, selectedFinituraCamereId);
        setMoodAndFinituraConfiguration(
          finiturePackages,
          selectedFinituraCamere,
          null,
          store,
        )
      }
      if(selectedFinituraCucina){
        const finiturePackages = await getPackages(configurationId, selectedFinituraCucinaId);
        setMoodAndFinituraConfiguration(
          finiturePackages,
          null,
          selectedFinituraCucina,
          store,
        )
      }
      */
    };
    if (!_.isEqual(oldAnswersRef.current, selectedPackages)) {
      store.setSubstepSave(save);
    } else {
      store.setSubstepSave(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPackages]);

  const handleUserSelection = (selection) => {
    singleChoiceSelection([selection], [selectedPackages], setSelectedPackages, store);
  };

  return isLoading ? (
    <LoadingAnimation />
  ) : (
    <ConfiguratorThreeColumnsLayout
      firstRowTitle='Configura'
      secondRowTitle={
        isCucina ? 'IL PAVIMENTO DELLA CUCINA' : 'I PAVIMENTI DI LIVING E CAMERE DA LETTO'
      }
      firstColumn={
        <FirstColumn options={packages} selectedOptions={selectedPackages} isCucina={isCucina} />
      }
      secondColumn={<SecondColumn options={packages} selectedOptions={selectedPackages} />}
      thirdColumn={
        <ThirdColumn
          options={packages}
          selectedOptions={selectedPackages}
          handleUserSelection={handleUserSelection}
          isCucina={isCucina}
        />
      }
    />
  );
};

export default FormatoGiornoNotte;
