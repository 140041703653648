import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import SvgTriangle from '../Icons/Triangle';

const useStyles = makeStyles((theme) => ({
  phase: {
    border: '2px dashed #000',
    position: 'relative',
    backgroundColor: '#FFF',
    padding: '74px 16px 0',
    height: '100%',
    opacity: 0.3,
    '& .phase-colored-triangle:first-child path': {
      stroke: '#000',
      fill: '#FFF',
      strokeDasharray: '2.3',
    },

    '& .circle': {
      position: 'absolute',
      height: '26px',
      width: '26px',
      border: '3px solid #000',
      borderRadius: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      left: 0,
      right: 0,
      bottom: '-14px',
    },

    '& .info-button': {
      backgroundColor: '#000',
      color: '#FFF',
      width: '15px',
      height: '15px',
      position: 'absolute',
      right: -7,
      bottom: -7,
      borderRadius: '100%',
      textAlign: 'center',
      fontSize: '11px',
      paddingTop: '3px',
    },

    '&.disabled.active': {
      opacity: 0.3,
    },
    '&.active': {
      opacity: 1,
      border: '3px solid #F08219',
      '& .phase-colored-triangle path': {
        fill: '#F08219',
        stroke: 'none',
      },
      '& .header-decoration': {
        borderBottom: '2px solid #F08219',
      },
      '& .circle': {
        borderColor: '#F08219',
      },
      '& .info-button': {
        backgroundColor: '#000',
      },
    },
    '&.completed': {
      opacity: 1,

      border: '3px solid #76B82D',
      '& .phase-colored-triangle path': {
        fill: '#76B82D',
        stroke: 'none',
      },
      '& .header-decoration': {
        borderBottom: '2px solid #76B82D',
      },

      '& .circle': {
        borderColor: '#76B82D',
        backgroundColor: '#76B82D',
      },

      '& .info-button': {
        backgroundColor: '#000',
      },
    },
    '&.coming-soon': {
      backgroundColor: 'rgba(196, 196, 196, 0.2);',
      border: 0,
      opacity: 1,
      '& .phase-triangle': {
        display: 'none',
      },
      '& .header-decoration': {
        visibility: 'hidden',
      },
      '& .title': {
        // visibility: 'hidden',
        color: '#CDC7C1',
      },
      '& .info-button, .list-title, .list': {
        display: 'none',
      },
    },

    '& .phase-triangle': {
      position: 'absolute',
      top: -3,
      left: -3,
      zIndex: 2,
      '& svg:nth-child(1)': {
        position: 'absolute',
        top: 1,
        left: 0,
        height: 70,
      },
      '& svg:nth-child(2)': {
        position: 'absolute',
        left: 0,
        top: 2,
      },
      '& svg:nth-child(3)': {
        position: 'absolute',
        left: 0,
        top: 17,
      },
    },
    '& .header-coming-soon': {
      position: 'absolute',
      top: '29px',
      left: 0,
      width: '100%',
      textAlign: 'center',
      fontSize: '12px',
      color: 'rgba(240, 130, 25,0.5)',
    },
  },

  headerDecoration: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '34px',
    borderBottom: '2px dashed #000',
  },

  circleBackground: {
    position: 'absolute',
    height: '50px',
    width: '50px',
    backgroundColor: '#FFF',
    borderRadius: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    bottom: '-25px',
  },
  title: {
    fontSize: '15px',
    lineHeight: '18px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    '& .bold': {
      fontWeight: 600,
      marginBottom: '40px',
      textTransform: 'uppercase',
    },
    '& .hidden': {
      visibility: 'hidden',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 12,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },
  icon: {
    textAlign: 'center',
    height: '90px',
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    marginBottom: '25px',
    '& svg': {
      width: '102px',
      height: 'auto',
      [theme.breakpoints.down('md')]: {
        width: '77px',
      },
    },
  },
  listTitle: {
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '16px',
    marginBottom: '8px',
  },
  list: {
    fontSize: 13,
    lineHeight: '100%',
    color: '#C4C4C4',
    fontWeight: 'normal',
    paddingBottom: '25px',
    '& ul': {
      padding: '0 13px',
      margin: 0,
    },
    '& li': {
      marginBottom: '5px',

      '&:last-child': {
        paddingBottom: 0,
      },
    },
  },
  /*
  footer: {
    fontSize: '11px',
    lineHeight: '13px',
    position: 'absolute',
    bottom: 0,
    paddingBottom: 5,
    maxWidth: '90%',
    '& .bold': {
      fontWeight: 600,
    },
    '& svg': {
      height: '27px',
      width: 'auto',
      fill: '#000',
      color: '#000',
      [theme.breakpoints.down('md')]: {
        height: '20px',
      },
    },
    '& g': {
      opacity: 1,
      fill: '#000',
    },
    '&.coming-soon-footer': {
      textAlign: 'center',
      color: '#CDC7C1',
      fontSize: '12px',
      bottom: '20px',
      lineHeight: '14px',
      '& .bold': {
        fontWeight: 600,
      },
    },
  },
  
  popover: {
    backgroundColor: 'rgba(0,0,0,0) !important',
    borderTopLeftRadius: '90px',
  },
  */
}));

/*
const ComingSoonFooter = ({ text }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={clsx(classes.footer, 'coming-soon-footer')}
      alignItems='center'
      spacing={1}
    >
      <Grid item xs>
        <div>
          Prossimamente
          <br /> sceglierai il tuo stile:
          <br />
          <span className='bold'>{text}</span>
        </div>
      </Grid>
    </Grid>
  );
};
*/

const PhaseCard = ({
  completed,
  isActive,
  title,
  boldTitle,
  icon,
  descriptions,
  withArchitect,
  firstPart,
  isComingSoon,
  showInfo,
  handleCloseInfo,
  handleOpenInfo,
  infoData,
}) => {
  const classes = useStyles();
  /*
  const infoRef = useRef();

  const Footer = ({ icon, firstRow, secondRow }) => {
    return (
      <Grid container className={classes.footer} alignItems='center' spacing={1}>
        <Grid item xs='auto'>
          {icon}
        </Grid>
        <Grid item xs>
          <div>{firstRow}</div>
          <div className='bold'>{secondRow}</div>
        </Grid>
      </Grid>
    );
  };
  */

  return (
    <Grid
      container
      direction='column'
      className={clsx(
        classes.phase,
        completed && 'completed',
        (isActive || firstPart) && !completed && 'active',
        !isActive && !completed && 'disabled',
        isComingSoon && 'coming-soon',
      )}
    >
      <div className={clsx('phase-triangle')}>
        <SvgTriangle
          className={'phase-colored-triangle'}
          color='#000'
          height={'72px'}
          width='33px'
        />
        <SvgTriangle color='#FFF' height={'68px'} width='29px' />
        <SvgTriangle
          className={'phase-colored-triangle'}
          color='#000'
          height={'36px'}
          width='16px'
        />
      </div>
      <div className={clsx(classes.headerDecoration, 'header-decoration')}>
        <div className={classes.circleBackground} />
        <div className={'circle'} />
      </div>
      {isComingSoon && <div className={clsx('header-coming-soon')}>Prossimamente</div>}
      <Grid item xs='auto' className={clsx(classes.title, 'title')}>
        {title}
        <span className={'bold'}>{boldTitle}</span>
        {!title && <span className={'hidden'}>_____</span>}
      </Grid>
      <Grid item xs='auto' className={clsx(classes.icon, 'icon')}>
        {icon}
      </Grid>
      <Grid item xs='auto' className={clsx(classes.listTitle, 'list-title')}>
        POTRAI PERSONALIZZARE
      </Grid>
      <Grid item xs className={clsx(classes.list, 'list')}>
        <ul>
          {descriptions?.map((el, i) => (
            <li key={i}>{el}</li>
          ))}
        </ul>
      </Grid>
      {/*
      {isComingSoon ? null : (
        <Footer
          icon={withArchitect ? <Talk /> : <Computer />}
          firstRow={withArchitect ? 'IL PERSONAL ARCHITECT' : 'OCCORRE'}
          secondRow={withArchitect ? 'TI ASPETTERÀ IN STORE' : 'SOLO IL TUO PC'}
        />
      )}

      <ButtonBase className={'info-button'} ref={infoRef} onClick={handleOpenInfo}>
        ?
      </ButtonBase>
      <Popover
        id={boldTitle}
        open={showInfo}
        anchorEl={infoRef.current}
        onClose={handleCloseInfo}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{ classes: { root: classes.popover } }}
      >
        <InfoPopup
          body={<PhaseInfoContent {...infoData} />}
          isPopover
          handleClose={handleCloseInfo}
        />
      </Popover>
      */}
    </Grid>
  );
};

export default PhaseCard;
