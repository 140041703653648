import * as React from 'react';

function SvgIngresso(props) {
  return (
    <svg viewBox='0 0 31 44' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#ingresso_svg__clip0_2448_22979)' fill='#C4C4C4'>
        <path d='M29.012 0h-28a1 1 0 00-1 1v41.78a1 1 0 001 1 .92.92 0 00.52-.17l17.79-3.18a.92.92 0 00.76-.9V4.26a.91.91 0 00-.76-.9L11.762 2h16.26v40.78a1 1 0 002 0V1a1 1 0 00-1.01-1zm-10.77 38.76l-16.24 2.92V2.11l16.24 2.91v33.74z' />
        <path d='M15 23.42a1.53 1.53 0 001.414-2.115A1.52 1.52 0 0015 20.37a1.52 1.52 0 00-1.52 1.52A1.53 1.53 0 0015 23.42z' />
      </g>
      <defs>
        <clipPath id='ingresso_svg__clip0_2448_22979'>
          <path fill='#fff' d='M0 0h30.01v43.78H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIngresso;
