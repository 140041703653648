import * as React from 'react';

function SvgUsbLoggia(props) {
  return (
    <svg viewBox='0 0 42 39' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.37.051c.043-.014.086-.025.13-.033v2.096C17.643 4.153 3.436 9.996 2.11 16.19H23.5v21.632a1 1 0 01-1.92-.392v-10h-6.87a1 1 0 010-2h6.84v-7.24H1a1 1 0 01-1-1C0 7.67 22.316.395 23.361.054l.01-.003zM4.28 38.381a1.001 1.001 0 001.18-.78l1.18-5.73h5.46l1.48 5.78a1 1 0 001 .75c.083.01.167.01.25 0a1 1 0 00.72-1.22l-1.68-6.52a1 1 0 00-1-.76H6.62l-1.64-7.16a1 1 0 00-1.824-.316 1 1 0 00-.127.756l1.77 7.74-1.3 6.31a1 1 0 00.78 1.15zm34.777-26.283H42v3.164h-1.006v2.45c0 .255-.045.507-.136.742-.091.235-.224.448-.392.627l-4.117 4.458h-.038v5.83c.649.127 1.23.509 1.63 1.072.4.564.59 1.268.53 1.974a2.922 2.922 0 01-.847 1.841 2.539 2.539 0 01-1.78.744c-.66 0-1.295-.265-1.782-.744a2.922 2.922 0 01-.847-1.841c-.059-.706.13-1.41.53-1.974.4-.563.982-.945 1.63-1.071v-2.375l-3.883-4.18a1.932 1.932 0 01-.392-.628 2.046 2.046 0 01-.136-.741v-2.757a1.459 1.459 0 01-.763-.67 1.637 1.637 0 01-.181-1.04 1.57 1.57 0 01.487-.92c.258-.236.586-.365.925-.365.338 0 .666.13.924.364.259.235.431.561.487.92.056.36-.008.728-.18 1.041-.173.314-.444.55-.764.67v2.752c0 .122.022.243.066.356a.942.942 0 00.187.303l3.223 3.471V9.144H34.16l.842-1.574.842-1.57.847 1.57.842 1.574H36.33V22.13l3.495-3.758a.972.972 0 00.233-.66v-2.45h-1v-3.164z'
        fill='#C4C4C4'
      />
    </svg>
  );
}

export default SvgUsbLoggia;
