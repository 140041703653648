import { useMediaQuery } from '@material-ui/core';
import { useMobileOrientation, deviceType } from 'react-device-detect';
import CustomDialog from './CustomDialog';

const SmallScreenMessage = () => {
  const { isLandscape } = useMobileOrientation();
  const smallView = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const open = deviceType === 'mobile' || (deviceType === 'tablet' && !isLandscape) || smallView;

  return <CustomDialog open={open} exit={false} screenType={deviceType} />;
};

export default SmallScreenMessage;
