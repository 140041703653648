import { lighten, makeStyles } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '50px',
    height: '50px',
    backgroundColor: theme.palette.color.brownGrey3,
    borderRadius: '50%',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: '6px',
    border: `7px solid ${theme.palette.color.brownGrey2}`,
    borderRadius: '50%',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.7)',
    transform: 'scale(0.75)',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      transform: 'scale(1)',
    },
    '& img': {
      width: '100%',
      height: '100%',
    },
  },
  orange: {
    backgroundColor: theme.palette.primary.main,
    // borderBottom: `3px solid ${darken(theme.palette.primary.main, 0.3)}`,
    // borderBottomLeftRadius: '4px',
    // borderBottomRightRadius: '4px',
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.3),
      // borderBottom: `3px solid ${darken(theme.palette.primary.main, 0.2)}`,
    },
    '&:active': {
      backgroundColor: lighten(theme.palette.primary.main, 0.4),
      // borderBottom: `3px solid ${darken(theme.palette.primary.main, 0.1)}`,
    },
  },
  green: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: lighten(theme.palette.secondary.main, 0.3),
    },
    '&:active': {
      backgroundColor: lighten(theme.palette.secondary.main, 0.4),
    },
    '&:before': {
      content: '',
      border: `6px solid ${theme.palette.color.brownGrey1}`,
      borderRadius: '50%',
      '&:hover, &:active': {
        display: 'none',
      },
    },
  },
  disable: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
}));

const IconRound3DButton = ({ color = 'green', icon, onClick, disabled, className }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, disabled && classes.disable, className)}>
      <ButtonBase className={clsx(classes.button, classes[color])} onClick={onClick} disableRipple>
        {icon}
      </ButtonBase>
    </div>
  );
};

export default IconRound3DButton;
