import { Grid, makeStyles, useTheme, Button } from '@material-ui/core';
import { useEffect, useState, useRef } from 'react';
import SelectionCard from '../../../components/Card/SelectionCard';
import { OutlinedHeart } from '../../../components/Icons';
import IconTitle from '../../../components/IconTitle';
import ImageZoom from '../../../components/ImageZoom';
import ConfiguratorColumnTitle from '../../../components/Labels/ConfiguratorColumnTitle';
import ConfiguratorSelectionLayout from '../../../components/Layout/ConfiguratorSelectionLayout';
import LoadingAnimation from '../../../components/LoadingAnimation';
import { resumeAnswerSimple } from '../../../utils/configuratorUtils';
import { getPackages } from '../../../services/packageApi';
import { useStore } from '../../../store/storeUtils';
import { useParams } from 'react-router';
import { handleSave, singleChoiceSelection } from '../../../utils/selectionUtils';
import _ from 'lodash';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    padding: '22px',
    backgroundColor: '#fff',
  },
  content: {
    width: '100%',
    height: '100%',
  },
  list: { marginTop: '50px', padding: '0 22px 22px' },
  message: { marginBottom: '50px' },
}));

const FixedHeader = () => {
  return (
    <>
      <ConfiguratorColumnTitle
        firstRow={'SELEZIONA'}
        secondRow={'I PAVIMENTI DI LIVING E CAMERE DA LETTO'}
      />
    </>
  );
};

const ScrollableContent = ({
  activeStep,
  showGallery, 
  setShowGallery,
  onClick,
  options,
  selectedOptions
 }) => {
  const classes = useStyles();
  
  if(options) options.packages = options?.packages?.slice(activeStep*4, (activeStep*4 + 4));
  const gallery = options?.packages?.map((el) => {
    return {
      name: (
        <span>
          Mood <b>{el.name?.split('Mood')[1]}</b>
        </span>
      ),
      images: el.images?.filter((elg) => elg?.imageType?.key === 'G').map((elt) => elt.label),
    };
  });
  const showGalleryOnScreen = showGallery !== -1 ? gallery[showGallery]?.images?.length > 0 : false;

  return (
    <>
      <Grid container direction='column' className={classes.content} wrap='nowrap'>
        <Grid item xs='auto' className={classes.message}>
          <IconTitle
            icon={<OutlinedHeart color='currentColor' />}
            title='Seleziona il mood che preferisci. Dal mood selezionato potrai scegliere i dettagli dei pavimenti nei diversi ambienti.'
          />
        </Grid>
        <Grid item xs>
          <Grid container direction='row' spacing={2} wrap='nowrap'>
            {options?.packages?.map((el, index) =>
              (<Grid item xs={3} key={index}>
                <SelectionCard
                  {...el}
                  onGalleryButtonClick={() => setShowGallery(index)}
                  noZoomImages={true}
                  noSlider={true}
                  onClick={() => onClick(el.id)}
                  isSelected={_.difference([el.id], selectedOptions).length === 0}
                  moodOptions={true}
                />
              </Grid>))}
          </Grid>
        </Grid>
      </Grid>
      {showGallery > -1 && showGalleryOnScreen && (
        <ImageZoom
          gallery={gallery}
          galleryToShow={showGallery}
          handleClose={() => setShowGallery(-1)}
        />
      )}
    </>
  );
};

const MoodGiornoNotte = () => {
  const [packages, setPackages] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [maxSteps, setMaxSteps] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const substepIdRef = useRef();
  const oldAnswersRef = useRef();
  const theme = useTheme();

  const store = useStore();
  const params = useParams();
  const classes = useStyles();

  const [showGallery, setShowGallery] = useState(-1);
  const [isLoading, setIsLoading] = useState(true);
  const configurationId = params.configuration;

  useEffect(() => {
    store.setUserCanGoNext(false);
    prepareOptions();
  }, []);

  const prepareOptions = async () => {
    const substepId = store.currentSubstepId;
    const packages = await getPackages(configurationId, substepId);
    substepIdRef.current = store.currentSubstepId;
    setMaxSteps(Math.round(packages?.data[0]?.packages?.length/4));
    setPackages(packages?.data[0]);
    resumeAnswerSimple(store, oldAnswersRef, substepIdRef, setSelectedPackages, setIsLoading);
  }
    

  useEffect(() => {
    const save = async () => {
      await handleSave(null, configurationId, substepIdRef.current, store, selectedPackages?.[0]);
    };

    if (!_.isEqual(oldAnswersRef.current, selectedPackages)) {
      store.setSubstepSave(save);
    } else {
      store.setSubstepSave(null);
    }
  }, [selectedPackages]);

  const handleUserSelection = (selection) => {
    singleChoiceSelection([selection], [selectedPackages], setSelectedPackages, store);
  };
    

  return (
    <div className={classes.root}>
      <ConfiguratorSelectionLayout
        fixedHeader={<FixedHeader />}
        scrollableContent={
          isLoading ? (
            <LoadingAnimation />
          ) : (
            <ScrollableContent 
              showGallery={showGallery} 
              setShowGallery={setShowGallery}
              onClick={handleUserSelection}
              options={packages}
              selectedOptions={selectedPackages}
              activeStep={activeStep}
            />
          )
        }
      />
      { packages?.packages?.length > 4 &&
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          {activeStep !== 0 && 
            <Button size="small" style={{marginTop: '-15px'}}  onClick={handleBack} disabled={activeStep === 0}>
              {theme.direction === 'rtl' ? <KeyboardArrowRight style={{border:'1px solid black', padding: '5px', borderRadius:'15px'}} /> : <KeyboardArrowLeft style={{border:'1px solid black', padding: '5px', borderRadius:'15px'}} />}
            </Button>
          }
          {activeStep !== maxSteps - 1 && 
            <Button size="small" style={{marginTop: '-15px'}} onClick={handleNext} disabled={activeStep === maxSteps - 1}>
              {theme.direction === 'rtl' ? <KeyboardArrowLeft style={{border:'1px solid black', padding: '5px', borderRadius:'15px'}}/> : <KeyboardArrowRight style={{border:'1px solid black', padding: '5px', borderRadius:'15px'}} />}
            </Button>
          }
          <span style={{marginTop: '-8px'}}>{packages?.packages?.length} Mood</span>
        </div>
      }
    </div>
  );
};

export default MoodGiornoNotte;
