import * as React from 'react';

function SvgPreseStudio(props) {
  return (
    <svg viewBox='0 0 71 45' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#prese-studio_svg__clip0_2758_23713)' fill='#C4C4C4'>
        <path d='M2.02 3.23c0-.78.63-1.41 1.4-1.41h36.5c.77 0 1.4.63 1.4 1.41v5.64h2.02V3.33c0-1.79-1.54-3.33-3.4-3.33H3.4C1.54 0 .06 1.54 0 3.39v25.4c0 1.86 1.54 3.33 3.4 3.39h17.17v2.5h-4.62v1.87h11.51v-1.87h-4.73v-2.5h5.25V30.4H3.42c-.77 0-1.4-.63-1.4-1.41v-1.63h25.96v-2H2.02V3.23zM48.562 24.24h-2.46v6.18h2.46v-6.18zM41.16 24.24H38.7v6.16h2.46v-6.16z' />
        <path d='M55.96 17.74c0-.72-.29-1.41-.8-1.93-.51-.51-1.21-.8-1.93-.8H34.04c-.73 0-1.42.29-1.94.8-.51.51-.8 1.21-.8 1.93v19.2c0 .72.29 1.42.8 1.93.52.51 1.21.8 1.94.8h19.19c.72 0 1.42-.29 1.93-.8.51-.52.8-1.21.8-1.93v-19.2zm-4.13 15.08a9.777 9.777 0 01-4.43 3.63 9.87 9.87 0 01-10.75-2.14 9.479 9.479 0 01-1.61-2.13c-.33-.56-.59-1.16-.78-1.78a9.278 9.278 0 01-.5-3.04c0-.64.07-1.34.2-2 .13-.62.31-1.22.55-1.8.75-1.8 2.02-3.34 3.64-4.43.97-.65 2.04-1.11 3.17-1.38.66-.16 1.33-.26 2.02-.27.1-.01.19-.01.29-.01 1.29 0 2.58.26 3.78.75 1.19.5 2.28 1.22 3.2 2.14.91.92 1.64 2 2.13 3.2.5 1.2.76 2.48.75 3.78 0 1.95-.57 3.86-1.66 5.48zM67.57 26.03h1.11c.14 0 .25-.11.25-.25v-4.74c0-.11 0-.22-.05-.33a.75.75 0 00-.18-.28.75.75 0 00-.47-.22.169.169 0 00-.1-.03c-.11-.01-.22 0-.33.05-.1.04-.2.1-.28.18a.75.75 0 00-.18.28c-.04.1-.05.22-.05.3v4.79c0 .14.11.25.25.25h.03zM61.292 26h1.05c.14 0 .25-.11.25-.25v-4.73a.734.734 0 00-.22-.61.875.875 0 00-.28-.17c-.1-.04-.2-.05-.31-.05-.1.01-.18.02-.27.06-.1.04-.19.1-.26.18a.77.77 0 00-.17.27c-.03.1-.05.21-.04.3v4.75c0 .14.11.25.25.25zM70.991 28.05a.834.834 0 00-.17-.63.86.86 0 00-.6-.33h-10.58c-.12.01-.23.04-.33.1-.11.05-.2.13-.27.21-.07.09-.12.2-.16.31-.03.11-.04.23-.03.31v6.01c-.01.12 0 .23.03.34.04.12.09.22.16.31.08.09.17.16.27.22.11.05.22.08.35.09h10.59c.11-.02.22-.05.32-.11a.814.814 0 00.4-.52c.03-.11.04-.22.03-.3v-6.01h-.01zM66.53 36.17h-3.17c-.14 0-.25.11-.25.25v1.06c0 .14.11.25.25.25h.81v3.3c-.03.63-.29 1.23-.74 1.68-.45.45-1.04.71-1.65.73h-5.82c-.14 0-.25.11-.25.25v1.06c0 .14.11.25.25.25h5.82c2.45 0 3.95-2 3.95-3.43v-3.84h.8c.14 0 .25-.11.25-.25v-1.06c0-.14-.11-.25-.25-.25z' />
      </g>
      <defs>
        <clipPath id='prese-studio_svg__clip0_2758_23713'>
          <path fill='#fff' d='M0 0h71v45H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgPreseStudio;
