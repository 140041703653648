import * as React from "react";

function SvgTecnologiaIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 103 79"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M51.1 78.9c28.166 0 51-2.686 51-6 0-3.313-22.834-6-51-6-28.167 0-51 2.687-51 6 0 3.314 22.833 6 51 6z"
        fill="#EEE"
      />
      <path
        d="M38.5 40.9c.3 0 .5.1.7.3 1.5 1.3 3.2 2.3 5.1 3 .3.1.6.4.7.8l1.1 5.1c.1.5.5.9 1 .9h7.4c.5 0 .9-.4 1-.9l1.1-5.1c.1-.4.3-.7.7-.8 1.9-.7 3.6-1.7 5.1-3 .3-.2.7-.3 1-.2l4.9 1.6c.5.2 1 0 1.3-.5l3.7-6.5c.2-.5.2-1-.2-1.4l-3.7-3.5c-.3-.2-.4-.6-.3-1 .2-1 .2-2 .2-3s-.1-2-.2-3c-.1-.4.1-.7.3-1l3.8-3.5c.4-.4.5-.9.2-1.4l-3.7-6.5c-.3-.4-.8-.7-1.3-.5l-4.9 1.6c-.4.1-.7 0-1-.2-1.5-1.3-3.2-2.3-5.1-3-.3-.1-.6-.4-.7-.8l-1.1-5.3c-.1-.5-.5-.9-1-.9h-7.5c-.5 0-.9.4-1 .9L45 8.2c-.1.4-.3.7-.7.8-1.9.7-3.6 1.7-5.1 3-.3.2-.7.3-1 .2l-4.9-1.5c-.5-.2-1 0-1.3.5l-3.7 6.5c-.2.5-.2 1 .2 1.4l3.7 3.5c.3.3.4.6.3 1-.2 1-.2 2-.2 3s.1 2 .2 3c.1.4-.1.7-.3 1l-3.7 3.5c-.3.4-.4 1-.2 1.4L32 42c.3.5.8.7 1.3.5l4.9-1.6h.3zm16 12.2h-7.4c-1.5 0-2.8-1.1-3.2-2.6l-1-4.5c-1.7-.7-3.3-1.7-4.7-2.8l-4.3 1.4c-1.4.5-3-.2-3.8-1.5l-3.7-6.5c-.7-1.3-.5-3 .6-4.1l3.3-3.1c-.2-1.9-.2-3.7 0-5.6l-3.2-3.1c-1.1-1.1-1.4-2.7-.6-4.1l3.6-6.5c.7-1.3 2.3-2 3.8-1.5l4.3 1.4c1.4-1.1 3-2.1 4.7-2.8l1-4.5c.4-1.6 1.7-2.7 3.2-2.7h7.4c1.5 0 2.8 1.1 3.2 2.6l1 4.5c1.7.7 3.3 1.7 4.7 2.8l4.3-1.4c1.5-.4 3.1.2 3.8 1.6l3.7 6.5c.7 1.3.5 3-.6 4.1l-3.3 3.1c.2 1.9.2 3.7 0 5.6l3.3 3.1c1.1 1.1 1.4 2.8.6 4.1l-3.7 6.5c-.8 1.3-2.3 2-3.8 1.5l-4.3-1.4c-1.4 1.1-3 2.1-4.7 2.8l-1 4.5c-.3 1.6-1.7 2.6-3.2 2.6z"
        fill="#F08219"
      />
      <path
        d="M50.801 15.5h-.1c-4.3.1-7.6 3.7-7.5 7.9.1 2.3 1.2 4.5 3.1 5.9 1.5 1.2 2.4 3 2.3 5v2.9c0 .2 0 .3.1.4h4.3v-3.3c0-1.9.9-3.8 2.4-5 3.4-2.6 4-7.5 1.4-10.9-.2-.3-.4-.5-.6-.7-1.4-1.4-3.4-2.2-5.4-2.2zm2.2 24.4h-4.3c-1 0-2.2-.8-2.2-2.6v-2.9c0-1.2-.5-2.4-1.5-3.2-4.4-3.3-5.3-9.5-2-13.9 1.8-2.4 4.6-3.9 7.6-4 2.6 0 5.2 1 7 2.9 3.9 3.9 3.8 10.3-.1 14.1-.3.3-.6.6-.9.8-1 .8-1.5 2-1.5 3.2v3.3c.1 1.3-.9 2.3-2.1 2.3z"
        fill="#F08219"
      />
    </svg>
  );
}

export default SvgTecnologiaIcon;
