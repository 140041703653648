import { Grid, makeStyles } from '@material-ui/core';
import { useState } from 'react';
import IconRound3DButton from './Buttons/IconRound3DButton';
import { MagnifyingGlass } from './Icons';
import ImageZoom from './ImageZoom';

const useStyles = makeStyles((theme) => ({
  watchPlanimetry: {
    height: '100%',
    color: '#676766',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
    },
  },
  action: {
    borderBottom: '1px solid #F08219',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    fontFamily: 'miller',
    fontStyle: 'italic',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '18px',
    paddingBottom: '3px',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'unset',
      borderBottom: 0,
    },
    '& svg': {
      borderRight: '1px solid #F08219',
      paddingRight: '6px',
      marginRight: '6px',
      boxSizing: 'content-box',
    },
    '& .text': {
      [theme.breakpoints.only('md')]: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 15,
      },
    },
  },
  planimetryContainer: {
    position: 'relative',
    padding: '10px',
    [theme.breakpoints.only('md')]: {
      minHeight: 77,
    },
    cursor: 'zoom-in',
  },
  planimetry: {
    height: '100%',
    width: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  left: {
    [theme.breakpoints.down('md')]: {
      paddingBottom: '5px',
      borderBottom: '1px solid #F08219',
      '& svg': {
        height: '30px',
        width: '30px',
        borderRight: '0',
      },
      '& > div:first-child': {
        borderRight: '1px solid #F08219',
        display: 'flex',
        alignItems: 'center',
      },
      '& > div:nth-child(2)': {
        paddingLeft: '15px',
      },
    },
  },
  zoomIconContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
}));

const WatchPlanimetry = ({ image }) => {
  const classes = useStyles();
  const [imageSrc, setImageSrc] = useState('');
  const planimetryUrl = encodeURI(image);

  return (
    <>
      <Grid container direction='column' className={classes.watchPlanimetry}>
        <Grid item xs className={classes.planimetryContainer}>
          <div
            className={classes.planimetry}
            style={{ backgroundImage: `url(${planimetryUrl}` }}
            onClick={() => setImageSrc(image)}
          ></div>
          <div className={classes.zoomIconContainer}>
            <IconRound3DButton
              onClick={() => setImageSrc(image)}
              className={classes.zoomIcon}
              icon={<MagnifyingGlass height='100%' width='100%' color='#000' />}
            />
          </div>
        </Grid>
      </Grid>
      {imageSrc && (
        <ImageZoom
          images={[imageSrc]}
          allowDownload
          isPlanimetry
          handleClose={() => setImageSrc('')}
        />
      )}
    </>
  );
};

export default WatchPlanimetry;
