import { makeStyles, Grid } from '@material-ui/core';
import OptionCard, { StandardOptionCardBody } from '../../components/Card/OptionCard';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
    root: {
      height: '79%',
      maxHeight: '80%',
      padding: '50px 25px',
      backgroundColor:'#F0EDEB',
    },
  }));

const DepOptions = ({options, selectedOptions, onClick}) => {
  const firstOption = options?.[0];

  return (
    <Grid container spacing={2} justifyContent='center'>
      <Grid item xs={8} lg={8}>
        <OptionCard
          {...firstOption}
          onClick={() => onClick(firstOption?.id)}
          needChanging
          singleChanging
          isSelected={_.difference([firstOption?.id], selectedOptions).length === 0}
          textOnButton={'conferma questa scelta'}
          infoPriceMessage='Il prezzo comprende la sostituzione della porta a battente con la porta scorrevole, inclusa la fornitura e la posa in opera del cassonetto interno muro.'
        >
          <StandardOptionCardBody
            {...firstOption}
            // message={'FILOPAVIMENTO'}
            icon={'La tua doccia è prevista senza box.'}
            hideName={true}
          />
        </OptionCard>
      </Grid>
    </Grid>
  )
}

const BoxDocciaCardContainer = ({options, selectedOptions, handleUserSelection}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DepOptions options={options} selectedOptions={selectedOptions} onClick={handleUserSelection} />
    </div>
  )
};

export default BoxDocciaCardContainer;