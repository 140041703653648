import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingTop: '6px',
    color: theme.palette.color.grey2,
    borderBottom: `2px solid ${theme.palette.color.white}`,
    '&.white-background-mode': {
      borderBottom: `2px solid #F7F1EB`,
    },
    '& .bordered-icon': {
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: '100%',
      height: '50px',
      width: '50px',
      padding: '8px',
      '& svg': {
        height: '100%',
        width: '100%',
      },
      '& svg g': {
        fill: '#000',
      },
    },
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    height: '30px',
    margin: '3px 0 3px',
    color: theme.palette.color.black,
    fontFamily: 'miller',
    fontSize: '13px',
    lineHeight: '12px',
    textAlign: 'center',
    textTransform: 'capitalize',
    '&.small': {
      height: '24px',
    },
  },
  counter: {
    width: '100%',
    marginTop: '4px',
    padding: '4px 0',
    backgroundColor: theme.palette.color.white,
    color: theme.palette.color.black,
    fontFamily: 'p22-underground',
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'center',
    textTransform: 'uppercase',
    '&.white-background-mode': {
      backgroundColor: '#F7F1EB',
    },
  },
}));

const FurnitureCounter = ({
  icon,
  text,
  counter,
  whiteBackgroundMode,
  hideIcon,
  roundedBorderedIcon,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={clsx(classes.container, whiteBackgroundMode && 'white-background-mode')}>
        {roundedBorderedIcon ? (
          <div className='bordered-icon'>{icon}</div>
        ) : hideIcon ? null : icon ? (
          icon
        ) : (
          <Box height={'24px'} width='24px' />
        )}
        {}
        <span className={clsx(classes.text, hideIcon && 'small')}>{text}</span>
      </div>
      <span className={clsx(classes.counter, whiteBackgroundMode && 'white-background-mode')}>
        {counter}
      </span>
    </div>
  );
};

export default FurnitureCounter;
