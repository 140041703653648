const projectConfig = {
  SIN: {
    myAiUrl: 'https://abitarein.my.site.com/AreaRiservata/s/',
    deadline: '28 marzo 2024',
  },
  BDP: {
    myAiUrl: 'https://abitarein.my.site.com/AreaRiservata/s/',
    deadline: '11 marzo 2024',
  },
};

export default projectConfig;
