import { Grid, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import ConfiguratorIntro from '../../../components/configurator/ConfiguratorIntro';
import { TemperaturaIcon } from '../../../components/Icons';
import ConfiguratorSelectionLayout from '../../../components/Layout/ConfiguratorSelectionLayout.jsx';
import ConfiguratorTwoColumnsPlanimetryLayout from '../../../layouts/ConfiguratorTwoColumnsPlanimetryLayout';
import { getOption } from '../../../services/optionApi';
import { useStore } from '../../../store/storeUtils';
import _ from 'lodash';
import {
  handleOptionPlanimetryLayers,
  prepareOptionData,
} from '../../../utils/optionsUtils';
import { useParams } from 'react-router';
import { useRef } from 'react';
import { handleSave, singleChoiceSelection } from '../../../utils/selectionUtils';
import { getLayoutImagesByConfigurationId } from '../../../services/layoutApi';
import clsx from 'clsx';
import {
  handleCardMouseEnterSimple,
  handleCardMouseLeaveSimple,
  resumeAnswerSimple,
} from '../../../utils/configuratorUtils';
import OptionCard, { StandardOptionCardBody } from '../../../components/Card/OptionCard';
import DefaultChoiceImgText from '../layout/currentConfiguration/DefaultChoiceImgText';

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: '#EEEEEE',
    width: '100%',
  },
  list: { marginTop: '25px', padding: '0 22px 22px' },
  alertMessage: { marginBottom: '5px' },
  scrollableContent: {
    backgroundColor: '#EEEEEE',
  },
}));

const FirstColumn = ({ defaultOption, onClick, selectedOptions }) => {
  return defaultOption ? (
    <DefaultChoiceImgText
      isSelected={_.isEqual(defaultOption.ids, selectedOptions)}
      onClick={() => {
        onClick(defaultOption.ids);
      }}
      message="Nell’appartamento la temperatura si regola da un unico cronotermostato posizionato nel soggiorno."
      icon={<TemperaturaIcon />}
    />
  ) : (
    ''
  );
};

const SecondColumn = ({
  options,
  selectedOptions,
  onClick,
  cardOnMouseEnter,
  cardOnMouseLeave,
}) => {
  const classes = useStyles();

  return (
    <ConfiguratorSelectionLayout scrollableContentClasses={clsx(classes.scrollableContent)}>
      <div className={classes.content}>
        <ConfiguratorIntro
          icon={<TemperaturaIcon />}
          title={'In quali locali vuoi aggiungere dei termostati?'}
          topLeftTitle={'TERMOSTATO'}
          badgeText='Aggiungendo dei termostati potrai personalizzare la temperatura del singolo locale.'
        />
        <Grid container className={classes.list} spacing={2} justifyContent='center'>
          {options.map((el, i) => (
            <Grid item key={i} xs={6} lg={4}>
              <OptionCard
                {...el}
                onClick={() => onClick(el.ids)}
                isSelected={_.difference(el.ids, selectedOptions).length === 0}
                onMouseEnter={cardOnMouseEnter}
                onMouseLeave={cardOnMouseLeave}
                infoPriceMessage="Il prezzo comprende la fornitura e l’installazione di n°1 termostatato per ogni posizione selezionata. Il prezzo è calcolato sul totale dei termostati previsti nel pacchetto."
              >
                <StandardOptionCardBody
                  {...el}
                  message={'Aggiungo termostati in:'}
                  plateText={'P-'}
                  name=''
                  useOptionPosition
                />
              </OptionCard>
            </Grid>
          ))}
        </Grid>
      </div>
    </ConfiguratorSelectionLayout>
  );
};

const Temperatura = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const [defaultOption, setDefaultOption] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [images, setImages] = useState([]);
  const [baseOptions, setBaseOptions] = useState([]);
  const [isMouseOn, setIsMouseOn] = useState(false);

  const substepIdRef = useRef();
  const oldAnswersRef = useRef();
  const showLayerOnHoverRef = useRef(true);
  const baseImagesRef = useRef([]);
  const baseImagesWithOptionsRef = useRef([]);

  const store = useStore();
  const params = useParams();

  useEffect(() => {
    store.setUserCanGoNext(false);
    prepareOptions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prepareOptions = async () => {
    const substepId = store.getSubstepIdByFrontendId(store.currentSubstep);

    let optionData = await getOption(
      store.currentConfiguration?.layout?.id,
      substepId,
      params.configuration,
    );
    substepIdRef.current = optionData?.data.substep.id;

    optionData = prepareOptionData(optionData);

    // Imposto i layer da far vedere
    const layoutImagesResponse = await getLayoutImagesByConfigurationId(params.configuration);
    const layoutImages = layoutImagesResponse.data;

    baseImagesRef.current = layoutImages;

    baseImagesWithOptionsRef.current = layoutImages;
    setImages(layoutImages);

    setOptions(
      optionData
        .filter((el) => !el.isDefault)
        ?.sort((a, b) => a?.position - b?.position), // Ordinamento opzioni
    );
    setBaseOptions(optionData.sort((a, b) => a.position - b.position))

    const defaultOption = optionData.find((el) => el.isDefault);
    setDefaultOption(defaultOption);

    resumeAnswerSimple(store, oldAnswersRef, substepIdRef, setSelectedOptions, setIsLoading);
  };

  const prepareRoomsHover = (rooms) => {
    const choosenLayoutRooms = store.currentConfiguration?.layout?.rooms;
    const choosenOptionRooms = choosenLayoutRooms?.filter((room) => rooms?.includes(room.name));
    const choosenOptionRoomsTags = choosenOptionRooms?.map((room) => room.tags);
    const choosenOptionRoomsTagsIsArea = choosenOptionRoomsTags?.map((room) => room.find((roo) => roo.isArea));
    const choosenOptionRoomsTagsIsRoom = choosenOptionRoomsTags?.map((room) => room.find((roo) => roo.isRoom));
    const choosenOptionRoomsTagsIsRoomFilelabels = choosenOptionRoomsTagsIsRoom?.map((roomIsRoom) => {
      return {fileUrl: roomIsRoom.fileLabels?.[0]?.url, isRoom: true}
    });
    const choosenOptionRoomsTagsIsAreaFilelabels = choosenOptionRoomsTagsIsArea?.map((roomIsArea) => {
      return {fileUrl: roomIsArea.fileLabels?.[0]?.url, isArea: true}
    });
    const choosenRoomsTags = choosenOptionRoomsTagsIsAreaFilelabels?.concat(choosenOptionRoomsTagsIsRoomFilelabels)
    return choosenRoomsTags;
  }

  useEffect(() => {
    const save = async () => {
      await handleSave(selectedOptions, params.configuration, substepIdRef.current, store);
    };

    if (!_.isEqual(oldAnswersRef.current, selectedOptions)) {
      store.setSubstepSave(save);
    } else {
      store.setSubstepSave(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  const handleUserSelection = (selection) => {
    singleChoiceSelection(selection, selectedOptions, setSelectedOptions, store);
  };

  // Gestione delle immagini della planimetria alla selezione di un opzione
  useEffect(() => {
    if(!isMouseOn){
      const optionsRooms = baseOptions?.find((option) => JSON.stringify(option?.ids) === JSON.stringify(selectedOptions))?.rooms;
      const choosenLayoutRooms = store.currentConfiguration?.layout?.rooms;
      const choosenOptionRooms = choosenLayoutRooms?.filter((room) => optionsRooms?.includes(room.name));
      const choosenOptionRoomsTags = choosenOptionRooms?.map((room) => room.tags);
      const choosenOptionRoomsTagsIsArea = choosenOptionRoomsTags?.map((room) => room.find((roo) => roo.isArea));
      const choosenOptionRoomsTagsIsRoom = choosenOptionRoomsTags?.map((room) => room.find((roo) => roo.isRoom));
      const choosenOptionRoomsTagsIsRoomFilelabels = choosenOptionRoomsTagsIsRoom?.map((roomIsRoom) => {
        return {fileUrl: roomIsRoom.fileLabels?.[0]?.url, isRoom: true}
      });
      const choosenOptionRoomsTagsIsAreaFilelabels = choosenOptionRoomsTagsIsArea?.map((roomIsArea) => {
        return {fileUrl: roomIsArea.fileLabels?.[0]?.url, isArea: true}
      });
      const choosenRoomsTags = choosenOptionRoomsTagsIsAreaFilelabels?.concat(choosenOptionRoomsTagsIsRoomFilelabels);
    handleOptionPlanimetryLayers(
      baseOptions,
      defaultOption?.ids,
      selectedOptions,
      showLayerOnHoverRef,
      baseImagesRef,
      setImages,
      baseImagesWithOptionsRef,
      choosenRoomsTags,
    );
    }
  }, [defaultOption?.ids, baseOptions, selectedOptions, store.currentConfiguration?.layout?.rooms, isMouseOn]);

  const handleCardMouseEnter = (fileLabels, tags, rooms) => {
    const roomsOn = rooms ? prepareRoomsHover(rooms) : [];
    setIsMouseOn(true)
    handleCardMouseEnterSimple(
      fileLabels,
      showLayerOnHoverRef,
      baseImagesWithOptionsRef,
      setImages,
      [],
      undefined,
      roomsOn
    );
  };
  const handleCardMouseLeave = () => {
    setIsMouseOn(false)
    handleCardMouseLeaveSimple(showLayerOnHoverRef, baseImagesWithOptionsRef, setImages);
  };

  return (
    <ConfiguratorTwoColumnsPlanimetryLayout
      planimetryImages={images}
      firstColumn={
        <FirstColumn
          defaultOption={defaultOption}
          selectedOptions={selectedOptions}
          onClick={handleUserSelection}
          cardOnMouseEnter={handleCardMouseEnter}
          cardOnMouseLeave={handleCardMouseLeave}
        />
      }
      secondColumn={
        <SecondColumn
          options={options}
          selectedOptions={selectedOptions}
          onClick={handleUserSelection}
          cardOnMouseEnter={handleCardMouseEnter}
          cardOnMouseLeave={handleCardMouseLeave}          
        />
      }
      isLoading={isLoading}
      firstRowTitle={'IL TUO'}
      secondRowTitle={'APPARTAMENTO'}
      secondColumnFirstRowTitle={'SOLUZIONI'}
      secondColumnSecondRowTitle={'ALTERNATIVE'}
      noOption={options?.length === 0}
      noOptionIcon={<TemperaturaIcon />}
      noOptionText={'Non ci sono termostati extra da inserire.'}
      optionLayersOnTop
    />
  );
};

export default Temperatura;
