import { useEffect } from 'react';
import { useState } from 'react';
import ConfigurationLayout from '../../../../components/Layout/ConfigurationLayout';
import FurnitureCounterContainer from '../../../../components/Layout/FurnitureCounterContainer';
import { prepareRoomsInfo } from '../../../../utils/layoutUtils';

const CurrentSceltaLayout = ({
  defaultItem,
  onClick,
  isSelected,
  infoRichText,
  subtitle,
  hideButton,
  infoRichTextButtonLabel,
}) => {
  const [roomsInfo, setRoomsInfo] = useState([]);

  useEffect(() => {
    const roomsInfo = prepareRoomsInfo(defaultItem?.rooms);
    setRoomsInfo(roomsInfo);
  }, [defaultItem]);

  return (
    <ConfigurationLayout
      title='la '
      boldTitle='configurazione di partenza'
      buttonText='CONFERMA QUESTA SCELTA'
      onClick={onClick}
      isSelected={isSelected}
      infoRichText={infoRichText}
      infoRichTextButtonLabel={infoRichTextButtonLabel}
      subtitle={subtitle}
      hideButton={hideButton}
    >
      <FurnitureCounterContainer rooms={roomsInfo} centerMode />
    </ConfigurationLayout>
  );
};

export default CurrentSceltaLayout;
