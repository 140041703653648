import { makeStyles } from '@material-ui/core';
import { Alert, Star } from '../Icons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '218px',
  },
  header: {
    display: 'grid',
    gridTemplateColumns: '1fr repeat(1, auto) 1fr',
    justifyItems: 'end',
    alignItems: 'center',
    // width: '100%',
    padding: '6px',
    backgroundColor: '#CDC7C1',
    color: theme.palette.color.white,
    '& .title': {
      gridColumnStart: 2,
      fontWeight: '600',
      fontSize: '15px',
      lineHeight: '18px',
      textAlign: 'center',
      textTransform: 'uppercase',
      //   [theme.breakpoints.down('sm')]: {
      //     gridColumnStart: 1,
      //   },
    },
    '& .stars': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: theme.palette.primary.main,
      '& > *': {
        margin: '0px 2px',
        '&:first-child': {
          marginLeft: '0px',
        },
        '&:last-child': {
          marginRight: '0px',
        },
      },
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: '20px 8px 8px',
    backgroundColor: '#F9F9F9',
    color: '#5E5E5E',
    '& .moods': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '20px',
      '& .mood': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& .subtitle': {
          fontSize: '14px',
          lineHeight: '17px',
          textAlign: 'center',
          color: theme.palette.color.black,
        },
        '& .shape': {
          width: '70px',
          height: '70px',
          margin: '6px auto 14px',
          borderRadius: '5px',
        },
      },
    },
    '& .info-text': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '6px',
      fontWeight: '500',
      fontSize: '13px',
      lineHeight: '14px',
      color: '#5E5E5E',
      '& svg': {
        alignSelf: 'baseline',
        minWidth: '15px',
      },
      [theme.breakpoints.down('sm')]: {
        gap: '3px',
      },
    },
  },
}));

const MoodCard = ({ title, nStars, moods, infoText }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <span className='title'>{title}</span>
        <span className='stars'>
          {Array.from('x'.repeat(nStars > 3 ? 3 : nStars)).map((_, i) => (
            <Star key={i} color='currentColor' width='16px' height='16px' />
          ))}
        </span>
      </div>
      <div className={classes.content}>
        <div className='moods'>
          {moods.map((el, i) => (
            <div key={i} className='mood'>
              <span className='subtitle'>{el.name}</span>
              <span className='shape' style={{ backgroundColor: el.color }}></span>
            </div>
          ))}
        </div>
        <div className='info-text'>
          <Alert color='currentColor' width='15px' height='15px' />
          <span>{infoText}</span>
        </div>
      </div>
    </div>
  );
};

export default MoodCard;
