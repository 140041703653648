import * as React from "react";

function SvgRuler(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.478 7.4L.59 0v15h15.569l-7.68-7.6zm-4.463 4.2l-.104-4.2 4.36 4.2H4.014zM9.386 3.145c.52 0 1.038.3 1.142.9h-.83c0-.2-.208-.3-.416-.3-.311 0-.415.2-.415.6 0 .4.208.6.415.6.208 0 .312-.1.416-.3h.83c-.104.6-.623.9-1.142.9-.726 0-1.245-.5-1.245-1.2s.519-1.2 1.245-1.2zM14.263 4.245c0-.2-.208-.5-.415-.5-.208 0-.52.2-.52.4V5.545h-.726v-1.3c0-.2-.207-.5-.415-.5s-.519.2-.519.4V5.545h-.727v-2.4h.727v.3c.104-.2.415-.3.727-.3.31 0 .622.2.83.4.104-.2.415-.4.726-.4.52 0 .934.3 1.038.8v1.5h-.726v-1.2z"
        fill={props.color}
      />
    </svg>
  );
}

export default SvgRuler;
