import * as React from 'react';

function SvgIlluminazioneIngresso(props) {
  return (
    <svg viewBox='0 0 54 56' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#illuminazione-ingresso_svg__clip0_2758_23970)' fill='#C4C4C4'>
        <path d='M28 53.87l-16.24-2.91V17.22l7.59-1.36V12.2H1c-.56 0-1 .45-1 1v41.78c0 .55.44 1 1 1s1-.45 1-1V14.2h16.26l-7.57 1.36c-.44.07-.75.45-.75.89v35.28c0 .44.31.82.75.89l17.79 3.19c.15.1.33.17.52.17.55 0 1-.45 1-1V41.7h-2v12.17z' />
        <path d='M13.469 34.088a1.52 1.52 0 103.038.002 1.52 1.52 0 00-3.038-.002zM38.81 33.93h-8.06c-.69 0-1.25.57-1.25 1.26 0 .69.56 1.26 1.25 1.26h8.06c.69 0 1.25-.56 1.25-1.26s-.56-1.26-1.25-1.26zM42.29 10.97c-.34-.33-.71-.64-1.09-.91-1.83-1.35-4.06-2.07-6.39-2.07-2.83 0-5.49 1.05-7.49 2.98-2.01 1.93-3.12 4.5-3.12 7.24 0 2.54.97 4.97 2.72 6.84 1.78 2.11 2.61 6.05 2.61 6.08l.17.82H40l.02-.99c.07-2.88 2.81-6.06 2.93-6.19 1.59-1.84 2.47-4.17 2.47-6.56 0-1.62-.39-3.18-1.12-4.58-.51-.98-1.18-1.87-2.01-2.66zm-.88 12.46c-.29.33-2.8 3.29-3.33 6.48h-6.74c-.35-1.34-1.26-4.28-2.91-6.23a7.95 7.95 0 01-2.2-5.47c0-4.51 3.85-8.19 8.58-8.19s8.57 3.68 8.57 8.19c0 1.9-.7 3.76-1.97 5.22zM35.938 0h-2.27v4.64h2.27V0zM47.112 3.931l-2.98 3.557 1.741 1.458 2.98-3.557-1.74-1.458zM53.18 16.24h-4.64v2.27h4.64v-2.27z' />
        <path d='M34.9 23.52l-2.92-2.91-1.41 1.41 4.33 4.33 4.09-4.1-1.41-1.41-2.68 2.68z' />
      </g>
      <defs>
        <clipPath id='illuminazione-ingresso_svg__clip0_2758_23970'>
          <path fill='#fff' d='M0 0h53.18v55.98H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIlluminazioneIngresso;
