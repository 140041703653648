import React, { useEffect, useState } from 'react';
import { makeStyles, ButtonBase, Grid, Tabs, Tab } from '@material-ui/core';
import clsx from 'clsx';
import { Document, Page } from 'react-pdf';
import { Exit, LeftChevron } from './Icons';
import LoadingAnimation from './LoadingAnimation';
import SvgDownload2 from './Icons/Download2';
import { saveAs } from 'file-saver';
import { getFileNameFromUrl } from '../utils/stringUtils';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    padding: '2vh 0',
    background: 'rgba(0,0,0,0.55)',
    backdropFilter: 'blur(15px)',
    overflow: 'hidden',
    zIndex: theme.zIndex.modal,
    '& iframe': {
      width: '95vw',
      height: '95vh',
      border: '0',
      borderRadius: '8px',
    },
  },
  closeIcon: {
    position: 'absolute',
    top: '30px',
    right: '30px',
    backgroundColor: '#000',
    height: '50px',
    width: '50px',
    borderRadius: '100%',
    zIndex: 10,
    '& svg': {
      height: '15px',
      width: 'auto',
    },
  },
}));

const Tutorial = ({ handleClose }) => {
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        direction='column'
        className={classes.root}
        alignItems='center'
        justifyContent='center'
      >
        <ButtonBase className={classes.closeIcon} onClick={handleClose}>
          <Exit height='8px' width='8px' color='#F08219' />
        </ButtonBase>

        <iframe src={`${process.env.PUBLIC_URL}/assets/tutorial/index.html`} />
      </Grid>
    </>
  );
};

export default Tutorial;
