import * as React from 'react';

function SvgTapparelleSmart(props) {
  return (
    <svg viewBox='0 0 122 80' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M85.171 47.71H5.121v4.1h80.05v-4.1zM90.29 31.93H0v6.2h90.29v-6.2zM85.171 56.8H5.121v4.1h80.05v-4.1zM85.171 65.92H5.121v4.1h80.05v-4.1zM85.161 75.01H5.121v4.1h80.04v-4.1zM86.738 10.43a1 1 0 00-1 1v2.44a1 1 0 00.88 1c11.45.48 20.68 10.25 21.13 22.38a.942.942 0 00.91.93h2.34a.944.944 0 00.859-.63.937.937 0 00.051-.37c-.49-14.48-11.52-26.16-25.18-26.68'
        fill='#4E4D4D'
      />
      <path
        d='M86.73.31a1 1 0 00-1 1v2.41a1 1 0 00.9 1c16.7.49 30.19 14.79 30.66 32.48a.949.949 0 00.247.697.939.939 0 00.673.303h2.3a.932.932 0 00.668-.306.943.943 0 00.242-.694C121 17.06 105.67.83 86.73.31zM98.21 37.22a.93.93 0 00.91.9h2.3a.941.941 0 00.92-1c-.48-8.88-7.21-16-15.59-16.52a1 1 0 00-1 1v2.44a1 1 0 00.85 1c6.19.45 11.16 5.7 11.58 12.26'
        fill='#4E4D4D'
      />
    </svg>
  );
}

export default SvgTapparelleSmart;
