import * as React from "react";

function SvgCabinetLighting(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 76 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.668 2.71a30.54 30.54 0 0061 0h4.56a35.1 35.1 0 01-70.16 0h4.6zm58.64 0a28.14 28.14 0 01-56.24 0h56.24zM.668 1.51a37.5 37.5 0 0075 0V.31h-75v1.2z"
        fill={props.color}
      />
      <path
        d="M38.167 24.74a23.12 23.12 0 0022.93-19.43l-2.37-.39a20.74 20.74 0 01-20.56 17.39 20.76 20.76 0 01-20.5-17.24l-2.37.41a23.16 23.16 0 0022.87 19.26z"
        fill={props.color}
      />
    </svg>
  );
}

export default SvgCabinetLighting;
