import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    position: 'relative',
  },
  firstColumn: {
    height: '100%',
    width: `20%`,
    maxWidth: '350px',
  },
  secondColumn: {
    height: '100%',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingLeft: '22px',
    paddingRight: '5px',
  },
}));

const TwoColumnsLayout = ({ firstColumn, secondColumn }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.firstColumn}>{firstColumn}</div>
      <div className={classes.secondColumn}>{secondColumn}</div>
    </div>
  );
};

export default TwoColumnsLayout;
