import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      gap: '8px',
    },
    [theme.breakpoints.down('md')]: {
      gap: '8px',
    },
  },
  icon: {
    display: 'flex',
    fontSize: '52px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '38px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '26px',
    },
  },
  title: {
    fontFamily: 'miller',
    fontStyle: 'italic',
    fontSize: '15px',
    lineHeight: '18px',
    textAlign: 'center',
    color: '#000',
  },
  subTitle: {
    fontFamily: 'miller',
    fontStyle: 'italic',
    fontSize: '11px',
    lineHeight: '18px',
    textAlign: 'center',
    color: '#000',
  },
  subTitleColor: {
    color: theme.palette.color.orange,
  }
}));

const IconTitle = ({ icon, title, subTitle, subTitleIcon }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.icon}>{icon}</div>
      <span className={classes.title}>{title}</span>
      {subTitle && (
      <span className={classes.subTitle}><i className={classes.subTitleColor}>{subTitleIcon} Dallo schema</i> selezionato potrai <i className={classes.subTitleColor}>scegliere i dettagli del tuo bagno.</i></span>
      )}
    </div>
  );
};

export default IconTitle;
