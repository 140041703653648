import * as React from 'react';

function SvgPlusCircled(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <circle cx={8} cy={8} r={7.5} stroke={props.color} />
      <path d='M7.996 4.445v7.111M11.55 8.002H4.44' stroke={props.color} />
    </svg>
  );
}

export default SvgPlusCircled;
