import * as React from "react";

function SvgNext(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 29 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={0.634}
        y={1.222}
        width={27.166}
        height={17.419}
        rx={1.6}
        stroke="#F08219"
        strokeWidth={0.8}
      />
      <path
        d="M8.52 9.933h12.429m0 0l-3.977-3.751m3.977 3.75l-3.977 3.752"
        stroke="#F08219"
        strokeWidth={0.8}
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgNext;
