import { makeStyles, Popover } from '@material-ui/core';
import clsx from 'clsx';
import { useRef, useState } from 'react';
import { Alert } from './Icons';
import InfoPopup, { PhaseInfoContent } from './InfoPopup';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '3px',
    paddingRight: '8px',
    fontWeight: '500',
    fontSize: '13px',
    lineHeight: '16px',
    backgroundColor: (props) => (props.color ? props.color : '#5E5E5E'),
    color: theme.palette.color.white,
    borderRadius: '32px',
    '& > svg': {
      marginRight: '6px',
    },
  },
}));

const AlertBox = ({ color, text, className, noIcon, infoData }) => {
  const classes = useStyles();
  const [showInfo, setShowInfo] = useState(false);
  const infoRef = useRef();

  return (
    <div className={clsx(classes.root, className)}>
      {!noIcon && <Alert color='#C4C4C4' />}
      <span ref={infoRef}>{text}</span>

      <Popover
        open={showInfo}
        anchorEl={infoRef.current}
        onClose={() => setShowInfo(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{ classes: { root: classes.popover } }}
      >
        <InfoPopup
          body={<PhaseInfoContent {...infoData} />}
          isPopover
          handleClose={() => setShowInfo(false)}
        />
      </Popover>
    </div>
  );
};

export default AlertBox;
