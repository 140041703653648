import { observer } from 'mobx-react-lite';
import ConfigurationLayout from '../../../../components/Layout/ConfigurationLayout';
import SubtitledImage from '../../../../components/Layout/SubtitledImage';

const DefaultChoiceImgText = observer(
  ({
    text,
    image,
    icon,
    onClick,
    isSelected,
    positions,
    plateText,
    message,
    planimetryImages,
    subtitle,
    legendImg,
    infoRichText,
    infoRichTextButtonLabel,
  }) => {
    return (
      <ConfigurationLayout
        title='la '
        boldTitle='configurazione di partenza'
        buttonText='CONFERMA QUESTA SCELTA'
        onClick={onClick}
        isSelected={isSelected}
        message={message}
        planimetryImages={planimetryImages}
        noBottomBorder={planimetryImages?.length > 0}
        subtitle={subtitle}
        legendImg={legendImg}
        infoRichText={infoRichText}
        infoRichTextButtonLabel={infoRichTextButtonLabel}
      >
        <SubtitledImage
          image={image}
          look='italic'
          subtitle={text}
          icon={icon}
          positions={positions}
          plateText={plateText}
        />
      </ConfigurationLayout>
    );
  },
);

export default DefaultChoiceImgText;
