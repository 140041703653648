import * as React from 'react';

function SVGPortaGrezza (props) {
  return (
    <svg viewBox="0 0 253 214" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M187.699 0.369141H64.6992V212.669H187.699V0.369141Z" fill="#EFEAEA"/>
<path d="M187.999 212.929H64.4492V0.119141H187.999V212.929ZM64.9992 212.429H187.499V0.619141H64.9992V212.429Z" fill="#AFAFAF"/>
<path d="M76.7 110C75.77 110 74.8609 109.724 74.0878 109.207C73.3146 108.69 72.7122 107.955 72.3568 107.096C72.0014 106.237 71.9089 105.291 72.091 104.379C72.2732 103.467 72.7218 102.63 73.3801 101.973C74.0384 101.316 74.8768 100.869 75.7892 100.689C76.7016 100.508 77.6469 100.603 78.5055 100.96C79.3642 101.318 80.0975 101.921 80.6128 102.696C81.128 103.47 81.402 104.38 81.4 105.31C81.4 105.926 81.2783 106.537 81.042 107.107C80.8057 107.676 80.4594 108.194 80.0228 108.629C79.5862 109.065 79.068 109.41 78.4978 109.645C77.9277 109.881 77.3167 110.001 76.7 110V110Z" fill="#AFAFAF"/>
<path d="M64.58 0H0V0.5H64.58V0Z" fill="#AFAFAF"/>
<path d="M64.58 212.68H0V213.18H64.58V212.68Z" fill="#AFAFAF"/>
<path d="M252.279 0H187.699V0.5H252.279V0Z" fill="#AFAFAF"/>
<path d="M252.279 212.68H187.699V213.18H252.279V212.68Z" fill="#AFAFAF"/>
<circle cx="188.5" cy="107.5" r="37.25" fill="white" stroke="#AFAFAF" strokeWidth="0.5"/>
<mask id="mask0_3484_23925" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="151" y="70" width="75" height="75">
<circle cx="188.5" cy="107.5" r="37.25" fill="white" stroke="#AFAFAF" strokeWidth="0.5"/>
</mask>
<g mask="url(#mask0_3484_23925)">
<g opacity="0.05">
<mask id="mask1_3484_23925" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="151" y="70" width="75" height="75">
<circle cx="188.5" cy="107.5" r="37.25" fill="white" stroke="#AFAFAF" strokeWidth="0.5"/>
</mask>
<g mask="url(#mask1_3484_23925)">
<circle cx="188.5" cy="107.5" r="37.25" fill="white" stroke="#AFAFAF" strokeWidth="0.5"/>
<path d="M320 201H84V43H320V201Z" fill="#EBF0F0"/>
</g>
</g>
<circle cx="188.5" cy="107.5" r="37.25" stroke="#AFAFAF" strokeWidth="0.5"/>
</g>
</svg>

  );
}

export default SVGPortaGrezza;
