import * as React from "react";

function SvgPlus(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.008 1.01v9.4M10.991 5.709H1.027"
        stroke={props.color}
        strokeWidth={2}
        strokeLinecap="round"
      />
    </svg>
  );
}

export default SvgPlus;
