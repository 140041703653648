import * as React from 'react';

function SvgPiano(props) {
  return (
    <svg viewBox='0 0 32 33' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#piano_svg__clip0_2723_23206)'>
        <path
          d='M31.71 16.77a.78.78 0 00-.38-.68l-5.08-2.98 5.07-2.82c.24-.14.4-.39.4-.67a.78.78 0 00-.38-.68L16.25.11a.795.795 0 00-.79 0L.38 8.94a.78.78 0 00.02 1.35l5.07 2.82-5.09 2.98a.78.78 0 00.02 1.35l4.75 2.64-4.77 2.79a.78.78 0 00-.38.68c0 .28.16.53.4.67l15.08 8.39c.12.07.25.1.38.1s.26-.03.38-.1l15.08-8.39c.24-.14.4-.39.4-.67a.78.78 0 00-.38-.68l-4.76-2.79 4.75-2.64c.24-.14.4-.39.4-.67h-.02zM15.86 1.68L29.37 9.6l-13.51 7.52L2.34 9.59l13.52-7.91zm9.13 19.28l4.38 2.57-13.51 7.52-13.52-7.52 4.38-2.57 8.76 4.87c.12.07.25.1.38.1s.26-.03.38-.1l8.75-4.87zm-.37-1.57l-8.76 4.88-13.52-7.53 4.71-2.75 8.43 4.69c.23.13.52.13.75 0l8.43-4.69 4.7 2.75-4.75 2.64.01.01z'
          fill={props.color}
        />
      </g>
      <defs>
        <clipPath id='piano_svg__clip0_2723_23206'>
          <path fill='#fff' d='M0 0h31.71v32.71H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgPiano;
