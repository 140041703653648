import * as React from 'react';

function SvgArrowBack(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 20 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M19 4.5a.5.5 0 000-1v1zM.646 3.646a.5.5 0 000 .708l3.182 3.182a.5.5 0 10.708-.708L1.707 4l2.829-2.828a.5.5 0 10-.708-.708L.646 3.646zM19 3.5H1v1h18v-1z'
        fill={props.color}
      />
    </svg>
  );
}

export default SvgArrowBack;
