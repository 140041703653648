import * as React from "react";

function SvgCloseRound(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.194.665a2.275 2.275 0 00-.742-.492 2.315 2.315 0 00-2.499.492l-9.572 9.319L3.824.635A2.333 2.333 0 002.232.074 2.327 2.327 0 00.678.729a2.229 2.229 0 00-.677 1.517c-.02.571.182 1.129.567 1.56l9.542 9.378-9.542 9.363c-.385.431-.587.989-.567 1.56a2.23 2.23 0 00.677 1.517c.414.404.97.638 1.554.655a2.333 2.333 0 001.592-.561l9.557-9.349 9.572 9.349a2.269 2.269 0 001.62.652c.302.002.6-.054.878-.166.279-.112.531-.277.743-.486.214-.208.383-.455.498-.727a2.194 2.194 0 00-.498-2.444l-9.572-9.363 9.572-9.349c.214-.207.383-.455.498-.727a2.194 2.194 0 000-1.716 2.235 2.235 0 00-.498-.727z"
        fill={props.color}
      />
    </svg>
  );
}

export default SvgCloseRound;
