import { Grid, makeStyles } from '@material-ui/core';
import ConfiguratorIntro from '../../../components/configurator/ConfiguratorIntro';
import { NoCassaforte, Safe } from '../../../components/Icons';
import ConfiguratorSelectionLayout from '../../../components/Layout/ConfiguratorSelectionLayout.jsx';
import ConfiguratorTwoColumnsPlanimetryLayout from '../../../layouts/ConfiguratorTwoColumnsPlanimetryLayout';
import DefaultChoiceImgText from './currentConfiguration/DefaultChoiceImgText';
import {
  handleOptionPlanimetryLayers,
  prepareOptionDataExploded,
} from '../../../utils/optionsUtils';
import { useEffect, useState } from 'react';
import { useStore } from '../../../store/storeUtils';
import { getOption } from '../../../services/optionApi';
import _ from 'lodash';
import { useRef } from 'react';
import { useParams } from 'react-router';
import { handleSave, multipleChoiceSelection } from '../../../utils/selectionUtils';
import { getLayoutImagesByConfigurationId } from '../../../services/layoutApi';
import {
  handleCardMouseEnterSimple,
  handleCardMouseLeaveSimple,
  resumeAnswerSimple,
} from '../../../utils/configuratorUtils';
import OptionCard, { StandardOptionCardBody } from '../../../components/Card/OptionCard';

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: '#EEEEEE',
    minHeight: '100%',
  },
  list: { marginTop: '25px', padding: '0 22px 22px' },
  alertMessage: { marginBottom: '5px' },
}));

const FirstColumn = ({ defaultOption, onClick, selectedOptions }) => {
  return defaultOption ? (
    <DefaultChoiceImgText
      icon={<NoCassaforte color={'#3a3a3a'} />}
      isSelected={_.isEqual(defaultOption.ids, selectedOptions)}
      onClick={() => {
        onClick(defaultOption.ids);
      }}
      message={"Nell'appartamento non sono previste casseforti."}
    />
  ) : (
    ''
  );
};

const SecondColumn = ({
  options,
  selectedOptions,
  onClick,
  cardOnMouseEnter,
  cardOnMouseLeave,
}) => {
  const classes = useStyles();

  return (
    <ConfiguratorSelectionLayout>
      <div className={classes.content}>
        <ConfiguratorIntro
          icon={<Safe />}
          title={'Vuoi installare la cassaforte?'}
          badgeText={'Puoi selezionare una o più posizioni'}
        />

        <Grid container className={classes.list} spacing={2} justifyContent='center'>
          {options.map((el, i) => (
            <Grid item key={i} xs={6} lg={4}>
              <OptionCard
                {...el}
                onClick={() => onClick(el.ids)}
                isSelected={_.difference(el.ids, selectedOptions).length === 0}
                onMouseEnter={cardOnMouseEnter}
                onMouseLeave={cardOnMouseLeave}
                multiple
                infoPriceMessage={
                  "Il prezzo comprende la fornitura e la posa in opera della cassaforte, incluso le lavorazioni edili e i rinforzi per l'installazione ad incasso nella parete."
                }
              >
                <StandardOptionCardBody
                  {...el}
                  message={'Aggiungo la cassaforte nella posizione:'}
                  name=''
                  plateText={'P-'}
                />
              </OptionCard>
            </Grid>
          ))}
        </Grid>
      </div>
    </ConfiguratorSelectionLayout>
  );
};

const Cassaforte = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const [defaultOption, setDefaultOption] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [images, setImages] = useState([]);

  const store = useStore();
  const params = useParams();

  const oldAnswersRef = useRef();
  const substepIdRef = useRef();
  const showLayerOnHoverRef = useRef(true);
  const baseImagesRef = useRef([]);
  const baseImagesWithOptionsRef = useRef([]);

  useEffect(() => {
    store.setUserCanGoNext(false);
    prepareOptions();
  }, []);

  const prepareOptions = async () => {
    const substepId = store.getSubstepIdByFrontendId(store.currentSubstep);

    let optionData = await getOption(
      store.currentConfiguration?.layout?.id,
      substepId,
      params.configuration,
    );
    substepIdRef.current = optionData?.data.substep.id;

    optionData = prepareOptionDataExploded(optionData);

    // Imposto i layer da far vedere
    const layoutImagesResponse = await getLayoutImagesByConfigurationId(params.configuration);
    const layoutImages = layoutImagesResponse.data;

    baseImagesRef.current = layoutImages;
    baseImagesWithOptionsRef.current = layoutImages;
    setImages(layoutImages);

    setOptions(
      optionData
        .filter((el) => !el.isDefault)
        ?.sort((a, b) => a?.positions[0] - b?.positions[0]), // Ordinamento opzioni
    );

    const defaultOption = optionData.find((el) => el.isDefault);
    setDefaultOption(defaultOption);

    resumeAnswerSimple(store, oldAnswersRef, substepIdRef, setSelectedOptions, setIsLoading);
  };

  const handleUserSelection = (selection) => {
    multipleChoiceSelection(
      selection,
      selectedOptions,
      defaultOption.ids,
      setSelectedOptions,
      store,
    );
  };

  useEffect(() => {
    const save = async () => {
      await handleSave(selectedOptions, params.configuration, substepIdRef.current, store);
    };

    if (!_.isEqual(oldAnswersRef.current, selectedOptions)) {
      store.setSubstepSave(save);
    } else {
      store.setSubstepSave(null);
    }
  }, [selectedOptions]);

  // Gestione delle immagini della planimetria alla selezione di un opzione
  useEffect(() => {
    handleOptionPlanimetryLayers(
      options,
      defaultOption?.ids,
      selectedOptions,
      showLayerOnHoverRef,
      baseImagesRef,
      setImages,
      baseImagesWithOptionsRef,
    );
  }, [selectedOptions]);

  const handleCardMouseEnter = (fileLabels) => {
    handleCardMouseEnterSimple(
      fileLabels,
      showLayerOnHoverRef,
      baseImagesWithOptionsRef,
      setImages,
    );
  };
  const handleCardMouseLeave = () => {
    handleCardMouseLeaveSimple(showLayerOnHoverRef, baseImagesWithOptionsRef, setImages);
  };

  return (
    <ConfiguratorTwoColumnsPlanimetryLayout
      planimetryImages={images}
      firstColumn={
        <FirstColumn
          defaultOption={defaultOption}
          selectedOptions={selectedOptions}
          onClick={handleUserSelection}
        />
      }
      secondColumn={
        <SecondColumn
          options={options}
          selectedOptions={selectedOptions}
          onClick={handleUserSelection}
          cardOnMouseEnter={handleCardMouseEnter}
          cardOnMouseLeave={handleCardMouseLeave}
        />
      }
      isLoading={isLoading}
      firstRowTitle={'IL TUO'}
      secondRowTitle={'APPARTAMENTO'}
      secondColumnFirstRowTitle={'SOLUZIONI'}
      secondColumnSecondRowTitle={'AGGIUNTIVE'}
      noOption={options?.length === 0}
      noOptionIcon={<Safe />}
      noOptionText={'Non ci sono opzioni per le cassaforti.'}
      optionLayersOnTop
    />
  );
};

export default Cassaforte;
