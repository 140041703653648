import API from './api';

export const getAnswer = async (configurationId) => {
  return await API.get(`/External/Answer?configurationId=${configurationId}`);
};

export const saveAnswer = async (configurationId, substepId, options, packageId, roomId) => {
  if (options) {
    return await API.post(`/External/Answer`, {
      configurationId,
      substepId,
      options,
    });
  } 
  return await API.post(`/External/Answer`, {
    configurationId,
    substepId,
    packageId,
    roomId,
  });
  
};

export const getAnswerDetail = async (configurationId) => {
  return await API.get(`/External/Answer/Detail?configurationId=${configurationId}`);
};

export const getAnswerPackageDetail = async (configurationId) => {
  return await API.get(`/External/Answer/PackageDetail?configurationId=${configurationId}`)
};
