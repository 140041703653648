import { Grid, makeStyles } from '@material-ui/core';
import ConfigurationLayout from '../../../../components/Layout/ConfigurationLayout';
import DoorCard from '../../../../components/Layout/DoorCard';

const useStyles = makeStyles((theme) => ({
  doorCardContainerRoot: {
    width: '100%',
    height: '100%',
  },
  doorCardRoot: {
    borderRight: '0.5px solid #999999',
    '&:last-child': {
      borderRight: 0,
    },
    [theme.breakpoints.down('md')]: {
      borderRight: 0,
      borderBottom: '0.5px solid #999999',
      '&:last-child': {
        paddingTop: '15px',
        borderBottom: 0,
      },
    },
  },
}));

const DefaultPorte = ({
  elements,
  isSelected,
  onClick,
  plateText,
  message,
  dimension,
  hideDescription,
  subtitle,
  leftMediaButton,
}) => {
  const classes = useStyles();

  return (
    <ConfigurationLayout
      title='la '
      boldTitle='configurazione di partenza'
      buttonText='CONFERMA QUESTA SCELTA'
      isSelected={isSelected}
      onClick={onClick}
      message={message}
      subtitle={subtitle}
    >
      {!elements ? (
        ''
      ) : (
        <Grid container className={classes.doorCardContainerRoot} alignItems='center'>
          {elements?.map((card, i) => (
            <Grid
              item
              key={i}
              sm={12}
              md={elements?.length === 1 ? 12 : 6}
              className={classes.doorCardRoot}
              onMouseEnter={
                card.cardOnMouseEnter ? () => card.cardOnMouseEnter(card?.fileLabels, card?.tags) : null
              }
              onMouseLeave={card.cardOnMouseLeave}
            >
              <DoorCard
                {...card}
                plateText={plateText}
                dimension={dimension}
                hideDescription={hideDescription}
                leftMediaButton={leftMediaButton}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </ConfigurationLayout>
  );
};

export default DefaultPorte;
