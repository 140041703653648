import * as React from "react";

function SvgTrash(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.01 8.252h7.293c.887 0 1.058.177.985.981L16.999 24.11c-.073.864-.328 1.112-1.216 1.112H4.332c-.912 0-1.13-.19-1.216-1.123-.251-2.91-.498-5.819-.741-8.728-.183-2.058-.353-4.103-.535-6.15 0-.78.097-.969.924-.969h7.294M11.843 3.663c-.08-.429-.32-.814-.675-1.08a1.847 1.847 0 00-1.246-.362 1.766 1.766 0 00-1.117.42c-.31.264-.512.626-.573 1.022h3.61zm-5.495 0c.15-.81.593-1.54 1.249-2.06A3.613 3.613 0 019.922.824c1.897 0 3.003.804 3.757 2.862h4.668c.802 0 1.13.355 1.082 1.088-.049.733-.365.875-1.216.887H1.802c-.864 0-1.216-.284-1.216-.982 0-.697.316-.993 1.216-.993h4.583"
        fill={props.color}
      />
    </svg>
  );
}

export default SvgTrash;
