import * as React from 'react';

function SvgUsbCucina(props) {
  return (
    <svg viewBox='0 0 40 42' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.08.018c.24.006.48.02.718.04v1.837a10.274 10.274 0 00-7.79 2.605 8.9 8.9 0 014.069 7.43.9.9 0 01-.95.92.93.93 0 01-.94-.92 7.441 7.441 0 00-6.1-7.08 7.63 7.63 0 00-9 5.59 7.13 7.13 0 001.16 5.4 7.358 7.358 0 004.8 3.08 1.001 1.001 0 01.76.73l2.32 13h4l-.67-8.61a.93.93 0 01.87-.93.82.82 0 01.6.15 1 1 0 01.41.67l.68 8.65h3.88v-7.2a.9.9 0 01.95-.92.928.928 0 01.94.92v7.19h.01v1.91h-11.5v4.94h11.5v1.73h-12.55a.94.94 0 01-.95-.92v-6.72l-2.32-13a11.797 11.797 0 01-1.23-.42.42.42 0 01-.15-.06h-.09a9.06 9.06 0 01-4.91-5 8.86 8.86 0 01.2-6.87 9.8 9.8 0 0112.51-4.69l.5-.49A12.15 12.15 0 0122.08.018zm14.973 12.08h2.943v3.164H38.99v2.45c.001.255-.045.507-.136.742-.09.235-.224.448-.392.627l-4.117 4.458h-.038v5.83c.649.127 1.23.509 1.63 1.072.4.564.59 1.268.53 1.974a2.922 2.922 0 01-.847 1.841 2.539 2.539 0 01-1.78.744c-.66 0-1.295-.265-1.781-.744a2.922 2.922 0 01-.848-1.841c-.058-.706.13-1.41.53-1.974.4-.563.982-.945 1.63-1.071v-2.375l-3.883-4.18a1.932 1.932 0 01-.392-.628 2.046 2.046 0 01-.136-.741v-2.757a1.46 1.46 0 01-.763-.67 1.638 1.638 0 01-.181-1.04 1.57 1.57 0 01.487-.92c.259-.236.586-.365.925-.365.338 0 .666.13.924.364.259.235.431.561.488.92.056.36-.009.728-.181 1.041-.173.314-.444.55-.764.67v2.752c0 .122.023.243.066.356a.942.942 0 00.187.303l3.223 3.471V9.144h-1.216l.842-1.574L33.84 6l.847 1.57.842 1.574h-1.202V22.13l3.495-3.758a.972.972 0 00.234-.66v-2.45h-1.002v-3.164z'
        fill='#C4C4C4'
      />
    </svg>
  );
}

export default SvgUsbCucina;
