import BoxAPI from './api';
import { getHcUserData } from '../../utils/localStorageUtils';

export const createSignRequest = async (embed_url_external_user_id, fileId, folderId, redirectUrl, nameNoExt) => {
    let data = JSON.stringify(
    {
	    "signers": [
		    {
          "role": 'signer',
          "email": process.env.REACT_APP_SEND_EMAIL_ONSIGN === 'true' ? getHcUserData().email : 'example@email.com',
          "embed_url_external_user_id": embed_url_external_user_id,
		    },
	    ],
	    "source_files": [
        {
          "type": 'file',
          "id": fileId,
        },
	    ],
      "parent_folder": {
        "type": 'folder',
        "id": folderId,
      },
      "redirect_url": redirectUrl,
      "name": nameNoExt,
    });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://api.box.com/2.0/sign_requests',
        headers: { 
          'Content-Type': 'application/json, application/json'
        },
        data : data
      };

    try {
        const response = await BoxAPI.request(config);
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
};