import BoxAPI from './api';

export const listSignRequest = async () => {
  try {
    const response = await BoxAPI.get(
      'https://api.box.com/2.0/sign_requests',
    );
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};