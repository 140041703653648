import { getHcUserData, setHcUserData } from '../utils/localStorageUtils';
import API, { APIrefresh } from './api';
import { getBoxJwt } from '../services/boxSign/requestJWT';

export const authenticate = async (email, password, store) => {
  try {
    const response = await API.post(`/User/Authenticate`, { email, password });
    if (response.status === 200) {
      response.data.email = email
      setHcUserData(response.data);
      store.setUserData(response.data);
      let userData = getHcUserData();
      try {
        const boxResponse = await getBoxJwt();
        if (boxResponse?.data?.accessToken) {
          userData.boxJwtToken = boxResponse?.data?.accessToken;
        } else {
          userData.boxJwtToken = '';
        }
        setHcUserData(userData);
        store.setUserData(userData);
      } catch (e) {
        console.log('BoxSign JWTerror: ', e);
      }
    }
    return response;
  } catch (e) {
    console.error('authenticate', e);
  }
};

export const sfNonceAuthenticate = async (email, nonce, store) => {
  try {
    const response = await API.post(`/User/SfNonceAuthenticate`, { email, nonce });
    if (response.status === 200) {
      response.data.email = email
      setHcUserData(response.data);
      store.setUserData(response.data);
      let userData = getHcUserData();
      try {
        const boxResponse = await getBoxJwt();
        if (boxResponse?.data?.accessToken) {
          userData.boxJwtToken = boxResponse?.data?.accessToken;
        } else {
          userData.boxJwtToken = '';
        }
        setHcUserData(userData);
        store.setUserData(userData);
      } catch (e) {
        console.log('BoxSign JWTerror: ', e);
      }
    }
    return response;
  } catch (e) {
    console.error('sfNonceAuthenticate', e);
  }
};

export const refreshToken = async (store) => {
  try {
    const response = await APIrefresh.post(`/User/RefreshToken`, {
      refreshToken: getHcUserData().refreshToken,
    });
    if (response.status === 200) {
      response.data.email = getHcUserData().email
      setHcUserData(response.data);
      store.setUserData(response.data);
      let userData = getHcUserData();
      try {
        const boxResponse = await getBoxJwt();
        if (boxResponse?.data?.accessToken) {
          userData.boxJwtToken = boxResponse?.data?.accessToken;
        } else {
          userData.boxJwtToken = '';
        }
        userData.email = getHcUserData().email
        setHcUserData(userData);
        store.setUserData(userData);
      } catch (e) {
        console.log('BoxSign JWTerror: ', e);
      }
    }
    console.log('error refreshing token...');
    return response;
  } catch (e) {
    console.error('refreshToken', e);
    return false;
  }
};
