import { makeStyles } from '@material-ui/core';
import MoodDetails from './MoodDetails';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    maxWidth: '290px',
  },
}));

const MoodSummary = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <MoodDetails title='Mood metropolitan' image='box-oggetto.png' name='sp3' description='Il tuo mood selezionato per la zona living e la zona notte è Metropolitan sp3.' />
      <MoodDetails title='Mood metropolitan' image='box-oggetto.png' name='sp3' description='Il tuo mood selezionato per la zona living e la zona notte è Metropolitan sp3.' />
    </div>
  );
};

export default MoodSummary;
