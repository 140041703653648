import * as React from 'react';

function SvgBath(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 186 150'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M185.172 46.62a1.499 1.499 0 00-1.09-.47H1.502a1.5 1.5 0 00-1.5 1.58l4.89 91.69a1.503 1.503 0 001.5 1.42h172.8a1.505 1.505 0 001.5-1.42l4.89-91.69a1.533 1.533 0 00-.092-.596 1.531 1.531 0 00-.318-.514zm-62.71 28.27h36.54v15h-36.54v-15zm36.55-3h-36.55V49.52a1.389 1.389 0 00-.06-.41h36.6l.01 22.78zm18.76 66H7.812l-4.73-88.74h116.45c-.043.133-.067.271-.07.41V73.1a2.805 2.805 0 000 .29v18.05a1.511 1.511 0 001.5 1.5h39.55a1.5 1.5 0 001.49-1.5V73.62a.806.806 0 000-.23.885.885 0 000-.23v-24h20.5l-4.73 88.73zM37.37 143.84H18.39v5.52h18.98v-5.52zM167.191 143.84h-18.98v5.52h18.98v-5.52zM39.453 39.86a8.09 8.09 0 10-8.09-8.09 8.1 8.1 0 008.09 8.09zm0-14.68a6.59 6.59 0 11-6.59 6.59 6.6 6.6 0 016.59-6.59zM26.69 21.35a5.218 5.218 0 006.54-3.43 5.239 5.239 0 00-.36-4 5.231 5.231 0 10-6.18 7.41v.02zm-2-6.1a3.73 3.73 0 014.67-2.45 3.729 3.729 0 01.667 6.908 3.73 3.73 0 01-2.886.212 3.67 3.67 0 01-2.19-1.82 3.77 3.77 0 01-.27-2.85h.01zM39.453 8.65a4.07 4.07 0 100-8.14 4.07 4.07 0 000 8.14zm0-6.63a2.57 2.57 0 110 5.14 2.57 2.57 0 010-5.14z'
        fill='#AFAFAF'
      />
    </svg>
  );
}

export default SvgBath;
