import * as React from 'react';

function SvgNoPreseUsb(props) {
  return (
    <svg viewBox='0 0 54 59' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M37.65 12.43h-6.29v6.29h2.14v4.87a1.85 1.85 0 01-.5 1.31l-7.47 7.47V6.56h2.57l-1.8-3.13L24.49.31l-1.8 3.12-1.8 3.13h2.6v32.65l-6.89-6.9a1.85 1.85 0 01-.54-1.31v-5.47a3.06 3.06 0 10-2 0v5.48a3.81 3.81 0 001.13 2.72l8.3 8.31v4.72a5.64 5.64 0 102 0V35.17h.08l8.8-8.86a3.811 3.811 0 001.13-2.72v-4.87h2.15v-6.29z'
        fill={props.color}
      />
      <path d='M50.12 6.51L0 55.446l3.095 3.17L53.215 9.68 50.12 6.51z' fill='#F0801A' />
    </svg>
  );
}

export default SvgNoPreseUsb;
