import * as React from "react";

function SvgOutlinedHeart(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 60 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M30.07 52a4.485 4.485 0 01-2.525-.908c-7.474-5.147-15.976-11.34-22.422-19.678C.35 25.254-1.097 18.872.82 12.594a17.1 17.1 0 014.547-7.677A17.49 17.49 0 0113.204.512c2.96-.766 6.073-.75 9.025.046a17.483 17.483 0 017.79 4.487 18.003 18.003 0 019.268-4.77 17.343 17.343 0 018.423.572 17.124 17.124 0 017.117 4.472 17.666 17.666 0 014.477 7.537c.83 2.84.927 5.84.28 8.727-1.743 7.858-7.126 13.348-11.534 17.156-3.31 2.917-6.864 5.61-10.296 8.218-1.742 1.321-3.484 2.625-5.227 3.963A4.036 4.036 0 0130.071 52zM17.668 3.536c-1.134 0-2.264.138-3.363.412A14.119 14.119 0 007.96 7.44a13.805 13.805 0 00-3.724 6.147c-1.586 5.284-.366 10.431 3.728 15.698 6.132 7.926 14.39 13.931 21.637 18.872.141.102.293.188.453.257 0 0 .122 0 .314-.223a529.697 529.697 0 015.226-3.997c3.398-2.574 6.97-5.233 10.105-8.063 4.286-3.758 8.868-8.578 10.453-15.44a14.063 14.063 0 00-.293-6.95 14.23 14.23 0 00-3.592-5.987 13.615 13.615 0 00-5.63-3.553 13.79 13.79 0 00-6.67-.478 14.868 14.868 0 00-8.71 5.147l-1.447 1.63-1.324-1.716-.4-.532a3.079 3.079 0 00-.349-.446 14.1 14.1 0 00-4.595-3.14 14.28 14.28 0 00-5.474-1.132z"
        fill={props.color}
      />
    </svg>
  );
}

export default SvgOutlinedHeart;
