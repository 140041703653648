import { makeStyles } from '@material-ui/core';
import ConfiguratorTwoColumnsPlanimetryLayout from '../../../layouts/ConfiguratorTwoColumnsPlanimetryLayout';
import DefaultConfigurazioneCucina from './currentConfiguration/DefaultConfigurazioneCucina';
import _ from 'lodash';
import { getRemoveImagesStartByLetter, prepareOptionData } from '../../../utils/optionsUtils';
import { useEffect, useRef, useState } from 'react';
import { useStore } from '../../../store/storeUtils';
import { getOption } from '../../../services/optionApi';
import { handleSave, singleChoiceSelection } from '../../../utils/selectionUtils';
import { useParams } from 'react-router';
import { getLayoutImagesByConfigurationId } from '../../../services/layoutApi';
import { resumeAnswerSimple } from '../../../utils/configuratorUtils';
import OutlineIconButton from '../../../components/Buttons/OutlineIconButton';
import { Camera } from '../../../components/Icons';
import KitchenCardSelector from '../../../components/configurator/KitchenCardSelector';
import ImageZoom from '../../../components/ImageZoom';

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: '#EEEEEE',
    paddingTop: '20px',
    '& .alert-badge': {
      marginBottom: '15px',
      '& span.orange': {
        color: theme.palette.primary.main,
      },
    },
  },
  list: { padding: '0 22px 22px' },
  alertMessage: { marginBottom: '5px' },
  kitchenPlanimetryButton: {
    backgroundColor: 'unset !important',
  },
}));

const FirstColumn = ({ defaultOption, onClick, selectedOptions, planimetryImages }) => {
  const classes = useStyles();

  const [showPlanimetry, setShowPlanimetry] = useState(false);
  const [kitchenPlanimetryImages, setKitchenPlanimetryImages] = useState([]);

  const option = defaultOption?.options[0];

  let planimetryImage = option?.fileLabels.filter((el) => el.isPlan).map((el) => el.url);
  planimetryImage = planimetryImage?.length > 0 ? planimetryImage[0] : '';

  const defaultLayoutCucina = {
    ...option,
    text: defaultOption?.options[0].description,
    planimetry: planimetryImage,
  };

  useEffect(() => {
    if (option) {
      let layers = getRemoveImagesStartByLetter(planimetryImages, 'K');
      layers = layers.map((el) => el.fileUrl);
      const kitchenLayer = option?.fileLabels
        .filter((el) => !el.isAxo && !el.isElettrico && !el.isPlan)
        .map((el) => el.url);

      setKitchenPlanimetryImages([...layers, ...kitchenLayer]);
    }
  }, [defaultOption]);

  return defaultOption ? (
    <>
      <DefaultConfigurazioneCucina
        {...defaultLayoutCucina}
        isSelected={_.isEqual(defaultOption.ids, selectedOptions)}
        onClick={() => {
          onClick(defaultOption.ids);
        }}
        infoRichText={option?.longDescription}
        infoRichTextButtonLabel={'Scopri le qualità di questa cucina'}
        actionButton={
          <OutlineIconButton
            className={classes.kitchenPlanimetryButton}
            onClick={() => setShowPlanimetry(true)}
            text={'Visualizza cucina ambientata nella planimetria'}
            icon={<Camera color='#878787' width='15px' height='15px' />}
          />
        }
        subtitle={'Conferma questa soluzione oppure scegli opzioni alternative a lato'}
      />
      {showPlanimetry && (
        <ImageZoom
          images={kitchenPlanimetryImages}
          isPlanimetry
          handleClose={() => setShowPlanimetry(false)}
          allowDownload
        />
      )}
    </>
  ) : (
    ''
  );
};

const SecondColumn = ({ options, selectedOptions, onClick, planimetryImages }) => {
  return (
    <KitchenCardSelector
      options={options}
      onClick={onClick}
      selectedOption={selectedOptions}
      planimetryImages={planimetryImages}
    />
  );
};

const LayoutCucina = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const [defaultOption, setDefaultOption] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [images, setImages] = useState([]);

  const substepIdRef = useRef();
  const oldAnswersRef = useRef();
  const baseImagesRef = useRef([]);

  const store = useStore();
  const params = useParams();

  useEffect(() => {
    store.setUserCanGoNext(false);
    prepareOptions();
  }, []);

  const prepareOptions = async () => {
    const substepId = store.getSubstepIdByFrontendId(store.currentSubstep);

    let optionData = await getOption(
      store.currentConfiguration?.layout?.id,
      substepId,
      params.configuration,
    );
    substepIdRef.current = optionData?.data.substep.id;

    optionData = prepareOptionData(optionData);

    // Imposto i layer da far vedere
    const layoutImagesResponse = await getLayoutImagesByConfigurationId(params.configuration);
    const layoutImages = layoutImagesResponse.data;

    baseImagesRef.current = layoutImages;

    setImages(layoutImages);

    setOptions(
      optionData
        .filter((el) => !el.isDefault)
        ?.sort((a, b) => a?.positions[0] - b?.positions[0]), // Ordinamento opzioni
    );
    const defaultOption = optionData.find((el) => el.isDefault);
    setDefaultOption(defaultOption);

    resumeAnswerSimple(store, oldAnswersRef, substepIdRef, setSelectedOptions, setIsLoading);
  };

  const handleUserSelection = (selection) => {
    singleChoiceSelection(selection, selectedOptions, setSelectedOptions, store);
  };

  useEffect(() => {
    const save = async () => {
      await handleSave(selectedOptions, params.configuration, substepIdRef.current, store);
    };

    if (!_.isEqual(oldAnswersRef.current, selectedOptions)) {
      store.setSubstepSave(save);
    } else {
      store.setSubstepSave(null);
    }
  }, [selectedOptions]);

  return (
    <ConfiguratorTwoColumnsPlanimetryLayout
      firstColumn={
        <FirstColumn
          defaultOption={defaultOption}
          selectedOptions={selectedOptions}
          onClick={handleUserSelection}
          planimetryImages={images}
        />
      }
      secondColumn={
        <SecondColumn
          options={options}
          selectedOptions={selectedOptions}
          onClick={handleUserSelection}
          planimetryImages={images}
        />
      }
      hidePlanimetry
      isLoading={isLoading}
      firstRowTitle={'IL TUO APPARTAMENTO'}
      secondRowTitle={'CONFIGURAZIONE 0'}
      secondColumnFirstRowTitle={'SOLUZIONI'}
      secondColumnSecondRowTitle={'ALTERNATIVE'}
      noOption={options?.length === 0}
      noOptionText={'Non ci sono layout alternativi per la cucina.'}
    />
  );
};

export default LayoutCucina;
