import { Box, Grid, makeStyles, Modal, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useHistory, useRouteMatch, useParams } from 'react-router';
import { useStore } from '../../store/storeUtils';
import Button3D from '../Buttons/Button3D';
import { Play } from '../Icons';
import SvgLeftChevron from '../Icons/LeftChevron';
import InfoPopup, { WarningContent } from '../InfoPopup';
import MenuItem from './MenuItem';
import { getNextSubStep } from '../../services/phaseApi';

const collapsedWidth = '80px';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '290px',
    height: '100%',
    backgroundColor: '#F9F9F9',
    border: `2px solid ${theme.palette.color.white}`,
    borderTopRightRadius: '25px',
    transition: 'width 200ms',
    zIndex: theme.zIndex.mobileStepper,
    overflow: 'hidden auto',
    '&.collapsed': {
      width: collapsedWidth,
    },
    [theme.breakpoints.down('lg')]: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    paddingBottom: '200px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    height: '60px',
    fontWeight: 400,
    fontStyle: 'italic',
    color: theme.palette.color.grey2,
  },
  menuToggle: {
    height: '60px',
    background: '#CDC7C1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '26px',
    borderRadius: '30px 0 0 30px',
    position: 'absolute',
    left: '-235px',
    transition: 'left 200ms',
    zIndex: theme.zIndex.mobileStepper,
    '& svg': {
      transform: 'rotate(180deg)',
    },
    '&.collapsed': {
      left: '-23px',
      '& svg': {
        transform: 'none',
      },
    },

    [theme.breakpoints.up('xl')]: {
      display: 'none',
    },
  },
  nextButtonRoot: {
    position: 'absolute',
    bottom: '0',
    left: 0,
    width: '100%',
    pointerEvents: 'none',
    height: '246px',
    [theme.breakpoints.down('lg')]: {
      position: 'absolute',
      right: 0,
      left: 'unset',
      width: '290px',
      transition: 'width 200ms',

      '&.collapsed': {
        width: '100%',
      },
    },
    zIndex: theme.zIndex.mobileStepper + 1,
  },
  nextButtonContainer: {
    width: '100%',
    background: '#F9F9F9',
    padding: '25px 15px 15px',
    pointerEvents: 'auto',
    position: 'absolute',
    bottom: 0,
    color: '#5E5E5E',
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '14px',
    [theme.breakpoints.down('lg')]: {
      padding: '12px',
      fontSize: 11,
    },
  },
  button: {
    width: '100%',
    maxWidth: 'unset !important',
    height: '40px !important',
    marginBottom: '10px',
    fontSize: '13px',
    '& svg': {
      display: 'none',
    },
    '&.collapsed': {
      height: '56px !important',
      padding: 'unset',
      '& svg': {
        display: 'initial',
      },
    },
  },
  shadow: {
    position: 'absolute',
    bottom: 0,
    height: '100%',
    width: '100%',
    background: 'linear-gradient(180deg, rgba(249, 249, 249, 0) -3.81%, #E9E6E1 66%)',
  },
}));

const SideMenu = observer(() => {
  const classes = useStyles();
  const theme = useTheme();
  const collapsible = useMediaQuery(theme.breakpoints.down('lg'));
  const history = useHistory();
  const { url } = useRouteMatch();
  const store = useStore();
  const phase = store.getCurrentPhaseData();
  const { configuration } = useParams();
  const isNew = phase?.order === 30 || phase?.order === 40;

  const [isCollapsed, setCollapsed] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  // Se il menu diventa non collassabile, lo riporto alla grandezza estesa
  useEffect(() => {
    if(!collapsible) {
      setCollapsed(false);
    }
  }, [collapsible]);

  const handleNextButtonClick = async () => {
    if (store.substepSave) {
      const resumeInfo = store.getResumeInfo();
      const currentSubstep = store.currentSubstep;
      if(((resumeInfo?.substep?.roomId && resumeInfo?.phase?.id !== 4) ?
            (resumeInfo?.substep?.frontendId - 1 > currentSubstep && store.currentRoomId !== resumeInfo?.substep?.roomId) :
            (resumeInfo?.substep?.frontendId - 1 > currentSubstep)
          )
          || resumeInfo?.allCompleted)
      {
        setShowConfirmModal(true);
      } else {
        handleSaveAndNext();
      }
    } else {
      goNext();
      store.setUserCanGoNext(false);
    }
  };

  const handleSaveAndNext = async () => {
    setShowConfirmModal(false);
    store.setUserCanGoNext(false);
    await store.substepSave();
    goNext();
  };

  const goNext = async () => {
    let nextInfo;
    if (isNew) {
      const nextSubstepData = await getNextSubStep(configuration, store.currentSubstepId, store.currentRoomId);
      const isCompleted = nextSubstepData?.data?.phaseId !== phase?.id;
      if (isCompleted) {
        nextInfo = 'completed';
      } else nextInfo = store.getNextInfo();
    } else nextInfo = store.getNextInfo();
    if (nextInfo === 'completed') {
      const nextPhase = store.getNextPhase();
      const currentPhaseKey = store?.getCurrentPhaseData()?.key;
      const newUrl = `${url.replace(`/${currentPhaseKey}`, '')}?completedPhaseId=${store.currentPhase}`;
      store.setCurrentPhase(nextPhase.order);
      store.setCurrentStep('');
      store.setCurrentSubstep('');
      store.setCurrentSubstepId('');
      history.push(`${newUrl}`);
    } else {
      if (isNew) {
        const nextSubstepData = await getNextSubStep(configuration, store.currentSubstepId, store.currentRoomId);
        store.setCurrentStep(nextSubstepData?.data?.stepId);
        const step = phase?.steps.find((el) => (nextSubstepData?.data?.roomId && phase?.order === 40) ? (nextSubstepData?.data?.roomId === el.roomId && el.id === nextSubstepData?.data?.stepId) :(el.id === nextSubstepData?.data?.stepId));
        const substep = step?.substeps?.find ((el) => el.id === nextSubstepData?.data?.substepId);
        store.setCurrentSubstep(substep?.frontendId);
        store.setCurrentSubstepId(nextSubstepData?.data?.substepId);
        nextSubstepData?.data?.roomId ? store.setCurrentRoomId(nextSubstepData?.data?.roomId) : store.setCurrentRoomId('');
        history.push(`${url}/${step?.key}/${substep?.key}`);
      } else {
        store.setCurrentStep(nextInfo.nextStep.id);
        store.setCurrentSubstep(nextInfo.nextSubstep.frontendId);
        store.setCurrentSubstepId(nextInfo.nextSubstep.id);
        history.push(`${url}/${nextInfo.nextStep.key}/${nextInfo.nextSubstep.key}`);
      }
    }
  };
  const roomsPhase = phase?.roomsId;
  const isLavanderia = phase?.name === 'BAGNO' && phase?.steps?.filter(step => step?.name === 'LAVANDERIA').length > 0;
  let roomsStepsArray = [];
  
  // let roomsSteps = {};
  if(roomsPhase) {
    roomsPhase.forEach((room, index) => {
      let roomsStep = {};
      const steps = phase.steps.filter((step) => step.roomId === room);
      roomsStep.hideInMenu = steps.every((el) => el.hideInMenu);
      roomsStep.id = phase?.id;
      roomsStep.isCompleted = steps.every((el) => el.isCompleted);
      roomsStep.key = 'BAGNO ' + (index + 1);
      roomsStep.name = 'BAGNO ' + (index + 1);
      roomsStep.order = phase?.order;
      roomsStep.roomId = room;
      roomsStep.substeps = steps;
      if(steps) roomsStepsArray.push(roomsStep);
    })
  }
  if (isLavanderia) {
    const lavanderiaStep = phase?.steps?.find(step => step?.name === 'LAVANDERIA');
    roomsStepsArray.push(lavanderiaStep);
  }
  const isBagno = roomsStepsArray.length > 0;

  /*
  const renderNestedMenu = () => {
    const roomNumber = roomsId?.findIndex((room) => room === roomId);
    const [expanded, setExpanded] = useState(false);
    const currentStep = store.currentStep;
    const currentRoomId = store.currentRoomId;
    const isExpandable = substeps?.length > 1;
    const isSingleSubstep = substeps?.length === 1;
    const isSubstepsCompleted = substeps ? !substeps.find((el) => !el.isAnswered) : false;
    const hasSubstepAnswered = substeps ? substeps.find((el) => el.isAnswered) : false;
    const isActive =
      (store.currentStep === id && (isBagnoPhase ? currentRoomId === roomId : true)) || (store.currentStepId === id && (isBagnoPhase ? currentRoomId === roomId : true)) ||
      // store.currentStep === order ||
      (store.currentStep === parentStep && store.currentSubstep === frontendId && (isBagnoPhase ? currentRoomId === roomId : true));

    const status = isAnswered || isSubstepsCompleted ? 'completed' : isActive ? '' : 'blocked';
    const iCanSeeRoom = isBagnoPhase ? currentRoomId >= roomId : true;
    return (
    <Accordion
    expanded={expanded || isActive}
    elevation={0}
    onChange={isExpandable || isSingleSubstep ? handleChange : null}
  >
    <AccordionSummary
      className={clsx(
        classes.root,
        isCollapsed && 'collapsed',
        isActive && 'active',
        isSingleSubstep && status,
      )}
      classes={{ content: clsx(classes.summaryContent, isCollapsed && 'collapsed') }}
      aria-controls='accordion-header'
      id='accordion-header'
    >
      <span
        className={clsx(
          subLevelClass ? subLevelClass : classes.status,
          status,
          isCollapsed && 'collapsed',
          isActive && 'active',
        )}
      ></span>
      <div className={clsx(classes.titleContainer, isCollapsed && 'collapsed')}>
        <span
          className={clsx(
            classes.title,
            status,
            isCollapsed && 'collapsed',
            !isExpandable && 'not-expandable',
            isActive && 'active',
          )}
        >
          {'BAGNO ' + (roomNumber !== -1 ? roomNumber + 1 : 1)}
        </span>
        {isExpandable && (
          <SvgChevron
            className={clsx(classes.indicator, (expanded || isActive) && 'expanded')}
            color={
              status === 'blocked' ? theme.palette.color.grey2 : theme.palette.primary.main
            }
          />
        )}
      </div>
    </AccordionSummary>
    {renderClassicMenu()}
    </Accordion>
    )
  }
  */

  return (
    <>
      <Box position='relative' height='100%'>
        <div
          className={clsx(classes.menuToggle, isCollapsed && 'collapsed')}
          onClick={
            collapsible
              ? () => {
                  setCollapsed((el) => !el);
                }
              : null
          }
        >
          <SvgLeftChevron color='#FFF' height='13px' width='13px' />
        </div>
        {collapsible && <Box width={collapsedWidth} />}
        <div className={clsx(classes.root, isCollapsed && 'collapsed')}>
          <span className={classes.header}>{isCollapsed ? <>Men&ugrave;</> : ''}</span>
          
          {isBagno && roomsStepsArray?.map((el, i) => (<MenuItem key={i} itemKey={el.key} {...el} isCollapsed={isCollapsed} isNew={isNew} roomsId={phase?.roomsId} isBagnoPhase={phase?.order === 40} />))
          /* phase?.steps.map((el, i) => (
            <MenuItem key={i} itemKey={el.key} {...el} isCollapsed={isCollapsed} isNew={isNew} roomsId={phase?.roomsId} isBagnoPhase={phase?.order === 40} />
          ))*/}
          {!isBagno && phase?.steps.map((el, i) => (
            <MenuItem key={i} itemKey={el.key} {...el} isCollapsed={isCollapsed} isNew={isNew} roomsId={phase?.roomsId} isBagnoPhase={phase?.order === 40} />))
          }
        </div>

        <div className={clsx(classes.nextButtonRoot, isCollapsed && 'collapsed')}>
          <div className={classes.shadow} />
          <div className={classes.nextButtonContainer}>
            <Button3D
              text={isCollapsed ? '' : 'PROSEGUI'}
              icon={<Play color='#FFF' height='20px' width='13px' />}
              className={clsx(classes.button, isCollapsed && 'collapsed')}
              onClick={handleNextButtonClick}
              disabled={!store.userCanGoNext}
            />
            <Grid container>
              <Grid item xs>
                Completa le scelte per continuare
              </Grid>
            </Grid>
          </div>
        </div>
      </Box>
      <Modal open={showConfirmModal} onClose={() => setShowConfirmModal(false)}>
        <InfoPopup
          body={
            <WarningContent
              handleSubmit={handleSaveAndNext}
              handleCancel={() => setShowConfirmModal(false)}
            />
          }
          noMaxWidth
          isWarning
          position='center'
          handleClose={() => setShowConfirmModal(false)}
        />
      </Modal>
    </>
  );
});

export default SideMenu;
