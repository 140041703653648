import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import OutlineIconButton from '../components/Buttons/OutlineIconButton';
import ConfiguratorIntro from '../components/configurator/ConfiguratorIntro';
import ConfiguratorColumnTitle from '../components/Labels/ConfiguratorColumnTitle';
import LoadingAnimation from '../components/LoadingAnimation';
import PlanimetryZoom from '../components/PlanimetryZoom';
import SurfaceMeters from '../components/SurfaceMeters';
import { FAKE_LOADING_MILLISECONDS } from '../config';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',

    '& .title-first-column': {
      flexBasis: '40%',

      backgroundColor: '#FFF',
      padding: '22px 0 0 22px',
      '&.full-width': {
        flexBasis: '100%',
      },
    },
    '& .title-second-column': {
      flexBasis: '60%',
      backgroundColor: '#FFF',
      padding: '22px 22px 0 22px',
    },

    '& .column-container': {
      overflow: 'hidden',
      '& > div': {
        gap: 16,
      },
    },

    '& .first-column': {
      flexBasis: '40%',
      backgroundColor: '#FFF',
      padding: '0 22px 22px',
      height: '100%',
      overflow: 'hidden auto',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      '&.full-width': {
        flexBasis: '100%',
      },
    },
    '& .second-column': {
      flexBasis: '60%',
      backgroundColor: '#FFF',
      padding: '0 22px 22px',
      overflow: 'hidden auto',
      height: '100%',
      '&.no-padding-bottom': {
        paddingBottom: 0,
      },
    },
    '& .no-options-container': {
      backgroundColor: '#EEEEEE',
      height: '100%',
      padding: '0 80px',
      '& > div': {
        marginTop: '-120px',
      },
    },
    '& .planimetry-button': {
      position: 'absolute',
      top: 0,
    },
    '& .default-option': {
      flexGrow: 1,
    },
  },
  titleContainer: {
    '& > div': {
      gap: 16,
    },
  },
  fullHeight: {
    height: '100%',
  },
  planimetry: {
    position: 'relative',
    height: '50%',
    borderBottom: '1px solid #999',
    marginBottom: '12px',
    position: 'relative',
    flexShrink: 0,
  },
  planimetrySurfaces: {
    position: 'absolute',
    top: '30px',
    left: 0,
    '& .surface': {
      marginBottom: '20px',
      backgroundColor: theme.palette.color.white,
    },
  },
}));

const ConfiguratorTwoColumnsPlanimetryLayout = ({
  firstRowTitle,
  secondRowTitle,
  firstColumn,
  secondColumn,
  secondColumnFirstRowTitle,
  secondColumnSecondRowTitle,
  planimetryImages,
  noSecondColumnPaddingBottom,
  isLoading,
  showSurfaces,
  superficieTotalePostVendita = 0,
  superficieTerrazzoPostVendita = 0,
  noOption,
  noOptionIcon,
  noOptionText,
  planimetryButtonOnClick,
  planimetryButtonText,
  planimetryButtonIcon,
  optionLayersOnTop,
  hidePlanimetry,
  hideSecondColumn,
  badgeText,
}) => {
  const classes = useStyles();

  const [showFakeLoading, setShowFakeLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowFakeLoading(false);
    }, FAKE_LOADING_MILLISECONDS);
  }, []);

  return (
    <Grid container direction='column' wrap='nowrap' className={classes.root}>
      <Grid item xs='auto' className={classes.titleContainer}>
        <Grid container wrap='nowrap'>
          <Grid item className={clsx('title-first-column', hideSecondColumn && 'full-width')}>
            <ConfiguratorColumnTitle firstRow={firstRowTitle} secondRow={secondRowTitle} />
          </Grid>
          {!hideSecondColumn && (
            <Grid item className={clsx('title-second-column')}>
              <ConfiguratorColumnTitle
                firstRow={secondColumnFirstRowTitle}
                secondRow={secondColumnSecondRowTitle}
                hide={!showFakeLoading && !isLoading && noOption}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs className={clsx('column-container')}>
        <Grid container wrap='nowrap' className={clsx(classes.fullHeight)}>
          <Grid item className={clsx('first-column', hideSecondColumn && 'full-width')}>
            {!hidePlanimetry && (
              <div className={classes.planimetry}>
                <PlanimetryZoom
                  images={planimetryImages}
                  allowDownload
                  optionLayersOnTop={optionLayersOnTop}
                />
                {showSurfaces && (
                  <div className={classes.planimetrySurfaces}>
                    <div className={'surface'}>
                      <SurfaceMeters
                        label='Superficie Totale'
                        meters={Math.trunc(superficieTotalePostVendita)}
                        smallText
                      />
                    </div>
                    <div className={'surface'}>
                      <SurfaceMeters
                        label='loggia'
                        meters={Math.trunc(superficieTerrazzoPostVendita)}
                        smallText
                      />
                    </div>
                  </div>
                )}
                {planimetryButtonOnClick && (
                  <OutlineIconButton
                    className={'planimetry-button'}
                    onClick={planimetryButtonOnClick}
                    text={planimetryButtonText}
                    icon={planimetryButtonIcon}
                  />
                )}
              </div>
            )}

            <div className='default-option'>{firstColumn}</div>
          </Grid>
          {!hideSecondColumn && (
            <Grid
              item
              className={clsx('second-column', noSecondColumnPaddingBottom && 'no-padding-bottom')}
            >
              {showFakeLoading || isLoading ? (
                <LoadingAnimation />
              ) : noOption ? (
                <Grid
                  container
                  alignItems='center'
                  justifyContent='center'
                  className='no-options-container'
                >
                  <Grid item xs>
                    <ConfiguratorIntro
                      icon={noOptionIcon}
                      title={noOptionText}
                      badgeText={badgeText}
                    />
                  </Grid>
                </Grid>
              ) : (
                secondColumn
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConfiguratorTwoColumnsPlanimetryLayout;
