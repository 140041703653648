import _ from 'lodash';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { Camera } from '../../../components/Icons';
import ImageZoom from '../../../components/ImageZoom';
import LayoutCardSelector from '../../../components/configurator/LayoutCardSelector';
import ConfiguratorTwoColumnsPlanimetryLayout from '../../../layouts/ConfiguratorTwoColumnsPlanimetryLayout';
import { saveConfigurationLayout } from '../../../services/configurationApi';
import { getLayout, getLayoutImagesById } from '../../../services/layoutApi';
import { useStore } from '../../../store/storeUtils';
import { getConfiguratorPhases } from '../../ConfiguratorPage';
import CurrentSceltaLayout from './currentConfiguration/CurrentSceltaLayout';

const FirstColumn = ({ defaultOption, onClick, selectedOption, noOptions }) => {
  return (
    <CurrentSceltaLayout
      defaultItem={defaultOption}
      isSelected={defaultOption?.id === selectedOption}
      onClick={() => {
        onClick(defaultOption?.id);
      }}
      infoRichText={defaultOption?.longDescription}
      infoRichTextButtonLabel={'Scopri le qualità di questo layout'}
      hideButton={noOptions}
      subtitle={
        noOptions ? '' : 'Conferma questa soluzione oppure scegli opzioni alternative a lato'
      }
    />
  );
};

const SecondColumn = ({ options, selectedOption, onClick }) => {
  return <LayoutCardSelector layouts={options} onClick={onClick} selectedOption={selectedOption} />;
};

const SceltaLayout = observer(() => {
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const [defaultOption, setDefaultOption] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [images, setImages] = useState([]);
  const [imagesWithQuotes, setImagesWithQuotes] = useState([]);
  const [showQuotedPlanimetry, setShowQuotedPlanimetry] = useState(false);

  const store = useStore();
  const params = useParams();

  const oldAnswersRef = useRef();

  useEffect(() => {
    store.setUserCanGoNext(false);
    prepareOptions();
  }, []);

  const prepareOptions = async () => {
    const optionData = await getLayout(params.configuration);

    if (optionData?.data?.length > 0) {
      const options = optionData?.data
        .filter((el) => !el.isDefaultLayout)
        .sort((a, b) => {
          if (a.layoutCode === b.layoutCode) {
            if (!a.layoutVariantCode) {
              return -1;
            }
            return 1;
          }
          return a.layoutCode - b.layoutCode;
        });
      setOptions(options);

      const defaultOption = optionData?.data.find((el) => el.isDefaultLayout);
      setDefaultOption(defaultOption);

      const layoutImagesResponse = await getLayoutImagesById(defaultOption.id);
      const layoutImages = layoutImagesResponse.data;

      setImages(layoutImages);

      setImagesWithQuotes(
        layoutImages
          .map((el) => el.fileUrl)
          .concat(defaultOption?.tags.find((el) => el.key === 'QUOTE')?.fileLabels[0]?.url),
      );

      if (options?.length === 0) {
        setSelectedOption(defaultOption.id);
        store.setUserCanGoNext(true);
      }

      resumeAnswer();
    } else {
      setIsLoading(false);
      setOptions([]);
    }
  };

  const resumeAnswer = async () => {
    const layout = store.currentConfiguration?.layout;

    if (layout) {
      oldAnswersRef.current = layout.id;

      setSelectedOption(layout.id);
      store.setUserCanGoNext(true);
    }

    setIsLoading(false);
  };

  const handleUserSelection = (id) => {
    let newLayoutId = '';

    // Se clicco su un valore diverso dal precedente, lo assegno
    // se è la stessa opzione resetto la selezione
    if (!_.isEqual(id, selectedOption)) {
      // Altrimenti aggiunge le opzioni
      newLayoutId = id;
    }
    setSelectedOption(newLayoutId);

    if (newLayoutId) {
      store.setUserCanGoNext(true);
    } else {
      store.setUserCanGoNext(false);
    }
  };

  useEffect(() => {
    const save = async () => {
      try {
        const currentConfigurationUpdated = await saveConfigurationLayout(
          params.configuration,
          selectedOption,
        );

        store.setLayoutAnswered(1);
        store.setCurrentConfiguration(currentConfigurationUpdated.data);
        store.setSubstepSave(null);

        const phases = await getConfiguratorPhases(params.configuration, store);
        store.setPhases(phases);

        // Resetto le answer poichè ho cambiato il layout
        store.setAnswers([]);
      } catch (e) {
        console.log(e);
      }
    };

    if (!_.isEqual(oldAnswersRef.current, selectedOption)) {
      store.setSubstepSave(save);
    } else {
      store.setSubstepSave(null);
    }
  }, [selectedOption]);

  const handleShowQuotedPlanimetry = () => {
    setShowQuotedPlanimetry(true);
  };

  return (
    <>
      <ConfiguratorTwoColumnsPlanimetryLayout
        planimetryImages={images}
        planimetryButtonOnClick={handleShowQuotedPlanimetry}
        planimetryButtonText={'Visualizza planimetria quotata'}
        planimetryButtonIcon={<Camera color='#878787' width='15px' height='15px' />}
        superficieTotalePostVendita={store.currentApartment?.superficieTotalePostVendita}
        superficieTerrazzoPostVendita={store.currentApartment?.superficieTerrazzoPostVendita}
        firstColumn={
          <FirstColumn
            defaultOption={defaultOption}
            selectedOption={selectedOption}
            onClick={handleUserSelection}
            noOptions={options?.length === 0}
          />
        }
        secondColumn={
          <SecondColumn
            options={options}
            selectedOption={selectedOption}
            onClick={handleUserSelection}
          />
        }
        firstRowTitle={'Il tuo appartamento'}
        secondRowTitle={'layout a'}
        secondColumnFirstRowTitle={'SOLUZIONI'}
        secondColumnSecondRowTitle={'ALTERNATIVE'}
        isLoading={isLoading}
        hideSecondColumn={!isLoading && options?.length === 0}
        noOption={!options}
        noOptionText={'Non sono presenti layout per questo appartamento.'}
      />
      {showQuotedPlanimetry && (
        <ImageZoom
          images={imagesWithQuotes}
          isPlanimetry
          handleClose={() => setShowQuotedPlanimetry(false)}
          allowDownload
        />
      )}
    </>
  );
});

export default SceltaLayout;
