import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    textAlign: 'center',
    fontFamily: 'miller',
    fontStyle: 'italic',
    fontSize: '30px',
    lineHeight: '36px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '25px',
      lineHeight: '30px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
      lineHeight: '22px',
    },
  },
  cheers: {
    color: theme.palette.primary.main,
  },
}));

const ConfigurableMessage = ({ orangeText, text }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <span className={classes.cheers}>{orangeText} </span>
      <span>{text}</span>
    </div>
  );
};

export default ConfigurableMessage;
