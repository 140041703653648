import * as React from 'react';

function SvgAngoloCottura(props) {
  return (
    <svg viewBox='0 0 45 46' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.903 1.081a1.08 1.08 0 01-1.081 1.08h-7.05a1.081 1.081 0 010-2.161h7.05a1.08 1.08 0 011.08 1.081zm20.386 0c0 .597-.484 1.08-1.08 1.08h-7.05a1.08 1.08 0 110-2.161h7.05c.596 0 1.08.484 1.08 1.081zM0 10.145v29.44A5.523 5.523 0 005.515 45.1H39.47a5.523 5.523 0 005.515-5.516v-29.44A5.522 5.522 0 0039.47 4.63H5.515A5.523 5.523 0 000 10.145zm39.47 32.794H5.514a3.359 3.359 0 01-3.353-3.354V16.303h40.662v23.282a3.359 3.359 0 01-3.353 3.354zm3.353-32.794v3.996H2.162v-3.996A3.359 3.359 0 015.514 6.79h33.957a3.356 3.356 0 013.353 3.354zM9.39 12.07a1.604 1.604 0 100-3.208 1.604 1.604 0 000 3.208zm10.343-1.604a1.604 1.604 0 11-3.208 0 1.604 1.604 0 013.208 0zm7.126 1.604a1.604 1.604 0 100-3.209 1.604 1.604 0 000 3.209zM37.2 10.467a1.604 1.604 0 11-3.208 0 1.604 1.604 0 013.208 0zM7.764 21.54h29.46a1.08 1.08 0 11.024 2.162v12.323a2.9 2.9 0 01-2.899 2.898H10.64a2.9 2.9 0 01-2.898-2.898V23.702a1.08 1.08 0 11.023-2.162zM34.35 36.755c.406 0 .736-.33.736-.737V23.702H9.903v12.316c0 .407.33.736.736.736h23.71z'
        fill='#C4C4C4'
      />
    </svg>
  );
}

export default SvgAngoloCottura;
