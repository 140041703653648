import { Grid, makeStyles } from '@material-ui/core';
import ImageZoomSlider from '../../../../components/ImageZoomSlider';
import ConfigurationLayout from '../../../../components/Layout/ConfigurationLayout';
import WatchPlanimetry from '../../../../components/WatchPlanimetry';

const useStyles = makeStyles((theme) => ({
  defaultConfigurazioneCucinaRoot: {
    height: '100%',
    width: '100%',
  },
  firstColumn: {
    height: '100%',
    paddingRight: '10px',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    },
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '15px',
    '& span': {
      fontWeight: 600,
      textTransform: 'uppercase',
    },
  },
  planimetryContainer: {
    height: '50%',
  },
  sliderContainer: { position: 'relative' },
}));

const DefaultConfigurazioneCucina = ({
  text,
  planimetry,
  isSelected,
  onClick,
  fileLabels,
  message,
  actionButton,
  infoRichText,
  subtitle,
}) => {
  const classes = useStyles();
  const images = fileLabels.filter((el) => el.isAxo).map((el) => el.url);

  const currentConfiguration = null;

  return (
    <ConfigurationLayout
      title={'la tua '}
      boldTitle={' configurazione di partenza'}
      buttonText='CONFERMA QUESTA SCELTA'
      isSelected={isSelected}
      onClick={onClick}
      message={message}
      actionButton={actionButton}
      infoRichText={infoRichText}
      subtitle={subtitle}
    >
      {currentConfiguration ? (
        ''
      ) : (
        <Grid container className={classes.defaultConfigurazioneCucinaRoot} direction='column'>
          <Grid item xs className={classes.planimetryContainer}>
            <WatchPlanimetry image={planimetry} />
          </Grid>
          <Grid item xs className={classes.sliderContainer}>
            <ImageZoomSlider images={images} />
          </Grid>
        </Grid>
      )}
    </ConfigurationLayout>
  );
};

export default DefaultConfigurazioneCucina;
