import * as React from 'react';

function SvgUsbCabinaArmadio(props) {
  return (
    <svg viewBox='0 0 55 42' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M30.27 18.09l7.731 5.585v3.066l-10.12-7.321a.329.329 0 01-.13-.26v-5.68a.32.32 0 01.32-.32h.93a7.25 7.25 0 002.64-.72 4.85 4.85 0 002.45-4.61 4.7 4.7 0 00-4.74-4.33 5.34 5.34 0 00-6 4.49 1.16 1.16 0 01-.53.81 1.14 1.14 0 01-.95.15 1.18 1.18 0 01-.8-.52 1.15 1.15 0 01-.13-.92 7.81 7.81 0 018.68-6.45 7.09 7.09 0 017 6.74 7.09 7.09 0 01-3.8 6.8 9.471 9.471 0 01-2.55.82v2.67zM3.762 39.54h34.24v2.41H3.691a3.63 3.63 0 01-3.52-2.51 3.48 3.48 0 011.49-4l21.6-14.11a1.32 1.32 0 011.71.27 1.26 1.26 0 01-.27 1.7L3.081 37.43a1.06 1.06 0 00-.44 1.29 1.18 1.18 0 001.12.82zm48.296-20.442H55v3.164h-1.006v2.45c0 .255-.045.507-.136.742-.091.235-.224.448-.392.627l-4.117 4.458h-.038v5.83c.649.127 1.23.509 1.63 1.072.4.564.59 1.268.53 1.974a2.922 2.922 0 01-.847 1.841 2.539 2.539 0 01-1.78.744c-.66 0-1.295-.265-1.782-.744a2.922 2.922 0 01-.847-1.841c-.059-.706.13-1.41.53-1.974.4-.563.982-.946 1.63-1.071v-2.375l-3.883-4.18a1.932 1.932 0 01-.392-.628 2.046 2.046 0 01-.136-.741v-2.757a1.459 1.459 0 01-.763-.67 1.637 1.637 0 01-.181-1.04 1.57 1.57 0 01.487-.92c.258-.236.586-.365.925-.365.338 0 .666.13.924.364.259.235.431.561.487.92.056.36-.008.728-.18 1.041-.173.314-.444.55-.764.67v2.752c0 .122.022.243.066.356a.942.942 0 00.187.303l3.223 3.471V16.145H47.16l.842-1.575.842-1.57.847 1.57.842 1.575H49.33V29.13l3.495-3.758a.972.972 0 00.233-.66v-2.45h-1v-3.164z'
        fill='#C4C4C4'
      />
    </svg>
  );
}

export default SvgUsbCabinaArmadio;
