import { useState, useEffect } from 'react';
import { Grid, makeStyles, Divider } from '@material-ui/core';
import { LeftChevron, Ruler, ArrowBack } from './Icons';
import ConfiguratorColumnTitle from './Labels/ConfiguratorColumnTitle';
import clsx from 'clsx';
import Slider from './Slider';
import PdfViewer from './PdfViewer';

const useStyles = makeStyles((theme) => ({
  materialDataSheetContainer: {
    height: '100%',
  },
  materialDataSheetRoot: {
    height: '100%',
    padding: '17px',
    backgroundColor: '#F0EDEB',
		width: '100%',
    borderRadius: '8px',

    sliderImage: {
      height: '100%',
      objectFit: 'contain',
      color: '#FFF',
    },
    '& .material': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      //position: 'relative',
      //width: '100%',
      //height: '100%',
      //padding: '0 20px 20px',
      '& .selectionPreview': {
        position: 'absolute',
        bottom: '20px',
        left: 0,
        width: '150px',
        height: '150px',
        padding: '20px',
        backgroundColor: theme.palette.color.white,
        [theme.breakpoints.down('lg')]: {
          bottom: '10px',
          width: '120px',
          height: '120px',
          padding: '10px',
        },
        [theme.breakpoints.down('md')]: {
          width: '90px',
          height: '90px',
        },
        '& img': {
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          borderRadius: '5px',
        },
      },
      '& > div': {
        width: '100%',
        height: '100%',
        backgroundSize: 'contain',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
      },
    },
    '& .previewImage': {
      border: '1px dashed  #C4C4C4',
      paddingLeft: '20%',
      paddingRight: '20%',
      textAlign: 'center',
      alignSelf: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '80%',
    },
    '& .schedaTecnica': {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      cursor: 'pointer',
    },
    '& .data-sheet-double': {
      backgroundColor: '#F0EDEB',
      alignItems: 'stretch',
      '& .data-half-part > div': {
        width: '1px',
        borderLeft: '1px solid #C4C4C4',
        marginLeft: 'auto',
        height: '105%',
        marginRight: 'auto',
        marginTop:'-30%'
      },
      '& .data-first-part > div': {
        border: '1px solid #C4C4C4',
        [theme.breakpoints.down('lg')]: {
          height: '100%',
        },
      },
      '& .data-second-part': {
        borderLeft: '1px solid #C4C4C4',
        border: '1px solid #C4C4C4',
        '& > div': {
          height: '100%',
        },
      },
      '& .header': {
        gridArea: 'header',
        padding: '10px',
        fontWeight: '500',
        fontSize: '15px',
        lineHeight: '16px',
        textTransform: 'uppercase',
        color: '#000',
        borderBottom: '1px solid #C4C4C4',
        [theme.breakpoints.down('md')]: {
          fontSize: '11px',
          lineHeight: '13px',
        },
        '& div': {
          fontSize: 10,
        },
        '& span': {
          fontWeight: 700,
        },
      },
      '& .central': {
        gridArea: 'central',
        padding: '16px 12px 8px',
        fontWeight: '500',
        fontSize: '13px',
        lineHeight: '16px',
        textTransform: 'uppercase',
        color: '#000',
        [theme.breakpoints.down('lg')]: {
          borderBottom: '1px solid #C4C4C4',
          padding: '10px 12px 8px',
        },
        '& span': {
          fontWeight: 600,
          fontSize: 11,
        },
        [theme.breakpoints.down('md')]: {
          fontSize: '11px',
          lineHeight: '13px',
        },
        '& .specification': {
          paddingLeft: '16px',
          fontWeight: '500',
          fontSize: '13px',
          lineHeight: '16px',
          color: '#999',
          textTransform: 'initial',
          margin: '5px 10px 0',
          [theme.breakpoints.down('md')]: {
            fontSize: '11px',
            lineHeight: '13px',
          },
          '& li::marker': {
            color: '#000',
          },
        },
        '& .more-info': {
          textTransform: 'initial',
        },
      },
      '& .side': {
        gridArea: 'side',
        padding: '10px 12px',
        fontWeight: '500',
        fontSize: '13px',
        lineHeight: '16px',
        textTransform: 'uppercase',
        color: '#000',
        borderBottom: '1px solid #C4C4C4',
        [theme.breakpoints.down('lg')]: {
          fontSize: '11px',
          lineHeight: '13px',
          borderLeft: 0,
        },
        '& span': {
          fontWeight: 600,
          fontSize: '11px',
        },
        '& .image': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '6px auto 0',
          [theme.breakpoints.down('sm')]: {
            display: 'none',
          },
          [theme.breakpoints.down('lg')]: {
            '& svg': {
              height: 40,
              width: 'auto',
            },
          },
        },
      },
      '& .side-bottom': {
        gridArea: 'side-bottom',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '6px',
        padding: '8px',
        fontWeight: '500',
        fontSize: '13px',
        lineHeight: '16px',
        color: '#999',
        borderLeft: '0px solid #C4C4C4',
        cursor: 'pointer',
        [theme.breakpoints.down('md')]: {
          fontSize: '11px',
          lineHeight: '13px',
        },
      },
    },
    '& .data-sheet': {
      border: '1px solid #C4C4C4',
      backgroundColor: '#F0EDEB',
      '& .data-first-part > div': {
        [theme.breakpoints.down('lg')]: {
          height: '100%',
        },
      },
      '& .data-second-part': {
        borderLeft: '1px solid #C4C4C4',
        '& > div': {
          height: '100%',
        },
      },
      '& .header': {
        gridArea: 'header',
        padding: '10px',
        fontWeight: '500',
        fontSize: '15px',
        lineHeight: '16px',
        textTransform: 'uppercase',
        color: '#000',
        borderBottom: '1px solid #C4C4C4',
        [theme.breakpoints.down('md')]: {
          fontSize: '11px',
          lineHeight: '13px',
        },
        '& div': {
          fontSize: 10,
        },
        '& span': {
          fontWeight: 700,
        },
      },
      '& .central': {
        gridArea: 'central',
        padding: '16px 12px 8px',
        fontWeight: '500',
        fontSize: '13px',
        lineHeight: '16px',
        textTransform: 'uppercase',
        color: '#000',
        [theme.breakpoints.down('lg')]: {
          borderBottom: '1px solid #C4C4C4',
          padding: '10px 12px 8px',
        },
        '& span': {
          fontWeight: 600,
          fontSize: 11,
        },
        [theme.breakpoints.down('md')]: {
          fontSize: '11px',
          lineHeight: '13px',
        },
        '& .specification': {
          paddingLeft: '16px',
          fontWeight: '500',
          fontSize: '13px',
          lineHeight: '16px',
          color: '#999',
          textTransform: 'initial',
          margin: '5px 10px 0',
          [theme.breakpoints.down('md')]: {
            fontSize: '11px',
            lineHeight: '13px',
          },
          '& li::marker': {
            color: '#000',
          },
        },
        '& .more-info': {
          textTransform: 'initial',
        },
      },
      '& .side': {
        gridArea: 'side',
        padding: '10px 12px',
        fontWeight: '500',
        fontSize: '13px',
        lineHeight: '16px',
        textTransform: 'uppercase',
        color: '#000',
        borderBottom: '1px solid #C4C4C4',
        [theme.breakpoints.down('lg')]: {
          fontSize: '11px',
          lineHeight: '13px',
          borderLeft: 0,
        },
        '& span': {
          fontWeight: 600,
          fontSize: '11px',
        },
        '& .image': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '6px auto 0',
          [theme.breakpoints.down('sm')]: {
            display: 'none',
          },
          [theme.breakpoints.down('lg')]: {
            '& svg': {
              height: 40,
              width: 'auto',
            },
          },
        },
      },
      '& .side-bottom': {
        gridArea: 'side-bottom',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '6px',
        padding: '8px',
        fontWeight: '500',
        fontSize: '13px',
        lineHeight: '16px',
        color: '#999',
        borderLeft: '0px solid #C4C4C4',
        cursor: 'pointer',
        [theme.breakpoints.down('md')]: {
          fontSize: '11px',
          lineHeight: '13px',
        },
      },
    },
  },

  previewTitle: {
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '18px',
    textTransform: 'uppercase',
    color: '#000',
  },

  fullHeight: {
    height: '100%',
  },

  imageTitle: {
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '18px',
    textTransform: 'uppercase',
    '& .alert': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      gap: '5px',
      marginTop: '5px',
      fontSize: '11px',
      lineHeight: '13px',
      textTransform: 'initial',
    },
    marginBottom: '20px',
  },
  labelContainer: {
    backgroundColor:'#fff',
    padding:'8px',
    color:'white',
    minHeight: '59px',
    marginBottom:'20px',
    borderRadius: '4px',
  },
  labelTitle: {
    color: '#000',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '17px',
    display: 'block',
  },
  labelContent: {
    display: 'block',
    color: '#858585',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '14px',
    // overflow: 'hidden !important',
    // textOverflow: 'ellipsis',
    // whiteSpace: 'nowrap',
    textAlign: 'left',
  },
}));

/*
const loadImage = (setImageDimensions, imageUrl) => {
  const img = new Image();
  img.src = imageUrl;

  img.onload = () => {
    setImageDimensions({
      height: img.height,
      width: img.width
    });
  };
  img.onerror = (err) => {
    console.log("img error");
    console.error(err);
  };
};
*/

const MaterialDataSheet = ({
  firstRowTitle,
  secondRowTitle,
  imageTitle,
  imageTitleAlert,
  type,
  material,
  selectionPreview,
  rootClasses,
  secondMaterial,
  isDoubleProduct,
  multipleImages,
  previewBoxText,
  noImageLayout,
  isNoChoice,
  multipleLabels,
  multipleTitles,
  multipleNames,
  setVirtualToDisplay
}) => {
  const classes = useStyles();
  const [zoomImageSrc, setZoomImageSrc] = useState('');
  // const [imageDimensions, setImageDimensions] = useState({});
  const [labelNumber, setLabelNumber] = useState(3);
  const [productLabels, setProductLabels] = useState(0);
  const pdfs =
  material?.dataSheet
      ? [material?.dataSheet]
      : null;

  const imageUrl = material?.image;

  const SingleMaterialInfoNoImageLayout = ({material}) => {
    return material?.info?.map((info, i) => {
      if(info !== null && info !== '') {
        return(
          <Grid item xs='12' className={classes.labelContainer}>
            <span className={classes.labelTitle}>{material?.infoTitle[i]}</span>
            <span className={classes.labelContent}>{info}</span>
          </Grid>
        )
      } else return null;
    })
  }

  const SingleMaterialInfoFirstColumn = ({material}) => {
    return material?.info?.map((info, i) => {
      if (i%2 === 0 && info !== null && info !== '') {
        return (
          <Grid item xs='10' className={classes.labelContainer}>
            <span className={classes.labelTitle}>{material?.infoTitle[i]}</span>
            <span className={classes.labelContent}>{info}</span>
          </Grid>
        )
      } else return null;
    })
  }

  const SingleMaterialInfoSecondColumn = ({material}) => {
    return material?.info?.map((info, i) => {
      if (i%2 !== 0 && info !== null && info !== '') {
        return (
          <Grid item xs='10' className={classes.labelContainer}>
            <span className={classes.labelTitle}>{material?.infoTitle[i]}</span>
            <span className={classes.labelContent}>{info}</span>
          </Grid>
        )
      } else return null;
    })
  }

  const DoubleMaterialInfoFirstColumn = ({multipleLabels, multipleTitles, productLabels}) => {
    return multipleLabels?.[productLabels]?.map((label, i) => {
      if (i%2 === 0 && label !== null && label !== '') {
        return (
          <Grid item xs='10' className={classes.labelContainer}>
            <span className={classes.labelTitle}>{multipleTitles?.[productLabels]?.[i]}</span>
            <span className={classes.labelContent}>{label}</span>
          </Grid>
        )
      } else return null;
    })
  }

  const DoubleMaterialInfoSecondColumn = ({multipleLabels, multipleTitles, productLabels}) => {
    return multipleLabels?.[productLabels]?.map((label, i) => {
      if (i%2 !== 0 && label !== null && label !== '') {
        return (
          <Grid item xs='10' className={classes.labelContainer}>
            <span className={classes.labelTitle}>{multipleTitles?.[productLabels]?.[i]}</span>
            <span className={classes.labelContent}>{label}</span>
          </Grid>
        )
      } else return null;
    })
  }

  const SingleMaterialInfoSingleColumn = ({material}) => {
    return material?.info?.map((info, i) => {
      if(info !== null && info !== ''){
        return(
          <Grid item xs='12' className={classes.labelContainer}>
            <span className={classes.labelTitle}>{material?.infoTitle[i]}</span>
            <span className={classes.labelContent}>{info}</span>
          </Grid>
        )
      } else return null;
    })
  }

  useEffect(() => {
    // loadImage(setImageDimensions, imageUrl);
    if (multipleImages) {
      setLabelNumber(multipleLabels?.[productLabels]?.length)
    } else if (material?.info) {
      setLabelNumber(material?.info?.length);
    }
  }, [imageUrl, material?.info, multipleImages, multipleLabels, productLabels]);

  useEffect(()=> {
    if(setVirtualToDisplay){
      setVirtualToDisplay(multipleImages?.[productLabels])
    }
  }, [multipleImages, productLabels, setVirtualToDisplay])

  return (
    <div className={classes.materialDataSheetContainer}>
      <Grid
        container
        direction='row'
				className={clsx(classes.materialDataSheetRoot)}
      >
				<Grid item xs={12} style={!noImageLayout ? {height: '50%'} : {height: '10%'}}>
          <ConfiguratorColumnTitle firstRow={firstRowTitle} secondRow={secondRowTitle} style={{height: '10%'}} />
          {!material.image && !noImageLayout &&(
          <div className={'previewImage'}>
            Seleziona {previewBoxText} per visualizzare la preview <br></br>
            <ArrowBack style={{transform: 'rotate(180deg)'}} color='currentColor' width='20px' height='20px' />
          </div>
          )}
          {material.image && !noImageLayout && !multipleImages && (
            <div style={{position: 'relative', height:'90%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <img src={material?.image} alt={'preview'} style={{width: '100%', height: '100%', objectFit: 'contain', position: 'absolute', borderRadius: '8px'}} />
            </div>
          )}
          {material.image && !noImageLayout && multipleImages && (
          <Slider multipleImages={multipleImages}  setProductLabels={setProductLabels} />
          )}
				</Grid>
				<Grid item xs={12} style={!noImageLayout ? {height: '50%', marginTop: '18px'} : {height: '90%'}}>
          {noImageLayout && (<Divider style={{marginBottom: '16px'}} />)}
					<Grid container style={{maxHeight: '19px', alignItems: 'center'}}>
						<Grid item xs={8} style={{overflow: 'hidden', display: '-webkit-box', WebkitLineClamp: '1', WebkitBoxOrient: 'vertical'}}>
              {multipleImages ? (
                <span style={{fontSize: '16px', fontWeight:'750', lineHeight:'19px' }}>{multipleNames?.[productLabels]} </span>
              ) : (
                <span style={{fontSize: '16px', fontWeight:'750', lineHeight:'19px' }}>{material?.name} </span>
              )}
						</Grid>
						<Grid item xs={4}>
            {material?.dataSheet && (
              <div className='schedaTecnica' onClick={() => setZoomImageSrc(material?.dataSheet)}>
                <Ruler color='currentColor' width='15px' height='15px' />
                <span style={{fontSize: '12px', fontWeight:'500', lineHeight:'14px'}}>Scheda tecnica</span>
                <LeftChevron
                  color='currentColor'
                  width='9px'
                  height='9px'
                  style={{ transform: 'rotate(180deg)' }}
                />
              </div>
            )}
						</Grid>
					</Grid>
          <Divider style={{marginTop: '18px', marginBottom: '16px'}} />
          {labelNumber > 3 && !noImageLayout && (
          <Grid container direction='row' style={{height: '80%', overflow:'auto'}}>
            <Grid item xs={6}>
              <Grid container direction='row' style={{justifyContent: 'center', alignItems: 'center'}}>
                { material?.info?.length > 0 && (
                  (multipleImages && multipleLabels && multipleTitles) 
                  ? <DoubleMaterialInfoFirstColumn multipleLabels={multipleLabels} multipleTitles={multipleTitles} productLabels={productLabels} /> 
                  : <SingleMaterialInfoFirstColumn material={material} />
                  )
                }
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction='row' style={{justifyContent: 'center', alignItems: 'center'}}>
                {material?.info?.length > 0 && (
                  (multipleImages && multipleLabels && multipleTitles)  
                  ? <DoubleMaterialInfoSecondColumn multipleLabels={multipleLabels} multipleTitles={multipleTitles} productLabels={productLabels} /> 
                  : <SingleMaterialInfoSecondColumn material={material} />
                )}
              </Grid>
            </Grid>
          </Grid>
          )}
          {labelNumber <= 3 && !noImageLayout && (
          <Grid container direction='row' style={{height: '90%', overflow:'auto'}}>
            <Grid item xs={12}>
              <Grid container direction='row' style={{justifyContent: 'center', alignItems: 'center'}}>
              {material?.info?.length > 0 && <SingleMaterialInfoSingleColumn material={material} />}
              {material?.info?.length === 0 && !isNoChoice && (
              <>
                <Grid item xs='12' className={classes.labelContainer}>
                  <span className={classes.labelTitle}>1. Label</span>
                  <span className={classes.labelContent}>Content Info</span>
                </Grid>
                <Grid item xs='12' className={classes.labelContainer}>
                  <span className={classes.labelTitle}>2. Label</span>
                  <span className={classes.labelContent}>Content Info</span>
                </Grid>
                <Grid item xs='12' className={classes.labelContainer}>
                  <span className={classes.labelTitle}>3. Label</span>
                  <span className={classes.labelContent}>Content Info</span>
                </Grid>
              </>
              )}
              </Grid>
            </Grid>
          </Grid>
          )}
          {noImageLayout && (
          <Grid container direction='row' style={{height: '90%', overflow:'auto'}}>
            <Grid item xs={12}>
              <Grid container direction='row' style={{justifyContent: 'center', alignItems: 'center'}}>
              {material?.info?.length > 0 && <SingleMaterialInfoNoImageLayout material={material} />}
              {material?.info?.length === 0 && !isNoChoice && (
              <>
                <Grid item xs='12' className={classes.labelContainer}>
                  <span className={classes.labelTitle}>1. Label</span>
                  <span className={classes.labelContent}>Content Info</span>
                </Grid>
                <Grid item xs='12' className={classes.labelContainer}>
                  <span className={classes.labelTitle}>2. Label</span>
                  <span className={classes.labelContent}>Content Info</span>
                </Grid>
                <Grid item xs='12' className={classes.labelContainer}>
                  <span className={classes.labelTitle}>3. Label</span>
                  <span className={classes.labelContent}>Content Info</span>
                </Grid>
                <Grid item xs='12' className={classes.labelContainer}>
                  <span className={classes.labelTitle}>4. Label</span>
                  <span className={classes.labelContent}>Content Info</span>
                </Grid>
                <Grid item xs='12' className={classes.labelContainer}>
                  <span className={classes.labelTitle}>5. Label</span>
                  <span className={classes.labelContent}>Content Info</span>
                </Grid>
                <Grid item xs='12' className={classes.labelContainer}>
                  <span className={classes.labelTitle}>6. Label</span>
                  <span className={classes.labelContent}>Content Info</span>
                </Grid>
              </>
              )}
              </Grid>
            </Grid>
          </Grid>
          )}
				</Grid>
            {/*
            <Grid item xs={'auto'} className={clsx('first-part')}>
            <Grid container direction='column' className={classes.fullHeight}>
            {!imageTitle && (
              <Grid item xs={'auto'}>
                <ConfiguratorColumnTitle firstRow={firstRowTitle} secondRow={secondRowTitle} />
              </Grid>
            )}
            <Grid container item direction='column' xs>
              {imageTitle && (
                <Grid item xs={'auto'}>
                  <div className={classes.imageTitle}>
                    <div>{imageTitle}</div>
                    {imageTitleAlert && (
                      <div className={'alert'}>
                        <Alert color='currentColor' width='20px' height='20px' />
                        <span>IMMAGINE A SCOPO ILLUSTRATIVO. {imageTitleAlert}</span>
                      </div>
                    )}
                  </div>
                </Grid>
              )}
              <Grid item xs={12} className={'material'}>
                {!material.image && (
                  <div className={'previewImage'}>
                    Seleziona {previewBoxText} per visualizzare la preview <br></br>
                    <ArrowBack style={{transform: 'rotate(180deg)'}} color='currentColor' width='20px' height='20px' />
                  </div>
                )}
                {material.image && !isDoubleProduct && !multipleImages && (
                  <div style={{ backgroundImage: `url(${material?.image})`, borderRadius:'10%', height:'100%' }} />
                )}
                {material.image && !isDoubleProduct && multipleImages && (
                  <Slider multipleImages={multipleImages} />
                )}
                {material.image && isDoubleProduct && secondMaterial.image && (
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%'}}>
                    <div style={{ backgroundImage: `url(${material?.image})`, width:'49.8%', height:'100%', borderRadius:'10% 0 0 10%' }}></div>
                    <div style={{ width:'0.4%', height:'100%' }}></div>
                    <div style={{ backgroundImage: `url(${secondMaterial?.image})`, width:'49.8%', height:'100%', borderRadius:'0 10% 10% 0' }}></div>
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
          </Grid>
             */}
            {/*
            {isDoubleProduct && (
            <Grid item xs={6} className={'second-part'}>
            <Grid container className={'data-sheet-double'}>
            <Grid item xs='5' className={'data-first-part'}>
              <Grid container direction='column'>
                <Grid item xs='auto' className='header'>
                  <div>{material?.product}</div>
                  <span>{material?.name} </span>
                </Grid>
                <Grid item xs className='central'>
                  <span>Principali informazioni tecniche</span>
                  <ul className='specification'>
                    {material?.info?.map((info, i) => (
                      <li key={i}>{info}</li>
                    ))}
                  </ul>
                </Grid>
                <Grid item xs xl className='side'>
                  <span>Codice art.</span>
                  <div>{material?.code}</div>
                </Grid>
                {material?.dataSheet && (<Grid
                  item
                  xs='auto'
                  className='side-bottom'
                  onClick={() => setZoomImageSrc(material?.dataSheet)}
                >
                  <Ruler color='currentColor' width='15px' height='15px' />
                  <span>Scheda tecnica</span>
                  <LeftChevron
                    color='currentColor'
                    width='9px'
                    height='9px'
                    style={{ transform: 'rotate(180deg)' }}
                  />
                </Grid>)}
              </Grid>
            </Grid>
            <Grid item xs='2' className={'data-half-part'}><div></div></Grid>
            <Grid item xs='5' className={'data-second-part'}>
              <Grid container direction='column'>
                <Grid item xs='auto' className='header'>
                  <div>{secondMaterial?.product}</div>
                  <span>{secondMaterial?.name} </span>
                </Grid>
                <Grid item xs className='central'>
                  <span>Principali informazioni tecniche</span>
                  <ul className='specification'>
                    {secondMaterial?.info?.map((info, i) => (
                      <li key={i}>{info}</li>
                    ))}
                  </ul>
                </Grid>
                <Grid item xs xl className='side'>
                  <span>Codice art.</span>
                  <div>{secondMaterial?.code}</div>
                </Grid>
                {secondMaterial?.dataSheet && (<Grid
                  item
                  xs='auto'
                  className='side-bottom'
                  onClick={() => setZoomImageSrc(secondMaterial?.dataSheet)}
                >
                  <Ruler color='currentColor' width='15px' height='15px' />
                  <span>Scheda tecnica</span>
                  <LeftChevron
                    color='currentColor'
                    width='9px'
                    height='9px'
                    style={{ transform: 'rotate(180deg)' }}
                  />
                </Grid>)}
              </Grid>
            </Grid>
          </Grid>
          </Grid>)}
        */}
            {/*
            {!isDoubleProduct && (
        <Grid item xs={'auto'} className={'second-part'}>
                <Grid container className={'data-sheet'}>
            <Grid item xs className={'data-first-part'}>
              <Grid container direction='column'>
                <Grid item xs='auto' className='header'>
                  <div>{material?.product}</div>
                  <span>{material?.name} </span>
                </Grid>
                <Grid item xs className='central'>
                  <span>Principali informazioni tecniche</span>
                  <ul className='specification'>
                    {material?.info?.map((info, i) => (
                      <li key={i}>{info}</li>
                    ))}
                  </ul>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs='auto' className={'data-second-part'}>
              <Grid container direction='column'>
                <Grid item xs xl className='side'>
                  <span>Codice art.</span>
                  <div>{material?.code}</div>
                  <div className='image'>{material?.icon}</div>
                </Grid>
                {material?.dataSheet && (<Grid
                  item
                  xs='auto'
                  className='side-bottom'
                  onClick={() => setZoomImageSrc(material?.dataSheet)}
                >
                  <Ruler color='currentColor' width='15px' height='15px' />
                  <span>Scheda tecnica</span>
                  <LeftChevron
                    color='currentColor'
                    width='9px'
                    height='9px'
                    style={{ transform: 'rotate(180deg)' }}
                  />
                </Grid>)}
              </Grid>
            </Grid>
          </Grid>
          </Grid>)}
            */}
      </Grid>
      {zoomImageSrc && (
        <PdfViewer allowDownload document={pdfs} handleClose={() => setZoomImageSrc(false)} />
      )}
      {/*zoomImageSrc && (
        <ImageZoom
          images={zoomImageSrc}
          title='Scheda tecnica'
          subtitle='Informazioni'
          datasheet
          allowDownload
          handleClose={() => setZoomImageSrc('')}
        />
      )*/}
    </div>
  );
};

export default MaterialDataSheet;
