import { useState } from 'react';
import { Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import DetailCard from './DetailCardNew';
import clsx from 'clsx';
import { MoodContent } from '../InfoPopup';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  detailsContainerRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flexWrap: 'nowrap',
    '&.selected-mode': {
    },
    '&.no-full-height': {
      height: 'unset',
    },
    '&.step-mode': {
      height: '100%',
      '& .details': {
        height: '100%',
      },
    },
  },
  filters: {
    width: '100%',
    margin: '10px 0',
    padding: '10px 0',
    textAlign: 'center',
    zIndex: 1,
    fontSize: 10,
    overflowX: 'auto',
    overflowY:'hidden'
  },
  filter: {
    width: '80%',
    padding: '5px 5px',
    border: `1px solid #CDC7C1`,
    textAlign: 'center',
    zIndex: 1,
    fontSize: 12,
    borderRadius: '10px',
    overflow: 'scroll',
    margin: '0px 5px',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    '&.selected-filter': {
      backgroundColor: 'white',
    },
  },
  gridDetail: {
    marginBottom: '16px',
    '&:last-child': {
      marginBottom: '0px',
    },
  },
  details: {
    height: '100%',
    // backgroundColor: '#F0EDEB',
    '& > div': {
      // border: `1px solid #CDC7C1`,
      // borderRight: 0,
      '&:nth-child(n)': {
        // borderRight: ({ numCols }) => numCols === 1 && `1px solid #CDC7C1`,
      },
      '&:nth-child(2n)': {
        // borderRight: ({ numCols }) => numCols % 2 === 0 && numCols === 2 && `1px solid #CDC7C1`,
      },
      '&:nth-child(3n)': {
        // borderRight: ({ numCols }) => numCols % 2 !== 0 && `1px solid #CDC7C1`,
      },
      '&:last-child': {
        // borderRight: `1px solid #CDC7C1`,
      },
    },
  },
}));

const DetailsContainer = ({
  title,
  numCols,
  details,
  noSelection,
  selectedMode,
  isRectangular,
  noFullHeight,
  stepMode,
  onClick,
  forceWrap,
  setSelectionPreview,
  selectedOptions,
  upInfo,
  filters,
  setFilter,
  filter,
  noDualImages
}) => {
  const classes = useStyles({ stepMode, numCols });
  const cols = 12 / numCols;
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  let newDetails = noSelection ? details.slice(0, -1) : details;
  const lastElement = noSelection && details[details.length - 1];

  newDetails = newDetails?.map((el) => {
    return {
      ...el,
      ...(el.infoPrice && {
        infoPopup: {
          isWarning: false,
          body: <MoodContent moodName={el.title} text={el.infoPrice} />,
          position: 'right',
        },
      }),
    };
  });

  const [selectedProduct, setSelectedProduct] = useState('');
  const selectProduct = (product) => {
    setSelectedProduct(product);
    if (setSelectionPreview) setSelectionPreview(product);
    if (onClick) onClick(product?.id);
  };

  return (
    <Grid
      container
      direction='column'
      className={clsx(
        classes.detailsContainerRoot,
        selectedMode && 'selected-mode',
        noFullHeight && 'no-full-height',
        stepMode && 'step-mode',
      )}
    >
      {upInfo}
      {filters && (
        <Grid item xs='auto' className={classes.filters}>
          Filtri di Visualizzazione:
          <br></br>
          <br></br>
          <Grid container direction="row" justifyContent="left" alignItems="center" wrap='nowrap'>
            {filters?.map((singleFilter, i) => (
              <Grid item >
                <span className={clsx(classes.filter, singleFilter === filter && 'selected-filter')} onClick={()=> setFilter(singleFilter)}>
                  {singleFilter}
                </span>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
      <Grid item xs>
        <Grid
          container
          className={clsx(classes.details, 'details')}
          wrap={forceWrap ? 'wrap' : stepMode && !smallScreen ? 'nowrap' : 'wrap'}
        >
          {newDetails?.map((detail, i) => (
            <Grid key={i} item xs={numCols ? cols : 6} lg={numCols ? cols : 4} className={clsx(classes.gridDetail)}>
              <DetailCard
                {...detail}
                selectProduct={selectProduct}
                selectedProduct={selectedProduct}
                chosenProduct={_.difference([detail.id], selectedOptions).length === 0}
                isRectangular={isRectangular}
                stepMode={stepMode}
                noDualImages={noDualImages}
              />
            </Grid>
          ))}
          {noSelection && (
            <Grid item xs={12}>
              <DetailCard
                {...lastElement}
                selectProduct={selectProduct}
                selectedProduct={selectedProduct}
                stepMode={stepMode}
                noSelection
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DetailsContainer;
