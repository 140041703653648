import { Dialog, DialogContent, Grid, makeStyles } from '@material-ui/core';
import { Exit, InfoCircled2 } from './Icons';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    backgroundColor: 'transparent',
    flexWrap: 'nowrap',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
    width: '100%',

    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '18px',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#000',
  },
  title: {
    padding: '0 30px',
    '& span': {
      color: theme.palette.primary.main,
    },
  },
  subtitle: {
    padding: '0 10px 30px',
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  paper: {
    // height: '534px',
    maxHeight: ({ screenType }) => (screenType === 'mobile' ? '100%' : 'unset'),
    backgroundColor: 'transparent',
  },

  contentContainer: {
    padding: '40px',
    backgroundColor: '#fff',
    borderRadius: '5px',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#fff',
  },
  exitIcon: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '20px',
    height: '20px',
    marginBottom: '10px',
    padding: '4px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    cursor: 'pointer',
  },
  zeroPadding: {
    padding: 0,
    '&:first-child': {
      paddingTop: '10px',
    },
  },
  images: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '20px',
    maxWidth: '100%',
    marginTop: '30px',
    '& img': {
      width: '50%',
      maxWidth: '300px',
    },
  },
}));

const InfoDialogBagno = ({ height, images, open, handleClose }) => {
  const classes = useStyles();
  return (
    <Dialog
      aria-labelledby='info-dialog'
      maxWidth={'sm'}
      PaperProps={{
        elevation: 0,
        classes: {
          root: classes.paper,
        },
      }}
      open={open}
    >
      <DialogContent classes={{ root: classes.zeroPadding }}>
        <Grid container sm direction='column' alignItems='flex-end' className={classes.root}>
          <Grid item>
            <Exit
              color='#fff'
              width='8px'
              height='8px'
              className={classes.exitIcon}
              onClick={handleClose}
            />
          </Grid>
          <div className={classes.contentContainer}>
            <Grid item className={classes.content}>
              <div className={classes.header}>
                <InfoCircled2 color='#F08219' width='30px' height='30px' />
                <span className={classes.title}>
                  Selezionando <span>un’altezza di {height}</span>
                  <br />
                  l'intonaco rimanente verrà smaltato
                </span>
                <span className={classes.subtitle}>Qui la configurazione d’esempio</span>
              </div>
            </Grid>
            <Grid item sm className={classes.content}>
              <div className={classes.images}>
                {images.map((image, i) => (
                  <img key={i} src={image} alt={image} />
                ))}
              </div>
            </Grid>
          </div>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default InfoDialogBagno;
