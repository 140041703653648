import * as React from "react";

function SvgInfoCircled(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#info-circled_svg__clip0)" fill={props.color}>
        <path d="M20 40a20 20 0 1120-20 20.021 20.021 0 01-20 20zm0-37.873A17.874 17.874 0 1037.873 20 17.894 17.894 0 0020 2.127z" />
        <path d="M15.988 29.215v-2.516h3.145V18.66h-2.994v-2.515h6.125v10.554h2.857v2.516h-9.133zm4.512-15.34c-.355 0-.684-.082-.984-.246a1.97 1.97 0 01-.711-.684 1.721 1.721 0 01-.26-.916c0-.537.182-.98.547-1.326.364-.355.834-.533 1.408-.533.337 0 .656.087.957.26.31.164.556.392.738.683.183.283.274.588.274.916 0 .328-.091.634-.274.916-.182.283-.428.51-.738.684-.3.164-.62.246-.957.246z" />
      </g>
      <defs>
        <clipPath id="info-circled_svg__clip0">
          <path fill={props.color} d="M0 0h40v40H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgInfoCircled;
