import mergeImages from 'merge-images';

export const getBlobByImages = async (images) => {
  const planimetryImages = [...images];
  return await getBlob(planimetryImages);
};

const getBlob = (planimetryImages) => {
  return new Promise(async (resolve, reject) => {
    const localImagesUrl = [];

    for (let i = 0; i < planimetryImages.length; i++) {
      const imageUrl = planimetryImages[i];
      const response = await fetch(imageUrl);
      const imageBlob = await response.blob();
      const reader = new FileReader();

      reader.readAsDataURL(imageBlob);
      reader.onloadend = async () => {
        const base64data = reader.result;
        localImagesUrl.push(base64data);
        if (i + 1 === planimetryImages.length) {
          const b64 = await mergeImages(localImagesUrl);
          const response = await fetch(b64);
          const responseBlob = await response.blob();
          resolve(responseBlob);
        }
      };
    }
  });
};
