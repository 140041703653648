import { Tab } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';

const styles = (theme) => ({
  optionTabRoot: {
    height: '40px',
    padding: '4px 8px 0',
    fontFamily: 'p22-underground',
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '16px',
    position: 'relative',
    textTransform: 'uppercase',
    minHeight: 'unset',
    color: '#000000',
    width: ({ deviceType }) => deviceType === 'browser' && 'fit-content',
    minWidth: 'unset',
    borderTop: '1px solid #CDC7C1',
    borderLeft: '1px solid #CDC7C1',
    '&.selected-tab': {
      backgroundColor: '#EEEEEE',
    },
    '&:last-child': {
      borderRight: '1px solid #CDC7C1',
    },
    [theme.breakpoints.down('sm')]: {
      width: '170px',
    },
  },
  optionTabWrapper: {
    flexDirection: 'row',
    gap: '6px',
  },
  optionTabStatus: {
    borderRadius: '100%',
    height: '13px',
    width: '13px',
    minWidth: '13px',
    backgroundColor: '#C4C4C4',
    marginTop: 3,
    '&.completed': {
      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/icons/check-ok.svg)`,
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
    },
  },
  optionTabText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'start',
  },
});

const a11yProps = (index) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
};

const OptionTab = (props) => {
  const { classes, isActive, value, label, ...others } = props;
  return (
    <Tab
      classes={{
        root: classes.optionTabRoot,
        selected: 'selected-tab',
        wrapper: classes.optionTabWrapper,
      }}
      value={value}
      label={<span className={classes.optionTabText}>{label}</span>}
      icon={<div className={clsx(classes.optionTabStatus, isActive && 'completed')} />}
      {...a11yProps(value)}
      {...others}
    />
  );
};

export default withStyles(styles)(OptionTab);
