import * as React from 'react';

function SvgPreseMatrimoniale(props) {
  return (
    <svg viewBox='0 0 84 46' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#prese-matrimoniale_svg__clip0_2758_23686)' fill='#C4C4C4'>
        <path d='M31.09 14.72a5.247 5.247 0 00-4.28-2.21H16.07c-2.9 0-5.26 2.37-5.26 5.3v2.67H7.78V8.33C7.72 4.88 10.51 2.02 14 1.95h34.08c3.5.06 6.29 2.88 6.35 6.39v.5h1.94v-.5c0-4.59-3.71-8.34-8.28-8.34h-34.1C9.42 0 5.71 3.75 5.71 8.34v12.3c-2.28.55-3.9 2.59-3.9 5.01v6.59H.5c-.28 0-.5.23-.5.5v4.87c0 .28.22.5.5.5h1.31v3.42c0 .28.23.5.5.5h4.9c.28 0 .5-.22.5-.5v-3.42h29.48V36.1H1.94v-1.91h35.25v-1.95H3.82v-6.59c0-1.74 1.4-3.15 3.12-3.15h30.25v-2.02h-5.18v-2.67c0-1.85 1.49-3.36 3.32-3.36h1.86v-1.94H35.4c-1.74 0-3.35.85-4.31 2.21zM3.82 38.11h1.89v1.91H3.82v-1.91zm26.25-17.63H12.68v-2.67c0-1.85 1.49-3.36 3.32-3.36h10.74c1.84 0 3.33 1.51 3.33 3.36v2.67zM58.379 24.65v6.18H60.849v-6.18h-2.47zM53.45 24.65h-2.47v6.16h2.47v-6.16z' />
        <path d='M68.248 18.16c-.01-.73-.29-1.42-.81-1.93-.51-.52-1.2-.81-1.93-.81h-19.19c-.72 0-1.42.29-1.93.8s-.81 1.21-.81 1.94v19.19c0 .26.04.52.12.76.13.44.36.84.69 1.17.51.51 1.21.8 1.93.8h19.19c.73 0 1.42-.29 1.93-.8s.8-1.21.81-1.93V18.16zm-4.13 15.07c-.57.84-1.25 1.59-2.04 2.22-.72.58-1.53 1.05-2.39 1.41-1.8.75-3.79.95-5.7.57a9.945 9.945 0 01-5.05-2.7c-.18-.18-.34-.36-.51-.54-.51-.6-.94-1.25-1.3-1.95a9.907 9.907 0 01-.33-8.27c.21-.51.47-1.01.77-1.47.46-.75 1.03-1.42 1.67-2.02h.16v-.14a9.608 9.608 0 012.01-1.36c.95-.5 1.97-.83 3.02-.98.49-.08.98-.12 1.48-.12a10.005 10.005 0 013.78.75c1.2.5 2.29 1.23 3.2 2.14.92.92 1.64 2.01 2.14 3.2a9.89 9.89 0 01-.91 9.26zM79.858 26.44h1.11c.14 0 .25-.11.25-.25v-4.74c0-.11 0-.22-.05-.33a.75.75 0 00-.18-.28.75.75 0 00-.47-.22.169.169 0 00-.1-.03c-.11-.01-.22 0-.33.05-.1.04-.2.1-.28.18a.75.75 0 00-.18.28c-.04.1-.05.22-.05.3v4.79c0 .14.11.25.25.25h.03zM73.57 26.42h1.06c.14 0 .25-.12.25-.25v-4.74a.76.76 0 00-.05-.33.65.65 0 00-.17-.27.75.75 0 00-.28-.18.86.86 0 00-.32-.05c-.09.01-.18.03-.27.06-.1.05-.18.11-.26.18a.856.856 0 00-.21.57v4.76c0 .13.12.25.25.25zM83.28 34.47v-6a.858.858 0 00-.17-.64.883.883 0 00-.61-.33H71.92a.836.836 0 00-.59.32c-.07.08-.13.19-.16.3-.03.11-.04.23-.03.31v6.01c-.01.12 0 .24.03.35.03.11.08.21.16.3.07.09.16.16.27.22.1.05.21.08.35.09h10.58a.857.857 0 00.58-.32c.07-.09.12-.19.15-.3.03-.11.03-.23.02-.31zM78.82 36.59h-3.17c-.14 0-.25.11-.25.25v1.05c0 .14.11.25.25.25h.81v3.3c-.03.64-.29 1.23-.74 1.68 0 .01-.03.02-.04.04-.44.42-1.02.67-1.61.69h-5.82c-.14 0-.25.12-.25.25v1.07c0 .13.11.25.25.25h5.82c.59 0 1.13-.12 1.61-.32 1.48-.62 2.34-2.03 2.34-3.12v-3.84h.8c.13 0 .25-.11.25-.25v-1.05c0-.14-.12-.25-.25-.25z' />
      </g>
      <defs>
        <clipPath id='prese-matrimoniale_svg__clip0_2758_23686'>
          <path fill='#fff' d='M0 0h83.29v45.42H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgPreseMatrimoniale;
