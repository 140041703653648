import { Grid, makeStyles, useTheme, Button } from '@material-ui/core';
import { useEffect, useState, useRef } from 'react';
import SelectionCard from '../../../components/Card/SelectionCard';
import { Alert } from '../../../components/Icons';
import IconTitle from '../../../components/IconTitle';
import ImageZoom from '../../../components/ImageZoom';
import ConfiguratorColumnTitle from '../../../components/Labels/ConfiguratorColumnTitle';
import ConfiguratorSelectionLayout from '../../../components/Layout/ConfiguratorSelectionLayout';
import LoadingAnimation from '../../../components/LoadingAnimation';
import { resumeAnswerSimple } from '../../../utils/configuratorUtils';
import { getPackages } from '../../../services/packageApi';
import { useStore } from '../../../store/storeUtils';
import { useParams } from 'react-router';
import { handleSave, singleChoiceSelection } from '../../../utils/selectionUtils';
import _ from 'lodash';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import BigImage from './BigImage';
import InfoColumn from '../../../components/InfoColumn';
import ConfiguratorTwoColumnsLayout from '../../../layouts/ConfiguratorTwoColumnsLayout';
import { getLayoutImagesByConfigurationId } from '../../../services/layoutApi';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    padding: '22px',
    backgroundColor: '#fff',
  },
  content: {
    width: '100%',
    height: '100%',
  },
  list: { marginTop: '50px', padding: '0 22px 22px' },
  message: { marginBottom: '50px' },
}));

const FirstStepFirstColumn = () => {
  return <BigImage image={`${process.env.PUBLIC_URL}/assets/images/Bagno.jpg`} />;
};

const FirstStepSecondColumn = ({ setFirstStep, planimetryImages, bagniReference }) => {
  return (
    <div style={{ height: '70%', backgroundColor: '#F0EDEB' }}>
      <InfoColumn
        setFirstStep={setFirstStep}
        planimetryImages={planimetryImages}
        layoutReference={bagniReference}
        bagnoPage={true}
      />
    </div>
  );
};

const FixedHeader = () => {
  return (
    <>
      <ConfiguratorColumnTitle
        firstRow={'SELEZIONA LO SCHEMA'}
        secondRow={'RIVESTIMENTO DEL TUO BAGNO'}
      />
    </>
  );
};

const ScrollableContent = ({
  activeStep,
  showGallery,
  setShowGallery,
  onClick,
  options,
  selectedOptions,
}) => {
  const classes = useStyles();

  if (options) options.packages = options?.packages?.slice(activeStep * 4, activeStep * 4 + 4);
  const gallery = options?.packages?.map((el) => {
    return {
      name: (
        <span>
          <b>{el.name}</b>
        </span>
      ),
      images: el.images?.filter((elg) => elg?.imageType?.key === 'G').map((elt) => elt.label),
    };
  });

  return (
    <>
      <Grid container direction='column' className={classes.content} wrap='nowrap'>
        <Grid item xs='auto' className={classes.message}>
          <IconTitle
            title='Quale altezza del rivestimento preferisci per il tuo bagno?'
            // subTitle={true}
            // subTitleIcon={<Alert />}
          />
        </Grid>
        <Grid item xs>
          <Grid justifyContent='center' container direction='row' spacing={6}>
            {options?.packages?.map((el, index) => (
              <Grid item xs={3} key={index}>
                <SelectionCard
                  {...el}
                  onGalleryButtonClick={() => setShowGallery(index)}
                  noZoomImages={true}
                  noSlider={true}
                  onClick={() => onClick(el.id)}
                  isSelected={_.difference([el.id], selectedOptions).length === 0}
                  infoPriceMessage={'Il prezzo comprende le lavorazioni, la fornitura e la posa del materiale necessario al raggiungimento dell’altezza dello schema rivestimento scelto.'}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      {showGallery > -1 && (
        <ImageZoom
          gallery={gallery}
          galleryToShow={showGallery}
          handleClose={() => setShowGallery(-1)}
        />
      )}
    </>
  );
};

const SchemaRivestimentoBagno = () => {
  const [packages, setPackages] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [maxSteps, setMaxSteps] = useState(0);
  const [images, setImages] = useState([]);
  const [firstStep, setFirstStep] = useState(true);
  const bagniReference = useRef();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const substepIdRef = useRef();
  const oldAnswersRef = useRef();
  const theme = useTheme();

  const store = useStore();
  const params = useParams();
  const classes = useStyles();

  const [showGallery, setShowGallery] = useState(-1);
  const [isLoading, setIsLoading] = useState(true);
  const currentRoomId = store.currentRoomId;

  useEffect(() => {
    setIsLoading(true);
    store.setUserCanGoNext(false);
    prepareOptions();
  }, [currentRoomId]);

  useEffect(() => {
    // store.setUserCanGoNext(false);
    // setFirstStep(true);
    // prepareOptions();
    // setSelectedPackages([]);
    // setPackages([]);
    // oldAnswersRef.current = null;
    if (selectedPackages.length > 0) setFirstStep(false);
    setImages([]);
    // cartaParatiReference.current = null;
  }, [currentRoomId, selectedPackages]);

  const prepareOptions = async () => {
    const configurationId = params.configuration;
    const substepId = store.currentSubstepId;
    const rooms = store.currentConfiguration?.layout?.rooms;
    const currentRoom = rooms.find((room) => room.id === currentRoomId);
    const currentRoomTag = currentRoom?.tags?.find((tag) => tag.isRoom === true);
    const currentRoomTagUrl = currentRoomTag?.fileLabels?.[0]?.url?.replace(/\s/g, '%20');
    const insertingRoomInLayout = {
      fileUrl: currentRoomTagUrl,
      order: 3,
    };
    const layoutImagesResponse = await getLayoutImagesByConfigurationId(configurationId);
    const layoutImages = layoutImagesResponse.data;
    layoutImages.push(insertingRoomInLayout);
    setImages(layoutImages);
    const packages = await getPackages(configurationId, substepId, currentRoomId);
    substepIdRef.current = store.currentSubstepId;
    setMaxSteps(Math.round(packages?.data[0]?.packages?.length / 4));
    setPackages(packages?.data[0]);
    resumeAnswerSimple(
      store,
      oldAnswersRef,
      substepIdRef,
      setSelectedPackages,
      setIsLoading,
      currentRoomId,
    );
  };

  useEffect(() => {
    const save = async () => {
      await handleSave(
        null,
        params.configuration,
        substepIdRef.current,
        store,
        selectedPackages?.[0],
        currentRoomId,
      );
    };

    if (!_.isEqual(oldAnswersRef.current, selectedPackages)) {
      store.setSubstepSave(save);
    } else {
      store.setSubstepSave(null);
    }
  }, [selectedPackages]);

  const handleUserSelection = (selection) => {
    singleChoiceSelection([selection], [selectedPackages], setSelectedPackages, store);
  };

  return isLoading ? (
    <LoadingAnimation />
  ) : firstStep ? (
    <ConfiguratorTwoColumnsLayout
      firstRowTitle='INIZIA LA'
      secondRowTitle={'CONFIGURAZIONE DEL BAGNO'}
      // noLoad={true}
      invertedProportion={!firstStep}
      firstColumn={<FirstStepFirstColumn />}
      secondColumn={
        <FirstStepSecondColumn
          options={packages}
          selectedOptions={selectedPackages}
          handleUserSelection={handleUserSelection}
          setFirstStep={setFirstStep}
          planimetryImages={images}
          layoutReference={bagniReference}
        />
      }
    />
  ) : (
    <div className={classes.root}>
      <ConfiguratorSelectionLayout
        fixedHeader={<FixedHeader />}
        scrollableContent={
          isLoading ? (
            <LoadingAnimation />
          ) : (
            <ScrollableContent
              showGallery={showGallery}
              setShowGallery={setShowGallery}
              onClick={handleUserSelection}
              options={packages}
              selectedOptions={selectedPackages}
              activeStep={activeStep}
            />
          )
        }
      />
      {packages?.packages?.length > 4 && (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {activeStep !== 0 && (
            <Button
              size='small'
              style={{ marginTop: '-15px' }}
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight
                  style={{ border: '1px solid black', padding: '5px', borderRadius: '15px' }}
                />
              ) : (
                <KeyboardArrowLeft
                  style={{ border: '1px solid black', padding: '5px', borderRadius: '15px' }}
                />
              )}
            </Button>
          )}
          {activeStep !== maxSteps - 1 && (
            <Button
              size='small'
              style={{ marginTop: '-15px' }}
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              {theme.direction === 'rtl' ? (
                <KeyboardArrowLeft
                  style={{ border: '1px solid black', padding: '5px', borderRadius: '15px' }}
                />
              ) : (
                <KeyboardArrowRight
                  style={{ border: '1px solid black', padding: '5px', borderRadius: '15px' }}
                />
              )}
            </Button>
          )}
          <span style={{ marginTop: '-8px' }}>{packages?.packages?.length} Mood</span>
        </div>
      )}
    </div>
  );
};

export default SchemaRivestimentoBagno;
