import { ButtonBase, Grid, makeStyles, Popover } from '@material-ui/core';
import IconButton from '../Buttons/IconButton';
import { CardCurrencyFormatter } from '../../utils/CurrencyFormatter';
import { Alert, Camera, Ruler, OutlinedStar, Plus, Configurations, AlertOutlined } from '../Icons';
import PlateContainer from './PlateContainer';
import clsx from 'clsx';
import ImageZoomButton from '../Buttons/ImageZoomButton';
import { useRef, useState } from 'react';
import InfoPopup, { PhaseInfoContent } from '../InfoPopup';
import RichTextPopover from '../popovers/RichTextPopover';
import { useStore } from '../../store/storeUtils';
import { useHistory, useRouteMatch } from 'react-router';

const useStyles = makeStyles((theme) => ({
  optionCardRoot: {
    height: '100%',
    '&.white .option-card-root': {
      backgroundColor: '#FFF',
    },
    '& .option-card-root': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: theme.palette.color.grey1,
      border: `1px solid ${theme.palette.color.grey2}`,
    },
    '& .option-card-container': {
      height: '100%',
    },
    '& .cancel-button': {
      display: 'flex',
      alignItems: 'flex-end',
      height: '100%',
      paddingBottom: '10px',
      '& button': {
        fontSize: '11px',
        fontWeight: 700,
        textDecoration: 'underline',
      },
    },
    '& .info-rich-button': {
      textAlign: 'center',
      width: '100%',
      height: '30px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderBottom: '1px solid #C4C4C4',
    },
  },
  optionCardBodyRoot: {
    width: '100%',
    padding: '16px 16px 0',
    '& .option-card-message': {
      width: '100%',
      fontSize: '13px',
      textAlign: 'center',
      lineHeight: '15px',
      marginBottom: '16px',
      padding: '0 16px',
    },
    '& .option-card-name': {
      fontSize: '15px',
      textAlign: 'center',
      textTransform: 'uppercase',
      marginBottom: '16px',
    },
    '& .option-card-plates': {
      marginBottom: '8px',
    },
    '& .option-card-icon': {
      height: '80px',
      marginBottom: '16px',
      textAlign: 'center',
      position: 'relative',
      '&.small': {
        height: '40px',
      },
      '& svg': {
        height: '100%',
        width: 'auto',
        maxWidth: '90%',
      },
      '& .icon-side-text': {
        position: 'absolute',
        right: '10%',
        top: '40%',
      },
    },
    '& .option-card-icon-subtitle': {
      marginBottom: '16px',
      textAlign: 'center',
    },
    '&.side-title': {
      '& .option-card-name': {
        textAlign: 'left',
        fontSize: '12px',
        lineHeight: '1.3',
        paddingLeft: '8px',
      },
      '& .option-card-icon': {
        borderRight: `1px solid ${theme.palette.color.grey2}`,
        paddingRight: '8px',
        height: 'unset',
        '& svg': {
          maxWidth: '75%',
          fill: '#000',
          height: '48px',
        },
      },
    },
  },
  recommended: {
    backgroundColor: '#F0D80A',
    fontFamily: 'miller',
    fontStyle: 'italic',
    fontWeight: 'normal',
    fontSize: 13,
    lineHeight: '16px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px',
    whiteSpace: 'nowrap',
    '& svg': {
      marginRight: '8px',
    },
    '& path:nth-child(2)': {
      fill: '#F0D80A',
    },
    '&.hidden': {
      visibility: 'hidden',
    },
  },
  needToKnow: {
    backgroundColor: '#FFF',
    fontFamily: 'miller',
    fontStyle: 'italic',
    fontWeight: 'normal',
    fontSize: 13,
    lineHeight: '16px',
    // height: '30px',
    display: 'flex',
    alignItems: 'start',
    padding: '5px 5px',
    whiteSpace: 'normal',
    '& svg': {
      marginRight: '4px',
    },
    '& path:nth-child(2)': {
      fill: '#FFF',
    },
    '&.hidden': {
      visibility: 'hidden',
    },
  },
  needToKnowIcon: {
    border: '1px solid ' + theme.palette.color.orange,
    borderRadius: '50%',
    backgroundColor: theme.palette.color.orange,
  },
  needToKnowText: {
    color: theme.palette.color.orange,
  },
  needToKnowSecondText: {
    color: theme.palette.color.black,
  },
  selectionButton: {
    width: '100%',
    padding: '8px',
    flexGrow: 1,
  },
  price: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    borderTop: `1px solid ${theme.palette.color.grey2}`,
    height: '30px',
    fontSize: '11px',
    lineHeight: '13px',
    '& .price-description': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      width: '50%',
      height: '100%',
      padding: '17px 20px 14px 10px',
      backgroundColor: theme.palette.color.yellow,
      clipPath: 'polygon(0 0, 100% 0, 95% 100%, 0% 100%)',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      fontSize: '10px',
    },
    '& .noPrice-description': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      width: '100%',
      height: '100%',
      padding: '17px 20px 14px 10px',
      color: theme.palette.color.orange,
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      fontSize: '10px',
    },
    '& .price-value': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingRight: '14px',
      fontSize: '12px',
      lineHeight: '15px',
      color: theme.palette.primary.main,
      '& svg': {
        marginRight: '8px',
        cursor: 'pointer',
      },
      '& span': {
        paddingTop: '3px',
        whiteSpace: 'nowrap',
        color: '#A15117',
      },
    },
  },
  bold: {
    fontWeight: 600,
  },
  popover: {
    backgroundColor: 'rgba(0,0,0,0) !important',
    borderTopLeftRadius: '90px',
  },
}));

const OptionCard = ({
  isSuggested,
  Tags,
  tags,
  rooms,
  price,
  multiple,
  isSelected,
  onClick,
  products,
  onMouseEnter,
  onMouseLeave,
  fileLabels,
  children,
  white,
  infoPriceMessage,
  infoRichText,
  infoRichTextButtonLabel,
  singleChanging,
  doubleChanging,
  textOnButton,
  isNewCreate,
  needToKnow,
  noAddPrice,
  noPossiblePrice,
  needChanging
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { url  } = useRouteMatch();
  const store = useStore();
  const phase = store.getCurrentPhaseData();

  const [showInfo, setShowInfo] = useState(false);
  const [showRichText, setShowRichText] = useState(false);

  const richTextRef = useRef();
  const infoRef = useRef();

  const infoData = {
    phase: 'VALORE AGGIUNTO',
    firstRow: 'Puoi monitorare le tue aggiunte nella sezione Riepilogo',
    secondRow: infoPriceMessage ? (
      <span dangerouslySetInnerHTML={{ __html: infoPriceMessage }} />
    ) : (
      "Selezionandolo aumenterà il prestigio del tuo preventivo. Avrai sempre la possibilità di monitorare le tue aggiunte con valore superiore nella sezione 'Recap scelte'"
    ),
    thirdRow: 'I prezzi sono da intendersi iva esclusa.',
  };

  const images =
    products?.filter((el) => el.imageFileUrl)?.length > 0
      ? [...new Set(products.map((el) => el.imageFileUrl))]
      : null;

  const pdfs =
    products?.filter((el) => el.dataSheetFileUrl)?.length > 0
      ? [...new Set(products.map((el) => el.dataSheetFileUrl))]
      : null;
  
  const changeFloorFunc = async () => {
    const keyStepTosearch = needChanging ? 'DOCCIA' : 'PAVIMENTOLIVINGECAMERE';
    const keySubStepTosearch = needChanging ? 'MODELLO' : 'FINITURA'
    const step = phase?.steps.find((el) => el.key === keyStepTosearch);
    store.setCurrentStep(step?.id);
    const substep = step?.substeps?.find ((el) => el.key === keySubStepTosearch);
    store.setCurrentSubstep(substep?.frontendId);
    store.setCurrentSubstepId(substep?.id);
    const onlyUrl = url?.split('/');
    onlyUrl?.pop(); // popping substep
    onlyUrl?.pop(); // popping step
    const rightUrl = onlyUrl?.join('/'); // url string
    history.push(`${rightUrl}/${step?.key}/${substep?.key}`);
  };

  return (
    <Grid
      container
      direction='column'
      className={clsx(classes.optionCardRoot, white && 'white')}
      onMouseEnter={onMouseEnter ? () => onMouseEnter(fileLabels, tags, rooms) : null}
      onMouseLeave={onMouseLeave}
    >
      <Grid
        item
        xs={'auto'}
        className={clsx(
          classes.recommended,
          (!isSuggested) && 'hidden',
        )}
      >
        <OutlinedStar color='#000' width='15px' height='15px' />
        <span>Cosigliato dall'architetto</span>
      </Grid>
      <Grid item className={'option-card-root'} xs>
        <Grid container className={'option-card-container'} direction='column' wrap='nowrap'>
          <Grid item xs>
            {children}
          </Grid>
          <Grid item xs='auto'>
            {images && (
              <ImageZoomButton
                imageSrc={images}
                label={'Foto'}
                icon={<Camera color='#878787' width='15px' height='15px' />}
              />
            )}
            {pdfs && (
              <ImageZoomButton
                imageSrc={pdfs}
                label={'Scheda Tecnica'}
                isPdf
                icon={<Ruler color='#878787' width='15px' height='15px' />}
              />
            )}
            {infoRichText && (
              <>
                <ButtonBase
                  ref={richTextRef}
                  className='info-rich-button'
                  onClick={() => setShowRichText(true)}
                >
                  {infoRichTextButtonLabel}
                </ButtonBase>
                <RichTextPopover
                  show={showRichText}
                  onClose={() => setShowRichText(false)}
                  anchorEl={richTextRef.current}
                  infoRichText={infoRichText}
                />
              </>
            )}
            <Grid
              container
              className={classes.selectionButton}
              justifyContent={doubleChanging ? 'center' : multiple && isSelected ? 'space-between' : 'flex-end'}
              alignItems={doubleChanging ? 'center' : 'flex-end'}
            >
              {multiple && isSelected && (
                <Grid item xs='auto' className='cancel-button'>
                  <ButtonBase onClick={multiple & isSelected ? onClick : null}>ELIMINA</ButtonBase>
                </Grid>
              )}
              {/*singleChanging && (
                <Grid item xs='4' className='cancel-button'>
                  <ButtonBase onClick={changeFloorFunc}>Cambia Scelta</ButtonBase>
                </Grid>
              )*/}
              <Grid item xs='auto'>
                <IconButton
                  text={(doubleChanging || needChanging) ? textOnButton : 
                    multiple
                      ? isSelected
                        ? 'Aggiunto'
                        : 'Aggiungi'
                      : isSelected
                      ? 'Selezionato'
                      : 'Seleziona'
                  }
                  icon={isNewCreate ? <Configurations color='currentColor' width='10px' height='10px' /> : <Plus color='currentColor' width='10px' height='10px' />}
                  onClick={multiple & isSelected ? null : onClick}
                  isSelected={isSelected}
                  longText={(doubleChanging || needChanging) ? true : false}
                />
              </Grid>
            </Grid>
            <div className={classes.price}>
              {noAddPrice && 
                <div className={`noPrice-description ${classes.bold}`}>
                  <span>Non sono previsti costi extra</span>
                </div>
              }
              {noPossiblePrice && 
                <div className={`noPrice-description ${classes.bold}`}>
                  
                </div>
              }
              {!noAddPrice && !noPossiblePrice && 
              <>
                <div
                  className={`price-description ${classes.bold}`}
                  style={{ visibility: Tags?.includes('sale') ? 'visible' : 'hidden' }}
                >
                  <span>{Tags?.includes('sale') ? 'Sconto del 20%' : "L'opzione piú scelta"}</span>
                </div>              
                <div className={`price-value ${classes.bold}`} ref={infoRef}>
                  <Alert
                    color='currentColor'
                    width='15px'
                    height='15px'
                    onClick={() => {
                      setShowInfo(true);
                    }}
                  />
                  <span>+ {CardCurrencyFormatter(price)}</span>
                </div>
                <Popover
                  open={showInfo}
                  anchorEl={infoRef.current}
                  onClose={() => setShowInfo(false)}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  PaperProps={{ classes: { root: classes.popover } }}
                >
                  <InfoPopup
                    body={<PhaseInfoContent {...infoData} />}
                    isPopover
                    handleClose={() => setShowInfo(false)}
                  />
                </Popover>
              </>}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={'auto'}
        className={clsx(
          classes.needToKnow,
          (!needToKnow || !isSelected) && 'hidden')}
      >
        <span className={classes.needToKnowText}><AlertOutlined className={classes.needToKnowIcon} color='#fff' width='15px' height='15px' /> <span className={classes.needToKnowSecondText}>Confermando questa scelta </span> {needToKnow} </span>
      </Grid>
    </Grid>
  );
};

export default OptionCard;

export const StandardOptionCardBody = ({
  message,
  positions,
  name,
  plateText,
  icon,
  iconSideText,
  hidePositions,
  topPositions,
  iconSubtitle,
  useOptionPosition,
  bottomIconMessage,
  bottomName,
  smallIcon,
  rooms,
  hideName,
  dimensionAfter,
  dimensionBefore,
  ...others
}) => {
  const classes = useStyles();

  const { position } = others;
  const platePositions = useOptionPosition ? [position] : positions;

  return (
    <div className={classes.optionCardBodyRoot}>
      {!bottomIconMessage && message && <div className={'option-card-message'}>{message}</div>}
      {!bottomName && name && !hideName && <div className={'option-card-name'}>{name}</div>}
      {topPositions && !hidePositions && positions && (
        <div className={'option-card-plates'}>
          <PlateContainer elements={platePositions} plateText={plateText} />
        </div>
      )}
      {topPositions && !hidePositions && rooms && (
        <div className={'option-card-plates'}>
          <PlateContainer elements={rooms} />
        </div>
      )}
      {icon && (
        <div className={clsx('option-card-icon', smallIcon && 'small')}>
          {icon}
          {iconSideText && <div className={'icon-side-text'}>{iconSideText}</div>}
        </div>
      )}
      {dimensionAfter && dimensionBefore && <div className={'option-card-message'}>Da {dimensionBefore}cm a {dimensionAfter}cm</div>}
      {bottomName && name && <div className={'option-card-name'}>{name}</div>}
      {bottomIconMessage && message && <div className={'option-card-message'}>{message}</div>}

      {iconSubtitle && <div className={'option-card-icon-subtitle'}>{iconSubtitle}</div>}
      {!topPositions && !hidePositions && positions && (
        <div className={'option-card-plates'}>
          <PlateContainer elements={platePositions} plateText={plateText} />
        </div>
      )}
      {!topPositions && !hidePositions && rooms && (
        <div className={'option-card-plates'}>
          <PlateContainer elements={rooms} />
        </div>
      )}
    </div>
  );
};

export const SideTitleOptionCardBody = ({
  message,
  positions,
  name,
  plateText,
  icon,
  iconSideText,
  hidePositions,
  topPositions,
  iconSubtitle,
  useOptionPosition,
  ...others
}) => {
  const classes = useStyles();

  const { position } = others;

  const platePositions = useOptionPosition ? [position] : positions;

  return (
    <div className={clsx(classes.optionCardBodyRoot, 'side-title')}>
      <Grid container>
        {icon && (
          <Grid item xs={6} className={'option-card-icon'}>
            {icon}
          </Grid>
        )}
        {name && (
          <Grid item xs={6} className={'option-card-name'}>
            {name}
          </Grid>
        )}
      </Grid>
      {message && <div className={'option-card-message'}>{message}</div>}
      {topPositions && !hidePositions && positions && (
        <div className={'option-card-plates'}>
          <PlateContainer elements={platePositions} plateText={plateText} />
        </div>
      )}

      {iconSubtitle && <div className={'option-card-icon-subtitle'}>{iconSubtitle}</div>}
      {!topPositions && !hidePositions && positions && (
        <div className={'option-card-plates'}>
          <PlateContainer elements={platePositions} plateText={plateText} />
        </div>
      )}
    </div>
  );
};
