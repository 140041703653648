import * as React from 'react';

function SvgConfigurations(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 35 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M22.782 11.293H11.207a.926.926 0 00-.663.281.972.972 0 000 1.358c.176.18.415.281.663.281h11.575a.926.926 0 00.663-.281.971.971 0 00.275-.679.972.972 0 00-.275-.679.926.926 0 00-.663-.281zM20.084 16.266h-8.877a.927.927 0 00-.663.28.972.972 0 00-.274.68c0 .254.098.499.274.679.176.18.415.28.663.28h8.877a.927.927 0 00.663-.28.972.972 0 000-1.358.927.927 0 00-.663-.281z'
        fill={props.color}
      />
      <path
        d='M33.182.2H9.928a.927.927 0 00-.664.28.972.972 0 00-.274.68v3.659H5.625a.927.927 0 00-.663.28.972.972 0 00-.275.68v23.374h-3.23a.938.938 0 00-.66.284.983.983 0 00-.277.676c0 1.396.534 2.737 1.488 3.735a5.152 5.152 0 003.617 1.6h18.847c1.355 0 2.654-.551 3.613-1.531.96-.98 1.5-2.31 1.502-3.697h3.595a.927.927 0 00.663-.281.972.972 0 00.275-.68V1.16a.98.98 0 00-.275-.679.936.936 0 00-.663-.28zm-5.439 30.052a3.358 3.358 0 01-.952 2.339c-.608.62-1.43.968-2.288.968H5.625a3.254 3.254 0 01-1.967-.71 3.387 3.387 0 01-1.159-1.776h18.806a.927.927 0 00.663-.281.972.972 0 000-1.358.927.927 0 00-.663-.281H6.53V6.75h21.212v23.502zm4.532-1.931h-2.688V5.779a.972.972 0 00-.274-.68.927.927 0 00-.663-.28H10.865v-2.7h21.38l.03 26.202z'
        fill={props.color}
      />
    </svg>
  );
}

export default SvgConfigurations;
