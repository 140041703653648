import * as React from "react";

function SvgCheckOk(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 50 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M25 50c13.807 0 25-11.193 25-25S38.807 0 25 0 0 11.193 0 25s11.193 25 25 25z'
        fill={props.color || '#76B830'}
      />
      <path
        d='M22.127 35c-.468 0-.936-.226-1.287-.566l-8.313-8.262c-.703-.68-.703-1.698 0-2.377.82-.68 1.873-.68 2.576 0l6.907 7.017L33.952 17.57c.586-.679 1.757-.792 2.459-.113.702.566.82 1.698.117 2.377L23.415 34.434c-.351.34-.82.566-1.288.566z'
        fill='#fff'
      />
    </svg>
  );
}

export default SvgCheckOk;
