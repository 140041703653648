import * as React from "react";

function SvgPanelControl(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 203 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M203 4.18v46.64a4.192 4.192 0 01-1.218 2.956A4.153 4.153 0 01198.84 55H4.16a4.15 4.15 0 01-2.942-1.224A4.192 4.192 0 010 50.819V4.181c0-1.109.438-2.172 1.218-2.956A4.15 4.15 0 014.16 0h46.15c11.98 0 23.458 5.479 30.537 15.19a25.626 25.626 0 009.073 7.749 25.488 25.488 0 0023.16 0 25.627 25.627 0 009.073-7.75C129.238 5.48 140.717 0 152.696 0h46.144c1.103 0 2.161.44 2.942 1.225A4.192 4.192 0 01203 4.18z"
        fill="#EBE4E0"
        fillOpacity={0.9}
      />
    </svg>
  );
}

export default SvgPanelControl;
