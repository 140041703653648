import { Dialog, DialogContent, Grid, makeStyles } from '@material-ui/core';
import projectConfig from '../configs/projectConfig';
import Button3D from './Buttons/Button3D';
import IconButton from './Buttons/IconButton';
import {
  AlertOutlined,
  DesktopCheck,
  DesktopWindowCheck,
  DesktopWindowUncheck,
  Exit,
  PhoneUncheck,
  Plus,
  TabletCheck,
  TabletOrientationCheck,
  TabletUncheck,
} from './Icons';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    backgroundColor: 'transparent',
    flexWrap: 'nowrap',
    overflow: 'hidden',
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: '5px',
    filter: 'drop-shadow(0px 20px 20px rgba(0, 0, 0, 0.5))',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
    width: '100%',
  },
  title: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    textTransform: 'uppercase',
  },
  subtitle: {
    padding: '0 10px 30px',
    fontFamily: 'miller',
    fontStyle: 'italic',
    fontSize: '20px',
    lineHeight: '24px',
    textAlign: 'center',
    borderBottom: ({ screenType }) => (screenType ? 0 : `2px solid ${theme.palette.primary.main}`),
  },
  actionButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '15px',
    width: '280px',
    marginTop: ({ screenType }) => screenType === 'mobile' && '15px',
    [theme.breakpoints.down('lg')]: {
      width: '260px',
    },
    '& .button-length': {
      width: '100%',
      maxWidth: 'unset',
    },
  },
  paper: {
    // height: '534px',
    maxHeight: ({ screenType }) => (screenType === 'mobile' ? '100%' : 'unset'),
    backgroundColor: 'transparent',
  },
  icons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
  },
  contentContainer: {
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: ({ screenType }) => (screenType === 'mobile' ? '5px' : '0 5px 5px 0'),
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  icon: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  exitIcon: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '20px',
    height: '20px',
    marginTop: '-10px',
    marginBottom: '10px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    cursor: 'pointer',
  },
  extraText: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    width: ({ screenType }) => screenType === 'browser' && '70%',
    paddingTop: '30px',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '18px',
    textAlign: ({ screenType }) => screenType === 'browser' && 'center',
    '& .highlight-text': {
      textTransform: 'uppercase',
    },
    '& span': {
      color: theme.palette.primary.main,
    },
    '& hr': {
      width: '100%',
      border: 0,
      borderTop: '1px dashed #000',
    },
  },
  zeroPadding: {
    padding: 0,
    '&:first-child': {
      paddingTop: ({ screenType }) => (screenType ? 0 : '10px'),
    },
  },
}));

const CustomDialog = ({
  image,
  title,
  subtitle,
  labelButton1,
  button1OnClick,
  labelButton2,
  button2OnClick,
  open,
  handleClose,
  screenType,
}) => {
  const classes = useStyles({ screenType });

  const renderContent = (screenType) => {
    switch (screenType) {
      case 'mobile':
        return <MobileContent screenType={screenType} />;
      case 'tablet':
        return <TabletContent screenType={screenType} />;
      case 'browser':
        return <BrowserContent screenType={screenType} />;
      default:
        return (
          <DefaultContent
            image={image}
            title={title}
            subtitle={subtitle}
            labelButton1={labelButton1}
            button1OnClick={button1OnClick}
            labelButton2={labelButton2}
            button2OnClick={button2OnClick}
            handleClose={handleClose}
          />
        );
    }
  };
  return (
    <Dialog
      // onClose={handleClose}
      aria-labelledby='custom-dialog'
      maxWidth={false}
      PaperProps={{
        elevation: 0,
        classes: {
          root: classes.paper,
        },
      }}
      open={open}
    >
      <DialogContent classes={{ root: classes.zeroPadding }}>
        {renderContent(screenType)}
      </DialogContent>
    </Dialog>
  );
};

export default CustomDialog;

const MobileContent = ({ screenType }) => {
  const classes = useStyles({ screenType });
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} sm container>
        <Grid item xs container direction='column'>
          <Grid item>&nbsp;</Grid>
          <Grid item xs container className={classes.contentContainer}>
            <Grid item className={classes.content}>
              <div className={classes.header}>
                <AlertOutlined color='currentColor' width='40px' height='40px' />
                <span className={classes.title}>Attenzione</span>
                <span className={classes.subtitle}>
                  La finestra del dispositivo è troppo piccola.
                </span>
              </div>
            </Grid>
            <Grid item className={classes.content}>
              <div className={classes.icons}>
                <PhoneUncheck color='currentColor' width='40px' height='55px' />
                <DesktopCheck color='currentColor' width='116px' height='96px' />
                <TabletCheck color='currentColor' width='66px' height='50px' />
              </div>
            </Grid>
            <Grid item className={classes.content}>
              <div className={classes.extraText}>
                <div classeName='device-list'>
                  Puoi accedere al configuratore tramite:
                  <ul>
                    <li>Computer</li>
                    <li>iPad</li>
                    <li>iPad Pro</li>
                    <li>Tablet</li>
                  </ul>
                </div>
                <hr />
                <div className='highlight-text'>
                  <span>Cambia dispositivo</span> per poter accedere alla configurazione.
                </div>
                <hr />
              </div>
            </Grid>
            <Grid item className={classes.content}>
              <div className={classes.actionButtons}>
                <Button3D
                  text='Ho capito, torna a MY AI'
                  normalWeightText
                  onClick={() => {
                    document.location.href =
                      projectConfig[process.env.REACT_APP_PROJECT_ID]?.myAiUrl;
                  }}
                  className='button-length'
                />
              </div>
            </Grid>
          </Grid>
          <Grid item>&nbsp;</Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const TabletContent = ({ screenType }) => {
  const classes = useStyles({ screenType });
  return (
    <Grid container className={classes.root}>
      <Grid item>
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/dialog2.png`}
          alt='img for dialog'
          className={classes.image}
        />
      </Grid>
      <Grid item xs={12} sm container>
        <Grid item xs container direction='column'>
          <Grid item>&nbsp;</Grid>
          <Grid item xs container className={classes.contentContainer}>
            <Grid item className={classes.content}>
              <div className={classes.header}>
                <AlertOutlined color='currentColor' width='40px' height='40px' />
                <span className={classes.title}>Attenzione</span>
                <span className={classes.subtitle}>
                  Per un’esperienza migliore ruotare il dispositivo e mantenerlo in posizione
                  orizzontale.
                </span>
              </div>
            </Grid>
            <Grid item className={classes.content}>
              <div className={classes.icons}>
                <TabletUncheck color='currentColor' width='76px' height='71px' />
                <TabletOrientationCheck color='currentColor' width='95px' height='80px' />
              </div>
            </Grid>
            <Grid item className={classes.content}>
              <div className={classes.extraText}>
                <hr />
                <div className='highlight-text'>
                  Per poter continuare <span>ruotare il dispositivo</span>.
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid item>&nbsp;</Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const BrowserContent = ({ screenType }) => {
  const classes = useStyles({ screenType });

  return (
    <Grid container className={classes.root}>
      <Grid item>
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/dialog3.png`}
          alt='img for dialog'
          className={classes.image}
        />
      </Grid>
      <Grid item xs={12} sm container>
        <Grid item xs container direction='column'>
          <Grid item>&nbsp;</Grid>
          <Grid item xs container className={classes.contentContainer}>
            <Grid item className={classes.content}>
              <div className={classes.header}>
                <AlertOutlined color='currentColor' width='40px' height='40px' />
                <span className={classes.title}>Attenzione</span>
                <span className={classes.subtitle}>La finestra del browser è troppo piccola.</span>
              </div>
            </Grid>
            <Grid item className={classes.content}>
              <div className={classes.icons}>
                <DesktopWindowUncheck color='currentColor' width='101px' height='80px' />
                <DesktopWindowCheck color='currentColor' width='97px' height='81px' />
              </div>
            </Grid>
            <Grid item className={classes.content}>
              <div className={classes.extraText}>
                <div className='highlight-text'>
                  <span>Allargare la finestra</span> per poter continuare la configurazione o
                  utilizzare un dispositivo più grande.
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid item>&nbsp;</Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const DefaultContent = ({
  image,
  title,
  subtitle,
  labelButton1,
  button1OnClick,
  labelButton2,
  button2OnClick,
  handleClose,
}) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid item>
        <img src={image} alt='img for dialog' className={classes.image} />
      </Grid>
      <Grid item xs={12} sm container>
        <Grid item xs container direction='column'>
          <Grid item className={classes.icon}>
            <div onClick={handleClose} className={classes.exitIcon}>
              <Exit color='#fff' width='8px' height='8px' />
            </div>
          </Grid>
          <Grid item xs container className={classes.contentContainer}>
            <Grid item className={classes.content}>
              <div className={classes.header}>
                <span className={classes.title}>{title}</span>
                <span className={classes.subtitle}>{subtitle}</span>
              </div>
            </Grid>

            <Grid item className={classes.content}>
              <div className={classes.actionButtons}>
                <IconButton
                  text={labelButton1}
                  icon={<Plus color='currentColor' />}
                  onClick={button1OnClick}
                  className='button-length'
                />
                <Button3D text={labelButton2} onClick={button2OnClick} className='button-length' />
              </div>
            </Grid>
          </Grid>
          <Grid item>&nbsp;</Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
