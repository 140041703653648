import { ButtonBase, Grid, makeStyles } from '@material-ui/core';
import { Exit } from './Icons';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.55)',
    backdropFilter: 'blur(15px)',
    zIndex: theme.zIndex.modal,
  },
  closeIcon: {
    position: 'absolute',
    top: '30px',
    right: '30px',
    backgroundColor: '#000',
    height: '50px',
    width: '50px',
    borderRadius: '100%',
    zIndex: 10,
    '& svg': {
      height: '15px',
      width: 'auto',
    },
  },
  videoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '90%',
    height: '100%',

    '& .video-player': {
      width: '100%',
      height: 'auto',
      maxHeight: '80%',
    },
  },
}));

const VideoViewer = ({ urlVideo, handleClose }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction='column'
      className={classes.root}
      alignItems='center'
      justifyContent='center'
    >
      <ButtonBase className={classes.closeIcon} onClick={handleClose}>
        <Exit height='8px' width='8px' color='#F08219' />
      </ButtonBase>

      <Grid
        item
        xs
        container
        alignItems='center'
        justifyContent='center'
        className={classes.videoContainer}
      >
        <video
          className='video-player'
          playsInline
          autoPlay
          muted
          controls
          controlsList='nodownload nofullscreen noremoteplayback'
          disablePictureInPicture
          src={urlVideo}
        />
      </Grid>
    </Grid>
  );
};

export default VideoViewer;
