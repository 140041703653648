import { Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import StyleText from '../../utils/StyleText';
import DetailsContainer from './DetailsContainerNew';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    backgroundColor:'white',
    overflow: 'hidden auto',
    paddingRight: '10px',
    '&::-webkit-scrollbar': {
      width: '3px',
      paddingLeft: '10px',
      borderRadius: '2px',
    }
  },
}));

const GridDetailsContainer = ({ containers, numCols, onClick, selectedOptions, upInfo, filters, setFilter, filter, noDualImages }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mediaCols = useMediaQuery(theme.breakpoints.down('lg')) && isMobile ? 2 : 3;
  const numContainers = containers.length;
  const colsCount = (content) => {
    return numContainers > 1
      ? content.length > 1
        ? content.length % 2 === 0
          ? 2
          : mediaCols
        : 1
      : content.length % 2 === 0
      ? content.length > 2
        ? 2
        : 1
      : mediaCols;
  };
  return (
    <div className={classes.root}>
      <Grid container direction='column'>
        {containers.map((el, i) => (
          <Grid
            key={i}
            item
            xs={el.noFullHeight ? 'auto' : true}
          >
            <DetailsContainer
              selectedOptions={selectedOptions}
              title={StyleText(el.title)}
              numCols={numCols ? numCols : colsCount(el.content)}
              details={el.content}
              noSelection={el.noSelection}
              selectedMode={el.selectedMode}
              noFullHeight={el.noFullHeight}
              stepMode={el.stepMode}
              onClick={onClick}
              filters={filters}
              upInfo={upInfo}
              setFilter={setFilter}
              filter={filter}
              noDualImages={noDualImages}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default GridDetailsContainer;
