import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { StoreProvider } from './store/storeUtils';
import Store from './store/store';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import theme from './styles/theme';
import { CssBaseline } from '@material-ui/core';
import { WinstonProvider } from 'winston-react/dist/context';
import logger from './logger';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from './components/ErrorPage';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <WinstonProvider logger={logger}>
          <StoreProvider value={new Store()}>
            <CssBaseline />
            <App />
          </StoreProvider>
        </WinstonProvider>
      </ErrorBoundary>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
