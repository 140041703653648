import { toJS } from 'mobx';
import { getAnswerDetail } from '../services/answerApi';

export const prepareRoomsInfo = (rooms) => {
  const roomsData = {};
  const roomsInfo = [];
  if (rooms) {
    rooms.forEach((room) => {
      const currentRoomInfoValue = roomsData[room.roomType.name];

      roomsData[room.roomType.name] = currentRoomInfoValue ? currentRoomInfoValue + 1 : 1;
    });

    for (const [key, value] of Object.entries(roomsData)) {
      roomsInfo.push({ roomType: key, value });
    }
  }

  return roomsInfo;
};

export const getDoorsLayers = (store) => {
  try {
    const doorTag = store.currentConfiguration.layout.tags.find((el) => el.key === 'DT');
    const doorsLayers = doorTag?.fileLabels.map((el) => el.url);
    return doorsLayers;
  } catch (e) {
    console.log('getDoorsLayer', e);
  }
};

export const getOpaqueWindowsLayers = (store) => {
  try {
    const opaqueWindowsTag = store.currentConfiguration.layout.tags.find((el) => el.key === 'WTO');
    const opaqueWindowsLayers = opaqueWindowsTag?.fileLabels.map((el) => el.url);
    return opaqueWindowsLayers;
  } catch (e) {
    console.log('getOpaqueWindowsLayers', e);
  }
};

export const checkRoomType = (rooms, roomId, roomValue) => {
  const roomData = rooms.find((room) => room.id === roomId);
  if (roomData?.roomType?.name?.toLowerCase().includes(roomValue)) {
    return true;
  }

  return false;
};

export const getRoomsInfoToShow = (store, iconMap, elementsToSum) => {
  const rooms = toJS(store.currentConfiguration?.layout?.rooms);

  const roomsInfo = rooms?.map((room) => {
    let name = room.name.toLowerCase();

    const sameRooms = store.currentConfiguration?.layout?.rooms.filter(
      (el) => el.roomType.id === room.roomType.id,
    );

    if (sameRooms.length === 1) {
      name = room.roomType.name.toLowerCase();
    }

    let number = 0;
    elementsToSum.forEach((el) => {
      number += room[el];
    });

    return {
      name,
      icon: iconMap[room.roomType.name],
      number,
    };
  });

  return roomsInfo.filter((el) => el.number !== 0);
};

export const getTagImageLayersByKey = (tags = [], key) => {
  return tags.find((tag) => tag?.key === key)?.fileLabels.map((el) => el?.url);
};

export const getAllImpiantoElettricoLayers = async (tags, configuration) => {
  // Recupero i layer per l'impianto elettrico
  const impiantoElettrico = getTagImageLayersByKey(tags, 'ELETTRICO');
  // Recupero eventuali altri layer del gruppo elettrico dalle risposte date in precedenza
  const answerResponse = await getAnswerDetail(configuration);
  const answerData = answerResponse.data;

  const othersImpiantoElettricoLayers = [];
  answerData.forEach((el) => {
    el.optionGroupPrefixes?.forEach((optionGroupPrefix) => {
      optionGroupPrefix.optionGroups.forEach((optionGroup) => {
        optionGroup.options.forEach((option) => {
          const impiantoElettricoLayer = option?.fileLabels?.find(
            (fileLabel) => fileLabel.isElettrico,
          );
          if (impiantoElettricoLayer) {
            othersImpiantoElettricoLayers.push(impiantoElettricoLayer.url);
          }
        });
      });
    });
  });

  return [...[impiantoElettrico], ...othersImpiantoElettricoLayers];
};
