import { Box, ButtonBase, Grid, makeStyles, Popover } from '@material-ui/core';
import IconButton from '../Buttons/IconButton';
import CurrencyFormatter from '../../utils/CurrencyFormatter';
import { Alert, Camera, Ruler, OutlinedStar, Plus } from '../Icons';
import PlateContainer from './PlateContainer';
import clsx from 'clsx';
import ImageZoomButton from '../Buttons/ImageZoomButton';
import { useRef, useState } from 'react';
import InfoPopup, { PhaseInfoContent } from '../InfoPopup';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',

    '& .card-root': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: ({ whiteMode }) =>
        whiteMode ? theme.palette.color.white : theme.palette.color.grey1,
      border: ({ Tags, whiteMode }) =>
        Tags?.includes('sale')
          ? `2px solid ${theme.palette.color.yellow}`
          : whiteMode
          ? 'none'
          : `1px solid ${theme.palette.color.grey2}`,
    },

    '&.white-mode': {
      '& .card-root': {
        backgroundColor: '#FFF',
      },
      '& .element-icon svg': {
        color: '#000',
        fill: '#000',
        height: '40px',
        width: 'auto',
      },
    },
    '& .dimension': {
      color: '#E52421',
      fontSize: '11px',
    },
    '& .cancel-button': {
      display: 'flex',
      alignItems: 'flex-end',
      height: '100%',
      paddingBottom: '10px',
      '& button': {
        fontSize: '11px',
        fontWeight: 700,
        textDecoration: 'underline',
      },
    },
  },

  wrapper: {
    width: '100%',
    padding: '18px 16px 0',
  },
  recommended: {
    backgroundColor: '#F0D80A',
    fontFamily: 'miller',
    fontStyle: 'italic',
    fontWeight: 'normal',
    fontSize: 13,
    lineHeight: '16px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px',
    whiteSpace: 'nowrap',
    '& svg': {
      marginRight: '8px',
    },
    '& path:nth-child(2)': {
      fill: '#F0D80A',
    },
    '&.hidden': {
      visibility: 'hidden',
    },
  },
  accept: {
    width: '100%',
    fontSize: '13px',
    textAlign: 'center',
    lineHeight: '15px',
  },
  model: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    margin: '0 0 15px',
    fontSize: '15px',
    '& .modelNumber': {
      textAlign: 'center',
    },
    '& .modelType': {
      fontStyle: 'italic',
      fontSize: '13px',
    },
    '& .uppercase-name': {
      fontWeight: 'normal !important',
      textTransform: 'uppercase',
    },
    '& svg': {
      width: 'auto',
      height: '75px',
      maxWidth: '200px',
      maxHeight: '75px',
    },
  },
  selectionButton: {
    width: '100%',
    padding: '20px 10px 15px',
    flexGrow: 1,
  },
  price: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    borderTop: `1px solid ${theme.palette.color.grey2}`,
    height: '30px',
    fontSize: '11px',
    lineHeight: '13px',
    '& .price-description': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      width: '50%',
      height: '100%',
      padding: '17px 20px 14px 10px',
      backgroundColor: theme.palette.color.yellow,
      clipPath: 'polygon(0 0, 100% 0, 95% 100%, 0% 100%)',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      fontSize: '10px',
    },
    '& .price-value': {
      display: 'flex',
      flexDirection: 'row',
      //   justifyContent: 'space-between',
      alignItems: 'center',
      paddingRight: '14px',
      fontSize: '12px',
      lineHeight: '15px',
      color: theme.palette.primary.main,
      '& svg': {
        marginRight: '8px',
        cursor: 'pointer',
      },
      '& span': {
        paddingTop: '3px',
        whiteSpace: 'nowrap',
        color: '#A15117',
      },
    },
  },
  thin: {
    fontWeight: 400,
  },
  bold: {
    fontWeight: 600,
  },
  bolder: {
    fontWeight: 750,
  },
  iconTitleDescription: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '15px',
    marginTop: '40px',
    fontSize: '13px',
    lineHeight: '16px',
  },
  sideTitle: {
    maxWidth: '115px',
    paddingLeft: '15px',
    textTransform: 'uppercase',
    borderLeft: '1px solid black',
  },
  iconTitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
    width: '100%',
    marginTop: '30px',
  },
  title: {
    marginBottom: '20px',
    fontSize: '13px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    textAlign: 'center',
    padding: '0 25px',
    height: '32px',
    '&.addMessage': {
      fontWeight: 600,
      textAlign: 'center',
    },
  },
  description: {
    maxWidth: '130px',
    margin: '20px 0 10px',
    fontSize: '13px',
    lineHeight: '16px',
    textAlign: 'center',
  },
  iconDescription: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: '20px',
  },
  addMessage: {
    marginBottom: '-7px',
  },
  popover: {
    backgroundColor: 'rgba(0,0,0,0) !important',
    borderTopLeftRadius: '90px',
  },
  image: {
    width: 'auto',
    height: '75px',
    maxWidth: '200px',
    maxHeight: '75px',
  },
}));

const Card = ({
  name: propName,
  Tags,
  price,
  cardMessage,
  cardMessagePlus,
  icon,
  title,
  subtitle,
  description: propDescription,
  whiteMode,
  sideTitle,
  bottomTitle,
  multiple,
  upperCaseName,
  addMessage,
  isSelected,
  onClick,
  defaultImage,
  positions,
  plateText,
  hideName,
  cardImage,
  cardIcon,
  unit,
  dimension,
  numbers,
  hidePositions,
  hideDescription,
  forcedDescription,
  products,
  addRoomInfo,
  roomFrom,
  roomType,
  useOptionPosition,
  position,
  onMouseEnter,
  onMouseLeave,
  fileLabels,
}) => {
  const classes = useStyles({ Tags });

  const [showInfo, setShowInfo] = useState(false);

  const infoRef = useRef();

  const infoData = {
    phase: 'VALORE AGGIUNTO',
    firstRow: 'Puoi monitorare le tue aggiunte nella sezione Riepilogo',
    secondRow:
      "Selezionandolo aumenterà il prestigio del tuo preventivo. Avrai sempre la possibilità di monitorare le tue aggiunte con valore superiore nella sezione 'Recap scelte'",
    thirdRow: 'I prezzi sono da intendersi iva esclusa.',
  };

  const description = hideDescription
    ? ''
    : forcedDescription
    ? forcedDescription
    : propDescription;

  const platePositions = hidePositions
    ? null
    : useOptionPosition
    ? [position]
    : positions
    ? positions
    : numbers?.map((el) => el.number);

  const images = products?.length > 0 ? [...new Set(products.map((el) => el.imageFileUrl))] : null;

  const pdfs =
    products?.length > 0 ? [...new Set(products.map((el) => el.dataSheetFileUrl))] : null;
  const name = hideName ? '' : propName;

  return (
    <Grid
      container
      direction='column'
      className={clsx(classes.root, whiteMode && 'white-mode')}
      onMouseEnter={onMouseEnter ? () => onMouseEnter(fileLabels) : null}
      onMouseLeave={onMouseLeave}
    >
      <Grid
        item
        xs={'auto'}
        className={clsx(
          classes.recommended,
          ((Tags && !Tags.includes('Recommended')) || !Tags) && 'hidden',
        )}
      >
        <OutlinedStar color='#000' width='15px' height='15px' />
        <span>Cosigliato dall'architetto</span>
      </Grid>
      <Grid item className={'card-root'} xs>
        {whiteMode ? (
          sideTitle && !bottomTitle ? (
            <IconTitleDescription
              icon={icon}
              title={title}
              description={description}
              cardMessage={cardMessage}
              positions={platePositions}
              plateText={plateText}
            />
          ) : !sideTitle && bottomTitle ? (
            <IconTitle icon={icon} title={name} addMessage={addMessage} />
          ) : (
            <IconDescription
              icon={icon}
              description={
                <>
                  <div>{description}</div>
                  {addRoomInfo && roomType?.name && (
                    <div>
                      <b>in {roomType.name.toLowerCase()}</b>
                    </div>
                  )}
                </>
              }
              cardMessage={cardMessage}
              positions={platePositions}
              plateText={plateText}
            />
          )
        ) : (
          <DefaultContent
            name={name}
            subtitle={subtitle}
            cardMessage={cardMessage}
            cardMessagePlus={cardMessagePlus}
            upperCaseName={upperCaseName}
            positions={platePositions}
            plateText={plateText}
            cardIcon={cardIcon}
            cardImage={cardImage}
            unit={unit}
            dimension={dimension}
          />
        )}
        {images && (
          <ImageZoomButton
            imageSrc={images}
            label={'Foto'}
            icon={<Camera color='#878787' width='15px' height='15px' />}
          />
        )}
        {pdfs && (
          <ImageZoomButton
            imageSrc={pdfs}
            label={'Scheda Tecnica'}
            isPdf
            icon={<Ruler color='#878787' width='15px' height='15px' />}
          />
        )}
        <Grid
          container
          className={classes.selectionButton}
          justifyContent={multiple && isSelected ? 'space-between' : 'flex-end'}
          alignItems='flex-end'
        >
          {multiple && isSelected && (
            <Grid item xs='auto' className='cancel-button'>
              <ButtonBase onClick={multiple & isSelected ? onClick : null}>ELIMINA</ButtonBase>
            </Grid>
          )}

          <Grid item xs='auto'>
            <IconButton
              text={multiple ? 'Aggiungi' : 'Seleziona'}
              icon={<Plus color='currentColor' width='10px' height='10px' />}
              onClick={multiple & isSelected ? null : onClick}
              isSelected={isSelected}
            />
          </Grid>
        </Grid>
        <div className={classes.price}>
          <div
            className={`price-description ${classes.bold}`}
            style={{ visibility: Tags?.includes('sale') ? 'visible' : 'hidden' }}
          >
            <span>{Tags?.includes('sale') ? 'Sconto del 20%' : "L'opzione piú scelta"}</span>
          </div>
          <div className={`price-value ${classes.bold}`} ref={infoRef}>
            <Alert
              color='currentColor'
              width='15px'
              height='15px'
              onClick={() => {
                setShowInfo(true);
              }}
            />
            <span>+ {CurrencyFormatter(price)}</span>
          </div>
          <Popover
            open={showInfo}
            anchorEl={infoRef.current}
            onClose={() => setShowInfo(false)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            PaperProps={{ classes: { root: classes.popover } }}
          >
            <InfoPopup
              body={<PhaseInfoContent {...infoData} />}
              isPopover
              handleClose={() => setShowInfo(false)}
            />
          </Popover>
        </div>
      </Grid>
    </Grid>
  );
};

export default Card;

const IconTitle = ({ icon, title, addMessage }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.iconTitle, 'element-icon')}>
      {icon}
      {addMessage && <div className={classes.addMessage}>Aggiungi</div>}
      <div className={clsx(classes.title, addMessage && 'addMessage')}>{title}</div>
    </div>
  );
};
const IconDescription = ({ icon, cardMessage, description, positions, plateText }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.iconDescription, 'element-icon')}>
      {icon}
      <div className={classes.description}>
        <div>{cardMessage}</div>
        <div>{description}</div>
        {positions && (
          <Box marginTop='15px'>
            <PlateContainer elements={positions} plateText={plateText} />
          </Box>
        )}
      </div>
    </div>
  );
};
const IconTitleDescription = ({ icon, title, description, cardMessage, positions, plateText }) => {
  const classes = useStyles();
  return (
    <>
      <div className={clsx(classes.iconTitleDescription, 'element-icon')}>
        {icon}
        <div className={classes.sideTitle}>{description}</div>
      </div>

      <div className={classes.description}>
        <div>{cardMessage}</div>
        {positions && (
          <Box marginTop='15px'>
            <PlateContainer elements={positions} plateText={plateText} />
          </Box>
        )}
      </div>
    </>
  );
};

// const FixtureModel = ({ plateElements, imageName, imageSubtitle, plateText }) => {
//   return (
//     <>
//       <PlateContainer elements={plateElements} plateText={plateText} />
//       <SubtitledImage
//         image={`${process.env.PUBLIC_URL}/assets/icons/${imageName}.svg`}
//         subtitle={imageSubtitle}
//         look='capitalize'
//       />
//     </>
//   );
// };

const DefaultContent = ({
  subtitle,
  cardMessage,
  upperCaseName,
  positions,
  cardMessagePlus,
  name,
  plateText,
  dimension,
  unit,
  cardImage,
  cardIcon,
}) => {
  const classes = useStyles();

  let message = cardMessage;
  if (cardMessage && cardMessage.includes('<D>')) {
    message = cardMessage.replace('<D>', cardMessagePlus);
  }

  return (
    <div className={classes.wrapper}>
      {cardMessage && (
        <div className={classes.accept}>
          <span className={classes.bold}>SI</span>, {message ? message : 'aggiungo questo modello.'}
        </div>
      )}

      {positions && (
        <Box marginTop='15px'>
          <PlateContainer elements={positions} plateText={plateText} />
        </Box>
      )}
      <div className={classes.model}>
        <span className={clsx('modelNumber', classes.bold, upperCaseName && 'uppercase-name')}>
          {name}
        </span>
        {cardIcon && cardIcon}
        {cardImage && <img src={cardImage} alt={cardImage} className={clsx(classes.image)} />}
        {dimension && <div className={'dimension'}>{`${dimension} ${unit}`}</div>}
        {subtitle && <span className={`modelType`}>{subtitle}</span>}
      </div>
    </div>
  );
};
