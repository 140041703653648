import * as React from 'react';

function SvgTriangle(props) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 16 36' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M16 18L0 36V0l16 18z' fill={props.color} />
    </svg>
  );
}

export default SvgTriangle;
