import * as React from 'react';

function SvgPreseCabinaArmadio(props) {
  return (
    <svg viewBox='0 0 77 49' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#prese-cabina-armadio_svg__clip0_2758_23695)' fill='#C4C4C4'>
        <path d='M26.141.06c-3.75.07-6.83 3.03-6.98 6.74-.22 2.79 1.23 5.4 3.8 6.8.85.41 1.66.67 2.54.82v2.67L1.471 34.43c-1.28.93-1.79 2.56-1.26 3.97.54 1.55 1.91 2.56 3.51 2.56h26.46v-2.44H3.651c-.52 0-1.01-.35-1.11-.79-.18-.52-.01-.99.44-1.33l24.89-17.98c.08-.06.13-.16.13-.26v-5.68c0-.17-.15-.32-.32-.32h-.93c-.88-.06-1.77-.3-2.64-.72-1.62-.89-2.59-2.7-2.46-4.61.21-2.47 2.25-4.33 4.74-4.33 3.01-.33 5.69 1.68 6.03 4.49a1.176 1.176 0 001.47.96c.31-.04.61-.23.8-.52.18-.28.23-.61.14-.92-.58-4.04-4.48-6.95-8.69-6.45zM51.371 32.64v.81H53.841v-6.18h-2.47v5.37zM43.969 27.81v5.62h2.47v-6.16h-2.47v.54z' />
        <path d='M61.24 20.78c0-.73-.29-1.42-.8-1.93a2.71 2.71 0 00-1.93-.81h-19.2c-.72 0-1.42.29-1.93.8-.51.52-.8 1.21-.81 1.94v19.19c0 .34.07.68.2.99.13.35.34.67.61.94.52.51 1.21.8 1.93.8h19.2c.72 0 1.41-.29 1.93-.8.51-.51.8-1.21.8-1.93V20.78zm-4.13 15.07c-.4.6-.86 1.14-1.37 1.64a9.94 9.94 0 01-3.06 2c-1.8.74-3.78.94-5.7.56a9.733 9.733 0 01-3.63-1.53c-.51-.34-.98-.73-1.42-1.17a9.87 9.87 0 01-2.7-5.05c-.3-1.52-.24-3.08.18-4.55.1-.39.23-.77.38-1.15.17-.4.36-.78.57-1.15a9.64 9.64 0 013.07-3.28 9.81 9.81 0 015.48-1.66c1.29-.01 2.58.25 3.77.75 1.2.49 2.29 1.22 3.2 2.13.91.91 1.65 2.01 2.14 3.21.5 1.19.75 2.48.75 3.77 0 1.95-.58 3.86-1.66 5.48zM72.85 29.06h1.11c.14 0 .25-.11.25-.25v-4.74c0-.11 0-.22-.05-.33a.75.75 0 00-.18-.28.75.75 0 00-.28-.18c-.06-.02-.12-.04-.19-.04a.169.169 0 00-.1-.03c-.11-.01-.22 0-.33.05-.1.04-.2.1-.28.18a.75.75 0 00-.18.28c-.04.1-.05.22-.05.3v4.79c0 .14.11.25.25.25h.03zM66.57 29.04h1.05c.14 0 .25-.12.25-.25v-4.74a.855.855 0 00-.04-.32.75.75 0 00-.18-.28.75.75 0 00-.28-.18.86.86 0 00-.32-.05c-.09.01-.18.03-.26.06a1 1 0 00-.26.18.77.77 0 00-.17.27c-.04.11-.05.21-.04.3v4.76c0 .13.11.25.25.25zM76.269 37.1v-6.01a.858.858 0 00-.77-.97h-10.58c-.12.01-.23.04-.33.1a.87.87 0 00-.43.52c-.03.11-.04.23-.03.31v6.01c-.01.12 0 .24.03.35a.87.87 0 00.43.52c.11.05.22.08.35.09h10.59c.11-.01.22-.05.31-.1.1-.06.19-.13.26-.22s.12-.19.15-.3a.64.64 0 00.02-.3zM71.808 39.21h-3.17c-.14 0-.25.11-.25.25v1.06c0 .13.11.25.25.25h.81v3.3a2.52 2.52 0 01-.74 1.67c-.01.01-.03.02-.04.04-.44.42-1.02.67-1.61.7h-5.82c-.14 0-.25.11-.25.25v1.06c0 .13.11.25.25.25h5.82c.6 0 1.14-.12 1.61-.32 1.48-.62 2.34-2.03 2.34-3.11v-3.84h.8c.14 0 .25-.12.25-.25v-1.06c0-.14-.11-.25-.25-.25z' />
      </g>
      <defs>
        <clipPath id='prese-cabina-armadio_svg__clip0_2758_23695'>
          <path fill='#fff' d='M0 0h76.28v48.04H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgPreseCabinaArmadio;
