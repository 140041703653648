import * as React from 'react';

function SvgPlay(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 14 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M13.53 10.157a1 1 0 010 1.552L2.549 20.642a1 1 0 01-1.631-.776V2a1 1 0 011.631-.776l10.98 8.933z'
        fill='#fff'
      />
    </svg>
  );
}

export default SvgPlay;
