import { lighten, makeStyles } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import clsx from 'clsx';
import { Alert } from '../Icons';

const useStyles = makeStyles((theme) => ({
  button3dRoot: {
    color: theme.palette.color.white,
    fontWeight: 400,
    textTransform: 'uppercase',
    borderRadius: '16px',
    transition: 'all 0.3s ease-in-out',
    paddingTop: '5px',
    height: '45px',
    padding: '5px 25px 0px',
    whiteSpace: 'nowrap',
    letterSpacing: '0.1em',
    borderBottomWidth: '4px',
    borderBottomStyle: 'solid',
  },
  disabled: {
    backgroundColor: `${theme.palette.color.disabledGrey} !important`,
    borderBottomColor: `${lighten(theme.palette.color.disabledGrey, 0.4)} !important`,
    pointerEvents: 'none',
  },
  disabledText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '8px',
    marginTop: '10px',
    color: theme.palette.color.disabledGreyText,
    textTransform: 'none',
    textAlign: 'left',
    fontWeight: '500',
    fontSize: '13px',
    lineHeight: '14px',
    '& svg': {
      minWidth: '15px',
    },
  },
  orange: {
    backgroundColor: theme.palette.primary.main,
    borderBottomColor: `${lighten(theme.palette.primary.main, 0.4)}`,
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.2),
    },
    '&:active': {
      backgroundColor: lighten(theme.palette.primary.main, 0.4),
    },
  },
  green: {
    backgroundColor: theme.palette.secondary.main,
    borderBottom: `4px solid ${lighten(theme.palette.secondary.main, 0.4)}`,
    borderBottomColor: `${lighten(theme.palette.secondary.main, 0.4)}`,

    '&:hover': {
      backgroundColor: lighten(theme.palette.secondary.main, 0.2),
    },
    '&:active': {
      backgroundColor: lighten(theme.palette.secondary.main, 0.4),
    },
  },
  bold: {
    fontWeight: 600,
  },
}));

const Button3D = ({
  text,
  icon,
  color = 'green',
  reverseTextStyle,
  disabled,
  disabledText,
  onClick,
  className,
  normalWeightText,
  type,
}) => {
  const classes = useStyles();

  const styleText = (text, reverse = false) => {
    const firstPart = text?.substr(0, text?.indexOf(' '));
    const secondPart = text?.substr(text?.indexOf(' ') + 1);
    return (
      <div>
        <span className={reverse ? classes.bold : ''}>{firstPart}</span>
        {firstPart && ' '}
        <span className={!reverse ? classes.bold : ''}>{secondPart}</span>
      </div>
    );
  };

  return (
    <>
      <ButtonBase
        className={clsx(
          classes.button3dRoot,
          disabled && classes.disabled,
          classes[color],
          className,
        )}
        onClick={onClick}
        disableRipple
        type={type}
      >
        {normalWeightText ? text : styleText(text, reverseTextStyle)}
        {icon && icon}
      </ButtonBase>
      {disabled && disabledText ? (
        <div className={classes.disabledText}>
          <Alert color='currentColor' width='15px' height='15px' />
          <span>{disabledText}</span>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default Button3D;
