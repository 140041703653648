import * as React from 'react';

function SvgIlluminazioneLavanderia(props) {
  return (
    <svg viewBox='0 0 61 50' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#illuminazione-lavanderia_svg__clip0_2758_24046)' fill='#C4C4C4'>
        <path d='M36.23 47.62H9.85L4.21 25.36c.81.13 1.51.35 2.15.68 1.31.66 2.89 1.03 4.46 1.03 1.54.05 3.09-.3 4.48-1.03 1.09-.55 2.28-.82 3.47-.77 1.16-.06 2.36.22 3.45.77 1.31.66 2.89 1.03 4.46 1.03.28.01.57 0 .85-.02v-2.28c-.26.02-.53.02-.79.01-1.17.06-2.36-.21-3.45-.76-1.31-.67-2.9-1.03-4.47-1.03-1.53-.05-3.09.3-4.48 1.03-1.09.55-2.28.82-3.46.76-1.17.07-2.36-.21-3.45-.76-1.12-.6-2.42-.95-3.77-1.03l-1.3-5.09c-.06-.32-.26-.59-.54-.75-.27-.16-.59-.2-.86-.11-.33.07-.61.24-.78.5-.17.25-.22.56-.16.88l7.7 30.64c.13.51.57.86 1.03.86.04 0 .07-.01.11-.01h28.31c.5 0 .99-.36 1.14-.85l1.94-7.61h-2.47l-1.55 6.17zM45.88 33.93h-8.06c-.69 0-1.25.57-1.25 1.26 0 .69.56 1.26 1.25 1.26h8.06c.69 0 1.25-.56 1.25-1.26s-.56-1.26-1.25-1.26zM49.36 10.97c-.34-.33-.71-.64-1.09-.91-1.83-1.35-4.06-2.07-6.39-2.07-2.83 0-5.49 1.05-7.49 2.98-2.01 1.93-3.12 4.5-3.12 7.24 0 2.54.97 4.97 2.72 6.84 1.78 2.11 2.61 6.05 2.61 6.08l.17.82h10.3l.02-.99c.07-2.88 2.81-6.06 2.93-6.19 1.59-1.84 2.47-4.17 2.47-6.56 0-1.62-.39-3.18-1.12-4.58-.51-.98-1.18-1.87-2.01-2.66zm-.88 12.46c-.29.33-2.8 3.29-3.33 6.48h-6.74c-.35-1.34-1.26-4.28-2.91-6.23a7.95 7.95 0 01-2.2-5.47c0-4.51 3.85-8.19 8.58-8.19s8.57 3.68 8.57 8.19c0 1.9-.7 3.76-1.97 5.22zM43.008 0h-2.27v4.64h2.27V0zM54.183 3.931l-2.98 3.557 1.74 1.458 2.98-3.557-1.74-1.458zM60.25 16.24h-4.64v2.27h4.64v-2.27z' />
        <path d='M41.96 23.52l-2.91-2.91-1.41 1.41 4.32 4.33 4.1-4.1-1.42-1.41-2.68 2.68z' />
      </g>
      <defs>
        <clipPath id='illuminazione-lavanderia_svg__clip0_2758_24046'>
          <path fill='#fff' d='M0 0h60.25v49.92H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIlluminazioneLavanderia;
