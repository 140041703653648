import { Grid, makeStyles, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import StyleText from '../../utils/StyleText';
import Button3D from '../Buttons/Button3D';
import SvgAlert from '../Icons/Alert';
import SvgCheck from '../Icons/Check';
import SvgNext from '../Icons/Next';
import { useEffect, useState } from 'react';
import { getConfigurationPercentage } from '../../services/configurationApi';
import { Edificio, Piano, Quadrante, Scala } from '../Icons';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '20px 22px 32px',
    backgroundColor: theme.palette.color.white,
    height: '100%',
  },
  apartmentBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '24px 28px',
    color: theme.palette.color.black,
    marginBottom: '8px',
    '&.completed': {
      backgroundColor: '#E9F1DF',
    },
    '&.progressing': {
      backgroundColor: '#F9F3EE',
    },
    '& .order': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // width: '56px',
      height: '56px',
      marginBottom: '12px',
      backgroundColor: theme.palette.color.white,
      color: theme.palette.color.disabledGreyText,
      borderRadius: '24px',
      fontSize: '32px',
      textTransform: 'uppercase',
      paddingTop: '6px',
      padding: '0 8px',
    },
    '& .apartment-type': {
      fontSize: '18px',
      lineHeight: '22px',
      textTransform: 'uppercase',
    },
  },
  surface: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'felx-start',
    marginTop: '8px',
    fontSize: '18px',
    '&:first-child': {
      marginRight: '18px',
    },
    '&:last-child': {
      marginLeft: '18px',
    },
    '& .value': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      width: '100%',
      borderBottom: `1px solid ${theme.palette.color.grey2}`,
      '& img': {
        width: '20px',
        height: '20px',
      },
    },
    '& .type': {
      paddingTop: '5px',
      textTransform: 'capitalize',
      fontSize: '13px',
    },
  },
  statusBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    marginTop: '24px',
    padding: '10px 0',
    '&.completed': {
      justifyContent: 'flex-start',
      paddingLeft: '24px',
      backgroundColor: '#E9F1DF',
      color: theme.palette.secondary.main,
      borderRadius: '5px',
      '& img': {
        marginRight: '12px',
      },
    },
    '&.progressing': {
      justifyContent: 'center',
      backgroundColor: '#F9F3EE',
      color: theme.palette.color.black,
      textTransform: 'uppercase',
    },
  },
  configurationsRoot: {
    padding: '16px 0',
    '&.no-padding-bottom': {
      paddingBottom: '0',
    },
  },
  configurationsList: {
    backgroundColor: '#F9F3EE',
    padding: '10px 16px',
    width: '100%',
    height: '100%',
    minHeight: '200px',
    '& > div': {
      width: '100%',
    },
    '& .no-configurations': {
      width: '100%',
      height: '100%',
      '& > span': {
        fontSize: 13,
        lineHeight: '16px',
        marginBottom: '16px',
      },
    },
  },
  configurationsListTitle: {
    backgroundColor: '#FFF',
    width: '100%',
    textAlign: 'center',
    lineHeight: 1,
    padding: '12px 0 8px',
    fontSize: '13px',
  },
  configuration: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '15px',
    padding: '10px 0',
    color: '#5E5E5E',
    borderTop: '1px solid #C4C4C4',
    borderRadius: '2px',
    '&:first-child': {
      borderTop: 0,
      marginTop: '15px',
    },
    '&:last-child': {
      paddingBottom: 0,
    },
    '&.completed': {
      color: theme.palette.secondary.main,
    },
  },
  configurationProgress: {
    minWidth: '60px',
    textAlign: 'center',
    backgroundColor: '#FFF',
    fontWeight: 700,
    color: theme.palette.primary.main,
    fontSize: '13px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: '1',
    paddingTop: '4px',
    '&.completed': {
      color: theme.palette.secondary.main,
      backgroundColor: '#E9F1DF',
    },
  },
  indicator: {
    marginRight: '8px',
  },
  inProgressIndicator: {
    display: 'inline-flex',
    width: '11px',
    height: '12px',
    border: '2px solid #F08219',
    boxSizing: 'border-box',
    borderRadius: '4px',
  },
  configurationStatus: { paddingRight: '8px', lineHeight: '14px' },
  configurationsSent: {
    width: '100%',
    color: theme.palette.secondary.main,
  },
  configurationsSentTitle: {
    width: '100%',
    background: '#E9F1DF',
    borderRadius: '5px',
    padding: '10px 20px',
  },
  configurationsSentInfo: {
    display: 'flex',
    fontSize: '13px',
    color: '#5E5E5E',
    lineHeight: '14px',
    padding: '20px 0 11px',
    borderBottom: '0.5px solid #C4C4C4',
    justifyContent: 'flex-start',
    textAlign: 'center',

    '&:last-child': {
      justifyContent: 'space-between',
    },
    '&.orange': {
      color: theme.palette.primary.main,
      paddingTop: '14px',
      alignItems: 'center',
      borderBottom: 0,
    },
  },
  configurationsSentNext: {
    display: 'flex',
    color: theme.palette.primary.main,
    lineHeight: '14px',
    fontSize: '13px',
  },
}));

const ApartmentInfo = ({ value, icon, type }) => {
  const classes = useStyles();

  return (
    <div className={classes.surface}>
      <div className='value'>
        {icon}
        <span>{value}</span>
      </div>
      <span className='type'>{StyleText(`${type}`)}</span>
    </div>
  );
};

const ConfigurationElement = ({ id, value }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [progress, setProgress] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    getProgress();
  }, []);

  const getProgress = async () => {
    try {
      const configurationPercentage = await getConfigurationPercentage(id);
      const progress = Number(configurationPercentage.data * 100).toFixed(0) * 1;

      setProgress(progress);
      setIsCompleted(progress === 100);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={clsx(classes.configuration, isCompleted && 'completed')}>
      <div className={classes.configurationStatus}>
        {isCompleted ? (
          <SvgCheck
            color={theme.palette.secondary.main}
            height='8px'
            width='11px'
            className={clsx(classes.indicator)}
          />
        ) : (
          <div className={clsx(classes.indicator, classes.inProgressIndicator)} />
        )}
        {value} <b>{isCompleted ? 'completata' : 'in sviluppo'}</b>
      </div>
      <div
        className={clsx(classes.configurationProgress, isCompleted && 'completed')}
      >{`${progress} %`}</div>
    </div>
  );
};

const ConfigurationsList = ({ items, handleStartNewConfiguration }) => {
  const classes = useStyles();

  return (
    <Grid container direction={'column'} className={classes.configurationsList}>
      {items?.length > 0 ? (
        <>
          <Grid item xs={'auto'} className={classes.configurationsListTitle}>
            LE TUE <b>CONFIGURAZIONI</b>
          </Grid>
          <Grid item>
            {items && items.map((el, i) => <ConfigurationElement key={i} {...el} />)}
          </Grid>
        </>
      ) : (
        <Grid
          item
          container
          direction='column'
          alignItems='center'
          justifyContent='center'
          alignContent='center'
          className={'no-configurations'}
        >
          <span>NON HAI ANCORA CONFIGURAZIONI</span>
          <Button3D
            text='INIZIA A CONFIGURARE'
            color='orange'
            onClick={handleStartNewConfiguration}
          />
        </Grid>
      )}
    </Grid>
  );
};

const ConfigurationSent = ({ items }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.configurationsSent}>
      <div className={classes.configurationsSentTitle}>
        <SvgCheck
          color={theme.palette.secondary.main}
          height='8px'
          width='11px'
          className={clsx(classes.indicator)}
        />
        Configurazione <b>inviata</b>
      </div>
      <div className={classes.configurationsSentInfo}>
        <SvgAlert height='15px' width='16px' className={classes.indicator} />
        <div>La tua configurazione è stata inviata ed è in fase di valutazione.</div>
      </div>
      <div className={clsx(classes.configurationsSentInfo, 'orange')}>
        <div>
          <b>Prosegui per</b> poter selezionare le <b>scelte d’arredo.</b>
        </div>

        <SvgNext height='18px' width='27px' className={classes.indicator} />
      </div>
    </div>
  );
};

const ApartmentCard = ({
  id,
  apartment,
  superficieTotalePostVendita = 0,
  superficieTerrazzoPostVendita = 0,
  configurations,
  completed,
  quadrante,
  handleStartNewConfiguration,
  handleViewConfigurations,
  piano,
  scala,
  building,
  codiceAppartamento,
}) => {
  const classes = useStyles();

  const hasConfigurations = configurations?.length > 0;

  return (
    <Grid container className={classes.root} direction='column' wrap='nowrap'>
      <Grid item className={clsx(classes.apartmentBox, completed ? 'completed' : 'progressing')}>
        <div className='order'>{codiceAppartamento}</div>
        <span>Appartamento</span>
        <span className='apartment-type'>{StyleText(apartment?.value, true)}</span>
      </Grid>
      <Grid item xs='auto'>
        <Grid container>
          <Grid item xs={6}>
            <ApartmentInfo
              value={building?.value}
              type={'Edificio'}
              icon={<Edificio color='#C4C4C4' height='20px' width='20px' />}
            />
          </Grid>
          <Grid item xs={6}>
            <ApartmentInfo
              value={scala?.value}
              type={'Scala'}
              icon={<Scala color='#C4C4C4' height='20px' width='20px' />}
            />
          </Grid>
          <Grid item xs={6}>
            <ApartmentInfo
              value={piano}
              type={'Piano'}
              icon={<Piano color='#C4C4C4' height='20px' width='20px' />}
            />
          </Grid>
          <Grid item xs={6}>
            <ApartmentInfo
              value={quadrante}
              type={'Quadrante'}
              icon={<Quadrante color='#C4C4C4' height='20px' width='20px' />}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs
        className={clsx(classes.configurationsRoot, !hasConfigurations && 'no-padding-bottom')}
      >
        {completed ? (
          <ConfigurationSent />
        ) : (
          <ConfigurationsList
            items={configurations}
            handleStartNewConfiguration={handleStartNewConfiguration}
          />
        )}
      </Grid>
      {hasConfigurations ? (
        <Grid item container xs='auto' justifyContent='center'>
          <Button3D text='Visualizza' color='orange' onClick={handleViewConfigurations} />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default ApartmentCard;
