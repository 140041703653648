import { withStyles } from '@material-ui/styles';

const styles = (theme) => ({
  bigTabPanelRoot: {
    backgroundColor: '#FFF',
    height: 'calc(100% - 52px)',
    overflow: 'hidden auto',
    padding: '24px',
  },
});

const BigTabPanel = (props) => {
  const { classes, children, value, index, ...other } = props;

  return (
    <div
      className={classes.bigTabPanelRoot}
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
    >
      {children}
    </div>
  );
};

export default withStyles(styles)(BigTabPanel);
