import * as React from 'react';

function SvgPreseLoggia(props) {
  return (
    <svg viewBox='0 0 72 49' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#prese-loggia_svg__clip0_2758_23617)' fill='#C4C4C4'>
        <path d='M22.68 2.05c3.96 1.36 11.58 4.58 16.39 8.51h2.96C35.96 4.45 23.68.28 23 .05c-.21-.07-.43-.07-.64 0C21.45.36 0 7.64 0 17.19c0 .55.44 1 1 1h20.55v7.25h-6.87c-.56 0-1 .44-1 1s.44 1 1 1h6.87v9.97c0 .55.45 1 1 1s1-.45 1-1v-9.97h.02v-2h-.02v-7.25h.02v-2H2.1C3.45 9.93 16.93 4.03 22.68 2.05z' />
        <path d='M13.838 30.63a.997.997 0 00-.97-.75h-6.25l-1.64-7.17a1 1 0 10-1.95.45l1.77 7.73-1.3 6.32c-.11.54.24 1.07.78 1.18.54.11 1.07-.24 1.18-.78l1.18-5.73h5.46l1.49 5.78c.12.45.52.75.97.75.08 0 .17 0 .25-.03.53-.14.86-.68.72-1.22l-1.68-6.53h-.01zM49.081 27.42h-2.46v6.18h2.46v-6.18zM39.569 27.42h-.35v6.16h2.46v-6.16h-2.11z' />
        <path d='M56.48 20.93c0-.73-.29-1.42-.8-1.94-.51-.51-1.21-.8-1.93-.8H34.56c-.73 0-1.42.29-1.94.8-.51.52-.8 1.21-.8 1.94v19.19c0 .72.29 1.42.8 1.93.52.51 1.21.8 1.94.8h19.19c.72 0 1.42-.29 1.93-.8s.8-1.21.8-1.93V20.93zM52.35 36a9.777 9.777 0 01-4.43 3.63 9.839 9.839 0 01-10.75-2.13 9.861 9.861 0 01-2.7-5.06c-.04-.19-.07-.37-.09-.56a9.6 9.6 0 01-.08-2c.07-1.07.32-2.13.73-3.14a9.89 9.89 0 013.64-4.42 9.82 9.82 0 015.48-1.67c1.29 0 2.58.26 3.78.75 1.19.5 2.28 1.23 3.2 2.14.91.92 1.64 2.01 2.14 3.2.49 1.2.75 2.48.75 3.78 0 1.95-.58 3.86-1.67 5.48zM68.089 29.22h1.11c.14 0 .25-.11.25-.25v-4.74c0-.11 0-.22-.05-.33a.75.75 0 00-.18-.28.75.75 0 00-.47-.22.169.169 0 00-.1-.03c-.11-.01-.22 0-.33.05-.1.04-.2.1-.28.18a.75.75 0 00-.18.28c-.04.1-.05.22-.05.3v4.79c0 .14.11.25.25.25h.03zM61.811 29.19h1.05c.14 0 .25-.12.25-.25V24.2c.01-.11 0-.22-.04-.33-.04-.1-.1-.19-.18-.28a.875.875 0 00-.28-.17c-.1-.04-.2-.05-.31-.05-.08.01-.15.02-.23.05 0 0-.03.01-.04.01a.806.806 0 00-.43.45c-.03.1-.05.21-.04.3v4.76c0 .13.11.25.25.25zM71.34 30.6a.86.86 0 00-.6-.33H60.16c-.12.01-.23.04-.33.1-.11.05-.2.13-.27.22-.07.08-.12.19-.16.3-.03.11-.04.23-.03.31v6.01c-.01.12 0 .24.03.34.04.12.09.22.16.31.08.09.17.16.27.22.11.05.22.08.35.09h10.59c.11-.01.22-.05.32-.1a.881.881 0 00.43-.83v-6.01a.899.899 0 00-.18-.63zM67.05 39.35h-3.17c-.14 0-.25.12-.25.25v1.06c0 .14.11.25.25.25h.81v3.3c-.03.63-.29 1.23-.74 1.68-.44.45-1.03.71-1.65.73h-5.82c-.14 0-.25.12-.25.25v1.06c0 .14.11.25.25.25h5.82c2.45 0 3.95-1.99 3.95-3.43v-3.84h.8c.14 0 .25-.11.25-.25V39.6c0-.13-.11-.25-.25-.25z' />
      </g>
      <defs>
        <clipPath id='prese-loggia_svg__clip0_2758_23617'>
          <path fill='#fff' d='M0 0h71.52v48.18H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgPreseLoggia;
