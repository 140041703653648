import { makeStyles, Popper } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { InfoPopupChain, InfoPopupChainData, InfoPopupChainSmall, InfoPopupChainDataSmall } from './InfoPopup';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  primaryText: {
    display: 'flex',
    textDecoration: 'underline',
    color: theme.palette.color.orange,
    fontWeight: '500',
    fontSize: '20px',
    textUnderlineOffset: '5px'
  },
  secondaryText: {
    display: 'flex',
    fontSize: '13px',
  },
  iconBefore: {
    display: 'flex', 
  }
}));

const AlertBoxChaining = ({ infoData, refdiv, selected }) => {
  const classes = useStyles();
  const [showInfoBig, setShowInfoBig] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    if(selected) setShowInfoBig(true);
  }, [selected]) 

  return (
    <>
      <Popper
        open={showInfoBig}
        anchorEl={refdiv.current}
        onClose={() => setShowInfoBig(true)}
        placement="bottom-start"
        PaperProps={{ classes: { root: classes.popover } }}
      >
        <InfoPopupChain
          body={<InfoPopupChainData {...infoData} />}
          isPopover
          handleClose={() => {
            setShowInfoBig(false);
            setShowInfo(true);
          }}
        />
      </Popper>
      <Popper
        open={showInfo}
        anchorEl={refdiv.current}
        onClose={() => setShowInfoBig(true)}
        placement="bottom-start"
        PaperProps={{ classes: { root: classes.popover } }}
      >
        <InfoPopupChainSmall
          body={<InfoPopupChainDataSmall {...infoData} />}
          isPopover
          handleClose={() => {
            setShowInfo(false);
            setShowInfoBig(true);
          }}
        />
      </Popper>
    </>
  );
};

export default AlertBoxChaining;
