import * as React from 'react';

function SvgPreseSoggiorno(props) {
  return (
    <svg viewBox='0 0 76 43' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#prese-soggiorno_svg__clip0_2758_23608)' fill='#C4C4C4'>
        <path d='M13.4 18.88c0-4.05-1.76-7.86-4.78-10.43-.05-.05-.1-.09-.18-.16-.01-.01-.02-.01-.03-.02C11.24 4.33 15.75 2 20.64 2h17.74c3.82 0 7.42 1.43 10.15 3.94h2.77C48.1 2.18 43.43 0 38.38 0H20.64C15.03 0 9.88 2.7 6.68 7.26A5.047 5.047 0 000 12.03a5.072 5.072 0 003.6 4.84c.15.07.29.15.41.24.81.58 1.27 1.48 1.27 2.47 0 .7-.23 1.37-.68 1.92-.02.02-.03.04-.04.06-.14.15-.3.3-.45.44-.05.04-.08.08-.12.13-.5.65-.78 1.47-.78 2.29 0 2.09 1.7 3.78 3.78 3.78h.47v2.54c0 .55.45 1 1 1h6.03c.55 0 1-.45 1-1V28.2h15.24v-2H6.99c-.98 0-1.78-.8-1.78-1.78 0-.36.11-.72.33-1.02.18-.17.37-.35.53-.54.05-.05.1-.11.14-.17.7-.9 1.07-1.97 1.07-3.11 0-1.61-.78-3.14-2.11-4.1-.21-.15-.47-.3-.76-.45-.07-.03-.13-.05-.21-.07-.15-.04-.31-.1-.46-.17a3.047 3.047 0 011.31-5.8c.53 0 1.04.13 1.48.37l.15.09s.03.02.04.03c.13.09.24.18.35.27.03.03.06.05.09.07 0 .01.1.1.11.11 2.62 2.23 4.13 5.49 4.13 8.95 0 .41-.03.81-.07 1.2-.01.07-.04.13-.04.2 0 .55.45 1 1 1h18.44v-2H13.39c.01-.14.01-.27.01-.4zM9.46 28.2h4.03v1.54H9.46V28.2zM50.5 21.35v6.18h2.46V21.35H50.5zM43.102 26.2v1.31h2.46v-6.16h-2.46v4.85z' />
        <path d='M60.36 14.86a2.765 2.765 0 00-1.37-2.38c-.41-.23-.88-.36-1.36-.36H38.44c-.73 0-1.42.29-1.94.8-.51.52-.8 1.21-.8 1.94v19.19c0 .72.29 1.42.8 1.93.52.51 1.21.8 1.94.8h19.19c.72 0 1.42-.29 1.93-.8s.8-1.21.8-1.93V14.86zm-4.13 15.07a9.777 9.777 0 01-4.43 3.63c-1.8.75-3.78.95-5.7.57a9.945 9.945 0 01-5.05-2.7 9.643 9.643 0 01-2.14-3.23c-.25-.59-.44-1.2-.56-1.83l-.03-.17c-.3-1.65-.17-3.34.37-4.92.06-.21.14-.41.22-.61.2-.48.44-.95.72-1.39.74-1.2 1.74-2.24 2.92-3.03a9.758 9.758 0 013.69-1.5c.59-.11 1.19-.17 1.79-.17h.4c1.16.05 2.3.3 3.37.74.48.2.94.44 1.38.71.58.35 1.12.76 1.62 1.24.07.05.14.12.21.19a9.96 9.96 0 012.13 3.2c.5 1.2.76 2.48.75 3.78 0 1.95-.57 3.86-1.66 5.48v.01zM71.971 23.14h1.11c.14 0 .25-.11.25-.25v-4.74c0-.11 0-.22-.05-.33a.75.75 0 00-.18-.28.75.75 0 00-.28-.18c-.06-.02-.12-.04-.19-.04a.169.169 0 00-.1-.03c-.11-.01-.22 0-.33.05-.1.04-.2.1-.28.18a.75.75 0 00-.18.28c-.04.1-.05.22-.05.3v4.79c0 .14.11.25.25.25h.03zM65.691 23.12h1.05c.14 0 .25-.12.25-.25v-4.74c0-.11 0-.22-.04-.33-.04-.1-.1-.19-.18-.28a.828.828 0 00-.6-.22c-.09.01-.17.03-.26.06-.1.04-.19.1-.26.18a.77.77 0 00-.17.27c-.04.1-.05.21-.04.3v4.76c0 .13.11.25.25.25zM75.39 25.16a.846.846 0 00-.17-.63.86.86 0 00-.6-.33H64.04c-.12.01-.23.04-.33.1-.11.05-.2.13-.27.21-.07.09-.12.2-.16.31-.03.11-.04.23-.03.31v6.01c0 .12 0 .24.03.34.04.12.09.22.16.31.08.09.17.16.27.22.11.05.22.08.35.09h10.59c.11-.01.22-.05.32-.1a.881.881 0 00.4-.52c.03-.11.04-.23.03-.31v-6.01h-.01zM70.93 33.28h-3.17c-.14 0-.25.12-.25.25v1.06c0 .14.11.25.25.25h.81v3.3c-.03.63-.29 1.23-.74 1.68-.44.45-1.04.71-1.65.73h-5.82c-.14 0-.25.12-.25.25v1.06c0 .14.11.25.25.25h5.82c1.32 0 2.36-.58 3.04-1.33.59-.65.91-1.44.91-2.1v-3.84h.8c.14 0 .25-.11.25-.25v-1.06c0-.13-.11-.25-.25-.25z' />
      </g>
      <defs>
        <clipPath id='prese-soggiorno_svg__clip0_2758_23608'>
          <path fill='#fff' d='M0 0h75.4v42.11H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgPreseSoggiorno;
