import * as React from 'react';

function SvgRipostiglio(props) {
  return (
    <svg viewBox='0 0 62 42' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#ripostiglio_svg__clip0_2448_23041)' fill='#C4C4C4'>
        <path d='M25.57 0h-22A3.58 3.58 0 00.02 3.51V34.8a3.47 3.47 0 002.77 3.41v2a.85.85 0 00.85.84h2.77a.85.85 0 00.85-.84v-1.9h14.77v1.9a.85.85 0 00.85.84h2.7a.85.85 0 00.85-.84v-2a3.52 3.52 0 002.77-3.4V3.51A3.62 3.62 0 0025.57 0zM5.47 39.42H4.4v-1h1.07v1zm19.24 0h-1.07v-1h1.07v1zm2.77-4.63a1.91 1.91 0 01-1.92 1.89h-22a1.91 1.91 0 01-1.92-1.89V3.51a1.9 1.9 0 011.92-1.89h22a1.898 1.898 0 011.92 1.89v31.28z' />
        <path d='M25.569 2.74h-22a.85.85 0 00-.85.84v31.29a.85.85 0 00.85.84h22a.85.85 0 00.85-.84V3.58a.809.809 0 00-.85-.84zm-11.79 31.22h-9.38V4.35h9.38v29.61zm11 0h-9.38V4.35h9.38v29.61z' />
        <path d='M10.44 21.67a1.82 1.82 0 001.82-1.81 1.83 1.83 0 00-1.82-1.82 1.88 1.88 0 00-1.83 1.82 1.82 1.82 0 001.83 1.81zM18.74 21.67a1.92 1.92 0 001.801-1.591v-.22a1.83 1.83 0 00-1.82-1.82 1.83 1.83 0 00-1.83 1.82 1.819 1.819 0 001.85 1.81zM58.01 0h-22a3.54 3.54 0 00-3.549 3.51V34.8a3.47 3.47 0 002.77 3.41v2a.85.85 0 00.85.84h2.77a.85.85 0 00.85-.84v-1.9h14.87v1.9a.85.85 0 00.85.84h2.7a.84.84 0 00.84-.84v-2a3.52 3.52 0 002.77-3.4V3.51A3.7 3.7 0 0058.011 0zM37.92 39.42h-1.07v-1h1.07v1zm19.24 0h-1.07v-1h1.07v1zm2.77-4.63a1.91 1.91 0 01-1.92 1.89h-22a1.91 1.91 0 01-1.92-1.89V3.51a1.899 1.899 0 011.92-1.89h22a1.9 1.9 0 011.92 1.89v31.28z' />
        <path d='M58.01 2.74h-22a.85.85 0 00-.85.84v31.29a.85.85 0 00.85.84h22a.85.85 0 00.85-.84V3.58a.85.85 0 00-.85-.84zM46.16 33.96h-9.31V4.35h9.31v29.61zm11.07 0h-9.37V4.35h9.37v29.61z' />
        <path d='M42.89 21.67a1.81 1.81 0 001.82-1.81 1.82 1.82 0 00-1.82-1.82 1.87 1.87 0 00-1.82 1.82 1.81 1.81 0 001.82 1.81zM51.128 21.67a1.81 1.81 0 001.82-1.81 1.82 1.82 0 00-1.82-1.82 1.83 1.83 0 00-1.82 1.82 1.82 1.82 0 001.82 1.81z' />
      </g>
      <defs>
        <clipPath id='ripostiglio_svg__clip0_2448_23041'>
          <path fill='#fff' d='M0 0h61.71v41.04H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgRipostiglio;
