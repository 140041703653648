import * as React from 'react';

function SvgCameraSingola(props) {
  return (
    <svg viewBox='0 0 48 43' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#cameraSingola_svg__clip0_2458_23702)'>
        <path
          d='M47.331 32.24h-1.51v-6.6a5.09 5.09 0 00-3.69-4.92V8.34A8.32 8.32 0 0033.841 0h-19.82a8.32 8.32 0 00-8.29 8.34v12.3a5.08 5.08 0 00-3.89 5v6.6H.531a.5.5 0 00-.5.5v4.87a.5.5 0 00.5.5h1.31v3.42a.5.5 0 00.5.5h4.9a.51.51 0 00.5-.5v-3.42h32.48v3.42a.5.5 0 00.5.5h4.83a.501.501 0 00.5-.5v-3.42h1.31a.5.5 0 00.5-.5v-4.87a.498.498 0 00-.53-.5zm-43.52-6.6a3.15 3.15 0 013.13-3.15h33.75a3.14 3.14 0 013.12 3.15v6.6h-40v-6.6zm28-11.19a3.35 3.35 0 013.33 3.35v2.68h-22.49V17.8a3.35 3.35 0 013.33-3.35h15.83zm8.36 6h-3v-2.68a5.28 5.28 0 00-5.26-5.29h-15.83a5.282 5.282 0 00-5.27 5.29v2.68h-3V8.34a6.31 6.31 0 016.22-6.38h19.84a6.47 6.47 0 016.36 6.39l-.06 12.1zm1.94 19.53v-1.9h1.88v1.9h-1.88zm-38.32 0v-1.9h1.89v1.9h-1.89zm42.08-3.92h-44v-1.9h44v1.9z'
          fill='#C4C4C4'
        />
      </g>
      <defs>
        <clipPath id='cameraSingola_svg__clip0_2458_23702'>
          <path fill='#fff' d='M0 0h47.84v42.03H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgCameraSingola;
