import { makeStyles } from '@material-ui/core';
import MoodCard from './MoodCard';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '20px',
    padding: '0 32px',
    [theme.breakpoints.down('lg')]: {
      padding: '0 30px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 20px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 10px',
      gap: '10px',
    },
  },
}));

const MoodContainer = ({ moods }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {moods.map((mood, i) => (
        <MoodCard
          key={i}
          title={mood.title}
          nStars={mood.nStars}
          moods={mood.moods}
          infoText={mood.infoText}
        />
      ))}
    </div>
  );
};

export default MoodContainer;
