import { Format, CartaParati } from '../components/Icons';

const getInfoPriceCard = (tag) => {
  switch (tag) {
    case 'FinituraCamere':
      return 'Il prezzo è riferito alla somma dei metri quadri degli ambienti della zona giorno e notte, escluso i locali bagno, cucina e lavanderia.';
    case 'TonalitaCamere':
      return 'Il prezzo è riferito alla somma dei metri quadri degli ambienti della zona giorno e notte, escluso i locali bagno, cucina e lavanderia.';
    case 'FormatoCamere':
      return 'Il prezzo è riferito alla somma dei metri quadri degli ambienti della zona giorno e notte, escluso i locali bagno, cucina e lavanderia.';
    case 'FinituraCucina':
      return 'Il prezzo è riferito alla metratura del locale cucina.';
    case 'TonalitaCucina':
      return 'Il prezzo è riferito alla metratura del locale cucina.';
    case 'FormatoCucina':
      return 'Il prezzo è riferito alla metratura del locale cucina.';
    case 'CartaParati':
      return 'Il prezzo è riferito alla fornitura e alla posa della carta da parati nella metratura della parete interessata.';
    case 'ColorePorte':
      return "Il prezzo è riferito al totale delle porte presenti nell'appartamento, escluso eventuali porte d'arredo e porte grezze selezionati nella fase Layout.";
    case 'ManigliaPorte':
      return "Il prezzo è riferito al totale delle porte presenti nell'appartamento, escluso eventuali porte d'arredo.";
    case 'PorteArredoProfilo':
      return "Il prezzo è riferito alla fornitura e posa del profilo selezionato per la porta d'arredo.";
    case 'PorteArredoVetro':
      return "Il prezzo è riferito alla fornitura e posa del vetro selezionato per la porta d'arredo.";
    case 'PlacchetteElettriche':
      return "Il prezzo è riferito alla fornitura e posa delle placchette elettriche in tutto l'appartamento.";
    default:
      return "Il prezzo è riferito al totale delle porte presenti nell'appartamento, escluso eventuali porte d'arredo.";
  }
};

const materialDataSheetOptionsConfiguration = (options, selectedOptions, cartaParati = false) => {
  const icon = cartaParati ? (
    <CartaParati width='60px' height='60px' />
  ) : (
    <Format width='60px' height='60px' />
  );
  const optionSelected = options?.find((option) => option?.id === selectedOptions?.[0]);
  const materialObject = {
    image: optionSelected?.preview?.replace(/\s/g, '%20'),
    name:
      optionSelected?.title === 'Nessuno' ? 'Nessuna' : optionSelected?.productName || 'Nome marca',
    product: 'PRODOTTO',
    infoTitle: optionSelected?.productDescriptionTitle || [],
    info: optionSelected?.productDescription || [],
    code: optionSelected?.code || 'example code',
    icon: icon,
    dataSheet: optionSelected?.datasheetFileLable,
    multipleImagesURLs: optionSelected?.multipleImagesURLs,
    multipleDescriptionsLabels: optionSelected?.multipleDescriptionsLabels,
    multipleDescriptionsTitles: optionSelected?.multipleDescriptionsTitles,
    multipleNames: optionSelected?.multipleNames,
  };
  return materialObject;
};

const packagesRestructuringClassicConfiguration = (
  packages,
  filters = false,
  multipleImages = false,
  simpleCode = false,
  infoPriceTag,
) => {
  return packages?.data?.[0]?.packages?.map((onePackage) => {
    const coverImage = onePackage?.images?.find(
      (image) => image?.imageType?.value === 'Cover',
    )?.label;
    const virtualImage = onePackage?.images?.find(
      (image) => image?.imageType?.value === 'Virtual',
    )?.label;
    const objectToReturn = {
      id: onePackage?.id,
      code: simpleCode
        ? onePackage?.code
        : onePackage?.products[0]?.producerCode + '_' + onePackage?.products[0]?.productCode,
      preview: onePackage?.products[0]?.imageFileLabel,
      name: onePackage?.name,
      materiotecaCode: onePackage?.materiotecaCode,
      materiotecaName: onePackage?.materiotecaName,
      productName: onePackage?.products[0]?.techInfo1,
      title: onePackage?.title,
      description: onePackage?.name,
      coverImage: coverImage,
      virtualImage: virtualImage,
      price: onePackage?.priceDiff,
      productDescriptionTitle: Object.values(
        Object.fromEntries(
          Object.entries(onePackage?.products[0]).filter(
            ([key, value]) =>
              key.includes('techInfo') &&
              key.includes('Title') &&
              !key.includes('1') &&
              (value !== null || value !== ''),
          ),
        ),
      ),
      productDescription: Object.values(
        Object.fromEntries(
          Object.entries(onePackage?.products[0]).filter(
            ([key, value]) =>
              key.includes('techInfo') &&
              !key.includes('Title') &&
              !key.includes('1') &&
              (value !== null || value !== ''),
          ),
        ),
      ),
      infoPrice: getInfoPriceCard(infoPriceTag),
      infoSpecification: 'Lorem Ipsum infoSpecification',
      isChained: onePackage?.subsequentChoiceWarning || false,
      datasheetFileLable: onePackage?.products[0]?.datasheetFileLabel,
    };
    if (filters) objectToReturn.filter = onePackage?.filter;
    if (multipleImages) {
      objectToReturn.multipleImagesURLs = onePackage?.products
        ?.sort((a, b) => a.order - b.order)
        ?.map((product) => product?.imageFileLabel?.replace(/\s/g, '%20'));

      objectToReturn.multipleDescriptionsLabels = onePackage?.products.map((product) => {
        return Object.values(
          Object.fromEntries(
            Object.entries(product).filter(
              ([key, value]) =>
                key.includes('techInfo') &&
                !key.includes('Title') &&
                !key.includes('1') &&
                (value !== null || value !== ''),
            ),
          ),
        );
      });
      objectToReturn.multipleDescriptionsTitles = onePackage?.products.map((product) => {
        return Object.values(
          Object.fromEntries(
            Object.entries(product).filter(
              ([key, value]) =>
                key.includes('techInfo') &&
                key.includes('Title') &&
                !key.includes('1') &&
                (value !== null || value !== ''),
            ),
          ),
        );
      });
      objectToReturn.multipleNames = onePackage?.products.map((product) => {
        return product?.techInfo1;
      });
    }
    return objectToReturn;
  });
};

const setMoodAndFinituraConfiguration = (
  finiturePackages,
  selectedFinituraCamere,
  selectedFinituraCucina,
  store,
) => {
  if (selectedFinituraCamere) {
    store.setSelectedFormatoCameraImage(
      finiturePackages?.data[0]?.packages
        ?.find((onePackage) => onePackage?.code === selectedFinituraCamere)
        ?.images?.find((image) => image?.imageType?.value === 'Cover')
        ?.label?.replace(/\s/g, '%20'),
    );
    store.setSelectedFormatoCameraTitle(
      finiturePackages?.data[0]?.packages?.find(
        (onePackage) => onePackage?.code === selectedFinituraCamere,
      )?.materiotecaName,
    );
  }
  if (selectedFinituraCucina) {
    store.setSelectedFormatoCucinaImage(
      finiturePackages?.data[0]?.packages
        ?.find((onePackage) => onePackage?.code === selectedFinituraCucina)
        ?.images?.find((image) => image?.imageType?.value === 'Cover')
        ?.label?.replace(/\s/g, '%20'),
    );
    store.setSelectedFormatoCucinaTitle(
      finiturePackages?.data[0]?.packages?.find(
        (onePackage) => onePackage?.code === selectedFinituraCucina,
      )?.materiotecaName,
    );
  }
};
export {
  materialDataSheetOptionsConfiguration,
  packagesRestructuringClassicConfiguration,
  setMoodAndFinituraConfiguration,
};
