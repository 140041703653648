import TwoColumnsLayout from '../layouts/TwoColumnsLayout';
import { Grid } from '@material-ui/core';
import ApartmentCard from '../components/Card/ApartmentCard';
import InfoApartment from '../components/InfoApartment';
import { useEffect, useState } from 'react';
import { getRealApartmentList } from '../services/realApartmentApi';
import LoadingAnimation from '../components/LoadingAnimation';
import { useStore } from '../store/storeUtils';
import { useHistory } from 'react-router';
import { newConfiguration } from '../services/configurationApi';
import { listFolder } from '../services/boxSign/listFolder';
import { folderExist } from '../utils/boxSignUtils';


const isConfirmedConfiguration = async (store, configuration, codiceAppartamento) => {
  const nameNoExt = `${store.currentProject?.shortName} - ${codiceAppartamento} - ${store.userData.firstName} ${store.userData.lastName} - ${configuration?.value}(${configuration?.id})`;
  const getMainListFolder = await listFolder(process.env.REACT_APP_BOX_INTEGRATION_FOLDERID);
  const mainFolderList = getMainListFolder?.data?.item_collection?.entries;
  const folderName = `${store.userData?.firstName}_${store.userData?.lastName}`;
  const folder = folderExist(mainFolderList, folderName);
  const folderId = folder?.id;
  let fileYetExist = false;
  if (folderId) {
    const getUserListFolder = await listFolder(folderId);
    const userFolderList = getUserListFolder?.data?.item_collection?.entries;
    fileYetExist =
      userFolderList.length > 0 &&
      userFolderList.some((fileInFolder) => fileInFolder.name === `${nameNoExt}.pdf`);
  }
  // store.setIsOneConfigurationConfirmed(fileYetExist);
  return fileYetExist;
};

const isApartmentConfigConfirmed = async (configurations, realApartmentId, codiceAppartamento, store) => {
  let apartmentConfirmedConf = {apartment: realApartmentId, configId: ''};
  await Promise.all(configurations?.map(async (configuration)=> {
    let isConfirmed = false
    isConfirmed = await isConfirmedConfiguration(store, configuration, codiceAppartamento)
    if (isConfirmed) {
      apartmentConfirmedConf.configId = configuration.id
      if(!store.configurationConfirmed.some((confirmedConf) => confirmedConf.apartment === realApartmentId))
      store.setConfigurationConfirmed(apartmentConfirmedConf);
    }
  }))
};

const ApartmentsPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [apartments, setApartments] = useState([]);
  const store = useStore();
  const history = useHistory();

  useEffect(() => {
    store.setCurrentConfiguration(null);

    prepareApartmentsData();
  }, []);

  const prepareApartmentsData = async () => {
    try {
      const apartmentsData = await getRealApartmentList(store.currentProject?.shortName);
      await Promise.all(apartmentsData?.data?.map(async (apartment) => {
        await isApartmentConfigConfirmed(apartment.configurations, apartment.id, apartment.codiceAppartamento, store)
      }))
      setApartments(apartmentsData.data);
    } catch (e) {
      console.error(e);
    }

    setIsLoading(false);
  };

  const handleViewConfigurations = (apartment) => {
    store.setCurrentApartment(apartment);
    history.push(`/apartment/${apartment.id}`);
  };

  const handleStartNewConfiguration = async (apartment) => {
    try {
      // Creo una nuova configurazione per l'appartamento
      const newConfigurationName = `Nuova Configurazione`;

      const newConfigurationResponse = await newConfiguration(newConfigurationName, apartment.id);
      // recupero id configurazione
      const newConfigurationId = newConfigurationResponse.data.id;

      store.setCurrentApartment(apartment);
      store.setCurrentConfiguration(newConfigurationResponse.data);

      history.push(`/apartment/${apartment.id}/${newConfigurationId}/configurator`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <TwoColumnsLayout
        firstColumn={<InfoApartment firstTitleRow={'I tuoi '} secondTitleRow={'appartamenti'} />}
        secondColumn={
          isLoading ? (
            <LoadingAnimation msg='Caricamento in corso' />
          ) : (
            <Grid container spacing={4}>
              {apartments.map((apartment) => (
                <Grid key={apartment.id} item xs={6} lg={5} xl={4}>
                  <ApartmentCard
                    {...apartment}
                    handleStartNewConfiguration={() => handleStartNewConfiguration(apartment)}
                    handleViewConfigurations={() => handleViewConfigurations(apartment)}
                  />
                </Grid>
              ))}
            </Grid>
          )
        }
      />
    </>
  );
};

export default ApartmentsPage;
