const StyleText = (text, reverse = false) => {
  const firstPart = text && text.substr(0, text.indexOf(' '));
  const secondPart = text && text.substr(text.indexOf(' ') + 1);
  const bold = { fontWeight: 700 };
  return (
    <>
      <span style={reverse ? bold : {}}>{firstPart}</span>&nbsp;
      <span style={!reverse ? bold : {}}>{secondPart}</span>
    </>
  );
};

export default StyleText;
