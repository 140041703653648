import * as React from 'react';

function SvgUsbStudio(props) {
  return (
    <svg viewBox='0 0 44 45' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M41.057 6.098H44v3.164h-1.006v2.45c0 .255-.045.507-.136.742-.091.235-.224.448-.392.627l-4.117 4.458h-.038v5.83c.649.127 1.23.509 1.63 1.072.4.564.59 1.268.53 1.974a2.922 2.922 0 01-.847 1.841 2.539 2.539 0 01-1.78.744c-.66 0-1.295-.265-1.782-.744a2.922 2.922 0 01-.847-1.841c-.059-.706.13-1.41.53-1.974.4-.563.982-.945 1.63-1.071v-2.375l-3.883-4.18a1.932 1.932 0 01-.392-.628 2.046 2.046 0 01-.136-.741v-2.757a1.459 1.459 0 01-.763-.67 1.637 1.637 0 01-.181-1.04 1.57 1.57 0 01.487-.92c.258-.236.586-.365.925-.365.338 0 .666.13.924.364.259.235.431.562.487.92.056.36-.008.728-.18 1.042-.173.313-.444.55-.764.669v2.752c0 .122.022.243.066.356a.942.942 0 00.187.303l3.223 3.471V3.144H36.16l.842-1.574.842-1.57.847 1.57.842 1.574H38.33V16.13l3.495-3.758a.972.972 0 00.233-.66v-2.45h-1V6.098zM3.4 8H26v1.82H3.42a1.41 1.41 0 00-1.4 1.41v22.14h41.32v3.42a3.45 3.45 0 01-3.4 3.39H22.73v2.5h4.73v1.87H15.95v-1.87h4.62v-2.5H3.4A3.45 3.45 0 010 36.79v-25.4A3.46 3.46 0 013.4 8zm37.92 29a1.41 1.41 0 01-1.4 1.41H3.42A1.41 1.41 0 012.02 37v-1.63h39.3V37z'
        fill='#C4C4C4'
      />
    </svg>
  );
}

export default SvgUsbStudio;
