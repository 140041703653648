import * as React from 'react';

function SvgPortaAltezzaStandard(props) {
  return (
    <svg viewBox='0 0 123 159' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M.113 158.1h93.2V0H.113v158.1zM.883.77h91.66v156.56H.883V.77z' fill='#4E4D4D' />
      <path
        d='M3.965 154.25h85.5V3.85h-85.5v150.4zm.77-149.62h84v148.84h-84V4.63zM122.343 2.98V.85h-9.57v2.13h4.21v153.07h-4.21v2.12h9.57v-2.12h-4.2V2.98h4.2z'
        fill='#4E4D4D'
      />
      <path d='M80.103 79.98h-16.9v2.9h16.9v-2.9z' fill='#4E4D4D' />
    </svg>
  );
}

export default SvgPortaAltezzaStandard;
