import * as React from 'react';

function SvgMobileLavaboLoggia(props) {
  return (
    <svg viewBox='0 0 109 96' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M89.508 94.338V27.902H55.499v-.05l4.027-5.752-1.177-.814-2.85 4.067V10.241c0-.044-.094-4.294 2.292-6.256 1.155-.953 2.728-1.219 4.668-.792 1.25.271 2.226.97 2.912 2.067 2.431 3.912.49 11.698.474 11.775l-.073.272 1.785.415.061-.271c.09-.371 2.103-9.1-.92-13.682-.931-1.413-2.253-2.283-3.926-2.582-2.794-.505-5.02-.056-6.62 1.33-2.8 2.427-2.644 6.816-2.633 6.987v18.398H19.663v66.436H.176v1.663h108.812v-1.663H89.502h.006zM55.499 26.323l2.99-4.267.262.183-3.252 4.643v-.559zm1.032-23.385c1.461-1.27 3.536-1.668 6.151-1.197 1.517.271 2.716 1.058 3.559 2.338 2.604 3.946 1.221 11.46.903 12.978l-.692-.16c.34-1.463 1.713-8.196-.602-11.925-.764-1.23-1.862-2.012-3.262-2.322-2.12-.466-3.848-.155-5.148.908-2.593 2.134-2.498 6.512-2.493 6.689V28.14h-.859V9.5c0-.044-.15-4.311 2.443-6.561zm32.42 91.4H20.23V43.89h68.72v50.45zm0-51.003H20.23V28.456h68.72v14.879z'
        fill={props.color}
      />
    </svg>
  );
}

export default SvgMobileLavaboLoggia;
