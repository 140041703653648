import * as React from 'react';

function SvgScala(props) {
  return (
    <svg viewBox='0 0 25 35' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#scala_svg__clip0_2723_23203)' fill={props.color}>
        <path d='M20.56 0H4.13C1.85 0 0 1.85 0 4.13v29.64c0 .43.35.78.78.78h23.13c.43 0 .78-.35.78-.78V4.13c0-2.28-1.85-4.13-4.13-4.13zM1.55 4.13c0-1.35 1.05-2.45 2.38-2.55L15.8 9.11V33H1.55V4.13zM23.13 33h-5.78V8.68c0-.27-.14-.51-.36-.66L6.8 1.55h13.76a2.57 2.57 0 012.57 2.57V33z' />
        <path d='M12.47 19.35H10.1a.781.781 0 000 1.56h2.37a.781.781 0 000-1.56z' />
      </g>
      <defs>
        <clipPath id='scala_svg__clip0_2723_23203'>
          <path fill='#fff' d='M0 0h24.69v34.55H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgScala;
