import * as React from "react";

function SvgCartaParati(props) {
    return (
        <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3644_24586)">
                <path d="M50.5826 25.542C54.7577 25.542 57.5411 28.3254 57.5411 32.5004C57.5411 36.6755 51.3296 38.937 49.2012 34.9359" stroke="#999999" stroke-width="1.33343" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M50.5815 42.2315C46.4064 42.2315 43.623 38.0564 43.623 33.8814C43.623 29.7063 46.4064 25.5312 50.5815 25.5312" stroke="#999999" stroke-width="1.33343" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M31.1084 49.1901V61.705C31.1084 63.24 29.86 64.4884 28.325 64.4884H3.29509C1.76014 64.4884 0.511719 63.24 0.511719 61.705V3.29509C0.511719 1.76014 1.76014 0.511719 3.29509 0.511719H61.7153C63.2502 0.511719 64.4987 1.76014 64.4987 3.29509V32.4694C64.4987 34.0248 63.9972 37.2686 61.6437 39.6222C60.4055 40.8604 58.3179 42.293 56.0871 42.293L25.5621 42.2419C21.387 42.2419 18.6037 38.0668 18.6037 33.8918C18.6037 29.7167 21.387 25.5416 25.5621 25.5416H50.6022" stroke="#999999" stroke-width="1.33343" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M11.7571 8.98497C11.7571 9.75245 11.1431 10.3664 10.3756 10.3664C9.60812 10.3664 8.99414 9.74221 8.99414 8.98497C8.99414 8.22773 9.61835 7.60352 10.3756 7.60352C11.1328 7.60352 11.7571 8.22773 11.7571 8.98497Z" fill="#999999"/>
                <path d="M21.4387 8.98497C21.4387 9.75245 20.8247 10.3664 20.0572 10.3664C19.2898 10.3664 18.6758 9.74221 18.6758 8.98497C18.6758 8.22773 19.2898 7.60352 20.0572 7.60352C20.8247 7.60352 21.4387 8.22773 21.4387 8.98497Z" fill="#999999"/>
                <path d="M11.7571 42.1803C11.7571 42.9478 11.1431 43.5617 10.3756 43.5617C9.60812 43.5617 8.99414 42.9375 8.99414 42.1803C8.99414 41.423 9.61835 40.7988 10.3756 40.7988C11.1328 40.7988 11.7571 41.423 11.7571 42.1803Z" fill="#999999"/>
                <path d="M11.7571 25.5826C11.7571 26.3501 11.1431 26.9641 10.3756 26.9641C9.60812 26.9641 8.99414 26.3399 8.99414 25.5826C8.99414 24.8254 9.61835 24.2012 10.3756 24.2012C11.1328 24.2012 11.7571 24.8254 11.7571 25.5826Z" fill="#999999"/>
                <path d="M31.1184 8.98497C31.1184 9.75245 30.5044 10.3664 29.7369 10.3664C28.9694 10.3664 28.3555 9.74221 28.3555 8.98497C28.3555 8.22773 28.9797 7.60352 29.7369 7.60352C30.4942 7.60352 31.1184 8.22773 31.1184 8.98497Z" fill="#999999"/>
                <path d="M11.7571 58.7779C11.7571 59.5454 11.1431 60.1594 10.3756 60.1594C9.60812 60.1594 8.99414 59.5352 8.99414 58.7779C8.99414 58.0207 9.61835 57.3965 10.3756 57.3965C11.1328 57.3965 11.7571 58.0207 11.7571 58.7779Z" fill="#999999"/>
                <path d="M21.4387 58.7779C21.4387 59.5454 20.8247 60.1594 20.0572 60.1594C19.2898 60.1594 18.6758 59.5352 18.6758 58.7779C18.6758 58.0207 19.2898 57.3965 20.0572 57.3965C20.8247 57.3965 21.4387 58.0207 21.4387 58.7779Z" fill="#999999"/>
                <path d="M40.7981 8.98497C40.7981 9.75245 40.1841 10.3664 39.4166 10.3664C38.6491 10.3664 38.0352 9.74221 38.0352 8.98497C38.0352 8.22773 38.6491 7.60352 39.4166 7.60352C40.1841 7.60352 40.7981 8.22773 40.7981 8.98497Z" fill="#999999"/>
                <path d="M50.4797 8.98497C50.4797 9.75245 49.8657 10.3664 49.0983 10.3664C48.3308 10.3664 47.7168 9.74221 47.7168 8.98497C47.7168 8.22773 48.3308 7.60352 49.0983 7.60352C49.8657 7.60352 50.4797 8.22773 50.4797 8.98497Z" fill="#999999"/>
                <path d="M60.1594 8.98497C60.1594 9.75245 59.5454 10.3664 58.7779 10.3664C58.0105 10.3664 57.3965 9.74221 57.3965 8.98497C57.3965 8.22773 58.0105 7.60352 58.7779 7.60352C59.5454 7.60352 60.1594 8.22773 60.1594 8.98497Z" fill="#999999"/>
                <path d="M6.22189 17.2838C6.22189 18.0513 5.59768 18.6653 4.84044 18.6653C4.0832 18.6653 3.45898 18.041 3.45898 17.2838C3.45898 16.5266 4.0832 15.9023 4.84044 15.9023C5.59768 15.9023 6.22189 16.5266 6.22189 17.2838Z" fill="#999999"/>
                <path d="M15.9016 17.2838C15.9016 18.0513 15.2876 18.6653 14.5201 18.6653C13.7527 18.6653 13.1387 18.041 13.1387 17.2838C13.1387 16.5266 13.7629 15.9023 14.5201 15.9023C15.2774 15.9023 15.9016 16.5266 15.9016 17.2838Z" fill="#999999"/>
                <path d="M6.22189 33.8815C6.22189 34.6489 5.59768 35.2629 4.84044 35.2629C4.0832 35.2629 3.45898 34.6387 3.45898 33.8815C3.45898 33.1242 4.0832 32.5 4.84044 32.5C5.59768 32.5 6.22189 33.1242 6.22189 33.8815Z" fill="#999999"/>
                <path d="M15.9016 33.8815C15.9016 34.6489 15.2876 35.2629 14.5201 35.2629C13.7527 35.2629 13.1387 34.6387 13.1387 33.8815C13.1387 33.1242 13.7629 32.5 14.5201 32.5C15.2774 32.5 15.9016 33.1242 15.9016 33.8815Z" fill="#999999"/>
                <path d="M6.22189 50.4791C6.22189 51.2466 5.59768 51.8606 4.84044 51.8606C4.0832 51.8606 3.45898 51.2364 3.45898 50.4791C3.45898 49.7219 4.0832 49.0977 4.84044 49.0977C5.59768 49.0977 6.22189 49.7219 6.22189 50.4791Z" fill="#999999"/>
                <path d="M15.9016 50.4791C15.9016 51.2466 15.2876 51.8606 14.5201 51.8606C13.7527 51.8606 13.1387 51.2364 13.1387 50.4791C13.1387 49.7219 13.7629 49.0977 14.5201 49.0977C15.2774 49.0977 15.9016 49.7219 15.9016 50.4791Z" fill="#999999"/>
                <path d="M25.5832 50.4791C25.5832 51.2466 24.9692 51.8606 24.2018 51.8606C23.4343 51.8606 22.8203 51.2364 22.8203 50.4791C22.8203 49.7219 23.4343 49.0977 24.2018 49.0977C24.9692 49.0977 25.5832 49.7219 25.5832 50.4791Z" fill="#999999"/>
                <path d="M25.5832 17.2838C25.5832 18.0513 24.9692 18.6653 24.2018 18.6653C23.4343 18.6653 22.8203 18.041 22.8203 17.2838C22.8203 16.5266 23.4343 15.9023 24.2018 15.9023C24.9692 15.9023 25.5832 16.5266 25.5832 17.2838Z" fill="#999999"/>
                <path d="M35.2629 17.2838C35.2629 18.0513 34.6489 18.6653 33.8815 18.6653C33.114 18.6653 32.5 18.041 32.5 17.2838C32.5 16.5266 33.114 15.9023 33.8815 15.9023C34.6489 15.9023 35.2629 16.5266 35.2629 17.2838Z" fill="#999999"/>
                <path d="M44.9426 17.2838C44.9426 18.0513 44.3286 18.6653 43.5611 18.6653C42.7937 18.6653 42.1797 18.041 42.1797 17.2838C42.1797 16.5266 42.7937 15.9023 43.5611 15.9023C44.3286 15.9023 44.9426 16.5266 44.9426 17.2838Z" fill="#999999"/>
                <path d="M54.634 17.2838C54.634 18.0513 54.02 18.6653 53.2526 18.6653C52.4851 18.6653 51.8711 18.041 51.8711 17.2838C51.8711 16.5266 52.4851 15.9023 53.2526 15.9023C54.02 15.9023 54.634 16.5266 54.634 17.2838Z" fill="#999999"/>
            </g>
            <defs>
                <clipPath id="clip0_3644_24586">
                    <rect width="65" height="65" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
};

export default SvgCartaParati;
