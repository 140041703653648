import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    padding: '33px 12px 22px',
    backgroundColor: '#fff',
    border: `2px solid ${theme.palette.primary.main}`,
    '&:before': {
      content: "'Complimenti! Hai concluso la personalizzazione della nuova casa.'",
      position: 'absolute',
      top: 0,
      transform: 'translateY(-50%)',
      margin: '0 32px',
      padding: '4px 30px',
      fontFamily: 'miller',
      fontStyle: 'italic',
      fontSize: '30px',
      lineHeight: '36px',
      textAlign: 'center',
      backgroundColor: '#fff',
      color: theme.palette.primary.main,
      [theme.breakpoints.down('sm')]: {
        fontSize: '25px',
        lineHeight: '30px',
      },
    },
    '&:after': {
      content: "'In caso di difformità, ti contatteremo.'",
      position: 'absolute',
      bottom: 0,
      transform: 'translateY(50%)',
      padding: '4px 30px',
      fontSize: '15px',
      lineHeight: '18px',
      textAlign: 'center',
      backgroundColor: '#fff',
      color: theme.palette.primary.main,
    },
  },
  message: {
    fontSize: '15px',
    lineHeight: '18px',
    textAlign: 'center',
  },
  alert: {
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '18px',
    textAlign: 'center',
  },
  highlight: {
    color: theme.palette.primary.main,
  },
}));

const FinalStepMessage = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <span className={classes.message}>
        Invia il tuo modulo d'ordine affinché venga valutato e convalidato dal nostro team di
        personal architects.
      </span>
      <span className={classes.alert}>
        In caso di convalida, entro 48 ore ti invieremo il progetto e relativo modulo d’ordine che
        potrai siglare digitalmente, dalla sezione{' '}
        <span className={classes.highlight}>«documenti contrattuali» di MYAI</span>.
      </span>
    </div>
  );
};

export default FinalStepMessage;
