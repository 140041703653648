import * as React from 'react';

function SvgQuadrante(props) {
  return (
    <svg viewBox='0 0 33 33' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#quadrante_svg__clip0_2723_23208)' fill={props.color}>
        <path d='M20.16 13.704H30.81c.905 0 1.68-.526 2.021-1.366.342-.84.16-1.76-.472-2.397L23.117.647a2.136 2.136 0 00-2.384-.475c-.835.344-1.347 1.122-1.347 2.033v10.71c0 .212.08.404.231.556.15.152.342.232.553.232h-.01zm.785-11.5c0-.384.292-.546.382-.576.04-.02.131-.05.252-.05.13 0 .281.04.432.182l9.24 9.294a.619.619 0 01.131.688.614.614 0 01-.573.384h-9.864V2.205zM12.268.171a2.157 2.157 0 00-2.383.476L.645 9.95a2.154 2.154 0 00-.473 2.387 2.157 2.157 0 002.021 1.355h10.649a.788.788 0 00.784-.79V2.205c0-.91-.513-1.689-1.348-2.033h-.01zm-.21 11.954H2.192a.602.602 0 01-.573-.384c-.03-.091-.14-.405.131-.688l9.24-9.294a.597.597 0 01.433-.182c.11 0 .211.03.251.05.09.041.382.193.382.577v9.921zM12.84 19.297H2.19c-.905 0-1.679.516-2.02 1.355-.343.84-.162 1.76.472 2.397l9.24 9.294c.423.425.965.648 1.529.648.281 0 .573-.06.844-.172.835-.344 1.348-1.123 1.348-2.033v-10.71a.809.809 0 00-.222-.556.766.766 0 00-.553-.233l.01.01zm-.785 11.489a.605.605 0 01-.382.576.594.594 0 01-.674-.131l-9.25-9.284a.619.619 0 01-.13-.688.615.615 0 01.572-.384h9.864v9.921-.01zM30.809 19.297H20.171a.788.788 0 00-.784.789v10.7c0 .91.512 1.689 1.347 2.032.282.112.563.172.855.172.563 0 1.106-.222 1.528-.647l9.24-9.294a2.184 2.184 0 00.473-2.397 2.142 2.142 0 00-2.021-1.355zm.442 2.64l-9.24 9.294a.595.595 0 01-.674.131.617.617 0 01-.382-.576v-9.922h9.864c.382 0 .543.294.573.385.03.09.141.404-.13.688h-.01z' />
      </g>
      <defs>
        <clipPath id='quadrante_svg__clip0_2723_23208'>
          <path fill='#fff' d='M0 0h33v33H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgQuadrante;
