import * as React from 'react';

function SvgLuciParete(props) {
  return (
    <svg viewBox='0 0 354 173' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#luci-parete_svg__clip0_1712:77293)' fill='#8E8E8E'>
        <path d='M33.109 69.392h1.224a.282.282 0 00.282-.282.3.3 0 00-.282-.301h-1.224a.301.301 0 00-.282.3.282.282 0 00.282.283zm-1.167 1.732h3.576a.282.282 0 00.283-.283.301.301 0 00-.283-.3h-3.576a.283.283 0 00-.282.3.285.285 0 00.174.261.282.282 0 00.108.022zm0-.885h3.576a.302.302 0 00.283-.301.283.283 0 00-.283-.282h-3.576a.283.283 0 00-.282.282.285.285 0 00.17.278.282.282 0 00.112.023zm1.882 15.058a5.12 5.12 0 005.082-5.251 6.625 6.625 0 00-1.712-4.122l-.245-.283a4.856 4.856 0 01-1.054-3.03.301.301 0 00-.283-.301.283.283 0 00-.282.3 5.327 5.327 0 001.205 3.427l.226.263a6.155 6.155 0 011.58 3.765 4.518 4.518 0 11-9.034 0 6.042 6.042 0 011.58-3.765l.227-.263a5.327 5.327 0 001.204-3.426.301.301 0 00-.282-.301.283.283 0 00-.282.3 4.819 4.819 0 01-1.073 3.031l-.245.283a6.626 6.626 0 00-1.845 4.197 5.12 5.12 0 005.102 5.252l.131-.076zm-2.014-13.25h3.577a.301.301 0 000-.603H31.81a.301.301 0 000 .602z' />
        <path d='M33.221 73.025a6.38 6.38 0 01-1.45 3.764l-.206.245a5.12 5.12 0 00-1.356 3.106 3.52 3.52 0 107.021 0 5.007 5.007 0 00-1.355-3.106l-.207-.245a6.343 6.343 0 01-1.43-3.764H33.22zm.508 13.345a6.119 6.119 0 01-6.098-6.268 7.53 7.53 0 011.882-4.743l.263-.34c.53-.669.815-1.499.81-2.352a1.222 1.222 0 000-.941 1.886 1.886 0 010-.452 4.068 4.068 0 000-1.337 1.299 1.299 0 011.261-1.298 1.28 1.28 0 011.205-.828h1.223a1.28 1.28 0 011.205.828 1.298 1.298 0 011.261 1.299c-.1.292-.1.61 0 .903.028.143.028.29 0 .433.027.15.027.302 0 .452-.1.305-.1.635 0 .941-.004.862.289 1.7.828 2.372l.245.3a7.53 7.53 0 011.882 4.763 6.118 6.118 0 01-6.08 6.268' />
        <path d='M33.732 65.475a12.085 12.085 0 1011.595 12.066 11.858 11.858 0 00-11.595-12.066zm0 26.164a14.097 14.097 0 1113.61-14.098 13.891 13.891 0 01-13.61 14.098zM319.683 69.392h1.205a.302.302 0 00.301-.282.304.304 0 00-.186-.278.299.299 0 00-.115-.023h-1.205a.304.304 0 00-.201.094.303.303 0 00-.081.207.283.283 0 00.282.282zm-1.186 1.732h3.577a.281.281 0 00.282-.283.276.276 0 00-.076-.212.275.275 0 00-.206-.089h-3.614a.3.3 0 00-.261.41.282.282 0 00.261.174h.037zm-.037-.885h3.576a.284.284 0 00.267-.187.276.276 0 00.015-.114.282.282 0 00-.282-.282h-3.576a.283.283 0 00-.283.282.3.3 0 00.283.301zm1.788 15.058a5.116 5.116 0 004.751-3.256 5.117 5.117 0 00.35-1.995 6.55 6.55 0 00-1.732-4.122 1.445 1.445 0 00-.226-.283 4.709 4.709 0 01-1.073-3.03.275.275 0 00-.075-.212.286.286 0 00-.207-.09.304.304 0 00-.201.095.303.303 0 00-.081.207 5.329 5.329 0 001.204 3.425l.226.264a6.04 6.04 0 011.581 3.765 4.519 4.519 0 01-9.035 0 6.16 6.16 0 011.582-3.765l.225-.263a5.324 5.324 0 001.205-3.426.276.276 0 00-.076-.212.28.28 0 00-.206-.09.3.3 0 00-.283.301 4.849 4.849 0 01-1.054 3.031l-.244.283a6.623 6.623 0 00-1.713 4.122 5.12 5.12 0 005.082 5.252zm-1.788-13.326h3.576a.3.3 0 00.301-.301.299.299 0 00-.301-.302h-3.576a.3.3 0 00-.301.302.299.299 0 00.301.3z' />
        <path d='M319.78 73.025a6.252 6.252 0 01-1.45 3.764l-.188.245a5.006 5.006 0 00-1.355 3.106c-.035.482.029.967.189 1.424a3.535 3.535 0 001.915 2.06 3.518 3.518 0 004.917-3.484 5.044 5.044 0 00-1.374-3.106l-.188-.245a6.252 6.252 0 01-1.45-3.764h-1.016zm.508 13.345a6.117 6.117 0 01-6.099-6.268 7.526 7.526 0 011.883-4.743c.082-.112.17-.218.263-.32a3.76 3.76 0 00.828-2.372 1.5 1.5 0 000-.941 1.27 1.27 0 010-.452 1.128 1.128 0 010-.433c.101-.293.101-.61 0-.903a1.302 1.302 0 011.261-1.299 1.288 1.288 0 011.205-.828h1.205a1.26 1.26 0 011.204.828 1.316 1.316 0 011.28 1.299c-.1.293-.1.61 0 .903.02.144.02.29 0 .433.019.15.019.302 0 .452a1.226 1.226 0 000 .941c-.009.86.277 1.697.81 2.372l.244.3a7.529 7.529 0 011.883 4.763 6.123 6.123 0 01-1.731 4.417 6.117 6.117 0 01-4.368 1.85' />
        <path d='M320.342 65.476a12.087 12.087 0 00-11.496 7.129 12.092 12.092 0 00-.88 7.018 12.088 12.088 0 0016.428 9.134 12.082 12.082 0 007.562-11.215 11.86 11.86 0 00-11.614-12.066zm0 26.164a14.095 14.095 0 01-13.355-8.37 14.1 14.1 0 012.748-15.519 14.1 14.1 0 0121.822 1.911 14.102 14.102 0 012.413 7.88 13.875 13.875 0 01-13.628 14.098zM34.729.52h-2.014v64.788h2.014V.52z' />
        <path d='M321.303.52h-2.014v64.788h2.014V.52zM2.014 144.364h63.414v-18.653H2.014v18.653zm65.428 2.014H0v-22.587h67.442v22.587zM288.592 144.364h63.414v-18.653h-63.414v18.653zm65.428 2.014h-67.442v-22.587h67.442v22.587zM2.014 169.21h63.414v-18.653H2.014v18.653zm65.428 2.015H0v-22.682h67.442v22.682zM288.592 169.21h63.414v-18.653h-63.414v18.653zm65.428 2.015h-67.442v-22.682h67.442v22.682z' />
        <path d='M33.73 133.371a2.223 2.223 0 00-2.067 1.362 2.22 2.22 0 104.27.84 2.202 2.202 0 00-2.203-2.202zm0 6.437a4.235 4.235 0 11-.038-8.472 4.235 4.235 0 01.038 8.472zM320.345 133.371a2.22 2.22 0 10.408 4.422 2.22 2.22 0 00-.408-4.422zm0 6.437a4.236 4.236 0 114.235-4.235 4.227 4.227 0 01-1.235 3 4.218 4.218 0 01-3 1.235zM33.73 158.31a2.225 2.225 0 00-2.065 1.358 2.222 2.222 0 102.066-1.358zm0 6.437a4.232 4.232 0 01-4.173-5.056 4.235 4.235 0 018.39.84 4.218 4.218 0 01-4.217 4.216zM320.344 158.31a2.218 2.218 0 00-2.052 1.371 2.222 2.222 0 104.274.85 2.228 2.228 0 00-.164-.853 2.204 2.204 0 00-2.058-1.368zm0 6.437a4.232 4.232 0 01-2.987-7.236 4.24 4.24 0 016.518.662c.463.699.708 1.52.705 2.358a4.224 4.224 0 01-1.215 3.037 4.215 4.215 0 01-3.021 1.255M89.217 170.018h180.115v-23.34h-.226v-.998c0-13.646-13.025-24.338-29.645-24.338H119.654c-16.772 0-30.437 10.917-30.437 24.338v24.338zm182.129 2.014H87.203V145.68c0-14.531 14.569-26.352 32.451-26.352h119.75c17.75 0 31.641 11.576 31.641 26.352h.358l-.057 26.352zM123.103 93.297a8.526 8.526 0 000 17.054h44.61a8.53 8.53 0 008.527-8.527 8.527 8.527 0 00-8.527-8.527h-44.61zm44.61 19.068h-44.61a10.54 10.54 0 010-21.082h44.61a10.542 10.542 0 110 21.082zM191.408 93.297a8.526 8.526 0 000 17.054h44.629a8.527 8.527 0 000-17.054h-44.629zm44.629 19.068h-44.629a10.54 10.54 0 110-21.082h44.629a10.54 10.54 0 010 21.082z' />
      </g>
      <defs>
        <clipPath id='luci-parete_svg__clip0_1712:77293'>
          <path fill='#fff' transform='translate(0 .52)' d='M0 0h354v171.494H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgLuciParete;
