import { makeStyles } from '@material-ui/core';
import { Switch, Route } from 'react-router-dom';
import { Redirect, useHistory, useParams, useRouteMatch, useLocation } from 'react-router';
import TabBar from '../components/Tabs/TabBar';
import { useEffect, useState, useMemo } from 'react';
import LayoutPage from './configuratorPages/LayoutPage';
import TecnologiaPage from './configuratorPages/TecnologiaPage';
import GiornoNottePage from './configuratorPages/GiornoNottePage';
import BagnoPage from './configuratorPages/BagnoPage';
// import ArredoPage from './configuratorPages/ArredoPage';
// import InteriorDesignerPage from './configuratorPages/InteriorDesignerPage';
import { observer } from 'mobx-react';
import { useStore } from '../store/storeUtils';
import PhaseListPage from './configuratorPages/PhaseListPage';
import { getPhases } from '../services/phaseApi';
import LoadingAnimation from '../components/LoadingAnimation';
import { useWinstonLogger } from 'winston-react/dist/hooks';
import { getAnswer } from '../services/answerApi';
import { getConfigurationDetail } from '../services/configurationApi';
import { getNextSubStep } from '../services/phaseApi';
import { getCartaDaParatiLayout } from '../services/layoutApi';
import _ from 'lodash';
import { getPackages } from '../services/packageApi';

const useStyles = makeStyles((theme) => ({
  newConfButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  configuratorBody: {
    height: 'calc(100% - 60px)',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100% - 50px)',
    },
  },
}));

const configuratorSteps = [
  {
    url: 'Layout',
    title: 'Layout',
    component: <LayoutPage />,
  },
  {
    title: 'Tecnologia',
    status: 'blocked',
    url: 'Tecnologia',
    component: <TecnologiaPage />,
  },
  {
    title: 'Il tuo stile: zona giorno & notte',
    status: 'blocked',
    url: 'GiornoENotte',
    component: <GiornoNottePage />,
  },
  {
    title: 'Il tuo stile: bagno',
    status: 'blocked',
    url: 'Bagno',
    component: <BagnoPage />,
  },
];

// occhio a questa fase perchè nel caso di variabili null o cose del genere
// in una nuova configurazione potrebbe dare problemi perchè non riesce a tirare fuori un errore
export const getConfiguratorPhases = async (configurationId, store) => {
  // prendo le fasi da BE
  const phaseData = await getPhases(configurationId);

  let cartaDaParatiPositionsData;

  // consiguro i dati che mi servono per la posizione della carta da parati
  if (store?.currentConfiguration?.layout?.id) {
    cartaDaParatiPositionsData = await getCartaDaParatiLayout(
      store?.currentConfiguration?.layout?.id,
    );
  }
  
  let phases = Array.isArray(phaseData.data) ? phaseData.data : [phaseData.data];
  let cartaDaParatiPositions = cartaDaParatiPositionsData?.data;

  // inizio ad ordinare le fasi perchè ho diverse info da dover codificare
  // per lo più per la zona giorno e il bagno
  let orderedPhases = [];
  const layoutIndex = phases.findIndex((el) => el.key === 'Layout');
  if (layoutIndex !== -1) orderedPhases = [...orderedPhases, phases[layoutIndex]];
  const tecnologiaIndex = phases.findIndex((el) => el.key === 'Tecnologia');
  if (tecnologiaIndex !== -1) orderedPhases = [...orderedPhases, phases[tecnologiaIndex]];
  // per la fase giorno devo inserire info aggiuntive per quanto riguarda la carta da parati
  const giornoenotteIndex = phases.findIndex((el) => el.key === 'GIORNOENOTTE');
  if (giornoenotteIndex !== -1) phases[giornoenotteIndex].order = 30;
  // praticamente devo inserire un sub-step per ogni punto della carta da parati
  // dentro lo step della carta da parati
  let substepCarta;
  let cartaIndex;
  if (giornoenotteIndex !== -1 && cartaDaParatiPositions) {
    substepCarta = phases[giornoenotteIndex]?.steps?.find((step) => step?.key === 'CARTAPARATI')
      ?.substeps?.[0];
    cartaDaParatiPositions.map((cartaDaParatiPosition) => {
      cartaDaParatiPosition.id = substepCarta.id;
      cartaDaParatiPosition.key = substepCarta.key;
      cartaDaParatiPosition.name =
        substepCarta.name + ' P-' + cartaDaParatiPosition.posizioneRivestimento;
      cartaDaParatiPosition.order = substepCarta.order;
      return cartaDaParatiPosition;
    });
    cartaIndex = phases[giornoenotteIndex]?.steps?.findIndex((step) => step?.key === 'CARTAPARATI');
    if (cartaIndex !== -1)
      phases[giornoenotteIndex].steps[cartaIndex].substeps = cartaDaParatiPositions;
  }
  // inseriti i sub-step della carta da parati posso inserire lo step giorno nell'array
  if (giornoenotteIndex !== -1) orderedPhases = [...orderedPhases, phases[giornoenotteIndex]];

  // ora è il turno dela bagno per cui per ogni bagno devo replicare gli step inserendo il roomId
  const bagnoIndex = phases.findIndex((el) => el.key === 'BAGNO');
  const bagnoPhase = phases.find((el) => el.key === 'BAGNO');
  const bagnoSteps = bagnoPhase?.steps;
  const bagniRooms = store?.currentConfiguration?.layout?.rooms?.filter(
    (room) => room?.roomType?.name === 'BAGNO',
  );
  let newBagnoSteps = [];
  let bagnoArray = [];
  bagniRooms?.forEach((bagno) => {
    bagnoArray.push(bagno?.id);
    bagnoSteps?.forEach((bagnoStep, index) => {
      if (bagnoStep.name !== 'LAVANDERIA') {
        let tempMyObj = Object.assign({}, bagnoSteps?.[index]);
        tempMyObj.roomId = bagno?.id;
        newBagnoSteps?.push(_.cloneDeep(tempMyObj));
      }
    });
  });
  newBagnoSteps?.map((step, i) => {
    step?.substeps?.map((substep, j) => {
      substep.roomId = step?.roomId;
      return substep;
    });
    return step;
  });

  if (bagnoSteps?.find((bagnoStep) => bagnoStep?.name === 'LAVANDERIA'))
    newBagnoSteps?.push(bagnoSteps?.find((bagnoStep) => bagnoStep?.name === 'LAVANDERIA'));

  if (bagnoArray?.length > 0) {
    if (bagnoIndex !== -1) phases[bagnoIndex].roomsId = bagnoArray;
    if (bagnoIndex !== -1) phases[bagnoIndex].steps = newBagnoSteps;
  }
  if (bagnoIndex !== -1) orderedPhases = [...orderedPhases, phases[bagnoIndex]];
  phases = orderedPhases;

  const sceltaLayoutStep = {
    id: 1,
    key: 'DistribuzioneInterna',
    name: 'DISTRIBUZIONE INTERNA',
    substeps: [{ id: 1, key: 'SceltaLayout', name: 'Scelta Layout', order: 10 }],
  };

  const sceltaLayoutPhase = {
    id: 1,
    key: 'Layout',
    name: 'Layout',
    order: 10,
    steps: [sceltaLayoutStep],
  };

  phases = phases.map((el) => {
    if (el.key === 'Layout') {
      el.steps = [sceltaLayoutStep, ...el.steps];
    }
    return { ...el };
  });

  phases = phases.length === 0 ? [sceltaLayoutPhase] : phases;

  phases = await phasesWithAnsweredInfoAsync(phases, store);

  return rebaseFrontendId(phases);
};

// Aggiunto l'attributo frontendId a substep per avere un indice
// per andare avanti nel flusso

// ho rivisto un po' il flusso per aggiornare tutte le fasi con le risposte
const phasesWithAnsweredInfoAsync = async (phases, store) => {
  let frontendId = 0;
  const configuration = store?.currentConfiguration?.id;
  // ho dovuto inserire delle chiamate asincrone
  return await Promise.all(
    phases?.map(async (phase, k) => {
      const steps = await Promise.all(
        phase.steps.map(async (step, j) => {
          // la risposta forzata mi serve per lo step dei pavimenti cucina
          let forceAnswer = false;
          let nonVisibleStep = false;
          const substeps = await Promise.all(
            step.substeps.map(async (substep, i) => {
              // mi servono due variabili per sapere se ho risposto a questi substep e/o se li devo nascondere
              let isAnswered;
              let hideInMenu = false;
              // mi serve sapere se sono una delle nuove fasi 3 o 4
              const isNew = phase?.order === 30 || phase?.order === 40;
              // mi occupo dello step della relazione del living e cucina che deve essere nascosto all'utente in alcuni casi
              if (substep.key === 'RELAZIONELIVINGCUCINA') {
                // prima di tutto cerco la risposta relativa a questo step
                const answer = store.answers?.find((answer) => {
                  if (answer?.room?.id !== 0) {
                    return (
                      answer?.substep?.id === substep?.id && answer?.room?.id === substep.roomId
                    );
                  } else {
                    return answer?.substep?.id === substep?.id;
                  }
                });
                // assegno la variabile per forzare la risposta in caso del pacchetto specifico
                forceAnswer = answer?.package?.value?.includes('CUC_FPAV');
              }
              // mi serve sapere se sono in uno degli step dei pavimenti living e cucina
              const isFloorSubStep =
                ['FINITURA', 'TONALITA', 'FORMATO'].includes(substep.key) && phase?.order === 30;
              // a questo punto verificate le condizioni decido se nascondere o meno lo step
              if (forceAnswer && isFloorSubStep) {
                isAnswered = true;
                hideInMenu = true;
              } else {
                // in tutti gli altri step (non relazione living e cucina) devo verificare se ho risposto o meno
                if (substep.id === 1) {
                  isAnswered = store.currentConfiguration?.layout?.id ? true : false;
                } else {
                  isAnswered = store.answers?.find((answer) => {
                    if (answer?.room?.id !== 0) {
                      return (
                        answer?.substep?.id === substep.id && answer?.room?.id === substep.roomId
                      );
                    } else {
                      return answer?.substep?.id === substep.id;
                    }
                  })
                    ? true
                    : false;
                }
              }
              // ricontrollo per verificare in maniera esaustiva che non ci sia la risposta
              // anche per quanto riguarda la nuova modalità di richiesta del nuovo step con l'API
              if (isNew && !isAnswered) {
                isAnswered = store.answers?.find((answer) => {
                  if (answer?.room?.id !== 0) {
                    return (
                      answer?.substep?.id === substep.id && answer?.room?.id === substep.roomId
                    );
                  } else {
                    return answer?.substep?.id === substep.id;
                  }
                })
                  ? true
                  : false;
                // ho bisogno di sapere se questo step esiste per la mia configurazione
                // questo perchè non tutti gli step inseriti con all'inizio possono essere raggiunti durante la configurazione
                // alcuni dipendono dalle scelte fatte in precedenza
                // altri invece dall'appartamento
                let previousSubstepId = null;
                let previousSubstep = null;
                let previousStep;
                // ho bisogno di calcolare quindi lo step precedente a quello considerato
                if (i > 0) {
                  previousSubstep = step?.substeps?.[i - 1];
                  previousSubstepId = previousSubstep?.id;
                } else if (j > 0) {
                  previousSubstep =
                    phase?.steps?.[j - 1]?.substeps?.[phase?.steps?.[j - 1]?.substeps?.length - 1];
                  previousSubstepId = previousSubstep?.id;
                  previousStep = phase?.steps?.[j - 1];
                } else if (k > 0) {
                  previousSubstep =
                    phases?.[k - 1]?.steps?.[phases?.[k - 1]?.steps?.length - 1]?.subSteps?.[
                      phases?.[k - 1]?.steps?.[phases?.[k - 1]?.steps?.length - 1]?.subSteps
                        ?.length - 1
                    ];
                  previousSubstepId = previousSubstep?.id;
                }
                // una volta trovato lo step precedente verifico se ho risposto a questo step
                if (previousSubstepId) {
                  let lastAnswer = store.answers?.[store.answers?.length - 1];
                  let stepHiding;
                  if (previousStep?.key === 'PAVIMENTOCUCINA') {
                    const answer = store.answers?.find(
                      (answer) => answer.substep.value === 'RELAZIONE LIVING-CUCINA',
                    );
                    stepHiding = answer?.package?.value?.includes('CUC_FPAV');
                    if (stepHiding) previousSubstepId = answer?.substep?.id;
                  }
                  // nel caso lo step precendente sia lo stesso di quello a cui ho risposto per ultimo
                  // devo controllare che il nextStep dello step precendete calcolato sia lo stesso di quello che sto considerando
                  if (lastAnswer?.substep?.id === previousSubstepId && !isAnswered) {
                    const nextSubstepData = await getNextSubStep(
                      configuration,
                      previousSubstepId,
                      previousSubstep?.roomId,
                    );
                    if (
                      nextSubstepData?.data?.substepId !== substep?.id &&
                      nextSubstepData?.data?.roomId === substep?.roomId
                    ) {
                      isAnswered = true;
                      hideInMenu = true;
                    }
                    if (nextSubstepData?.data?.stepId !== step.id) nonVisibleStep = true;
                  }
                }
              }
              // qui inizia tutto il calcolo per gli step  che devono essere nascosti perchè vengono successivamente all'ultima risposta data
              const lastAnswer = store.answers?.[store.answers?.length - 1];
              const lastAnswerPhase = phases?.find((phase) =>
                phase?.steps?.find((step) =>
                  step?.substeps?.find((ansSubStep) => ansSubStep?.id === lastAnswer?.substep?.id),
                ),
              );
              const lastAnswerStep = lastAnswerPhase?.steps?.find((step) =>
                step?.substeps?.find(
                  (answerSubstep) => answerSubstep?.id === lastAnswer?.substep?.id,
                ),
              );
              const lastAnswerSubStep = lastAnswerStep?.substeps?.find(
                (answerSubstep) => answerSubstep?.id === lastAnswer?.substep?.id,
              );
              const hasBagnoRoomId = lastAnswer?.room?.id !== 0 && substep?.roomId;
              // se mi trovo nel bagno e la stanza è precedente a quella dell'ultima risposta allora devo nascondere
              if (hasBagnoRoomId && phase?.order === 40 && !isAnswered) {
                if (lastAnswer?.room?.id > substep?.roomId) {
                  isAnswered = true;
                  hideInMenu = true;
                }
              }
              // se mi trovo in una fase successiva a quella dell'ultima risposta allora devo nascondere
              if (
                lastAnswerSubStep?.order > substep?.order &&
                !isAnswered &&
                (hasBagnoRoomId ? lastAnswer?.room?.id === substep?.roomId : true)
              ) {
                isAnswered = true;
                hideInMenu = true;
              }
              // a questo punto posso ritornare l'oggetto substep con tutti i dati aggiornati
              return await { ...substep, isAnswered, frontendId: frontendId++, hideInMenu };
            }),
          );
          // a questo punto posso ritornare l'oggetto step con tutti i dati aggiornati
          return await {
            ...step,
            substeps,
            isCompleted: !substeps.some((el) => !el.isAnswered),
            hideInMenu: nonVisibleStep || substeps.every((el) => el.hideInMenu),
          };
        }),
      );
      // a questo punto posso ritornare l'oggetto phase con tutti i dati aggiornati
      return await {
        ...phase,
        steps,
        isCompleted: !steps.some((el) => !el.isCompleted),
      };
    }),
  );
};
// devo rivedere i frontEndId
const rebaseFrontendId = (phases) => {
  let rebasedFrontendId = 0;
  return phases.map((phase, i) => {
    const steps = phase.steps.map((step, i) => {
      const substeps = step.substeps.map((substep, i) => {
        return { ...substep, frontendId: rebasedFrontendId++ };
      });
      return {
        ...step,
        substeps,
      };
    });
    return {
      ...phase,
      steps,
    };
  });
};

// la base è la stessa di quella sopra ma non posso fare chiamate asincrone
// viene fatta ogni volta che aggiorno le answer
// serve per aggiornare le fasi in store e di conseguenza il menu
const phasesWithAnsweredInfo = (phases, store) => {
  let frontendId = 0;
  return phases.map((phase, i) => {
    const steps = phase.steps.map((step, i) => {
      let forceAnswer = false;
      const substeps = step.substeps.map((substep, i) => {
        let isAnswered;
        let hideInMenu = false;
        if (substep.key === 'RELAZIONELIVINGCUCINA') {
          const answer = store.answers?.find((answer) => answer.substep.id === substep.id);
          forceAnswer = answer?.package?.value.includes('CUC_FPAV');
        }
        const isFloorSubStep =
          ['FINITURA', 'TONALITA', 'FORMATO'].includes(substep.key) && phase?.order === 30;
        if (forceAnswer && isFloorSubStep && phase?.order === 30) {
          isAnswered = true;
          hideInMenu = true;
        } else {
          if (substep.id === 1) {
            isAnswered = store.currentConfiguration?.layout?.id ? true : false;
          } else {
            isAnswered = store.answers?.find((answer) => {
              if (answer?.room?.id !== 0) {
                return answer.substep.id === substep.id && answer?.room?.id === substep?.roomId;
              } else {
                return answer.substep.id === substep.id;
              }
            })
              ? true
              : false;
          }
        }
        const lastAnswer = store.answers?.[store.answers?.length - 1];
        const lastAnswerPhase = phases?.find((phase) =>
          phase?.steps?.find((step) =>
            step?.substeps?.find((ansSubStep) => ansSubStep?.id === lastAnswer?.substep?.id),
          ),
        );
        const lastAnswerStep = lastAnswerPhase?.steps?.find((step) =>
          step?.substeps?.find((answerSubstep) => answerSubstep?.id === lastAnswer?.substep?.id),
        );
        const lastAnswerSubStep = lastAnswerStep?.substeps?.find(
          (answerSubstep) => answerSubstep?.id === lastAnswer?.substep?.id,
        );
        const hasBagnoRoomId = lastAnswer?.room?.id !== 0 && substep?.roomId;
        if (hasBagnoRoomId && phase?.order === 40 && !isAnswered) {
          if (lastAnswer?.room?.id > substep?.roomId) {
            isAnswered = true;
            hideInMenu = true;
          }
        }
        if (
          lastAnswerSubStep?.order > substep?.order &&
          !isAnswered &&
          (hasBagnoRoomId ? lastAnswer?.room?.id === substep?.roomId : true)
        ) {
          isAnswered = true;
          hideInMenu = true;
        }
        return { ...substep, isAnswered, frontendId: frontendId++, hideInMenu };
      });
      return {
        ...step,
        substeps,
        isCompleted: !substeps.some((el) => !el.isAnswered),
        hideInMenu: substeps.every((el) => el.hideInMenu),
      };
    });
    return {
      ...phase,
      steps,
      isCompleted: !steps.some((el) => !el.isCompleted),
    };
  });
};

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const ConfiguratorPage = observer(() => {
  const { path, url } = useRouteMatch();
  const params = useParams();
  const store = useStore();
  const history = useHistory();
  const classes = useStyles();
  const logger = useWinstonLogger();
  const query = useQuery();

  const [isLoading, setIsLoading] = useState(true);

  // stati possibili active/completed/blocked
  const [phasesStatus, setPhasesStatus] = useState([]);

  useEffect(() => {
    if (store.phases?.length > 0) {
      const completedPhaseOrder = query.get('completedPhaseId');
      const activeIndex = store.phases.findIndex((el) => store.currentPhase === el?.order);
      const phasesStatus = store.phases.map((el, i) => {
        let status = activeIndex === i ? 'active' : '';
        status += ` ${
          store.isPhaseCompleted(el?.order) ||
          store.currentPhase > el?.order ||
          completedPhaseOrder === '40'
            ? 'completed'
            : ''
        }`;
        status += ` ${
          !store.isPhaseCompleted(el?.order) &&
          activeIndex !== i &&
          !store.isPhaseCompleted(store.phases[i - 1]?.order)
            ? 'blocked'
            : ''
        }`;
        return { ...el, status };
      });
      setPhasesStatus(phasesStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.phases, store.currentPhase]);

  useEffect(() => {
    if (!store.currentApartment) {
      history.replace('/apartments');
    } else {
      prepareAnswersData();
      prepareCurrentConfiguration();
    }
    return () => {
      store.setCurrentPhase(null);
      store.setCurrentSubstep(null);
      store.setCurrentSubstepId(null);
      store.setCurrentConfiguration({});
      store.setUserCanGoNext(false);
      store.setSubstepSave(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Ricalcolo se i substep sono completati o meno quando aggiorno le risposte
  useEffect(() => {
    if (store.phases) {
      const phases = phasesWithAnsweredInfo(store.phases, store);
      store.setPhases(phases);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.answers]);

  const preparePhaseData = async () => {
    const phases = await getConfiguratorPhases(params.configuration, store);

    store.setPhases(phases);

    const resumeInfo = store.getResumeInfo();
    // controllo che ci troviamo in una fase nuova dove questa vecchia logica può essere fallace
    const isNew = resumeInfo?.phase?.order >= 30;
    // introduco una nuova logica per andare avanti nel flusso
    if (isNew && !resumeInfo.isFirstPhaseSubstep) {
      const lastSavedAnswer = store?.answers?.[store?.answers?.length - 1];
      const nextSubstep = await getNextSubStep(
        params.configuration,
        lastSavedAnswer?.substep?.id,
        lastSavedAnswer?.room?.id,
      );
      store.setCurrentPhase(resumeInfo?.phase?.order);
      store.setCurrentStep(nextSubstep?.data?.stepId);
      store.setCurrentSubstepId(nextSubstep?.data?.substepId);
      if (nextSubstep?.data?.roomId) store.setCurrentRoomId(nextSubstep?.data?.roomId);
      const phaseToComplete = phases?.find((phase) => phase?.id === nextSubstep?.data?.phaseId);
      const stepToComplete = phaseToComplete?.steps?.find((step) =>
        nextSubstep?.data?.roomId && resumeInfo?.phase?.order === 40
          ? step?.id === nextSubstep?.data?.stepId && step?.roomId === nextSubstep?.data?.roomId
          : step?.id === nextSubstep?.data?.stepId,
      );
      const subStepToComplete = stepToComplete?.substeps?.find((substep) =>
        nextSubstep?.data?.roomId
          ? substep?.id === nextSubstep?.data?.substepId &&
            substep?.roomId === nextSubstep?.data?.roomId
          : substep?.id === nextSubstep?.data?.substepId,
      );
      store.setCurrentSubstep(subStepToComplete?.frontendId);
      history.replace(
        `${url}/${phaseToComplete?.key}/${stepToComplete?.key}/${subStepToComplete?.key}`,
      );
      // nel caso contrario utilizzo la vecchia logica
    } else {
      if (resumeInfo.isFirstPhaseSubstep) {
        store.setCurrentPhase(resumeInfo.phase?.order);
        history.replace(`${url}`);
      } else {
        store.setCurrentPhase(resumeInfo.allCompleted ? '50' : resumeInfo.phase?.order);
        store.setCurrentStep(resumeInfo.step.id);
        if (resumeInfo.step.roomId) store.setCurrentRoomId(resumeInfo.step.roomId);
        if (resumeInfo?.substep?.roomId) store.setCurrentRoomId(resumeInfo.substep.roomId);
        // store.setCurrentStep(resumeInfo.step.order);
        store.setCurrentSubstep(resumeInfo.substep.frontendId);
        store.setCurrentSubstepId(resumeInfo.substep.id);
        history.replace(
          resumeInfo.allCompleted
            ? `${url}`
            : `${url}/${resumeInfo.phase.key}/${resumeInfo.step.key}/${resumeInfo.substep.key}`,
        );
      }
    }
    // Se non sono impostati i vari dati per phase,step,substep
    // riporto l'utente per sicurezza sulla pagina PhaseListPage
    if (!store.currentPhase || !store.currentStep) {
      history.replace(`${url}`);
    }
    setIsLoading(false);
  };

  const prepareAnswersData = async () => {
    try {
      const answersResponse = await getAnswer(params.configuration);
      const answersData = answersResponse.data;
      // const stepFinituraId = answersData.find((el) => el.substep.value === 'FINITURA')?.substep?.id;
      // const selectedFiniture = answersData.filter((el) => el.substep.value === 'FINITURA' && el.package.value);
      const selectedFormatiPavimenti = answersData.filter(
        (el) => el.substep.value === 'FORMATO' && el.package.value && !el.room.id,
      );
      // const selectedPavimentiBagno = answersData.filter((el) => el.substep.value === 'PAVIMENTO');
      // const selectedParetiBagno = answersData.filter((el) => el.substep.value === 'PARETI');
      const selectedFormatoBagno = answersData.filter(
        (el) => el.substep.value === 'FORMATO' && el.package.value && el.room.id,
      );
      const configurationResponse = await getConfigurationDetail(params.configuration);
      const configurationData = configurationResponse.data;
      const bagniRooms = configurationData?.layout?.rooms?.filter(
        (room) => room?.roomType?.name === 'BAGNO',
      );
      let pavimentieParetiBagno = [];
      if (bagniRooms)
        for (const room of bagniRooms) {
          const formatoPackageId = selectedFormatoBagno.find((el) => el.room?.id === room.id)
            ?.package?.id;
          // const pavimentoPackageId = selectedPavimentiBagno.find(el => el.room?.id === room.id)?.package?.id;
          // const paretePackageId = selectedParetiBagno.find(el => el.room?.id === room.id)?.package?.id;

          const formatoSubStepId = selectedFormatoBagno.find((el) => el.room?.id === room.id)
            ?.substep?.id;
          // const pavimentoSubStepId = selectedPavimentiBagno.find(el => el.room?.id === room.id)?.substep?.id;
          // const pareteSubStepId = selectedParetiBagno.find(el => el.room?.id === room.id)?.substep?.id;

          let pavimentoBagnoDes = '';
          let pareteBagnoDes = '';
          let pavimentoBagnoImg = '';
          let pareteBagnoImg = '';

          if (formatoPackageId && formatoSubStepId) {
            const formatoPackages = await getPackages(
              params.configuration,
              formatoSubStepId,
              room.id,
            );
            const formatoProds = formatoPackages?.data[0]?.packages?.find(
              (onePackage) => onePackage?.id === formatoPackageId,
            )?.products;
            pavimentoBagnoDes = formatoProds?.find(
              (prod) =>
                prod?.productPackageType?.value?.includes('PAVIMENTO') &&
                !prod?.productPackageType?.value?.includes('FILOPAVIMENTO'),
            )?.description;
            pareteBagnoDes = formatoProds?.find((prod) =>
              prod?.productPackageType?.value?.includes('PARETE'),
            )?.description;
            pavimentoBagnoImg = formatoProds
              ?.find(
                (prod) =>
                  prod?.productPackageType?.value?.includes('PAVIMENTO') &&
                  !prod?.productPackageType?.value?.includes('FILOPAVIMENTO'),
              )
              ?.imageFileLabel?.replace(/\s/g, '%20');
            pareteBagnoImg = formatoProds
              ?.find((prod) => prod?.productPackageType?.value?.includes('PARETE'))
              ?.imageFileLabel?.replace(/\s/g, '%20');
          }
          /*
        if(pavimentoPackageId && pavimentoSubStepId) {
          const pavimentoPackages = await getPackages(params.configuration, pavimentoSubStepId, room.id);
          pavimentoBagnoImg = pavimentoPackages?.data[0]?.packages
            ?.find((onePackage) => onePackage?.id === pavimentoPackageId)
            ?.images?.find(image => image?.imageType?.value === 'Cover')?.label;
        }
        if(paretePackageId && pareteSubStepId) {
          const paretePackages = await getPackages(params.configuration, pareteSubStepId, room.id);
          pareteBagnoImg = paretePackages?.data[0]?.packages
            ?.find((onePackage) => onePackage?.id === paretePackageId)
            ?.images?.find(image => image?.imageType?.value === 'Cover')?.label;
        }
        */
          const newObj = {
            roomId: room.id,
            // pavimentoBagno: selectedPavimentiBagno.find(el => el.room?.id === room.id),
            pavimentoBagnoDes: pavimentoBagnoDes,
            pareteBagnoDes: pareteBagnoDes,
            pavimentoBagnoImg: pavimentoBagnoImg,
            pareteBagnoImg: pareteBagnoImg,
            // pareteBagno: selectedParetiBagno.find(el => el.room?.id === room.id),
          };
          pavimentieParetiBagno.push(newObj);
        }
      const pavimentieParetiBagnoFinale = pavimentieParetiBagno;
      store.setSelectedPavimentiBagno(pavimentieParetiBagnoFinale);
      const selectedFormatoCamere = selectedFormatiPavimenti?.[0]?.package?.value;
      const selectedFormatoCucina = selectedFormatiPavimenti?.[1]?.package?.value;
      if (selectedFormatoCamere) {
        const stepFormatoCamereId = selectedFormatiPavimenti?.[0]?.substep?.id;
        const formatoCamerePackages = await getPackages(params.configuration, stepFormatoCamereId);
        store.setSelectedFormatoCameraImage(
          formatoCamerePackages?.data[0]?.packages
            ?.find((onePackage) => onePackage?.code === selectedFormatoCamere)
            .products[0]?.imageFileLabel?.replace(/\s/g, '%20'),
        );
        store.setSelectedFormatoCameraTitle(
          formatoCamerePackages?.data[0]?.packages?.find(
            (onePackage) => onePackage?.code === selectedFormatoCamere,
          )?.materiotecaName,
        );
      }
      if (selectedFormatoCucina) {
        const stepFormatoCucinaId = selectedFormatiPavimenti?.[1]?.substep?.id;
        const formatoCucinaPackages = await getPackages(params.configuration, stepFormatoCucinaId);
        store.setSelectedFormatoCucinaImage(
          formatoCucinaPackages?.data[0]?.packages
            ?.find((onePackage) => onePackage?.code === selectedFormatoCucina)
            .products[0]?.imageFileLabel?.replace(/\s/g, '%20'),
        );
        store.setSelectedFormatoCucinaTitle(
          formatoCucinaPackages?.data[0]?.packages?.find(
            (onePackage) => onePackage?.code === selectedFormatoCucina,
          )?.materiotecaName,
        );
      }
      store.setAnswers(answersData);
      await preparePhaseData();
    } catch (e) {
      logger.error('prepareAnswersData', e);
    }
  };

  const prepareCurrentConfiguration = async () => {
    try {
      const configurationResponse = await getConfigurationDetail(params.configuration);
      const configurationData = configurationResponse.data;
      store.setCurrentConfiguration(configurationData);
    } catch (e) {
      logger.error('prepareCurrentConfiguration', e);
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingAnimation msg='Caricamento in corso' />
      ) : (
        <>
          <TabBar phases={phasesStatus} />
          <div className={classes.configuratorBody}>
            <Switch>
              {configuratorSteps.map((el, i) => {
                return <Redirect key={i} exact from={`${url}/${el.url}/completed`} to={`${url}`} />;
              })}
              {configuratorSteps.map((el, i) => (
                <Route key={i} path={`${path}/${el.url}`}>
                  {el.component}
                </Route>
              ))}
              <Route path={`${path}/`}>
                <PhaseListPage />
              </Route>
            </Switch>
          </div>
        </>
      )}
    </>
  );
});

export default ConfiguratorPage;
