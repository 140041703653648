import * as React from "react";

function SvgSave(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M31.217 6.34L24.98.182A.638.638 0 0024.54 0H.613A.618.618 0 00.18.177.602.602 0 000 .606v29.043c0 .16.065.315.18.428a.618.618 0 00.433.178h30.164a.618.618 0 00.434-.178.601.601 0 00.18-.428V6.764a.589.589 0 00-.174-.424zM9.642 1.21h11.83v4.644H9.642V1.211zm11.83 27.832H9.642v-9.69h11.83v9.69zm8.66 0H22.73V18.746a.602.602 0 00-.18-.428.618.618 0 00-.433-.177H9.029a.618.618 0 00-.434.177.602.602 0 00-.18.428v10.297H1.258V1.211h7.157v5.25c0 .16.065.315.18.428a.618.618 0 00.434.178h13.088a.62.62 0 00.566-.374.6.6 0 00.047-.232V1.21h1.564l5.87 5.856-.031 21.976z"
        fill="#141414"
      />
    </svg>
  );
}

export default SvgSave;
