import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    position: 'relative',
  },
  content: {
    flex: 1,
    display: 'flex',
    position: 'relative',
  },

  firstColumn: {
    height: '100%',
    minWidth: `40%`,
    maxWidth: '350px',
    marginRight: '8px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  secondColumn: {
    height: '100%',
    flex: 1,
    marginLeft: '8px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}));

const ConfTwoColumnsLayout = ({ firstColumn, secondColumn }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.firstColumn}>{firstColumn}</div>
        <div className={classes.secondColumn}>{secondColumn}</div>
      </div>
    </div>
  );
};

export default ConfTwoColumnsLayout;
