import { Grid, makeStyles } from '@material-ui/core';
import FurnitureCounter from './FurnitureCounter';
import {
  AngoloCottura,
  Antibagno,
  Bagno,
  CabinaArmadio,
  CameraNew,
  CameraSingola,
  Cucina,
  Disimpegno,
  Ingresso,
  Lavanderia,
  Loggia,
  Ripostiglio,
  Soggiorno,
  Studio,
} from '../Icons';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    gap: '12px',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      gap: '5px',
    },
    '& > div': {
      maxWidth: '65px',
      width: '100%',
    },

    '& > div:last-child': {
      width: 'unset',
      maxWidth: 'unset',
      [theme.breakpoints.up('xl')]: {
        display: 'none',
      },
    },
    '&.big-screen-item-fill > div:last-child': {
      [theme.breakpoints.up('xl')]: {
        display: 'initial',
      },
    },
    '&.center-mode': {
      justifyContent: 'center',
      '& > div:last-child': {
        display: 'none',
      },
    },
  },
}));

const FurnitureCounterContainer = ({
  whiteBackgroundMode,
  bigScreenItemFill,
  rooms,
  centerMode,
  hideIcon,
}) => {
  const classes = useStyles();

  const roomIcons = {
    CUCINA: <Cucina color='currentColor' width='24px' height='24px' />,
    BAGNO: <Bagno color='currentColor' width='24px' height='24px' />,
    'CAMERA SINGOLA': <CameraSingola color='currentColor' width='24px' height='24px' />,
    'CAMERA DOPPIA': <CameraNew color='currentColor' width='24px' height='24px' />,
    CAMERA: <CameraNew color='currentColor' width='24px' height='24px' />,
    'CABINA ARMADIO': <CabinaArmadio color='currentColor' width='24px' height='24px' />,
    LAVANDERIA: <Lavanderia color='currentColor' width='24px' height='24px' />,
    STUDIO: <Studio color='currentColor' width='24px' height='24px' />,
    RIPOSTIGLIO: <Ripostiglio color='currentColor' width='24px' height='24px' />,
    DISIMPEGNO: <Disimpegno color='currentColor' width='24px' height='24px' />,
    INGRESSO: <Ingresso color='currentColor' width='24px' height='24px' />,
    LOGGIA: <Loggia color='currentColor' width='24px' height='24px' />,
    TERRAZZO: <Loggia color='currentColor' width='24px' height='24px' />,
    SOGGIORNO: <Soggiorno color='currentColor' width='24px' height='24px' />,
    ANTIBAGNO: <Antibagno color='currentColor' width='24px' height='24px' />,
    'ANGOLO COTTURA': <AngoloCottura color='currentColor' width='24px' height='24px' />,
  };

  const items = rooms?.map((room) => {
    return { text: room.roomType, counter: room.value, icon: roomIcons[room.roomType] };
  });

  return (
    <Grid
      container
      wrap='wrap'
      className={clsx(
        classes.root,
        bigScreenItemFill && 'big-screen-item-fill',
        centerMode && 'center-mode',
      )}
    >
      {items?.map((item, i) => (
        <Grid item key={i}>
          <FurnitureCounter
            icon={item.icon}
            text={item.text?.toLowerCase()}
            counter={item.counter}
            whiteBackgroundMode={whiteBackgroundMode}
            hideIcon={hideIcon}
          />
        </Grid>
      ))}
      <Grid item xs />
    </Grid>
  );
};

export default FurnitureCounterContainer;
