const LOCAL_STORAGE_NAME = 'hc_user';

export const setHcUserData = (data) => {
  try {
    localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify(data));
  } catch (error) {
    console.error('Error setting Home Configurator User Local Storage Data...', error);
  }
};

export const deleteHcUserData = () => {
  try {
    localStorage.removeItem(LOCAL_STORAGE_NAME);
  } catch (error) {
    console.error('Error deleting Home Configurator User Local Storage Data...', error);
  }
};

export const getHcUserData = () => {
  try {
    const hcUserItem = localStorage.getItem(LOCAL_STORAGE_NAME);

    const hcUserData = hcUserItem ? JSON.parse(hcUserItem) : null;
    return hcUserData;
  } catch (error) {
    console.error('Error getting Home Configurator User Local Storage Data...', error);
    return {};
  }
};
