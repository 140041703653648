import * as React from 'react';

function SvgSendEmail(props) {
  return (
    <svg viewBox='0 0 52 58' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#send-email_svg__clip0_3005_23659)' fill='#CECECE'>
        <path d='M48.07 53.94H3.89a.19.19 0 01-.19-.19V29.93L25 43.17c.3.19.64.28.98.28.34 0 .69-.1 1-.29l21.28-13.6v24.19c0 .1-.08.19-.19.19zm-2.62-26.99L25.96 39.41 5.94 26.96h39.52l-.01-.01zm2.62-3.71H3.89C1.74 23.24 0 24.99 0 27.13v26.61c0 2.15 1.75 3.89 3.89 3.89h44.18c2.15 0 3.89-1.75 3.89-3.89v-26.6c0-2.15-1.75-3.89-3.89-3.89M20.6 9.85l3.53-3.53v8.57c0 1.02.83 1.85 1.85 1.85s1.85-.83 1.85-1.85V6.33l3.53 3.53a1.855 1.855 0 002.62 0c.72-.72.72-1.9 0-2.62L27.29.54a1.869 1.869 0 00-.59-.4c-.11-.04-.22-.08-.34-.1C26.24.02 26.12 0 26 0s-.24.01-.36.04c-.12.02-.23.06-.34.1-.11.05-.22.1-.31.17-.1.07-.2.15-.28.23l-6.69 6.69c-.72.72-.72 1.9 0 2.62.73.72 1.9.72 2.62 0' />
      </g>
      <defs>
        <clipPath id='send-email_svg__clip0_3005_23659'>
          <path fill='#fff' d='M0 0h51.97v57.65H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgSendEmail;
