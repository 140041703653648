import { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import MenuSubItem from './MenuSubItem';
import clsx from 'clsx';
import SvgChevron from '../Icons/Chevron';
import { useStore } from '../../store/storeUtils';
import { observer } from 'mobx-react';
import { useHistory, useRouteMatch } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: ({ subLevelClass }) => (subLevelClass ? '40px' : '70px'),
    minHeight: ({ subLevelClass }) => (subLevelClass ? '40px !important' : '70px'),
    backgroundColor: '#F9F9F9',
    borderTop: `1px solid ${theme.palette.color.white}`,
    borderBottom: `1px solid ${theme.palette.color.white}`,
    padding: ({ subLevelClass }) => (subLevelClass ? '0 15px 0 26px' : '0 15px'),

    '&.collapsed': {
      padding: 0,
    },
    '&.active': {
      backgroundColor: '#FFF',
    },
    '&.blocked': {
      pointerEvents: 'none',
    },
  },
  status: {
    minWidth: '35px',
    minHeight: '35px',
    marginRight: '13px',
    borderRadius: '50%',
    margin: 'auto !important',
    '&.completed': {
      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/icons/check-ok.svg)`,
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      color: theme.palette.primary.main,
    },
    '&.active': {
      color: theme.palette.primary.main,
      border: `2px solid ${theme.palette.primary.main}`,
    },
    '&.blocked': {
      color: theme.palette.color.grey2,
      border: `2px solid ${theme.palette.color.grey2}`,
    },
    '&.collapsed': {
      width: '25px',
      height: '25px',
      margin: '0 0 5px 0 !important',
    },
  },

  chevron: {
    fill: theme.palette.color.grey2,
  },
  indicator: {
    transition: 'transform 200ms',
    width: 10,
    height: 'auto',
    '&.expanded': {
      transform: 'rotate(180deg)',
    },
  },
  summaryContent: {
    margin: '0 !important',
    width: '100%',
    '&.collapsed': {
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  titleContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: ({ subLevelClass }) => (subLevelClass ? '0' : '10px'),
    '&.collapsed': {
      position: 'absolute',
      right: 5,
    },
  },
  title: {
    alignSelf: 'center',
    fontWeight: ({ subLevelClass }) => (subLevelClass ? 400 : 600),
    textTransform: ({ subLevelClass }) => (subLevelClass ? 'uppercase' : 'none'),
    fontSize: ({ subLevelClass }) => (subLevelClass ? '12px' : 'none'),
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&.completed': {
      color: theme.palette.primary.main,
    },
    '&.active': {
      fontWeight: 700,
    },
    '&.blocked': {
      color: theme.palette.color.grey2,
    },
    '&.collapsed': {
      display: 'none',
    },
  },
}));

const MenuItem = observer(
  ({
    id,
    itemKey,
    name,
    isAnswered,
    isCompleted,
    substeps,
    isCollapsed,
    subLevelClass,
    parentStep,
    frontendId,
    order,
    isNew,
    hideInMenu,
    roomId,
    isBagnoPhase,
    roomsId,
    isBagnoSubPhase,
    isBagnoSubSubPhase,
  }) => {
    
    const classes = useStyles({ subLevelClass });
    const theme = useTheme();
    const store = useStore();
    const history = useHistory();
    const { url } = useRouteMatch();
    const phase = store.getCurrentPhaseData();

    const [expanded, setExpanded] = useState(false);

    const handleChange = () => {
      if (isSingleSubstep) {
        store.setCurrentStep(id);
        roomId ? store.setCurrentRoomId(roomId) : store.setCurrentRoomId('');
        // store.setCurrentStep(order);
        store.setCurrentSubstep(substeps[0]?.frontendId);
        store.setCurrentSubstepId(substeps[0]?.id);
        history.push(`${url}/${itemKey}/${substeps[0]?.key}`);
      } else {
        setExpanded(!expanded);
      }
    };
    const currentStep = store.currentStep;
    const currentRoomId = store.currentRoomId;
    const isExpandable = substeps?.length > 1;
    const isSingleSubstep = substeps?.length === 1;
    const isSubstepsCompleted = substeps ? !substeps.find((el) => !el.isAnswered) ? true : false : false;
    const hasSubstepAnswered = substeps ? substeps.find((el) => el.isAnswered) ? true : false : false;
    const hasSubSubstepAnswered = substeps ? substeps.find((el) => el.substeps?.find((el) => el.isAnswered)) ? true : false : false;
    let isActive;
    if((roomId || isBagnoPhase || isBagnoSubPhase) && name !== 'LAVANDERIA') {
      isActive = (currentRoomId === roomId && id === phase?.id) || (currentRoomId === roomId && id === store.currentStep)
    } else {
      isActive = (store.currentStep === id) || (store.currentStep === parentStep?.id && store.currentSubstep === frontendId)
    }
    const status = isAnswered || isSubstepsCompleted || isCompleted ? 'completed' : isActive ? '' : 'blocked';
    const iCanSeeRoom = ((roomId || isBagnoPhase || isBagnoSubPhase) && name !== 'LAVANDERIA') ? (hasSubSubstepAnswered || hasSubstepAnswered) ? true : (currentRoomId === roomId || status === 'completed') : true;
    /*
    console.log('eccomi, name ', name);
    console.log('eccomi, substeps', substeps);
    console.log('eccomi, isAnswered ', isAnswered);
    console.log('eccomi, hideInMenu ', hideInMenu);
    console.log('eccomi, isSubstepsCompleted ', isSubstepsCompleted);
    console.log('eccomi, currentStep ', currentStep);
    console.log('eccomi, id ', id);
    console.log('eccomi, isCompleted ', isCompleted);
    console.log('eccomi, hasSubstepAnswered ', hasSubstepAnswered);
    console.log('eccomi, hasSubSubstepAnswered ', hasSubSubstepAnswered);
    console.log('eccomi, display ', display);
    console.log('eccomi, iCanSeeRoom ', iCanSeeRoom);
    console.log('eccomi, currentRoomId ', currentRoomId);
    console.log('eccomi, roomId ', roomId);
    console.log('eccomi, isBagnoPhase ', isBagnoPhase);
    console.log('eccomi, isActive ', isActive);
    console.log('eccomi, name ', name);
    console.log('eccomi -------------------------------------');
    */

    const renderClassicMenu = () => {
      return (
        <Accordion
        expanded={expanded || isActive}
        elevation={0}
        onChange={isExpandable || isSingleSubstep ? handleChange : null}
        >
          <AccordionSummary
            className={clsx(
              classes.root,
              isCollapsed && 'collapsed',
              isActive && 'active',
              isSingleSubstep && status,
            )}
            classes={{ content: clsx(classes.summaryContent, isCollapsed && 'collapsed') }}
            aria-controls='accordion-header'
            id='accordion-header'
          >
            <span
              className={clsx(
                subLevelClass ? subLevelClass : classes.status,
                status,
                isCollapsed && 'collapsed',
                isActive && 'active',
              )}
            ></span>
            <div className={clsx(classes.titleContainer, isCollapsed && 'collapsed')}>
              <span
                className={clsx(
                  classes.title,
                  status,
                  isCollapsed && 'collapsed',
                  !isExpandable && 'not-expandable',
                  isActive && 'active',
                )}
              >
                {name}
              </span>
              {isExpandable && (
                <SvgChevron
                  className={clsx(classes.indicator, (expanded || isActive) && 'expanded')}
                  color={
                    status === 'blocked' ? theme.palette.color.grey2 : theme.palette.primary.main
                  }
                />
              )}
            </div>
          </AccordionSummary>
          {(isExpandable && !hideInMenu) ? (
            <AccordionDetails>
              {substeps.map((el, index) => phase?.order !== 40 ? (
                <MenuSubItem
                  key={index}
                  itemKey={el.key}
                  {...el}
                  isNew={isNew}
                  isCollapsed={isCollapsed}
                  roomId={el.roomId}
                  // isAnswered={phase?.order === 40 ? el.isAnswered : null}
                  // isCompleted={isCompleted}
                  parentStep={parentStep ? parentStep : { id, frontendId, name, key: itemKey, order, roomId: el.roomId } }
                  parentSubstep={subLevelClass ? { id, frontendId, name, key: itemKey, roomId: el.roomId } : ''}
                  // isBagnoPhase={!isBagnoPhase }
                  isBagnoSubPhase={false}
                  isBagnoSubSubPhase={false}
                />
              ) : (
                <MenuSubItem
                  key={index}
                  itemKey={el.key}
                  {...el}
                  isNew={isNew}
                  isCollapsed={isCollapsed}
                  roomId={roomId}
                  isAnswered={el.isAnswered}
                  isCompleted={el.isCompleted}
                  parentStep={parentStep ? parentStep : { id, frontendId, name, key: itemKey, order, roomId: el.roomId, isCompleted } }
                  parentSubstep={subLevelClass ? { id, frontendId, name, key: itemKey, roomId: el.roomId } : ''}
                  // isBagnoPhase={!isBagnoPhase }
                  isBagnoSubPhase={!isBagnoSubPhase}
                  isBagnoSubSubPhase={!isBagnoSubSubPhase}
                />
              ))}
            </AccordionDetails>
          ) : (
          ''
          )}
        </Accordion>
      )
    }
    /*
    const renderNestedMenu = () => {
      const roomNumber = roomsId?.findIndex((room) => room === roomId);
      return (
      <Accordion
      expanded={expanded || isActive}
      elevation={0}
      onChange={isExpandable || isSingleSubstep ? handleChange : null}
    >
      <AccordionSummary
        className={clsx(
          classes.root,
          isCollapsed && 'collapsed',
          isActive && 'active',
          isSingleSubstep && status,
        )}
        classes={{ content: clsx(classes.summaryContent, isCollapsed && 'collapsed') }}
        aria-controls='accordion-header'
        id='accordion-header'
      >
        <span
          className={clsx(
            subLevelClass ? subLevelClass : classes.status,
            status,
            isCollapsed && 'collapsed',
            isActive && 'active',
          )}
        ></span>
        <div className={clsx(classes.titleContainer, isCollapsed && 'collapsed')}>
          <span
            className={clsx(
              classes.title,
              status,
              isCollapsed && 'collapsed',
              !isExpandable && 'not-expandable',
              isActive && 'active',
            )}
          >
            {'BAGNO ' + (roomNumber !== -1 ? roomNumber + 1 : 1)}
          </span>
          {isExpandable && (
            <SvgChevron
              className={clsx(classes.indicator, (expanded || isActive) && 'expanded')}
              color={
                status === 'blocked' ? theme.palette.color.grey2 : theme.palette.primary.main
              }
            />
          )}
        </div>
      </AccordionSummary>
      {renderClassicMenu()}
      </Accordion>
      )
    }
    */
    return (isNew ? ((isAnswered || isSubstepsCompleted || currentStep === id || (isBagnoPhase && id === phase?.id) || hasSubstepAnswered || hasSubSubstepAnswered) && !hideInMenu && iCanSeeRoom) : true) ? 
    renderClassicMenu():
    null;
  },
);

export default MenuItem;
