import * as React from 'react';

function SvgPortaRasomuro(props) {
  return (
    <svg viewBox='0 0 253 214' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M188.324.879h-123v212.3h123V.879z' fill='#e8e8e8' />
      <path
        d='M188.624 213.439H65.074V.629h123.55v212.81zm-123-.5h122.5V1.129h-122.5v211.81z'
        fill='#AFAFAF'
      />
      <path
        d='M77.325 110.509a4.702 4.702 0 01-3.32-8.027 4.704 4.704 0 017.233.723c.515.775.789 1.684.787 2.614a4.686 4.686 0 01-2.902 4.336 4.679 4.679 0 01-1.798.354zM65.205.51H.625v.5h64.58v-.5zM65.205 213.189H.625v.5h64.58v-.5zM252.904.51h-64.58v.5h64.58v-.5zM252.904 213.189h-64.58v.5h64.58v-.5z'
        fill='#AFAFAF'
      />
    </svg>
  );
}

export default SvgPortaRasomuro;
