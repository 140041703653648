import { useEffect, useState, useRef } from 'react';
import GridDetailsContainer from '../../../components/Card/GridDetailsContainer';
import MaterialDataSheet from '../../../components/MaterialDataSheet';
import ConfiguratorThreeColumnsLayout from '../../../layouts/ConfiguratorThreeColumnsLayout';
import { resumeAnswerSimple } from '../../../utils/configuratorUtils';
import { getPackages } from '../../../services/packageApi';
import { useStore } from '../../../store/storeUtils';
import { useParams } from 'react-router';
import { handleSave, singleChoiceSelection } from '../../../utils/selectionUtils';
import _ from 'lodash';
import LoadingAnimation from '../../../components/LoadingAnimation';
import {
  materialDataSheetOptionsConfiguration,
  packagesRestructuringClassicConfiguration,
} from '../../../configs/bagnoPhasesConfigurations';
import { makeStyles, Grid } from '@material-ui/core';
import clsx from 'clsx';
import BigBox from '../giornoNotte/config/BigBox';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '15px',
    textAlign: 'left',
    height: '5%',
  },
  subTitle: {
    fontSize: '11px',
    textAlign: 'left',
    height: '5%',
  },
  secondaryText: {
    color: theme.palette.color.orange,
  },
  filters: {
    width: '100%',
    // margin: '10px 0',
    padding: '10px 0',
    textAlign: 'center',
    zIndex: 1,
    fontSize: 10,
    overflowX: 'auto',
    overflowY: 'hidden',
    height: '12%',
  },
  filter: {
    width: '80%',
    padding: '5px 5px',
    border: `1px solid #CDC7C1`,
    textAlign: 'center',
    zIndex: 1,
    fontSize: 12,
    borderRadius: '10px',
    overflow: 'scroll',
    margin: '0px 5px',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    '&.selected-filter': {
      backgroundColor: '#CDC7C1',
    },
  },
}));

const FirstColumn = ({
  options,
  selectedOptions,
  selectedPavimentoBagnoImage,
  selectedPavimentoBagnoTitle,
  selectedPareteBagnoImage,
  selectedPareteBagnoTitle,
}) => {
  const optionSelected = options?.find((option) => option?.id === selectedOptions?.[0]);
  const selectedVirtualImage = optionSelected?.virtualImage?.replace(/\s/g, '%20');
  return (
    <BigBox
      defaultImage={`${process.env.PUBLIC_URL}/assets/images/Bagno.jpg`}
      selectedVirtualImage={selectedVirtualImage}
      selectedPavimentoBagnoImage={selectedPavimentoBagnoImage}
      selectedPavimentoBagnoTitle={selectedPavimentoBagnoTitle}
      selectedPareteBagnoImage={selectedPareteBagnoImage}
      selectedPareteBagnoTitle={selectedPareteBagnoTitle}
    />
  );
};

const SecondColumn = ({ options, selectedOptions }) => {
  const materialObject = materialDataSheetOptionsConfiguration(options, selectedOptions);
  return (
    <MaterialDataSheet
      firstRowTitle={'PREVIEW'}
      secondRowTitle={'PRODOTTO'}
      previewBoxText={'una SANITARIO'}
      material={materialObject}
      multipleImages={materialObject?.multipleImagesURLs}
      multipleLabels={materialObject?.multipleDescriptionsLabels}
      multipleTitles={materialObject?.multipleDescriptionsTitles}
      multipleNames={materialObject?.multipleNames}
    />
  );
};

const ThirdColumn = ({
  options,
  selectedOptions,
  handleUserSelection,
  filters,
  setFilter,
  filter,
}) => {
  const classes = useStyles();
  const optionsFiltered = options?.filter((option) =>
    filter === 'Tutte le collezioni' ? option : option?.filter === filter,
  );
  const containers = [
    {
      title: 'Modello',
      content: optionsFiltered,
    },
  ];
  return (
    <>
      <div className={classes.title}>
        <span>
          Quale <span className={classes.secondaryText}>modello di sanitari</span> preferisci per il tuo{' '}
          <span className={classes.secondaryText}>bagno </span>?
        </span>
      </div>
      <div className={classes.subTitle}>
        <span>
          Potrai selezionare le finiture nello step successivo.
        </span>
      </div>
      <div className={classes.subTitle}>
        <span>
          Alcune collezioni sono disponibili in un'unica finitura.
        </span>
      </div>
      <Grid item xs='auto' className={classes.filters}>
        Filtri di Visualizzazione:
        <br></br>
        <br></br>
        <Grid container direction='row' justifyContent='left' alignItems='center' wrap='nowrap'>
          {filters?.map((singleFilter, i) => (
            <Grid item>
              <span
                className={clsx(classes.filter, singleFilter === filter && 'selected-filter')}
                onClick={() => setFilter(singleFilter)}
              >
                {singleFilter}
              </span>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <div style={{ height: '73%' }}>
        <GridDetailsContainer
          containers={containers}
          onClick={handleUserSelection}
          selectedOptions={selectedOptions}
          numCols={1}
          // filters={filters}
          // setFilter={setFilter}
          // filter={filter}
          noDualImages={true}
        />
      </div>
    </>
  );
};

const SanitariModello = () => {
  const [packages, setPackages] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [selectedPavimentoBagnoImage, setselectedPavimentoBagnoImage] = useState(null);
  const [selectedPavimentoBagnoTitle, setSelectedPavimentoBagnoTitle] = useState(null);
  const [selectedPareteBagnoImage, setSelectedPareteBagnoImage] = useState(null);
  const [selectedPareteBagnoTitle, setSelectedPareteBagnoTitle] = useState(null);
  const [filter, setFilter] = useState('Tutte le collezioni');
  const [filters, setFilters] = useState([]);

  const substepIdRef = useRef();
  const oldAnswersRef = useRef();

  const store = useStore();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const configurationId = params.configuration;
  const currentRoomId = store.currentRoomId;

  const settingFiniture = () => {
    const pavimentoBagnoDes = store.selectedPavimentiBagno?.find(room => room.roomId === currentRoomId)?.pavimentoBagnoDes;
    const pareteBagnoDes = store.selectedPavimentiBagno?.find(room => room.roomId === currentRoomId)?.pareteBagnoDes;
    const pavimentoBagnoImg = store.selectedPavimentiBagno?.find(room => room.roomId === currentRoomId)?.pavimentoBagnoImg;
    const pareteBagnoImg = store.selectedPavimentiBagno?.find(room => room.roomId === currentRoomId)?.pareteBagnoImg;
    if(pareteBagnoDes !== ''){
      setSelectedPareteBagnoTitle(pareteBagnoDes);
      setSelectedPareteBagnoImage(pareteBagnoImg);
    }
    if(pavimentoBagnoDes !== ''){
      setSelectedPavimentoBagnoTitle(pavimentoBagnoDes);
      setselectedPavimentoBagnoImage(pavimentoBagnoImg);
    }
  }

  useEffect(() => {
    setIsLoading(true);
    store.setUserCanGoNext(false);
    prepareOptions();
    settingFiniture();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoomId]);

  const prepareOptions = async () => {
    const substepId = store.currentSubstepId;
    const packages = await getPackages(configurationId, substepId, currentRoomId);
    substepIdRef.current = store.currentSubstepId;
    /*
    const { answers } = store;

    setSelectedMoods(answers.find((el) => el.substep.value === 'FINITURA')?.package?.value);
    const selectedFinitura = answers.find((el) => el.substep.value === 'TONALITA')?.package?.value;
    const stepFinituraId = answers.find((el) => el.substep.value === 'TONALITA')?.substep?.id;
    const finiturePackages = await getPackages(configurationId, stepFinituraId);
    setSelectedFinituraImage(
      finiturePackages?.data[0]?.packages
        ?.find((onePackage) => onePackage?.code === selectedFinitura)
        ?.images?.find((image) => image?.imageType?.value === 'Cover')?.label,
    );
    setselectedFinituraProductDescription(
      finiturePackages?.data[0]?.packages?.find(
        (onePackage) => onePackage?.code === selectedFinitura,
      )?.products[0]?.description,
    );
    */
    let filters = packages?.data[0]?.filters?.filter(filter => filter !== null);
    filters?.unshift('Tutte le collezioni');
    setFilters(filters);
    const packagesRestruct = packagesRestructuringClassicConfiguration(packages, true, true, 'SanitariModello');
    setPackages(packagesRestruct);
    resumeAnswerSimple(
      store,
      oldAnswersRef,
      substepIdRef,
      setSelectedPackages,
      setIsLoading,
      currentRoomId,
    );
  };

  useEffect(() => {
    const save = async () => {
      await handleSave(
        null,
        configurationId,
        substepIdRef.current,
        store,
        selectedPackages?.[0],
        currentRoomId,
      );
    };
    if (!_.isEqual(oldAnswersRef.current, selectedPackages)) {
      store.setSubstepSave(save);
    } else {
      store.setSubstepSave(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPackages]);

  const handleUserSelection = (selection) => {
    singleChoiceSelection([selection], [selectedPackages], setSelectedPackages, store);
  };

  return isLoading ? (
    <LoadingAnimation />
  ) : (
    <ConfiguratorThreeColumnsLayout
      firstRowTitle='CONFIGURA'
      secondRowTitle='I SANITARI'
      firstColumn={
        <FirstColumn
          options={packages}
          selectedOptions={selectedPackages}
          selectedPavimentoBagnoImage={selectedPavimentoBagnoImage}
          selectedPavimentoBagnoTitle={selectedPavimentoBagnoTitle}
          selectedPareteBagnoImage={selectedPareteBagnoImage}
          selectedPareteBagnoTitle={selectedPareteBagnoTitle}
        />
      }
      secondColumn={<SecondColumn options={packages} selectedOptions={selectedPackages} />}
      thirdColumn={
        <ThirdColumn
          options={packages}
          selectedOptions={selectedPackages}
          handleUserSelection={handleUserSelection}
          filters={filters}
          setFilter={setFilter}
          filter={filter}
        />
      }
    />
  );
};

export default SanitariModello;
