import { Page, StyleSheet, Text, View, Image } from '@react-pdf/renderer';
import { commonStyles } from '../pdfUtiles';

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  logoContainer: {
    height: '5%',
    width: '100%',
  },
  logo: {
    height: '100%',
    width: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  logoBody: {
    height: '15%',
    width: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop:'20%'
  },
  titleContainer: {
    height: '10%', 
    width: '100%', 
    justifyContent: 'center', 
    alignItems: 'center'
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '12px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  configurationName: {
    fontFamily: 'Miller Display',
    fontSize: '24px',
  },
  bold: { 
    fontWeight: 700 
  },
  bodyContainer: {
    height: '80%', 
    width: '100%', 
  },
  projectImage: {
    height: '100%', 
    width: 'auto', 
    marginLeft: 'auto', 
    marginRight: 'auto'
  },
  footer: {
    height: '5%', 
    width: '100%', 
    display: 'flex', 
    flexDirection:'row', 
    justifyContent: 'space-between', 
    alignItems: 'center'
  },
  flexRow: {
    display: 'flex', 
    flexDirection:'row'
  },
  flexColumn: {
    display: 'flex', 
    flexDirection:'column'
  },
  borderLeftDivider: {
    borderLeft: '1px solid #000', 
    paddingLeft: '8px', 
    marginLeft: '8px'
  }
  /*
  header: {
    textAlign: 'center',
    paddingBottom: '36px',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '12px',
    paddingBottom: '8px',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  bold: { fontWeight: 700 },
  configurationName: {
    fontFamily: 'Miller Display',
    fontSize: '24px',
  },
  logoContainer: {
    flexGrow: 1,
    height: '10%',
    width: '100%',
    marginBottom: '10px',
  },
  logo: {
    height: '100%',
    width: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  /*
  imageContainer: {
    flexGrow: 1,
    height: '10%',
  },
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center top',
  },
  
  footer: {
    paddingTop: '12%',
  },
  footerRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  borderRight: {
    paddingRight: '8px',
    borderRight: '1px solid #000',
    marginRight: '8px',
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
  },
  */
});

const CoverPage = ({
  apartmentCode,
  configurationName,
  apartmentType,
  building,
  stair,
  floor,
  quadrant,
}) => {
  return (
    <Page size='A4' style={[commonStyles.page, styles.page]}>
      <View style={styles.logoContainer}>
        <Image style={styles.logo} src={`${process.env.PUBLIC_URL}/assets/images/logo.png`} />
      </View>
      <View style={styles.titleContainer}>
        <View style={styles.title}>
          <Text>Appartamento </Text>
          <Text style={styles.bold}>{apartmentCode}</Text>
        </View>
        <Text style={styles.configurationName}>{configurationName}</Text>
      </View>
      <View style={styles.bodyContainer}>
      <Image style={styles.logoBody} src={`${process.env.PUBLIC_URL}/assets/images/logo.png`} />
        {/*<Image style={styles.projectImage} src={`${process.env.PUBLIC_URL}/assets/images/pdf-cover.png`} /> */}
      </View>
      <View style={styles.footer}>
        <View style={styles.flexColumn}>
          <Text style={styles.bold}>{apartmentType}</Text>
        </View>
        <View style={styles.flexColumn}>
          <View style={styles.flexRow}>
            <Text>Progetto: </Text>
            <Text style={styles.bold}>{building}</Text>
          </View>
          <View style={styles.flexRow}>
            <Text>Piano: </Text>
            <Text style={styles.bold}>{floor}</Text>
            <Text style={styles.borderLeftDivider}>Quadrante: </Text>
            <Text style={styles.bold}>{quadrant}</Text>
          </View>
        </View>
      </View>
    </Page>
  )
  /*
  return (
    <Page size='A4' style={[commonStyles.page, styles.page]}>
      <View style={styles.header}>
        <View style={styles.logoContainer}>
          <Image style={styles.logo} src={`${process.env.PUBLIC_URL}/assets/images/logo.png`} />
        </View>
        <View style={styles.title}>
          <Text>Appartamento </Text>
          <Text style={styles.bold}>{apartmentCode}</Text>
        </View>
        <Text style={styles.configurationName}>{configurationName}</Text>
      </View>
      {
        /*
        <View style={styles.imageContainer}>
          <Image style={styles.image} src={`${process.env.PUBLIC_URL}/assets/images/pdf-cover.png`} />
        </View>
        
      }
      <View style={styles.footer}>
        <View style={styles.footerRow}>
          <View style={styles.flex}>
            <Text>Appartamento </Text>
            <Text style={styles.bold}>{apartmentCode}</Text>
          </View>
          <View style={styles.flex}>
            <Text>Edificio </Text>
            <Text style={[styles.bold, styles.borderRight]}>{building}</Text>
            <Text>Scala </Text>
            <Text style={styles.bold}>{stair}</Text>
          </View>
        </View>
        <View style={styles.footerRow}>
          <View>
            <Text style={styles.bold}>{apartmentType}</Text>
          </View>
          <View style={styles.flex}>
            <Text>Piano </Text>
            <Text style={[styles.bold, styles.borderRight]}>{floor}</Text>
            <Text>Quadrante </Text>
            <Text style={styles.bold}>{quadrant}</Text>
          </View>
        </View>
      </View>
      
    </Page>
  );
  */
};

export default CoverPage;
