import * as React from 'react';

function SvgTemperatura(props) {
  return (
    <svg width="55" height="97" viewBox="0 0 55 97" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_3439_24069" fill="white">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.5 0C13.1487 0 8 5.14873 8 11.5V61.7503C3.15008 65.2976 0 71.031 0 77.5C0 88.2696 8.73045 97 19.5 97C30.2696 97 39 88.2696 39 77.5C39 71.031 35.8499 65.2976 31 61.7503V11.5C31 5.14873 25.8513 0 19.5 0Z"/>
      </mask>
      <path d="M8 61.7503L8.88554 62.961L9.5 62.5116V61.7503H8ZM31 61.7503H29.5V62.5116L30.1145 62.961L31 61.7503ZM9.5 11.5C9.5 5.97715 13.9772 1.5 19.5 1.5V-1.5C12.3203 -1.5 6.5 4.3203 6.5 11.5H9.5ZM9.5 61.7503V11.5H6.5V61.7503H9.5ZM1.5 77.5C1.5 71.5297 4.40519 66.238 8.88554 62.961L7.11446 60.5396C1.89497 64.3572 -1.5 70.5322 -1.5 77.5H1.5ZM19.5 95.5C9.55888 95.5 1.5 87.4411 1.5 77.5H-1.5C-1.5 89.098 7.90202 98.5 19.5 98.5V95.5ZM37.5 77.5C37.5 87.4411 29.4411 95.5 19.5 95.5V98.5C31.098 98.5 40.5 89.098 40.5 77.5H37.5ZM30.1145 62.961C34.5948 66.238 37.5 71.5297 37.5 77.5H40.5C40.5 70.5322 37.105 64.3572 31.8855 60.5396L30.1145 62.961ZM29.5 11.5V61.7503H32.5V11.5H29.5ZM19.5 1.5C25.0228 1.5 29.5 5.97715 29.5 11.5H32.5C32.5 4.3203 26.6797 -1.5 19.5 -1.5V1.5Z" fill="#4E4D4D" mask="url(#path-1-inside-1_3439_24069)"/>
      <path d="M23.7998 64.7997V33H15.2013V64.7997C9.85381 66.5878 6 71.6214 6 77.5513C6 84.9784 12.0437 91 19.5 91C26.9563 91 33 84.9795 33 77.5513C33 71.6214 29.1473 66.5878 23.7987 64.7997H23.7998Z" fill="#D3D3D3"/>
      <rect x="39" y="16" width="16" height="4" fill="#D9D9D9"/>
      <rect x="39" y="27" width="16" height="4" fill="#D9D9D9"/>
      <rect x="39" y="38" width="16" height="4" fill="#D9D9D9"/>
      <rect x="39" y="49" width="16" height="4" fill="#D9D9D9"/>
    </svg>
  );
}

export default SvgTemperatura;
