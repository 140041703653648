import * as React from 'react';

function SvgNoLuce(props) {
  return (
    <svg viewBox='0 0 80 59' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#no-luce_svg__clip0_2068_22803)'>
        <path
          d='M73.668 48.31c-3.47-11.81-12.73-20.56-24.18-22.82l-.35-.06a5.68 5.68 0 00-5-4.21V3.48a1.85 1.85 0 001.31-1.31 1.643 1.643 0 000-.38h-3.74a1.8 1.8 0 001.38 1.71v17.73a5.66 5.66 0 00-5.05 4.25l-.35.07c-11.3 2.34-20.45 11.07-23.89 22.78a4.24 4.24 0 004.06 5.4h20.78a5.741 5.741 0 0010.19 0h20.77a4.212 4.212 0 003.39-1.7 4.16 4.16 0 00.68-3.72zm-29.93 7.49a4.64 4.64 0 01-3.9-2.09h7.81a4.67 4.67 0 01-3.91 2.11v-.02zm28.44-4.39a3.18 3.18 0 01-2.57 1.29h-51.74a3.22 3.22 0 01-3.08-4.11c3.33-11.34 12.19-19.8 23.12-22.07a28.55 28.55 0 0111.4 0c11.06 2.18 20 10.66 23.39 22.12a3.18 3.18 0 01-.52 2.79v-.02z'
          fill='#3A3A3A'
        />
        <path d='M77.119.007L-.004 54.998l2.572 3.607 77.123-54.99L77.119.006z' fill='#F0801A' />
      </g>
      <defs>
        <clipPath id='no-luce_svg__clip0_2068_22803'>
          <path fill='#fff' d='M0 0h79.68v58.62H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgNoLuce;
