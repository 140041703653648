import { Grid, makeStyles } from '@material-ui/core';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router';
import SideMenu from '../../components/SideMenu/SideMenu';
import SchemaRivestimentoBagno from './bagno/SchemaRivestimentoBagno';
import TonalitaBagno from './bagno/TonalitaBagno';
import FormatoBagno from './bagno/FormatoBagno';
import FinitureBagno from './bagno/FinitureBagno';
import DocciaModello from './bagno/DocciaModello';
import DocciaFinitura from './bagno/DocciaFinitura';
import RubinetteriaModello from './bagno/RubinetteriaModello';
import MobileLavaboModello from './bagno/MobileLavaboModello';
import MobileLavaboFinitura from './bagno/MobileLavaboFinitura';
import RubinetteriaFinitura from './bagno/RubinetteriaFinitura';
import SanitariModello from './bagno/SanitariModello';
import SanitariFinitura from './bagno/SanitariFinitura';
import AccessoriDocciaModello from './bagno/AccessoriDocciaModello';
import AccessoriDocciaFinituraVetro from './bagno/AccessoriDocciaFinituraVetro';
import VascaModello from './bagno/VascaModello';
import VascaFinitura from './bagno/VascaFinitura';
import AccessoriVascaSoffione from './bagno/AccessoriVascaSoffione';
import AccessoriVascaBox from './bagno/AccessoriVascaBox';
import Lavanderia from './bagno/Lavanderia';
import ScaldaSalvietteePlaccadiScarico from './bagno/ScaldaSalvietteePlaccadiScarico';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    '& .sidebar-container': {
      height: '100%',
    },
    '& > div': {
      height: '100%',
    },
  },
}));

const BagnoPage = () => {
  const { path } = useRouteMatch();
  const classes = useStyles();

  const bagniSubsteps = [
    { stepKey: 'RIVESTIMENTI', substepKey: 'SCHEMARIVESTIMENTO', component: <SchemaRivestimentoBagno /> },
    { stepKey: 'RIVESTIMENTI', substepKey: 'FINITURA', component: <FinitureBagno /> },
    { stepKey: 'RIVESTIMENTI', substepKey: 'TONALITA', component: <TonalitaBagno /> },
    { stepKey: 'RIVESTIMENTI', substepKey: 'FORMATO', component: <FormatoBagno /> },
    { stepKey: 'DOCCIA', substepKey: 'MODELLO', component: <DocciaModello /> },
    { stepKey: 'DOCCIA', substepKey: 'FINITURA', component: <DocciaFinitura /> },
    { stepKey: 'VASCA', substepKey: 'MODELLO', component: <VascaModello /> },
    { stepKey: 'VASCA', substepKey: 'FINITURA', component: <VascaFinitura /> },
    { stepKey: 'RUBINETTERIA', substepKey: 'MODELLO', component: <RubinetteriaModello /> },
    { stepKey: 'RUBINETTERIA', substepKey: 'FINITURA', component: <RubinetteriaFinitura /> },
    { stepKey: 'MOBILELAVABO', substepKey: 'MODELLO', component: <MobileLavaboModello /> },
    { stepKey: 'MOBILELAVABO', substepKey: 'FINITURA', component: <MobileLavaboFinitura /> },
    { stepKey: 'SANITARI', substepKey: 'MODELLO', component: <SanitariModello /> },
    { stepKey: 'SANITARI', substepKey: 'FINITURA', component: <SanitariFinitura /> },
    { stepKey: 'ACCESSORIDOCCIA', substepKey: 'MODELLO', component: <AccessoriDocciaModello /> },
    { stepKey: 'ACCESSORIDOCCIA', substepKey: 'FINITURAVETRO', component: <AccessoriDocciaFinituraVetro /> },
    { stepKey: 'ACCESSORIVASCA', substepKey: 'SOFFIONE', component: <AccessoriVascaSoffione /> },
    { stepKey: 'ACCESSORIVASCA', substepKey: 'BOX', component: <AccessoriVascaBox /> },
    { stepKey: 'ALTREDOTAZIONI', substepKey: 'SCALDASALVIETTEEPLACCADISCARICO', component: <ScaldaSalvietteePlaccadiScarico /> },
    { stepKey: 'LAVANDERIA', substepKey: 'LAVANDERIA', component: <Lavanderia /> },
  ];

  return (
    <Grid container className={classes.root} wrap='nowrap'>
      <Grid item xs className={'configurator-body'}>
        <Switch>
          {bagniSubsteps?.map((el) => (
            <Route
              key={`${el.stepKey}/${el.substepKey}`}
              path={`${path}/${el.stepKey}/${el.substepKey}`}
            >
              {el.component}
            </Route>
          ))}
        </Switch>
      </Grid>
      <Grid item xs='auto' className='sidebar-container'>
        <SideMenu />
      </Grid>
    </Grid>
  );
};

export default BagnoPage;
