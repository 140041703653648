import * as React from "react";

function SvgLeftChevron(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 10 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9 1L2 7.5 9 14" stroke={props.color} strokeWidth={2} />
    </svg>
  );
}

export default SvgLeftChevron;
