import * as React from 'react';

function SvgChiusuraWireless(props) {
  return (
    <svg viewBox='0 0 111 80' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M79.31 9.08a.86.86 0 00-.86.88v2.18a.85.85 0 00.76.86c10.28.43 18.56 9.21 19 20.09a.85.85 0 00.82.84h2.069a.85.85 0 00.82-.91c-.44-13-10.33-23.48-22.6-24'
        fill='#4D4D4D'
      />
      <path
        d='M79.3 0a.85.85 0 00-.85.87v2.19a.85.85 0 00.81.87c15 .44 27.099 13.28 27.509 29.15a.86.86 0 00.83.86h2.06a.838.838 0 00.82-.9C110.039 15 96.299.47 79.299 0zM89.6 33.13a.85.85 0 00.83.81h2.06a.85.85 0 00.82-.94c-.43-8-6.47-14.37-14-14.83a.84.84 0 00-.86.87v2.19a.86.86 0 00.76.88 11.54 11.54 0 0110.38 11M45.209 39.52l-10.25 10.17a1.7 1.7 0 000 2.38l6.06 6a1.681 1.681 0 002.38 0l1-1 21.6 21.58a1.74 1.74 0 001.16.49l9 .17a1.69 1.69 0 001.68-1.69v-7.09a1.62 1.62 0 00-.49-1.19l-4-4a1.63 1.63 0 00-1.14-.47l-3.61-.1.35-2.89a1.71 1.71 0 00-.49-1.4L65.999 58a1.69 1.69 0 00-1-.49l-3.37-.32-.94-4.19a1.69 1.69 0 00-1.35-1.29l-4.57-.83-.7-4.42a1.66 1.66 0 00-.47-.93l-6-6a1.68 1.68 0 00-2.38 0M24.529 15h.07a1.7 1.7 0 010 2.38l-11.7 11.71a1.681 1.681 0 01-2.38.19 1.7 1.7 0 01-.18-2.38c.06-.06.12-.13.18-.18L22.149 15a1.671 1.671 0 012.38 0zm-8.32-7.29l-13 13a8.47 8.47 0 000 12.87l19.48 19.51a3.6 3.6 0 005.05 0l20.75-20.74a3.63 3.63 0 000-5.06L29.029 7.71c-4-4-8.81-4-12.84 0'
        fill='#4D4D4D'
      />
    </svg>
  );
}

export default SvgChiusuraWireless;
