import GridDetailsContainer from '../../../components/Card/GridDetailsContainer';
import MaterialDataSheet from '../../../components/MaterialDataSheet';
import ConfiguratorTwoColumnsLayoutCarta from '../../../layouts/ConfiguratorTwoColumnsLayoutCarta';
import ConfiguratorThreeColumnsLayout from '../../../layouts/ConfiguratorThreeColumnsLayout';
import { useEffect, useState, useRef } from 'react';
import { resumeAnswerSimple } from '../../../utils/configuratorUtils';
import { getPackages } from '../../../services/packageApi';
import { useStore } from '../../../store/storeUtils';
import { useParams } from 'react-router';
import { handleSave, singleChoiceSelection } from '../../../utils/selectionUtils';
import _ from 'lodash';
import LoadingAnimation from '../../../components/LoadingAnimation';
import { getLayoutImagesByConfigurationId } from '../../../services/layoutApi';
import PlanimetryZoom from '../../../components/PlanimetryZoom';
import { Grid, useTheme, Link } from '@material-ui/core';
import BigBox from './config/BigBox';
import {
  materialDataSheetOptionsConfiguration,
  packagesRestructuringClassicConfiguration,
} from '../../../configs/giornoNottePhasesConfigurations';
import Button3D from '../../../components/Buttons/Button3D';
import { makeStyles } from '@material-ui/core';

/*
const FirstStepFirstColumn = () => {
  return <BigImage image={`${process.env.PUBLIC_URL}/assets/images/Soggiorno.jpg`} />;
};
*/

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '15px',
    textAlign: 'left',
    height: '5%',
  },
  subTitle: {
    fontSize: '11px',
    textAlign: 'left',
    height: '5%',
  },
  secondaryText: {
    color: theme.palette.color.orange,
  },
}));

const SecondStepFirstColumn = ({ planimetryImages, cartaParatiReference }) => {
  return (
    <div style={{ position: 'relative', width: '100%', height: '95%' }}>
      <PlanimetryZoom
        images={planimetryImages}
        // rotate={true}
        notZoom={true}
        reference={cartaParatiReference}
      />
    </div>
  );
};

/*
const FirstStepSecondColumn = ({ setFirstStep, planimetryImages, cartaParatiReference }) => {
  return (
    <div style={{ height: '100%', backgroundColor: '#F0EDEB' }}>
      <InfoColumn
        setFirstStep={setFirstStep}
        planimetryImages={planimetryImages}
        layoutReference={cartaParatiReference}
      />
    </div>
  );
};
*/

const SecondStepSecondColumn = ({ currentPosition, setSecondStep }) => {
  const theme = useTheme();
  return (
    <Grid container direction='row' style={{ height: '100%' }}>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '10%',
        }}
      >
        {/*<PlateContainer elements={[currentPosition]} plateText={'P'} />*/}
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '-10%',
        }}
      >
        <p
          style={{
            fontFamily: 'miller',
            fontStyle: 'italic',
            fontSize: '28px',
            textAlign: 'center',
          }}
        >
          Puoi selezionare una <b style={{ color: theme.palette.color.orange }}>carta da parati</b>{' '}
          nella posizione{' '}
          <b style={{ color: theme.palette.color.orange }}> indicata nella planimetria</b> a
          sinistra
        </p>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '-20%',
        }}
      >
        {/*<p>* Se non desideri la carta da parati seleziona 'Nessuno'.</p>*/}
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <Button3D
          style={{
            width: '100%',
            maxWidth: 'unset !important',
            height: '40px !important',
            fontSize: '13px',
          }}
          text={'INIZIA LA CONFIGURAZIONE'}
          onClick={() => setSecondStep(false)}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      ></Grid>
    </Grid>
  );
};

const FirstColumn = ({
  options,
  selectedOptions,
  selectedFormatoCamereImage,
  selectedFormatoCamereTitle,
  selectedFormatoCucinaImage,
  selectedFormatoCucinaTitle,
}) => {
  const selectedVirtualImage = options
    ?.find((option) => option.id === selectedOptions?.[0])
    ?.virtualImage?.replace(/\s/g, '%20');
  const selectedOption = options?.find((option) => option.id === selectedOptions?.[0]);
  const isNoChoice = selectedOption?.title === 'Nessuno';
  // const isChained = options?.find(option => option.id === selectedOptions?.[0])?.isChained;
  return (
    <BigBox
      defaultImage={`${process.env.PUBLIC_URL}/assets/images/Soggiorno.jpg`}
      selectedVirtualImage={selectedVirtualImage}
      selectedFormatoCamereImage={selectedFormatoCamereImage}
      // selectedFormatoCucinaImage={selectedFormatoCucinaImage}
      selectedFormatoCamereTitle={selectedFormatoCamereTitle}
      // selectedFormatoCucinaTitle={selectedFormatoCucinaTitle}
      isNoChoice={isNoChoice}
    />
  );
};

const SecondColumn = ({ options, selectedOptions }) => {
  const materialDataSheetOptions = materialDataSheetOptionsConfiguration(
    options,
    selectedOptions,
    true,
  );
  const selectedOption = options?.find((option) => option.id === selectedOptions?.[0]);
  const isNoChoice = selectedOption?.title === 'Nessuno';
  return (
    <MaterialDataSheet
      firstRowTitle={'PREVIEW'}
      secondRowTitle={'PRODOTTO'}
      previewBoxText={'un MODELLO'}
      material={materialDataSheetOptions}
      noImageLayout={isNoChoice}
      isNoChoice={isNoChoice}
    />
  );
};

const ThirdColumn = ({
  options,
  selectedOptions,
  handleUserSelection,
  setLayout,
  layout,
  planimetryImages,
  cartaParatiReference,
}) => {
  const classes = useStyles();

  const containers = [
    {
      title: 'Carta da Parati',
      content: options,
    },
  ];

  const title = (
    <span>
      Vuoi aggiungere una <span className={classes.secondaryText}>carta da parati</span> ?
    </span>
  );

  const subTitle = (
    <>
      {layout && (
        <PlanimetryZoom
          images={planimetryImages}
          rotate={true}
          notZoom={true}
          reference={cartaParatiReference}
          onlyZoom={true}
          setLayout={setLayout}
        />
      )}
      <div style={{ height: '5%' }}>
        <Link
          onClick={() => {
            setLayout(true);
          }}
          style={{ cursor: 'pointer' }}
        >
          Visualizza la parete&nbsp;
        </Link>{' '}
        nella planimetria
      </div>
    </>
  );

  /*
  return (
    <>
      {layout && (
        <PlanimetryZoom
          images={planimetryImages}
          rotate={true}
          notZoom={true}
          reference={cartaParatiReference}
          onlyZoom={true}
          setLayout={setLayout}
        />
      )}
      <div style={{ height: '5%' }}>
        <Link
          onClick={() => {
            setLayout(true);
          }}
          style={{ cursor: 'pointer' }}
        >
          Visualizza la parete&nbsp;
        </Link>{' '}
        nella planimetria
      </div>
    </>
  );
  */
  return (
    <>
      <div className={classes.title}>{title}</div>
      <div className={classes.subTitle}>{subTitle}</div>
      <div style={{ height: '90%' }}>
        <GridDetailsContainer
          containers={containers}
          onClick={handleUserSelection}
          selectedOptions={selectedOptions}
          numCols={1}
        />
      </div>
    </>
  );
};

const CartaparatiGiornoNotte = () => {
  const [packages, setPackages] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [images, setImages] = useState([]);
  const [secondStep, setSecondStep] = useState(true);
  const [currentPosition, setCurrentPosition] = useState(0);
  const [selectedFormatoCamereImage, setSelectedFormatoCamereImage] = useState(null);
  const [selectedFormatoCamereTitle, setSelectedFormatoCamereTitle] = useState(null);
  const [selectedFormatoCucinaImage, setSelectedFormatoCucinaImage] = useState(null);
  const [selectedFormatoCucinaTitle, setSelectedFormatoCucinaTitle] = useState(null);
  const [layout, setLayout] = useState(false);

  const substepIdRef = useRef();
  const oldAnswersRef = useRef();
  const cartaParatiReference = useRef();

  const store = useStore();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const currentRoomId = store.currentRoomId;
  const configurationId = params.configuration;

  const settingFiniture = () => {
    const storedFinitureCamereTitle = store.selectedFormatoCameraTitle;
    const storedFinituraCamereImage = store.selectedFormatoCameraImage;
    const storedFinituraCucinaTitle = store.selectedFormatoCucinaTitle;
    const storedFinituraCucinaImage = store.selectedFormatoCucinaImage;
    if (storedFinitureCamereTitle !== '') {
      setSelectedFormatoCamereTitle(storedFinitureCamereTitle);
      setSelectedFormatoCamereImage(storedFinituraCamereImage);
    }
    if (storedFinituraCucinaTitle !== '') {
      setSelectedFormatoCucinaTitle(storedFinituraCucinaTitle);
      setSelectedFormatoCucinaImage(storedFinituraCucinaImage);
    }
  };

  useEffect(() => {
    store.setUserCanGoNext(false);
    prepareOptions();
    settingFiniture();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    store.setUserCanGoNext(false);
    prepareOptions();
    setSecondStep(true);
    setSelectedPackages([]);
    setPackages([]);
    oldAnswersRef.current = null;
    setImages([]);
    cartaParatiReference.current = null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoomId]);

  /*
  useEffect(() => {
    if(images?.length > 0 && !firstStep){
      setTimeout(() => {
        setSecondStep(false);
      }, 10000);
    }
  }, [images, firstStep]);
  */

  const prepareOptions = async () => {
    const substepId = store.currentSubstepId;
    const phases = store.phases;
    const giornoEnottePhase = phases?.find((phase) => phase.key === 'GIORNOENOTTE');
    const cartaParatiStep = giornoEnottePhase?.steps?.find((step) => step.key === 'CARTAPARATI');
    const cartaParatiSubStep = cartaParatiStep?.substeps?.find(
      (substep) => substep.roomId === currentRoomId,
    );
    const currentPosition = cartaParatiSubStep?.posizioneRivestimento;
    setCurrentPosition(currentPosition);
    const rooms = store.currentConfiguration?.layout?.rooms;
    const currentRoom = rooms.find((room) => room.id === currentRoomId);
    const currentRoomTag = currentRoom?.tags?.filter((tag) => tag.value.includes('R_'));
    //const currentRoomTagUrl = currentRoomTag?.fileLabels?.[0]?.url?.replace(/\s/g, '%20');
    /*
    const insertingRoomInLayout = {
      fileUrl: currentRoomTagUrl,
      order: 3,
    };
    */
    // const { answers } = store;
    // setSelectedMoods(answers.find((el) => el.substep.value === 'MOOD')?.package?.value);
    const layoutImagesResponse = await getLayoutImagesByConfigurationId(configurationId);
    const layoutImages = layoutImagesResponse.data;
    currentRoomTag.forEach((currentTag) => {
      const currentRoomTagUrl = currentTag?.fileLabels?.[0]?.url?.replace(/\s/g, '%20');
      const insertingRoomInLayout = {
        fileUrl: currentRoomTagUrl,
        order: 3,
      };
      layoutImages.push(insertingRoomInLayout);
    });
    setImages(layoutImages);
    const packages = await getPackages(configurationId, substepId, currentRoomId);
    substepIdRef.current = store.currentSubstepId;

    /*
    const selectedFinitura = answers.find((el) => el.substep.value === 'FINITURA')?.package?.value;
    let stepFinituraId;
    let finiturePackages;
    if (selectedFinitura) {
      stepFinituraId = answers.find((el) => el.substep.value === 'FINITURA')?.substep?.id;
      if (substepId !== stepFinituraId) {
        finiturePackages = await getPackages(configurationId, stepFinituraId);
        setSelectedFinituraImage(
          finiturePackages?.data[0]?.packages
            ?.find((onePackage) => onePackage?.code === selectedFinitura)
            ?.images?.find((image) => image?.imageType?.value === 'Cover')
            ?.label?.replace(/\s/g, '%20'),
        );
      }
    }
    */

    const packagesRestruct = packagesRestructuringClassicConfiguration(
      packages,
      false,
      false,
      false,
      'CartaParati',
    );
    setPackages(packagesRestruct);
    resumeAnswerSimple(
      store,
      oldAnswersRef,
      substepIdRef,
      setSelectedPackages,
      setIsLoading,
      currentRoomId,
    );
  };

  useEffect(() => {
    const save = async () => {
      await handleSave(
        null,
        configurationId,
        substepIdRef.current,
        store,
        selectedPackages?.[0],
        currentRoomId,
      );
    };
    if (!_.isEqual(oldAnswersRef.current, selectedPackages)) {
      store.setSubstepSave(save);
    } else {
      store.setSubstepSave(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPackages]);

  const handleUserSelection = (selection) => {
    singleChoiceSelection([selection], [selectedPackages], setSelectedPackages, store);
  };

  return isLoading ? (
    <LoadingAnimation />
  ) : secondStep ? (
    <ConfiguratorTwoColumnsLayoutCarta
      firstRowTitle='CONFIGURA'
      secondRowTitle={'LA CARTA DA PARATI'}
      noLoad={true}
      invertedProportion={secondStep}
      firstColumn={
        <SecondStepFirstColumn
          planimetryImages={images}
          cartaParatiReference={cartaParatiReference}
        />
      }
      secondColumn={
        <SecondStepSecondColumn currentPosition={currentPosition} setSecondStep={setSecondStep} />
      }
    />
  ) : (
    <ConfiguratorThreeColumnsLayout
      firstRowTitle='CONFIGURA'
      secondRowTitle={'LA CARTA DA PARATI'}
      firstColumn={
        <FirstColumn
          options={packages}
          selectedOptions={selectedPackages}
          selectedFormatoCamereImage={selectedFormatoCamereImage}
          selectedFormatoCamereTitle={selectedFormatoCamereTitle}
          selectedFormatoCucinaImage={selectedFormatoCucinaImage}
          selectedFormatoCucinaTitle={selectedFormatoCucinaTitle}
        />
      }
      secondColumn={<SecondColumn options={packages} selectedOptions={selectedPackages} />}
      thirdColumn={
        <ThirdColumn
          options={packages}
          selectedOptions={selectedPackages}
          handleUserSelection={handleUserSelection}
          setLayout={setLayout}
          layout={layout}
          planimetryImages={images}
          cartaParatiReference={cartaParatiReference}
          currentPosition={currentPosition}
        />
      }
    />
  );
};

export default CartaparatiGiornoNotte;
