import * as React from 'react';

function SvgDocumentDownload(props) {
  return (
    <svg viewBox='0 0 42 49' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#document-download_svg__clip0_3005_23643)' fill='#F0801A'>
        <path d='M6 18.65h18.45c.45 0 .81-.36.81-.81 0-.45-.36-.81-.81-.81H6a.809.809 0 100 1.62zM6 13.18h18.45c.45 0 .81-.36.81-.81 0-.45-.36-.81-.81-.81H6a.809.809 0 100 1.62z' />
        <path d='M31.17 22.77V8l-7.36-8H2.59A2.59 2.59 0 000 2.59v37a2.59 2.59 0 002.59 2.59H17.3c2.28 3.81 6.43 6.37 11.18 6.37 7.19 0 13.03-5.85 13.03-13.03 0-6.26-4.44-11.5-10.34-12.75zm-6.75-18.9l3.23 3.51h-3.23V3.87zm-6.34 23.82H6a.809.809 0 100 1.62h11.01c-1 1.84-1.57 3.96-1.57 6.2 0 1.58.3 3.09.81 4.49H2.59c-.23 0-.41-.19-.41-.41v-37c0-.23.19-.41.41-.41h19.66v6.29c0 .6.49 1.09 1.09 1.09H29v12.96c-.18 0-.35-.03-.52-.03-1.13 0-2.21.16-3.26.43-.09-.35-.4-.6-.77-.6H6a.809.809 0 100 1.62h16.52c-1.75.9-3.26 2.19-4.44 3.75zm15.24 13.65h-9.7v-1.37h9.7v1.37zm-2.15-5.4L29 38.09l-.52.52-4.85-4.79h2.77v-4.11h4.16v4.11h2.77l-2.15 2.13-.01-.01z' />
      </g>
      <defs>
        <clipPath id='document-download_svg__clip0_3005_23643'>
          <path fill='#fff' d='M0 0h41.51v48.55H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgDocumentDownload;
