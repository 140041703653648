import { Grid, makeStyles } from '@material-ui/core';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router';
import Temperatura from './tecnologia/Temperatura';
import SmartHome from './tecnologia/SmartHome';
import SideMenu from '../../components/SideMenu/SideMenu';
import LuciComodinoAltre from './tecnologia/LuciComodinoAltre';
import LuciGoleLuminose from './tecnologia/LuciGoleLuminose';
import LuciPuntiExtra from './tecnologia/LuciPuntiExtra';
import LuciArmadi from './tecnologia/LuciArmadi';
import ImpiantoPreseUsb from './tecnologia/ImpiantoPreseUsb';
import ImpiantoPreseCucina from './tecnologia/ImpiantoPreseCucina';
import ImpiantoPreseLoggia from './tecnologia/ImpiantoPreseLoggia';
import ImpiantoPreseTv from './tecnologia/ImpiantoPreseTv';
import ImpiantoPreseComandate from './tecnologia/ImpiantoPreseComandate';
import LuciComodinoPrincipale from './tecnologia/LuciComodinoPrincipale';
import ImpiantoPreseLavanderia from './tecnologia/ImpiantoPreseLavanderia';
import usePageStyle from '../../styles/usePageStyle';

const useStyles = makeStyles((theme) => ({
  tecnologiaPageRoot: {
    height: '100%',
    '& .sidebar-container': {
      height: '100%',
    },
    '& > div': {
      height: '100%',
    },
    '& > div:first-child': {
      width: 'calc(100% - 80px)',
    },
  },
}));

const TecnologiaPage = () => {
  const { path } = useRouteMatch();
  const classes = usePageStyle();

  const layoutSubsteps = [
    { stepKey: 'Luci', substepKey: 'PuntiLuceExtra', component: <LuciPuntiExtra /> },
    {
      stepKey: 'Luci',
      substepKey: 'LuciComodinoCameraPrincipale',
      component: <LuciComodinoPrincipale />,
    },
    { stepKey: 'Luci', substepKey: 'LuciComodinoAltreCamere', component: <LuciComodinoAltre /> },
    { stepKey: 'Luci', substepKey: 'GoleLuminose', component: <LuciGoleLuminose /> },
    { stepKey: 'Luci', substepKey: 'IlluminazioneArmadi', component: <LuciArmadi /> },
    { stepKey: 'Luci', substepKey: 'PreseComandate', component: <ImpiantoPreseComandate /> },
    { stepKey: 'Prese', substepKey: 'PresePianoCottura', component: <ImpiantoPreseCucina /> },
    { stepKey: 'Prese', substepKey: 'PreseLavanderia', component: <ImpiantoPreseLavanderia /> },
    { stepKey: 'Prese', substepKey: 'PreseLoggia', component: <ImpiantoPreseLoggia /> },
    { stepKey: 'Prese', substepKey: 'PreseUsb', component: <ImpiantoPreseUsb /> },
    { stepKey: 'Prese', substepKey: 'PreseTv', component: <ImpiantoPreseTv /> },
    { stepKey: 'Temperatura', substepKey: 'Temperatura', component: <Temperatura /> },
    { stepKey: 'SmartHome', substepKey: 'SmartHome', component: <SmartHome /> },
  ];

  return (
    <Grid container wrap='nowrap' className={classes.root}>
      <Grid item xs>
        <Switch>
          {layoutSubsteps.map((el) => (
            <Route
              key={`${el.stepKey}/${el.substepKey}`}
              path={`${path}/${el.stepKey}/${el.substepKey}`}
            >
              {el.component}
            </Route>
          ))}
        </Switch>
      </Grid>
      <Grid item xs='auto' className='sidebar-container'>
        <SideMenu />
      </Grid>
    </Grid>
  );
};

export default TecnologiaPage;
