import { Grid, lighten, makeStyles } from '@material-ui/core';
import CurrencyFormatter from '../../utils/CurrencyFormatter';
import { Alert, CloseRound, Check, NoChoice } from '../Icons';
import clsx from 'clsx';
import { useStore } from '../../store/storeUtils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    // minHeight: '100%',
    border: `3px solid transparent`,
    backgroundColor: '#F8F5F3',
    borderRadius: '4px',
    cursor: 'pointer',
    '&.selected': {
      backgroundColor: '#F7F6F4',
    },
    '&.step-mode': {
      justifyContent: 'center',
    }
  },
  header: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'left',
    alignItems: 'left',
    paddingBottom: '20px',
    marginTop: '20px',
    paddingLeft: '24px',
    paddingRight: '24px',
    cursor: 'pointer',
    '&.choosed': {
      cursor: 'unset',
    },
    userSelect: 'none',
  },
  headerProduct: {
    display: 'flex',
    flexGrow: 1,
    paddingLeft: '24px',
    paddingTop: '20px',
    paddingBottom: '20px',
    maxWidth: '148px',
    maxHeight: '140px',
    '&.choosed': {
      cursor: 'unset',
    },
    userSelect: 'none',
    position: 'relative',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '19px',
    '& svg': {
      marginLeft: '-18px',
      marginRight: '5px',
    },
  },
  image: {
    width: '100px',
    height: '100px',
    backgroundColor: '#CDC7C1',
    borderRadius: '8px',
    objectFit: 'cover',
    border: '1px solid white',
    '&.opacity': {
      filter: 'opacity(50%)',
    },
    '&.rectangular': {
      width: '50px',
      borderRadius: 'unset',
    },
  },
  imageMultiple: {
    width: '75px',
    height: '75px',
    backgroundColor: '#CDC7C1',
    objectFit: 'cover',
    '&.opacity': {
      filter: 'opacity(50%)',
    },
    '&.rectangular': {
      width: '50px',
      borderRadius: 'unset',
    },
  },
  imageMultipleRight: {
    borderRadius: '0px 5px 5px 0px',
  },
  imageMultipleLeft: {
    borderRadius: '5px 0px 0px 5px',
  },
  textInImageLeft: {
    position: 'absolute',
    bottom: '10px',
    left: '10px',
  },
  textInImageRight: {
    position: 'absolute',
    bottom: '10px',
    right: '10px',
  },
  description: {
    marginTop: '4px',
    fontFamily: 'miller',
    fontStyle: 'italic',
    fontSize: '12px',
    textAlign: 'left',
    fontWeight: '400',
    color: '#7C7C7C',
    lineHeight: '18px',
    // whiteSpace: 'nowrap',
    // overflow: 'hidden',
    // textOverflow: 'ellipsis',
  },
  price: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'stretch',
    color: theme.palette.primary.main,
    marginTop: '12px',
    fontSize: '14px',
    fontWeight: '750',
    lineHeight: '17px',
    '& svg': {
      marginRight: '8px',
      cursor: 'pointer',
    },
    '& span': {
      fontWeight: 750,
      color: lighten(theme.palette.color.red, 0.15),
    },
    '&.hidden': {
      visibility: 'hidden',
    },
  },
  productContainer: {
    position: 'relative',
    height: '100px',
  },
  productContainerMultiple: {
    position: 'relative',
    height: '100px',
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
  },
  selected: {
    border: `3px solid ${theme.palette.primary.main}`,
  },
  chosen: {
    cursor: 'unset',
    border: `3px solid ${theme.palette.primary.main}`,
  },
  selectedRight: {
    borderTop: `3px solid ${theme.palette.primary.main}`,
    borderRight: `3px solid ${theme.palette.primary.main}`,
    borderBottom: `3px solid ${theme.palette.primary.main}`,
  },
  chosenRight: {
    cursor: 'unset',
    borderTop: `3px solid ${theme.palette.primary.main}`,
    borderRight: `3px solid ${theme.palette.primary.main}`,
    borderBottom: `3px solid ${theme.palette.primary.main}`,
  },
  selectedLeft: {
    borderLeft: `3px solid ${theme.palette.primary.main}`,
    borderTop: `3px solid ${theme.palette.primary.main}`,
    borderBottom: `3px solid ${theme.palette.primary.main}`,
  },
  chosenLeft: {
    cursor: 'unset',
    borderLeft: `3px solid ${theme.palette.primary.main}`,
    borderTop: `3px solid ${theme.palette.primary.main}`,
    borderBottom: `3px solid ${theme.palette.primary.main}`,
  },
  iconIndicator: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: '30px',
    height: '30px',
    // borderRadius: '100%',
    backgroundColor: `${theme.palette.primary.main}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

  },
  noSelectionIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '5px',
    color: theme.palette.primary.main,
  },
}));

const DetailCard = (props) => {
  const {
    title,
    coverImage,
    description,
    materiotecaCode,
    materiotecaName,
    price,
    selectProduct,
    selectedProduct,
    chosenProduct,
    noSelection,
    infoPopup,
    isRectangular,
    stepMode,
    id,
    multipleImagesDescriptions,
    multipleDescriptions,
    noDualImages,
  } = props;
  const classes = useStyles();

  const store = useStore();

  const isNoChoice = title.toLowerCase() === 'Nessuno'.toLowerCase();

  return (
    <Grid
      container
      direction='column'
      className={clsx(
        classes.root,
        (selectedProduct.id === id || chosenProduct) && classes.selected,
        stepMode && 'step-mode',
        // (selectedProduct.id === id || chosenProduct) && classes.selectionArrow,
      )}
      onClick={
        !chosenProduct
          ? () => {
              selectProduct(props);
            }
          : null
      }
    >
      {(selectedProduct.id === id || chosenProduct) &&
          <div style={{position:'relative', height: '100%', width: '100%'}}>
            <div className={clsx(classes.iconIndicator)} >
            <Check
              color='white'
              height='13px'
              width='15px'
            />
            </div>
          </div>
        }
      <Grid
        item
        xs={5}
        className={clsx(classes.headerProduct, (selectedProduct.id === id || chosenProduct) && 'choosed')}
      >
        {multipleImagesDescriptions && !noDualImages && (
        <div className={classes.productContainerMultiple}>
          <img
            src={multipleImagesDescriptions[0]}
            alt=''
            className={clsx(
              classes.imageMultiple,
              classes.imageMultipleLeft,
              isRectangular && 'rectangular',
              (selectedProduct.id === id || chosenProduct)
                ? classes.chosenLeft
                : (selectedProduct.id === id || chosenProduct) && classes.selectedLeft,
            )}
          />
          <div className={classes.textInImageLeft}>.1</div>
          <img
            src={multipleImagesDescriptions[1]}
            alt=''
            className={clsx(
              classes.imageMultiple,
              classes.imageMultipleRight,
              isRectangular && 'rectangular',
              (selectedProduct.id === id || chosenProduct)
                ? classes.chosenRight
                : (selectedProduct.id === id || chosenProduct) && classes.selectedRight,
            )}
          />
          <div className={classes.textInImageRight}>.2</div>
          {noSelection && (
            <CloseRound
              color='currentColor'
              width='26px'
              height='26px'
              className={classes.noSelectionIcon}
            />
          )}
        </div>
        )}
        {(!multipleImagesDescriptions || noDualImages) && (
        <div className={classes.productContainer}>
          <img
            src={coverImage}
            alt=''
            className={clsx(
              classes.image,
              isRectangular && 'rectangular',
            )}
          />
          {isNoChoice && (
            <NoChoice
              color='#656361'
              width='39px'
              height='39px'
              className={classes.noSelectionIcon}
            />
          )}
          {noSelection && (
            <CloseRound
              color='currentColor'
              width='26px'
              height='26px'
              className={classes.noSelectionIcon}
            />
          )}
        </div>
        )}
      </Grid>
      {multipleDescriptions && !noDualImages && (
      <Grid
        item
        xs={7}
        className={clsx(classes.header, (selectedProduct.id === id || chosenProduct) && 'choosed')}
        onClick={
          !chosenProduct
            ? () => {
                selectProduct(props);
              }
            : null
        }
      >
        <div className={classes.title}>
          {'.1 ' + multipleDescriptions?.[0]?.split(' ')?.[0]}
        </div>
        {materiotecaCode && <span className={classes.description}>{materiotecaCode}</span>}
        {materiotecaName && <span className={classes.description}>{materiotecaName}</span>}
        {(description && !materiotecaCode && !materiotecaName) && <span className={classes.description}>{materiotecaCode}</span>}
        <div className={classes.title}>
          {'.2 ' + multipleDescriptions?.[1]?.split(' ')?.[0]}
        </div>
        {materiotecaCode && <span className={classes.description}>{materiotecaCode}</span>}
        {materiotecaName && <span className={classes.description}>{materiotecaName}</span>}
        {(description && !materiotecaCode && !materiotecaName) && <span className={classes.description}>{materiotecaCode}</span>}
        <div className={clsx(classes.price, !price && 'hidden')}>
          {infoPopup && (
            <Alert
              color='currentColor'
              width='15px'
              height='15px'
              onClick={() => {
                store.setInfoPopup(infoPopup);
              }}
            />
          )}
          <span>+ {CurrencyFormatter(price)}</span>
        </div>
      </Grid>
      )}
      {(!multipleDescriptions || noDualImages) && (
      <Grid
        item
        xs={7}
        className={clsx(classes.header, (selectedProduct.id === id || chosenProduct) && 'choosed')}
        onClick={
          !chosenProduct
            ? () => {
                selectProduct(props);
              }
            : null
        }
      >
        <div className={classes.title}>
          {title}
        </div>
        {materiotecaCode && <span className={classes.description}>{materiotecaCode}</span>}
        {materiotecaName && <span className={classes.description}>{materiotecaName}</span>}
        {(description && !materiotecaCode && !materiotecaName) && <span className={classes.description}>{materiotecaCode}</span>}
        <div className={clsx(classes.price, !price && 'hidden')}>
          {infoPopup && (
            <Alert
              color='currentColor'
              width='15px'
              height='15px'
              onClick={() => {
                store.setInfoPopup(infoPopup);
              }}
            />
          )}
          <span>+ {CurrencyFormatter(price)}</span>
        </div>
      </Grid>
      )}
    </Grid>
  )
};

export default DetailCard;
