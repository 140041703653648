import GridDetailsContainer from '../../../components/Card/GridDetailsContainer';
// import MaterialDataSheet from '../../../components/MaterialDataSheet';
// import ConfiguratorThreeColumnsLayout from '../../../layouts/ConfiguratorThreeColumnsLayout';
import { useEffect, useState, useRef } from 'react';
import { resumeAnswerSimple } from '../../../utils/configuratorUtils';
import { getPackages } from '../../../services/packageApi';
import { useStore } from '../../../store/storeUtils';
import { useParams } from 'react-router';
import { handleSave, singleChoiceSelection } from '../../../utils/selectionUtils';
import _ from 'lodash';
import LoadingAnimation from '../../../components/LoadingAnimation';
import { makeStyles } from '@material-ui/core';
import {
  //  materialDataSheetOptionsConfiguration,
  //  secondMaterialDataSheetOptionsConfiguration,
  packagesRestructuringDoubleConfiguration,
} from '../../../configs/bagnoPhasesConfigurations';
import ConfiguratorTwoColumnsLayout from '../../../layouts/ConfiguratorTwoColumnsLayout';
import BigBox from '../giornoNotte/config/BigBox';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '15px',
    textAlign: 'left',
    height: '5%',
  },
  subTitle: {
    fontSize: '11px',
    textAlign: 'left',
    height: '8%',
  },
  secondaryText: {
    color: theme.palette.color.orange,
  },
}));

const FirstColumn = ({
  options,
  selectedOptions,
  selectedFormatoCamereImage,
  selectedFormatoCamereTitle,
  selectedFormatoCucinaImage,
  selectedFormatoCucinaTitle,
}) => {
  const optionSelected = options?.find((option) => option?.id === selectedOptions?.[0]);
  const selectedVirtualImage = optionSelected?.virtualImage?.replace(/\s/g, '%20');
  return (
    <BigBox
      defaultImage={`${process.env.PUBLIC_URL}/assets/images/Bagno.jpg`}
      selectedVirtualImage={selectedVirtualImage}
      selectedFormatoCamereImage={selectedFormatoCamereImage}
      selectedFormatoCucinaImage={selectedFormatoCucinaImage}
      selectedFormatoCamereTitle={selectedFormatoCamereTitle}
      selectedFormatoCucinaTitle={selectedFormatoCucinaTitle}
    />
  );
};

/*
const SecondColumn = ({ options, selectedOptions }) => {
  const firstMaterialObject = materialDataSheetOptionsConfiguration(options, selectedOptions);
  const secondMaterialObject = secondMaterialDataSheetOptionsConfiguration(
    options,
    selectedOptions,
  );

  let isDoubleProduct = false;
  if (secondMaterialObject?.name) isDoubleProduct = true;
  if (isDoubleProduct) {
    return (
      <MaterialDataSheet
        firstRowTitle={'PREVIEW'}
        secondRowTitle={"TONALITA'"}
        material={firstMaterialObject}
        secondMaterial={secondMaterialObject}
        isDoubleProduct={isDoubleProduct}
        noImageLayout={true}
      />
    );
  } else {
    return (
      <MaterialDataSheet
        firstRowTitle={'PREVIEW'}
        secondRowTitle={"TONALITA'"}
        previewBoxText={"una TONALITA'"}
        material={firstMaterialObject}
        noImageLayout={true}
      />
    );
  }
};
*/

const ThirdColumn = ({ options, selectedOptions, handleUserSelection }) => {
  const classes = useStyles();
  const containers = [
    {
      title: 'Tonalitá',
      content: options,
    },
  ];

  return (
    <>
      <div className={classes.title}>
        <span>
          Quale <span className={classes.secondaryText}>finitura</span> preferisci per il
          <span className={classes.secondaryText}> Rivestimento </span> del bagno?
        </span>
      </div>
      <div className={classes.subTitle}>
        <span>
          Questo step mostra le finiture in abbinamento al pavimento scelto nello step precedente.
        </span>
      </div>
      <div style={{ height: '87%' }}>
        <GridDetailsContainer
          containers={containers}
          onClick={handleUserSelection}
          selectedOptions={selectedOptions}
          numCols={1}
        />
      </div>
    </>
  );
};

const TonalitaBagno = () => {
  const [packages, setPackages] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [selectedFormatoCamereImage, setSelectedFormatoCamereImage] = useState(null);
  const [selectedFormatoCamereTitle, setSelectedFormatoCamereTitle] = useState(null);
  const [selectedFormatoCucinaImage, setSelectedFormatoCucinaImage] = useState(null);
  const [selectedFormatoCucinaTitle, setSelectedFormatoCucinaTitle] = useState(null);

  const substepIdRef = useRef();
  const oldAnswersRef = useRef();

  const store = useStore();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const configurationId = params.configuration;
  const currentRoomId = store.currentRoomId;

  const settingFiniture = () => {
    const storedFinitureCamereTitle = store.selectedFormatoCameraTitle;
    const storedFinituraCamereImage = store.selectedFormatoCameraImage;
    const storedFinituraCucinaTitle = store.selectedFormatoCucinaTitle;
    const storedFinituraCucinaImage = store.selectedFormatoCucinaImage;
    if (storedFinitureCamereTitle !== '') {
      setSelectedFormatoCamereTitle(storedFinitureCamereTitle);
      setSelectedFormatoCamereImage(storedFinituraCamereImage);
    }
    if (storedFinituraCucinaTitle !== '') {
      setSelectedFormatoCucinaTitle(storedFinituraCucinaTitle);
      setSelectedFormatoCucinaImage(storedFinituraCucinaImage);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    store.setUserCanGoNext(false);
    prepareOptions();
    settingFiniture();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoomId]);

  const prepareOptions = async () => {
    const substepId = store.currentSubstepId;
    const packages = await getPackages(configurationId, substepId, currentRoomId);
    substepIdRef.current = store.currentSubstepId;
    /*
    const { answers } = store;

    setSelectedMoods(answers.find((el) => el.substep.value === 'MOOD')?.package?.value);
    const selectedFinitura = answers.find((el) => el.substep.value === 'FINITURA')?.package?.value;
    const stepFinituraId = answers.find((el) => el.substep.value === 'FINITURA')?.substep?.id;
    const finiturePackages = await getPackages(configurationId, stepFinituraId);
    setSelectedFinituraImage(
      finiturePackages?.data[0]?.packages
        ?.find((onePackage) => onePackage?.code === selectedFinitura)
        ?.images?.find((image) => image?.imageType?.value === 'Cover')?.label,
    );
    setselectedFinituraProductDescription(
      finiturePackages?.data[0]?.packages?.find(
        (onePackage) => onePackage?.code === selectedFinitura,
      )?.products[0]?.description,
    );
    */
    const packagesRestruct = packagesRestructuringDoubleConfiguration(packages, 'TanalitaBagno');
    setPackages(packagesRestruct);
    resumeAnswerSimple(
      store,
      oldAnswersRef,
      substepIdRef,
      setSelectedPackages,
      setIsLoading,
      currentRoomId,
    );
  };

  useEffect(() => {
    const save = async () => {
      await handleSave(
        null,
        configurationId,
        substepIdRef.current,
        store,
        selectedPackages?.[0],
        currentRoomId,
      );
    };
    if (!_.isEqual(oldAnswersRef.current, selectedPackages)) {
      store.setSubstepSave(save);
    } else {
      store.setSubstepSave(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPackages]);

  const handleUserSelection = (selection) => {
    singleChoiceSelection([selection], [selectedPackages], setSelectedPackages, store);
  };

  return isLoading ? (
    <LoadingAnimation />
  ) : (
    <ConfiguratorTwoColumnsLayout
      firstRowTitle='CONFIGURA'
      secondRowTitle='IL RIVESTIMENTO DEL BAGNO'
      firstColumn={
        <FirstColumn
          options={packages}
          selectedOptions={selectedPackages}
          selectedFormatoCamereImage={selectedFormatoCamereImage}
          selectedFormatoCamereTitle={selectedFormatoCamereTitle}
          selectedFormatoCucinaImage={selectedFormatoCucinaImage}
          selectedFormatoCucinaTitle={selectedFormatoCucinaTitle}
        />
      }
      secondColumn={
        <ThirdColumn
          options={packages}
          selectedOptions={selectedPackages}
          handleUserSelection={handleUserSelection}
        />
      }
    />
  );
};

export default TonalitaBagno;
