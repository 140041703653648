import * as React from "react";

function SvgUsb(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 27 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.47 12.43h-6.29v6.29h2.14v4.87a1.85 1.85 0 01-.5 1.31l-7.47 7.47V6.56h2.57l-1.8-3.13L13.31.31l-1.8 3.12-1.8 3.13h2.6v32.65l-6.89-6.9A1.849 1.849 0 014.88 31v-5.47a3.06 3.06 0 10-2 0v5.48a3.81 3.81 0 001.13 2.72l8.3 8.31v4.72a5.64 5.64 0 102 0V35.17h.08l8.8-8.86a3.81 3.81 0 001.13-2.72v-4.87h2.15v-6.29z"
        fill={props.color}
      />
    </svg>
  );
}

export default SvgUsb;
