import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PlateContainer from '../Card/PlateContainer';

const useStyles = makeStyles((theme) => ({
  subtitledImageRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '95px',
    height: '100%',
    '& img': {
      maxHeight: '100px',
      height: '100%',
      '&.italic': {
        marginBottom: '18px',
      },
      '&.capitalize': {
        marginTop: '16px',
      },
    },
    '& svg': {
      maxHeight: '120px',
      width: 'auto',
      height: '80%',
      maxWidth: '250px',
    },
    '& .dimension': {
      color: '#E52421',
      fontSize: '11px',
      marginTop: '-15px',
      marginBottom: '14px',
    },
  },
  image: {
    width: 'auto',
    height: '75px',
    maxWidth: '200px',
    maxHeight: '75px',
  },
  italic: {
    fontFamily: 'miller',
    fontStyle: 'italic',
    fontSize: '15px',
    lineHeight: '18px',
    textAlign: 'center',
    padding: '0 35px',
  },
  capitalize: {
    textTransform: 'capitalize',
    fontSize: '11px',
    lineHeight: '13px',
  },
  normal: {
    fontSize: '13px',
    lineHeight: '16px',
    textAlign: 'center',
  },
  positions: {
    marginTop: '12px',
  },
}));

const SubtitledImage = ({
  image,
  icon,
  subtitle,
  look = 'normal',
  positions,
  dimension,
  unit,
  plateText,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.subtitledImageRoot}>
      {image && <img src={image} alt={image} className={clsx(classes.image, look)} />}
      {icon && icon}
      {dimension && <div className={'dimension'}>{`${dimension} ${unit}`}</div>}
      {subtitle && (
        <span className={classes[look]}>{`${subtitle}${dimension ? ' ' + dimension : ''} ${
          unit ? ' ' + unit : ''
        }`}</span>
      )}
      {positions && (
        <div className={classes.positions}>
          <PlateContainer elements={positions} plateText={plateText} />
        </div>
      )}
    </div>
  );
};

export default SubtitledImage;
