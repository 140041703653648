import { Grid, makeStyles, TextField } from '@material-ui/core';
import '../styles/loadingAnimation.css';
import Button3D from '../components/Buttons/Button3D';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    '& .container': {
      overflow: 'hidden',
    },
  },
}));

const LoginPage = ({ handleLogin, loginError }) => {
  const classes = useStyles();

  const handleSubmit = (e) => {
    const email = e.target.elements['email'].value;
    const password = e.target.elements['password'].value;
    handleLogin(email, password);
    e.preventDefault();
  };

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <Grid container justifyContent='center' alignItems='center' className='container'>
        <Grid container direction='column' alignItems='center' spacing={3}>
          <Grid item>
            <ExitToAppIcon fontSize='large' />
          </Grid>
          <Grid item>
            <TextField
              required
              id='email'
              name='email'
              label='Email'
              variant='outlined'
              size='small'
              error={loginError}
            />
          </Grid>
          <Grid item>
            <TextField
              required
              id='password'
              name='password'
              type='password'
              label='Password'
              variant='outlined'
              size='small'
              error={loginError}
            />
          </Grid>
          <Grid item>
            <Button3D type='submit' text={'Login'} />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default LoginPage;
