import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { getHcUserData, setHcUserData } from '../utils/localStorageUtils';

const API = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const APIrefresh = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

API.interceptors.request.use(
  (request) => {
    const jwt = getHcUserData()?.jwtToken;
    request.headers.authorization = `Bearer ${jwt}`;
    return request;
  },
  (error) => {
    console.log('error refreshing token...');
    return Promise.reject(error);
  },
);

const refreshTokenUrl = '/User/RefreshToken';

const refreshAuthLogic = async (failedRequest) => {
  return API.post(refreshTokenUrl, {
    refreshToken: getHcUserData().refreshToken,
  })
    .then((res) => {
      console.log('refreshAuthLogic', res.status);
      if (res.status === 200) {
        res.data.email = getHcUserData().email
        res.data.email = getHcUserData().boxJwtToken
        setHcUserData(res.data);
        failedRequest.response.config.headers.authorization = 'Bearer ' + res.data.jwtToken;
        return Promise.resolve();
      }
    })
    .catch((e) => {
      console.log('error refreshAuthLogic');
      document.location.href = '/';
      return Promise.reject(e);
    });
};

createAuthRefreshInterceptor(API, refreshAuthLogic);

export default API;
