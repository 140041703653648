import { Page, StyleSheet, Text, View, Image } from '@react-pdf/renderer';
import { commonStyles } from '../pdfUtiles';

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  logoContainer: {
    height: '5%',
    width: '100%',
  },
  logo: {
    height: '100%',
    width: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '12px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  configurationName: {
    fontFamily: 'Miller Display',
    fontSize: '24px',
  },
  bold: { 
    fontWeight: 700 
  },
  bodyContainer: {
    height: '85%', 
    width: '100%', 
    marginTop: '5%'
  },
  projectImage: {
    height: '100%', 
    width: 'auto', 
    marginLeft: 'auto', 
    marginRight: 'auto'
  },
  footer: {
    height: '5%', 
    width: '100%', 
    display: 'flex', 
    flexDirection:'row', 
    justifyContent: 'space-between', 
    alignItems: 'center'
  },
  flexRow: {
    display: 'flex', 
    flexDirection:'row'
  },
  flexColumn: {
    display: 'flex', 
    flexDirection:'column'
  },
  borderLeftDivider: {
    borderLeft: '1px solid #000', 
    paddingLeft: '8px', 
    marginLeft: '8px'
  }
});

const SignPage = ({
  apartmentType,
  building,
  floor,
  quadrant,
}) => {
  return (
    <Page size='A4' style={[commonStyles.page, styles.page]}>
      <View style={styles.logoContainer}>
        <Image style={styles.logo} src={`${process.env.PUBLIC_URL}/assets/images/logo.png`} />
      </View>
      <View style={styles.bodyContainer}>
        <Text>Firma* per presa visione e accettazione di tutte le pagine che compongono il presente documento</Text>
        <Text style={{color: 'white', fontSize: '38px', borderBottom: '1px solid #000', width: '20%', marginBottom:'30px', marginTop: '30px'}}>[[s|1]]</Text>
        <Text>*Con la firma di questo documento la personalizzazione del suo appartamento è conclusa e non sarà possibile apportare future modifiche.</Text>
      </View>
      <View style={styles.footer}>
        <View style={styles.flexColumn}>
          <Text style={styles.bold}>{apartmentType}</Text>
        </View>
        <View style={styles.flexColumn}>
          <View style={styles.flexRow}>
            <Text>Progetto: </Text>
            <Text style={styles.bold}>{building}</Text>
          </View>
          <View style={styles.flexRow}>
            <Text>Piano: </Text>
            <Text style={styles.bold}>{floor}</Text>
            <Text style={styles.borderLeftDivider}>Quadrante: </Text>
            <Text style={styles.bold}>{quadrant}</Text>
          </View>
        </View>
      </View>
    </Page>
  )
};

export default SignPage;
