import * as React from 'react';

function SvgUsbIngresso(props) {
  return (
    <svg viewBox='0 0 37 47' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1 0h28a1 1 0 011.01 1v11h-2V2H11.75l7.56 1.36a.91.91 0 01.76.9v35.27a.92.92 0 01-.76.9L1.52 43.61a.92.92 0 01-.52.17 1 1 0 01-1-1V1a1 1 0 011-1zm.99 41.68l16.24-2.92V5.02L1.99 2.11v39.57zm14.08-18.709a1.53 1.53 0 01-1.081.448 1.53 1.53 0 01-1.075-2.605 1.52 1.52 0 011.075-.445 1.52 1.52 0 011.082 2.602zm17.976 1.127h2.942v3.164h-1.006v2.45c.001.255-.045.507-.136.742a1.93 1.93 0 01-.392.627l-4.117 4.458h-.038v5.83c.65.127 1.23.509 1.63 1.072.4.564.59 1.268.53 1.974a2.922 2.922 0 01-.847 1.841 2.539 2.539 0 01-1.78.744c-.66 0-1.295-.265-1.781-.744a2.922 2.922 0 01-.848-1.841c-.058-.706.13-1.41.53-1.974.4-.563.982-.946 1.63-1.071v-2.375l-3.882-4.18a1.931 1.931 0 01-.393-.628 2.043 2.043 0 01-.136-.741v-2.757a1.46 1.46 0 01-.763-.67 1.638 1.638 0 01-.18-1.04 1.57 1.57 0 01.486-.92c.259-.236.586-.365.925-.365.338 0 .666.13.924.364.259.235.432.561.488.92.056.36-.008.728-.181 1.041-.173.314-.443.55-.763.67v2.752c0 .122.022.243.065.356a.942.942 0 00.187.303l3.224 3.471V21.145h-1.217l.842-1.575.843-1.57.846 1.57.842 1.575h-1.202V34.13l3.495-3.758a.972.972 0 00.234-.66v-2.45h-1.001v-3.164z'
        fill='#C4C4C4'
      />
    </svg>
  );
}

export default SvgUsbIngresso;
