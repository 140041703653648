import * as React from 'react';

function SvgPortaTelaioVisibile(props) {
  return (
    <svg viewBox='0 0 46 77' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#porta-telaio-visibile_svg__clip0_2089_22627)'>
        <path
          d='M43.18 2.18h-41v72.38h41V2.18zM5.29 39.69a1.74 1.74 0 01-1.74-1.73 1.74 1.74 0 011.74-1.74 1.74 1.74 0 011.73 1.74 1.74 1.74 0 01-1.73 1.73z'
          fill='#EFEEEE'
        />
        <path d='M0 0v76.74h45.36V0H0zm43.18 74.56h-41V2.18h41v72.38z' fill='#262626' />
        <path
          d='M5.29 36.22a1.739 1.739 0 00-1.74 1.74 1.74 1.74 0 001.74 1.73 1.74 1.74 0 001.73-1.73 1.74 1.74 0 00-1.73-1.74zm0 3a1.23 1.23 0 01-1.24-1.23 1.23 1.23 0 011.24-1.24 1.229 1.229 0 011.23 1.24 1.23 1.23 0 01-1.23 1.2v.03z'
          fill='#262626'
        />
      </g>
      <defs>
        <clipPath id='porta-telaio-visibile_svg__clip0_2089_22627'>
          <path fill='#fff' d='M0 0h45.35v76.73H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgPortaTelaioVisibile;
