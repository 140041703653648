import * as React from 'react';

function SvgLoggia(props) {
  return (
    <svg viewBox='0 0 46 39' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#loggia_svg__clip0_2448_22986)' fill='#C4C4C4'>
        <path d='M23 .05a1 1 0 00-.63 0C21.45.35 0 7.64 0 17.19a1 1 0 001 1h20.55v7.24h-6.84a1 1 0 000 2h6.87v10a1 1 0 002 0v-10h6.87a1 1 0 000-2h-6.87v-7.24H44.4a1 1 0 001-1C45.37 7.64 23.91.35 23 .05zM2.11 16.19C3.45 9.93 16.94 4.03 22.68 2.05c5.75 2 19.23 7.88 20.58 14.14H2.11z' />
        <path d='M4.28 38.38a1.001 1.001 0 001.18-.78l1.18-5.73h5.46l1.48 5.78a1 1 0 001 .75c.083.01.167.01.25 0a1 1 0 00.72-1.22l-1.68-6.52a1 1 0 00-1-.76H6.62l-1.64-7.16a1 1 0 00-1.824-.315 1 1 0 00-.127.755l1.77 7.74-1.3 6.31a1 1 0 00.78 1.15zM41.859 21.95a1 1 0 00-1.2.76l-1.65 7.16h-6.25a.999.999 0 00-1 .76l-1.68 6.52a1.001 1.001 0 00.72 1.22h.25a1 1 0 001-.75l1.48-5.78h5.46l1.18 5.73a1 1 0 001.96-.4l-1.3-6.31 1.78-7.74a1 1 0 00-.75-1.17z' />
      </g>
      <defs>
        <clipPath id='loggia_svg__clip0_2448_22986'>
          <path fill='#fff' d='M0 0h45.37v38.41H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgLoggia;
