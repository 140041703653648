import { Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import CurrencyFormatter from '../utils/CurrencyFormatter';
import Button3D from './Buttons/Button3D';
import SummarySheet from './SummarySheet';
import Box from '@material-ui/core/Box';
import ConfirmationInfoCard from './Card/ConfirmationInfoCard';

const useStyles = makeStyles((theme) => ({
  summaryContainerRoot: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.color.white,
  },
  firstColumn: {
    order: 1,
    overflow: 'hidden auto',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      order: 2,
      height: '55%',
    },
  },
  secondColumn: {
    order: 2,
    [theme.breakpoints.down('md')]: {
      order: 1,
      height: '40%',
      marginBottom: '35px',
    },
  },
  cta: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingLeft: '5px',
    '& .text': {
      fontSize: '15px',
      lineHeight: '18px',
      textTransform: 'uppercase',
      [theme.breakpoints.down('lg')]: {
        fontSize: '13px',
        lineHeight: '16px',
      },
    },
    '& .total': {
      marginTop: '14px',
      fontWeight: '750',
      fontSize: '30px',
      lineHeight: '36px',
      textTransform: 'uppercase',
      textAlign: 'right',
      color: theme.palette.primary.main,
      width: '100%',
      [theme.breakpoints.down('md')]: {
        marginTop: '6px',
      },
    },
    '& .buttons': {
      display: 'flex',
      flexDirection: 'column',
      margin: '24px auto 0',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        margin: '20px auto 0',
      },
      '& > *': {
        marginBottom: '26px',
        [theme.breakpoints.down('lg')]: {
          marginBottom: '18px',
        },
        [theme.breakpoints.down('md')]: {
          marginBottom: '15px',
        },
      },
    },
  },
}));

const SummaryContainer = ({
  onClickResume,
  onClickSend,
  answers,
  isAllPhasesCompleted,
  editable,
  notAllowedFinish,
  isConfirmed,
  resumePdfLoading,
  downloadConfirmed,
  signing,
  getSigningRequest,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const smallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const getFinalPrice = () => {
    const realSheets = answers.filter((el) => el.rows);

    return realSheets
      .map((sheet) => {
        return sheet.rows
          ?.map((row) => row.price)
          .flat()
          .map(Number)
          .reduce((prev, curr) => prev + curr, 0);
      })
      .reduce((prev, curr) => prev + curr, 0);
  };

  return (
    <div className={classes.summaryContainerRoot}>
      <Grid container spacing={1} style={{ height: '100%' }}>
        <Grid item lg={8} xs={12} className={classes.firstColumn}>
          <Box width={'100%'} id='configuration-resume'>
            {answers?.map((sheet, i) => (
              <SummarySheet key={i} sheet={sheet} />
            ))}
          </Box>
          {/*isAllPhasesCompleted && (
            <ConfirmationInfoCard handleConfirmation={onClickSend} hideAction={!editable} />
          )*/}
        </Grid>
        <Grid item lg={4} xs={12} className={classes.secondColumn}>
          <div className={classes.cta}>
            <span className='text'>
              Il <strong>valore totale</strong> delle scelte <br /> da te effettuate è di
            </span>
            <span className='total'>
              {CurrencyFormatter(answers?.length > 0 && getFinalPrice())}
            </span>
              <div className='buttons'>
                {editable && (
                <Button3D
                  text={
                    smallScreen
                      ? isAllPhasesCompleted && !isConfirmed
                        ? 'Modifica'
                        : 'Continua'
                      : isAllPhasesCompleted && !isConfirmed
                      ? 'Modifica le tue scelte'
                      : 'Continua le tue scelte'
                  }
                  reverseTextStyle
                  color='orange'
                  onClick={onClickResume}
                  disabled={resumePdfLoading}
                />
                )}
                <Button3D
                  text={smallScreen ? isConfirmed ? signing ? 'Firma' : 'Download' : 'Conferma' : isConfirmed ?  signing ? 'Firma la configurazione' : 'Scarica la configurazione' : 'Conferma la configurazione'}
                  reverseTextStyle
                  color='green'
                  onClick={isConfirmed ? signing ? getSigningRequest : downloadConfirmed : onClickSend}
                  disabled={!isAllPhasesCompleted || notAllowedFinish || resumePdfLoading}
                  disabledText='Completa tutte le scelte per poter continuare.'
                />
              </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SummaryContainer;
