import GridDetailsContainer from '../../../components/Card/GridDetailsContainer';
// import MaterialDataSheet from '../../../components/MaterialDataSheet';
import BigBox from './config/BigBox';
import { useEffect, useState, useRef } from 'react';
import { resumeAnswerSimple } from '../../../utils/configuratorUtils';
import { getPackages } from '../../../services/packageApi';
import { useStore } from '../../../store/storeUtils';
import { useParams } from 'react-router';
import { handleSave, singleChoiceSelection } from '../../../utils/selectionUtils';
import _ from 'lodash';
import LoadingAnimation from '../../../components/LoadingAnimation';
import {
  //  materialDataSheetOptionsConfiguration,
  packagesRestructuringClassicConfiguration,
} from '../../../configs/giornoNottePhasesConfigurations';
import ConfiguratorTwoColumnsLayout from '../../../layouts/ConfiguratorTwoColumnsLayout';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '15px',
    textAlign: 'left',
    height: '5%',
  },
  subTitle: {
    fontSize: '11px',
    textAlign: 'left',
    height: '5%',
  },
  titleCucina: {
    fontSize: '15px',
    textAlign: 'left',
    height: '8%',
  },
  subTitleCucina: {
    fontSize: '11px',
    textAlign: 'left',
    height: '7%',
  },
  secondaryText: {
    color: theme.palette.color.orange,
  },
}));

const FirstColumn = ({
  options,
  selectedOptions,
  isCucina,
  selectedFormatoCamereImage,
  selectedFormatoCamereTitle,
  selectedFormatoCucinaImage,
  selectedFormatoCucinaTitle,
}) => {
  const optionSelected = options?.find((option) => option?.id === selectedOptions?.[0]);
  const selectedVirtualImage = optionSelected?.virtualImage?.replace(/\s/g, '%20');
  return (
    <BigBox
      defaultImage={
        isCucina
          ? `${process.env.PUBLIC_URL}/assets/images/Cucina.jpg`
          : `${process.env.PUBLIC_URL}/assets/images/Soggiorno.jpg`
      }
      selectedVirtualImage={selectedVirtualImage}
      selectedFormatoCamereImage={selectedFormatoCamereImage}
      selectedFormatoCucinaImage={selectedFormatoCucinaImage}
      selectedFormatoCamereTitle={selectedFormatoCamereTitle}
      selectedFormatoCucinaTitle={selectedFormatoCucinaTitle}
    />
  );
};

/*
const SecondColumn = ({ options, selectedOptions }) => {
  const materialDataSheetOptions = materialDataSheetOptionsConfiguration(options, selectedOptions);
  return (
    <MaterialDataSheet
      firstRowTitle={'PREVIEW'}
      secondRowTitle={"PRODOTTO"}
      previewBoxText={"una TONALITA'"}
      material={materialDataSheetOptions}
      noImageLayout={true}
    />
  );
};
*/

const SecondColumnText = ({ isCucina }) => {
  const classes = useStyles();
  const cucinaPrimartyText = (
    <span>
      Quale <span className={classes.secondaryText}>Tonalità</span> preferisci per il{' '}
      <span className={classes.secondaryText}>pavimento </span>della cucina?
    </span>
  );
  const livingPrimaryText = (
    <span>
      Quale <span className={classes.secondaryText}>Tonalità</span> preferisci per il{' '}
      <span className={classes.secondaryText}>pavimento</span> di{' '}
      <span className={classes.secondaryText}>living e camere</span>?
    </span>
  );
  /*
  const cucinaSecondaryText = (
    <span className={classes.secondaryText}>
      Le finiture di seguito proposte, sono selezionate per essere accostate al pavimento della tua
      zona living / notte.
    </span>
  );
  const livingSecondaryText = (
    <span className={classes.secondaryText}>
      <Chains color='#F08219' height='12px' width='12px' /> Le scelte con questa icona determinano
      anche le scelte della cucina.
    </span>
  );
  */
  return (
    <div className={isCucina ? classes.titleCucina : classes.title}>
      {isCucina ? cucinaPrimartyText : livingPrimaryText}
    </div>
  );
};

const ThirdColumn = ({ options, selectedOptions, handleUserSelection, isCucina }) => {
  const containers = [
    {
      title: 'Tonalitá',
      content: options,
    },
  ];

  return (
    <>
      <SecondColumnText
        isCucina={isCucina}
        style={isCucina ? { height: '15%' } : { height: '10%' }}
      />
      <div style={isCucina ? { height: '85%' } : { height: '90%' }}>
        <GridDetailsContainer
          containers={containers}
          onClick={handleUserSelection}
          selectedOptions={selectedOptions}
          numCols={1}
        />
      </div>
    </>
  );
};

const TonalitaGiornoNotte = () => {
  const [packages, setPackages] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [selectedFormatoCamereImage, setSelectedFormatoCamereImage] = useState(null);
  const [selectedFormatoCamereTitle, setSelectedFormatoCamereTitle] = useState(null);
  const [selectedFormatoCucinaImage, setSelectedFormatoCucinaImage] = useState(null);
  const [selectedFormatoCucinaTitle, setSelectedFormatoCucinaTitle] = useState(null);
  const [isCucina, setIsCucina] = useState(false);

  const substepIdRef = useRef();
  const oldAnswersRef = useRef();

  const store = useStore();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const configurationId = params.configuration;

  /*
  const settingFiniture = () => {
    const storedFinitureCamereTitle = store.selectedFormatoCameraTitle;
    const storedFinituraCamereImage = store.selectedFormatoCameraImage;
    const storedFinituraCucinaTitle = store.selectedFormatoCucinaTitle;
    const storedFinituraCucinaImage = store.selectedFormatoCucinaImage
    if(storedFinitureCamereTitle !== ''){
      setSelectedFormatoCamereTitle(storedFinitureCamereTitle);
      setSelectedFormatoCamereImage(storedFinituraCamereImage);
    }
    if(storedFinituraCucinaTitle !== ''){
      setSelectedFormatoCucinaTitle(storedFinituraCucinaTitle);
      setSelectedFormatoCucinaImage(storedFinituraCucinaImage);
      setIsCucina(true);
    }
  }
  */

  useEffect(() => {
    store.setUserCanGoNext(false);
    // settingFiniture();
    prepareOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prepareOptions = async () => {
    const substepId = store.currentSubstepId;
    const packages = await getPackages(configurationId, substepId);
    substepIdRef.current = store.currentSubstepId;
    /*
    const { answers } = store;

    setSelectedMoods(answers.find((el) => el.substep.value === 'MOOD')?.package?.value);
    const selectedFinitura = answers.find((el) => el.substep.value === 'FINITURA')?.package?.value;
    setIsCucina(answers.filter((el) => el.substep.value === 'FINITURA')?.length > 1);
    const stepFinituraId = answers.find((el) => el.substep.value === 'FINITURA')?.substep?.id;
    const finiturePackages = await getPackages(configurationId, stepFinituraId);
    // setIsCucina(finiturePackages?.data[0]?.packages?.length > 1);
    setSelectedFinituraImage(
      finiturePackages?.data[0]?.packages
        ?.find((onePackage) => onePackage?.code === selectedFinitura)
        ?.images?.find((image) => image?.imageType?.value === 'Cover')?.label,
    );
    */
    substepIdRef.current = store.currentSubstepId;
    const packagesRestruct = packagesRestructuringClassicConfiguration(
      packages,
      false,
      false,
      false,
      isCucina ? 'TonalitaCucina' : 'TonalitaCamere',
    );
    setPackages(packagesRestruct);
    resumeAnswerSimple(store, oldAnswersRef, substepIdRef, setSelectedPackages, setIsLoading);
  };

  useEffect(() => {
    const save = async () => {
      await handleSave(null, configurationId, substepIdRef.current, store, selectedPackages?.[0]);
    };
    if (!_.isEqual(oldAnswersRef.current, selectedPackages)) {
      store.setSubstepSave(save);
    } else {
      store.setSubstepSave(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPackages]);

  const handleUserSelection = (selection) => {
    singleChoiceSelection([selection], [selectedPackages], setSelectedPackages, store);
  };

  return isLoading ? (
    <LoadingAnimation />
  ) : (
    <ConfiguratorTwoColumnsLayout
      firstRowTitle='Configura'
      secondRowTitle={
        isCucina ? 'IL PAVIMENTO DELLA CUCINA' : 'I PAVIMENTI DI LIVING E CAMERE DA LETTO'
      }
      firstColumn={
        <FirstColumn
          options={packages}
          selectedOptions={selectedPackages}
          selectedFormatoCamereImage={selectedFormatoCamereImage}
          selectedFormatoCamereTitle={selectedFormatoCamereTitle}
          isCucina={isCucina}
          selectedFormatoCucinaImage={selectedFormatoCucinaImage}
          selectedFormatoCucinaTitle={selectedFormatoCucinaTitle}
        />
      }
      secondColumn={
        <ThirdColumn
          options={packages}
          selectedOptions={selectedPackages}
          handleUserSelection={handleUserSelection}
          isCucina={isCucina}
        />
      }
    />
  );
};

export default TonalitaGiornoNotte;
