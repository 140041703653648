import * as React from 'react';

function SvgOutlinedStar(props) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M7.644 14.998c4.048 0 7.266-3.1 7.266-7s-3.218-7-7.266-7-7.265 3.1-7.265 7 3.217 7 7.265 7z' fill={props.color} />
      <path
        d='M7.956 4.098l1.142 2.2.104.1 2.49.3c.104 0 .208.1.104.2v.1l-1.868 1.7s-.104.1 0 .1l.415 2.4c0 .1 0 .2-.104.2h-.103l-2.284-1.2h-.207l-2.18 1.1c-.104 0-.207 0-.207-.1v-.1l.415-2.4v-.1l-1.869-1.6c-.103-.1-.103-.2 0-.2h.104l2.491-.3c.104 0 .104 0 .104-.1l1.142-2.3c0-.1.104-.1.311 0-.104-.1 0 0 0 0z'
        fill='none'
      />
    </svg>
  );
}

export default SvgOutlinedStar;
