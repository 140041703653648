import './App.css';
import './styles/utilities.css';
import { observer } from 'mobx-react';
import { Box, makeStyles } from '@material-ui/core';
import Menu from './components/Menu';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import ConfigurationsPage from './pages/ConfigurationsPage';
import ApartmentsPage from './pages/ApartmentsPage';
import ConfiguratorPage from './pages/ConfiguratorPage';
import DevComponents from './pages/DevComponents';
import ConfTwoColumnsLayout from './layouts/ConfTwoColumnsLayout';
import ConfigurationResumePage from './pages/ConfigurationResumePage';
import SmallScreenMessage from './components/SmallScreenMessage';
import InfoPopup from './components/InfoPopup';
import { useEffect, useState, useMemo } from 'react';
import { authenticate, refreshToken, sfNonceAuthenticate } from './services/userApi';
import { useStore } from './store/storeUtils';
import LoadingAnimation from './components/LoadingAnimation';
import clsx from 'clsx';
import { isJwtExpired } from './utils/jwtUtils';
import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';
import LoginPage from './pages/LoginPage';
import { getHcUserData } from './utils/localStorageUtils';
import MaintenancePage from './pages/MaintenancePage';
import FaqPage from './pages/FaqPage';
import { getCheckMaintenance } from './services/settingApi';
import LogoutPage from './pages/LogoutPage';
import { getProjects } from './services/projectApi';
import { confirmConfiguration, getConfigurationList } from './services/configurationApi';

const height = '90px';
const heightMobile = '60px';

const useStyles = makeStyles((theme) => ({
  menu: {
    height: height,
    [theme.breakpoints.down('lg')]: {
      height: heightMobile,
    },
  },
  content: {
    height: `calc(100% - ${height})`,
    padding: '25px',
    backgroundColor: '#F9F3EE',
    [theme.breakpoints.down('lg')]: {
      height: `calc(100% - ${heightMobile})`,
    },
  },
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    background: '#FFF',
    opacity: 0,
    transition: 'all 300ms',
    zIndex: 100,
    width: '100%',
    height: 0,
    pointerEvents: 'none',
    '&.show': {
      opacity: 1,
      width: '100%',
      height: '100%',
    },
  },
}));

const useQuery = () => {
  const { search } = window.location;
  return useMemo(() => new URLSearchParams(search), [search]);
};

const App = observer(() => {
  const classes = useStyles();
  const store = useStore();
  const query = useQuery();
  // prendo il projectShortName dalla url
  const projectFromURL = window.location.pathname.split('/')?.[1]?.toUpperCase();

  const [isLoading, setIsLoading] = useState(true);
  const [isLogged, setIsLogged] = useState(!isJwtExpired());
  const [loginError, setLoginError] = useState(false);
  const [isMaintenance, setIsMaintenance] = useState(false);
  const [isLoadingProject, setIsLoadingProject] = useState(true);
  const [currentProject, setCurrentProject] = useState(null);

  const getProjectsList = async () => {
    try {
      setIsLoading(true);
      setIsLoadingProject(true)
      const response = await getProjects();
      if (response?.status === 200) {
        const projects = response?.data;
        const project = projectFromURL ? projects?.find((project) => project?.shortName === projectFromURL) : null;
        if (project) {
          setCurrentProject(project?.shortName);
          store.setCurrentProject(project);
        }
      }
      const configurationListData = await getConfigurationList();
      const configConfirmed = query.get('confirmed');
      const apartmentConfirmed = query.get('apartment');
      const isConfirmedControl = configurationListData.data.find((el) => el.confirmationDate && el.realApartmentId?.toString() === apartmentConfirmed?.toString() && el.id?.toString() === configConfirmed?.toString());
      if(configConfirmed && apartmentConfirmed && !isConfirmedControl && process.env.REACT_APP_SEND_EMAIL_ONSIGN === 'true'){
        await confirmConfiguration(configConfirmed);
      }
      setIsLoading(false);
      setIsLoadingProject(false)
    }
    catch (error) {
      setIsLoading(false);
      setIsLoadingProject(false)
      console.log(error);
    }
  }

  useEffect(() => {
    if (isLogged) {
      const userData = getHcUserData();
      store.setUserData(userData);
      getProjectsList(); // devo anche eliminare il set false sotto
      setIsLoading(false);
    } else {
      handlePreLogin();
    }
  }, []);

  const handlePreLogin = async () => {
    // Provo a refreshare il token
    const response = await refreshToken(store);

    // Se il refresh non è andato a buon fine,
    // proseguo con le altre logiche di login
    if (response?.status === 200) {
      setIsLoading(false);
      setIsLogged(true);
      await getProjectsList();
    } else {
      handleAutoLogin();
    }
  };

  const handleAutoLogin = async () => {
    try {
      // Verifico se sono presenti le info per il login
      const params = new URLSearchParams(document.location.search);
      const base64LoginInfo = params.get('sfdata');

      if (base64LoginInfo) {
        // Se sono disponibli le informazioni faccio il login automatico
        const parsedWordArray = Base64.parse(base64LoginInfo);

        const parsedString = parsedWordArray.toString(Utf8);

        const [email, nonce] = parsedString.split(';');

        const response = await sfNonceAuthenticate(email, nonce, store);

        if (response?.status === 200) {
          setIsLoading(false);
          setIsLogged(true);
          await getProjectsList();
        } else {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
        await getProjectsList();
      }
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      setIsLogged(false);
    }
  };

  const handleLogin = async (email, password) => {
    try {
      const response = await authenticate(email, password, store);
      if (response?.status === 200) {
        setIsLoading(false);
        setIsLogged(true);
        await getProjectsList();
      } else {
        setIsLoading(false);
        setLoginError(true);
      }
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      setIsLogged(false);
      setLoginError(true);
    }
  };

  const maintenanceCheck = async () => {
    try {
      const checkResponse = await getCheckMaintenance();
      if (checkResponse?.data?.isMaintenanceOn || checkResponse?.status !== 200) {
        setIsMaintenance(true);
      } else if (checkResponse?.data?.isMaintenanceOn === false) {
        setIsMaintenance(false);
      }
    } catch (error) {
      console.log(error);
      setIsMaintenance(true);
    }
  };

  useEffect(() => {
    maintenanceCheck();
    const checkMaintenanceIntervalId = setInterval(() => {
      maintenanceCheck();
    }, 1000 * 60);

    return () => {
      clearInterval(checkMaintenanceIntervalId);
    };
  }, []);

  return (
    <>
      {isMaintenance ? (
        <MaintenancePage />
      ) : (
        <div className='App'>
          <SmallScreenMessage />
          <div className={clsx(classes.loading, isLoading && 'show')}>
            <LoadingAnimation msg={'Caricamento dati utente'} />
          </div>
          {isLoading || isLoadingProject ? (
            ''
          ) : !isLogged ? (
            <LoginPage handleLogin={handleLogin} loginError={loginError} />
          ) : !currentProject ? (
            <MaintenancePage noProject={!currentProject}/>
          ) : (
            <>
              <Router basename={currentProject ? `/${currentProject}` : projectFromURL} >
                <div className={classes.menu}>
                  <Menu />
                </div>
                <div className={classes.content}>
                  <Switch>
                    <Redirect exact from='/' to='/apartments' />
                    <Route exact path='/dev'>
                      <ConfTwoColumnsLayout
                        firstColumn={<Box bgcolor='#FFF' height='100%' width='100%' />}
                        secondColumn={<DevComponents />}
                      />
                    </Route>
                    <Route exact path='/faq'>
                      <FaqPage />
                    </Route>
                    <Route exact path='/apartments'>
                      <ApartmentsPage />
                    </Route>
                    <Route exact path='/apartment/:apartment'>
                      <ConfigurationsPage />
                    </Route>
                    <Route exact path='/apartment/:apartment/:configuration/resume'>
                      <ConfigurationResumePage />
                    </Route>
                    <Route path='/apartment/:apartment/:configuration/configurator'>
                      <ConfiguratorPage />
                    </Route>
                    <Route path='/logout'>
                      <LogoutPage />
                    </Route>
                    <Route path='*'>
                      <Redirect exact from='/' to='/apartments' />
                    </Route>
                  </Switch>
                </div>
              </Router>
              <InfoPopup warning={false} />
            </>
          )}
        </div>
      )}
    </>
  );
});

export default App;
