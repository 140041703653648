import { Tabs } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const styles = {
  optionsTabsIndicator: {
    display: 'none',
  },
  optionsTabsRoot: {
    minHeight: 'unset',
    marginBottom: '-1px',
  },
};

const BigTabs = (props) => {
  const { children, classes, ...others } = props;
  return (
    <Tabs
      classes={{
        root: classes.optionsTabsRoot,
        indicator: classes.optionsTabsIndicator,
      }}
      {...others}
    >
      {children}
    </Tabs>
  );
};

export default withStyles(styles)(BigTabs);
