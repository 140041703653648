import * as React from "react";

function SvgDomotic(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 97 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M81.443 73.27a2.39 2.39 0 01-2.45 2.31h-60.68a2.39 2.39 0 01-2.45-2.31V35.99l31.5-24.72a2.09 2.09 0 012.57 0l31.51 24.72v37.28zm13.83-37.45l-15.48-12.24V8.38a1.68 1.68 0 00-1.72-1.62h-8a1.68 1.68 0 00-1.73 1.62v6.15L51.513 1.27a4.76 4.76 0 00-5.73 0L2.023 35.82a4 4 0 00-.58 5.87l.1.11a4.68 4.68 0 006.27.47l3.47-2.73v33.73a6.85 6.85 0 007 6.62h60.7a6.85 6.85 0 007-6.62V39.58l3.46 2.69a4.68 4.68 0 006.26-.51l.1-.11a4 4 0 00-.57-5.87l.04.04zm-62.66 15.96l2.53 2.38a19.91 19.91 0 0126.93 0l2.53-2.38a23.64 23.64 0 00-32 0h.01zm-5.79-5.51l2.53 2.38a28.51 28.51 0 0138.59 0l2.5-2.38a32.26 32.26 0 00-43.65 0h.03zm17.62 16.63l4.2 3.95 4.19-3.95a6.2 6.2 0 00-8.39 0zm-5.93-5.63l2.52 2.38a11.25 11.25 0 0115.23 0l2.52-2.38a15 15 0 00-20.27 0z"
        fill={props.color}
      />
    </svg>
  );
}

export default SvgDomotic;
