import { Grid, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import OptionCard, { StandardOptionCardBody } from '../../../components/Card/OptionCard';
import ConfiguratorIntro from '../../../components/configurator/ConfiguratorIntro';
import {
  CarichiSmart,
  ChiusuraWireless,
  Domotic,
  LuciSmart,
  SmartHomeNo,
  TapparelleSmart,
  VideoCitofonoConnesso,
  TermostatoSmart,
} from '../../../components/Icons';
import ConfiguratorSelectionLayout from '../../../components/Layout/ConfiguratorSelectionLayout';
import VideoViewer from '../../../components/VideoViewer';
import ConfiguratorTwoColumnsPlanimetryLayout from '../../../layouts/ConfiguratorTwoColumnsPlanimetryLayout';
import { getLayoutImagesByConfigurationId } from '../../../services/layoutApi';
import { getOption } from '../../../services/optionApi';
import { useStore } from '../../../store/storeUtils';
import { resumeAnswerSimple } from '../../../utils/configuratorUtils';
import { getLayoutLayers, prepareOptionDataExploded } from '../../../utils/optionsUtils';
import { handleSave, multipleChoiceSelection } from '../../../utils/selectionUtils';
import DefaultChoiceImgText from '../layout/currentConfiguration/DefaultChoiceImgText';

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: '#EEEEEE',
    width: '100%',
    minHeight: '100%',
  },
  list: { marginTop: '50px', padding: '0 22px 22px' },
  alertMessage: { marginBottom: '5px' },
  hidden: { display: 'none' },
}));

const FirstColumn = ({ defaultOption, onClick, selectedOptions }) => {
  return defaultOption ? (
    <DefaultChoiceImgText
      isSelected={_.isEqual(defaultOption.ids, selectedOptions)}
      onClick={() => {
        onClick(defaultOption.ids);
      }}
      message="Nell'appartamento è prevista la predisposizione per rendere l'impianto elettrico SMART."
      icon={<SmartHomeNo />}
    />
  ) : (
    ''
  );
};

const getSmartHomeIcon = (value) => {
  switch (value) {
    case "Tapparelle Smart":
      return <TapparelleSmart />;
    case "Luci Smart":
      return <LuciSmart />;
    case "Carichi Smart":
      return <CarichiSmart />;
    case "Porta Blindata Connessa":
      return <ChiusuraWireless />;
    case "Video Citofono Connesso":
      return <VideoCitofonoConnesso />;
    case "Termostati Smart": 
      return <TermostatoSmart />;
    default:
      return <CarichiSmart />;
  }
};

const getSmartHomeInfoPriceText = (value) => {
  switch (value) {
    case 1:
      return "Il prezzo comprende la sostituzione di tutti i comandi delle tapparelle con comandi connessi. Il prezzo è calcolato sul totale delle finestre presenti nell'appartamento.";
    case 2:
      return "Il prezzo comprende la sostituzione di tutti i comandi delle luci con comandi connessi. Il prezzo è calcolato sul totale delle luci presenti nell'appartamento, escluso eventuale illuminazione armadi.";
    case 3:
      return "Il prezzo comprende l'installazione nel quadro elettrico di un dispositivo per la gestione dei carichi che consente di controllare la potenza impegnata e di attivare, in caso di sovraccarico, il distacco automatico dei carichi non prioritari. Il sistema prevede la gestione di n°4 carichi: forno, lavastoviglie, lavatrice, piano cottura.";
    case 4:
      return 'Il prezzo comprende la sostituzione del portoncino previsto nella tua configurazione di partenza con un portoncino con serratura motorizzata, tastierino per digitazione codice ingresso e app per il controllo da remoto.';
    case 5:
      return 'Il prezzo comprende la sostituzione del videocitofono Bticino Classe 100 previsto nella tua configurazione di partenza con un modello connesso.';
    case 7:
      return "Il prezzo comprende l'installazione di un sistema di regolazione delle temperatura connesso con app per il controllo da remoto.";
    default:
      return '';
  }
};

const SecondColumn = ({ options, selectedOptions, onClick, showVideo, brochure }) => {
  const classes = useStyles();

  return (
    <ConfiguratorSelectionLayout>
      <div className={classes.content}>
        <ConfiguratorIntro
          icon={<Domotic color={'#AFB0AF'} />}
          title={'Quali soluzioni vuoi aggiungere per rendere SMART la tua casa?'}
          showVideo={showVideo}
          brochure={brochure}
          badgeText={'Puoi selezionare una o più opzioni'}
          badgeTextOrange={
            'Selezionando almeno un pacchetto a scelta tra TAPPARELLE SMART, LUCI SMART, VIDEOCITOFONO CONNESSO o CARICHI SMART, nel Riepilogo verrà aggiunto il costo di installazione e attivazione del Gateway.'
          }
        />
        <Grid container className={classes.list} spacing={2}>
          {options.map((el, i) => (
            <Grid item key={i} xs={6} lg={4}>
              <OptionCard
                {...el}
                onClick={() => onClick(el.ids)}
                isSelected={_.difference(el.ids, selectedOptions).length === 0}
                multiple
                white
                infoPriceMessage={getSmartHomeInfoPriceText(el.position)}
              >
                <StandardOptionCardBody
                  {...el}
                  icon={getSmartHomeIcon(el.name)}
                  smallIcon
                  bottomIconMessage
                  hidePositions
                  bottomName
                />
              </OptionCard>
            </Grid>
          ))}
        </Grid>
      </div>
    </ConfiguratorSelectionLayout>
  );
};

const SmartHome = () => {
  const [showVideo, setShowVideo] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const [defaultOption, setDefaultOption] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [images, setImages] = useState([]);

  const substepIdRef = useRef();
  const oldAnswersRef = useRef();
  const activationGatewayIdRef = useRef();
  const optionsWithActivationIdsRef = useRef([]);

  const store = useStore();
  const params = useParams();

  const ACTIVATION_GATEWAY_POSITION = 6;
  const OPTIONS_WITH_ACTIVATION_POSITION = [1, 2, 3, 5];

  useEffect(() => {
    store.setUserCanGoNext(false);
    prepareOptions();
  }, []);

  const prepareOptions = async () => {
    const substepId = store.getSubstepIdByFrontendId(store.currentSubstep);

    let optionData = await getOption(
      store.currentConfiguration?.layout?.id,
      substepId,
      params.configuration,
    );
    substepIdRef.current = optionData?.data?.substep?.id;

    optionData = prepareOptionDataExploded(optionData);

    // Imposto i layer da far vedere
    const layoutImagesResponse = await getLayoutImagesByConfigurationId(params.configuration);
    const layoutImages = layoutImagesResponse.data;

    const optionLayers = getLayoutLayers(optionData);
    const newImages = layoutImages.concat(optionLayers);
    setImages(newImages);

    setOptions(
      optionData.filter((el) => !el.isDefault && el?.position !== ACTIVATION_GATEWAY_POSITION),
    );

    optionsWithActivationIdsRef.current = [];
    optionData.forEach((option) => {
      if (OPTIONS_WITH_ACTIVATION_POSITION.includes(option?.position)) {
        optionsWithActivationIdsRef.current.push(option.id);
      }
      if (option?.position === ACTIVATION_GATEWAY_POSITION) {
        activationGatewayIdRef.current = option.id;
      }
    });

    const defaultOption = optionData.find((el) => el.isDefault);
    setDefaultOption(defaultOption);

    resumeAnswerSimple(store, oldAnswersRef, substepIdRef, setSelectedOptions, setIsLoading);
  };

  useEffect(() => {
    const optionsToSave = [...selectedOptions].filter(
      (el) => el !== activationGatewayIdRef.current,
    );

    if (optionsToSave.some((optionId) => optionsWithActivationIdsRef.current.includes(optionId))) {
      optionsToSave.push(activationGatewayIdRef.current);
    }

    const save = async () => {
      await handleSave(optionsToSave, params.configuration, substepIdRef.current, store);
    };

    if (!_.isEqual(oldAnswersRef.current, selectedOptions)) {
      store.setSubstepSave(save);
    } else {
      store.setSubstepSave(null);
    }
  }, [selectedOptions]);

  const handleUserSelection = (selection) => {
    multipleChoiceSelection(
      selection,
      selectedOptions,
      defaultOption.ids,
      setSelectedOptions,
      store,
    );
  };

  const handleShowVideo = () => {
    setShowVideo(true);
  };
  const handleHideVideo = () => {
    setShowVideo(false);
  };

  return (
    <>
      <ConfiguratorTwoColumnsPlanimetryLayout
        planimetryImages={images}
        firstColumn={
          <FirstColumn
            defaultOption={defaultOption}
            selectedOptions={selectedOptions}
            onClick={handleUserSelection}
          />
        }
        secondColumn={
          <SecondColumn
            options={options}
            selectedOptions={selectedOptions}
            onClick={handleUserSelection}
            showVideo={handleShowVideo}
          />
        }
        firstRowTitle={'IL TUO'}
        secondRowTitle={'APPARTAMENTO'}
        secondColumnFirstRowTitle={'POSSIBILI'}
        secondColumnSecondRowTitle={'AGGIUNTE'}
        isLoading={isLoading}
        noOption={options?.length === 0}
        noOptionIcon={<Domotic />}
        noOptionText={'Non ci sono opzioni smart da aggiungere.'}
      />
      {showVideo && (
        <VideoViewer
          urlVideo={'https://www.w3schools.com/html/mov_bbb.mp4'}
          handleClose={handleHideVideo}
        />
      )}
    </>
  );
};

export default SmartHome;
