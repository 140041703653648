import * as React from 'react';

function SvgUsbLettoSingolo(props) {
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M41.057 6.098H44v3.164h-1.006v2.45c0 .255-.045.507-.136.742-.091.235-.224.448-.392.627l-4.117 4.458h-.038v5.83c.649.127 1.23.509 1.63 1.072.4.564.59 1.268.53 1.974a2.922 2.922 0 01-.847 1.841 2.539 2.539 0 01-1.78.744c-.66 0-1.295-.265-1.782-.744a2.922 2.922 0 01-.847-1.841c-.059-.706.13-1.41.53-1.974.4-.563.982-.945 1.63-1.071v-2.375l-3.883-4.18a1.932 1.932 0 01-.392-.628 2.046 2.046 0 01-.136-.741v-2.757a1.459 1.459 0 01-.763-.67 1.637 1.637 0 01-.181-1.04 1.57 1.57 0 01.487-.92c.258-.236.586-.365.925-.365.338 0 .666.13.924.364.259.235.431.562.487.92.056.36-.008.728-.18 1.042-.173.313-.444.55-.764.669v2.752c0 .122.022.243.066.356a.942.942 0 00.187.303l3.223 3.471V3.144H36.16l.842-1.574.842-1.57.847 1.57.842 1.574H38.33V16.13l3.495-3.758a.972.972 0 00.233-.66v-2.45h-1V6.098zM14.021 5h9.98v1.96h-9.97a6.31 6.31 0 00-6.22 6.38v12.11h3v-2.68a5.279 5.279 0 015.27-5.29h7.92v1.97h-8.02a3.35 3.35 0 00-3.33 3.35v2.68h11.35v2.01H6.942a3.15 3.15 0 00-3.13 3.15v6.6h20.19v.06h23.6a.5.5 0 01.26.44v4.87a.5.5 0 01-.5.5h-1.31v3.42a.5.5 0 01-.5.5h-4.83a.5.5 0 01-.5-.5v-3.42H7.742v3.42a.51.51 0 01-.5.5h-4.9a.5.5 0 01-.5-.5v-3.42H.531a.5.5 0 01-.5-.5v-4.87a.5.5 0 01.5-.5h1.31v-6.6a5.08 5.08 0 013.89-5v-12.3A8.32 8.32 0 0114.021 5zm28.09 39.98v-1.9h1.88v1.9h-1.88zm-38.32 0v-1.9h1.89v1.9h-1.89zm42.08-3.92h-44v-1.9h44v1.9z'
        fill='#C4C4C4'
      />
    </svg>
  );
}

export default SvgUsbLettoSingolo;
