import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import OptionCard, { StandardOptionCardBody } from '../../../components/Card/OptionCard';
import ConfiguratorIntro from '../../../components/configurator/ConfiguratorIntro';
import { PortaAltezza, PortaAltezzaStandard } from '../../../components/Icons';
import ConfiguratorSelectionLayout from '../../../components/Layout/ConfiguratorSelectionLayout.jsx';
import ConfiguratorTwoColumnsPlanimetryLayout from '../../../layouts/ConfiguratorTwoColumnsPlanimetryLayout';
import { getLayoutImagesByConfigurationId } from '../../../services/layoutApi';
import { getOption } from '../../../services/optionApi';
import { getProjects } from '../../../services/projectApi';
import { useStore } from '../../../store/storeUtils';
import {
  handleCardMouseEnterSimple,
  handleCardMouseLeaveSimple,
  resumeAnswerSimple,
} from '../../../utils/configuratorUtils';
import { prepareOptionDataExploded, handleOptionPlanimetryLayersUpdated } from '../../../utils/optionsUtils';
import { handleSave, multipleChoiceSelection } from '../../../utils/selectionUtils';
import DefaultPorte from './currentConfiguration/DefaultPorte';
import portaRasomuro from '../../../assets/POR_B_R_80_210.jpg';
import portaRasomuroExtraAltezza from '../../../assets/POR_B_R_80_240.jpg';
import pdfPortaRB210 from '../../../assets/POR_B_R_80_210.pdf';
import pdfPortaRSI210 from '../../../assets/POR_SI_R_80_210.pdf';
import pdfPortaRB240 from '../../../assets/POR_B_R_80_240.pdf';
import pdfPortaRSI240 from '../../../assets/POR_SI_R_80_240.pdf';

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: '#EEEEEE',
    width: '100%',
  },
  list: { marginTop: '25px', padding: '0 22px 22px' },
  alertMessage: { marginBottom: '5px' },
  scrollableContent: {
    backgroundColor: '#EEEEEE',
  },
  textMainColor: {
    color: theme.palette.primary.main,
  },
}));

const FirstColumn = ({ defaultOption, onClick, selectedOption }) => {
  const defaultPorteAltezza = [
    {
      icon: <PortaAltezzaStandard />,
      products: [
        { imageFileUrl: portaRasomuro, dataSheetFileUrl: pdfPortaRB210 },
        { imageFileUrl: portaRasomuro, dataSheetFileUrl: pdfPortaRSI210 },
      ],
    },
  ];

  return (
    defaultOption && (
      <DefaultPorte
        elements={defaultPorteAltezza}
        isSelected={_.isEqual(defaultOption.ids, selectedOption)}
        onClick={() => {
          onClick(defaultOption.ids);
        }}
        message={"Nell'appartamento sono previste porte con altezza 210cm."}
        dimension='210 cm'
        hideDescription
        subtitle={'Conferma questa soluzione oppure scegli opzioni alternative a lato'}
        leftMediaButton
      />
    )
  );
};

const SecondColumn = ({
  options,
  selectedOptions,
  onClick,
  cardOnMouseEnter,
  cardOnMouseLeave,
  extraHeight,
}) => {
  const classes = useStyles();

  return (
    <ConfiguratorSelectionLayout scrollableContentClasses={clsx(classes.scrollableContent)}>
      <div className={classes.content}>
        <ConfiguratorIntro
          icon={<PortaAltezza />}
          title={"Vuoi aumentare l'altezza delle porte a 240cm?"}
          topLeftTitle={'altezza'}
          badgeText={'Potrai personalizzare le finiture in una fase successiva.'}
          badgeTextOrange={
            'Per ragioni tecniche alcune porte avranno altezza 210cm.'
          }
          iconSideText={`${extraHeight} cm`}
        />
        <Grid container className={classes.list} spacing={2} justifyContent='center'>
          {options.map((el, i) => (
            <Grid item key={i} xs={6} lg={4}>
              <OptionCard
                {...el}
                onClick={() => onClick(el.ids)}
                isSelected={_.difference(el.ids, selectedOptions).length === 0}
                onMouseEnter={cardOnMouseEnter}
                onMouseLeave={cardOnMouseLeave}
                multiple
                infoPriceMessage='Il prezzo comprende la sostituzione della porta alta 210cm con la porta alta 240cm.'
                products={[
                  { imageFileUrl: portaRasomuroExtraAltezza, dataSheetFileUrl: pdfPortaRB240 },
                  { imageFileUrl: portaRasomuroExtraAltezza, dataSheetFileUrl: pdfPortaRSI240 },
                ]}
              >
                <StandardOptionCardBody
                  {...el}
                  message={`Seleziono l'altezza ${extraHeight}cm nella posizione:`}
                  plateText={'P-'}
                  icon={<PortaAltezzaStandard />}
                  iconSideText={`${extraHeight} cm`}
                  hideName={true}
                />
              </OptionCard>
            </Grid>
          ))}
        </Grid>
      </div>
    </ConfiguratorSelectionLayout>
  );
};

const PorteAltezza = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const [defaultOption, setDefaultOption] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [images, setImages] = useState([]);
  const [doorsExtraHeight, setDoorsExtraHeight] = useState(0);
  const [baseOptions, setBaseOptions] = useState([]);

  const substepIdRef = useRef();
  const oldAnswersRef = useRef();
  const showLayerOnHoverRef = useRef(true);
  const baseImagesRef = useRef([]);
  const baseImagesWithOptionsRef = useRef([]);

  const store = useStore();
  const params = useParams();

  useEffect(() => {
    store.setUserCanGoNext(false);
    prepareOptions();
  }, []);

  const prepareOptions = async () => {
    const substepId = store.getSubstepIdByFrontendId(store.currentSubstep);

    let optionData = await getOption(
      store.currentConfiguration?.layout?.id,
      substepId,
      params.configuration,
    );
    substepIdRef.current = optionData?.data.substep.id;

    optionData = prepareOptionDataExploded(optionData);

    // Imposto i layer da far vedere
    const layoutImagesResponse = await getLayoutImagesByConfigurationId(params.configuration);
    const layoutImages = layoutImagesResponse.data;

    baseImagesRef.current = layoutImages;
    baseImagesWithOptionsRef.current = layoutImages;

    setImages(layoutImages);

    getDoorHeightInfo();

    // Può capitare che arrivino 2 opzioni uguali, verifico e nel caso ne mostro solo una
    const noDuplicateOptionData = [];

    optionData
      .filter((el) => !el.isDefault)
      ?.sort((a, b) => a?.positions[0] - b?.positions[0]) // Ordinamento opzioni
      .forEach((option) => {
        const index = noDuplicateOptionData.findIndex((el) =>
          _.isEqual(el?.positions, option?.positions),
        );
        if (index === -1) {
          noDuplicateOptionData.push(option);
        }
      });

    if(noDuplicateOptionData.length > 0) setOptions(noDuplicateOptionData);

    setBaseOptions(optionData);

    const defaultOption = optionData.find((el) => el.isDefault);
    setDefaultOption(defaultOption);

    resumeAnswerSimple(store, oldAnswersRef, substepIdRef, setSelectedOptions, setIsLoading);
  };

  const getDoorHeightInfo = async () => {
    const projectsResponse = await getProjects();
    const projectInfo = projectsResponse?.data?.find(
      (project) => project.shortName === store.currentProject?.shortName,
    );
    setDoorsExtraHeight(projectInfo?.extraAltezzaRasomuro);
  };

  useEffect(() => {
    let selectedOptionsToSave = selectedOptions;  
    const save = async () => {
      if (options.length > 0 && defaultOption) {
        const optionSelected = options.filter(option => selectedOptions.includes(option?.id));
        const optionsSelectedNumber = optionSelected.length > 0 && optionSelected.map(selectedOpt => selectedOpt?.numbers[0]?.number);
        defaultOption.options.forEach((defaultOpt) => {
          const defNumber = defaultOpt?.numbers[0]?.number;
          const defId = defaultOpt?.id;
          if (optionsSelectedNumber.length > 0 && !optionsSelectedNumber.includes(defNumber)) selectedOptionsToSave.push(defId);
        });
      };
      await handleSave(selectedOptionsToSave, params.configuration, substepIdRef.current, store);
    };
    if (!_.isEqual(oldAnswersRef.current, selectedOptions)) {
      store.setSubstepSave(save);
    } else {
      store.setSubstepSave(null);
    }
  }, [selectedOptions]);

  const handleUserSelection = (selection) => {
    multipleChoiceSelection(
      selection,
      selectedOptions,
      defaultOption.ids,
      setSelectedOptions,
      store,
    );
  };

  // Gestione delle immagini della planimetria alla selezione di un opzione
  useEffect(() => {
    handleOptionPlanimetryLayersUpdated(
      baseOptions,
      defaultOption?.ids,
      selectedOptions,
      showLayerOnHoverRef,
      baseImagesRef,
      setImages,
      baseImagesWithOptionsRef,
    );
  }, [selectedOptions]);

  const handleCardMouseEnter = (fileLabels, tags) => {
    handleCardMouseEnterSimple(fileLabels, showLayerOnHoverRef, baseImagesRef, setImages, tags);
  };
  const handleCardMouseLeave = () => {
    handleCardMouseLeaveSimple(showLayerOnHoverRef, baseImagesWithOptionsRef, setImages);
  };

  return (
    <ConfiguratorTwoColumnsPlanimetryLayout
      planimetryImages={images}
      firstColumn={
        <FirstColumn
          defaultOption={defaultOption}
          selectedOption={selectedOptions}
          onClick={handleUserSelection}
        />
      }
      secondColumn={
        <SecondColumn
          options={options}
          selectedOptions={selectedOptions}
          onClick={handleUserSelection}
          cardOnMouseEnter={handleCardMouseEnter}
          cardOnMouseLeave={handleCardMouseLeave}
          extraHeight={doorsExtraHeight}
        />
      }
      firstRowTitle={'IL TUO '}
      secondRowTitle={'APPARTAMENTO'}
      secondColumnFirstRowTitle={'SOLUZIONI'}
      secondColumnSecondRowTitle={'AGGIUNTIVE'}
      isLoading={isLoading}
      noOption={options?.length === 0}
      noOptionIcon={<PortaAltezza />}
      noOptionText={`Nell'appartamento sono previste porte con altezza 210cm. `}
      badgeText={
        'Qualora volessi selezionare delle porte alte 240cm torna allo step precedente e scegli uno dei pacchetti proposti per le porte senza coprifilo.'
      }
      optionLayersOnTop
    />
  );
};

export default PorteAltezza;
