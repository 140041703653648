import {
  IlluminazioneAngoloCottura,
  IlluminazioneAntibagno,
  IlluminazioneBagno,
  IlluminazioneCabinaArmadio,
  IlluminazioneCucina,
  IlluminazioneDisimpegno,
  IlluminazioneIngresso,
  IlluminazioneLavanderia,
  IlluminazioneLettoSingolo,
  IlluminazioneLoggia,
  IlluminazioneMatrimoniale,
  IlluminazioneRipostiglio,
  IlluminazioneSoggiorno,
  IlluminazioneStudio,
} from '../components/Icons';

const PUNTI_LUCE_ROOM_ICONS_MAP = {
  CUCINA: <IlluminazioneCucina color='currentColor' width='40px' height='40px' />,
  BAGNO: <IlluminazioneBagno color='currentColor' width='40px' height='40px' />,
  'CAMERA SINGOLA': <IlluminazioneLettoSingolo color='currentColor' width='40px' height='40px' />,
  'CAMERA DOPPIA': <IlluminazioneMatrimoniale color='currentColor' width='40px' height='40px' />,
  CAMERA: <IlluminazioneMatrimoniale color='currentColor' width='40px' height='40px' />,
  'CABINA ARMADIO': <IlluminazioneCabinaArmadio color='currentColor' width='40px' height='40px' />,
  LAVANDERIA: <IlluminazioneLavanderia color='currentColor' width='40px' height='40px' />,
  STUDIO: <IlluminazioneStudio color='currentColor' width='40px' height='40px' />,
  RIPOSTIGLIO: <IlluminazioneRipostiglio color='currentColor' width='40px' height='40px' />,
  DISIMPEGNO: <IlluminazioneDisimpegno color='currentColor' width='40px' height='40px' />,
  INGRESSO: <IlluminazioneIngresso color='currentColor' width='40px' height='40px' />,
  LOGGIA: <IlluminazioneLoggia color='currentColor' width='40px' height='40px' />,
  TERRAZZO: <IlluminazioneLoggia color='currentColor' width='40px' height='40px' />,
  SOGGIORNO: <IlluminazioneSoggiorno color='currentColor' width='40px' height='40px' />,
  ANTIBAGNO: <IlluminazioneAntibagno color='currentColor' width='40px' height='40px' />,
  'ANGOLO COTTURA': <IlluminazioneAngoloCottura color='currentColor' width='40px' height='40px' />,
};

export default PUNTI_LUCE_ROOM_ICONS_MAP;
