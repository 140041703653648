import { Grid } from '@material-ui/core';
import { Switch, Route } from 'react-router-dom';
import { useLocation, useRouteMatch } from 'react-router';
import SceltaLayout from './layout/SceltaLayout';
import SecondoBagno from './layout/SecondoBagno';
import InfissiOpacita from './layout/InfissiOpacita';
import InfissiZanzariere from './layout/InfissiZanzariere';
import Cassaforte from './layout/Cassaforte';
import PorteTipologiaPosizione from './layout/PorteTipologiaPosizione';
import PorteStile from './layout/PorteStile';
import PorteGrezze from './layout/PorteGrezze';
import PorteAltezza from './layout/PorteAltezza';
import LoggiaLavabo from './layout/LoggiaLavabo';
import LayoutCucina from './layout/LayoutCucina';
import { observer } from 'mobx-react-lite';
import SideMenu from '../../components/SideMenu/SideMenu';
import usePageStyle from '../../styles/usePageStyle';
import InfissiDimensione from './layout/InfissiDimensione';

const LayoutPage = observer(() => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const classes = usePageStyle();

  const layoutSubsteps = [
    { stepKey: 'DistribuzioneInterna', substepKey: 'SceltaLayout', component: <SceltaLayout /> },
    { stepKey: 'Porte', substepKey: 'TipologiaPorte', component: <PorteTipologiaPosizione /> },
    { stepKey: 'Porte', substepKey: 'StilePorte', component: <PorteStile /> },
    { stepKey: 'Porte', substepKey: 'PorteGrezze', component: <PorteGrezze /> },
    { stepKey: 'Porte', substepKey: 'AltezzaPorte', component: <PorteAltezza /> },
    { stepKey: 'Bagno', substepKey: 'Bagno', component: <SecondoBagno /> },
    { stepKey: 'Cucina', substepKey: 'Cucina', component: <LayoutCucina /> },
    { stepKey: 'Finestre', substepKey: 'OpacitaFinestre', component: <InfissiOpacita /> },
    { stepKey: 'Finestre', substepKey: 'ZanzariereFinestre', component: <InfissiZanzariere /> },
    { stepKey: 'Finestre', substepKey: 'DimensioneFinestre', component: <InfissiDimensione /> },
    { stepKey: 'Cassaforte', substepKey: 'Cassaforte', component: <Cassaforte /> },
    { stepKey: 'Loggia', substepKey: 'LavaboEsterno', component: <LoggiaLavabo /> },
  ];

  const isSelectionLayoutPage = pathname.includes('DistribuzioneInterna/SceltaLayout');

  return (
    <Grid container className={classes.root} wrap='nowrap'>
      <Grid item xs className={'configurator-body'}>
        <Switch>
          {layoutSubsteps.map((el) => (
            <Route
              key={`${el.stepKey}/${el.substepKey}`}
              path={`${path}/${el.stepKey}/${el.substepKey}`}
            >
              {el.component}
            </Route>
          ))}
        </Switch>
      </Grid>

      <Grid item xs='auto' className='sidebar-container'>
        <SideMenu />
      </Grid>
    </Grid>
  );
});

export default LayoutPage;
