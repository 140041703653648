import { Box, Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { useStore } from '../store/storeUtils';
import CurrencyFormatter from '../utils/CurrencyFormatter';
import IconButton from './Buttons/IconButton';
import { AlertOutlined, Exit, InfoCircled, ChefHatLock, Chains, Alert, Soggiorno, Cucina } from './Icons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-evenly',
    alignItems: 'center',
    // gap: '24px',
    // width: '700px',
    padding: '30px 60px',
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    borderTopLeftRadius: '90px',
    position: 'fixed',
    zIndex: theme.zIndex.tooltip,
    top: 100,
    left: 40,
    maxWidth: '600px',
    '&.right': {
      left: 'unset',
      right: 40,
    },
    '&.center': {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    '&.popover': {
      position: 'initial',
    },
    '&.no-max-width': {
      maxWidth: 'initial',
    },
    '&.rich-text': {
      color: '#FFF',
      padding: '24px 32px',
    },
  },
  infoPopupChain: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'top',
    backgroundColor: 'rgba(255, 255, 255)',
    borderRadius: '10px',
    position: 'fixed',
    zIndex: theme.zIndex.tooltip,
    maxWidth: '324px',
    '&.right': {
      left: 'unset',
      right: 40,
    },
    '&.center': {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    '&.popover': {
      position: 'initial',
    },
    '&.no-max-width': {
      maxWidth: 'initial',
    },
    '&.rich-text': {
      color: '#FFF',
      padding: '24px 32px',
    },
  },
  infoPopupChoice: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'top',
    backgroundColor: 'rgba(255, 255, 255)',
    borderRadius: '10px',
    position: 'fixed',
    zIndex: theme.zIndex.tooltip,
    maxWidth: '324px',
    '&.right': {
      left: 'unset',
      right: 40,
    },
    '&.center': {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    '&.popover': {
      position: 'initial',
      willChange: 'unset',
    },
    '&.no-max-width': {
      maxWidth: 'initial',
    },
    '&.rich-text': {
      color: '#FFF',
      padding: '24px 32px',
    }
  },
  infoPopupChainSpan: {
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    width: '100%',
    backgroundColor: '#C5BAAE',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px',
  },
  infoPopupChoiceSpan: {
    borderTopRightRadius: '10px',
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    padding: '1px',
  },
  infoPopupChainSpanSmall: {
    // borderTopLeftRadius: '10px',
    // borderTopRightRadius: '10px',
    width: '100%',
    // backgroundColor: '#C5BAAE',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    // paddingTop: '20px',
    // padding: '5px',
  },
  exitIcon: {
    display: 'flex',
    marginRight: '-25px',
    backgroundColor: theme.palette.color.orange,
    padding: '2px',
    borderRadius : '50%',
    cursor: 'pointer',
    color: theme.palette.color.orange,
  },
  exitIconNew: {
    display: 'flex',
    position:'absolute',
    backgroundColor: theme.palette.color.orange,
    padding: '2px',
    borderRadius : '50%',
    cursor: 'pointer',
    right: '6px',
    top: '6px',
    zIndex: '2',
  },
  exitIconSmall: {
    display: 'flex',
    marginRight: '-25px',
    // marginTop: '-20px',
    // backgroundColor: theme.palette.color.orange,
    padding: '2px',
    borderRadius : '50%',
    cursor: 'pointer',
    color: theme.palette.color.orange,
  },
  exitIconSmallNew: {
    display: 'flex',
    position:'absolute',
    // left: '0',
    right: '0',
    top: '0',
    // bottom: '0',
    margin: 'auto',
    padding: '2px',
    borderRadius : '50%',
    cursor: 'pointer',
    color: theme.palette.color.orange,
  },
  soggiornoIcon: {
    display: 'flex',
    position:'absolute',
    left: '0',
    right: '0',
    top: '0',
    bottom: '0',
    margin: 'auto',
    padding: '5px',
    borderRadius : '50%',
    backgroundColor: theme.palette.color.grey2,
    opacity: '0.9',
  },
  titleChain: {
    display: 'flex',
    color: 'white',
  },
  attention: {
    fontWeight: 750,
    fontSize: '24px',
    lineHeight: '29px',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: theme.palette.color.white,
  },
  text: {
    width: '75%',
    fontFamily: 'miller',
    fontStyle: 'italic',
    fontSize: '22px',
    lineHeight: '26px',
    textAlign: 'center',
    color: theme.palette.color.white,
  },
  normalText: {
    fontSize: '21px',
    color: theme.palette.color.white,
  },
  actionText: {
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '18px',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: theme.palette.color.white,
    marginBottom: '25px',
  },
  actionButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '22px',
    marginBottom: '15px',

    '& button': {
      minWidth: '215px',
      '& .text span': {
        fontSize: '18px',
      },
    },
  },
  subText: {
    fontFamily: 'miller',
    fontStyle: 'italic',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '18px',
    textAlign: 'center',
    color: theme.palette.primary.main,
    '& svg': {
      marginBottom: '2px',
      verticalAlign: 'middle',
    },
  },
  highlight: {
    color: theme.palette.primary.main,
  },
  infoText: {
    fontFamily: 'miller',
    fontStyle: 'italic',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#FFF',
  },
  infoTextChain: {
    fontFamily: 'miller',
    fontStyle: 'italic',
    fontSize: '12px',
    color: '#FFF',
    margin: '13px auto 0 auto',
  },
  textChain: {
    fontFamily: 'miller',
    fontStyle: 'italic',
    fontSize: '12px',
    color: 'black',
    textAlign: 'center',
  },
  containerChoice: {
    // borderBottom: '1px solid black',
    // marginLeft:'5px',
    // marginRight: '5px',
    //marginBottom: '5px',
  },
  containerTotalChoice: {
    // marginTop: '-10px',
    padding: '15px'
  },
  textChoice: {
    fontFamily: 'miller',
    fontStyle: 'italic',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '15px',
    color: 'black',
    textAlign: 'left',
  },
  textChoiceB: {
    fontFamily: 'miller',
    fontStyle: 'italic',
    fontSize: '15px',
    fontWeight: '400',
    lineHeight: '15px',
    color: 'black',
    textAlign: 'left',
  },
  textChoiceTitle: {
    // fontFamily: 'miller',
    // fontStyle: 'italic',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '17px',
    color: 'black',
    textAlign: 'left',
  },
  textChoiceSubTitle: {
    // fontFamily: 'miller',
    // fontStyle: 'italic',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '15px',
    color: 'black',
    textAlign: 'left',
  },
  iconContainerChain: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    borderBottom: '1px solid #CDC7C1',
    margin: '5px 10px',
    padding: '15px 5px',
  },
  iconContainerChoice: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    margin: '5px 10px',
    padding: '15px 5px',
    position:'relative',
  },
  iconContainerChoiceNew: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    // margin: '5px 10px',
    // padding: '15px 5px',
    position:'relative',
    width: '100%',
    height: '100%',
    maxHeight: '100px',
  },
  iconContainerChainSmall: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    // borderBottom: '1px solid #CDC7C1',
    // margin: '5px 10px',
    paddingBottom: '20px',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  iconContainerChoiceSmall: {
    width: '100px',
    height: '100px',
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingTop: '5px',
    paddingBottom: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  chainKithcenIcon: {
    display: 'flex',
  },
  chainIcon: {
    display: 'flex',
    marginRight: '7px',
  },
  date: {
    fontSize: '25px',
    lineHeight: '30px',
    textTransform: 'uppercase',
    textAlign: 'center',
    color: '#FFF',
  },
  divider: {
    width: '100%',
    margin: 0,
    border: 0,
    borderTop: `1px dashed ${theme.palette.color.white}`,
  },
  warning: {
    fontFamily: 'miller',
    fontStyle: 'italic',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#FFF',
  },
  text: {
    fontSize: '15px',
    lineHeight: '21px',
    color: '#FFF',
    marginTop: 16,
  },
  default: {
    fontSize: '15px',
    lineHeight: '21px',
    textTransform: 'uppercase',
    color: '#FFF',
  },
  moodContent: {
    color: '#FFF',
    textAlign: 'center',
    width: '430px',
    '& .subtitle': {
      marginBottom: '10px',
    },
    '& .title': {
      marginBottom: '20px',
    },
    '& .text': {
      borderTop: '1px dashed #FFF',
      paddingTop: '20px',
      marginBottom: 10,
    },
  },
  warningDeleteRoot: {
    width: '100%',
    color: '#FFF',
    '& .title': {
      paddingTop: '10px',
      textAlign: 'center',
      fontWeight: 750,
      fontSize: '24px',
      lineHeight: '29px',
      textAlign: 'center',
      textTransform: 'uppercase',
    },
  },
  title: {
    padding: '10px 0 10px',
    textAlign: 'center',
    fontWeight: 750,
    fontSize: '24px',
    lineHeight: '29px',
    textTransform: 'uppercase',
  },
  italicText: {
    fontFamily: 'miller',
    fontStyle: 'italic',
    fontSize: '22px',
    lineHeight: '26px',
    textAlign: 'center',
    color: theme.palette.color.white,
    marginBottom: '25px',
  },
}));

const InfoPopup = observer((props) => {
  const classes = useStyles();
  const store = useStore();

  const { isWarning, body, position = 'left' } = store.infoPopup;

  return body || props.body ? (
    <div
      className={clsx(
        classes.root,
        props.position ? props.position : position,
        props.isPopover && 'popover',
        props.noMaxWidth && 'no-max-width',
      )}
    >
      <Exit
        color='#fff'
        width='15px'
        height='15px'
        className={classes.exitIcon}
        onClick={() => {
          store.hideInfoPopup();
          if (props.handleClose) {
            props.handleClose();
          }
        }}
      />
      {isWarning || props.isWarning ? (
        <AlertOutlined color='#fff' width='30px' height='30px' />
      ) : (
        <InfoCircled color='#fff' width='30px' height='30px' />
      )}
      {body || props.body}
    </div>
  ) : null;
});

const InfoPopupChain = observer((props) => {
  const classes = useStyles();
  const store = useStore();


  return props.body ? (
    <div
      className={clsx(
        classes.infoPopupChain,
        props.position ? props.position : 'left',
        props.isPopover && 'popover',
        props.noMaxWidth && 'no-max-width',
      )}
    >
      <div className={clsx(classes.infoPopupChainSpan)}> 
      <span className={classes.titleChain}></span>
      <span className={classes.titleChain}>Nota Bene</span>
      <Exit
        color='white'
        width='15px'
        height='15px'
        className={classes.exitIcon}
        onClick={() => {
          store.hideInfoPopup();
          if (props.handleClose) {
            props.handleClose();
          }
        }}
      />
      </div>
      {props.body}
    </div>
  ) : null;
});

const InfoPopupChoice = observer((props) => {
  const classes = useStyles();
  const position = 'left';

  return props.body ? (
    <div
      className={clsx(
        classes.infoPopupChoice,
        props.position ? props.position : position,
        props.isPopover && 'popover',
        props.noMaxWidth && 'no-max-width',
      )}
    >
      {props.body}
    </div>
  ) : null;
});

const InfoPopupChoiceSmall = observer((props) => {
  const classes = useStyles();

  return props.body ? (
    <div
      className={clsx(
        classes.infoPopupChain,
        props.position ? props.position : 'left',
        props.isPopover && 'popover',
        props.noMaxWidth && 'no-max-width',
      )}
    >
      {props.body}
    </div>
  ) : null;
});

const InfoPopupChainSmall = observer((props) => {
  const classes = useStyles();
  const store = useStore();


  return props.body ? (
    <div
      className={clsx(
        classes.infoPopupChain,
        props.position ? props.position : 'left',
        props.isPopover && 'popover',
        props.noMaxWidth && 'no-max-width',
      )}
    >
      <div className={clsx(classes.infoPopupChainSpanSmall)}> 
      <span className={classes.titleChain}></span>
      <span className={classes.titleChain}></span>
      <Alert
        // color='white'
        width='20px'
        height='20px'
        className={classes.exitIconSmall}
        onClick={() => {
          store.hideInfoPopup();
          if (props.handleClose) {
            props.handleClose();
          }
        }}
      />
      </div>
      {props.body}
    </div>
  ) : null;
});

const InfoPopupChainData = observer((props) => {
  const classes = useStyles();
  const store = useStore();
  return (
    <>
      <span className={classes.infoTextChain} style={{textAlign:'center'}}>
        <span className={classes.highlight}>Scelta collegata </span> 
        <span className={classes.textChain}> alla cucina</span>
      </span>
      <span className={classes.iconContainerChain}>
          <Chains
            color='#F08219'
            width='23px'
            height='17px'
            className={classes.chainIcon}
            onClick={() => {
              store.hideInfoPopup();
              if (props.handleClose) {
                props.handleClose();
              }
            }}
          />
          <ChefHatLock
            color='black'
            width='40px'
            height='40px'
            className={classes.chainKithcenIcon}
          />
      </span>  
      <span className={classes.infoTextChain} style={{padding: '0 13px 24px 13px'}}>
        <span className={classes.textChain}>Scegliendo questa opzione anche </span>
        <span className={classes.highlight}> nella cucina verrà applicato lo stesso pavimento.</span> 
      </span>
      
    </>
  );
});

const InfoPopupChoiceData = observer((props) => {
  const classes = useStyles();
  const store = useStore();
  const { finituraImg, isCucina, selectedFinituraTitle, isPavimentoBagno, isPareteBagno } = props;
  return (
    <Grid container justifyContent="center" className={classes.containerTotalChoice}>
      <Grid item xs='8' style={{paddingRight: '10px'}}>
          { isPavimentoBagno && !isPareteBagno && (
            <div className={classes.containerChoice}>
              <span className={classes.textChoiceTitle}> Pavimento </span><br />
              <span className={classes.textChoiceSubTitle}> Bagno </span>
            </div>
          )}
          { !isPavimentoBagno && isPareteBagno && (
            <div className={classes.containerChoice}>
              <span className={classes.textChoiceTitle}> Parete </span><br />
              <span className={classes.textChoiceSubTitle}> Bagno </span>
            </div>
          )}
          { !isPavimentoBagno && !isPareteBagno && (
            <div className={classes.containerChoice}>
              <span className={classes.textChoiceTitle}> Finitura </span><br />
              <span className={classes.textChoiceSubTitle}> {isCucina ? 'CUCINA' : 'LIVING E CAMERE'} </span>
            </div>
          )}
        <div>
          <span className={classes.textChoiceB}><b>{selectedFinituraTitle}</b></span>
        </div>
      </Grid>
      <Grid item xs='4'>
        <Exit
          color='white'
          width='15px'
          height='15px'
          className={classes.exitIconNew}
          onClick={() => {
            store.hideInfoPopup();
            if (props.handleClose) {
              props.handleClose();
            }
          }}
        />
        <span className={classes.iconContainerChoiceNew}>
          <img src={finituraImg} alt='miniatura formato' style={{width: '90%', borderRadius:'10px', height: '90%', objectFit: 'contain'}}/>
        </span>
      </Grid>
    </Grid>
  )
});

const InfoPopupChainDataSmall = observer((props) => {
  const classes = useStyles();
  const store = useStore();
  return (
    <>
      <span className={classes.iconContainerChainSmall}>
          <Chains
            color='#F08219'
            width='23px'
            height='17px'
            className={classes.chainIcon}
            onClick={() => {
              store.hideInfoPopup();
              if (props.handleClose) {
                props.handleClose();
              }
            }}
          />
          <ChefHatLock
            color='black'
            width='40px'
            height='40px'
            className={classes.chainKithcenIcon}
          />
      </span>        
    </>
  );
});
const InfoPopupChoiceDataSmall = observer((props) => {
  const classes = useStyles();
  const store = useStore();
  const { finituraImg, isCucina, isPavimentoBagno, isPareteBagno } = props;
  return (
    <>
      <span className={classes.iconContainerChoiceSmall}>
        <Alert
          // color='white'
          width='20px'
          height='20px'
          className={classes.exitIconSmallNew}
          onClick={() => {
            store.hideInfoPopup();
            if (props.handleClose) {
              props.handleClose();
            }
          }}
        />
        {isCucina && !isPavimentoBagno && !isPareteBagno && (
          <Cucina
            color='white'
            width='35px'
            height='35px'
            className={classes.soggiornoIcon}
          />
        )}
        {!isCucina && !isPavimentoBagno && !isPareteBagno && (
          <Soggiorno
            color='white'
            width='35px'
            height='35px'
            className={classes.soggiornoIcon}
          />
        )}
          <img src={finituraImg} style={{width: '90%', borderRadius:'10px', height: '90%', objectFit: 'contain' }} alt="" />
      </span>
    </>
  );
});

export const InfoPopupRichText = observer((props) => {
  const classes = useStyles();
  const store = useStore();

  const { body, position = 'left' } = store.infoPopup;

  return body || props.body ? (
    <div
      className={clsx(
        classes.root,
        'rich-text',
        props.position ? props.position : position,
        props.isPopover && 'popover',
        props.noMaxWidth && 'no-max-width',
      )}
    >
      <Exit
        color='#fff'
        width='15px'
        height='15px'
        className={classes.exitIcon}
        onClick={() => {
          store.hideInfoPopup();
          if (props.handleClose) {
            props.handleClose();
          }
        }}
      />
      <div dangerouslySetInnerHTML={{ __html: props.body }} />
    </div>
  ) : null;
});

const PriceContent = ({ Name, Price }) => {
  const classes = useStyles();
  return (
    <Grid container direction='column' className={classes.moodContent}>
      <Grid item className={clsx(classes.warning, 'subtitle')}>
        Standard content
      </Grid>
      <Grid item className={clsx(classes.date, 'title')}>
        {Name}
      </Grid>
      <Grid item className={clsx(classes.warning, 'text')}>
        il prezzo é {CurrencyFormatter(Price)}
      </Grid>
    </Grid>
  );
};

const MoodContent = ({ moodName, text }) => {
  const classes = useStyles();
  return (
    <Grid container direction='column' className={classes.moodContent}>
      <Grid item className={clsx(classes.warning, 'subtitle')}>
        Mood
      </Grid>
      <Grid item className={clsx(classes.date, 'title')}>
        {moodName}
      </Grid>
      <Grid item className={clsx(classes.warning, 'text')}>
        {text}
      </Grid>
    </Grid>
  );
};

const WarningContent = ({ handleCancel, handleSubmit }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.warningDeleteRoot}>
        <div className={classes.title}>ATTENZIONE!</div>
        <div className={classes.italicText}>
          Modificando questa scelta verranno annullate anche tutte le scelte successive.
        </div>
        <div className={classes.actionText}>
          Vuoi comunque <span className={classes.highlight}>procedere con la modifica ?</span>
        </div>
        <div className={classes.actionButtons}>
          <IconButton
            onClick={handleCancel}
            text={
              <>
                <span>NO</span>, mantengo le mie scelte
              </>
            }
          />
          <IconButton
            onClick={handleSubmit}
            text={
              <>
                <span>SI</span>, modifico questa scelta e le successive
              </>
            }
          />
        </div>
      </div>
    </>
  );
};

export const SaveConfirmContent = ({ handleCancel, handleSubmit }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.warningDeleteRoot}>
        <div className={classes.title}>CONFIGURAZIONE SALVATA!</div>
        <div className={classes.italicText}>
          Complimenti, hai salvato la configurazione. Potrai vedere le tue scelte nel riepilogo
          della configurazione.
        </div>
        <div className={classes.actionButtons}>
          <IconButton
            onClick={handleSubmit}
            text={
              <>
                <span>OK</span>
              </>
            }
          />
        </div>
      </div>
    </>
  );
};

export const ExitContent = ({ handleCancel, handleSubmit }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.warningDeleteRoot}>
        <div className={classes.title}>ATTENZIONE!</div>
        <div className={classes.italicText}>Sei sicuro di uscire dall'Home Configurator?</div>
        {/* <div className={classes.actionText}>
          Vuoi comunque <span className={classes.highlight}>procedere con la modifica ?</span>
        </div> */}
        <div className={classes.actionButtons}>
          <IconButton
            onClick={handleCancel}
            text={
              <>
                <span>NO</span>, voglio rimanere
              </>
            }
          />
          <IconButton
            onClick={handleSubmit}
            text={
              <>
                <span>SI</span>, voglio uscire
              </>
            }
          />
        </div>
        {/* <div className={classes.subText}>
          Visualizza e modifica le tue configurazioni in ogni momento. <br /> Le trovi tutte
          sull’icona della <PlanimetryCircled color='currentColor' /> Planimetria
        </div> */}
      </div>
    </>
  );
};

export const WarningDeleteConfigurationContent = ({ handleCancel, handleSubmit }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.warningDeleteRoot}>
        <div className={classes.title}>ATTENZIONE!</div>
        <div className={classes.italicText}>
          Eliminando questa opzione, verrano annullate tutte le scelte effettuate dentro la
          configurazione.
        </div>
        <div className={classes.actionText}>
          SEI SICURO DI VOLER{' '}
          <span className={classes.highlight}>ELIMINARE QUESTA CONFIGURAZIONE?</span>
        </div>
        <div className={classes.actionButtons}>
          <IconButton
            onClick={handleCancel}
            text={
              <>
                <span>NO</span>, voglio mantenere
              </>
            }
          />
          <IconButton
            onClick={handleSubmit}
            text={
              <>
                <span>SI</span>, voglio eliminare
              </>
            }
          />
        </div>
      </div>
    </>
  );
};

const GenericTextContent = ({ text }) => {
  const classes = useStyles();

  return <span className={classes.text}>{text}</span>;
};

const InfoContent = (props) => {
  const classes = useStyles();
  return (
    <>
      <span className={classes.infoText}>
        Potrai configurare la tua casa fino a{' '}
        <span className={classes.highlight}>"il tuo stile: bagno"</span> compreso,
      </span>
      <span className={classes.date}>
        entro la data del <span className={classes.highlight}>{props.date}</span>.
      </span>
      <hr className={classes.divider} />
      <span className={classes.warning}>
        Se non concluderai le prime quattro tab entro lo scadere del tempo,
      </span>
      <span className={classes.default}>
        verranno considerate{' '}
        <span className={classes.highlight}>valide le forniture a capitolato</span>.
      </span>
      <span className={classes.subText}>
        Ai sensi dell’art. 41 del Preliminare di Compravendita e del Capitolato, allegato C
      </span>
    </>
  );
};

const ComingSoonContent = ({ phase }) => {
  return (
    <>
      {/* <Box paddingTop={'10px'}>
        <span className={classes.infoText}>
          Potrai configurare la tua casa fino <span className={classes.highlight}>"{phase}"</span>{' '}
          compresa,
        </span>
      </Box>

      <Box
        fontSize={'20px'}
        color={'#FFF'}
        paddingBottom={'14px'}
        paddingTop={'4px'}
        lineHeight={'1.2'}
      >
        prossimamente sbloccheremo le prossime fasi.
      </Box>
      <hr className={classes.divider} /> */}

      <Box fontSize={'16px'} color={'#FFF'} padding={'16px'} marginTop={'16px'} lineHeight={'1.2'}>
        Ti ricordiamo di confermare la configurazione entro i termini previsti.
      </Box>
      <Box fontSize={'16px'} color={'#FFF'} padding={'2px 16px 16px'} lineHeight={'1.2'}>
        Qualora non dovessi rispettare la scadenza, verranno inserite nel tuo appartamento delle
        forniture di capitolato selezionate per te dai nostri architetti, ai sensi dell’art.4.1 del
        contratto Preliminare di compravendita e del Capitolato Allegato C.
      </Box>
    </>
  );
};

const PhaseInfoContent = ({ phase, firstRow, secondRow, thirdRow }) => {
  const classes = useStyles();

  return (
    <>
      <Box paddingTop={'10px'} fontSize={'20px'} color={'#F08219'}>
        {phase}
      </Box>
      <Box
        fontSize={'18px'}
        color={'#FFF'}
        paddingBottom={'14px'}
        paddingTop={'4px'}
        lineHeight={'1.2'}
        fontFamily={'miller'}
        fontStyle={'italic'}
        textAlign={'center'}
      >
        {firstRow}
      </Box>
      <hr className={classes.divider} />
      <Box fontSize={'17px'} color={'#FFF'} padding={'14px'} lineHeight={'1.2'}>
        {secondRow}
      </Box>
      <hr className={classes.divider} />
      <Box fontSize={'17px'} color={'#F08219'} padding={'14px'} lineHeight={'1.2'}>
        {thirdRow}
      </Box>
    </>
  );
};

export {
  PriceContent,
  MoodContent,
  WarningContent,
  InfoContent,
  ComingSoonContent,
  PhaseInfoContent,
  GenericTextContent,
  InfoPopupChain,
  InfoPopupChainSmall,
  InfoPopupChainData,
  InfoPopupChainDataSmall,
  InfoPopupChoiceSmall,
  InfoPopupChoice,
  InfoPopupChoiceDataSmall,
  InfoPopupChoiceData
};

export default InfoPopup;
