import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import ImageZoomButton from '../Buttons/ImageZoomButton';
import { Camera } from '../Icons';
import { saveAs } from 'file-saver';
import { isValidElement } from 'react';
import AlertBadge from '../Card/AlertBadge';

const useStyles = makeStyles((theme) => ({
  configuratorIntroRoot: {
    width: '100%',
    textAlign: 'center',
    position: 'relative',
    paddingTop: '32px',
  },
  topLeftTitle: {
    position: 'absolute',
    top: 15,
    left: 15,
    textTransform: 'uppercase',
    letterSpacing: '2px',
  },
  img: {
    maxHeight: '126px',
    marginBottom: '30px',
  },
  icon: {
    '& svg': {
      height: '126px',
      width: 'auto',
      maxWidth: '180px',
      // fill: 'rgb(142, 142, 142)',
    },
    marginBottom: '16px',
    '&.multiple:first-child': {
      borderRight: `1px solid ${theme.palette.primary.main}`,
    },
  },
  title: {
    marginBottom: '4px',
    padding: '10px',
    '& span': {
      fontFamily: 'miller',
      fontStyle: 'italic',
      fontSize: '16px',
      lineHeight: '18px',
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
  },
  subtitle: {
    fontFamily: 'miller',
    fontStyle: 'italic',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '18px',
    marginTop: '-8px',
    marginBottom: '20px',
    color: theme.palette.primary.main,
  },
  imgZoomButton: {
    width: 'fit-content',
    margin: 'auto',
    marginTop: '15px',
    '& button.img-zoom-button': {
      border: '0.5px solid #C4C4C4',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
  },
  videoMessage: {
    fontFamily: 'miller',
    fontStyle: 'italic',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '18px',
    color: theme.palette.primary.main,
    '& span': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  brochure: {
    textAlign: 'center',
    fontFamily: 'miller',
    fontStyle: 'italic',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '18px',
    color: theme.palette.primary.main,
    '& span': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  badge: {
    fontSize: '14px',
    borderLeft: `2px solid ${theme.palette.primary.main}`,
    width: 'fit-content',
    margin: 'auto',
    background: theme.palette.color.white,
    padding: '8px 16px',
    fontFamily: 'miller',
    fontStyle: 'italic',
    color: theme.palette.primary.main,
    textAlign: 'left',
    lineHeight: 1,
    maxWidth: '95%',
    '& svg': {
      marginRight: '5px',
    },
    '& .badge-text': {
      color: theme.palette.color.black,
    },
    '& .badge-text-orange > span': {
      fontFamily: 'p22-underground',
      fontWeight: 700,
      fontStyle: 'normal',
    },
  },
}));

const ConfiguratorIntro = ({
  topLeftTitle,
  img,
  icon,
  title,
  subtitle,
  videoMessage,
  imgToZoom,
  showVideo,
  brochure,
  badgeText,
  badgeTextOrange,
  iconSideText,
}) => {
  const classes = useStyles();

  const multipleIcon = Array.isArray(icon);

  const handleDownload = () => {
    saveAs(brochure);
  };

  return (
    <div className={clsx(classes.configuratorIntroRoot, 'configuration-intro-root')}>
      {/* {topLeftTitle && <span className={classes.topLeftTitle}>{topLeftTitle}</span>} */}
      {img && (
        <div>
          <img className={classes.img} src={img} alt='' />
        </div>
      )}
      {isValidElement(icon) && (
        <Grid container alignItems='center' justifyContent='center'>
          <Grid className={classes.icon} item>
            {icon}
          </Grid>
          {iconSideText && <Grid item>{iconSideText}</Grid>}
        </Grid>
      )}
      {!isValidElement(icon) && !multipleIcon && (
        <div className={classes?.icon}>
          {/* da rivedere un attimo,forse cancellare */}
          {icon?.icon}
          <div>{icon?.description}</div>
        </div>
      )}
      {!isValidElement(icon) && icon && multipleIcon && (
        <Grid container justifyContent='center'>
          {icon.map((el, i) => (
            <Grid item key={i} xs={5} className={clsx(classes.icon, icon.length > 1 && 'multiple')}>
              {el.icon}
              <div>{el.description}</div>
            </Grid>
          ))}
        </Grid>
      )}

      {title && (
        <div className={classes.title}>
          <span>{title}</span>
        </div>
      )}
      {badgeText && <AlertBadge badgeText={badgeText} badgeTextOrange={badgeTextOrange} />}
      {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
      {imgToZoom && (
        <div className={classes.imgZoomButton}>
          <ImageZoomButton
            imageSrc={imgToZoom}
            className='img-zoom-button'
            label={'Foto'}
            icon={<Camera color='#878787' width='15px' height='15px' />}
          />
        </div>
      )}
      {videoMessage && (
        <div className={classes.videoMessage}>
          {videoMessage} <span onClick={showVideo}>GUARDA IL VIDEO!</span>
        </div>
      )}
      {brochure && (
        <div className={classes.brochure}>
          oppure <span onClick={handleDownload}>scarica la brochure</span>
        </div>
      )}
    </div>
  );
};

export default ConfiguratorIntro;
