import * as React from 'react';

function SvgFinestraTrasparente(props) {
  return (
    <svg viewBox='0 0 71 93' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M46.334 67.66l-24.17-36.34.22-.15 24.15 36.34-.2.15zm2.41-8.83l-24.18-36.32.23-.14 24.14 36.32-.19.14z'
        fill='#AFAFAF'
      />
      <path
        d='M.336 92.15h70.43V.51H.336v91.64zm1.38-90.27h67.67v88.89H1.716V1.88z'
        fill='#AFAFAF'
      />
      <path
        d='M22.393 31.165l-.225.15 24.144 36.34.225-.15-24.144-36.34zM24.8 22.35l-.226.15L48.72 58.84l.225-.15-24.145-36.34z'
        fill='#AFAFAF'
      />
    </svg>
  );
}

export default SvgFinestraTrasparente;
