import { Box, ButtonBase, Grid, makeStyles, Modal } from '@material-ui/core';
import clsx from 'clsx';
import SvgFile from './Icons/File';
import OutlinePlus from './Icons/OutlinePlus';
import SvgLetterI from './Icons/LetterI';
import SvgSave from './Icons/Save';
import Countdown from 'react-countdown';
import { useHistory, useLocation } from 'react-router';
import { useStore } from '../store/storeUtils';
import { Configurations } from './Icons';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';
import InfoPopup, { ExitContent, WarningContent } from './InfoPopup';
import Tutorial from './Tutorial';
import projectConfig from '../configs/projectConfig';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    background: '#FFF',
    position: 'relative',
    boxShadow: '0 0 0.5rem 0 rgba(0, 0, 0, 0.2)',
  },
  title: {
    position: 'absolute',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    fontSize: '48px',
    fontWeight: '800',
    height: '100%',
    '& img': {
      height: '100%',
      cursor: 'pointer',
    },
  },
  menuItemContainer: {
    borderRight: `1px solid ${theme.palette.color.grey1}`,
    minWidth: '87px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    '&:first-child': {
      borderLeft: `1px solid ${theme.palette.color.grey1}`,
    },
    cursor: 'pointer',
    userSelect: 'none',
    '& span': {
      padding: '2px',
    },
    [theme.breakpoints.down('lg')]: {
      minWidth: '76px',
    },
    [theme.breakpoints.down('md')]: {
      minWidth: '65px',
    },
    '&.current-info-container': {
      padding: '0 15px',
      alignItems: 'center',
      borderLeft: 'solid 5px #F08219',
      borderRight: 'solid 1px #F08219',
      backgroundColor: '#f9f4f0',
      '& .current-info-apartment': {
        fontFamily: 'miller',
        color: theme.palette.primary.main,
        fontStyle: 'italic',
        textTransform: 'capitalize',
        fontSize: '18px',
      },
      '& .current-info-configuration': {
        fontWeight: '700',
        fontSize: '11px',
      },
    },
  },
  menuButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  menuItem: {
    // gap: '15px',
    height: '100%',
    padding: '0 15px',
    fontWeight: 300,
    fontSize: '13px',
    lineHeight: '16px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      padding: '0 10px',
      gap: '5px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '11px',
      lineHeight: '13px',
    },
  },
  menuItemLabel: {
    marginBottom: '4px',
    padding: '0 4px',
    // fontFamily: 'Lato',
    fontWeight: 500,
    fontSize: '9px',
    lineHeight: '11px',
    textTransform: 'uppercase',
    [theme.breakpoints.down('md')]: {
      fontSize: '8px',
      lineHeight: '10px',
    },
  },
  fullHeight: {
    height: '100%',
  },
  sideContainer: {
    zIndex: 2,
  },
  backArrow: {
    color: theme.palette.primary.main,
  },
  backToMyAi: {
    [theme.breakpoints.down('lg')]: {
      maxWidth: '110px',
      textAlign: 'center',
      lineHeight: '18px',
    },
  },
  menuIconContainer: {
    height: '30px',
    [theme.breakpoints.down('lg')]: {
      height: '20px',
    },
    '& svg': {
      height: '100%',
      width: 'auto',
      marginRight: '10px',
    },
  },
  labelsContainer: {
    textAlign: 'left',
  },
  label: {
    fontSize: '13px',
    lineHeight: '15px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '11px',
      lineHeight: '13px',
    },
  },
  subLabel: {
    fontSize: '11px',
    lineHeight: '13px',
    color: theme.palette.color.green,
    maxWidth: '115px',
    '&.disabled': {
      color: theme.palette.color.disabledGreyText,
      fontSize: '11px',
    },
  },
  iconContainer: {
    height: '100%',
    position: 'relative',
  },
  iconIndicator: {
    height: '12px',
    width: '12px',
    position: 'absolute',
    bottom: '-2px',
    right: '-2px',
    borderRadius: '100%',
    backgroundColor: theme.palette.color.disabledGreyText,
    '&.no-background': {
      backgroundColor: '#FFF',
    },
    [theme.breakpoints.down('lg')]: {
      height: '7px',
      width: '7px',
    },
    '& svg': {
      position: 'absolute',
      top: 0,
      left: 0,
    },
  },
  infoButton: {
    width: '28px',
    height: '28px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '22px',
      height: '22px',
    },
    '& svg': {
      height: '14px',
      width: 'auto',
      [theme.breakpoints.down('md')]: {
        height: '10px',
      },
    },
    '& span': {
      paddingTop: '6px',
      fontWeight: 500,
      fontSize: '22px',
      color: theme.palette.color.white,
      [theme.breakpoints.down('md')]: {
        height: '12px',
        fontSize: '16px',
        paddingTop: '2px',
      },
    },
  },
  cartButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '50px',
    height: '50px',
    backgroundColor: '#F9F3EE',
    borderRadius: '50%',
    border: '1px solid #8e8e8e',
    [theme.breakpoints.down('lg')]: {
      width: '40px',
      height: '40px',
    },
    [theme.breakpoints.down('md')]: {
      width: '30px',
      height: '30px',
    },
    '& svg': {
      height: 'auto',
      width: '60%',
      [theme.breakpoints.down('lg')]: {
        width: '50%',
      },
      [theme.breakpoints.down('md')]: {
        width: '40%',
      },
    },
  },
  cartNumber: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '19px',
    height: '19px',
    position: 'absolute',
    bottom: '-4px',
    right: '-3px',
    paddingTop: '2px',
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.main,
    color: '#FFF',
    fontWeight: 'bold',
    fontSize: '13px',
    lineHeight: '16px',
    [theme.breakpoints.down('lg')]: {
      width: '17px',
      height: '17px',
    },
    [theme.breakpoints.down('md')]: {
      width: '12px',
      height: '12px',
      fontSize: '10px',
      lineHeight: '13px',
    },
  },
  countdownContainer: {
    fontFamily: 'miller',
    color: theme.palette.primary.main,
    fontSize: '13px',
    lineHeight: '16px',
    display: 'block',
    fontStyle: 'italic',
  },
  countdown: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  countdownElement: {
    width: '50px',
  },
  countdownNumber: {
    fontFamily: 'p22-underground',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '22px',
    color: '#EBE4E0',
    position: 'relative',
  },
  countdownSeparator: {
    alignSelf: 'baseline',
    fontWeight: 600,
    fontSize: '18px',
    // lineHeight: '22px',
    color: '#EBE4E0',
  },
}));

const SaveIcon = ({ disabled }) => {
  const classes = useStyles();

  return (
    <div className={classes.iconContainer}>
      <SvgSave />
      {/* <div className={clsx(classes.iconIndicator)}>{!disabled && <SvgCheckOk />}</div> */}
    </div>
  );
};
const FileIcon = () => {
  const classes = useStyles();

  return (
    <div className={classes.iconContainer}>
      <SvgFile color='#000' />
      <div className={clsx(classes.iconIndicator, 'no-background')}>
        {<OutlinePlus color='#76B82D' />}
      </div>
    </div>
  );
};

const MenuButton = ({ children, onClick, label }) => {
  const classes = useStyles();

  return (
    <ButtonBase className={classes.menuButtonContainer} onClick={onClick}>
      <div className={classes.menuItem}>{children}</div>
      {label && <span className={classes.menuItemLabel}>{label}</span>}
    </ButtonBase>
  );
};

const CartButton = ({ cartElementsNumber }) => {
  const classes = useStyles();

  return (
    <div className={classes.cartButton}>
      <Configurations color={'#000'} />

      {cartElementsNumber > 0 && <div className={classes.cartNumber}>{cartElementsNumber}</div>}
    </div>
  );
};

const MenuIconButton = ({ icon, label, subLabel, onClick, disabled }) => {
  const classes = useStyles();

  return (
    <ButtonBase onClick={onClick} className={clsx(classes.menuItem, classes.menuItemIcon)}>
      <div className={clsx(classes.menuIconContainer)}>{icon}</div>
      <div className={clsx(classes.labelsContainer)}>
        <div className={clsx(classes.label)}>{label}</div>
        <div className={clsx(classes.subLabel, disabled && 'disabled')}>
          {disabled ? 'Qui potrai salvare le tue configurazioni' : subLabel}
        </div>
      </div>
    </ButtonBase>
  );
};

const ConfigurationCountdown = ({ onClick, startDate, durationMonths }) => {
  const classes = useStyles();

  const CountdownElement = ({ number, label }) => {
    return (
      <div className={clsx(classes.countdownElement)}>
        <div className={clsx(classes.countdownNumber)}>{number}</div>
        <div>{label}</div>
      </div>
    );
  };

  const countdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
    return (
      <div className={clsx(classes.countdown)}>
        <CountdownElement number={days} label={'Giorni'} />
        <span className={classes.countdownSeparator}>:</span>
        <CountdownElement number={hours} label={'Ore'} />
        <span className={classes.countdownSeparator}>:</span>
        <CountdownElement number={minutes} label={'Minuti'} />
        <span className={classes.countdownSeparator}>:</span>
        <CountdownElement number={seconds} label={'Secondi'} />
      </div>
    );
  };

  const date = dayjs(startDate).add(durationMonths, 'M');

  return (
    <ButtonBase onClick={onClick} className={clsx(classes.menuItem, classes.countdownContainer)}>
      <Box marginBottom={'6px'}>Termine massimo configurazione</Box>
      <Countdown date={date} renderer={countdownRenderer} />
    </ButtonBase>
  );
};

const Menu = observer(() => {
  const classes = useStyles();
  const history = useHistory();
  const store = useStore();
  const location = useLocation();

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showExitConfirm, setShowExitConfirm] = useState(false);
  const [userIsInConfigurator, setUserIsInConfigurator] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);

  const handleResumeButton = () => {
    if (store.currentApartment && store.currentConfiguration) {
      history.push(
        `/apartment/${store.currentApartment.id}/${store.currentConfiguration.id}/resume`,
      );
    } else if (store.currentApartment) {
      history.push(`/apartment/${store.currentApartment.id}`);
    } else {
      history.replace(`/apartments`);
    }
  };

  const handleSaveAndGoHome = async () => {
    if (store.substepSave) {
      const resumeInfo = store.getResumeInfo();
      const currentSubstep = store.currentSubstep;

      if (resumeInfo?.substep?.frontendId - 1 > currentSubstep || resumeInfo?.allCompleted) {
        setShowConfirmModal(true);
      } else {
        handleSaveAndGoHomeConfirm();
      }
    } else {
      history.push(`/apartment/${store.currentApartment.id}`);
    }
  };

  const handleSaveAndGoHomeConfirm = async () => {
    setShowConfirmModal(false);
    await store.substepSave();
    history.push(`/apartment/${store.currentApartment.id}`);
  };

  const handleExit = async () => {
    setShowExitConfirm(false);
    document.location.href = projectConfig[process.env.REACT_APP_PROJECT_ID]?.myAiUrl;
  };

  useEffect(() => {
    // Per prevenire il back del browser manuale dell'utente
    return history.listen(() => {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener('popstate', function () {
        window.history.pushState(null, document.title, window.location.href);
      });
    });
  }, []);

  useEffect(() => {
    setUserIsInConfigurator(location.pathname.includes('/configurator/'));
  }, [location]);

  return (
    <>
      <Grid
        container
        className={classes.container}
        justifyContent='space-between'
        alignItems='center'
      >
        <div className={classes.title} onClick={() => history.push('/apartments')}>
          <img src={`${process.env.PUBLIC_URL}/assets/images/logo.png`} style={{paddingTop: '3px', paddingBottom: '3px'}} alt='AbitareIn' />
        </div>
        <Grid item className={clsx(classes.fullHeight, classes.sideContainer)}>
          <Grid container className={classes.fullHeight}>
            {!store.currentConfiguration && (
              <Grid item className={clsx(classes.menuItemContainer, classes.backToMyAi)}>
                <MenuButton onClick={() => setShowExitConfirm(true)}>
                  <span>
                    TORNA A <b>MY AI</b>
                  </span>
                </MenuButton>
              </Grid>
            )}
            {store.currentConfiguration && (
              <Grid item className={clsx(classes.menuItemContainer, 'current-info-container')}>
                <div>
                  <div className={'current-info-apartment'}>
                    {store.currentApartment?.apartment?.value?.toLowerCase()}
                  </div>
                  <div className={'current-info-configuration'}>
                    {store.currentConfiguration?.name}
                  </div>
                </div>
              </Grid>
            )}
            {store.currentConfiguration && (
              <Grid item className={classes.menuItemContainer}>
                <MenuIconButton
                  onClick={handleSaveAndGoHome}
                  icon={userIsInConfigurator ? <SaveIcon /> : ''}
                  label={
                    userIsInConfigurator ? (
                      <span>
                        SALVA E TORNA
                        <br /> ALLA HOME
                      </span>
                    ) : (
                      <span>HOME</span>
                    )
                  }
                  // subLabel={store.currentConfiguration?.name}
                />
              </Grid>
            )}
            {store.currentConfiguration && !location?.pathname.includes('/resume') && (
              <Grid item className={classes.menuItemContainer}>
                <MenuIconButton
                  onClick={handleResumeButton}
                  icon={<Configurations color='#000' />}
                  label={<span>RIEPILOGO</span>}
                  // subLabel={store.currentConfiguration?.name}
                />
              </Grid>
            )}
            {/* {store.currentApartment && configurations < 4 && (
            <Grid item className={classes.menuItemContainer}>
              <MenuIconButton
                icon={<FileIcon />}
                label={
                  <span>
                    <b>NUOVO</b>
                  </span>
                }
                onClick={handleNewConfiguration}
              />
            </Grid>
          )} */}
            {/* {store.currentConfiguration && (
            <Grid item className={classes.menuItemContainer}>
              <MenuButton onClick={handleCartButtonClick} label='Recap scelte'>
                <CartButton cartElementsNumber={configurations} />
              </MenuButton>
            </Grid>
          )} */}
          </Grid>
        </Grid>
        <Grid item className={clsx(classes.fullHeight, classes.sideContainer)}>
          <Grid container className={classes.fullHeight}>
            {/* {store.currentConfiguration && (
              <Grid item className={classes.menuItemContainer}>
                <ConfigurationCountdown
                  startDate={store.currentConfiguration?.creationDate}
                  durationMonths={1}
                />
              </Grid>
            )} */}
            <Grid item className={classes.menuItemContainer}>
              <MenuButton onClick={() => history.push('/faq')} label='Faq'>
                <div className={classes.infoButton}>
                  <span>?</span>
                </div>
              </MenuButton>
            </Grid>
            <Grid item className={classes.menuItemContainer}>
              <MenuButton onClick={() => setShowTutorial(true)} label='Tutorial'>
                <div className={classes.infoButton}>
                  <SvgLetterI />
                </div>
              </MenuButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Modal open={showConfirmModal} onClose={() => setShowConfirmModal(false)}>
        <InfoPopup
          body={
            <WarningContent
              handleSubmit={handleSaveAndGoHomeConfirm}
              handleCancel={() => setShowConfirmModal(false)}
            />
          }
          noMaxWidth
          isWarning
          position='center'
          handleClose={() => setShowConfirmModal(false)}
        />
      </Modal>
      <Modal open={showExitConfirm} onClose={() => setShowExitConfirm(false)}>
        <InfoPopup
          body={
            <ExitContent handleSubmit={handleExit} handleCancel={() => setShowExitConfirm(false)} />
          }
          noMaxWidth
          isWarning
          position='center'
          handleClose={() => setShowExitConfirm(false)}
        />
      </Modal>

      {showTutorial && <Tutorial handleClose={() => setShowTutorial(false)} />}
    </>
  );
});

export default Menu;
