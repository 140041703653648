import { lighten, makeStyles } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  iconButtonRoot: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'transparent',
    color: theme.palette.color.black,
    fontWeight: 500,
    textTransform: 'uppercase',
    borderRadius: '40px',
    transition: 'all 0.2s ease-in-out',
    '& .text': {
      margin: '0 auto',
      fontSize: '11px',
      padding: ({ icon }) => (icon ? '3px 14px 0' : '14px 14px 12px'),
      fontWeight: 600,
      whiteSpace: 'nowrap',
      color: ({ icon }) => (icon ? 'currentColor' : '#fff'),
      letterSpacing: '1px',
      [theme.breakpoints.down('md')]: {
        whiteSpace: 'normal',
      },
    },
    '& .longText': {
      margin: '0 auto',
      fontSize: '8px',
      padding: ({ icon }) => (icon ? '1px 8px 0' : '14px 14px 12px'),
      fontWeight: 600,
      whiteSpace: 'nowrap',
      color: ({ icon }) => (icon ? 'currentColor' : '#fff'),
      letterSpacing: '1px',
      [theme.breakpoints.down('md')]: {
        whiteSpace: 'normal',
      },
    },
    '& .icon': {
      width: '35px',
      height: '35px',
      padding: '8px ',
      color: ({ filled }) => (filled ? theme.palette.color.white : theme.palette.primary.main),
      borderRight: ({ filled }) =>
        `1px solid ${filled ? theme.palette.color.white : theme.palette.primary.main}`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    '&.orange': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.color.white,
      '&:active': {
        backgroundColor: lighten(theme.palette.primary.main, 0.4),
      },
    },
    '&.transparent': {
      backgroundColor: 'transparent',
      color: theme.palette.color.black,
      border: `1px solid ${theme.palette.primary.main}`,
      '&:active': {
        opacity: 0.7,
      },
    },

    '&.selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.color.white,
      // pointerEvents: 'none',
      '& [class*="icon"]': {
        color: theme.palette.color.white,
        borderRight: `1px solid ${theme.palette.color.white}`,
      },
      '&:before': {
        opacity: 1,
      },
    },

    '&:hover': {
      backgroundColor: `${lighten(theme.palette.primary.main, 0.3)}`,
      color: theme.palette.color.white,
      '& .icon': {
        color: theme.palette.color.white,
      },
    },
  },
}));

const IconButton = ({ text, icon, filled, onClick, className, isSelected, longText }) => {
  const classes = useStyles({ icon, filled });

  const select = () => {
    if (!filled) filled = true;
    if (onClick) {
      onClick();
    }
  };
  return (
    <ButtonBase
      className={clsx(
        classes.iconButtonRoot,
        filled ? 'orange' : 'transparent',
        isSelected && 'selected',
        className,
      )}
      onClick={select}
      disableRipple
    >
      {icon ? <span className={'icon'}>{icon}</span> : ''}
      <span className={longText ? 'longText' : 'text'}>{text}</span>
    </ButtonBase>
  );
};

export default IconButton;
