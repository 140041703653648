import * as React from 'react';

function SvgFinestraDimensionePrima(props) {
  return (
    <svg viewBox="0 0 148 174" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M148 173.86H0V0H148V173.86ZM1.37 172.51H146.63V1.36H1.37V172.51Z" fill="#AFAFAF"/>
      <path d="M141.23 167.11H6.76953V6.75977H141.23V167.11ZM8.12953 165.75H139.88V8.10977H8.12953V165.75Z" fill="#AFAFAF"/>
      <path d="M78.0592 7.9707H76.6992V166.141H78.0592V7.9707Z" fill="#AFAFAF"/>
      <path d="M71.3014 7.9707H69.9414V166.141H71.3014V7.9707Z" fill="#AFAFAF"/>
    </svg>
  );
}

export default SvgFinestraDimensionePrima;
