import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { Alert } from '../Icons';

const useStyles = makeStyles((theme) => ({
  badge: {
    fontSize: '12px',
    borderLeft: `2px solid ${theme.palette.primary.main}`,
    width: 'fit-content',
    margin: 'auto',
    background: theme.palette.color.white,
    padding: '8px 16px',
    fontFamily: 'miller',
    fontStyle: 'italic',
    color: theme.palette.primary.main,
    textAlign: 'left',
    lineHeight: 1,
    maxWidth: '95%',
    '& svg': {
      marginRight: '5px',
    },
    '& .badge-text': {
      color: theme.palette.color.black,
    },
    '& .badge-text-orange > span': {
      fontFamily: 'p22-underground',
      fontWeight: 700,
      fontStyle: 'normal',
    },
  },
}));

const AlertBadge = ({ badgeText, badgeTextOrange, className }) => {
  const classes = useStyles();

  return (
    <Grid container className={clsx(classes.badge, className)}>
      {badgeTextOrange ? (
        <>
          <Grid item xs='auto'>
            <Alert color='currentColor' width='15px' height='15px' />
          </Grid>
          <Grid item xs>
            <div className={'badge-text-orange'}>
              <span>Attenzione! </span>
              {badgeTextOrange}
            </div>
            <div className={'badge-text'}>{badgeText}</div>
          </Grid>
        </>
      ) : (
        <Grid item xs className={'badge-text'}>
          {badgeText}
        </Grid>
      )}
    </Grid>
  );
};

export default AlertBadge;
