import { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  useTheme,
  useMediaQuery,
  alpha,
} from '@material-ui/core';
import { Apartment, ChevronOutline, OutlinePlus, User } from './Icons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    '&:first-child': {
      marginBottom: '30px',
      [theme.breakpoints.down('md')]: {
        marginBottom: '20px',
      },
    },
    '&:last-child': {
      paddingBottom: '10px',
    },
    '& .MuiCollapse-root': {
      width: '100%',
    },
  },
  accordionSummary: {
    width: '100%',
    minHeight: 0,
    margin: 0,
    // marginBottom: '55px',
    padding: 0,
    borderBottom: '1px solid #c4c4c4',
    // [theme.breakpoints.down('lg')]: {
    //   marginBottom: '52px',
    // },
    // [theme.breakpoints.down('md')]: {
    //   marginBottom: '30px',
    // },
    // [theme.breakpoints.down('sm')]: {
    //   marginBottom: '24px',
    // },
  },
  header: {
    width: '100%',
    fontSize: '13px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    '& .first-part': {
      fontWeight: 500,
    },
    '& .second-part': {
      fontWeight: 750,
    },
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    minHeight: 0,
    margin: 0,
    padding: '23px 0 0 26px',
    backgroundColor: theme.palette.color.white,
    fontWeight: '500',
    fontSize: '13px',
    lineHeight: '16px',
    color: theme.palette.color.main,
    [theme.breakpoints.down('lg')]: {
      padding: '22px 0 0 15px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '12px 0 0 12px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0 0 10px',
    },

    '& .configuration': {
      marginBottom: '8px',
      color: theme.palette.primary.main,
      fontFamily: 'miller',
      fontStyle: 'italic',
      cursor: 'pointer',
      [theme.breakpoints.down('lg')]: {
        marginBottom: '7px',
      },
      [theme.breakpoints.down('md')]: {
        marginBottom: '3px',
      },
      '&:last-child': {
        marginTop: '4px',
      },
      '&.selected': {
        color: alpha(theme.palette.primary.main, 0.4),
      },
    },
    '& .new-configuration': {
      color: theme.palette.primary.main,
      textTransform: 'uppercase',
      cursor: 'pointer',
    },
    '& svg.icon': {
      marginRight: '6px',
      [theme.breakpoints.down('lg')]: {
        marginRight: '5px',
      },
      [theme.breakpoints.down('md')]: {
        marginRight: '3px',
      },
    },
  },
  heading: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    marginBottom: '6px',
    '& svg': {
      marginRight: '13px',
      [theme.breakpoints.down('lg')]: {
        marginRight: '10px',
      },
      [theme.breakpoints.down('md')]: {
        marginRight: '8px',
      },
      [theme.breakpoints.down('sm')]: {
        marginRight: '7px',
      },
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    fontSize: '13px',
    lineHeight: '16px',
    marginBottom: '20px',
    [theme.breakpoints.down('lg')]: {
      marginBottom: '19px',
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: '15px',
    },
    '& .title': {
      marginBottom: '8px',
      paddingLeft: '13px',
      textTransform: 'uppercase',
      borderLeft: `1px solid ${theme.palette.color.black}`,
      [theme.breakpoints.down('lg')]: {
        marginBottom: '7px',
        paddingLeft: '10px',
      },
      [theme.breakpoints.down('md')]: {
        marginBottom: '6px',
        paddingLeft: '8px',
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: '5px',
        paddingLeft: '7px',
      },
      '& .first-part': {
        fontWeight: 500,
      },
      '& .second-part': {
        fontWeight: 750,
      },
    },
    '& .body': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingLeft: '15px',
      fontWeight: 600,
      fontSize: '15px',
      lineHeight: '18px',
      borderLeft: `1px solid transparent`,
      [theme.breakpoints.down('lg')]: {
        paddingLeft: '10px',
      },
      [theme.breakpoints.down('md')]: {
        paddingLeft: '8px',
        fontSize: '13px',
        lineHeight: '16px',
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '7px',
      },
      '& span': {
        marginBottom: 0,
      },
    },
  },
  subAccordion: {
    width: '100%',
    marginBottom: '30px !important',
    [theme.breakpoints.down('md')]: {
      marginBottom: '20px !important',
    },
  },
  normalize: {
    minHeight: '0 !important',
    margin: '0 !important',
    padding: '0 !important',
  },
  fullWidth: {
    minWidth: '100%',
  },
}));

const styleText = (text) => {
  const parts = text.split(' ');
  const secondPart = parts.pop();
  const firstPart = parts.join(' ');

  return (
    <>
      <span className='first-part'>{firstPart}</span>{' '}
      <span className='second-part'>{secondPart}</span>
    </>
  );
};

const InfoBox = ({ data, extraInfo, selectedConfiguration, setSelectedConfiguration }) => {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const [expanded, setExpanded] = useState(false);
  const [subExpanded, setSubExpanded] = useState(false);

  const handleChange = (panel, level) => (event, isExpanded) => {
    level === 'top'
      ? setExpanded(isExpanded ? panel : false)
      : setSubExpanded(isExpanded ? panel : false);
  };

  const [configurations, setConfigurations] = useState();
  const addConfiguration = (apt) => {
    const config = { ...configurations };
    if (config[apt]) {
      config[apt].push('Configurazione');
    } else {
      config[apt] = [];
      config[apt].push('Configurazione');
    }
    setConfigurations(config);
  };

  return (
    <>
      <InfoContent
        category='pesonal'
        level='top'
        title={largeScreen ? styleText('Le tue informazioni') : styleText('I tuoi dati')}
        expanded={expanded}
        handleChange={handleChange}
      >
        <InfoItem
          icon={<User color='currentColor' width='18px' height='18px' className='icon' />}
          simpleItem
          category='Nome cognome'
          content='Sandra Nandra'
        />
      </InfoContent>

      <InfoContent
        category='apartments'
        level='top'
        title={styleText('I tuoi appartamenti')}
        expanded={expanded}
        handleChange={handleChange}
      >
        {['trilocale comfort', 'quadrilocale comfort'].map((title, i) => (
          <>
            {extraInfo ? (
              <InfoContent
                category={title}
                level='sub'
                key={i}
                title={
                  <InfoItem
                    title={title}
                    icon={
                      <Apartment color='currentColor' width='18px' height='18px' className='icon' />
                    }
                    category='Modulo ordine'
                    content='12312311523'
                  />
                }
                expanded={subExpanded}
                handleChange={handleChange}
              >
                <>
                  {configurations[title] &&
                    configurations[title].map((value, i2) => (
                      <span
                        key={i2}
                        className={`configuration ${
                          selectedConfiguration === `${value} ${i + 1}` ? 'selected' : ''
                        }`}
                        onClick={() => setSelectedConfiguration(`${value} ${i + 1}`)}
                      >
                        {value} {i + 1}
                      </span>
                    ))}
                  <span className='new-configuration' onClick={() => addConfiguration(title)}>
                    <OutlinePlus color={theme.palette.primary.main} className='icon' />
                    Crea nuova configurazione
                  </span>
                </>
              </InfoContent>
            ) : (
              <InfoItem
                key={i}
                title={title}
                icon={
                  <Apartment color='currentColor' width='18px' height='18px' className='icon' />
                }
                category='Modulo ordine'
                content='12312311523'
              />
            )}
          </>
        ))}
      </InfoContent>
    </>
  );
};

export default InfoBox;

const InfoItem = ({ title, icon, simpleItem, category, content }) => {
  const classes = useStyles();
  return (
    <div className={classes.heading}>
      {icon}
      {simpleItem ? (
        <div className={classes.content}>
          <span className='title'>{category}</span>
          <span className='body'>{content}</span>
        </div>
      ) : (
        <div className={classes.content}>
          <span className='title'>{styleText(title)}</span>
          <span className='body'>
            <span>{category}</span>
            <span>{content}</span>
          </span>
        </div>
      )}
    </div>
  );
};

const InfoContent = ({ category, level, title, expanded, handleChange, children }) => {
  const classes = useStyles();
  const theme = useTheme();

  const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Accordion
      expanded={largeScreen ? true : expanded === category}
      onChange={handleChange(category, level)}
      elevation={0}
      className={classes.root}
    >
      <AccordionSummary
        expandIcon={
          !largeScreen ? (
            <ChevronOutline color={theme.palette.primary.main} width='14px' height='14px' />
          ) : (
            ''
          )
        }
        aria-controls='panel1bh-content'
        id='panel1bh-header'
        className={classes.accordionSummary}
        classes={{
          //   root: classes.normalize,
          expanded: classes.normalize,
          content: classes.normalize,
          expandIcon: classes.normalize,
        }}
      >
        <span className={classes.header}>{title}</span>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails} classes={{ root: classes.fullWidth }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};
