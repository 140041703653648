import * as React from 'react';

function SvgCameraNew(props) {
  return (
    <svg viewBox='0 0 63 43' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#cameraNew_svg__clip0_2448_23024)'>
        <path
          d='M61.58 32.24h-1.51v-6.59a5.1 5.1 0 00-3.69-4.93V8.34A8.33 8.33 0 0048.09 0h-34.1a8.33 8.33 0 00-8.28 8.34v12.3a5.1 5.1 0 00-3.89 5v6.59H.5a.51.51 0 00-.5.5v4.87a.5.5 0 00.5.5h1.32v3.42a.5.5 0 00.5.5h4.9a.5.5 0 00.5-.5V38.1h46.72v3.42a.5.5 0 00.5.5h4.83a.5.5 0 00.5-.5V38.1h1.31a.5.5 0 00.5-.5v-4.87a.5.5 0 00-.5-.49zM3.82 25.65a3.14 3.14 0 013.13-3.15h48a3.14 3.14 0 013.12 3.15v6.59H3.83l-.01-6.59zm8.86-5.17v-2.67a3.35 3.35 0 013.33-3.36h10.74a3.35 3.35 0 013.32 3.36v2.67H12.68zm19.33 0v-2.67a3.35 3.35 0 013.33-3.36h10.74a3.35 3.35 0 013.32 3.36v2.67H32.01zm22.43 0h-3v-2.67a5.277 5.277 0 00-5.27-5.3H35.43a5.252 5.252 0 00-4.3 2.21 5.27 5.27 0 00-4.29-2.21H16.1a5.279 5.279 0 00-5.26 5.3v2.67h-3V8.33a6.32 6.32 0 016.22-6.38h34.08a6.48 6.48 0 016.36 6.39l-.06 12.14zm1.94 19.54v-1.91h1.88v1.94l-1.88-.03zm-52.56 0v-1.91h1.89v1.94l-1.89-.03zm56.32-3.92H1.94v-1.91h58.2v1.91z'
          fill='#C4C4C4'
        />
      </g>
      <defs>
        <clipPath id='cameraNew_svg__clip0_2448_23024'>
          <path fill='#fff' d='M0 0h62.08v42.03H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgCameraNew;
