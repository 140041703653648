import { useState } from 'react';
import { Dialog, Grid, makeStyles } from '@material-ui/core';
import Button3D from '../Buttons/Button3D';
import { Exit } from '../Icons';
import SvgLetterI from '../Icons/LetterI';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { ticketRequestJWT, createTicket } from '../../services/faqTicket/faqTicket';

const useStyles = makeStyles((theme) => ({
  newTicketDialogRoot: {
    backgroundColor: 'transparent',
    //overflow: 'auto',
    '& .paper': {
      backgroundColor: 'transparent',
    },
    '& .dialog-container': {
      padding: 0,
      flexWrap: 'nowrap',
      // overflow: 'auto',
      width: 'calc(100% - 28px)',
    },
    '& .exit-icon': {
      position: 'absolute',
      right: 0,
      top: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '20px',
      height: '20px',
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50%',
      cursor: 'pointer',
    },
    '& .dialog-content': {
      padding: '24px 0',
      textAlign: 'center',
      '& > div': {
        backgroundColor: '#FFF',
        height: '100%',
        padding: '16px 32px',
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
      },
    },
    '& .info-icon': {
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: '100%',
      aspectRatio: 1,
      padding: 4,
      display: 'inline-flex',
      marginBottom: 16,
      '& path': {
        fill: '#000',
      },
    },
    '& .dialog-title': {
      color: theme.palette.primary.main,
      fontSize: 16,
      textAlign:'left',
    },
    '& .dialog-body': {
      borderTop: '1px solid #e0e0e0',
      padding: '24px 0',
      borderBottom: '1px solid #e0e0e0',
      marginBottom: 24,
      textAlign: 'left',
      // overflow: 'auto',
      '& .form-control': {
        width: '50%',
        // overflow: 'auto',
        '& .form-select': {
          width: '100%',
        },
        '& .form-control-label': {
          paddingLeft: '4px',
        },
        '& .form-textField': {
          marginTop: '15px',
        },
      }
    },
    '& .dialog-action-cancel': {
      marginTop: 32,
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      fontSize: 12,
      cursor: 'pointer',
    },
  },
}));

const NewTicketRequest = ({
  isOpen,
  handleClose,
  projectShortName,
  setSucceded,
  setIsOpen
}) => {
  const classes = useStyles();
  const [tipologia, setTipologia] = useState('');
  const [domanda, setDomanda] = useState('');
  const [errorTipologia, setErrorTipologia] = useState(false);
  const [errorDomanda, setErrorDomanda] = useState(false);

  const handleConfirm = async () => {
    if(domanda === '') setErrorDomanda(true)
    if(tipologia === '') setErrorTipologia(true)
    if(!errorDomanda && !errorTipologia && process.env.REACT_APP_CAN_TICKETING === 'true') {
      const token = await ticketRequestJWT();
      const success = await createTicket(tipologia, domanda, projectShortName, token);
      if(success === 'true') {
        setIsOpen(false);
        setSucceded(true);
      }
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      classes={{ root: classes.newTicketDialogRoot }}
      PaperProps={{
        elevation: 0,
        classes: {
          root: 'paper',
        },
      }}
    >
      <div onClick={handleClose} className={'exit-icon'}>
        <Exit color='#fff' width='8px' height='8px' />
      </div>
      <Grid container className={'dialog-container'}>
        <Grid item xs={12} className={'dialog-content'}>
          <div>
            <div className={'info-icon'}>
              <SvgLetterI />
            </div>
            <div className={'dialog-title'}>
              Se non hai trovato la risposta alla tua domanda puoi inviare una
              richiesta al nostro team che provvederà a contattarti quanto prima.
            </div>
            <div className={'dialog-body'}>
              <FormControl className={'form-control'} variant="outlined">
                <InputLabel className={'form-control-label'} id="demo-simple-select-label">Tipologia: </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={tipologia}
                  onChange={(e) => {
                    setTipologia(e.target.value);
                    if(e.target.value !== '') setErrorTipologia(false)
                  }}
                  className={'form-select'}
                  label="Tipologia"
                  error={errorTipologia}
                >
                  <MenuItem value={'Chiarimento su utilizzo software'}>Chiarimento su utilizzo software</MenuItem>
                  <MenuItem value={'Malfunzionamento software'}>Malfunzionamento software</MenuItem>
                </Select>
                <TextField
                  id="standard-multiline-static"
                  label="Inserisci descrizione"
                  multiline
                  variant="outlined"
                  className={'form-textField'}
                  value={domanda}
                  onChange={(e) => {
                    setDomanda(e.target.value);
                    if(e.target.value !== '') setErrorDomanda(false);
                  }}
                  error={errorDomanda}
                />
                <FormHelperText>* Entrambi i campi devono essere compilati</FormHelperText>
              </FormControl>
            </div>
            <div className='dialog-action'>
              <Button3D
                text={'Crea nuovo ticket'}
                normalWeightText
                onClick={handleConfirm}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default NewTicketRequest;
