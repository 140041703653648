import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useStore } from '../../store/storeUtils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    height: '60px',
    paddingLeft: '12px',
    paddingRight: '34px',
    backgroundColor: theme.palette.color.brownGrey2,
    cursor: 'pointer',
    lineHeight: '18px',
    userSelect: 'none',
    color: theme.palette.color.grey2,
    borderRadius: '4px',
    '&.completed': {
      backgroundColor: theme.palette.color.brownGrey3,
      color: theme.palette.color.black,
    },
    '&.active': {
      backgroundColor: theme.palette.color.white,
      color: theme.palette.primary.main,
      cursor: 'default',
      pointerEvents: 'none',
    },
    '&.blocked': {
      backgroundColor: theme.palette.color.brownGrey2,
      color: theme.palette.color.grey2,
      cursor: 'default',
      pointerEvents: 'none',
      '&.enabled': {
        pointerEvents: 'initial',
        cursor: 'pointer',
      },
    },
    [theme.breakpoints.down('md')]: {
      height: '50px',
      fontSize: '13px',
      lineHeight: '16px',
      paddingRight: '15px',
    },
  },
  shape: {
    clipPath: 'polygon(0 0, 90% 0%, 100% 100%, 0% 100%)',
  },
  borderedActive: {
    width: '108px',
    height: '40px',
    paddingLeft: '10px',
    paddingRight: '10px',
    border: `1px solid ${theme.palette.color.grey2}`,
    '&:before': {
      content: "''",
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: '5px',
      backgroundColor: theme.palette.primary.main,
    },
  },
  bordered: {
    width: '108px',
    height: '40px',
    paddingLeft: '10px',
    paddingRight: '10px',
    border: `1px solid ${theme.palette.color.grey2}`,
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  status: {
    display: 'inline-block',
    width: '15px',
    minWidth: '15px',
    height: '15px',
    marginRight: '12px',
    borderRadius: '50%',
    backgroundColor: theme.palette.color.white,

    '&.completed': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&.active': {
      backgroundColor: theme.palette.primary.main,
    },
    '&.blocked': {
      backgroundColor: theme.palette.color.white,
    },
    [theme.breakpoints.down('md')]: {
      marginRight: '6px',
    },
  },
  thin: {
    fontWeight: 400,
  },
  bold: {
    fontWeight: 600,
  },
  bolder: {
    fontWeight: 750,
  },
}));

const TabStep = observer(({ id, phaseKey, name, status, bottom, order }) => {
  const classes = useStyles();
  const { url } = useRouteMatch();
  const store = useStore();

  const [enabled, setEnabled] = useState(false);

  const history = useHistory();

  const styleText = (text) => {
    const checker = (text) => {
      const articles = ['il ', 'lo ', 'la ', 'i ', 'gli ', 'le '];
      return articles.some((el) => text.toLowerCase().includes(el));
    };

    if (text.includes(':')) {
      const firstPart = text.substr(0, text.indexOf(':'));
      const secondPart = text.substr(text.indexOf(':') + 1);
      return (
        <>
          <span className={classes.thin}>{firstPart}:</span>
          <span className={classes.bold}>{secondPart}</span>
        </>
      );
    } else if (checker(text)) {
      const firstPart = text.substr(0, text.indexOf(' '));
      const secondPart = text.substr(text.indexOf(' ') + 1);
      return (
        <>
          <span className={classes.thin}>{firstPart}</span>{' '}
          <span className={classes.bold}>{secondPart}</span>
        </>
      );
    } else {
      return (
        <>
          <span className={classes.bolder}>{text}</span>
        </>
      );
    }
  };

  const handleClick = () => {
    const firstSubstepInfo = store.getFirstSubstepInfo(order);

    store.setCurrentPhase(order);
    // store.setCurrentStep(firstSubstepInfo.step.order);
    store.setCurrentStep(firstSubstepInfo.step.id);
    firstSubstepInfo.step.roomId ? store.setCurrentRoomId(firstSubstepInfo.step.roomId) : store.setCurrentRoomId('');
    store.setCurrentSubstep(firstSubstepInfo.substep.frontendId);
    store.setCurrentSubstepId(firstSubstepInfo.substep.id);
    history.push(`${url}/${phaseKey}/${firstSubstepInfo.step.key}/${firstSubstepInfo.substep.key}`);
  };

  const isActive = order === store.currentPhase;

  useEffect(() => {
    const resumeInfo = store.getResumeInfo();
    setEnabled(resumeInfo.phase.order === order);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.currentPhase]);

  return (
    <div
      onClick={handleClick}
      className={clsx(
        classes.root,
        status,
        bottom ? (isActive ? classes.borderedActive : classes.bordered) : classes.shape,
        enabled && 'enabled',
      )}
    >
      <div className={classes.content}>
        <span className={`${classes.status} ${status}`}></span>
        <span>{styleText(name)}</span>
      </div>
    </div>
  );
});

export default TabStep;
