import * as React from 'react';

function SvgLuciApplique(props) {
  return (
    <svg viewBox='0 0 354 173' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#luci-applique_svg__clip0_1712:77365)' fill='#8E8E8E'>
        <path d='M2.014 144.362h63.414v-18.653H2.014v18.653zm65.428 2.014H0v-22.587h67.442v22.587zM288.592 144.362h63.414v-18.653h-63.414v18.653zm65.428 2.014h-67.442v-22.587h67.442v22.587zM2.014 169.208h63.414v-18.653H2.014v18.653zm65.428 2.015H0v-22.682h67.442v22.682zM288.592 169.208h63.414v-18.653h-63.414v18.653zm65.428 2.015h-67.442v-22.682h67.442v22.682z' />
        <path d='M33.73 133.371a2.223 2.223 0 00-2.067 1.362 2.22 2.22 0 104.27.84 2.202 2.202 0 00-2.203-2.202zm0 6.437a4.235 4.235 0 11-.038-8.472 4.235 4.235 0 01.038 8.472zM320.345 133.371a2.22 2.22 0 10.408 4.422 2.22 2.22 0 00-.408-4.422zm0 6.437a4.236 4.236 0 114.235-4.235 4.227 4.227 0 01-1.235 3 4.218 4.218 0 01-3 1.235zM33.73 158.31a2.225 2.225 0 00-2.065 1.358 2.222 2.222 0 102.066-1.358zm0 6.437a4.232 4.232 0 01-4.173-5.056 4.235 4.235 0 018.39.84 4.218 4.218 0 01-4.217 4.216zM320.344 158.31a2.218 2.218 0 00-2.052 1.371 2.222 2.222 0 104.274.85 2.228 2.228 0 00-.164-.853 2.204 2.204 0 00-2.058-1.368zm0 6.437a4.232 4.232 0 01-2.987-7.236 4.24 4.24 0 016.518.662c.463.699.708 1.52.705 2.358a4.224 4.224 0 01-1.215 3.037 4.215 4.215 0 01-3.021 1.255M89.217 170.018h180.115v-23.34h-.226v-.998c0-13.646-13.025-24.338-29.645-24.338H119.654c-16.772 0-30.437 10.917-30.437 24.338v24.338zm182.129 2.014H87.203V145.68c0-14.531 14.569-26.352 32.451-26.352h119.75c17.75 0 31.641 11.576 31.641 26.352h.358l-.057 26.352zM123.103 93.297a8.526 8.526 0 000 17.054h44.61a8.53 8.53 0 008.527-8.527 8.527 8.527 0 00-8.527-8.527h-44.61zm44.61 19.068h-44.61a10.54 10.54 0 010-21.082h44.61a10.542 10.542 0 110 21.082zM191.408 93.297a8.526 8.526 0 000 17.054h44.629a8.527 8.527 0 000-17.054h-44.629zm44.629 19.068h-44.629a10.54 10.54 0 110-21.082h44.629a10.54 10.54 0 010 21.082zM320.102 73.803c-10.654 0-14.644-1.882-14.814-2.014l-.658-.32V52.854l.658-.34c.17 0 4.16-1.995 14.814-1.995s14.644 1.883 14.813 1.996l.659.338v18.635l-.659.32c-.131.056-4.122 1.995-14.813 1.995zm-13.176-3.877a57.202 57.202 0 0026.239 0V54.359a57.898 57.898 0 00-26.239 0v15.567zM33.789 73.803c-10.654 0-14.644-1.882-14.814-2.014l-.659-.32V52.854l.66-.34c.169 0 4.159-1.995 14.813-1.995 10.653 0 14.644 1.883 14.813 1.996l.64.338v18.635l-.64.32c-.075.056-4.066 1.995-14.72 1.995h-.093zm-13.176-3.877a57.126 57.126 0 0026.22 0V54.359a57.821 57.821 0 00-26.22 0v15.567z' />
      </g>
      <defs>
        <clipPath id='luci-applique_svg__clip0_1712:77365'>
          <path fill='#fff' transform='translate(0 .52)' d='M0 0h354v171.494H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgLuciApplique;
