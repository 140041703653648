import * as React from 'react';

function SvgBagno(props) {
  return (
    <svg viewBox='0 0 43 43' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#bagno_svg__clip0_2448_23011)' fill='#C4C4C4'>
        <path d='M37.6 0a5.34 5.34 0 00-3.909 1.71 5.141 5.141 0 00-6.75.43.91.91 0 00-.26.62.91.91 0 00.26.62l6 6a.909.909 0 00.62.26.871.871 0 00.62-.26 5.14 5.14 0 00.67-6.44 3.63 3.63 0 012.71-1.23 3.62 3.62 0 013.62 3.61V21H.83a.87.87 0 00-.87.88v3.09a12.1 12.1 0 008.49 11.53l-1.53 4.63a.91.91 0 00.05.67.88.88 0 00.51.44c.09.015.18.015.27 0a.88.88 0 00.84-.6l1.58-4.79c.619.1 1.243.153 1.87.16h22.83v4.46a.88.88 0 00.88.87.87.87 0 00.87-.87v-4.42a7.2 7.2 0 006.31-7.13V5.36A5.38 5.38 0 0037.6 0zm-1.82 35.34h-23.7A10.34 10.34 0 011.74 25.01v-2.22h39.471v7.11a5.45 5.45 0 01-5.47 5.44h.04zM33.52 7.48l-4.64-4.64a3.41 3.41 0 014.101.54 3.41 3.41 0 01.54 4.09v.01z' />
        <path d='M29.21 24.91H17.93a.88.88 0 00-.88.87.89.89 0 00.88.88h11.28a.88.88 0 00.87-.88.87.87 0 00-.87-.87zM38.161 24.91h-5a.869.869 0 00-.88.87.88.88 0 00.88.88h5a.89.89 0 00.88-.88.88.88 0 00-.88-.87zM29.54 11.22c.124.06.261.09.4.09a.88.88 0 00.78-.48l.22-.45a.869.869 0 00-.12-.968.878.878 0 00-.27-.211.88.88 0 00-1.17.4l-.23.44a.87.87 0 000 .67.9.9 0 00.39.51zM28.509 11.9a.86.86 0 00-.51.44l-.29.58a.91.91 0 00-.05.67.87.87 0 00.83.59.86.86 0 00.78-.48l.29-.57a.88.88 0 00-.39-1.18.891.891 0 00-.66-.05zM27.74 14.83a.87.87 0 00-.97.118.881.881 0 00-.21.271l-.22.44a.88.88 0 00.39 1.18.86.86 0 00.39.09.86.86 0 00.78-.48l.23-.45a.91.91 0 00.05-.67.87.87 0 00-.44-.5zM25.56 6.89c.11 0 .219-.021.32-.06l.46-.18a.869.869 0 00.5-1.13.88.88 0 00-1.13-.5l-.47.18a.88.88 0 00-.5 1.13.89.89 0 00.82.56zM22.55 8.07a.87.87 0 00.32-.06l.6-.24a.87.87 0 00.5-1.13.9.9 0 00-.47-.49.889.889 0 00-.66 0l-.61.24a.87.87 0 00-.49 1.08.86.86 0 00.81.6zM19.678 9.19a.87.87 0 00.32-.06l.47-.18a.88.88 0 00.49-1.14.88.88 0 00-1.13-.49l-.46.18a.868.868 0 00-.5 1.13.86.86 0 00.81.56zM27.428 7.38l-.39.32a.83.83 0 00-.32.58.84.84 0 00.19.65.88.88 0 00.68.32.92.92 0 00.55-.19l.39-.32a.87.87 0 00.13-1.23.88.88 0 00-1.23-.13zM25.05 11.3a.85.85 0 00.55-.2l.51-.41a.83.83 0 00.32-.58.9.9 0 00-.19-.65.89.89 0 00-.59-.32.92.92 0 00-.64.19l-.51.41a.92.92 0 00-.32.59.87.87 0 00.19.64.88.88 0 00.68.33zM23.12 11.19a.9.9 0 00-.65.19l-.39.31a.88.88 0 00.55 1.56.85.85 0 00.55-.2l.39-.31a.87.87 0 00.17-1.27.828.828 0 00-.62-.28z' />
      </g>
      <defs>
        <clipPath id='bagno_svg__clip0_2448_23011'>
          <path fill='#fff' d='M0 0h42.97v42.32H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgBagno;
