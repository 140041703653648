import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 1024, // Ipad
      md: 1200, // Small Desktop
      lg: 1366, // Ipad Pro
      xl: 1920, // Desktop
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        'html,body,#root,.App': {
          height: '100%',
        },
        'body, button, input': {
          fontFamily: 'p22-underground',
        },
      },
    },
    MuiAccordion: {
      root: {
        '&:before': {
          backgroundColor: 'transparent',
        },
        '&.Mui-expanded': {
          margin: 0,
        },
        '&.Mui-disabled': {
          backgroundColor: '#F8F5F3',
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        flexDirection: 'column',
        padding: 0,
      },
    },
    MuiTableRow: {
      root: {
        height: '40px',
      },
    },
    MuiTableCell: {
      root: {
        padding: '0 16px',
        verticalAlign: 'middle',
        lineHeight: 1,
      },
    },
  },
  palette: {
    primary: {
      main: '#F08219',
    },
    secondary: {
      main: '#76B830',
    },
    color: {
      black: '#000',
      white: '#fff',
      orange: '#F08219',
      green: '#76B830',
      red: '#991914',
      yellow: '#F0D80A',
      grey1: '#F0EFEE',
      grey2: '#BEBCBB',
      brownGrey1: '#F8F5F3',
      brownGrey2: '#EBE4E0',
      brownGrey3: '#C5BAAE',
      disabledGrey: '#E5E5E5',
      disabledGreyText: '#5E5E5E',
    },
  },
});

export default theme;
