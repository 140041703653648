import * as React from "react";

function SvgTabletUncheck(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 75 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M64.136 76.584V3.463c0-.808-.654-1.463-1.462-1.463H13.116c-.807 0-1.462.655-1.462 1.463v73.12c0 .809.655 1.464 1.462 1.464h49.558c.808 0 1.462-.655 1.462-1.463z"
        stroke="#1D1D1B"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx={37.326}
        cy={71.594}
        r={3}
        transform="rotate(90 37.326 71.594)"
        fill={props.color}
      />
      <path d="M60.326 66.094h-44" stroke={props.color} />
      <path d="M2 74L73 5.5" stroke="#F08219" strokeWidth={5} />
    </svg>
  );
}

export default SvgTabletUncheck;
