import * as React from "react";

function SvgFormat(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 58 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke="#C4C4C4"
        strokeWidth={0.52}
        d="M.26.26h26.48v26.48H.26zM31.26.26h26.48v26.48H31.26zM.26 33.26h26.48v26.48H.26zM31.26 33.26h26.48v26.48H31.26z"
      />
    </svg>
  );
}

export default SvgFormat;
