import * as React from 'react';

function SvgDownloadOk(props) {
  return (
    <svg viewBox='0 0 35 42' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M22.21 25.06c0 .39-.32.72-.72.72H5.28c-.4 0-.72-.32-.72-.72 0-.4.32-.72.72-.72h16.21c.39 0 .72.32.72.72zm0-4.73c0 .4-.32.72-.72.72H5.28c-.4 0-.72-.32-.72-.72 0-.4.32-.71.72-.71h16.21c.39 0 .72.32.72.71zm0-4.65c0 .39-.32.71-.72.71H5.28c-.4 0-.72-.32-.72-.71 0-.39.32-.72.72-.72h16.21c.39 0 .72.32.72.72zM5.28 10.16h16.21c.39 0 .72.32.72.72 0 .4-.32.72-.72.72H5.28c-.4 0-.72-.32-.72-.72 0-.4.32-.72.72-.72zM25.5 34.81c0 .2-.16.36-.36.36H2.27c-.2 0-.36-.16-.36-.36V2.27c0-.2.16-.36.36-.36h17.29v5.53c0 .53.43.95.96.95h4.98v26.42zM21.47 3.4l2.84 3.08h-2.84V3.4zM20.93 0H2.27C1.02 0 0 1.02 0 2.27v32.54c0 1.25 1.02 2.27 2.27 2.27h22.86c1.25 0 2.27-1.02 2.27-2.27V7.03L20.93 0z'
        fill='#F0801A'
      />
      <path
        d='M23.5 41C29.3 41 34 36.297 34 30.5S29.3 20 23.5 20 13 24.703 13 30.5 17.7 41 23.5 41z'
        fill='#F0801A'
      />
      <path
        d='M22.238 33.02l.1-.1c1.49-1.56 2.98-3.13 4.47-4.69.02-.02.04-.04.06-.07.2-.21.45-.21.64-.01.19.2.19.46-.01.67-.94.99-1.88 1.97-2.82 2.95-.69.73-1.38 1.45-2.07 2.17-.22.23-.48.23-.7 0-.69-.73-1.39-1.45-2.08-2.18-.12-.12-.19-.27-.14-.44.05-.19.17-.31.35-.36.18-.05.31.03.43.16.57.59 1.13 1.19 1.69 1.78.03.03.05.07.07.11'
        fill='#fff'
      />
      <path
        d='M22.26 33.92c-.34 0-.67-.15-.93-.42l-2.08-2.18c-.45-.47-.4-.96-.33-1.21.12-.46.45-.79.91-.92.25-.07.75-.12 1.22.38l1.2 1.27 4.04-4.24c.25-.26.57-.4.9-.4.33 0 .64.14.88.4.48.51.48 1.25-.02 1.77l-4.89 5.13c-.26.27-.59.42-.93.42h.03z'
        fill='#fff'
      />
    </svg>
  );
}

export default SvgDownloadOk;
