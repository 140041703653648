import { Box, Grid, makeStyles, Popover } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import CurrencyFormatter from '../../utils/CurrencyFormatter';
import { getRemoveImagesStartByLetter } from '../../utils/optionsUtils';
import IconButton from '../Buttons/IconButton';
import OutlineIconButton from '../Buttons/OutlineIconButton';
import { Alert, Camera } from '../Icons';
import SvgPlus from '../Icons/Plus';
import ImageZoom from '../ImageZoom';
import ImageZoomSlider from '../ImageZoomSlider';
import InfoPopup, { PhaseInfoContent } from '../InfoPopup';
import RichTextPopover from '../popovers/RichTextPopover';
import WatchPlanimetry from '../WatchPlanimetry';
import AlertBadge from './AlertBadge';

const useStyles = makeStyles((theme) => ({
  kitchenLayoutCardRoot: {
    fontSize: 13,
    lineHeight: '16px',
    padding: '16px 16px 8px',
    height: '100%',
    position: 'relative',

    '& .price-container': {
      borderTop: `1px solid ${theme.palette.color.grey2}`,
      height: '30px',
      fontSize: '11px',
      lineHeight: '13px',
    },
    '& .price-value': {
      display: 'flex',
      flexDirection: 'row',
      //   justifyContent: 'space-between',
      alignItems: 'center',
      paddingRight: '14px',
      fontSize: '12px',
      lineHeight: '15px',
      color: theme.palette.primary.main,
      fontWeight: '800',
      '& svg': {
        marginRight: '8px',
        cursor: 'pointer',
      },
      '& span': {
        paddingTop: '3px',
        whiteSpace: 'nowrap',
        color: '#A15117',
      },
    },
    '& .alert-badge': {
      zIndex: 2,
      maxWidth: 'calc(100% - 128px)',
      margin: 'unset',
    },
    '& .info-button': {
      top: 16,
      right: 16,
      cursor: 'pointer',
      position: 'absolute',
      fontSize: 12,
      transition: 'color 100ms',
      textDecoration: 'underline',
      zIndex: 2,
      width: 120,
      textAlign: 'right',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  },
  firstRow: {
    borderBottom: '0.5px solid #CDC7C1',
    padding: '12px',
  },
  secondRow: {
    padding: '12px',
  },
  sliderContainer: {
    position: 'relative',
    height: '50%',
  },
  layoutName: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 15,
    lineHeight: '18px',
    textTransform: 'uppercase',
  },
  watchPlanimetryContainer: {
    height: '50%',
  },
  popover: {
    backgroundColor: 'rgba(0,0,0,0) !important',
    borderTopLeftRadius: '90px',
  },
  buttonContainer: {
    padding: '8px 0',
  },
}));

const KitchenLayoutCard = ({
  price,
  onClick,
  isSelected,
  fileLabels,
  options,
  planimetryImages,
}) => {
  const classes = useStyles();

  const [showInfo, setShowInfo] = useState(false);
  const [showRichText, setShowRichText] = useState(false);
  const [showPlanimetry, setShowPlanimetry] = useState(false);
  const [kitchenPlanimetryImages, setKitchenPlanimetryImages] = useState([]);

  const infoRef = useRef();
  const infoRichText = useRef();

  const images = fileLabels.filter((el) => el.isAxo).map((el) => el.url);

  let planimetryImage = fileLabels.filter((el) => el.isPlan).map((el) => el.url);
  planimetryImage = planimetryImage.length > 0 ? planimetryImage[0] : '';

  const longDescription = options[0]?.longDescription;
  const priceDescription = options[0]?.priceDescription;

  const infoData = {
    phase: 'VALORE AGGIUNTO',
    firstRow: 'Puoi monitorare le tue aggiunte nella sezione Riepilogo',
    secondRow: priceDescription ? (
      <span dangerouslySetInnerHTML={{ __html: priceDescription }} />
    ) : (
      "Selezionandolo aumenterà il prestigio del tuo preventivo. Avrai sempre la possibilità di monitorare le tue aggiunte con valore superiore nella sezione 'Recap scelte'"
    ),
    thirdRow: 'I prezzi sono da intendersi iva esclusa.',
  };

  useEffect(() => {
    let layers = getRemoveImagesStartByLetter(planimetryImages, 'K');

    const kitchenLayer = fileLabels
      .filter((el) => !el.isAxo && !el.isElettrico && !el.isPlan)
      .map((el) => {
        return { fileUrl: el.url, order: 2 };
      });
    layers = [...layers, ...kitchenLayer].sort((a, b) => a.order - b.order).map((el) => el.fileUrl);
    setKitchenPlanimetryImages(layers);
  }, []);

  return (
    <>
      <Grid container className={classes.kitchenLayoutCardRoot} direction='column' wrap='nowrap'>
        <AlertBadge
          badgeText={
            'Potrai personalizzare il modello, i moduli e le finiture in una fase successiva.'
          }
          badgeTextOrange={
            "Con questa scelta definisci la posizione degli attacchi idrici di lavello e lavastoviglie e l'impianto elettrico per piano cottura, cappa, lavastoviglie, frigorifero, forno etc."
          }
          className={'alert-badge'}
        />
        {longDescription && (
          <>
            <div
              ref={infoRichText}
              item
              xs='auto'
              className={'info-button'}
              onClick={() => setShowRichText(true)}
            >
              Scopri le qualità di questa cucina
            </div>
            <RichTextPopover
              show={showRichText}
              onClose={() => setShowRichText(false)}
              anchorEl={infoRichText.current}
              infoRichText={longDescription}
            />
          </>
        )}
        <Grid item xs>
          <div className={classes.watchPlanimetryContainer}>
            <WatchPlanimetry image={planimetryImage} />
          </div>
          <div className={classes.sliderContainer}>
            <ImageZoomSlider images={images} />
          </div>
        </Grid>
        <Grid item xs='auto'>
          <Grid container justifyContent='space-between' className={classes.buttonContainer}>
            <Grid item>
              <OutlineIconButton
                onClick={() => setShowPlanimetry(true)}
                text={'Visualizza cucina ambientata nella planimetria'}
                icon={<Camera color='#878787' width='15px' height='15px' />}
                noBackground
              />
            </Grid>
            <Grid item xs='auto'>
              <IconButton
                text={isSelected ? 'Selezionato' : 'Seleziona'}
                icon={<SvgPlus color='currentColor' width='10px' height='10px' />}
                onClick={onClick}
                isSelected={isSelected}
              />
            </Grid>
          </Grid>
          {price > 0 && (
            <Grid
              container
              className='price-container'
              alignItems='center'
              justifyContent='flex-end'
            >
              <Grid item xs={'auto'}>
                <div className={classes.price}>
                  {/* <div
                className={`price-description ${classes.bold}`}
                style={{ visibility: Tags?.includes('sale') ? 'visible' : 'hidden' }}
              >
                <span>{Tags?.includes('sale') ? 'Sconto del 20%' : "L'opzione piú scelta"}</span>
              </div> */}

                  <div className={`price-value ${classes.bold}`} ref={infoRef}>
                    <Alert
                      color='currentColor'
                      width='15px'
                      height='15px'
                      onClick={() => {
                        setShowInfo(true);
                      }}
                    />
                    <span>+ {CurrencyFormatter(price)}</span>
                  </div>
                  <Popover
                    open={showInfo}
                    anchorEl={infoRef.current}
                    onClose={() => setShowInfo(false)}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    PaperProps={{ classes: { root: classes.popover } }}
                  >
                    <InfoPopup
                      body={<PhaseInfoContent {...infoData} />}
                      isPopover
                      handleClose={() => setShowInfo(false)}
                    />
                  </Popover>
                </div>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      {showPlanimetry && (
        <ImageZoom
          images={kitchenPlanimetryImages}
          isPlanimetry
          handleClose={() => setShowPlanimetry(false)}
          allowDownload
        />
      )}
    </>
  );
};

export default KitchenLayoutCard;
