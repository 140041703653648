import * as React from 'react';

function SvgPreseAngoloCottura(props) {
  return (
    <svg viewBox='0 0 71 53' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#prese-angolo-cottura_svg__clip0_2758_23749)' fill='#C4C4C4'>
        <path d='M8.771 2.16h7.05c.6 0 1.08-.49 1.08-1.08 0-.59-.48-1.08-1.08-1.08h-7.05c-.6 0-1.08.48-1.08 1.08 0 .6.48 1.08 1.08 1.08zM3.14 41.95c-.63-.63-.98-1.48-.98-2.37V16.3h42.82v-6.16c0-1.46-.58-2.86-1.61-3.9a5.596 5.596 0 00-3.9-1.62H5.51c-1.46.01-2.86.59-3.9 1.62A5.543 5.543 0 000 10.14v29.44c0 1.46.58 2.86 1.61 3.9a5.596 5.596 0 003.9 1.62h19.74v-2.17H5.51c-.89 0-1.74-.35-2.37-.98zm-.98-31.81a3.345 3.345 0 013.35-3.35h33.96a3.345 3.345 0 013.35 3.35v4H2.16v-4z' />
        <path d='M35.588 8.86c-.88 0-1.6.72-1.6 1.6 0 .88.72 1.61 1.6 1.61.88 0 1.61-.72 1.61-1.61 0-.89-.72-1.6-1.61-1.6zM6.668 22.6c-.01.6.47 1.09 1.07 1.1v12.32c0 .77.3 1.51.85 2.05.54.54 1.28.85 2.05.85h14.61v-2.17h-14.61c-.41 0-.74-.33-.74-.74V23.7h15.35v-2.16H7.758c-.6-.01-1.08.47-1.09 1.06zM9.391 8.86c-.89 0-1.61.72-1.61 1.6 0 .88.72 1.61 1.61 1.61.89 0 1.6-.72 1.6-1.61 0-.89-.72-1.6-1.6-1.6zM18.12 8.86c-.88 0-1.6.72-1.6 1.6 0 .88.72 1.61 1.6 1.61.88 0 1.61-.72 1.61-1.61 0-.89-.72-1.6-1.61-1.6zM26.86 8.86c-.89 0-1.61.72-1.61 1.6 0 .88.72 1.61 1.61 1.61.89 0 1.6-.72 1.6-1.61 0-.89-.72-1.6-1.6-1.6zM29.158 2.16h7.05c.59 0 1.08-.49 1.08-1.08 0-.59-.49-1.08-1.08-1.08h-7.05c-.6 0-1.08.48-1.08 1.08 0 .6.48 1.08 1.08 1.08zM47.788 31.6h-2.46v6.18h2.46V31.6zM38.16 31.6h-.23v6.16h2.46V31.6h-2.23z' />
        <path d='M55.539 25.1c0-.72-.29-1.42-.8-1.93s-1.21-.8-1.93-.81h-19.19c-.73.01-1.42.3-1.94.81-.51.51-.8 1.21-.8 1.93v19.2c0 .72.29 1.41.8 1.93.33.33.74.57 1.18.69.25.07.5.11.76.11h19.19c.72 0 1.42-.29 1.93-.81.51-.51.8-1.2.8-1.92V25.1zm-4.13 15.08a9.841 9.841 0 01-4.43 3.63 9.87 9.87 0 01-10.75-2.14c-.11-.11-.22-.22-.32-.34-.4-.43-.75-.9-1.06-1.4a9.62 9.62 0 01-1.32-3.31c-.38-1.91-.18-3.9.56-5.7.16-.37.33-.73.54-1.08.35-.63.77-1.22 1.26-1.75.54-.6 1.16-1.14 1.84-1.6a9.89 9.89 0 019.26-.91c1.19.5 2.28 1.22 3.2 2.14.91.92 1.64 2 2.13 3.2.5 1.2.76 2.48.75 3.78 0 1.95-.57 3.85-1.66 5.48zM66.8 33.39h1.11c.14 0 .25-.11.25-.25V28.4c0-.11 0-.22-.05-.33a.75.75 0 00-.18-.28.75.75 0 00-.47-.22.169.169 0 00-.1-.03c-.11-.01-.22 0-.33.05-.1.04-.2.1-.28.18a.75.75 0 00-.18.28c-.04.1-.05.22-.05.3v4.79c0 .14.11.25.25.25h.03zM60.518 33.36h1.05c.14 0 .25-.11.25-.25v-4.74c.01-.11 0-.22-.04-.32s-.1-.2-.18-.28a.898.898 0 00-.28-.18c-.1-.03-.2-.04-.31-.04-.1 0-.18.02-.27.06-.1.04-.19.1-.26.18a.77.77 0 00-.17.27c-.03.1-.05.21-.04.3v4.75c0 .14.11.25.25.25zM70.222 35.41a.834.834 0 00-.17-.63.826.826 0 00-.6-.33h-10.58a.842.842 0 00-.6.31c-.07.09-.12.2-.16.31-.03.11-.04.22-.03.31v6.01c-.01.11 0 .23.03.34.04.11.09.22.16.31.08.09.17.16.27.21.11.06.22.09.35.1h10.59c.11-.02.22-.05.32-.11.1-.05.18-.13.25-.22s.12-.19.15-.3c.03-.11.04-.22.03-.3v-6.01h-.01zM65.761 43.53h-3.17c-.14 0-.25.11-.25.25v1.06c0 .14.11.25.25.25h.81v3.3a2.5 2.5 0 01-.74 1.67l-.03.03c-.44.43-1.02.68-1.62.71h-5.82c-.14 0-.25.11-.25.25v1.06c0 .14.11.25.25.25h5.82c.6 0 1.15-.12 1.62-.32 1.47-.63 2.33-2.03 2.33-3.11v-3.84h.8c.14 0 .25-.11.25-.25v-1.06c0-.14-.11-.25-.25-.25z' />
      </g>
      <defs>
        <clipPath id='prese-angolo-cottura_svg__clip0_2758_23749'>
          <path fill='#fff' d='M0 0h70.23v52.36H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgPreseAngoloCottura;
