import * as React from 'react';

function SvgUsbSoggiorno(props) {
  return (
    <svg viewBox='0 0 49 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M45.135 6.098h2.943v3.164h-1.006v2.45c.001.255-.045.507-.136.742a1.93 1.93 0 01-.392.627l-4.117 4.458h-.038v5.83c.649.127 1.23.509 1.63 1.072.4.564.59 1.268.53 1.974a2.921 2.921 0 01-.847 1.841 2.539 2.539 0 01-1.78.744c-.66 0-1.295-.265-1.781-.744a2.921 2.921 0 01-.848-1.841c-.058-.706.13-1.41.53-1.974.4-.563.982-.945 1.63-1.071v-2.375l-3.883-4.18a1.931 1.931 0 01-.392-.628 2.041 2.041 0 01-.136-.741v-2.757a1.459 1.459 0 01-.763-.67 1.638 1.638 0 01-.181-1.04 1.57 1.57 0 01.487-.92c.258-.236.586-.365.925-.365.338 0 .666.13.924.364.259.235.431.562.488.92.056.36-.008.728-.181 1.042-.173.313-.444.55-.764.669v2.752c0 .122.023.243.066.356a.94.94 0 00.187.303l3.224 3.471V3.144h-1.217l.842-1.574.842-1.57.847 1.57.842 1.574h-1.202V16.13l3.495-3.758a.972.972 0 00.234-.66v-2.45h-1.002V6.098zM20.69 0h9.39v1.98h-9.35V2A15 15 0 008.52 8.27l.17.16a13.66 13.66 0 014.78 10.42v.4h16.61v1.94H12.37a1 1 0 01-1-1 1.058 1.058 0 010-.19c.048-.401.072-.805.07-1.21a11.7 11.7 0 00-4.13-8.95l-.11-.1-.08-.07c-.11-.1-.23-.19-.35-.28l-.15-.08a2.999 2.999 0 00-1.49-.38 3 3 0 00-3.05 3 3.05 3.05 0 001.75 2.76c.147.073.301.13.46.17a.7.7 0 01.2.08c.27.127.527.278.77.45a5.06 5.06 0 012.11 4.09 5 5 0 01-1.08 3.11c-.038.061-.082.118-.13.17-.17.19-.35.37-.54.54a1.8 1.8 0 00-.33 1 1.79 1.79 0 001.79 1.79h23v2.1H15.5v2.5a1 1 0 01-1 1h-6a1 1 0 01-1-1v-2.53h-.46a3.79 3.79 0 01-3.79-3.79 3.76 3.76 0 01.79-2.29.689.689 0 01.11-.13c.157-.139.307-.286.45-.44v-.06a3 3 0 00.69-1.92 3 3 0 00-1.28-2.47 3.843 3.843 0 00-.4-.24 3.67 3.67 0 01-.71-.27A5.06 5.06 0 010 11.98a5.05 5.05 0 015.05-5 5 5 0 011.64.28 16.94 16.94 0 0114-7.26zm-7.12 29.71h-4v-1.52h4v1.52z'
        fill='#C4C4C4'
      />
    </svg>
  );
}

export default SvgUsbSoggiorno;
