import { useEffect, useState, useRef } from 'react';
import GridDetailsContainer from '../../../components/Card/GridDetailsContainer';
import ConfiguratorTwoColumnsLayout from '../../../layouts/ConfiguratorTwoColumnsLayout';
import BigBox from '../giornoNotte/config/BigBox';
import { makeStyles } from '@material-ui/core';
// import { Chains } from '../../../components/Icons';
import { resumeAnswerSimple } from '../../../utils/configuratorUtils';
import { getPackages } from '../../../services/packageApi';
import { useStore } from '../../../store/storeUtils';
import { useParams } from 'react-router';
import { handleSave, singleChoiceSelection } from '../../../utils/selectionUtils';
import _ from 'lodash';
import LoadingAnimation from '../../../components/LoadingAnimation';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '15px',
    textAlign: 'left',
    height: '5%',
  },
  subTitle: {
    fontSize: '11px',
    textAlign: 'left',
    height: '8%',
  },
  secondaryText: {
    color: theme.palette.color.orange,
  },
}));

const FirstColumn = ({
  options,
  selectedOptions,
  selectedFormatoCamereImage,
  selectedFormatoCamereTitle,
  selectedFormatoCucinaImage,
  selectedFormatoCucinaTitle,
}) => {
  const selectedVirtualImage = options
    ?.find((option) => option.id === selectedOptions?.[0])
    ?.virtualImage?.replace(/\s/g, '%20');
  // const isChained = options?.find(option => option.id === selectedOptions?.[0])?.isChained;
  return (
    <BigBox
      defaultImage={`${process.env.PUBLIC_URL}/assets/images/Bagno.jpg`}
      selectedVirtualImage={selectedVirtualImage}
      selectedFormatoCamereImage={selectedFormatoCamereImage}
      selectedFormatoCucinaImage={selectedFormatoCucinaImage}
      selectedFormatoCamereTitle={selectedFormatoCamereTitle}
      selectedFormatoCucinaTitle={selectedFormatoCucinaTitle}
    />
  );
};

const SecondColumn = ({ options, selectedOptions, handleUserSelection }) => {
  const containers = [
    {
      title: 'Finiture ',
      content: options,
    },
  ];
  return (
    <>
      <SecondColumnText />
      <div style={{ height: '87%', backgroundColor: '#F0EDEB' }}>
        <GridDetailsContainer
          containers={containers}
          onClick={handleUserSelection}
          selectedOptions={selectedOptions}
          numCols={1}
        />
      </div>
    </>
  );
};

const SecondColumnText = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.title}>
        <span>
          Quale <span className={classes.secondaryText}>finitura</span> vuoi per il{' '}
          <span className={classes.secondaryText}>Pavimento del bagno</span>?
        </span>
      </div>
      <div className={classes.subTitle}>
        L'<span className={classes.secondaryText}> immagine </span> accanto mostra una{' '}
        <span className={classes.secondaryText}> finitura a parete </span> in abbinamento al
        pavimento selezionato. Potrai personalizzare ulteriori abbinamenti nello step successivo.
      </div>
    </>
  );
};

const FinitureBagno = () => {
  const [packages, setPackages] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [selectedFormatoCamereImage, setSelectedFormatoCamereImage] = useState(null);
  const [selectedFormatoCamereTitle, setSelectedFormatoCamereTitle] = useState(null);
  const [selectedFormatoCucinaImage, setSelectedFormatoCucinaImage] = useState(null);
  const [selectedFormatoCucinaTitle, setSelectedFormatoCucinaTitle] = useState(null);

  const substepIdRef = useRef();
  const oldAnswersRef = useRef();

  const store = useStore();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const currentRoomId = store.currentRoomId;
  // const { answers } = store;

  const settingFiniture = () => {
    const storedFormatoCamereTitle = store.selectedFormatoCameraTitle;
    const storedFormatoCamereImage = store.selectedFormatoCameraImage;
    const storedFormatoCucinaTitle = store.selectedFormatoCucinaTitle;
    const storedFormatoCucinaImage = store.selectedFormatoCucinaImage;
    if (storedFormatoCamereTitle !== '') {
      setSelectedFormatoCamereTitle(storedFormatoCamereTitle);
      setSelectedFormatoCamereImage(storedFormatoCamereImage);
    }
    if (storedFormatoCucinaTitle !== '') {
      setSelectedFormatoCucinaTitle(storedFormatoCucinaTitle);
      setSelectedFormatoCucinaImage(storedFormatoCucinaImage);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    store.setUserCanGoNext(false);
    prepareOptions();
    settingFiniture();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoomId]);

  const prepareOptions = async () => {
    const configurationId = params.configuration;
    const substepId = store.currentSubstepId;
    const packages = await getPackages(configurationId, substepId, currentRoomId);
    substepIdRef.current = store.currentSubstepId;
    /*
    const { answers } = store;
    setSelectedMoods(answers.find((el) => el.substep.value === 'MOOD')?.package?.value);
    const selectedFiniture = answers.filter((el) => el.substep.value === 'FINITURA');
    const selectedFinituraLivingImage = selectedFiniture?.[0]?.package?.value;
    const selectedFormatoCucinaImage = selectedFiniture?.[1]?.package?.value;
    let stepFinituraLivingId;
    let finitureLivingPackages;
    let stepFinituraCucinaId;
    let finitureCucinaPackages;
    if (selectedFinituraLivingImage) {
      stepFinituraLivingId = selectedFiniture?.[0]?.substep?.id;
      if (substepId !== stepFinituraLivingId) {
        finitureLivingPackages = await getPackages(configurationId, stepFinituraLivingId);
        setSelectedFinituraLivingImage(
          finitureLivingPackages?.data[0]?.packages
            ?.find((onePackage) => onePackage?.code === selectedFinituraLivingImage)
            ?.images?.find((image) => image?.imageType?.value === 'Cover')
            ?.label?.replace(/\s/g, '%20'),
        );
      }
    }
    if (selectedFormatoCucinaImage) {
      stepFinituraCucinaId = selectedFiniture?.[1]?.substep?.id;
      if (substepId !== stepFinituraCucinaId) {
        finitureCucinaPackages = await getPackages(configurationId, stepFinituraCucinaId);
        setSelectedFormatoCucinaImage(
          finitureCucinaPackages?.data[0]?.packages
            ?.find((onePackage) => onePackage?.code === selectedFormatoCucinaImage)
            ?.images?.find((image) => image?.imageType?.value === 'Cover')
            ?.label?.replace(/\s/g, '%20'),
        );
      }
    }
    */
    const packagesRestruct = packages?.data[0]?.packages?.map((onePackage) => {
      const coverImage = onePackage?.images?.find(
        (image) => image?.imageType?.value === 'Cover',
      )?.label;
      const virtualImage = onePackage?.images?.find(
        (image) => image?.imageType?.value === 'Virtual',
      )?.label;
      return {
        id: onePackage?.id,
        name: onePackage?.name,
        materiotecaCode: onePackage?.materiotecaCode,
        materiotecaName: onePackage?.materiotecaName,
        title: onePackage?.title,
        description: onePackage?.name,
        coverImage: coverImage,
        virtualImage: virtualImage,
        price: onePackage?.priceDiff,
        infoPrice:
          "Il prezzo comprende la fornitura e la posa del materiale selezionato per il pavimento e il rivestimento del bagno, calcolato in sostituzione a quanto previsto all'interno del capitolato. La quantità del materiale è calcolata sulla base dello schema rivestimento selezionato in precedenza.",
        infoSpecification: 'Lorem Ipsum infoSpecification',
        isChained: onePackage?.subsequentChoiceWarning || false,
      };
    });
    setPackages(packagesRestruct);
    resumeAnswerSimple(
      store,
      oldAnswersRef,
      substepIdRef,
      setSelectedPackages,
      setIsLoading,
      currentRoomId,
    );
  };

  useEffect(() => {
    const save = async () => {
      await handleSave(
        null,
        params.configuration,
        substepIdRef.current,
        store,
        selectedPackages?.[0],
        currentRoomId,
      );
    };

    if (!_.isEqual(oldAnswersRef.current, selectedPackages)) {
      store.setSubstepSave(save);
    } else {
      store.setSubstepSave(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPackages]);

  const handleUserSelection = (selection) => {
    singleChoiceSelection([selection], [selectedPackages], setSelectedPackages, store);
  };

  return isLoading ? (
    <LoadingAnimation />
  ) : (
    <ConfiguratorTwoColumnsLayout
      firstRowTitle='CONFIGURA'
      secondRowTitle='IL PAVIMENTO DEL BAGNO'
      firstColumn={
        <FirstColumn
          options={packages}
          selectedOptions={selectedPackages}
          selectedFormatoCamereImage={selectedFormatoCamereImage}
          selectedFormatoCamereTitle={selectedFormatoCamereTitle}
          selectedFormatoCucinaImage={selectedFormatoCucinaImage}
          selectedFormatoCucinaTitle={selectedFormatoCucinaTitle}
        />
      }
      secondColumn={
        <SecondColumn
          options={packages}
          selectedOptions={selectedPackages}
          handleUserSelection={handleUserSelection}
          secondColumnText={<SecondColumnText />}
        />
      }
      options={packages}
    />
  );
};

export default FinitureBagno;
