import React, { useEffect, useState } from 'react';
import { makeStyles, ButtonBase, Grid, Tabs, Tab } from '@material-ui/core';
import clsx from 'clsx';
import { Document, Page } from 'react-pdf';
import { Exit, LeftChevron } from './Icons';
import LoadingAnimation from './LoadingAnimation';
import SvgDownload2 from './Icons/Download2';
import { saveAs } from 'file-saver';
import { getFileNameFromUrl } from '../utils/stringUtils';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    padding: '2vh 0',
    background: 'rgba(0,0,0,0.55)',
    backdropFilter: 'blur(15px)',
    overflow: 'hidden',
    zIndex: theme.zIndex.modal,

    '& .react-pdf__Page__annotations': {
      display: 'none',
    },
    '& .react-pdf__message': {
      color: '#000',
    },
  },
  closeIcon: {
    position: 'absolute',
    top: '30px',
    right: '30px',
    backgroundColor: '#000',
    height: '50px',
    width: '50px',
    borderRadius: '100%',
    zIndex: 10,
    '& svg': {
      height: '15px',
      width: 'auto',
    },
  },
  documentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    width: '100%',
  },
  document: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '80vw',
    height: '90vh',
    maxHeight: '90vh',
    color: theme.palette.color.white,
    background: '#f0efee',
    position: 'relative',
    // overflow: 'hidden',

    paddingTop: '30px',
  },
  page: {
    display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    height: '100%',
    overflow: 'hidden auto',
    paddingBottom: '30px',
    '& canvas': {
      boxShadow: '0 0 0.5rem 0 rgba(0, 0, 0, 0.3)',
    },
  },
  pager: {
    color: theme.palette.color.white,
  },
  directionButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '30px',
    height: '30px',
    backgroundColor: '#676766',
    border: `1px solid ${theme.palette.color.white}`,
    '&.back': {
      left: '-15px',
    },
    '&.forward': {
      right: '-15px',
      '& svg': {
        transform: 'rotate(180deg)',
      },
    },
  },
  galleryTabs: {
    zIndex: 2,
    position: 'absolute',
    top: 10,
  },
  tabIndicator: {
    bottom: 'unset',
    top: '0',
    backgroundColor: '#F08219',
    height: '5px',
  },
  tabRoot: {
    height: '40px',
    border: '0.5px solid #CDC7C1',
    padding: '4px 4px 0',
    fontFamily: 'p22-underground',
    fontWeight: 500,
    fontSize: 13,
    textTransform: 'uppercase',
    minHeight: 'unset',
    width: '190px',
    color: '#000000',
    backgroundColor: '#D6D6D6',
    opacity: 1,
    '&.selected-tab': {
      backgroundColor: '#FFF',
    },
  },
  loadingLabel: {
    color: '#000',
  },
  pageDotsContainer: {
    position: 'absolute',
    bottom: '10px',
    '& .page-dot': {
      backgroundColor: 'rgba(135, 135, 135,0.5)',
      width: '10px',
      height: '10px',
      borderRadius: '100%',
      margin: '0 3px',
      '&.active': {
        backgroundColor: 'rgba(135, 135, 135,1)',
      },
    },
  },
  downloadIcon: {
    fill: '#FFF',
    height: 'auto',
    width: '50px',
    cursor: 'pointer',
    marginLeft: '30px',
    position: 'absolute',
    right: 30,
    bottom: 30,
  },
}));

const a11yProps = (index) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
};

const GalleryTabs = ({ gallery, activeIndex, onChange }) => {
  const classes = useStyles();

  return (
    <Grid container justifyContent='center' className={clsx(classes.galleryTabs)}>
      <Grid item xs='auto'>
        <Tabs
          classes={{
            root: classes.tabsRoot,
            indicator: classes.tabIndicator,
          }}
          value={activeIndex}
          onChange={onChange}
          aria-label='tabs'
          centered
        >
          {gallery &&
            gallery.map((el, i) => (
              <Tab
                key={i}
                classes={{
                  root: clsx(classes.tabRoot, 'tab-root'),
                  selected: 'selected-tab',
                  wrapper: classes.tapWrapper,
                }}
                label={<span className={classes.tabText}>{`Scheda Tecnica ${i * 1 + 1}`}</span>}
                {...a11yProps(i)}
              />
            ))}
        </Tabs>
      </Grid>
    </Grid>
  );
};

const PdfViewer = ({ document, handleClose, allowDownload }) => {
  const classes = useStyles();

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [currentGallery, setCurrentGallery] = useState(0);

  const [pageWidth, setPageWidth] = useState(0);

  useEffect(() => {
    setPageWidth(window.document.querySelector('.react-pdf__Document').clientWidth * 0.8);
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  const previousPage = () => {
    setPageNumber((page) => (page > 1 ? page - 1 : page));
  };
  const nextPage = () => {
    setPageNumber((page) => (page < numPages ? page + 1 : page));
  };

  const handleDownload = () => {
    saveAs(document[currentGallery], getFileNameFromUrl(document[currentGallery]));
  };

  return (
    <>
      <Grid
        container
        direction='column'
        className={classes.root}
        alignItems='center'
        justifyContent='center'
      >
        <ButtonBase className={classes.closeIcon} onClick={handleClose}>
          <Exit height='8px' width='8px' color='#F08219' />
        </ButtonBase>

        <Grid item xs className={classes.documentContainer} id='pdf-viewer'>
          {document?.length > 1 && (
            <GalleryTabs
              gallery={document}
              activeIndex={currentGallery}
              onChange={(event, newValue) => {
                setCurrentGallery(newValue);
              }}
            />
          )}

          <Document
            file={document[currentGallery]}
            loading={
              <LoadingAnimation msg='Caricamento file PDF...' labelClasses={classes.loadingLabel} />
            }
            onLoadSuccess={onDocumentLoadSuccess}
            className={classes.document}
            error={'La scheda tecnica al momento non è disponibile.'}
          >
            <Page
              pageNumber={pageNumber}
              width={pageWidth}
              loading={<LoadingAnimation />}
              className={classes.page}
            />
            {numPages > 1 && (
              <>
                <ButtonBase
                  className={clsx(classes.directionButton, 'back')}
                  onClick={previousPage}
                >
                  <LeftChevron height='12px' width='auto' color='#FFF' />
                </ButtonBase>
                <ButtonBase className={clsx(classes.directionButton, 'forward')} onClick={nextPage}>
                  <LeftChevron height='12px' width='auto' color='#FFF' />
                </ButtonBase>
                <Grid container className={clsx(classes.pageDotsContainer)} justifyContent='center'>
                  {[...Array(numPages)].map((el, i) => (
                    <Grid
                      item
                      key={i}
                      className={clsx('page-dot', i + 1 === pageNumber && 'active')}
                    />
                  ))}
                </Grid>
              </>
            )}
          </Document>
        </Grid>
        {allowDownload && (
          <SvgDownload2
            onClick={handleDownload}
            className={clsx(classes.downloadIcon)}
            color='#FFF'
          />
        )}
      </Grid>
    </>
  );
};

export default PdfViewer;
