
export const userExist = (userList, store) => {
    const userName = `${store.userData?.firstName} ${store.userData?.lastName}`;
    return userList.find(user => user.name === userName);
}

export const folderExist = (folderList, folderName) => {
    // const folderName = `${store.userData?.firstName}_${store.userData?.lastName}`;
    return folderList.find(folder => folder.name === folderName);
}
