import * as React from 'react';

function SvgIlluminazioneMatrimoniale(props) {
  return (
    <svg viewBox='0 0 74 53' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#illuminazione-matrimoniale_svg__clip0_2758_24037)' fill='#C4C4C4'>
        <path d='M61.58 42.76H3.82v-6.6c0-1.73 1.4-3.15 3.12-3.15h31.41V31h-6.34v-2.68c0-1.84 1.49-3.35 3.32-3.35h3.02v-1.94H35.4c-1.74 0-3.35.84-4.31 2.21a5.247 5.247 0 00-4.28-2.21H16.07c-2.9 0-5.26 2.37-5.26 5.29V31H7.78V18.85c-.06-3.45 2.73-6.32 6.22-6.38h24.35v-1.95H13.99c-4.57 0-8.28 3.74-8.28 8.34v12.3c-2.28.54-3.9 2.58-3.9 5v6.6H.5c-.28 0-.5.22-.5.5v4.87c0 .27.22.5.5.5h1.31v3.42c0 .27.23.5.5.5h4.9c.28 0 .5-.23.5-.5v-3.42h46.72v3.42c0 .27.23.5.5.5h4.83c.28 0 .5-.23.5-.5v-3.42h1.32c.27 0 .5-.23.5-.5v-4.87c0-.28-.23-.5-.5-.5zm-48.9-14.44c0-1.84 1.49-3.35 3.32-3.35h10.74c1.84 0 3.33 1.51 3.33 3.35V31H12.68v-2.68zM5.71 50.53H3.82v-1.9h1.89v1.9zm52.55 0h-1.89v-1.9h1.89v1.9zm1.88-3.92H1.94v-1.9h58.2v1.9z' />
        <path d='M58.85 33.93h-8.06c-.69 0-1.25.57-1.25 1.26 0 .69.56 1.26 1.25 1.26h8.06c.69 0 1.25-.56 1.25-1.26s-.56-1.26-1.25-1.26zM62.34 10.97c-.34-.33-.71-.64-1.09-.91-1.83-1.35-4.06-2.07-6.39-2.07-2.83 0-5.49 1.05-7.49 2.98-2.01 1.93-3.12 4.5-3.12 7.24 0 2.54.97 4.97 2.72 6.84 1.78 2.11 2.61 6.05 2.61 6.08l.17.82h10.3l.02-.99c.07-2.88 2.81-6.06 2.93-6.19 1.59-1.84 2.47-4.17 2.47-6.56 0-1.62-.39-3.18-1.12-4.58-.51-.98-1.18-1.87-2.01-2.66zm-.88 12.46c-.29.33-2.8 3.29-3.33 6.48h-6.74c-.35-1.34-1.26-4.28-2.91-6.23a7.95 7.95 0 01-2.2-5.47c0-4.51 3.85-8.19 8.58-8.19s8.57 3.68 8.57 8.19c0 1.9-.7 3.76-1.97 5.22zM55.989 0h-2.27v4.64h2.27V0zM67.151 3.939l-2.98 3.557 1.741 1.458 2.98-3.557-1.74-1.458zM73.23 16.24h-4.64v2.27h4.64v-2.27z' />
        <path d='M54.94 23.52l-2.91-2.91-1.42 1.41 4.33 4.33 4.09-4.1-1.41-1.41-2.68 2.68z' />
      </g>
      <defs>
        <clipPath id='illuminazione-matrimoniale_svg__clip0_2758_24037'>
          <path fill='#fff' d='M0 0h73.23v52.55H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIlluminazioneMatrimoniale;
