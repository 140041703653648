import * as React from 'react';

function SvgFinestraDimensioneDopo(props) {
  return (
    <svg viewBox="0 0 216 191" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M183 173.86H35V0H183V173.86ZM36.37 172.51H181.63V1.36H36.37V172.51Z" fill="#AFAFAF"/>
      <path d="M176.23 167.11H41.7695V6.75977H176.23V167.11ZM43.1295 165.75H174.88V8.10977H43.1295V165.75Z" fill="#AFAFAF"/>
      <path d="M113.059 7.9707H111.699V166.141H113.059V7.9707Z" fill="#AFAFAF"/>
      <path d="M106.301 7.9707H104.941V166.141H106.301V7.9707Z" fill="#AFAFAF"/>
      <path d="M183.412 185.461H36.0117V187.461H183.412V185.461Z" fill="#991914"/>
      <path d="M37.0117 181.961H35.0117V190.961H37.0117V181.961Z" fill="#991914"/>
      <path d="M184.41 181.961H182.41V190.961H184.41V181.961Z" fill="#991914"/>
      <path d="M36 187H33.13V186H36V187ZM31.27 187H28.4V186H31.27V187ZM26.53 187H23.67V186H26.53V187ZM21.8 187H18.93V186H21.8V187ZM17.06 187H14.2V186H17.06V187ZM12.33 187H9.46V186H12.33V187ZM7.6 187H4.73V186H7.6V187ZM2.86 187H0V186H2.86V187Z" fill="#991914"/>
      <path d="M216 187H213V186H216V187ZM211 187H208V186H211V187ZM206 187H203V186H206V187ZM201 187H198V186H201V187ZM196 187H193V186H196V187ZM191 187H188V186H191V187ZM186 187H183V186H186V187Z" fill="#991914"/>
    </svg>
  );
}

export default SvgFinestraDimensioneDopo;
