import * as React from "react";

function SvgDescktopCheck(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 116 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#descktop-check_svg__clip0)">
        <path
          d="M105.936 8H4.063c-1.126 0-2.038.9-2.038 2.012v72.91c0 1.112.912 2.013 2.038 2.013h101.873c1.126 0 2.039-.901 2.039-2.012V10.012c0-1.111-.913-2.012-2.039-2.012z"
          stroke="#1D1D1B"
          strokeWidth={3.1}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M86.808 90.016H23.192c-1.126 0-2.039.9-2.039 2.012v1.96c0 1.111.913 2.012 2.039 2.012h63.616c1.126 0 2.039-.9 2.039-2.012v-1.96c0-1.112-.913-2.012-2.039-2.012zM54.994 16.976c1.11 0 2.012-.889 2.012-1.986s-.901-1.986-2.012-1.986c-1.112 0-2.013.89-2.013 1.986 0 1.097.901 1.986 2.013 1.986z"
          fill="#1D1D1B"
        />
      </g>
      <path
        d="M106 20c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z"
        fill="#76B82D"
      />
      <path
        d="M104.851 14a.761.761 0 01-.515-.226l-3.325-3.305a.642.642 0 010-.95c.327-.272.749-.272 1.03 0l2.763 2.807 4.777-5.297a.694.694 0 01.983-.046c.281.227.328.68.047.951l-5.245 5.84a.765.765 0 01-.515.227z"
        fill="#fff"
      />
      <defs>
        <clipPath id="descktop-check_svg__clip0">
          <path fill="#fff" transform="translate(0 6)" d="M0 0h110v90H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgDescktopCheck;
