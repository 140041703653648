import { Grid, makeStyles } from '@material-ui/core';
import '../styles/loadingAnimation.css';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  animationRoot: {
    position: 'relative',
    textAlign: 'center',
    width: '200px',
    display: 'flex',
  },
  animation: {
    position: 'relative',
    height: '60px',
    width: '150px',
  },
  label: {
    fontFamily: 'miller',
    fontSize: '18px',
    display: 'block',
    fontStyle: 'italic',
  },
}));

const LoadingAnimation = ({ msg = 'Caricamento Alternative', labelClasses }) => {
  const classes = useStyles();

  return (
    <Grid container justifyContent='center' alignItems='center' className={classes.root}>
      <Grid
        container
        direction='column'
        alignItems='center'
        className={clsx('loading-animation', classes.animationRoot)}
      >
        <Grid item className={classes.animation}>
          <div className='circle'></div>
          <div className='circle'></div>
          <div className='circle'></div>
          <div className='circle'></div>
        </Grid>
        <Grid item className={clsx(classes.label, labelClasses)}>
          {msg}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoadingAnimation;
