import { Grid, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { useRef } from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import OptionCard, { StandardOptionCardBody } from '../../../components/Card/OptionCard';
import ConfiguratorIntro from '../../../components/configurator/ConfiguratorIntro';
import { Zanzariera, FinestraZanzarieraPrima } from '../../../components/Icons';
import ConfiguratorSelectionLayout from '../../../components/Layout/ConfiguratorSelectionLayout';
import ConfiguratorTwoColumnsPlanimetryLayout from '../../../layouts/ConfiguratorTwoColumnsPlanimetryLayout';
import { getLayoutImagesByConfigurationId } from '../../../services/layoutApi';
import { getOption } from '../../../services/optionApi';
import { useStore } from '../../../store/storeUtils';
import {
  handleCardMouseEnterSimple,
  handleCardMouseLeaveSimple,
  resumeAnswerSimple,
} from '../../../utils/configuratorUtils';
import { prepareOptionData } from '../../../utils/optionsUtils';
import { handleSave, multipleChoiceSelection } from '../../../utils/selectionUtils';
import DefaultChoiceImgText from './currentConfiguration/DefaultChoiceImgText';
import { handleOptionPlanimetryLayers } from '../../../utils/optionsUtils';

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: '#EEEEEE',
    minHeight: '100%',
  },
  list: { marginTop: '25px', padding: '0 22px 22px' },
  alertMessage: { marginBottom: '5px' },
}));

const FirstColumn = ({ defaultOption, onClick, selectedOptions }) => {
  return defaultOption ? (
    <DefaultChoiceImgText
      icon={<FinestraZanzarieraPrima />}
      isSelected={_.isEqual(defaultOption.ids, selectedOptions)}
      onClick={() => {
        onClick(defaultOption.ids);
      }}
      message="Nell'appartamento non sono previste zanzariere."
    />
  ) : (
    ''
  );
};

const SecondColumn = ({
  options,
  selectedOptions,
  onClick,
  cardOnMouseEnter,
  cardOnMouseLeave,
}) => {
  const classes = useStyles();

  return (
    <ConfiguratorSelectionLayout>
      <div className={classes.content}>
        <ConfiguratorIntro
          icon={<Zanzariera />}
          title={'Vuoi aggiungere un sistema di zanzariere?'}
          // badgeText={'Puoi selezionare una o più opzioni'}
        />
        <Grid container className={classes.list} spacing={2} justifyContent='center'>
          {options.map((el, i) => (
            <Grid item key={i} xs={6} lg={4}>
              <OptionCard
                {...el}
                onClick={() => onClick(el.ids)}
                isSelected={_.difference(el.ids, selectedOptions).length === 0}
                onMouseEnter={cardOnMouseEnter}
                onMouseLeave={cardOnMouseLeave}
                multiple
                infoPriceMessage={ el.displayName?.includes('Loggia') ? "Il prezzo comprendere la fornitura e la posa delle zanzariere sul filo esterno della loggia, compreso interruttori di comando elettrico."
              : "Il prezzo comprende la fornitura e la posa delle zanzariere su tutti gli infissi del tuo appartamento."}
              >
                <StandardOptionCardBody {...el.options[0]} hidePositions />
              </OptionCard>
            </Grid>
          ))}
        </Grid>
      </div>
    </ConfiguratorSelectionLayout>
  );
};

const InfissiZanzariere = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const [defaultOption, setDefaultOption] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [images, setImages] = useState([]);

  const substepIdRef = useRef();
  const oldAnswersRef = useRef();
  const showLayerOnHoverRef = useRef(true);
  const baseImagesRef = useRef([]);
  const baseImagesWithOptionsRef = useRef([]);

  const store = useStore();
  const params = useParams();

  useEffect(() => {
    store.setUserCanGoNext(false);
    prepareOptions();
  }, []);

  const prepareOptions = async () => {
    const substepId = store.getSubstepIdByFrontendId(store.currentSubstep);

    let optionData = await getOption(
      store.currentConfiguration?.layout?.id,
      substepId,
      params.configuration,
    );
    substepIdRef.current = optionData?.data.substep.id;

    optionData = prepareOptionData(optionData);

    // Imposto i layer da far vedere
    const layoutImagesResponse = await getLayoutImagesByConfigurationId(params.configuration);
    const layoutImages = layoutImagesResponse.data;

    const loggiaTag = store.currentConfiguration?.layout?.tags?.find(el => el.key === 'LOGGIA')?.fileLabels;
    const finestreTag = store.currentConfiguration?.layout?.tags?.find(el => el.key === 'FINESTRE')?.fileLabels;

    baseImagesRef.current = layoutImages;
    baseImagesWithOptionsRef.current = layoutImages;

    setImages(layoutImages);

    optionData.map((el) => {
      // FINESTRE
      if (el.value === "WZ1") {
        finestreTag.forEach((tag) => {
          el.fileLabels.push(tag)
        });
      }
      // LOGGIA
      if (el.value === "WZ2") {
        loggiaTag.forEach((tag) => {
          el.fileLabels.push(tag)
        });
      }
    });

    setOptions(
      optionData
        .filter((el) => !el.isDefault)
        ?.sort((a, b) => a?.positions[0] - b?.positions[0]), // Ordinamento opzioni
    );
    const defaultOption = optionData.find((el) => el.isDefault);
    setDefaultOption(defaultOption);

    resumeAnswerSimple(store, oldAnswersRef, substepIdRef, setSelectedOptions, setIsLoading);
  };

  const handleUserSelection = (selection) => {
    multipleChoiceSelection(
      selection,
      selectedOptions,
      defaultOption.ids,
      setSelectedOptions,
      store,
    );
  };

  useEffect(() => {
    const save = async () => {
      await handleSave(selectedOptions, params.configuration, substepIdRef.current, store);
    };
    if (!_.isEqual(oldAnswersRef.current, selectedOptions)) {
      store.setSubstepSave(save);
    } else {
      store.setSubstepSave(null);
    }
  }, [selectedOptions]);

  useEffect(() => {
    handleOptionPlanimetryLayers(
      options,
      defaultOption?.ids,
      selectedOptions,
      showLayerOnHoverRef,
      baseImagesRef,
      setImages,
      baseImagesWithOptionsRef,
    );
  }, [selectedOptions]);

  const handleCardMouseEnter = (fileLabels) => {
    handleCardMouseEnterSimple(
      fileLabels,
      showLayerOnHoverRef,
      baseImagesWithOptionsRef,
      setImages,
    );
  };
  const handleCardMouseLeave = () => {
    handleCardMouseLeaveSimple(showLayerOnHoverRef, baseImagesWithOptionsRef, setImages);
  };

  return (
    <ConfiguratorTwoColumnsPlanimetryLayout
      planimetryImages={images}
      optionLayersOnTop
      firstColumn={
        <FirstColumn
          defaultOption={defaultOption}
          selectedOptions={selectedOptions}
          onClick={handleUserSelection}
        />
      }
      secondColumn={
        <SecondColumn
          options={options}
          selectedOptions={selectedOptions}
          onClick={handleUserSelection}
          cardOnMouseEnter={handleCardMouseEnter}
          cardOnMouseLeave={handleCardMouseLeave}
        />
      }
      firstRowTitle={'IL TUO'}
      secondRowTitle={'APPARTAMENTO'}
      secondColumnFirstRowTitle={'SOLUZIONI'}
      secondColumnSecondRowTitle={'AGGIUNTIVE'}
      isLoading={isLoading}
      noOption={options?.length === 0}
      noOptionIcon={<Zanzariera />}
      noOptionText={'Non ci sono altre opzioni per le zanzariere.'}
    />
  );
};

export default InfissiZanzariere;
