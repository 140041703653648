import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import PlateContainer from './Card/PlateContainer';
import clsx from 'clsx';
import { ResumeCurrencyFormatter } from '../utils/CurrencyFormatter';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '16px',
    backgroundColor: ({ status }) =>
      status === 'disabled' || status === 'comingSoon' ? '#f6f6f6' : '#F9F3EE',
    '&:first-child': {
      marginTop: '0px',
    },
    '& .MuiTableRow-root': {
      height: 'initial',
    },
    '& .MuiTableCell-body': {
      padding: '10px 15px',
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '15px',
    padding: '16px 0 0 14px',
    '& .circle': {
      width: '32px',
      height: '32px',
      border: ({ status }) =>
        status === 'disabled' || status === 'comingSoon'
          ? `2px solid #878787`
          : `2px solid ${theme.palette.secondary.main}`,
      borderRadius: '50%',
      [theme.breakpoints.down('lg')]: {
        width: '31px',
        height: '31px',
      },
      [theme.breakpoints.down('md')]: {
        width: '30px',
        height: '30px',
      },
    },
    '&.disabled': {
      paddingBottom: '16px',
    },
    '& .heading': {
      display: 'flex',
      flexDirection: 'column',
      fontWeight: '600',
      fontSize: '18px',
      lineHeight: '22px',
      textTransform: 'uppercase',
      color: ({ status }) =>
        status === 'disabled' || status === 'comingSoon' ? '#878787' : theme.palette.color.black,
      '& .disabledText': {
        fontFamily: 'miller',
        fontStyle: 'italic',
        fontWeight: '500',
        fontSize: '13px',
        lineHeight: '15px',
        textTransform: 'initial',
        color: '#878787',
      },
      '& .coming-soon-text': {
        fontWeight: '500',
        fontSize: '13px',
        lineHeight: '15px',
        textTransform: 'initial',
        color: '#F08219',
        fontFamily: 'p22-underground',
      },
    },
  },
  table: {
    marginBottom: '20px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  valueCell: {
    fontFamily: 'p22-underground',
    fontSize: '15px',
    lineHeight: '18px',
    textAlign: 'right',
    color: ({ status }) => status === 'disabled' && '#878787',
  },
  defaultCell: {
    width: '1%',
    fontFamily: 'miller',
    fontStyle: 'italic',
    fontSize: '13px',
    lineHeight: '16px',
    color: ({ status }) => (status === 'disabled' ? '#878787' : theme.palette.primary.main),
    padding: '8px 16px',
  },
  nameCell: {
    fontFamily: 'p22-underground',
    fontWeight: '500',
    fontSize: '13px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    color: ({ status }) => status === 'disabled' && '#878787',
  },
  descriptionCell: {
    fontFamily: 'p22-underground',
    padding: '8px 16px',
    textTransform: 'uppercase',

    color: ({ status }) => status === 'disabled' && '#878787',
    '& .title': {
      fontWeight: '500',
      fontSize: '13px',
      lineHeight: '16px',
    },
    '& .subtitle': {
      fontWeight: '500',
      fontSize: '13px',
      lineHeight: '16px',
      textTransform: 'uppercase',
    },
    '& .listTitle': {
      fontSize: '11px',
      lineHeight: '13px',
    },
    '& .plates': {
      paddingTop: '4px',
      '&.no-padding': {
        paddingTop: 0,
      },
    },
    '& .options': {
      fontSize: '11px',
      lineHeight: '13px',
      '& .option:not(first-of-type)': {
        marginTop: '3px',
      },
    },
    '&.extra-space': {
      padding: '10px 0',
    },
  },
  valuesCell: {
    fontFamily: 'p22-underground',
    fontSize: '13px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    textAlign: 'right',
    color: ({ status }) => (status === 'disabled' ? '#878787' : theme.palette.primary.main),
    '&.default': {
      color: '#000',
    },
  },
  totalCell: {
    fontFamily: 'p22-underground',
    fontWeight: '750',
    fontSize: '20px',
    lineHeight: '24px',
    textTransform: 'uppercase',
    textAlign: 'right',
    color: ({ status }) => (status === 'disabled' ? '#878787' : theme.palette.primary.main),
  },
  lessPadding: {
    padding: '10px 16px 8px',
  },
}));

const SummarySheet = ({ sheet }) => {
  const { status } = sheet;
  const classes = useStyles({ status });

  const [lightKind, setLightKind] = useState({});

  const getFinalPrice = () => {
    return sheet.rows
      .map((row) => row.price)
      .flat()
      .map(Number)
      .reduce((prev, curr) => prev + curr, 0);
  };

  const defaultDoorHeight = 210;
  const extraDoorHeight = 240;

  useEffect(() => {
    const mainLight = sheet?.rows?.find((row) => row.key === 'Luci_LuciComodinoCameraPrincipale');
    if (mainLight) {
      setLightKind(mainLight.additionalProperties);
    }
  }, []);

  const renderRow = (row, i, status) => {
    switch (row.key) {
      case 'Cassaforte_Cassaforte':
        return <RowPlates row={row} key={i} status={status} hideDescription />;
      case 'Loggia_LavaboEsterno':
        return <RowPlates row={row} key={i} status={status} hideDescription />;
      case 'Porte_TipologiaPorte':
        return (
          <RowPlates
            row={row}
            key={i}
            status={status}
            description={
              row.description?.toLowerCase().includes('battente') ? 'Battenti:' : 'Scorrevoli:'
            }
          />
        );
      case 'Porte_StilePorte':
        return (
          <RowPlates
            row={row}
            key={i}
            status={status}
            description={
              !row.isDefault ? 'Rasomuro:' : 'Telaio Visibile:'
            }
          />
        );
      case 'Porte_AltezzaPorte':
        return (
          <RowPlates
            row={row}
            key={i}
            status={status}
            description={row.isDefault ? `${defaultDoorHeight}cm:` : `${extraDoorHeight}cm:`}
          />
        );
        case 'Porte_PorteGrezze':
          return row?.isDefault ? '' : (
            <RowPlates
              row={row}
              key={i}
              status={status}
              description={'Porte Grezze'}
            />
          );
      case 'Finestre_OpacitaFinestre':
        return <RowPlates row={row} key={i} status={status} description={row.isDefault ? '' : 'Finestre opache'} useAggiuntaLabel />;
      case 'Finestre_DimensioneFinestre':
        return row?.isDefault ? '' : <WindowDimensionRow row={row} key={i} status={status} />;
      case 'Finestre_ZanzariereFinestre':
        return row?.isDefault ? <RowPlates row={row} key={i} status={status} description={row.isDefault ? '' : 'Finestre opache'} useAggiuntaLabel /> : <SimpleRow row={row} key={i} status={status} showName />;
      case 'Luci_LuciComodinoCameraPrincipale':
        return (
          <SimpleRow
            row={row}
            key={i}
            status={status}
            description={
              row?.additionalProperties?.aSoffitto
                ? `Luci comodino soffitto`
                : row?.additionalProperties?.aParete
                ? 'Luci comodino parete'
                : 'Nessuna aggiunta'
            }
            hide
          />
        );
      case 'Luci_LuciComodinoAltreCamere':
        return <RowPlates row={row} key={i} status={status} hideDescription useAggiuntaLabel />;
      case 'Luci_GoleLuminose':
        return <RowPlates row={row} key={i} status={status} hideDescription useAggiuntaLabel />;
      case 'Luci_PuntiLuceExtra':
        return (
          <RowPlates
            row={row}
            key={i}
            status={status}
            usePositions
            hideDescription
            useAggiuntaLabel
            zeroNotPosition
          />
        );
      case 'Luci_IlluminazioneArmadi':
        return <RowPlates row={row} key={i} status={status} hideDescription useAggiuntaLabel />;
      case 'ImpiantoIlluminotecnico_PreseUsb':
        return <RowPlates row={row} key={i} status={status} useAggiuntaLabel />;
      case 'Prese_PresePianoCottura':
        return <RowPlates row={row} key={i} status={status} hideDescription useAggiuntaLabel />;
      case 'ImpiantoIlluminotecnico_PresePianoCucina':
        return <RowPlates row={row} key={i} status={status} useAggiuntaLabel />;
      case 'Prese_PreseLoggia':
        return <RowPlates row={row} key={i} status={status} hideDescription useAggiuntaLabel />;
      case 'ImpiantoIlluminotecnico_PreseGruppoTv':
        return <RowPlates row={row} key={i} status={status} useAggiuntaLabel />;
      case 'Prese_PreseLavanderia':
        return <RowPlates row={row} key={i} status={status} hideDescription useAggiuntaLabel />;
      case 'Luci_PreseComandate':
        return <RowPlates row={row} key={i} status={status} hideDescription useAggiuntaLabel />;
      case 'Prese_PreseUsb':
        return <RowPlates row={row} key={i} status={status} hideDescription useAggiuntaLabel />;
      case 'Prese_PreseTv':
        return <RowPlates row={row} key={i} status={status} hideDescription useAggiuntaLabel />;
      case 'Temperatura_Temperatura':
        return row?.isDefault ? '' :  <RowPlates row={row} key={i} status={status} description={row.isDefault ? '' : 'Termostato'} useAggiuntaLabel/>;
      case 'SmartHome_SmartHome':
        return <SmartHomeRow row={row} key={i} status={status} useAggiuntaLabel/>;
      default:
        return <SimpleRow row={row} key={i} status={status} useAggiuntaLabel/>;
    }
  };

  const getTitles = () => {
    const titles = sheet.rows?.map((row) => row.title && row.title);
    return [...new Set(titles)];
  };

  return (
    <TableContainer className={classes.root}>
      <div
        className={clsx(
          classes.header,
          (sheet.status === 'disabled' || sheet.status === 'comingSoon') && 'disabled',
        )}
      >
        <span className='circle'></span>
        <div className='heading'>
          {sheet.status === 'disabled' ? (
            <>
              <span>{sheet.title}</span>
              <span className='disabledText'>Non hai ancora effettuato le tue scelte</span>
            </>
          ) : sheet.status === 'comingSoon' ? (
            <>
              <span>{sheet.title}</span>

              <span className='disabledText'>
                <span className='coming-soon-text'>Coming soon.</span> Prossimamente potrai
                personalizzarli.
              </span>
            </>
          ) : (
            <span>{sheet.title}</span>
          )}
        </div>
      </div>
      {getTitles() ? (
        getTitles().map((title, i) => (
          <Table key={i} aria-label='simple table' className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>{title}</TableCell>
                <TableCell></TableCell>
                <TableCell className={classes.valueCell}>Valore</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sheet.rows.map((row, i) => row.title === title && renderRow(row, i, sheet.status))}
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell className={classes.totalCell}>
                  {ResumeCurrencyFormatter(getFinalPrice())}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ))
      ) : (
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell className={classes.valueCell}>Valore</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sheet.rows.map((row, i) => renderRow(row, i))}
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell className={classes.totalCell}>
                {ResumeCurrencyFormatter(getFinalPrice())}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default SummarySheet;

const stepSubstepName = (stepName, substepName) => {
  if (stepName === substepName) {
    return substepName;
  } else {
    return `${stepName}: ${substepName}`;
  }
};

const SmartHomeRow = ({ row, status, showName }) => {
  const classes = useStyles({ status });

  const description = row.description?.toLowerCase().includes('nessuna')
    ? 'NESSUNA AGGIUNTA'
    : row.description;

  return (
    <TableRow>
      <TableCell className={classes.nameCell}>
        {stepSubstepName(row.step.name, row.substep.name)}
      </TableCell>
      <TableCell className={classes.descriptionCell}>
        <div className='subtitle'>{!showName ? description : row.name}</div>
      </TableCell>
      <TableCell className={clsx(classes.valuesCell, row.isDefault && 'default')}>
        <div>{ResumeCurrencyFormatter(row.price)}</div>
      </TableCell>
    </TableRow>
  );
};

const SimpleRow = ({ row, status, showName, description }) => {
  const classes = useStyles({ status });

  return (
    <TableRow>
      <TableCell className={classes.nameCell}>
        {stepSubstepName(row.step.name, row.substep.name)}
      </TableCell>
      <TableCell className={classes.descriptionCell}>
        <div className='subtitle'>
          {description ? description : !showName ? row.description : row.name}
        </div>
      </TableCell>
      <TableCell className={clsx(classes.valuesCell, row.isDefault && 'default')}>
        <div>{ResumeCurrencyFormatter(row.price)}</div>
      </TableCell>
    </TableRow>
  );
};

const RowPlates = ({
  row,
  status,
  name,
  hideDescription,
  usePositions,
  description,
  useAggiuntaLabel,
  zeroNotPosition,
}) => {
  const classes = useStyles({ status });

  const positions = !usePositions
    ? row.numbers.map((el) => el.number).sort((a, b) => a - b)
    : row.positions.length > 0
    ? row.positions.sort((a, b) => a - b)
    : zeroNotPosition && row.position === 0 ? [] : [row.position];

  return (
    <TableRow>
      <TableCell className={classes.nameCell}>
        {name ? name : stepSubstepName(row.step.name, row.substep.name)}
      </TableCell>
      <TableCell className={clsx(classes.descriptionCell)}>
        {positions?.length > 0 ? (
          <>
            {!hideDescription && (
              <>
                <div className='title'>{description ? description : row.description}</div>
                <div className='subtitle'>{row.description.subtitle}</div>
              </>
            )}

            <div className={clsx('plates', hideDescription && 'no-padding')}>
              <PlateContainer elements={positions} position='start' plateText={'P-'} small />
            </div>
          </>
        ) : (
          <div className='subtitle'>
            {useAggiuntaLabel ? 'Nessuna aggiunta' : 'Nessuna Opzione'}
          </div>
        )}
      </TableCell>
      <TableCell className={clsx(classes.valuesCell, row.isDefault && 'default')}>
        <div>{ResumeCurrencyFormatter(row.price)}</div>
      </TableCell>
    </TableRow>
  );
};

const LayoutRow = ({ row, status }) => {
  const classes = useStyles({ status });
  return (
    <TableRow>
      <TableCell className={classes.nameCell}>{row.step.value}</TableCell>
      <TableCell className={clsx(classes.descriptionCell, 'extra-space')}>
        <div className='title'>{row.description.title}</div>
        <div className='options'>
          {row.description.options.map((option, i) => (
            <div key={i} className='option'>
              + {option}
            </div>
          ))}
        </div>
      </TableCell>
      <TableCell className={classes.valuesCell}>
        {row.values.map((value, i) => (
          <div key={i} style={i === 0 ? { visibility: 'hidden' } : {}}>
            {ResumeCurrencyFormatter(value)}
          </div>
        ))}
      </TableCell>
    </TableRow>
  );
};

const WindowDimensionRow = ({ row, status }) => {
  const classes = useStyles({ status });

  return (
    <TableRow>
      <TableCell className={classes.nameCell}>Infisso {row.numbers[0]?.number}</TableCell>
      <TableCell className={classes.descriptionCell}>
        <div className='subtitle'>{row.width} CM</div>
      </TableCell>
      <TableCell className={clsx(classes.valuesCell, row.isDefault && 'default')}>
        <div>{ResumeCurrencyFormatter(row.price)}</div>
      </TableCell>
    </TableRow>
  );
  /*(
  <TableRow>
    <TableCell className={classes.nameCell}>Infisso {row.numbers[0]?.number}</TableCell>
    <TableCell className={classes.descriptionCell}>
      <div className='subtitle'>
        {'Nessuna Opzione'}
      </div>
    </TableCell>
    <TableCell className={clsx(classes.valuesCell, row.isDefault && 'default')}>
      <div>{ResumeCurrencyFormatter(row.price)}</div>
    </TableCell>
  </TableRow>);
  */
};

const WindowOpacityRow = ({ row, status }) => {
  const classes = useStyles({ status });

  return (
    <TableRow>
      <TableCell className={classes.nameCell}>Infisso {row.numbers[0]?.number}</TableCell>
      <TableCell className={classes.descriptionCell}>
        <div className='subtitle'>{row.name}</div>
      </TableCell>
      <TableCell className={clsx(classes.valuesCell, row.isDefault && 'default')}>
        <div>{ResumeCurrencyFormatter(row.price)}</div>
      </TableCell>
    </TableRow>
  );
};

const WindowZanzarieraRow = ({ row, status }) => {
  const classes = useStyles({ status });

  return (
    <TableRow>
      <TableCell className={classes.nameCell}>Infisso</TableCell>
      <TableCell className={classes.descriptionCell}>
        <div className='subtitle'>{row.name}</div>
      </TableCell>
      <TableCell className={clsx(classes.valuesCell, row.isDefault && 'default')}>
        <div>{ResumeCurrencyFormatter(row.price)}</div>
      </TableCell>
    </TableRow>
  );
};

const SafeRow = ({ row, status }) => {
  const classes = useStyles({ status });

  return (
    <TableRow>
      <TableCell className={classes.nameCell}>CASSAFORTE</TableCell>
      <TableCell className={classes.descriptionCell}>
        <div className='subtitle'>POSIZIONE {row.numbers[0]?.number}</div>
      </TableCell>
      <TableCell className={classes.valuesCell}>
        <div>{ResumeCurrencyFormatter(row.price)}</div>
      </TableCell>
    </TableRow>
  );
};
