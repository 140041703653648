import { Page, StyleSheet, Text, View, Image } from '@react-pdf/renderer';

const infoNew = [
  {
    phase: 'Layout',
    step: 'DISTRIBUZIONE INTERNA',
    description: 'Dipende dal layout scelto. ',
  },
  {
    phase: 'Layout',
    step: 'PORTE: TIPOLOGIA',
    description:
      'Il prezzo comprende la sostituzione della porta a battente con la porta scorrevole, inclusa la fornitura e la posa in opera del cassonetto interno muro.',
  },
  {
    phase: 'Layout',
    step: 'PORTE: STILE',
    description:
      'Il prezzo comprende la sostituzione della porta con coprifilo con il modello senza coprifilo, inclusa la fornitura e la posa in opera del telaio.',
  },
  {
    phase: 'Layout',
    step: 'PORTE: ALTEZZA',
    description: 'Il prezzo comprende la sostituzione della porta alta 210cm con la porta alta 240cm.',
  },
  {
    phase: 'Layout',
    step: 'BAGNO CON VASCA',
    description: 
      'VASCA DA INCASSO: Il prezzo comprende la fornitura e la posa in opera della vasca da incasso, incluso la realizzazione del muretto rivestito in gres a completamento del fronte della vasca. Incluso rubinetteria per vasca a parete a scelta tra le collezioni di capitolato. Il prezzo è calcolato in sostituzione del piatto doccia e della rubinetteria previste nella tua configurazione di partenza. Potrai personalizzare il modello e la finitura della rubinetteria in una fase successiva.',
  },
  {
    phase: 'Layout',
    step: 'BAGNO CON VASCA',
    description:
      "VASCA IDROMASSAGGIO: Il prezzo comprende la fornitura e la posa in opera della vasca idromassaggio inclusa di realizzazione della componente elettrica costituita da un differenziale collegato al quadro generale dell'appartamento. Incluso rubinetteria per vasca a parete a scelta tra le collezioni di capitolato. Il prezzo è calcolato in sostituzione del piatto doccia e della rubinetteria previsti nella tua configurazione di partenza. Potrai personalizzare le finiture e gli optional della vasca e il modello e la finitura della rubinetteria in una fase successiva."
  },
  {
    phase: 'Layout',
    step: 'DISTRIBUZIONE CUCINA',
    description: 'Dipende dalla configurazione scelta. Il prezzo comprende eventuali punti presa per elettrodomestici aggiunti rispetto alla configurazone di partenza e/o eventuale rinforzo cappa a soffitto per i layout che prevedono il piano cottura su isola o penisola.',
  },
  {
    phase: 'Layout',
    step: 'FINESTRE: OPACITÀ',
    description: 'Il prezzo comprende la sostituzione del vetro trasparente con il vetro opaco.'
  },
  {
    phase: 'Layout',
    step: 'FINESTRE: ZANZARIERE',
    description: 'Il prezzo comprende la fornitura e la posa delle zanzariere su tutti gli infissi del tuo appartamento.'
  },
  {
    phase: 'Layout',
    step: 'CASSAFORTE',
    description: "Il prezzo comprende la fornitura e la posa in opera della cassaforte, incluso le lavorazioni edili e i rinforzi per l'installazione ad incasso nella parete."
  },
  {
    phase: 'Tecnologia',
    step: 'LUCI: CAMERA PRINCIPALE',
    description: 'Il prezzo comprende la realizzazione di n°1 punto luce e n°1 interruttore per comodino. La fornitura delle lampade è esclusa.'
  },
  {
    phase: 'Tecnologia',
    step: 'LUCI: ALTRE CAMERE',
    description: 'Il prezzo comprende la realizzazione di n°1 punto luce e n°1 interruttore per comodino. La fornitura delle lampade è esclusa.'
  },
  {
    phase: 'Tecnologia',
    step: 'LUCI: GOLE LUMINOSE',
    description: 
      'Il prezzo comprende la realizzazione di n°1 punto luce a soffitto e n°1 interruttore. Compresa la realizzazione di un controsoffitto e delle lavorazioni edili necessarie. La fornitura delle lampade è esclusa.'
  },
  {
    phase: 'Tecnologia',
    step: 'LUCI: ILLUMINAZIONE ARMADI',
    description: "l prezzo comprende la realizzazione di uno o più punti luce a parete a seconda della configurazione dell'arredo. La fornitura delle lampade è esclusa e potrai selezionarla insieme con l'arredo."
  },
  {
    phase: 'Tecnologia',
    step: 'LUCI: PRESE COMANDATE',
    description: 'Il prezzo comprende la fornitura e la posa in opera di n°1 presa elettrica 10A comandata da n°1 interruttore. La fornitura delle lampade è esclusa.'
  },
  {
    phase: 'Tecnologia',
    step: 'PRESE: PRESE SCHUKO',
    description: 'Il prezzo comprende la fornitura e la posa in opera di n°1 presa Schuko, incluso scatola elettrica.'
  },
  {
    phase: 'Tecnologia',
    step: 'PRESE: ZONA LAVANDERIA',
    description: 'Il prezzo comprende la fornitura e la posa in opera di n°1 presa Schuko, incluso scatola elettrica e interruttore.'
  },
  {
    phase: 'Tecnologia',
    step: 'PRESE: ESTERNO',
    description: 'Il prezzo comprende la fornitura e la posa in opera di n°1 presa Schuko, incluso scatola elettrica da esterni stagna.'
  },
  {
    phase: 'Tecnologia',
    step: 'PRESE: USB',
    description: 'Il prezzo comprende la fornitura e la posa in opera di n°1 presa USB, incluso scatola elettrica.'
  },
  {
    phase: 'Tecnologia',
    step: 'PRESE: TV',
    description: 'Il prezzo comprende la fornitura e la posa in opera di n°3 prese 10/16A, n°1 presa TV /SAT e n°1 presa RJ45, incluso scatole elettriche.'
  },
  {
    phase: 'Tecnologia',
    step: 'SMART HOME: PORTA BLINDATA CONNESSA',
    description: 'Il prezzo comprende la sostituzione del portoncino previsto nella tua configurazione di partenza con un portoncino con serratura motorizzata, tastierino per digitazione codice ingresso e app per il controllo da remoto.'
  },
  {
    phase: 'Tecnologia',
    step: 'SMART HOME: CARICHI SMART',
    description: "Il prezzo comprende l'installazione nel quadro elettrico di un dispositivo per la gestione dei carichi che consente di controllare la potenza impegnata e di attivare, in caso di sovraccarico, il distacco automatico dei carichi non prioritari. Il sistema prevede la gestione di n°4 carichi: forno, lavastoviglie, lavatrice, piano cottura."
  },
  {
    phase: 'Tecnologia',
    step: 'SMART HOME: LUCI SMART',
    description: "Il prezzo comprende la sostituzione di tutti i comandi delle luci con comandi connessi. Il prezzo è calcolato sul totale delle luci presenti nell'appartamento, escluso eventuale illuminazione armadi."
  },
  {
    phase: 'Tecnologia',
    step: 'SMART HOME: TAPPARELLE SMART',
    description: "Il prezzo comprende la sostituzione di tutti i comandi delle tapparelle con comandi connessi. Il prezzo è calcolato sul totale delle finestre presenti nell'appartamento."
  },
  {
    phase: 'Tecnologia',
    step: 'SMART HOME: VIDEOCITOFONO CONNESSO',
    description: "Il prezzo comprende la sostituzione del videocitofono Bticino Classe 100 previsto nella tua configurazione di partenza con un modello connesso."
  },
  {
    phase: 'Tecnologia',
    step: 'SMART HOME: INSTALLAZIONE E ATTIVAZIONE GATEWAY',
    description: "Il prezzo comprende il costo di installazione e attivazione del Gateway. Il prezzo viene esposto selezionando almeno un pacchetto a scelta tra TAPPARELLE SMART, LUCI SMART, VIDEOCITOFONO CONNESSO o CARICHI SMART."
  },
  {
    phase: 'GiornoENotte',
    step: 'PAVIMENTO LIVING E CAMERE',
    description: "Il prezzo è riferito alla somma dei metri quadri degli ambienti della zona giorno e notte, escluso i locali bagno, cucina e lavanderia."
  },
  {
    phase: 'GiornoENotte',
    step: 'PAVIMENTO CUCINA',
    description: "Il prezzo è riferito alla metratura del locale cucina."
  },
  {
    phase: 'GiornoENotte',
    step: 'PORTE: COLORE',
    description: "Il prezzo è riferito al totale delle porte presenti nell'appartamento, escluso eventuali porte d'arredo e porte grezze selezionati nella fase Layout."
  },
  {
    phase: 'GiornoENotte',
    step: 'PORTE: MANIGLIA',
    description: "Il prezzo è riferito al totale delle porte presenti nell'appartamento, escluso eventuali porte d'arredo."
  },
  {
    phase: 'Bagno',
    step: 'SCHEMA RIVESTIMENTO',
    description: "Il prezzo comprende le lavorazioni, la fornitura e la posa del materiale necessario al raggiungimento dell’altezza dello schema rivestimento scelto."
  },
  {
    phase: 'Bagno',
    step: 'RIVESTIMENTI',
    description: "Il prezzo comprende la fornitura e la posa del materiale selezionato per il pavimento e il rivestimento del bagno, calcolato in sostituzione a quanto previsto all'interno del capitolato. La quantità del materiale è calcolata sulla base dello schema rivestimento selezionato in precedenza."
  },
  {
    phase: 'Bagno',
    step: 'MOBILE BAGNO: MODELLO',
    description: "Il prezzo comprende la fornitura e la posa della composizione del mobile bagno selezionata, calcolato in sostituzione alla composizione prevista all'interno del capitolato (My Time 70_01)"
  },
  {
    phase: 'Bagno',
    step: 'MOBILE BAGNO: FINITURA',
    description: "Il prezzo è relativo alla finitura della composizione del mobile bagno selezionata."
  },
  {
    phase: 'Bagno',
    step: 'SANITARI',
    description: "Il prezzo comprende la fornitura e la posa modello di sanitari selezionato, calcolato in sostituzione ai modelli previsti all'interno del capitolato"
  },
  {
    phase: 'Bagno',
    step: 'DOCCIA',
    description: "Il prezzo comprende la fornitura e la posa modello di sanitari selezionato, calcolato in sostituzione ai modelli previsti all'interno del capitolato."
  },
  {
    phase: 'Bagno',
    step: 'VASCA',
    description: "Il prezzo comprende la fornitura e la posa della vasca selezionata, in sostituzione alla vasca base selezionata nella fase layout."
  },
  {
    phase: 'Bagno',
    step: 'RUBINETTERIA: MODELLO',
    description: "Il prezzo comprende la fornitura e la posa di tutti i prodotti inclusi nel set di rubinetteria selezionato, calcolato in sostituzione ai modelli previsti all'interno del capitolato."
  },
  {
    phase: 'Bagno',
    step: 'RUBINETTERIA: FINITURA',
    description: "Il prezzo è relativo alla finitura del set di rubinetteria selezionato."
  },
  {
    phase: 'Bagno',
    step: 'BOX DOCCIA: MODELLO',
    description: "Il prezzo comprende la fornitura e la posa del box doccia in aggiunta."
  },
  {
    phase: 'Bagno',
    step: 'BOX DOCCIA: FINITURA',
    description: "Il prezzo è relativo alla finitura del vetro del box doccia selezionata, in sostituzione al vetro trasparente."
  },
  {
    phase: 'Bagno',
    step: 'ACCESSORI VASCA: SOFFIONE',
    description: "Il prezzo comprende la fornitura e la posa del soffione e del miscelatore a parete in aggiunta alla rubinetteria vasca. Compreso di punto acqua caldo/freddo in aggiunta rispetto alla configurazione di partenza."
  },
  {
    phase: 'Bagno',
    step: 'ACCESSORI VASCA: BOX',
    description: "Il prezzo è relativo alla finitura del vetro del box doccia selezionata, in sostituzione al vetro trasparente."
  },
  {
    phase: 'Bagno',
    step: 'ALTRE DOTAZIONI',
    description: "Il prezzo comprende la fornitura e la posa della coppia di dotazioni selezionata, calcolato in sostituzione al set e alla finitura previsti all'interno del capitolato."
  }
]

const info = [
  {
    step: 'DISTRIBUZIONE INTERNA',
    description: 'Dipende dal layout scelto. ',
  },
  {
    step: 'PORTE: TIPOLOGIA',
    description:
      'Per ogni posizione scelta il prezzo comprende la sostituzione della porta a battente con la porta scorrevole, inclusa la fornitura e la posa in opera del cassonetto interno muro.',
  },
  {
    step: 'PORTE: STILE',
    description:
      'Per ogni posizione scelta il prezzo comprende la sostituzione della porta con coprifilo con il modello senza coprifilo, inclusa la fornitura e la posa in opera del telaio. ',
  },
  {
    step: 'PORTE: ALTEZZA',
    description:
      'Per ogni posizione scelta il prezzo comprende la sostituzione della porta alta 210cm con la porta alta 240cm. ',
  },
  {
    step: 'BAGNO CON VASCA',
    description:
      'VASCA DA INCASSO: Il prezzo comprende la fornitura e la posa in opera della vasca da incasso, incluso la realizzazione del muretto rivestito in gres a completamento del fronte della vasca. Il prezzo è calcolato in sostituzione del piatto doccia e della rubinetteria previste nella tua configurazione di partenza.',
  },
  {
    step: 'BAGNO CON VASCA',
    description:
      'VASCA FREESTANDING: Il prezzo comprende la fornitura e la posa in opera della vasca freestanding e della rubinetteria a pavimento. Il prezzo è calcolato in sostituzione del piatto doccia e della rubinetteria previsti nella tua configurazione di partenza. Potrai personalizzare il modello di vasca e rubinetteria in una fase successiva. Clicca su foto e scheda tecnica per visualizzare i modelli disponibili. ',
  },
  {
    step: 'BAGNO CON VASCA',
    description:
      "VASCA IDROMASSAGGIO: Il prezzo comprende la fornitura e la posa in opera della vasca idromassaggio, incluso la realizzazione della componente elettrica costituita da un differenziale collegato al quadro generale dell'appartamento. Il prezzo è calcolato in sostituzione del piatto doccia e della rubinetteria previsti nella tua configurazione di partenza. Potrai personalizzare le finiture in una fase successiva.",
  },
  {
    step: 'DISTRIBUZIONE CUCINA',
    description:
      'Dipende dalla configurazione scelta. Il prezzo comprende eventuali punti presa per elettrodomestici aggiunti rispetto alla configurazone di partenza e/o eventuale rinforzo cappa a soffitto per i layout che prevedono il piano cottura su isola o penisola. ',
  },
  {
    step: "FINESTRE: OPACITA'",
    description:
      'Per ogni posizione scelta il prezzo comprende la sostituzione del vetro trasparente con il vetro opaco.',
  },
  {
    step: 'CASSAFORTE',
    description:
      "Per ogni posizione scelta il prezzo comprende la fornitura e la posa in opera della cassaforte, incluso le lavorazioni edili e i rinforzi per l'installazione ad incasso nella parete.",
  },
  {
    step: 'LOGGIA: LAVABO ESTERNO',
    description:
      'Per ogni posizione scelta il prezzo comprende la realizzazione del punto acqua fredda e dello scarico a parete. La fornitura del lavabo da esterno è esclusa. ',
  },
  {
    step: 'Luci: Punti luce',
    description:
      'Per ogni posizione scelta il prezzo comprende la realizzazione di n°1 punto luce a soffitto e n°1 interruttore. La fornitura delle lampade è esclusa.',
  },
  {
    step: 'Luci: Camera principale',
    description:
      'LUCI COMODINO PARETE: Il prezzo comprende la realizzazione di n°1 punto luce a parete e n°1 interruttore per comodino. La fornitura delle lampade è esclusa.',
  },
  {
    description:
      'LUCI COMODINO SOFFITTO: Il prezzo comprende la realizzazione di n°1 punto luce a soffitto e n°1 interruttore per comodino. La fornitura delle lampade è esclusa.',
  },
  {
    step: 'Luci: Altre camere',
    description:
      'Il prezzo comprende la realizzazione di n°1 punto luce a parete o a soffitto e n°1 interruttore per comodino (la posizione del punto luce tra "a parete" o "a soffitto" è vincolata alla scelta fatta per la camera principale). La fornitura delle lampade è esclusa.',
  },
  {
    step: 'Luci: Gole luminose',
    description:
      'Per ogni posizione scelta il prezzo comprende la realizzazione di n°1 punto luce a soffitto e n°1 interruttore. Compresa la realizzazione di un controsoffitto e delle lavorazioni edili necessarie. La fornitura delle lampade è esclusa.',
  },
  {
    step: 'Luci: Illuminazione armadi',
    description:
      "Il prezzo comprende la realizzazione di uno o più punti luce a parete a seconda della configurazione dell'arredo. L’accensione avverrà tramite sensore di presenza attivato dall’apertura dell’anta negli armadi, mentre nelle cabine armadio l’accensione verrà collegata della luce principale della stanza; per i mobili della zona giorno l'accensione avverrà tramite interruttore integrato nell'arredo. La fornitura delle lampade è esclusa.",
  },
  {
    step: 'Luci: Prese comandate',
    description:
      'Per ogni posizione scelta il prezzo comprende la fornitura e la posa in opera di n°1 presa elettrica 10A comandata da n°1 interruttore. La fornitura delle lampade è esclusa.',
  },
  {
    step: 'Prese: Piano cottura',
    description:
      'Per ogni posizione scelta il prezzo comprende la fornitura e la posa in opera di n°1 presa Schuko, incluso scatola elettrica. ',
  },
  {
    step: 'Prese: Zona lavanderia',
    description:
      'Per ogni posizione scelta il prezzo comprende la fornitura e la posa in opera di n°1 presa Schuko, incluso scatola elettrica e interruttore.',
  },
  {
    step: 'Prese: Loggia',
    description:
      'Per ogni posizione scelta il prezzo comprende la fornitura e la posa in opera di n°1 presa Schuko, incluso scatola elettrica da esterni stagna.',
  },
  {
    step: 'Prese: USB',
    description:
      'Per ogni posizione scelta il prezzo comprende la fornitura e la posa in opera di n°1 presa USB a due posti compreso caricatore di tipo A e C, incluso scatola elettrica. ',
  },
  {
    step: 'Prese: TV',
    description:
      'Per ogni posizione scelta il prezzo comprende la fornitura e la posa in opera di n°3 prese 10/16A, n°1 presa TV /SAT e n°1  presa RJ45, incluso scatole elettriche.',
  },
  {
    step: 'Smart Home: Tapparelle smart',
    description:
      "Il prezzo comprende la sostituzione di tutti i comandi delle tapparelle con comandi connessi. Il prezzo è calcolato sul totale delle finestre presenti nell'appartamento. ",
  },
  {
    step: 'Smart Home: Luci smart',
    description:
      "Il prezzo comprende la sostituzione di tutti i comandi delle luci con comandi connessi. Il prezzo è calcolato sul totale delle luci presenti nell'appartamento, escluso l’illuminazione dell’esterno ed escluso eventuale illuminazione armadi.",
  },
  {
    step: 'Smart Home: Carichi smart',
    description:
      "Il prezzo comprende l'installazione nel quadro elettrico di un dispositivo per la gestione dei carichi che consente di controllare la potenza impegnata e di attivare, in caso di sovraccarico, il distacco automatico dei carichi non prioritari. Il sistema prevede la gestione di n°4 carichi: forno, lavastoviglie, lavatrice, piano cottura. ",
  },
  {
    step: 'Smart Home: Porta blindata Connessa',
    description:
      'Il prezzo comprende la sostituzione del portoncino previsto nella tua configurazione di partenza con un portoncino con serratura motorizzata, apertura smart con tag o scheda elettronica e app per il controllo da remoto.',
  },
  {
    step: 'Smart Home: Video Citofono Connesso',
    description:
      'Il prezzo comprende la sostituzione del videocitofono Bticino Classe 100 previsto nella tua configurazione di partenza con un modello connesso Bticino 300EOS. ',
  },
  {
    step: 'Smart Home: Installazione e attivazione Gateway',
    description:
      'Il prezzo comprende il costo di installazione e attivazione del Gateway. Il prezzo viene esposto selezionando almeno uno dei pacchetti della sezione SMART HOME (escluso PORTA BLINDATA CONNESSA).',
  },
];

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    padding: '1.27cm',
    fontSize: '11pt',
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '5%',
    width: '100%',
    marginBottom: '10px',
    justifyContent: 'center',
  },
  logo: {
    height: '100%',
    width: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  phaseTitle: {
    fontSize: '16pt',
    // marginTop: '15pt',
    marginBottom: '15pt',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    paddingBottom: '5px',
    paddingTop: '5px',
    borderBottom: '1px solid black',
  },
  title: { width: '30%' },
  description: { width: '70%' },
  footer: {
    height: '5%',
    width: '100%',
    display: 'flex',
    flexDirection:'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'absolute',
    bottom: '1.27cm',
    left: '1.27cm',
    // marginTop: '50px',
  },
  bold: {
    fontWeight: 700
  },
  footerContainer: {
    height: '10%',
    width: '100%',
  },
  flexRow: {
    display: 'flex', 
    flexDirection:'row'
  },
  flexColumn: {
    display: 'flex', 
    flexDirection:'column'
  },
  borderLeftDivider: {
    borderLeft: '1px solid #000', 
    paddingLeft: '8px', 
    marginLeft: '8px'
  }
});

const SimplePriceInfoRow = ({ title, description }) => {
  return (
    <View style={styles.row} wrap={false}>
      <View style={styles.title}>
        <Text>{title}</Text>
      </View>
      <View style={styles.description}>
        <Text>{description}</Text>
      </View>
    </View>
  );
};

const PriceInfoPage = ({
  apartmentType,
  building,
  floor,
  quadrant
}) => {
  return (
    <Page size='A4' style={styles.page}>
      <View style={styles.logoContainer} fixed>
        <Image style={styles.logo} src={`${process.env.PUBLIC_URL}/assets/images/logo.png`} />
      </View>
      <View style={styles.phaseTitle}>
        <Text>Guida ai prezzi</Text>
      </View>
      <SimplePriceInfoRow title={'MENU'} description={'INFORMAZIONI RELATIVE AI PREZZI ESPOSTI'} />
      {infoNew?.map((el, i) => {
        return <SimplePriceInfoRow key={i} title={el.step} description={el.description} />;
      })}
      <View style={styles.footerContainer} fixed></View>
      <View style={styles.footer} fixed>
        <View style={styles.flexColumn}>
          <Text style={styles.bold}>{apartmentType}</Text>
        </View>
        <View style={styles.flexColumn}>
          <View style={styles.flexRow}>
            <Text>Progetto: </Text>
            <Text style={styles.bold}>{building}</Text>
          </View>
          <View style={styles.flexRow}>
            <Text>Piano: </Text>
            <Text style={styles.bold}>{floor}</Text>
            <Text style={styles.borderLeftDivider}>Quadrante: </Text>
            <Text style={styles.bold}>{quadrant}</Text>
          </View>
        </View>
      </View>
    </Page>
  );
};

export default PriceInfoPage;
