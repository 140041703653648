import * as React from 'react';

function SvgTermostatoSmart(props) {
  return (
    <svg viewBox="0 0 116 97" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_3439_24096" fill="white">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M75.5 0C69.1487 0 64 5.14873 64 11.5V61.7503C59.1501 65.2976 56 71.031 56 77.5C56 88.2696 64.7304 97 75.5 97C86.2696 97 95 88.2696 95 77.5C95 71.031 91.8499 65.2976 87 61.7503V11.5C87 5.14873 81.8513 0 75.5 0Z"/>
      </mask>
      <path d="M64 61.7503L64.8855 62.961L65.5 62.5116V61.7503H64ZM87 61.7503H85.5V62.5116L86.1145 62.961L87 61.7503ZM65.5 11.5C65.5 5.97715 69.9772 1.5 75.5 1.5V-1.5C68.3203 -1.5 62.5 4.3203 62.5 11.5H65.5ZM65.5 61.7503V11.5H62.5V61.7503H65.5ZM57.5 77.5C57.5 71.5297 60.4052 66.238 64.8855 62.961L63.1145 60.5396C57.895 64.3572 54.5 70.5322 54.5 77.5H57.5ZM75.5 95.5C65.5589 95.5 57.5 87.4411 57.5 77.5H54.5C54.5 89.098 63.902 98.5 75.5 98.5V95.5ZM93.5 77.5C93.5 87.4411 85.4411 95.5 75.5 95.5V98.5C87.098 98.5 96.5 89.098 96.5 77.5H93.5ZM86.1145 62.961C90.5948 66.238 93.5 71.5297 93.5 77.5H96.5C96.5 70.5322 93.105 64.3572 87.8855 60.5396L86.1145 62.961ZM85.5 11.5V61.7503H88.5V11.5H85.5ZM75.5 1.5C81.0228 1.5 85.5 5.97715 85.5 11.5H88.5C88.5 4.3203 82.6797 -1.5 75.5 -1.5V1.5Z" fill="#4E4D4D" mask="url(#path-1-inside-1_3439_24096)"/>
      <path d="M79.7998 64.7997V33H71.2013V64.7997C65.8538 66.5878 62 71.6214 62 77.5513C62 84.9784 68.0437 91 75.5 91C82.9563 91 89 84.9795 89 77.5513C89 71.6214 85.1473 66.5878 79.7987 64.7997H79.7998Z" fill="#D3D3D3"/>
      <rect x="95" y="16" width="16" height="4" fill="#D9D9D9"/>
      <rect x="95" y="27" width="16" height="4" fill="#D9D9D9"/>
      <rect x="95" y="38" width="16" height="4" fill="#D9D9D9"/>
      <rect x="95" y="49" width="16" height="4" fill="#D9D9D9"/>
      <path d="M67.5 78C68.3284 78 69 77.3284 69 76.5C69 75.6716 68.3284 75 67.5 75C66.6716 75 66 75.6716 66 76.5C66 77.3284 66.6716 78 67.5 78Z" fill="white"/>
      <path d="M69.6903 82.4207C68.9863 81.3873 68.4037 80.3299 67.9551 79.3063C67.7447 78.8262 67.1153 78.9342 67.0445 79.4646C66.8646 80.8144 67.23 82.3956 68.1426 83.7334C69.0475 85.0614 70.2881 85.8526 71.4695 85.9966C71.9372 86.0534 72.1869 85.3844 71.8329 85.0309C71.0993 84.2976 70.3713 83.4181 69.6903 82.4196V82.4207Z" fill="white"/>
      <path d="M16.1389 51.6601C16.1362 51.8128 16.1643 51.9644 16.2218 52.1058C16.2792 52.2472 16.3648 52.3756 16.4732 52.483C16.5816 52.5905 16.7106 52.6749 16.8525 52.7311C16.9945 52.7873 17.1463 52.8142 17.2989 52.8101L20.2189 52.8101C20.503 52.8175 20.7798 52.7194 20.9959 52.5347C21.2119 52.3501 21.3519 52.0919 21.3889 51.8101C21.9589 38.0801 33.6889 27.0101 48.2289 26.4701C48.5217 26.4676 48.8022 26.3516 49.0111 26.1464C49.22 25.9412 49.3411 25.6629 49.3489 25.3701L49.3489 22.6101C49.3425 22.4573 49.3058 22.3073 49.2407 22.1688C49.1756 22.0304 49.0836 21.9063 48.97 21.8039C48.8564 21.7014 48.7235 21.6227 48.579 21.5722C48.4346 21.5218 48.2816 21.5007 48.1289 21.5101C30.7689 22.1001 16.7589 35.3201 16.1289 51.7101" fill="#4D4D4D"/>
      <path d="M4 51.6623C3.99995 51.8146 4.0304 51.9653 4.08956 52.1056C4.14871 52.2459 4.23538 52.373 4.34443 52.4792C4.45349 52.5855 4.58273 52.6688 4.72452 52.7243C4.86631 52.7798 5.01779 52.8063 5.17 52.8023L8.09 52.8023C8.38669 52.808 8.67409 52.6988 8.89214 52.4976C9.11019 52.2963 9.24199 52.0185 9.26 51.7223C9.85 31.7223 27 15.5223 48.26 14.9623C48.5548 14.9601 48.8375 14.8446 49.0496 14.6399C49.2618 14.4352 49.3872 14.1569 49.4 13.8623L49.4 11.1023C49.3949 10.9508 49.3596 10.8019 49.2963 10.6642C49.2329 10.5265 49.1427 10.4028 49.031 10.3004C48.9192 10.1979 48.7882 10.1188 48.6455 10.0676C48.5028 10.0165 48.3514 9.99427 48.2 10.0023C24.08 10.6123 4.63 28.9723 4 51.6623Z" fill="#4D4D4D"/>
      <path d="M48.2629 37.9219C48.5505 37.9118 48.8234 37.7923 49.026 37.5879C49.2285 37.3835 49.3454 37.1095 49.3529 36.8219L49.3529 34.0619C49.348 33.9077 49.3118 33.7561 49.2466 33.6163C49.1814 33.4765 49.0884 33.3513 48.9734 33.2485C48.8584 33.1457 48.7238 33.0672 48.5776 33.0179C48.4314 32.9687 48.2767 32.9496 48.1229 32.9619C37.4829 33.5419 28.9229 41.6119 28.3129 51.6619C28.3088 51.8153 28.336 51.968 28.3928 52.1106C28.4496 52.2532 28.5349 52.3827 28.6435 52.4913C28.752 52.5998 28.8816 52.6851 29.0242 52.742C29.1668 52.7988 29.3195 52.826 29.4729 52.8219L32.4029 52.8219C32.6865 52.827 32.9621 52.7281 33.1776 52.5438C33.3932 52.3596 33.5338 52.1027 33.5729 51.8219C34.1029 44.3919 40.4029 38.4519 48.2629 37.9419" fill="#4D4D4D"/>
    </svg>
  );
}

export default SvgTermostatoSmart;
