import * as React from 'react';

function SvgUsbMatrimoniale(props) {
  return (
    <svg viewBox='0 0 63 46' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M56.057 6.098H59v3.164h-1.006v2.45c0 .255-.045.507-.136.742-.091.235-.224.448-.392.627l-4.117 4.458h-.038v5.83c.649.127 1.23.509 1.63 1.072.4.564.59 1.268.53 1.974a2.922 2.922 0 01-.847 1.841 2.539 2.539 0 01-1.78.744c-.66 0-1.295-.265-1.782-.744a2.922 2.922 0 01-.847-1.841c-.059-.706.13-1.41.53-1.974.4-.563.982-.945 1.63-1.071v-2.375l-3.883-4.18a1.932 1.932 0 01-.392-.628 2.046 2.046 0 01-.136-.741v-2.757a1.459 1.459 0 01-.763-.67 1.637 1.637 0 01-.181-1.04 1.57 1.57 0 01.487-.92c.258-.236.586-.365.925-.365.338 0 .666.13.924.364.259.235.431.562.487.92.056.36-.008.728-.18 1.042-.173.313-.444.55-.764.669v2.752c0 .122.022.243.066.356a.942.942 0 00.187.303l3.223 3.471V3.144H51.16l.842-1.574.842-1.57.847 1.57.842 1.574H53.33V16.13l3.495-3.758a.972.972 0 00.233-.66v-2.45h-1V6.098zM13.99 3H41v1.95H14.06a6.32 6.32 0 00-6.22 6.38v12.15h3v-2.67a5.281 5.281 0 015.26-5.3h10.74a5.271 5.271 0 014.29 2.21 5.249 5.249 0 014.3-2.21H41v1.94h-5.66a3.35 3.35 0 00-3.33 3.36v2.67H41v2.02H6.95a3.14 3.14 0 00-3.13 3.15l.01 6.59H41v.06h20.82a.5.5 0 01.26.43v4.87a.5.5 0 01-.5.5h-1.31v3.42a.5.5 0 01-.5.5h-4.83a.5.5 0 01-.5-.5V41.1H7.72v3.42a.5.5 0 01-.5.5h-4.9a.5.5 0 01-.5-.5V41.1H.5a.5.5 0 01-.5-.5v-4.87a.51.51 0 01.5-.5h1.32v-6.59a5.1 5.1 0 013.89-5v-12.3A8.33 8.33 0 0113.99 3zm-1.31 20.48v-2.67a3.35 3.35 0 013.33-3.36h10.74a3.35 3.35 0 013.32 3.36v2.67H12.68zm43.7 19.54v-1.91h1.88v1.94l-1.88-.03zm-52.56 0v-1.91h1.89v1.94l-1.89-.03zm56.32-3.92H1.94v-1.91h58.2v1.91z'
        fill='#C4C4C4'
      />
    </svg>
  );
}

export default SvgUsbMatrimoniale;
