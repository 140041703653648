import { useEffect } from 'react';
import { deleteHcUserData } from '../utils/localStorageUtils';

const LogoutPage = () => {
  useEffect(() => {
    deleteHcUserData();
    window.location.href = '/';
  }, []);

  return <></>;
};

export default LogoutPage;
