import GridDetailsContainer from '../../../components/Card/GridDetailsContainer';
import MaterialDataSheet from '../../../components/MaterialDataSheet';
import ConfiguratorThreeColumnsLayout from '../../../layouts/ConfiguratorThreeColumnsLayout';
import { useEffect, useState, useRef } from 'react';
import { resumeAnswerSimple } from '../../../utils/configuratorUtils';
import { getPackages } from '../../../services/packageApi';
import { useStore } from '../../../store/storeUtils';
import { useParams } from 'react-router';
import { handleSave, singleChoiceSelection } from '../../../utils/selectionUtils';
import _ from 'lodash';
import LoadingAnimation from '../../../components/LoadingAnimation';
import BigBox from './config/BigBox';
import { makeStyles } from '@material-ui/core';
import {
  materialDataSheetOptionsConfiguration,
  packagesRestructuringClassicConfiguration,
} from '../../../configs/giornoNottePhasesConfigurations';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '15px',
    textAlign: 'left',
    height: '5%',
  },
  subTitle: {
    fontSize: '11px',
    textAlign: 'left',
    height: '5%',
  },
  secondaryText: {
    color: theme.palette.color.orange,
  },
}));

const FirstColumn = ({
  options,
  selectedOptions,
  selectedFormatoCamereImage,
  selectedFormatoCamereTitle,
  selectedFormatoCucinaImage,
  selectedFormatoCucinaTitle,
}) => {
  const selectedVirtualImage = options
    ?.find((option) => option.id === selectedOptions?.[0])
    ?.virtualImage?.replace(/\s/g, '%20');
  return (
    <BigBox
      defaultImage={`${process.env.PUBLIC_URL}/assets/images/Soggiorno.jpg`}
      selectedVirtualImage={selectedVirtualImage}
      selectedFormatoCamereImage={selectedFormatoCamereImage}
      selectedFormatoCucinaImage={selectedFormatoCucinaImage}
      selectedFormatoCamereTitle={selectedFormatoCamereTitle}
      selectedFormatoCucinaTitle={selectedFormatoCucinaTitle}
    />
  );
};

const SecondColumn = ({ options, selectedOptions }) => {
  const materialDataSheetOptions = materialDataSheetOptionsConfiguration(options, selectedOptions);
  return (
    <MaterialDataSheet
      firstRowTitle={'PREVIEW'}
      secondRowTitle={'PRODOTTO'}
      previewBoxText={'un PRODOTTO'}
      material={materialDataSheetOptions}
    />
  );
};

const ThirdColumn = ({ options, selectedOptions, handleUserSelection }) => {
  const classes = useStyles();
  const containers = [
    {
      title: 'Preferenza Profilo',
      content: options,
    },
  ];

  return (
    <>
      <div className={classes.title}>
        <span>
          Quale <span className={classes.secondaryText}>profilo</span> preferisci per la{' '}
          <span className={classes.secondaryText}>porta d'arredo</span>?
        </span>
      </div>
      {/*
        <div className={classes.subTitle}>
          <span>
            Le finiture di seguito proposte, sono selezionate per essere accostate al mood e finiture
            scelte.
          </span>
        </div>
        */}
      <div style={{ height: '95%' }}>
        <GridDetailsContainer
          containers={containers}
          onClick={handleUserSelection}
          selectedOptions={selectedOptions}
          numCols={1}
        />
      </div>
    </>
  );
};

const SistemaChiusuraProfilo = () => {
  const [packages, setPackages] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [selectedFormatoCamereImage, setSelectedFormatoCamereImage] = useState(null);
  const [selectedFormatoCamereTitle, setSelectedFormatoCamereTitle] = useState(null);
  const [selectedFormatoCucinaImage, setSelectedFormatoCucinaImage] = useState(null);
  const [selectedFormatoCucinaTitle, setSelectedFormatoCucinaTitle] = useState(null);

  const substepIdRef = useRef();
  const oldAnswersRef = useRef();

  const store = useStore();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const configurationId = params.configuration;

  const settingFiniture = () => {
    const storedFinitureCamereTitle = store.selectedFormatoCameraTitle;
    const storedFinituraCamereImage = store.selectedFormatoCameraImage;
    const storedFinituraCucinaTitle = store.selectedFormatoCucinaTitle;
    const storedFinituraCucinaImage = store.selectedFormatoCucinaImage;
    if (storedFinitureCamereTitle !== '') {
      setSelectedFormatoCamereTitle(storedFinitureCamereTitle);
      setSelectedFormatoCamereImage(storedFinituraCamereImage);
    }
    if (storedFinituraCucinaTitle !== '') {
      setSelectedFormatoCucinaTitle(storedFinituraCucinaTitle);
      setSelectedFormatoCucinaImage(storedFinituraCucinaImage);
    }
  };

  useEffect(() => {
    store.setUserCanGoNext(false);
    prepareOptions();
    settingFiniture();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prepareOptions = async () => {
    const substepId = store.currentSubstepId;
    const packages = await getPackages(configurationId, substepId);
    substepIdRef.current = store.currentSubstepId;
    /*
    const { answers } = store;

    setSelectedMoods(answers.find((el) => el.substep.value === 'MOOD')?.package?.value);
    const selectedFinitura = answers.find((el) => el.substep.value === 'FINITURA')?.package?.value;
    const stepFinituraId = answers.find((el) => el.substep.value === 'FINITURA')?.substep?.id;
    const finiturePackages = await getPackages(configurationId, stepFinituraId);
    setSelectedFinituraImage(
      finiturePackages?.data[0]?.packages
        ?.find((onePackage) => onePackage?.code === selectedFinitura)
        ?.images?.find((image) => image?.imageType?.value === 'Cover')
        ?.label?.replace(/\s/g, '%20'),
    );
    */
    const packagesRestruct = packagesRestructuringClassicConfiguration(
      packages,
      false,
      false,
      false,
      'PorteArredoProfilo',
    );
    setPackages(packagesRestruct);
    resumeAnswerSimple(store, oldAnswersRef, substepIdRef, setSelectedPackages, setIsLoading);
  };

  useEffect(() => {
    const save = async () => {
      await handleSave(null, configurationId, substepIdRef.current, store, selectedPackages?.[0]);
    };

    if (!_.isEqual(oldAnswersRef.current, selectedPackages)) {
      store.setSubstepSave(save);
    } else {
      store.setSubstepSave(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPackages]);

  const handleUserSelection = (selection) => {
    singleChoiceSelection([selection], [selectedPackages], setSelectedPackages, store);
  };

  return isLoading ? (
    <LoadingAnimation />
  ) : (
    <ConfiguratorThreeColumnsLayout
      firstRowTitle='CONFIGURA'
      secondRowTitle="LE PORTE D'ARREDO"
      firstColumn={
        <FirstColumn
          options={packages}
          selectedOptions={selectedPackages}
          selectedFormatoCamereImage={selectedFormatoCamereImage}
          selectedFormatoCamereTitle={selectedFormatoCamereTitle}
          selectedFormatoCucinaImage={selectedFormatoCucinaImage}
          selectedFormatoCucinaTitle={selectedFormatoCucinaTitle}
        />
      }
      secondColumn={<SecondColumn options={packages} selectedOptions={selectedPackages} />}
      thirdColumn={
        <ThirdColumn
          options={packages}
          selectedOptions={selectedPackages}
          handleUserSelection={handleUserSelection}
        />
      }
    />
  );
};

export default SistemaChiusuraProfilo;
