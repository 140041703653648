import {
  BagnoIcon,
  GiornoIcon,
  LayoutIcon,
  TecnologiaIcon,
} from '../components/Icons';

const phasesConfig = [
  {
    id: 1,
    title: '',
    boldTitle: 'layout',
    icon: <LayoutIcon color='#F08219' />,
    descriptions: [
      'La distribuzione interna',
      'La tipologia di porte',
      'La composizione della cucina',
    ],
    withArchitect: false,
    completed: false,
    firstPart: true,
    infoData: {
      phase: 'LAYOUT',
      firstRow: 'In questa sezione potrai definire i dettagli della tua planimetria.',
      secondRow:
        'Selezionerai come preferisci la distribuzione delle stanze, definirai i serramenti e personalizzerai il tuo bagno.',
      thirdRow: 'Potrai sempre cambiare le tue scelte in qualsiasi momento.',
    },
    completedPhaseMessage: {
      orangeText: 'Complimenti,',
      text: 'hai concluso la personalizzazione del layout della tua nuova!',
    },
    order: 10,
  },
  {
    id: 2,
    title: '',
    boldTitle: 'tecnologia',
    descriptions: ["L'illuminazione", "Le prese", 'Le funzionalità smart'],
    icon: <TecnologiaIcon color='#F08219' />,
    withArchitect: false,
    completed: false,
    firstPart: true,
    infoData: {
      phase: 'TECNOLOGIA',
      firstRow: 'In questa sezione potrai personalizzare i tuoi ambienti.',
      secondRow:
        "Selezionerai i punti luminosi che preferisci, definirai l'impianto illuminotecnico, aggiungerai funzioni smart e personalizzerai il tuo eventuale box",
      thirdRow: 'Potrai sempre cambiare le tue scelte in qualsiasi momento.',
    },
    completedPhaseMessage: {
      orangeText: 'Complimenti,',
      text: 'hai completato la configurazione della tecnologia della nuova casa!',
    },
    order: 20,
  },
  {
    id: 3,
    boldTitle: 'zona giorno e notte',
    icon: <GiornoIcon color='#F08219' />,
    descriptions: [
      'Il pavimento del soggiorno',
      'Il pavimento delle camere',
      'Il pavimento della cucina',
    ],
    withArchitect: false,
    completed: false,
    firstPart: true,
    completedPhaseMessage: {
      orangeText: 'Complimenti,',
      text: 'hai completato la configurazione del tuo stile nella zona giorno e notte!',
    },
    order: 30,
  },
  {
    id: 4,
    title: '',
    boldTitle: 'bagno',
    icon: <BagnoIcon color='#F08219' />,
    descriptions: [
      'Il pavimento e i rivestimenti',
      'Il mobile lavabo e i sanitari',
      'La rubinetteria',
    ],
    withArchitect: false,
    completed: false,
    firstPart: true,
    order: 40,
  },
];

export default phasesConfig;
