import * as React from "react";

function SvgLayoutIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 102 79"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M51 78.9c28.166 0 51-2.686 51-6 0-3.313-22.834-6-51-6s-51 2.687-51 6c0 3.314 22.834 6 51 6z"
        fill="#EEE"
      />
      <path
        d="M23.9.9V54H32v-1.6h-6.5V23.9h11.8v-1.6H25.5V2.5h22v28.7h1.6V12.6h12.5V11H49.1V2.5h26.2v38.7H61V28.9h-1.6v12.2h-4.9v1.6h20.8v9.7H39.5V54h37.4V.9h-53z"
        fill="#F08219"
      />
    </svg>
  );
}

export default SvgLayoutIcon;
