import { Grid, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import ConfiguratorIntro from '../../../components/configurator/ConfiguratorIntro';
import {
  LuciApplique,
  LuciParete,
  LuciComodino as LuciComodinoIcon,
} from '../../../components/Icons';
import ConfiguratorSelectionLayout from '../../../components/Layout/ConfiguratorSelectionLayout.jsx';
import ConfiguratorTwoColumnsPlanimetryLayout from '../../../layouts/ConfiguratorTwoColumnsPlanimetryLayout';
import { getOption } from '../../../services/optionApi';
import { useStore } from '../../../store/storeUtils';
import _ from 'lodash';
import {
  handleOptionPlanimetryLayers,
  prepareOptionDataExploded,
} from '../../../utils/optionsUtils';
import { useParams } from 'react-router';
import { useRef } from 'react';
import { handleSave, multipleChoiceSelection } from '../../../utils/selectionUtils';
import DefaultChoiceImgText from '../layout/currentConfiguration/DefaultChoiceImgText';
import { getLayoutImagesByConfigurationId } from '../../../services/layoutApi';
import { getAnswerDetail } from '../../../services/answerApi';
import clsx from 'clsx';
import {
  handleCardMouseEnterSimple,
  handleCardMouseLeaveSimple,
  resumeAnswerSimple,
} from '../../../utils/configuratorUtils';
import OptionCard, { StandardOptionCardBody } from '../../../components/Card/OptionCard';
import { getAllImpiantoElettricoLayers } from '../../../utils/layoutUtils';

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: '#EEEEEE',
    width: '100%',
  },
  list: { marginTop: '25px', padding: '0 22px 22px' },
  alertMessage: { marginBottom: '5px' },
  scrollableContent: {
    backgroundColor: '#EEEEEE',
  },
}));

const FirstColumn = ({
  defaultOption,
  onClick,
  selectedOptions,
  luciPrincipali,
  planimetryImages,
}) => {
  return (
    defaultOption && (
      <DefaultChoiceImgText
        message={
          <>
            <div>Per ogni comodino è prevista una presa elettrica per lampade da tavolo.</div>
          </>
        }
        // icon={
        //   luciPrincipali === 'abat-jour' ? (
        //     <LuciComodinoIcon />
        //   ) : luciPrincipali?.includes('soffitto') ? (
        //     <LuciParete />
        //   ) : (
        //     <LuciApplique />
        //   )
        // }
        icon={<LuciComodinoIcon />}
        isSelected={_.isEqual(defaultOption.ids, selectedOptions)}
        onClick={() => {
          onClick(defaultOption.ids);
        }}
        planimetryImages={planimetryImages}
        legendImg={`${process.env.PUBLIC_URL}/legenda-elettrico.png`}
      />
    )
  );
};

const SecondColumn = ({
  options,
  selectedOptions,
  onClick,
  luciPrincipali,
  cardOnMouseEnter,
  cardOnMouseLeave,
}) => {
  const classes = useStyles();

  return (
    <ConfiguratorSelectionLayout scrollableContentClasses={clsx(classes.scrollableContent)}>
      <div className={classes.content}>
        <ConfiguratorIntro
          icon={
            luciPrincipali === 'abat-jour'
              ? { icon: <LuciComodinoIcon /> }
              : luciPrincipali?.includes('soffitto')
              ? { icon: <LuciParete /> }
              : { icon: <LuciApplique /> }
          }
          title={
            luciPrincipali === 'abat-jour'
              ? 'Hai selezionato le lampade Abat-jour'
              : `Vuoi aggiungere altri punti luce ${luciPrincipali} per l'illuminazione del comodino?`
          }
          badgeText={
            luciPrincipali !== 'abat-jour'
              ? 'Puoi selezionare una o più posizioni'
              : 'Nello step precedente hai selezionato la soluzione che prevede un’illuminazione con lampade da tavolo: la stessa soluzione viene estesa anche alle altre camere. Qualora volessi valutare delle proposte di illuminazione alternative torna allo step precedente e aggiungi anche nella camera principale uno dei pacchetti proposti. '
          }
        />
        <Grid container className={classes.list} spacing={2} justifyContent='center'>
          {options.map((el, i) => (
            <Grid item key={i} xs={6} lg={4}>
              <OptionCard
                {...el}
                onClick={() => onClick(el.ids)}
                isSelected={_.difference(el.ids, selectedOptions).length === 0}
                onMouseEnter={cardOnMouseEnter}
                onMouseLeave={cardOnMouseLeave}
                multiple
                infoPriceMessage={
                  'Il prezzo comprende la realizzazione di n°1 punto luce e n°1 interruttore per comodino. La fornitura delle lampade è esclusa.'
                }
              >
                <StandardOptionCardBody
                  {...el}
                  message={
                    <>{`Aggiungo punti luce per lampade ${luciPrincipali} nella posizione:`}</>
                  }
                  plateText={'P-'}
                  name=''
                />
              </OptionCard>
            </Grid>
          ))}
        </Grid>
      </div>
    </ConfiguratorSelectionLayout>
  );
};

const LuciComodinoAltre = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const [defaultOption, setDefaultOption] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [images, setImages] = useState([]);
  const [luciPrincipali, setLuciPrincipali] = useState();
  const [luciPrincipaliPositions, setLuciPrincipaliPositions] = useState([]);
  const [impiantoElettricoImages, setImpiantoElettricoImages] = useState([]);

  const substepIdRef = useRef();
  const oldAnswersRef = useRef();
  const showLayerOnHoverRef = useRef(true);
  const baseImagesRef = useRef([]);
  const baseImagesWithOptionsRef = useRef([]);

  const store = useStore();
  const params = useParams();

  useEffect(() => {
    store.setUserCanGoNext(false);
    prepareOptions();
  }, []);

  const prepareOptions = async () => {
    const substepId = store.getSubstepIdByFrontendId(store.currentSubstep);

    let optionData = await getOption(
      store.currentConfiguration?.layout?.id,
      substepId,
      params.configuration,
    );
    substepIdRef.current = optionData?.data.substep.id;

    optionData = prepareOptionDataExploded(optionData);

    // Imposto i layer da far vedere
    const layoutImagesResponse = await getLayoutImagesByConfigurationId(params.configuration);
    const layoutImages = layoutImagesResponse.data;

    baseImagesRef.current = layoutImages;
    baseImagesWithOptionsRef.current = layoutImages;

    setImages(layoutImages);

    // Recupero che tipo di luci sono stati scelti nello step "Luci Comodino Camera Principale"
    const answersDetailResponse = await getAnswerDetail(params.configuration);

    const luciPrincipaleAnswer = answersDetailResponse.data[0].optionGroupPrefixes.find(
      (el) => el.value === 'OM',
    );

    if (luciPrincipaleAnswer) {
      const luciDisplayName = luciPrincipaleAnswer.optionGroups[0].displayName.toLowerCase();

      setLuciPrincipali(
        luciDisplayName.includes('soffitto')
          ? 'a soffitto'
          : luciDisplayName.includes('parete')
          ? 'a parete'
          : 'abat-jour',
      );

      const luciPrincipalePositions = luciPrincipaleAnswer.optionGroups[0]?.options[0]?.numbers.map(
        (el) => el.number,
      );
      if (luciPrincipalePositions) {
        setLuciPrincipaliPositions(luciPrincipalePositions);
      }
    }

    const impiantoElettricoLayers = await getAllImpiantoElettricoLayers(
      store.currentConfiguration?.layout?.tags,
      params.configuration,
    );

    setImpiantoElettricoImages([
      ...layoutImages.map((el) => el.fileUrl),
      ...impiantoElettricoLayers,
    ]);

    setOptions(
      optionData.filter((el) => !el.isDefault)?.sort((a, b) => a?.position - b?.position), // Ordinamento opzioni
    );
    const defaultOption = optionData.find((el) => el.isDefault);
    setDefaultOption(defaultOption);

    resumeAnswerSimple(store, oldAnswersRef, substepIdRef, setSelectedOptions, setIsLoading);
  };

  useEffect(() => {
    const save = async () => {
      await handleSave(selectedOptions, params.configuration, substepIdRef.current, store);
    };

    if (!_.isEqual(oldAnswersRef.current, selectedOptions)) {
      store.setSubstepSave(save);
    } else {
      store.setSubstepSave(null);
    }
  }, [selectedOptions]);

  const handleUserSelection = (selection) => {
    multipleChoiceSelection(
      selection,
      selectedOptions,
      defaultOption.ids,
      setSelectedOptions,
      store,
    );
  };

  // Gestione delle immagini della planimetria alla selezione di un opzione
  useEffect(() => {
    handleOptionPlanimetryLayers(
      options,
      defaultOption?.ids,
      selectedOptions,
      showLayerOnHoverRef,
      baseImagesRef,
      setImages,
      baseImagesWithOptionsRef,
    );
  }, [selectedOptions]);

  const handleCardMouseEnter = (fileLabels) => {
    handleCardMouseEnterSimple(
      fileLabels,
      showLayerOnHoverRef,
      baseImagesWithOptionsRef,
      setImages,
    );
  };
  const handleCardMouseLeave = () => {
    handleCardMouseLeaveSimple(showLayerOnHoverRef, baseImagesWithOptionsRef, setImages);
  };

  return (
    <ConfiguratorTwoColumnsPlanimetryLayout
      planimetryImages={images}
      firstColumn={
        <FirstColumn
          defaultOption={defaultOption}
          selectedOptions={selectedOptions}
          onClick={handleUserSelection}
          luciPrincipali={luciPrincipali}
          luciPrincipaliPositions={luciPrincipaliPositions}
          planimetryImages={impiantoElettricoImages}
        />
      }
      secondColumn={
        <SecondColumn
          options={options}
          selectedOptions={selectedOptions}
          onClick={handleUserSelection}
          luciPrincipali={luciPrincipali}
          cardOnMouseEnter={handleCardMouseEnter}
          cardOnMouseLeave={handleCardMouseLeave}
        />
      }
      isLoading={isLoading}
      firstRowTitle={'IL TUO'}
      secondRowTitle={'APPARTAMENTO'}
      secondColumnFirstRowTitle={'SOLUZIONI'}
      secondColumnSecondRowTitle={'AGGIUNTIVE'}
      noOption={options?.length === 0}
      noOptionIcon={
        luciPrincipali === 'abat-jour'
          ? { icon: <LuciComodinoIcon /> }
          : luciPrincipali?.includes('soffitto')
          ? { icon: <LuciParete /> }
          : { icon: <LuciApplique /> }
      }
      noOptionText={
        luciPrincipali === 'abat-jour' ? '' : `Non puoi inserire altre ${luciPrincipali}.`
      }
      badgeText={
        'Nello step precedente hai selezionato la soluzione che prevede un’illuminazione con lampade da tavolo: la stessa soluzione viene estesa anche alle altre camere. Qualora volessi valutare delle proposte di illuminazione alternative torna allo step precedente e aggiungi anche nella camera principale uno dei pacchetti proposti. '
      }
      optionLayersOnTop
    />
  );
};

export default LuciComodinoAltre;
