import { useState } from 'react';
import { ButtonBase, makeStyles } from '@material-ui/core';
import PlateContainer from '../Card/PlateContainer';
import { Camera, Ruler } from '../Icons';
import ImageZoom from '../ImageZoom';
import PdfViewer from '../PdfViewer';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  doorCardRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '4px',
    width: '100%',
    borderRight: '1px solid #999',
    padding: '0 4px',
    '&:first-child': {
      // paddingLeft: 0,
    },
    '&:last-child': {
      // paddingRight: 0,
      borderRight: 0,
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: '20px',
      borderRight: 0,
      borderBottom: '1px solid #999',
      '&:last-child': {
        borderBottom: 0,
      },
    },
    '& .icon-container': {
      position: 'relative',
      '& > svg': {
        // fill: '#000',
        height: '80px',
        width: 'auto',
      },
      '& .dimension': {
        position: 'absolute',
        top: '50%',
        right: '-60px',
        fontSize: '11px',
        marginTop: '-15px',
        fontSize: '15px',
      },
    },
  },
  galleryButtonContainer: {
    position: 'absolute',
    top: 0,
    right: -75,
    display: 'flex',
    flexDirection: 'column',
    '&.left': {
      right: 'unset',
      left: -100,
    },
  },
  galleryButton: {
    color: '#000',
    border: '1px solid #C4C4C4',
    borderRadius: '16px',
    height: '30px',
    width: '72px',
    marginBottom: '5px',
    fontSize: '9px',
    padding: '4px 8px',
    display: 'flex',
    justifyContent: 'flex-start',
    lineHeight: 1,
    '& > svg': {
      height: '12px',
      minWidth: '12px',
      marginRight: '2px',
    },
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '4px',
  },
  title: {
    fontSize: '15px',
    lineHeight: '18px',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  subtitle: {
    fontSize: '15px',
    lineHeight: '18px',
    textAlign: 'center',
  },
  description: {
    fontSize: '13px',
    lineHeight: '16px',
    textAlign: 'center',
  },
}));

const DoorCard = ({
  icon,
  title,
  subtitle,
  description,
  positions,
  plateText,
  products,
  dimension,
  hideDescription,
  leftMediaButton,
}) => {
  const classes = useStyles();
  const [showImages, setShowImages] = useState(false);
  const [showPdfs, setShowPdfs] = useState(false);

  const sortPositions = positions ? positions.sort((a, b) => a - b) : positions;

  const images = products?.length > 0 ? [...new Set(products.map((el) => el.imageFileUrl))] : null;
  const pdfs =
    products?.length > 0 && products.filter((el) => el.dataSheetFileUrl).length > 0
      ? [...new Set(products.map((el) => el.dataSheetFileUrl))]
      : null;

  return (
    <div className={classes.doorCardRoot}>
      <div className='icon-container'>
        {icon}
        {dimension && <div className={'dimension'}>{`${dimension}`}</div>}
        <div className={clsx(classes.galleryButtonContainer, leftMediaButton && 'left')}>
          {images && (
            <ButtonBase className={classes.galleryButton} onClick={() => setShowImages(true)}>
              <Camera color='#878787' width='16px' height='16px' /> Foto
            </ButtonBase>
          )}
          {pdfs && (
            <ButtonBase className={classes.galleryButton} onClick={() => setShowPdfs(true)}>
              <Ruler color='#878787' width='16px' height='16px' /> Scheda Tecnica
            </ButtonBase>
          )}
        </div>
      </div>
      <div className={classes.text}>
        <span className={classes.title}>{title}</span>
        <span className={classes.subtitle}>{subtitle}</span>
        {!hideDescription && <span className={classes.description}>{description}</span>}
      </div>
      <PlateContainer elements={sortPositions} plateText={plateText} />
      {showImages && (
        <ImageZoom
          images={images}
          title={title}
          allowDownload
          handleClose={() => setShowImages('')}
        />
      )}
      {showPdfs && (
        <PdfViewer allowDownload document={pdfs} handleClose={() => setShowPdfs(false)} />
      )}
    </div>
  );
};

export default DoorCard;
