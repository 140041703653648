import { Format } from '../components/Icons';

const getInfoPriceCard = (tag) => {
	switch (tag) {
		case 'TanalitaBagno':
		  return "Il prezzo comprende la fornitura e la posa del materiale selezionato per il pavimento e il rivestimento del bagno, calcolato in sostituzione a quanto previsto all'interno del capitolato. La quantità del materiale è calcolata sulla base dello schema rivestimento selezionato in precedenza."
		case 'FormatoBagno':
			return "Il prezzo comprende la fornitura e la posa del materiale selezionato per il pavimento e il rivestimento del bagno, calcolato in sostituzione a quanto previsto all'interno del capitolato. La quantità del materiale è calcolata sulla base dello schema rivestimento selezionato in precedenza."
		case 'MobileLavaboModello':
			return "Il prezzo comprende la fornitura e la posa della composizione del mobile bagno selezionata, calcolato in sostituzione alla composizione prevista all'interno del capitolato (My Time 70_01)"
		case 'MobileLAvaboFinitura':
			return 'Il prezzo è relativo alla finitura della composizione del mobile bagno selezionata.'
		case 'SanitariModello':
			return "Il prezzo comprende la fornitura e la posa modello di sanitari selezionato, calcolato in sostituzione ai modelli previsti all'interno del capitolato."
		case 'SanitariFinitura':
			return "Il prezzo comprende la fornitura e la posa modello di sanitari selezionato, calcolato in sostituzione ai modelli previsti all'interno del capitolato."
		case 'PiattoDocciaModello':
			return "Il prezzo comprende la fornitura e la posa del piatto doccia selezionato, calcolato  in sostituzione al modello previsto all'interno del capitolato."
		case 'PiattoDocciaFinitura':
			return "Il prezzo comprende la fornitura e la posa del piatto doccia selezionato, calcolato  in sostituzione al modello previsto all'interno del capitolato."
		case 'RubinetteriaModello':
			return "Il prezzo comprende la fornitura e la posa di tutti i prodotti inclusi nel set di rubinetteria selezionato, calcolato in sostituzione ai modelli previsti all'interno del capitolato."
		case 'RubinetteriaFinitura':
			return "Il prezzo è relativo alla finitura del set di rubinetteria selezionato."
		case 'AccessoriDocciaModello':
			return "Il prezzo comprende la fornitura e la posa del box doccia in aggiunta."
		case 'AccessoriDocciaFinitura':
			return "Il prezzo è relativo alla finitura del vetro del box doccia selezionata, in sostituzione al vetro trasparente."
		case 'ScaldaSalvietteePlaccadiScarico':
			return "Il prezzo comprende la fornitura e la posa della coppia di dotazioni selezionata, calcolato in sostituzione al set e alla finitura previsti all'interno del capitolato."
		case 'VascaModello':
			return "Il prezzo comprende la fornitura e la posa della vasca selezionata, in sostituzione alla vasca base selezionata nella fase layout"
		case 'VascaFinitura':
			return "Il prezzo è relativo alla finitura della vasca selezionata."
		default:
		  return "Il prezzo è riferito al totale delle porte presenti nell'appartamento, escluso eventuali porte d'arredo.";
	}
}

const materialDataSheetOptionsConfiguration = (options, selectedOptions) => {
    const optionSelected = options?.find(option => option?.id === selectedOptions?.[0]);
    const materialObject = {
        image: optionSelected?.preview ? optionSelected?.preview?.replace(/\s/g, "%20") : null,
        name: optionSelected?.productName || 'Nome marca',
        product: 'PRODOTTO',
		infoTitle: optionSelected?.productDescriptionTitle || [],
        info: optionSelected?.productDescription || [],
        code: optionSelected?.code || 'example code',
        icon: <Format width='60px' height='60px' />,
        dataSheet: optionSelected?.datasheetFileLable,
        multipleImagesURLs: optionSelected?.multipleImagesURLs,
		multipleDescriptionsLabels: optionSelected?.multipleDescriptionsLabels,
		multipleDescriptionsTitles: optionSelected?.multipleDescriptionsTitles,
		rivOrPav: optionSelected?.rivOrPav,
		multipleNames: optionSelected?.multipleNames,
    }
    return materialObject;
}
const secondMaterialDataSheetOptionsConfiguration = (options, selectedOptions) => {
    const optionSelected = options?.find(option => option?.id === selectedOptions?.[0]);
    const materialObject = {
        image: optionSelected?.secondProductPreview ? optionSelected?.secondProductPreview?.replace(/\s/g, "%20") : null,
        name: optionSelected?.secondProductName || 'Nome marca',
        product: 'PRODOTTO',
		infoTitle: optionSelected?.secondProductDescriptionTitle || [],
        info: optionSelected?.secondProductDescription || [],
        code: optionSelected?.secondProductCode || 'example code',
        icon: <Format width='60px' height='60px' />,
        dataSheet: optionSelected?.secondProductdatasheetFileLable,
		rivOrPav: optionSelected?.secondProductRivOrPav,
      }
    return materialObject;
}

const packagesRestructuringClassicConfiguration = (packages, filters = false, multipleImages = false, infoPriceTag) => {
	return packages?.data[0]?.packages?.map(onePackage => {
		const coverImage = onePackage?.images?.find(image => image?.imageType?.value === 'Cover')?.label;
		const virtualImage = onePackage?.images?.find(image => image?.imageType?.value === 'Virtual')?.label;
		const objectToReturn = {
			id: onePackage?.id,
			code: onePackage?.products[0]?.producerCode + '_' + onePackage?.products[0]?.productCode,
			name: onePackage?.name,
			preview: onePackage?.products[0]?.imageFileLabel,
			materiotecaCode: onePackage?.materiotecaCode,
			materiotecaName: onePackage?.materiotecaName,
			productName: onePackage?.products[0]?.techInfo1,
			title: onePackage?.title,
			description: onePackage?.name,
			coverImage: coverImage,
			virtualImage: virtualImage,
			price: onePackage?.priceDiff,
			productDescriptionTitle: 
				Object.values(
					Object.fromEntries(
						Object.entries(onePackage?.products[0]).filter(
							([key, value]) => 
								key.includes('techInfo') && 
								key.includes('Title') && 
								!key.includes('1') && 
								(value !== null || value !== '')
						)
					)
				),
			productDescription: 
				Object.values(
					Object.fromEntries(
						Object.entries(onePackage?.products[0]).filter(
							([key, value]) => 
								key.includes('techInfo') && 
								!key.includes('Title') && 
								!key.includes('1') && 
								(value !== null || value !== '')
						)
					)
				),
			infoPrice: getInfoPriceCard(infoPriceTag),
			infoSpecification: 'Lorem Ipsum infoSpecification',
			isChained: onePackage?.subsequentChoiceWarning || false,
			datasheetFileLable: onePackage?.products[0]?.datasheetFileLabel,
			rivOrPav: onePackage?.products[0]?.productPackageType?.value?.includes('PAVIMENTO') ? 'Pavimento' : 'Rivestimento',
		}
		if(filters) objectToReturn.filter = onePackage?.filter;
		if(multipleImages) {
			objectToReturn.multipleImagesURLs = 
			onePackage?.products
			?.sort((a,b) => a.order - b.order)
			?.map(product => product?.imageFileLabel?.replace(/\s/g, "%20"));

			objectToReturn.multipleDescriptionsLabels = onePackage?.products.map(product => {
				return Object.values(
					Object.fromEntries(
						Object.entries(product).filter(
							([key, value]) => 
								key.includes('techInfo') && 
								!key.includes('Title') && 
								!key.includes('1') && 
								(value !== null || value !== '')
						)
					)
				);
			});
			objectToReturn.multipleDescriptionsTitles = onePackage?.products.map(product => {
				return Object.values(
					Object.fromEntries(
						Object.entries(product).filter(
							([key, value]) => 
								key.includes('techInfo') && 
								key.includes('Title') && 
								!key.includes('1') && 
								(value !== null || value !== '')
						)
					)
				);
			});
			objectToReturn.multipleNames = onePackage?.products.map(product => {
				return product?.techInfo1;
			});
		};
		return objectToReturn;
	});
}

const packagesRestructuringDoubleConfiguration = (packages, infoPriceTag) => {
	return packages?.data[0]?.packages?.map(onePackage => {
		const filopavIndex = onePackage?.products?.findIndex((prod) => prod?.productPackageType?.value?.toLowerCase()?.includes('filopavimento'));
		if (filopavIndex !== -1) onePackage?.products.splice(filopavIndex, 1);
		const coverImage = onePackage?.images?.find(image => image?.imageType?.value === 'Cover')?.label;
		const virtualImage = onePackage?.images?.find(image => image?.imageType?.value === 'Virtual')?.label;
		const isDoubleProduct = onePackage?.products?.length > 1;
		return {
			id: onePackage?.id,
			code: onePackage?.products[0]?.producerCode + '_' + onePackage?.products[0]?.productCode,
			secondProductCode: isDoubleProduct ? onePackage?.products[1]?.producerCode + '_' + onePackage?.products[1]?.productCode : false,
			preview: onePackage?.products[0]?.imageFileLabel,
			secondProductPreview: isDoubleProduct ? onePackage?.products[1]?.imageFileLabel : false,
			name: onePackage?.name,
			materiotecaCode: onePackage?.materiotecaCode,
			materiotecaName: onePackage?.materiotecaName,
			productName:  onePackage?.products[0]?.techInfo1,
			secondProductName: isDoubleProduct ? onePackage?.products[1]?.techInfo1 : false,
			title: onePackage?.title,
			description: onePackage?.name,
			coverImage: coverImage,
			virtualImage: virtualImage,
			price: onePackage?.priceDiff,
			productDescriptionTitle:
				Object.values(
					Object.fromEntries(
						Object.entries(onePackage?.products[0]).filter(
							([key, value]) => 
								key.includes('techInfo') && 
								key.includes('Title') && 
								!key.includes('1') && 
								(value !== null || value !== '')
						)
					)
				),
			productDescription: 
				Object.values(
					Object.fromEntries(
						Object.entries(onePackage?.products[0]).filter(
							([key, value]) =>
								key.includes('techInfo') &&
								!key.includes('Title') &&
								!key.includes('1') &&
								(value !== null || value !== '')
						)
					)
				),
			secondProductDescriptionTitle: isDoubleProduct ?
				Object.values(
					Object.fromEntries(
						Object.entries(onePackage?.products[1]).filter(
							([key, value]) => 
								key.includes('techInfo') && 
								key.includes('Title') && 
								!key.includes('1') && 
								(value !== null || value !== '')
						)
					)
				) : false,
			secondProductDescription: isDoubleProduct ? 
				Object.values(
					Object.fromEntries(
						Object.entries(onePackage?.products[1]).filter(
							([key, value]) => 
								key.includes('techInfo') && 
								!key.includes('Title') && 
								!key.includes('1') && 
								(value !== null || value !== '')
						)
					)
				) : false,
			infoPrice: getInfoPriceCard(infoPriceTag),
			infoSpecification: 'Lorem Ipsum infoSpecification',
			isChained: onePackage?.subsequentChoiceWarning || false,
			datasheetFileLable: onePackage?.products[0]?.datasheetFileLabel,
			secondProductdatasheetFileLable: isDoubleProduct ? onePackage?.products[1]?.datasheetFileLabel : false,
			rivOrPav: onePackage?.products[0]?.productPackageType?.value?.includes('PAVIMENTO') && !onePackage?.products[0]?.productPackageType?.value?.includes('FILOPAVIMENTO') ? 'Pavimento' : 'Rivestimento',
			secondProductRivOrPav: onePackage?.products[1]?.productPackageType?.value?.includes('PAVIMENTO') && !onePackage?.products[0]?.productPackageType?.value?.includes('FILOPAVIMENTO') ? 'Pavimento' : 'Rivestimento',
		}
	});
}

const setPavimentoBagnoConfiguration = (
	/*
	configurationResponse,
	formatoPackages,
	pavimentoPackages,
	paretePackages,
	store,
	*/
	store,
	roomId,
	selectedFormatiPreviewPavimento,
	selectedFormatiDescriptionPavimento,
	selectedFormatiPreviewRivestimento,
	selectedFormatiDescriptionRivestimento
) => {
	const pavimentieParetiBagno = store.selectedPavimentiBagno;
	const isYetIns = pavimentieParetiBagno?.find(pac => pac.roomId === roomId);
	if (isYetIns) {
		const indexBagno = pavimentieParetiBagno?.findIndex(pac => pac.roomId === roomId);
		if(indexBagno >= 0) {
			pavimentieParetiBagno[indexBagno].pavimentoBagnoDes = selectedFormatiDescriptionPavimento;
			pavimentieParetiBagno[indexBagno].pareteBagnoDes = selectedFormatiDescriptionRivestimento;
			pavimentieParetiBagno[indexBagno].pavimentoBagnoImg = selectedFormatiPreviewPavimento;
			pavimentieParetiBagno[indexBagno].pareteBagnoImg = selectedFormatiPreviewRivestimento;
			store.setSelectedPavimentiBagno(pavimentieParetiBagno)
		}
		/*
		const newObj = {
			roomId: roomId,
			pavimentoBagnoDes: selectedFormatiDescriptionPavimento,
			pareteBagnoDes: selectedFormatiDescriptionRivestimento,
			pavimentoBagnoImg: selectedFormatiPreviewPavimento,
			pareteBagnoImg: selectedFormatiPreviewRivestimento,
		}
		*/
	} else {
		const newObj = {
			roomId: roomId,
			pavimentoBagnoDes: selectedFormatiDescriptionPavimento,
			pareteBagnoDes: selectedFormatiDescriptionRivestimento,
			pavimentoBagnoImg: selectedFormatiPreviewPavimento,
			pareteBagnoImg: selectedFormatiPreviewRivestimento,
		}
		pavimentieParetiBagno.push(newObj);
		store.setSelectedPavimentiBagno(pavimentieParetiBagno);
	}
	/*
	const selectedPavimentiBagno = store.answers.filter((el) => el.substep.value === 'PAVIMENTO');
	const selectedParetiBagno = store.answers.filter((el) => el.substep.value === 'PARETI');
	const selectedFormatoBagno = store.answers.filter((el) => el.substep.value === 'FORMATO' && el.room.id );
	const bagniRooms = configurationResponse?.layout?.rooms?.filter((room) => room?.roomType?.name === 'BAGNO');
	let pavimentieParetiBagno = [];
	if (bagniRooms) for(const room of bagniRooms) {
		const formatoPackageId = selectedFormatoBagno.find(el => el.room?.id === room.id)?.package?.id;
		const pavimentoPackageId = selectedPavimentiBagno.find(el => el.room?.id === room.id)?.package?.id;
		const paretePackageId = selectedParetiBagno.find(el => el.room?.id === room.id)?.package?.id;
		
		let pavimentoBagnoDes ='';
		let pareteBagnoDes ='';
		let pavimentoBagnoImg = '';
		let pareteBagnoImg = '';

		if(formatoPackageId && formatoPackages) {
			// const formatoPackages = await getPackages(params.configuration, formatoSubStepId, room.id);
			const formatoProds = formatoPackages?.data[0]?.packages
				?.find((onePackage) => onePackage?.id === formatoPackageId)
				?.products;
			pavimentoBagnoDes = formatoProds?.find(prod => prod?.productPackageType?.value?.includes('PAVIMENTO'))?.description;
			pareteBagnoDes = formatoProds?.find(prod => prod?.productPackageType?.value?.includes('PARETE'))?.description;          
		}
		if(pavimentoPackageId && pavimentoPackages) {
			// const pavimentoPackages = await getPackages(params.configuration, pavimentoSubStepId, room.id);
			pavimentoBagnoImg = pavimentoPackages?.data[0]?.packages
				?.find((onePackage) => onePackage?.id === pavimentoPackageId)
				?.images?.find(image => image?.imageType?.value === 'Cover')?.label;
		}
		if(paretePackageId && paretePackages) {
			// const paretePackages = await getPackages(params.configuration, pareteSubStepId, room.id);
			pareteBagnoImg = paretePackages?.data[0]?.packages
				?.find((onePackage) => onePackage?.id === paretePackageId)
				?.images?.find(image => image?.imageType?.value === 'Cover')?.label;
		}
		const newObj = {
			roomId: room.id,
			pavimentoBagnoDes: pavimentoBagnoDes,
			pareteBagnoDes: pareteBagnoDes,
			pavimentoBagnoImg: pavimentoBagnoImg,
			pareteBagnoImg: pareteBagnoImg,
		}
		pavimentieParetiBagno.push(newObj);
	};
	store.setSelectedPavimentiBagno(pavimentieParetiBagno);
	*/
}

export {
	setPavimentoBagnoConfiguration,
	materialDataSheetOptionsConfiguration,
	secondMaterialDataSheetOptionsConfiguration,
	packagesRestructuringClassicConfiguration,
	packagesRestructuringDoubleConfiguration,
}