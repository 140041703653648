import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { AlertOutlined } from './Icons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    padding: '3px',
    paddingRight: '8px',
    backgroundColor: (props) => (props.color ? props.color : '#ffffffd3'),
    borderRadius: '12px',
    '& > svg': {
      marginRight: '6px',
    },
  },
  primaryText: {
    display: 'flex',
    textDecoration: 'underline',
    color: theme.palette.color.orange,
    fontWeight: '500',
    fontSize: '20px',
    textUnderlineOffset: '5px'
  },
  secondaryText: {
    display: 'flex',
    fontSize: '13px',
  },
  iconBefore: {
    display: 'flex',
  }
}));

const AlertBoxCentered = ({ primaryText, secondaryText, className, refdiv, show }) => {
  const classes = useStyles();
  const [showInfo, setShowInfo] = useState(show);

  useEffect(() => {
    if(show) setShowInfo(true);
  }, [show])

  return (
    <div className={clsx(classes.root, className)}>
      <AlertOutlined className={clsx(classes.iconBefore)} color='#F08219' />
      <span className={clsx(classes.primaryText)}>{primaryText}</span>
      <span className={clsx(classes.secondaryText)}>{secondaryText}</span>
    </div>
  );
};

export default AlertBoxCentered;
