import * as React from 'react';

function SvgPreseLettoSingolo(props) {
  return (
    <svg viewBox='0 0 68 52' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#prese-letto-singolo_svg__clip0_2758_23648)' fill='#C4C4C4'>
        <path d='M12.68 17.81c0-1.85 1.49-3.36 3.32-3.36h15.83c.39 0 .77.07 1.12.2h3.17a5.231 5.231 0 00-4.22-2.14H16.07c-2.91 0-5.27 2.37-5.27 5.3v2.67H7.78V8.33c-.07-3.45 2.72-6.31 6.21-6.38h19.85c3.5.06 6.29 2.88 6.35 6.39v6.31h1.94V8.34c0-4.59-3.72-8.34-8.29-8.34H13.99C9.42 0 5.7 3.75 5.7 8.34v12.3c-2.28.55-3.89 2.59-3.89 5.01v6.59H.5c-.28 0-.5.23-.5.5v4.87c0 .28.22.5.5.5h1.31v3.42c0 .28.22.5.5.5h4.9c.27 0 .5-.22.5-.5v-3.42h13.5V36.1H1.94v-1.91h19.27v-1.95H3.82v-6.59c0-1.74 1.4-3.15 3.12-3.15h14.27v-2.02h-8.53v-2.67zm-8.86 20.3H5.7v1.91H3.82v-1.91zM44.868 30.46H42.398v6.18h2.47v-6.18zM35 32.24v4.37h2.47v-6.15H35v1.78z' />
        <path d='M52.272 23.96c0-.72-.29-1.42-.8-1.93-.52-.51-1.21-.8-1.93-.81h-19.2a2.71 2.71 0 00-2.3 1.28c-.28.43-.43.94-.44 1.46v19.19c.01.73.3 1.42.81 1.93.51.52 1.21.8 1.93.81h19.2c.72-.01 1.41-.3 1.92-.81.52-.51.8-1.2.81-1.93V23.96zm-4.13 15.07c-.59.88-1.3 1.64-2.12 2.28-.33.27-.68.51-1.05.72-.4.25-.82.46-1.26.64a9.87 9.87 0 01-10.75-2.14 9.435 9.435 0 01-1.76-2.42c-.35-.64-.61-1.31-.79-2.01-.06-.2-.11-.41-.15-.62a9.793 9.793 0 01-.1-3.24c.11-.84.33-1.67.66-2.46.75-1.8 2.01-3.35 3.63-4.43a9.922 9.922 0 015.49-1.66c1.23 0 2.45.23 3.6.68.06.02.11.05.17.07.75.31 1.46.71 2.11 1.2.39.28.75.6 1.09.94.92.91 1.65 2 2.14 3.2.5 1.19.75 2.48.75 3.77 0 1.96-.58 3.86-1.66 5.48zM63.882 32.25h1.11c.14 0 .25-.11.25-.25v-4.74c0-.11 0-.22-.05-.33a.75.75 0 00-.18-.28.75.75 0 00-.28-.18c-.06-.02-.12-.04-.19-.04a.169.169 0 00-.1-.03c-.11-.01-.22 0-.33.05-.1.04-.2.1-.28.18a.75.75 0 00-.18.28c-.04.1-.05.22-.05.3V32c0 .14.11.25.25.25h.03zM57.59 32.22h1.06c.14 0 .25-.11.25-.25v-4.74a.696.696 0 00-.05-.32.624.624 0 00-.17-.28.75.75 0 00-.28-.18c-.1-.03-.21-.04-.32-.04a.66.66 0 00-.27.06c-.1.04-.18.1-.26.17-.07.08-.13.17-.16.28-.04.1-.05.2-.05.29v4.76c0 .14.12.25.25.25zM67.13 33.63a.922.922 0 00-.6-.33H55.95c-.12.01-.23.05-.34.1-.1.06-.19.13-.26.22s-.13.19-.16.31c-.03.11-.04.22-.03.31v6.01c-.01.11 0 .23.03.34.03.11.09.21.16.3s.16.17.27.22c.1.06.21.09.35.1h10.59a.922.922 0 00.57-.33c.07-.09.12-.19.15-.3.03-.11.04-.22.02-.3v-6.01a.858.858 0 00-.17-.64zM62.84 42.39h-3.17c-.14 0-.25.11-.25.25v1.06c0 .14.11.25.25.25h.81v3.3a2.52 2.52 0 01-.74 1.67c-.01.01-.03.02-.04.04-.44.42-1.02.67-1.61.7h-5.82c-.14 0-.25.11-.25.25v1.06c0 .14.11.25.25.25h5.82c.59 0 1.13-.12 1.61-.32 1.48-.62 2.34-2.03 2.34-3.11v-3.84h.8c.14 0 .25-.11.25-.25v-1.06c0-.14-.11-.25-.25-.25z' />
      </g>
      <defs>
        <clipPath id='prese-letto-singolo_svg__clip0_2758_23648'>
          <path fill='#fff' d='M0 0h67.31v51.22H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgPreseLettoSingolo;
