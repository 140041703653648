import { ButtonBase, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useState } from 'react';
import ImageZoom from '../ImageZoom';
import PdfViewer from '../PdfViewer';

const useStyles = makeStyles((theme) => ({
  imageZoom: {
    width: '100%',
    fontSize: '13px',
    lineHeight: '16px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: '15px',
    borderBottom: '1px solid #C4C4C4',
    '& span': {
      padding: '4px 0 0 10px',
    },
    '&:first-of-type': { borderTop: '1px solid #C4C4C4' },
    // '&:nth-child(even)': { borderTop: '1px solid #C4C4C4' },
  },
}));

const ImageZoomButton = ({
  icon,
  label,
  imageSrc,
  className,
  onClick,
  isPdf,
  isPlanimetry,
  legendImg,
}) => {
  const classes = useStyles();
  const [showZoom, setShowZoom] = useState(false);

  const handleOnClick = () => {
    if (typeof onClick === 'function') {
      onClick();
    } else {
      setShowZoom(true);
    }
  };

  return (
    <>
      <ButtonBase className={clsx(classes.imageZoom, className)} onClick={handleOnClick}>
        {icon}
        <span>{label}</span>
      </ButtonBase>
      {isPdf
        ? showZoom && (
            <PdfViewer allowDownload document={imageSrc} handleClose={() => setShowZoom(false)} />
          )
        : showZoom && (
            <ImageZoom
              images={imageSrc}
              isPlanimetry={isPlanimetry}
              allowDownload
              handleClose={() => setShowZoom(false)}
              legendImg={legendImg}
            />
          )}
    </>
  );
};

export default ImageZoomButton;
