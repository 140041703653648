import * as React from "react";

function SvgCheck(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 13 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 3.625L5.4 8 12 1"
        stroke={props.color}
        strokeWidth={2.5}
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgCheck;
