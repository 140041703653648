import * as React from 'react';

function SvgIlluminazioneBagno(props) {
  return (
    <svg viewBox='0 0 53 50' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#illuminazione-bagno_svg__clip0_2758_24024)' fill='#C4C4C4'>
        <path d='M34.838 40.81c-1.53.89-3.16 1.34-4.84 1.34H7.158c-2.76 0-5-2.24-5-5v-6.62h20.12v-2.15H2.148V13.51c0-1.79 1.46-3.25 3.25-3.25.87 0 1.71.35 2.32.97-1.22 2.03-.9 4.67.78 6.35.4.4 1.12.4 1.52 0l5.82-5.82c.2-.2.31-.48.31-.76s-.11-.56-.32-.76a5.2 5.2 0 00-6.64-.56c-1.01-1-2.38-1.57-3.8-1.57-2.98 0-5.4 2.42-5.4 5.4v23.65c0 3.54 2.6 6.54 6.07 7.07v4.06c0 .6.48 1.08 1.08 1.08.6 0 1.08-.48 1.08-1.08v-3.97h21.79c.51 0 1.05-.04 1.64-.13l1.46 4.44c.15.44.56.74 1.02.74.12 0 .23-.02.34-.06.27-.09.49-.28.63-.54.13-.26.15-.55.06-.82l-1.4-4.24c1.54-.52 2.97-1.36 4.23-2.51l.45-.41h-3.53l-.05.03-.02-.01zm-24.82-29.05c.58-.59 1.36-.89 2.15-.89.42 0 .85.09 1.25.27l-4.02 4.02c-.51-1.14-.28-2.5.62-3.4z' />
        <path d='M12.36 17.34c-.2.1-.35.27-.42.49-.07.22-.05.44.05.65l.22.43c.14.29.43.46.75.46a.847.847 0 00.75-1.23l-.22-.43a.855.855 0 00-1.13-.38v.01zM15.768 13.899c-.17.43.05.92.48 1.09l.45.17a.844.844 0 10.62-1.57l-.45-.17c-.43-.16-.93.05-1.09.48h-.01zM15.207 15.7a.84.84 0 00-1.18.12.83.83 0 00-.18.62c.02.22.13.43.31.57l.38.3c.15.12.34.19.53.19a.835.835 0 00.84-.93.799.799 0 00-.31-.57l-.37-.3h-.02zM38.072 33.93h-8.06c-.69 0-1.25.57-1.25 1.26 0 .69.56 1.26 1.25 1.26h8.06c.69 0 1.25-.56 1.25-1.26s-.56-1.26-1.25-1.26zM41.55 10.97c-.34-.33-.71-.64-1.09-.91-1.83-1.35-4.06-2.07-6.39-2.07-2.83 0-5.49 1.05-7.49 2.98-2.01 1.93-3.12 4.5-3.12 7.24 0 2.54.97 4.97 2.72 6.84 1.78 2.11 2.61 6.05 2.61 6.08l.17.82h10.3l.02-.99c.07-2.88 2.81-6.06 2.93-6.19 1.59-1.84 2.47-4.17 2.47-6.56 0-1.62-.39-3.18-1.12-4.58-.51-.98-1.18-1.87-2.01-2.66zm-.88 12.46c-.29.33-2.8 3.29-3.33 6.48H30.6c-.35-1.34-1.26-4.28-2.91-6.23a7.95 7.95 0 01-2.2-5.47c0-4.51 3.85-8.19 8.58-8.19s8.57 3.68 8.57 8.19c0 1.9-.7 3.76-1.97 5.22zM35.2 0h-2.27v4.64h2.27V0zM46.37 3.93l-2.98 3.556 1.74 1.458 2.98-3.557-1.74-1.458zM52.44 16.24H47.8v2.27h4.64v-2.27z' />
        <path d='M34.162 23.52l-2.92-2.91-1.41 1.41 4.33 4.33 4.09-4.1-1.41-1.41-2.68 2.68z' />
      </g>
      <defs>
        <clipPath id='illuminazione-bagno_svg__clip0_2758_24024'>
          <path fill='#fff' d='M0 0h52.44v49.36H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIlluminazioneBagno;
