import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { AlertTriangle } from './Icons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '12px',
  },
  icon: {
    color: theme.palette.primary.main,
  },
  alertMessage: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '12px',
    borderLeft: `2px solid ${theme.palette.primary.main}`,
  },
  title: {
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
  },
  subtitle: {
    fontFamily: 'miller',
    fontStyle: 'italic',
    fontSize: '13px',
    lineHeight: '16px',
    color: theme.palette.primary.main,
  },
  description: {
    fontFamily: 'miller',
    fontStyle: 'italic',
    fontSize: '13px',
    lineHeight: '16px',
  },
}));

const AlertMessage = ({ title, subtitle, description, className }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)}>
      <AlertTriangle color='currentColor' width='24px' height='24px' className={classes.icon} />
      <div className={classes.alertMessage}>
        <span className={classes.title}>{title}</span>
        <span className={classes.subtitle}>{subtitle}</span>
        <span className={classes.description}>{description}</span>
      </div>
    </div>
  );
};

export default AlertMessage;
