import * as React from 'react';

function SvgIlluminazioneRipostiglio(props) {
  return (
    <svg viewBox='0 0 81 54' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#illuminazione-ripostiglio_svg__clip0_2758_24091)' fill='#C4C4C4'>
        <path d='M25.56 12.4H3.55C1.62 12.4 0 14.01 0 15.91V47.2c0 1.66 1.16 3.08 2.77 3.41v1.99c0 .4.32.84.85.84h2.77c.41 0 .85-.32.85-.84v-1.9h14.77v1.9c0 .4.33.84.85.84h2.7c.41 0 .85-.32.85-.84v-2c1.64-.4 2.77-1.78 2.77-3.4V15.91c0-1.9-1.66-3.51-3.62-3.51zM5.47 51.83H4.4v-1.06h1.07v1.06zm19.24 0h-1.07v-1.06h1.07v1.06zm2.77-4.63c0 1.04-.86 1.89-1.92 1.89H3.55c-1.06 0-1.92-.85-1.92-1.89V15.91c0-1.05.86-1.9 1.92-1.9h22.01c1.06 0 1.92.85 1.92 1.9V47.2z' />
        <path d='M25.56 15.14H3.55c-.41 0-.85.32-.85.84v31.29c0 .4.32.84.85.84h22.01c.41 0 .85-.32.85-.84V15.98c0-.5-.35-.84-.85-.84zM13.78 46.36H4.4V16.75h9.38v29.61zm11 0H15.4V16.75h9.38v29.61z' />
        <path d='M10.44 34.07c1 0 1.82-.81 1.82-1.81s-.82-1.82-1.82-1.82-1.83.85-1.83 1.82c0 .97.82 1.81 1.83 1.81zM18.742 34.07c.85 0 1.61-.67 1.8-1.59l.02-.22a1.82 1.82 0 00-3.64 0c0 1.01.81 1.81 1.82 1.81zM59.94 47.2c0 1.04-.87 1.89-1.93 1.89H36c-1.06 0-1.92-.85-1.92-1.89V15.91c0-1.05.86-1.9 1.92-1.9h11.86V12.4H36c-1.96 0-3.55 1.58-3.55 3.51V47.2c0 1.66 1.16 3.08 2.77 3.41v1.99c0 .4.33.84.85.84h2.77c.41 0 .85-.32.85-.84v-1.9h14.85v1.9c0 .4.32.84.85.84h2.7c.4 0 .85-.32.85-.84v-2c1.63-.4 2.76-1.78 2.76-3.4v-7.54h-1.76v7.54zm-22.02 4.63h-1.07v-1.06h1.07v1.06zm19.25 0h-1.08v-1.06h1.08v1.06z' />
        <path d='M35.998 15.14c-.41 0-.85.32-.85.84v31.29c0 .4.33.84.85.84h22.01c.41 0 .85-.32.85-.84v-7.61h-1.62v6.7h-9.38V15.14h-11.86zm10.16 31.22h-9.31V16.75h9.31v29.61z' />
        <path d='M42.89 34.07c1.01 0 1.82-.81 1.82-1.81s-.81-1.82-1.82-1.82-1.82.85-1.82 1.82c0 .97.82 1.81 1.82 1.81zM65.97 33.93h-8.06c-.69 0-1.25.57-1.25 1.26 0 .69.56 1.26 1.25 1.26h8.06c.69 0 1.25-.56 1.25-1.26s-.56-1.26-1.25-1.26zM69.461 10.97c-.34-.33-.71-.64-1.09-.91-1.83-1.35-4.06-2.07-6.39-2.07-2.83 0-5.49 1.05-7.49 2.98-2.01 1.93-3.12 4.5-3.12 7.24 0 2.54.97 4.97 2.72 6.84 1.78 2.11 2.61 6.05 2.61 6.08l.17.82h10.3l.02-.99c.07-2.88 2.81-6.06 2.93-6.19 1.59-1.84 2.47-4.17 2.47-6.56 0-1.62-.39-3.18-1.12-4.58-.51-.98-1.18-1.87-2.01-2.66zm-.88 12.46c-.29.33-2.8 3.29-3.33 6.48h-6.74c-.35-1.34-1.26-4.28-2.91-6.23a7.95 7.95 0 01-2.2-5.47c0-4.51 3.85-8.19 8.58-8.19s8.57 3.68 8.57 8.19c0 1.9-.7 3.76-1.97 5.22zM63.11 0h-2.27v4.64h2.27V0zM74.28 3.923L71.3 7.48l1.741 1.458 2.98-3.557-1.74-1.458zM80.35 16.24h-4.64v2.27h4.64v-2.27z' />
        <path d='M62.062 23.52l-2.91-2.91-1.41 1.41 4.32 4.33 4.1-4.1-1.42-1.41-2.68 2.68z' />
      </g>
      <defs>
        <clipPath id='illuminazione-ripostiglio_svg__clip0_2758_24091'>
          <path fill='#fff' d='M0 0h80.35v53.44H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIlluminazioneRipostiglio;
