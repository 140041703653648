import { ButtonBase, Grid, makeStyles, Tab, Tabs } from '@material-ui/core';
import clsx from 'clsx';
import SvgExit from './Icons/Exit';
import SvgDownload2 from './Icons/Download2';
import { saveAs } from 'file-saver';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import Slider from 'react-slick';
import { useEffect, useRef, useState } from 'react';
import SvgLeftChevron from './Icons/LeftChevron';
import ZoomControl from './ZoomControl';
import { getFileNameFromUrl } from '../utils/stringUtils';
import mergeImages from 'merge-images';
import { useStore } from '../store/storeUtils';
import _ from 'lodash';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  usePDF,
  Image as ImagePDF,
} from '@react-pdf/renderer';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.55)',
    backdropFilter: 'blur(15px)',
    zIndex: theme.zIndex.modal,
    padding: '30px 100px',
    '& .slick-list,.slick-slider,.slick-track, .slick-slide > div': {
      height: '100%',
    },
    '& .slick-slider': {
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
    },
    '& .react-transform-component,.react-transform-wrapper': {
      height: '100%',
      width: '100%',
      cursor: 'grab',
    },
    '&.panning .react-transform-component,.react-transform-wrapper': {
      cursor: 'grabbing',
    },
  },
  title: {
    fontWeight: ({ datasheet }) => (datasheet ? 500 : 600),
    fontSize: '15px',
    lineHeight: '18px',
    color: '#FFFFFF',
    width: '240px',
    textTransform: ({ datasheet }) => (datasheet ? 'uppercase' : 'initial'),
  },
  space: {
    marginBottom: '32px',
    [theme.breakpoints.down('lg')]: {
      marginBottom: '10px',
    },
  },
  closeIcon: {
    position: 'absolute',
    top: '30px',
    right: '30px',
    backgroundColor: '#000',
    height: '50px',
    width: '50px',
    borderRadius: '100%',
    zIndex: 10,
    '& svg': {
      height: '15px',
      width: 'auto',
    },
  },
  image: {
    maxWidth: '70vw',
    maxHeight: '70vh',
  },
  downloadIcon: {
    cursor: 'pointer',
    marginLeft: '30px',
    position: 'absolute',
    right: 30,
    bottom: 30,
    '& svg ': {
      fill: '#FFF',
      height: 'auto',
      width: '50px',
      '& g': {
        fill: '#FFF',
      },
    },
  },
  imageContainer: {
    // height: '90vh',
    // width: '90vw',
    position: 'relative',
    paddingBottom: '50px',
  },
  planimetryContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '90vw',
    '& img': {
      position: 'absolute',
    },
  },
  arrow: {
    padding: 0,
    transform: 'translate(0, -50%)',
    cursor: 'pointer',
    fontSize: 0,
    lineHeight: 0,
    position: 'absolute',
    top: '50%',
    background: '#676766',
    border: '1px solid #FFFFFF',
    height: '30px',
    width: '30px',
    display: 'flex',
    alignItems: 'center',
    zIndex: 2,

    '&.next': {
      right: '0',
      '& svg': {
        transform: 'rotate(180deg)',
      },
    },
  },
  elementRoot: {
    height: '100%',
    outline: 'none',
    display: 'flex !important',
    justifyContent: 'center',
    alignItems: 'center',

    '& img': {},
    '& div, img': {},
  },
  sliderImage: {
    height: '100%',
    objectFit: 'contain',
    color: '#FFF',
  },
  galleryTabs: {
    zIndex: 2,
    position: 'absolute',
    top: 10,
  },
  tabIndicator: {
    bottom: 'unset',
    top: '0',
    backgroundColor: '#F08219',
    height: '5px',
  },
  tabRoot: {
    height: '40px',
    border: '0.5px solid #CDC7C1',
    padding: '4px 4px 0',
    fontFamily: 'p22-underground',
    fontWeight: 500,
    fontSize: 13,
    textTransform: 'uppercase',
    minHeight: 'unset',
    width: '190px',
    color: '#000000',
    backgroundColor: '#D6D6D6',
    opacity: 1,
    '&.selected-tab': {
      backgroundColor: '#FFF',
    },
  },
  legendImg: {
    boxShadow: 'rgba(149, 157, 165, 0.3) 0px 8px 24px',
    position: 'absolute',
    left: 30,
    bottom: 30,
    height: '30%',
    minHeight: '230px',
    '&.small': {
      height: '200px',
      minHeight: 'unset',
    },
  },
  imgDisclaimer: {
    textAlign: 'center',
    color: '#FFF',
    fontSize: '12px',
    fontWeight: 400,
    textShadow: '0px 2px 7px #000',
    position: 'absolute',
    bottom: '40px',
    width: '60%',
  },
}));

const Arrow = (props) => {
  const classes = useStyles();

  const { onClick, isNext } = props;
  return (
    <div className={clsx(classes.arrow, isNext && 'next')} onClick={onClick}>
      <SvgLeftChevron height='12px' width='auto' color='#FFF' />
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
};

const GalleryTabs = ({ gallery, activeIndex, onChange }) => {
  const classes = useStyles();

  return (
    <Grid container justifyContent='center' className={clsx(classes.galleryTabs)}>
      <Grid item xs='auto'>
        <Tabs
          classes={{
            root: classes.tabsRoot,
            indicator: classes.tabIndicator,
          }}
          value={activeIndex}
          onChange={onChange}
          aria-label='tabs'
          centered
        >
          {gallery &&
            gallery.map((el, i) => (
              <Tab
                key={i}
                classes={{
                  root: clsx(classes.tabRoot, 'tab-root'),
                  selected: 'selected-tab',
                  wrapper: classes.tapWrapper,
                }}
                label={<span className={classes.tabText}>{el.name}</span>}
                {...a11yProps(i)}
              />
            ))}
        </Tabs>
      </Grid>
    </Grid>
  );
};

const Image = ({ images, noZoom, zoomRef, imagesIsArray, onError, setIsPanning }) => {
  const classes = useStyles();

  return (
    <TransformWrapper
      onZoomStop={(ref, event) => {
        console.log(ref);
        console.log(event);
      }}
      onPanningStart={() => setIsPanning(true)}
      onPanningStop={() => setIsPanning(false)}
      disabled={noZoom}
      ref={zoomRef}
      wheel={{ disabled: true }}
      doubleClick={{ disabled: true }}
    >
      <TransformComponent>
        <div className={classes.planimetryContainer}>
          {imagesIsArray ? (
            images.map((el, i) => (
              <img key={i} src={el} alt={el} className={clsx(classes.image)} onError={onError} />
            ))
          ) : (
            <img src={images} alt={images} className={clsx(classes.image)} onError={onError} />
          )}
        </div>
      </TransformComponent>
    </TransformWrapper>
  );
};

const ImageZoom = ({
  title,
  subtitle,
  datasheet,
  images,
  allowDownload,
  handleClose,
  isPlanimetry,
  gallery,
  startIndexImage,
  galleryToShow,
  legendImg,
}) => {
  const classes = useStyles({ datasheet });

  const currentImage = useRef(0);
  const zoomRef = useRef();
  const store = useStore();

  const [currentGallery, setCurrentGallery] = useState(galleryToShow);
  const [isPanning, setIsPanning] = useState(false);
  const [textLayers, setTextLayers] = useState([]);

  const handleAfterChange = (i) => {
    currentImage.current = i;
  };

  const handleDownload = async () => {
    if (isPlanimetry) {
      const planimetryImages = [...images, ...textLayers];
      const localImagesUrl = [];
      for (let i = 0; i < planimetryImages.length; i++) {
        const imageUrl = planimetryImages[i];
        const response = await fetch(imageUrl);
        const imageBlob = await response.blob();
        const reader = new FileReader();

        reader.readAsDataURL(imageBlob);
        reader.onloadend = () => {
          const base64data = reader.result;
          localImagesUrl.push(base64data);
          if (i + 1 === planimetryImages.length) {
            mergeAndDownload(localImagesUrl);
          }
        };
      }
    } else if (_.isString(images)) {
      saveAs(images, getFileNameFromUrl(images));
    } else {
      saveAs(images[currentImage.current], getFileNameFromUrl(images[currentImage.current]));
    }
  };

  const mergeAndDownload = async (localImagesUrl) => {
    const b64 = await mergeImages(localImagesUrl);
    fetch(b64)
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        console.log('blob', blob);
        saveAs(blob, `${store.currentApartment?.apartment?.value}.png`);
      });
  };

  const sliderSetting = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <Arrow isNext />,
    prevArrow: <Arrow />,
    draggable: false,
    dots: false,
    afterChange: handleAfterChange,
    initialSlide: startIndexImage ? startIndexImage : null,
  };

  const imagesToShow = gallery ? gallery[currentGallery].images : images;
  const imagesIsArray = Array.isArray(imagesToShow);

  const handleImageError = (e) => {
    e.target.alt = "L'immagine al momento non è disponibile";
  };

  useEffect(() => {
    const textLayers = store.currentConfiguration?.layout?.tags
      ?.find((tag) => tag.key === 'TESTO')
      ?.fileLabels?.map((el) => el.url);
    setTextLayers(textLayers ? textLayers : []);
  }, []);

  return (
    <Grid
      container
      direction='column'
      className={clsx(classes.root, isPanning && 'panning')}
      alignItems='center'
      justifyContent='center'
    >
      <ButtonBase className={classes.closeIcon} onClick={handleClose}>
        <SvgExit height='8px' width='8px' color='#F08219' />
      </ButtonBase>
      {title && (
        <Grid item xs={'auto'} className={clsx(classes.title, subtitle ? '' : classes.space)}>
          {title}
        </Grid>
      )}
      {subtitle && (
        <Grid item xs={'auto'} className={clsx(classes.title, classes.space)}>
          {subtitle}
        </Grid>
      )}

      <Grid
        item
        xs
        container
        alignItems='center'
        justifyContent='center'
        className={clsx(classes.imageContainer)}
      >
        {isPlanimetry ? (
          <Image
            images={[...images, ...textLayers]}
            imagesIsArray={imagesIsArray}
            zoomRef={zoomRef}
            onError={handleImageError}
            setIsPanning={setIsPanning}
          />
        ) : (
          <>
            <GalleryTabs
              gallery={gallery}
              activeIndex={currentGallery}
              onChange={(event, newValue) => {
                setCurrentGallery(newValue);
              }}
            />
            {imagesIsArray ? (
              <Slider {...sliderSetting}>
                {imagesToShow.map((el, i) => (
                  <img
                    key={i}
                    src={el}
                    alt={el}
                    className={clsx(classes.sliderImage)}
                    onError={handleImageError}
                  />
                ))}
              </Slider>
            ) : (
              <Image
                images={images}
                noZoom
                imagesIsArray={imagesIsArray}
                zoomRef={zoomRef}
                onError={handleImageError}
              />
            )}
          </>
        )}
      </Grid>
      {isPlanimetry && (
        <Grid item xs='auto' container alignItems='center' justifyContent='center'>
          <ZoomControl
            onResetClick={() => {
              zoomRef.current.resetTransform();
            }}
            onZoomInClick={() => {
              zoomRef.current.zoomIn();
            }}
            onZoomOutClick={() => {
              zoomRef.current.zoomOut();
            }}
          />
        </Grid>
      )}
      {allowDownload && (
        <ButtonBase className={clsx(classes.downloadIcon)} onClick={handleDownload}>
          <SvgDownload2 color='#FFF' />
        </ButtonBase>
      )}
      {legendImg && (
        <img 
          className={clsx(classes.legendImg)} 
          src={legendImg} 
          alt='legenda' 
        />
      )}
      {isPlanimetry && !legendImg && (
        <img
          className={clsx(classes.legendImg, 'small')}
          src={`${process.env.PUBLIC_URL}/assets/images/legenda.png`}
          alt='legenda'
        />
      )}
      {!isPlanimetry && (
        <div className={clsx(classes.imgDisclaimer)}>
          Le immagini sono a puro scopo illustrativo, dimensioni e colori sono orientativi. Ogni
          elemento può risultare alterato a causa della taratura dello schermo, della luce e
          dell’angolo di visualizzazione.
        </div>
      )}
    </Grid>
  );
};

export default ImageZoom;
