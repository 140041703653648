import { useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Alert, CheckOk, Exit } from './Icons';
import StyleText from '../utils/StyleText';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#FFF',
    fontFamily: 'p22-underground',
    width: '290px',
    '&:first-child': {
      marginBottom: '5px',
    },
  },
  header: {
    width: '100%',
    height: '25px',
    backgroundColor: '#CDC7C1',
    '& .title': {
      fontSize: '15px',
      lineHeight: '18px',
      textTransform: 'uppercase',
      color: '#FFF',
      textAlign: 'center',
      paddingTop: '5px',
    },
    '& .icon': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '3px',
      color: theme.palette.color.white,
      border: `1px solid ${theme.palette.color.white}`,
      borderRadius: '50%',
      cursor: 'pointer',
      marginRight: '5px',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '30px 35px 15px',
    '& .title': {
      width: '100%',
      textAlign: 'center',
    },
    '& .name': {
      margin: '12px 0 15px',
      fontSize: '14px',
      lineHeight: '17px',
      textAlign: 'center',
      color: theme.palette.color.black,
      textTransform: 'uppercase',
    },
    '& .description': {
      width: '100%',
      color: '#CDC7C1',
      fontFamily: 'miller',
      fontStyle: 'italic',
      fontSize: '14px',
      lineHeight: '17px',
      textAlign: 'center',
    },
  },
  collapsibleImage: {
    position: 'relative',
    width: '70px',
    height: '70px',
    border: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: '5px',
    cursor: 'pointer',
    '&.minimized': {
      marginBottom: 8,
    },
    '&:last-child': {
      marginBottom: 0,
    },
    '& img': {
      width: '100%',
      height: '100%',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
    '& .icon': {
      position: 'absolute',
      bottom: '-0.5em',
      right: '-0.5em',
    },
    '&.info .icon': {
      position: 'absolute',
      top: '-0.5em',
      right: '-0.5em',
    },
  },
}));

const MoodDetails = ({ title, image, name, description, className, isInfo }) => {
  const classes = useStyles();
  const [openSelection, setOpenSelection] = useState(false);

  const closeSelection = () => {
    setOpenSelection(!openSelection);
  };

  return (
    <>
      {openSelection ? (
        <div className={classes.root}>
          <Grid container alignItems='center' className={classes.header}>
            <Grid item xs className='title'>
              {StyleText(title)}
            </Grid>
            <Grid item xs='auto' className='icon' onClick={closeSelection}>
              <Exit color='currentColor' width='8px' height='8px' />
            </Grid>
          </Grid>
          <div className={classes.content}>
            <div className={classes.collapsibleImage} style={{ backgroundImage: `url(${image})` }}>
              {!isInfo && <CheckOk className='icon' />}
            </div>
            <span className='name'>{name}</span>
            <span className='description'>{description}</span>
          </div>
        </div>
      ) : (
        <div
          className={clsx(classes.collapsibleImage, 'minimized', isInfo && 'info')}
          onClick={closeSelection}
          style={{ backgroundImage: `url(${image})` }}
        >
          {isInfo ? <Alert className='icon' color='#76B82D' /> : <CheckOk className='icon' />}
        </div>
      )}
    </>
  );
};

export default MoodDetails;
