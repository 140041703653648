import * as React from 'react';

function SvgEdificio(props) {
  return (
    <svg viewBox='0 0 37 33' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#edificio_svg__clip0_2723_23190)' fill={props.color}>
        <path d='M33.47 6.75h-5.16c-.66 0-1.27.2-1.79.53V3.34c0-1.84-1.5-3.34-3.34-3.34H3.34C1.5 0 0 1.5 0 3.34V28.8c0 1.84 1.5 3.34 3.34 3.34h30.13c1.84 0 3.34-1.5 3.34-3.34V10.09c0-1.84-1.5-3.34-3.34-3.34zM1.55 28.8V3.34c0-.99.8-1.79 1.79-1.79h19.84c.99 0 1.79.8 1.79 1.79v27.25H3.34c-.99 0-1.79-.8-1.79-1.79zm33.7 0c0 .99-.8 1.79-1.79 1.79h-6.94v-20.5c0-.99.8-1.79 1.79-1.79h5.16c.99 0 1.79.8 1.79 1.79V28.8h-.01z' />
        <path d='M19.67 12.34H15.8a.781.781 0 000 1.56h3.87a.781.781 0 000-1.56zM19.67 18.26H15.8a.781.781 0 000 1.56h3.87a.781.781 0 000-1.56zM19.67 6.42H15.8a.781.781 0 000 1.56h3.87a.781.781 0 000-1.56zM19.67 24.18H15.8a.781.781 0 000 1.56h3.87a.781.781 0 000-1.56zM10.728 12.34h-3.87a.781.781 0 000 1.56h3.87a.781.781 0 000-1.56zM10.728 18.26h-3.87a.781.781 0 000 1.56h3.87a.781.781 0 000-1.56zM10.728 6.42h-3.87a.781.781 0 000 1.56h3.87a.781.781 0 000-1.56zM10.728 24.18h-3.87a.781.781 0 000 1.56h3.87a.781.781 0 000-1.56zM32.53 12.34h-3.29a.781.781 0 000 1.56h3.29a.781.781 0 000-1.56zM32.53 18.26h-3.29a.781.781 0 000 1.56h3.29a.781.781 0 000-1.56zM32.53 24.18h-3.29a.781.781 0 000 1.56h3.29a.781.781 0 000-1.56z' />
      </g>
      <defs>
        <clipPath id='edificio_svg__clip0_2723_23190'>
          <path fill='#fff' d='M0 0h36.81v32.15H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgEdificio;
