import BoxAPI from './api';

export const uploadFile = async (file, name, parentFolderId) => {
    const blob = new Blob([file]);
    let data = new FormData();
    data.append('attributes', `{\n  "name": "${name}",\n  "parent": {\n    "id": "${parentFolderId}"\n  }}`); // ,\n  "content_created_at": "2012-12-12T10:53:43-08:00",\n  "content_modified_at": "2012-12-12T10:53:43-08:00"\n}');
    data.append('file', blob);
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://upload.box.com/api/2.0/files/content?fields=id,type,name',
        headers: {
            'content-type': 'multipart/form-data'
        },
        data : data
    };
    try {
        const response = await BoxAPI.request(config);
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const uploadFileVersion = async (file, name, parentFolderId, fileId) => {
    const blob = new Blob([file]);
    let data = new FormData();
    data.append('attributes', `{\n  "name": "${name}",\n  "parent": {\n    "id": "${parentFolderId}"\n  }}`);
    data.append('file', blob);
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `https://upload.box.com/api/2.0/files/${fileId}/content`,
        headers: {
            'content-type': 'multipart/form-data'
        },
        data : data
    };
    try {
        const response = await BoxAPI.request(config);
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
};