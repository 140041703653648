import {
  alpha,
  Box,
  Grid,
  InputBase,
  LinearProgress,
  makeStyles,
  Popover,
} from '@material-ui/core';
import clsx from 'clsx';
import IconButton from '../Buttons/IconButton';
import IconRoundFlatButton from '../Buttons/IconRoundFlatButton';
import { Time, Duplicate, CheckOk, Rename, Eye, Trash, Alert, Compass, Send } from '../Icons';
import dayjs from 'dayjs';
import { useTheme } from '@material-ui/styles';
import { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useEffect } from 'react';
import { getConfigurationPercentage } from '../../services/configurationApi';
import { useStore } from '../../store/storeUtils';
import InfoPopup, { GenericTextContent } from '../InfoPopup';

const useStyles = makeStyles((theme) => ({
  configurationCardRoot: {
    backgroundColor: '#fbf8f5',
    width: '100%',
    marginBottom: '20px',
    borderRadius: 8,
    '&.confirmed': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
    '& .confirmed-info': {
      borderTop: '2px solid #d9d7d4',
      textAlign: 'center',
      '& .info-icon': {
        paddingTop: 32,
        marginBottom: 8,
        '& svg': {
          height: 40,
          width: 'auto',
        },
      },
      '& .info-text': {
        fontSize: 18,
        paddingBottom: 32,
        borderBottom: `1px solid ${theme.palette.color.grey2}`,
        '& > div:first-child': {
          fontSize: 24,
          marginBottom: 8,
        },
        '& span': {
          fontWeight: 700,
        },
        '& svg': {
          color: theme.palette.primary.main,
        },
      },
      '& .info-orange-text': {
        fontSize: 14,
        padding: '8px 0',
        color: theme.palette.primary.main,
      },
    },
  },
  configurationCard: {
    padding: '12px 22px 16px',
    backgroundColor: theme.palette.color.white,
    width: '100%',
    borderRadius: 8,
    '& .duplicate-action': {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        marginRight: '5px',
      },
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.color.grey2}`,
    '& .element': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingBottom: '8px',
      '& > svg': {
        marginRight: '8px',
      },
      '& span': {
        paddingTop: '3px',
      },
      '&:first-child': {
        color: '#646363',
      },
      '&:last-child': {
        color: ({ isConfigurationSent }) =>
          isConfigurationSent ? '#5e5e5e' : theme.palette.primary.main,
        textDecoration: ({ isConfigurationSent }) => !isConfigurationSent && 'underline',
        cursor: ({ isConfigurationSent }) => !isConfigurationSent && 'pointer',
      },
    },
  },
  content: {
    marginTop: '12px',
    '& .numbering': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '40px 0',
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      minWidth: '108px',
      '& .circle': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '84px',
        height: '84px',
        fontSize: '48px',
        lineHeight: '58px',
        color: theme.palette.color.black,
        border: `1px solid ${theme.palette.color.black}`,
        borderRadius: '50%',
        paddingTop: '8px',
      },
    },
    '& .configuration-name': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& .title': {
        width: '100%',
        padding: '11px 8px',
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
        fontWeight: 600,
        textAlign: 'center',
        textTransform: 'uppercase',
      },
      '& .name': {
        color: theme.palette.color.black,
        width: '100%',
        textAlign: 'center',
        padding: '0 10px',
        fontSize: '18px',
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '& .action': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: theme.palette.primary.main,
        textDecoration: 'underline',
        cursor: 'pointer',
        '& > svg': {
          marginRight: '6px',
        },
      },
    },
    '& .configuration-status': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& .title': {
        width: '100%',
        padding: '11px 8px',
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
        fontWeight: 600,
        textAlign: 'center',
        textTransform: 'uppercase',
      },
      '& .status': {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        alignItems: 'center',
        color: theme.palette.color.black,
        textAlign: 'center',
        justifyContent: 'center',
        '& .text': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: '8px',
          '& > svg': {
            marginRight: '8px',
            color: theme.palette.primary.main,
          },
          '& span': {
            fontWeight: 600,
            '&.completed': {
              color: theme.palette.secondary.main,
            },
            '&.progressing': {
              color: theme.palette.primary.main,
            },
          },
        },
        '& .confirmed-status': {
          marginTop: 4,
          marginBottom: 16,
          padding: '0 10%',
          '& span': {
            color: theme.palette.primary.main,
            fontWeight: 700,
          },
        },
        '& .confirmed-status-info': {
          fontWeight: 700,
        },
      },
      '& .text': {
        fontWeight: 600,
        fontSize: '15px',
        lineHeight: '18px',
        textAlign: 'center',
        color: '#000',
      },
      '& .action': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: theme.palette.primary.main,
        textDecoration: 'underline',
        cursor: 'pointer',
        '& > svg, & path': {
          marginRight: '6px',
          fill: theme.palette.primary.main,
        },
      },
    },
    '& .action-button': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      height: '100%',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-end',
        padding: '16px 20px',
      },
    },
  },
  progress: {
    width: '100%',
    height: '2px',
    borderRadius: '4px',
    '&.completed': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&.progressing': {
      backgroundColor: alpha(theme.palette.primary.main, 0.3),
    },
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '15px',
  },
  renamingInput: {
    width: '100%',
    borderBottom: '1px solid black',
    fontFamily: 'p22-underground',
    '& input': {
      textAlign: 'center',
      fontSize: '14px',
    },
  },
  popover: {
    backgroundColor: 'rgba(0,0,0,0) !important',
    borderTopLeftRadius: '90px',
  },
}));

const ConfigurationCard = ({
  index = 1,
  id,
  name,
  lastAnswerDate,
  handleDelete,
  handleUpdate,
  handleDuplicate,
  apartment,
  status,
  isConfigurationSent,
  configuration,
  isConfigurationsFull,
  isConfirmed,
  editable,
}) => {
  const classes = useStyles({ status, isConfigurationSent });
  const theme = useTheme();
  const history = useHistory();
  const store = useStore();

  const [currentName, setCurrentName] = useState(name);
  const [isRenaming, setRenaming] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const infoRef = useRef();

  const handleRenamingButton = () => {
    if (isRenaming) {
      // Controllo se il nome è cambiato
      if (name !== currentName) {
        // Lo Salvo
        handleUpdate(id, currentName);
      }
    }

    setRenaming(!isRenaming);
  };

  const handleResumeButton = () => {
    store.setCurrentConfiguration(configuration);
    history.push(`/apartment/${apartment}/${id}/configurator`);
  };
  const handleShowButton = () => {
    store.setCurrentConfiguration(configuration);
    history.push(`/apartment/${apartment}/${id}/resume`);
  };

  const handleCancelRenaming = () => {
    setCurrentName(name);
    setRenaming(false);
  };

  useEffect(() => {
    getProgress();
  }, []);

  const getProgress = async () => {
    try {
      const configurationPercentage = await getConfigurationPercentage(id);
      const progress = Number(configurationPercentage.data * 100).toFixed(0) * 1;
      setProgress(progress);
      setIsCompleted(progress === 100);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Grid container>
      <Grid item xs>
        <div className={clsx(classes.configurationCardRoot, isConfirmed && 'confirmed')}>
          <div className={clsx(classes.configurationCard, isConfirmed && 'confirmed')}>
            {!isConfirmed && (
              <div className={classes.header}>
                <>
                  <div className='element'>
                    {lastAnswerDate && (
                      <>
                        <Time color='currentColor' width='15px' height='15px' />
                        <span>
                          data ultimo salvataggio{' '}
                          <strong>{dayjs(lastAnswerDate).format('DD.MM.YYYY')}</strong>
                        </span>
                      </>
                    )}
                  </div>
                  <div className='element'>
                    {isConfigurationsFull || !editable ? (
                      ''
                    ) : (
                      <div className='duplicate-action' onClick={handleDuplicate}>
                        <Duplicate color='currentColor' width='13px' height='13px' />
                        <span>Duplica configurazione</span>
                      </div>
                    )}
                  </div>
                </>
              </div>
            )}

            <div className={classes.content}>
              <Grid container spacing={1}>
                <Grid item xs={3} md={2} lg={2}>
                  <div className='numbering'>
                    <span className='circle'>{String(index).padStart(2, '0')}.</span>
                  </div>
                </Grid>
                <Grid item xs={4} md={5} lg={4} className='configuration-name'>
                  <div className='title'>Nome configurazione</div>
                  <div className='name'>
                    {isRenaming ? (
                      <InputBase
                        autoFocus
                        classes={{ root: classes.renamingInput }}
                        value={currentName}
                        inputProps={{ 'aria-label': 'naked' }}
                        onChange={(e) => setCurrentName(e.target.value)}
                      />
                    ) : (
                      <span>{name}</span>
                    )}
                  </div>
                  {editable && (
                    <div
                      className='action'
                      style={{
                        visibility: !isConfigurationSent ? 'visible' : 'hidden',
                      }}
                    >
                      <Rename color='currentColor' width='20px' height='20px' />
                      {isRenaming && (
                        <Box display={'inline'} marginRight={'15px'} onClick={handleCancelRenaming}>
                          Annulla
                        </Box>
                      )}
                      <span onClick={handleRenamingButton}>
                        {isRenaming ? 'Salva' : 'Rinomina'}
                      </span>
                    </div>
                  )}
                </Grid>
                <Grid item xs={5} md={5} lg={4} className='configuration-status'>
                  <div className='title'>Stato configurazione</div>
                  <div className='status'>
                    {isConfirmed ? (
                      <>
                        <CheckOk color={theme.palette.primary.main} width='20px' height='20px' />
                        <div className='confirmed-status'>
                          La tua configurazione è stata inviata ed è{' '}
                          <span>in fase di valutazione</span>.
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='text'>
                          {isCompleted ? (
                            <CheckOk width='20px' height='20px' />
                          ) : (
                            <Box
                              border={`3px solid ${theme.palette.primary.main}`}
                              borderRadius={'100%'}
                              width='17px'
                              height='17px'
                              marginRight={'5px'}
                            />
                          )}
                          Completata al&nbsp;
                          <span className={clsx(isCompleted && 'completed')}>{progress}%</span>
                        </div>
                        <LinearProgress
                          variant='determinate'
                          className={clsx(classes.progress, isCompleted && 'completed')}
                          value={Number(progress)}
                          color={isCompleted ? 'secondary' : 'primary'}
                        />
                      </>
                    )}
                  </div>
                  {editable && (
                    <div
                      className='action'
                      onClick={handleResumeButton}
                      style={{
                        visibility: !isConfigurationSent ? 'visible' : 'hidden',
                      }}
                    >
                      <span className='action'>
                        <Compass color='currentColor' width='20px' height='20px' />
                        {isCompleted
                          ? 'Modifica la personalizzazione'
                          : 'Continua la personalizzazione'}
                      </span>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} md={12} lg={2}>
                  <div className='action-button'>
                    <IconButton
                      text='Visualizza'
                      icon={<Eye color='#fff' width='100%' height='100%' />}
                      filled
                      onClick={handleShowButton}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
          {isConfirmed && (
            <>
              <div className='confirmed-info'>
                <div className='info-icon'>
                  <Send />
                </div>
                <div className='info-text'>
                  <div className='italic'>La tua configurazione è stata inviata.</div>
                  <div ref={infoRef}>
                    <span>Attendi il documento definitivo</span> da sottoscrivere con la firma
                    digitale.{' '}
                    <Alert
                      color=''
                      width='15px'
                      height='15px'
                      onClick={() => {
                        setShowInfo(true);
                      }}
                    />
                  </div>
                  <Popover
                    open={showInfo}
                    anchorEl={infoRef.current}
                    onClose={() => setShowInfo(false)}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    PaperProps={{ classes: { root: classes.popover } }}
                  >
                    <InfoPopup
                      body={
                        <GenericTextContent
                          text={
                            "Il documento da sottoscrivere viene inviato manualmente dal servizio clienti e non è un'operazione automatica. Ti preghiamo di pazientare, grazie."
                          }
                        />
                      }
                      isPopover
                      handleClose={() => setShowInfo(false)}
                    />
                  </Popover>
                </div>
              </div>
            </>
          )}
        </div>
      </Grid>
      {editable && (
        <Grid item xs={'auto'} className={classes.button}>
          <IconRoundFlatButton
            onClick={handleDelete}
            icon={<Trash color='currentColor' width='50%' height='50%' />}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ConfigurationCard;
