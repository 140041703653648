import * as React from 'react';

function SvgInfoCircled2(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 30 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M14.79 29.79c8.014 0 14.51-6.609 14.51-14.76C29.3 6.878 22.804.27 14.79.27S.28 6.878.28 15.03c0 8.151 6.496 14.76 14.51 14.76z'
        stroke={props.color}
        strokeWidth={0.56}
        strokeMiterlimit={10}
      />
      <path
        d='M17.79 5.53a1.5 1.5 0 00-1.15-.48 1.4 1.4 0 00-1.09.51 1.76 1.76 0 00-.46 1.2c.002.43.166.845.46 1.16a1.45 1.45 0 001.09.5 1.43 1.43 0 001.14-.53 1.84 1.84 0 00.46-1.24 1.58 1.58 0 00-.45-1.12zm-1.1 17.26a5.53 5.53 0 01-.68.06 1.61 1.61 0 01-1-.26 1.241 1.241 0 01-.32-1c.007-.26.037-.517.09-.77 0-.2.14-.63.22-1l1.31-5.31c.068-.256.118-.517.15-.78.032-.202.049-.406.05-.61a1.85 1.85 0 00-.46-1.32 1.94 1.94 0 00-1.47-.49c-.41.024-.817.097-1.21.22-.594.168-1.172.386-1.73.65l-.09.4c-.05.22-.06.39 0 .38.06-.01.31-.07.61-.13.237-.053.478-.083.72-.09a1.36 1.36 0 01.93.23c.189.27.27.602.23.93-.004.285-.035.57-.09.85 0 .23-.13.65-.19.94l-1.31 5.32c-.05.23-.13.58-.16.79-.03.198-.047.399-.05.6a1.76 1.76 0 00.5 1.28 1.93 1.93 0 001.48.52 4.54 4.54 0 001.16-.18c.62-.171 1.229-.385 1.82-.64l.09-.4c.06-.22.06-.39 0-.37-.193.056-.39.096-.59.12'
        fill='#000'
      />
    </svg>
  );
}

export default SvgInfoCircled2;
