import * as React from 'react';

function SvgPreseIngresso(props) {
  return (
    <svg viewBox='0 0 65 50' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#prese-ingresso_svg__clip0_2758_23638)' fill='#C4C4C4'>
        <path d='M11.77 5.02l16.24-2.91v9.76h2V1c0-.55-.45-1-1-1H1C.45 0 0 .45 0 1v41.78c0 .55.45 1 1 1s1-.45 1-1V2h16.26L10.7 3.36c-.44.07-.75.45-.75.89v35.28c0 .44.31.82.75.89l6.01 1.08v-1.85l-4.94-.89V5.02z' />
        <path d='M13.48 21.89c0 .84.68 1.52 1.53 1.52s1.52-.68 1.52-1.52-.68-1.53-1.52-1.53-1.53.69-1.53 1.53zM42.222 28.73h-2.46v6.18h2.46v-6.18zM34.82 28.73h-2.46v6.15h2.46v-6.15z' />
        <path d='M49.62 22.23c0-.72-.29-1.42-.8-1.93s-1.21-.8-1.93-.81H27.7c-.73 0-1.42.3-1.94.81-.51.51-.8 1.21-.8 1.93v19.2c0 .6.2 1.17.56 1.65.07.1.15.19.24.28.52.51 1.21.8 1.94.8h19.19a2.751 2.751 0 002.73-2.73v-19.2zm-4.13 15.08a9.841 9.841 0 01-4.43 3.63 9.87 9.87 0 01-10.75-2.14c-.1-.1-.21-.21-.3-.32a9.692 9.692 0 01-2-3.31c-.17-.46-.3-.94-.4-1.42a9.89 9.89 0 01.4-5.28c.05-.14.1-.28.16-.42a9.643 9.643 0 011.84-2.87c.53-.59 1.13-1.11 1.8-1.56a9.89 9.89 0 015.48-1.66c1.29 0 2.58.25 3.77.75 1.2.49 2.29 1.22 3.21 2.14.91.91 1.64 2 2.13 3.2.5 1.2.76 2.48.75 3.77 0 1.96-.57 3.86-1.66 5.49zM61.23 30.52h1.11c.14 0 .25-.11.25-.25v-4.74c0-.11 0-.22-.05-.33a.75.75 0 00-.18-.28.75.75 0 00-.28-.18c-.06-.02-.12-.04-.19-.04a.169.169 0 00-.1-.03c-.11-.01-.22 0-.33.05-.1.04-.2.1-.28.18a.75.75 0 00-.18.28c-.04.1-.05.22-.05.3v4.79c0 .14.11.25.25.25h.03zM54.949 30.49h1.05c.14 0 .25-.11.25-.25V25.5a.75.75 0 00-.22-.6.75.75 0 00-.28-.18c-.1-.03-.2-.04-.32-.04-.08 0-.15.01-.22.04-.01 0-.03 0-.04.02-.1.04-.19.1-.26.18-.08.07-.13.16-.17.27-.04.1-.05.21-.04.29v4.76c0 .14.11.25.25.25zM64.652 32.54a.87.87 0 00-.17-.64c-.14-.18-.34-.29-.6-.33h-10.56s-.01 0-.02.01a.842.842 0 00-.76.62c-.03.11-.04.22-.03.31v6.01c-.01.11 0 .23.03.34.04.11.09.22.16.31.08.08.17.16.27.21.11.06.22.09.35.1h10.59c.11-.02.22-.05.32-.11.1-.05.18-.13.25-.22s.12-.19.15-.3c.03-.11.04-.22.03-.3v-6.01h-.01zM60.191 40.66h-3.17c-.14 0-.25.11-.25.25v1.06c0 .14.11.25.25.25h.81v3.3a2.52 2.52 0 01-.74 1.67c-.44.45-1.04.71-1.65.74h-5.82c-.14 0-.25.11-.25.25v1.06c0 .14.11.25.25.25h5.82c2.45 0 3.95-2 3.95-3.43v-3.84h.8c.14 0 .25-.11.25-.25v-1.06c0-.14-.11-.25-.25-.25z' />
      </g>
      <defs>
        <clipPath id='prese-ingresso_svg__clip0_2758_23638'>
          <path fill='#fff' d='M0 0h64.66v49.49H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgPreseIngresso;
