import * as React from 'react';

function SvgPreseBagno(props) {
  return (
    <svg viewBox='0 0 74 46' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#prese-bagno_svg__clip0_2758_23666)' fill='#C4C4C4'>
        <path d='M1.75 5.37c0-1.99 1.62-3.62 3.61-3.62 1.04 0 2.03.45 2.71 1.23-1.29 2-1.07 4.7.68 6.45.16.16.38.25.62.25s.45-.09.61-.25l6.05-6.05c.16-.16.25-.38.25-.62s-.09-.45-.25-.61a5.13 5.13 0 00-6.75-.44A5.397 5.397 0 005.36 0C2.4 0 0 2.41 0 5.37v24.54c0 3.66 2.75 6.68 6.3 7.12v4.42c0 .49.39.88.88.88s.87-.39.87-.88v-4.36h19.14v-1.75H7.18c-2.99 0-5.43-2.44-5.43-5.43V22.8h25.44v-1.75H1.75V5.37z' />
        <path d='M13.76 24.91c-.48 0-.87.39-.87.88s.39.87.87.87h11.28c.48 0 .87-.39.87-.87a.88.88 0 00-.87-.88H13.76zM4.8 24.91c-.48 0-.87.39-.87.88s.39.87.87.87h5.05c.48 0 .87-.39.87-.87a.88.88 0 00-.87-.88H4.8zM13.862 10.72c.07-.22.06-.46-.05-.67l-.22-.45a.879.879 0 00-1.17-.39c-.21.11-.37.29-.44.51-.07.22-.06.46.05.67l.22.45c.15.3.45.48.78.48.14 0 .27-.03.39-.09.21-.11.37-.29.44-.51zM13.69 13.71c.15.3.45.48.78.48a.859.859 0 00.83-.6c.07-.22.06-.46-.05-.67l-.29-.58a.884.884 0 00-.51-.44.916.916 0 00-.67.05c-.43.22-.6.74-.39 1.17l.29.58.01.01zM15.07 16.45c.15.3.45.48.78.48a.87.87 0 00.78-1.26l-.22-.45a.855.855 0 00-.51-.44.916.916 0 00-.67.05c-.21.1-.36.28-.44.51-.07.22-.06.46.05.67l.22.45.01-.01zM16.622 6.65l.47.18a.888.888 0 001.14-.5.876.876 0 00-.5-1.13l-.47-.18a.876.876 0 00-1.13.5c-.17.45.05.96.5 1.13h-.01zM19.49 7.78l.6.24a.864.864 0 001.13-.5.868.868 0 00-.5-1.13l-.6-.24a.884.884 0 00-1.14.49c-.18.45.05.96.5 1.13l.01.01zM22.5 8.95l.47.18a.888.888 0 001.14-.5.876.876 0 00-.5-1.13l-.47-.18a.876.876 0 00-1.13.5c-.17.45.05.96.5 1.13h-.01zM14.83 9.06a.884.884 0 001.23-.13.872.872 0 00-.13-1.23l-.39-.31a.874.874 0 10-1.1 1.36l.39.31zM17.311 9.15a.873.873 0 00-.46 1.55l.51.41c.16.13.35.19.55.19.27 0 .51-.12.68-.33a.872.872 0 00-.13-1.23l-.51-.41a.83.83 0 00-.64-.19v.01zM20.492 11.38a.872.872 0 00-.64-.19c-.23.03-.44.14-.59.32-.3.38-.24.93.13 1.23l.39.31c.16.13.35.19.55.19.27 0 .52-.12.68-.33.3-.38.24-.93-.13-1.23l-.39-.31v.01zM50.85 24.56h-2.46v6.18h2.46v-6.18zM41.218 24.56h-.23v6.16h2.46v-6.16h-2.23z' />
        <path d='M58.25 18.06c0-.72-.29-1.42-.8-1.93s-1.21-.8-1.93-.81H36.33c-.73.01-1.42.3-1.94.81-.51.51-.8 1.21-.8 1.93v19.2c0 .72.29 1.41.8 1.93.33.33.74.57 1.18.69.25.07.5.11.76.11h19.19c.72 0 1.42-.29 1.93-.81.51-.51.8-1.2.8-1.92v-19.2zm-4.13 15.08a9.841 9.841 0 01-4.43 3.63 9.87 9.87 0 01-10.75-2.14c-.11-.11-.22-.22-.32-.34-.4-.43-.75-.9-1.06-1.4a9.62 9.62 0 01-1.32-3.31c-.38-1.91-.18-3.9.56-5.7.16-.37.33-.73.54-1.08.35-.63.77-1.22 1.26-1.75.54-.6 1.16-1.14 1.84-1.6a9.89 9.89 0 019.26-.91c1.19.5 2.28 1.22 3.2 2.14.91.92 1.64 2 2.13 3.2.5 1.2.76 2.48.75 3.78 0 1.95-.57 3.85-1.66 5.48zM69.858 26.35h1.11c.14 0 .25-.11.25-.25v-4.74c0-.11 0-.22-.05-.33a.75.75 0 00-.18-.28.75.75 0 00-.28-.18c-.06-.02-.12-.04-.19-.04a.169.169 0 00-.1-.03c-.11-.01-.22 0-.33.05-.1.04-.2.1-.28.18a.75.75 0 00-.18.28c-.04.1-.05.22-.05.3v4.79c0 .14.11.25.25.25h.03zM63.58 26.32h1.05c.14 0 .25-.11.25-.25v-4.74c.01-.11 0-.22-.04-.32s-.1-.2-.18-.28a.898.898 0 00-.28-.18c-.1-.03-.2-.04-.31-.04-.1 0-.18.02-.27.06-.1.04-.19.1-.26.18a.77.77 0 00-.17.27c-.03.1-.05.21-.04.3v4.75c0 .14.11.25.25.25zM73.28 28.37a.834.834 0 00-.17-.63.826.826 0 00-.6-.33H61.93a.842.842 0 00-.6.31c-.07.09-.12.2-.16.31-.03.11-.04.22-.03.31v6.01c-.01.11 0 .23.03.34.04.11.09.22.16.31.08.09.17.16.27.21.11.06.22.09.35.1h10.59c.11-.02.22-.05.32-.11.1-.05.18-.13.25-.22s.12-.19.15-.3c.03-.11.04-.22.03-.3v-6.01h-.01zM68.82 36.49h-3.17c-.14 0-.25.11-.25.25v1.06c0 .14.11.25.25.25h.81v3.3a2.5 2.5 0 01-.74 1.67l-.03.03c-.44.43-1.02.68-1.62.71h-5.82c-.14 0-.25.11-.25.25v1.06c0 .14.11.25.25.25h5.82c.6 0 1.15-.12 1.62-.32 1.47-.63 2.33-2.03 2.33-3.11v-3.84h.8c.14 0 .25-.11.25-.25v-1.06c0-.14-.11-.25-.25-.25z' />
      </g>
      <defs>
        <clipPath id='prese-bagno_svg__clip0_2758_23666'>
          <path fill='#fff' d='M0 0h73.29v45.32H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgPreseBagno;
