import { ButtonBase, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { useHistory, useRouteMatch } from 'react-router';
import { useStore } from '../../store/storeUtils';
import MenuItem from './MenuItem';

const useStyles = makeStyles((theme) => ({
  menuSubItemRoot: {
    backgroundColor: '#F9F9F9',
    borderTop: `1px solid ${theme.palette.color.white}`,
    borderBottom: `1px solid ${theme.palette.color.white}`,
    height: '40px',
    paddingLeft: ({ parentSubstep }) => (parentSubstep ? '55px' : '26px'),
    fontSize: '12px',
    fontWeight: 500,
    justifyContent: 'flex-start',
    '&.collapsed': {
      padding: '0 8px',
      height: '30px',
      justifyContent: 'center',
    },
    '&.active': {
      backgroundColor: theme.palette.color.white,
      fontWeight: 700,
    },
    '&.blocked': {
      pointerEvents: 'none',
    },
    '&.DEV-MODE': {
      pointerEvents: 'initial !important',
    },
  },
  status: {
    minWidth: '15px',
    height: '15px',
    marginRight: '13px',
    borderRadius: '50%',
    '&.completed': {
      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/icons/check-ok.svg)`,
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      color: theme.palette.primary.main,
    },
    '&.active': {
      color: theme.palette.primary.main,
      border: `2px solid ${theme.palette.primary.main}`,
    },
    '&.blocked': {
      backgroundColor: theme.palette.color.grey2,
      color: theme.palette.color.grey2,
      //   border: `2px solid ${theme.palette.color.grey2}`,
    },
    '&.collapsed': {
      minWidth: '15px',
      width: '15px',
      height: '15px',
      borderRadius: '100%',
      margin: 0,
    },
  },
  title: {
    alignSelf: 'center',
    textTransform: 'uppercase',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.color.black,
    paddingTop: '3px',
    whiteSpace: 'nowrap',
    '&.blocked': {
      color: theme.palette.color.grey2,
    },
    '&.collapsed': {
      display: 'none',
    },
  },
  subItem: {
    height: '40px',
    minHeight: '40px',
    padding: '0 21px 0 50px',
  },
}));

const MenuSubItem = observer(
  ({
    id,
    itemKey,
    name,
    isAnswered,
    isCompleted,
    substeps,
    isCollapsed,
    parentStep,
    parentSubstep,
    frontendId,
    isNew,
    hideInMenu,
    roomId,
    isBagnoSubPhase,
    isBagnoSubSubPhase,
  }) => {
    const classes = useStyles({ parentSubstep });
    const history = useHistory();
    const store = useStore();
    const currentRoomId = store.currentRoomId;
    const phase = store.getCurrentPhaseData();

    const { url } = useRouteMatch();

    const handleClick = () => {
      if (parentSubstep) {
        store.setCurrentStep((isBagnoSubPhase || isBagnoSubSubPhase) ? parentSubstep?.id : parentStep?.id);
        roomId ? store.setCurrentRoomId(roomId) : store.setCurrentRoomId('');
        // store.setCurrentStep(parentStep?.order);
        store.setCurrentSubstep((isBagnoSubPhase || isBagnoSubSubPhase) ? frontendId : parentSubstep?.frontendId);
        store.setCurrentSubstepId((isBagnoSubPhase || isBagnoSubSubPhase) ? id : parentSubstep?.id);
        store.setCurrentSubSubstep(frontendId);
        (isBagnoSubPhase || isBagnoSubSubPhase) ? history.push(`${url}/${parentSubstep?.key}/${itemKey}`) : history.push(`${url}/${parentStep?.key}/${itemKey}`);
      } else {
        store.setCurrentStep((isBagnoSubPhase || isBagnoSubSubPhase) ? id : parentStep?.id);
        roomId ? store.setCurrentRoomId(roomId) : store.setCurrentRoomId('');
        // store.setCurrentStep(parentStep?.order);
        store.setCurrentSubstep(frontendId);
        store.setCurrentSubstepId(id);
        (isBagnoSubPhase || isBagnoSubSubPhase) ? history.push(`${url}/${parentSubstep?.key}/${itemKey}`) : history.push(`${url}/${parentStep?.key}/${itemKey}`);
      }
    };
    let isActive = false;
  
    /*
    console.log('eccomi, name ', name);
    console.log('eccomi, currentStep ', store.currentStep);
    console.log('eccomi, id ', id);
    console.log('eccomi, currentSubstep ', store.currentSubstep);
    console.log('eccomi, frontendId ', frontendId);
    console.log('eccomi, parentStep?.id ', parentStep?.id);
    console.log('eccomi, currentSubSubstep ', store.currentSubSubstep);
    console.log('eccomi, currentRoomId ', currentRoomId);
    console.log('eccomi, roomId ', roomId);
    console.log('eccomi -------------------------------------');
    */
    
    if(isBagnoSubPhase || isBagnoSubSubPhase) {
      isActive = (store.currentStep === id && store.currentSubstep === frontendId) ||
      // (store.currentStep === id  && store.currentSubSubstep === id && store.currentSubstep === frontendId)  || 
        (parentStep?.id === phase.id && ((isAnswered || substeps?.some(substep => substep?.isAnswered === true)) ? true : currentRoomId === roomId) && id === store.currentStep) ||
        (id === store.currentStep && ((isAnswered || substeps?.some(substep => substep?.isAnswered === true)) ? true : currentRoomId === roomId)) ||
        // (store.currentSubSubstep === frontendId && currentRoomId === roomId) ||
        (store.currentSubstep === frontendId && ((isAnswered || substeps?.some(substep => substep?.isAnswered === true)) ? true : currentRoomId === roomId))
    } else {
      isActive = roomId ? (store.currentStep === parentStep?.id && ((substeps?.some(substep => substep?.isAnswered === true)) ? true : currentRoomId === roomId)) : (store.currentStep === parentStep?.id && store.currentSubstep === frontendId) ||
      (store.currentStep === parentStep?.id && store.currentSubSubstep === id && store.currentSubstep === parentSubstep?.frontendId) || 
      (store.currentSubstep === parentSubstep?.frontendId);
    }

    const status = (isAnswered || substeps?.every(substep => substep?.isAnswered === true)) ? 'completed' : isActive ? '' : 'blocked';
    /*
    console.log('eccomi, name ', name);
    console.log('eccomi, substeps ', substeps);
    console.log('eccomi, isNew ', isNew);
    console.log('eccomi, isAnswered ', isAnswered);
    console.log('eccomi, hideInMenu', hideInMenu);
    console.log('eccomi, status', status);
    console.log('eccomi, isActive ', isActive);
    console.log('eccomi, isCompleted ', isCompleted);
    console.log('eccomi, parentStep?.id ', parentStep);
    console.log('eccomi, parentSubstep', parentSubstep);
    console.log('eccomi, currentSubSubstep ', store.currentSubSubstep);
    console.log('eccomi, currentStep ', store.currentStep);
    console.log('eccomi, currentSubstep ', store.currentSubstep);
    console.log('eccomi, frontendId ', frontendId);
    console.log('eccomi, currentRoomId ', currentRoomId);
    console.log('eccomi, roomId ', roomId);
    console.log('eccomi -------------------------------------');
    // console.log('eccolo: ', name, status);
    //console.log('ciaone: ',name, (substeps && isNew && isActive && !hideInMenu) || (substeps && !isNew && !hideInMenu) || (substeps && isAnswered && !hideInMenu) || (isCompleted && !hideInMenu) || (isAnswered && !hideInMenu));
    /*
    const isActive =
      (((isBagnoSubPhase || isBagnoSubSubPhase) ? store.currentStep === id :  store.currentStep === parentStep?.id) && store.currentSubstep === frontendId) ||
      (((isBagnoSubPhase || isBagnoSubSubPhase) ?  store.currentStep === id : store.currentStep === parentStep?.id) && store.currentSubSubstep === id &&
        ((isBagnoSubPhase || isBagnoSubSubPhase) ? store.currentSubstep === frontendId : store.currentSubstep === parentSubstep?.frontendId)) || 
        (isBagnoSubPhase ? (parentStep?.id === phase.id && currentRoomId === roomId) :  false) ||
        (isBagnoSubPhase ? (id === store.currentStep && currentRoomId === roomId) :  false);
    */

    return (
      <>
        {(((substeps && isNew && isActive) || (substeps && !isNew) || (substeps && isAnswered) || substeps?.some(substep => substep?.isAnswered === true) || isCompleted ) && !hideInMenu) ? (
          <MenuItem
            id={id}
            name={name}
            itemKey={itemKey}
            frontendId={frontendId}
            isAnswered={isAnswered}
            hideInMenu={hideInMenu}
            substeps={substeps}
            isCollapsed={isCollapsed}
            parentStep={parentStep}
            roomId={roomId}
            subLevelClass={clsx(
              classes.status,
              status,
              isCollapsed && 'collapsed',
              isActive && 'active',
            )}
            isBagnoSubSubPhase={isBagnoSubPhase}
          />
        ) : ((((isNew && isActive) || !isNew || isAnswered || isCompleted) && !hideInMenu)) && (
          <ButtonBase
            className={clsx(
              classes.menuSubItemRoot,
              isCollapsed && 'collapsed',
              isActive && 'active',
              status,
              process.env.REACT_APP_DEV_MODE && 'DEV-MODE',
            )}
            onClick={handleClick}
          >
            <span
              className={clsx(
                classes.status,
                status,
                isCollapsed && 'collapsed',
                isActive && 'active',
              )}
            />
            <span className={clsx(classes.title, status, isCollapsed && 'collapsed')}>{name}</span>
          </ButtonBase>
        )}
      </>
    );
  },
);

export default MenuSubItem;
