import { Dialog, Grid, makeStyles } from '@material-ui/core';
import Button3D from '../Buttons/Button3D';
import { DigitalSign, DownloadOk, SendEmail } from '../Icons';
import LoadingAnimation from '../LoadingAnimation';

const useStyles = makeStyles((theme) => ({
  configurationConfirmationDialogRoot: {
    '& .dialog-first-part': {
      padding: '40px 32px',
    },
    '& .dialog-title': {
      fontFamily: 'miller',
      fontStyle: 'italic',
      fontSize: '32px',
      color: theme.palette.primary.main,
      marginBottom: 4,
    },
    '& .dialog-subtitle': {
      fontWeight: '800',
      textTransform: 'uppercase',
      paddingBottom: 32,
      borderBottom: '2px solid #e2e1e2',
      marginBottom: 24,
    },
    '& .dialog-icon': {
      textAlign: 'center',
      marginBottom: 4,
      '& svg': {
        height: 50,
        width: 'auto',
      },
    },
    '& .dialog-icon-text': {
      textAlign: 'center',
      fontWeight: '800',
      textTransform: 'uppercase',
      padding: '0 30%',
      paddingBottom: 32,
      borderBottom: '2px solid #e2e1e2',
      marginBottom: 24,
    },
    '& .dialog-body-title': {
      fontSize: '24px',
      fontFamily: 'miller',
      fontStyle: 'italic',
      marginBottom: 8,
      '& span': {
        fontFamily: 'p22-underground',
        fontStyle: 'initial',
        color: theme.palette.primary.main,
      },
    },
    '& .dialog-body-info-text': {
      color: '#b2b2b2',
      paddingRight: '30%',
      lineHeight: '1.1',
    },
    '& .dialog-step': {
      backgroundColor: '#f7f7f7;',
      borderTop: '2px solid #e2e1e2',
      borderBottom: '2px solid #e2e1e2',
      marginBottom: 40,
    },
    '& .step': {
      padding: '24px 16px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      lineHeight: 1.1,
      borderRight: '2px solid #e2e1e2',
      '&:last-child': {
        borderRight: 0,
      },
      '& span': {
        fontWeight: 700,
      },
    },
    '& .step-icon': {
      textAlign: 'center',
      marginBottom: 16,
      '& svg': {
        height: 50,
        width: 'auto',
      },
    },
    '& .step-number': {
      aspectRatio: 1,
      width: 16,
      height: 16,
      display: 'inline-flex',
      color: '#f7f7f7;',
      fontWeigh: 700,
      background: theme.palette.primary.main,
      borderRadius: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 11,
      lineHeight: 1,
      marginRight: 8,
      paddingTop: 3,
    },
    '& .dialog-button': {
      marginBottom: 16,
    },
    '& .dialog-button-back': {
      textAlign: 'center',
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      fontWeight: 700,
      marginBottom: 32,
      cursor: 'pointer',
    },
  },
}));

const ConfigurationStep = ({ icon, number, boldText, text }) => {
  return (
    <Grid item xs={6} className='step'>
      <div>
        <div className='step-icon'>{icon}</div>
        <Grid container className='step-text'>
          <Grid item className='step-number'>
            {number}
          </Grid>
          <Grid item xs>
            <span>{boldText}</span> {text}
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

const ConfigurationConfirmationDialog = ({
  isOpen,
  handleClose,
  handleConfirm,
  configurationName,
  apartmentName,
  handleDownloadConfirmed,
  setIsConfirming,
  isLoading,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      classes={{ root: classes.configurationConfirmationDialogRoot }}
    >
      <div className='dialog-first-part'>
        <div className='dialog-title'>{configurationName}</div>
        <div className='dialog-subtitle'>{apartmentName}</div>
        <div className='dialog-icon'>
          <DownloadOk />
        </div>
        <div className='dialog-icon-text'>
          La configurazione è pronta per essere inviata ad AbitareIn.
        </div>
        <div className='dialog-body-title'>
          <span>Conferma e invia</span> la configurazione per concludere il tuo percorso di personalizzazione
        </div>
        <div className='dialog-body-title'>
          <span>Attenzione</span> la configurazione inviata non potrà più essere modificata
        </div>
        {/* <div className='dialog-body-info-text'>
          Inviata la configurazione, nei prossimi giorni verrai contattato da un Personal Architect
          per proseguire con le finiture della tua casa
        </div> */}
      </div>
      <Grid container className='dialog-step'>
        <ConfigurationStep
          number={'1'}
          boldText={'Invia'}
          text={'la Configurazione.'}
          icon={<SendEmail />}
        />
        <ConfigurationStep
          number={'2'}
          boldText={'Firma'}
          text={'il documento definitivo da sottoscrivere con la firma digitale.'}
          icon={<DigitalSign />}
        />
      </Grid>
      <Grid container className='dialog-action' justifyContent='center' alignItems='center'>
        <Grid item>
        {isLoading ? <LoadingAnimation msg={'Sto inviando la configurazione...'} /> : (
          <>
            <div className='dialog-button'>
              <Button3D text={'Confermo e invio configurazione'} 
                onClick={() => {
                  setIsConfirming(true);
                  handleConfirm();
                }}
              />
            </div>
            <div className='dialog-button-back' onClick={handleClose}>
              Torna indietro
            </div>
          </>
        )}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ConfigurationConfirmationDialog;
