import { Page, StyleSheet, Text, View, Image } from '@react-pdf/renderer';
import { commonStyles } from '../pdfUtiles';

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  logoContainer: {
    height: '5%',
    width: '100%',
  },
  logo: {
    height: '100%',
    width: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  titleContainer: {
    height: '10%', 
    width: '100%', 
  },
  title: {
    fontSize: '18px',
  },
  configurationName: {
    fontFamily: 'Miller Display',
    fontSize: '24px',
  },
  bold: { 
    fontWeight: 700 
  },
  bodyContainer: {
    height: '80%', 
    width: '100%',
    display: 'flex',
    flexDirection:'column',
  },
  projectImage: {
    height: '100%', 
    width: 'auto', 
    marginLeft: 'auto', 
    marginRight: 'auto'
  },
  footer: {
    height: '5%', 
    width: '100%', 
    display: 'flex',
    flexDirection:'row',
    justifyContent: 'space-between', 
    alignItems: 'center'
  },
  flexRow: {
    display: 'flex', 
    flexDirection:'row'
  },
  flexColumn: {
    display: 'flex', 
    flexDirection:'column'
  },
  borderLeftDivider: {
    borderLeft: '1px solid #000', 
    paddingLeft: '8px', 
    marginLeft: '8px'
  },
  singleProductInfos: {
    borderLeft: '1px solid #000', 
    paddingLeft: '8px', 
    marginLeft: '8px',
    marginTop: '25px'
  },
  kitchenFirstPart: {
    marginTop: '1cm',
    height: '30%',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  kitchenFirstPartImg: {
    height: '100%',
    width: '80%',
    objectFit: 'contain',
  },
  kitchenFirstPartLegend: { 
    height: '100%', 
    width: '20%', 
    objectFit: 'contain' 
},
  kitchenSecondPart: {
    height: '60%',
    objectFit: 'contain',
  },
  disclaimer: {
    height: '10%',
  },
  planimetryPageBody: {
    display: 'flex',
    flexDirection: 'row',
    height: '90%',
  },
  planimetry: {
    height: '100%',
    width: '100%'
  },
  planimetryAndLegend: {
    height: '100%',
    width: '80%'
  },
  legend: {
    width: '20%',
    height: '100%',
  },
  electricLegend: {
    width: '20%',
    height: '100%',
  },
  img: { 
    objectFit: 'contain', 
    height: '100%', 
    width: '100%' 
    },
  infoPlan: {
    fontSize: '8px',
  }
});

export const KitchenViewBody = ({ axoImg, planImg }) => {
    return (
      <>
        <View style={styles.kitchenFirstPart}>
          <Image style={styles.kitchenFirstPartImg} src={planImg} />
          <Image
            style={styles.kitchenFirstPartLegend}
            src={`${process.env.PUBLIC_URL}/assets/images/legenda.png`}
          />
        </View>
        <Image style={styles.kitchenSecondPart} src={axoImg} />
        <View style={styles.infoPlan}>
          <Text>Le informazioni desumibili e le misure presenti nel documento sono indicative e soggette alle tolleranze previste dalla legge e/o a variazioni dovute ad esigenze tecniche di cantiere.</Text>
          <Text>Oltre ai prodotti personalizzabili, saranno previsti all’interno dell’appartamento gli elementi tecnici necessari al funzionamento degli impianti quali: </Text>
          <Text>quadro elettrico, cassette di derivazione, collettori, botole di ispezione fancoil, griglie di mandata e ripresa dell’aria, termostati.</Text>
          <Text>Inoltre, gli impianti saranno soggetti a valutazione da parte della direzione lavori e potrebbero subire variazioni.</Text>
        </View>
      </>
    );
};

export const TechInfoBody = ({ productsInfos, indexInfos, indexProduct }) => {
  if (indexProduct !== -1) {
    return (
      <View style={styles.singleProductInfos}>
        {productsInfos[indexProduct]?.infos?.map((productInfo, index) => {
          if (index >= indexInfos) {
            return (
              <View style={styles.flexRow} key={index}>
                <Text style={styles.bold}>{productsInfos[indexProduct]?.titles[index]} : </Text>
                <Text>{productInfo}</Text>
              </View>
            )
          }
          return null
        }
        )}
      </View>
    )
  }
  return (
    productsInfos.map((product) => (
      <View style={styles.singleProductInfos}>
        {product.infos?.map((productInfo, index) => {
          if (index >= indexInfos) {
            return (
              <View style={styles.flexRow} key={index}>
                <Text style={styles.bold}>{product.titles[index]} : </Text>
                <Text>{productInfo}</Text>
              </View>
            )
          }
          return null
        })}
      </View>
    ))
  );
};

  
export const PlanimetryBody= ({ planimetrySrc, showKitchenLegend, showElectricLegend }) => {
    const needLegend = showElectricLegend || showKitchenLegend;
    return (
      <>
        <View style={styles.planimetryPageBody}>
          <View style={needLegend ? styles.planimetryAndLegend : styles.planimetry}>
            {planimetrySrc && <Image style={styles.img} src={planimetrySrc} />}
          </View>
          {needLegend && (
            <View style={showElectricLegend ? styles.electricLegend : styles.legend}>
            {showKitchenLegend && (
              <Image 
                style={styles.img} 
                src={`${process.env.PUBLIC_URL}/assets/images/legenda.png`} 
              />
            )}
            {showElectricLegend && (
              <Image 
                style={styles.img} 
                src={`${process.env.PUBLIC_URL}/legenda-elettrico.png`} 
              />
            )}
            </View>
          )}
        </View>
        <View style={styles.infoPlan}>
          <Text>Le informazioni desumibili e le misure presenti nel documento sono indicative e soggette alle tolleranze previste dalla legge e/o a variazioni dovute ad esigenze tecniche di cantiere.</Text>
          <Text>Oltre ai prodotti personalizzabili, saranno previsti all’interno dell’appartamento gli elementi tecnici necessari al funzionamento degli impianti quali: </Text>
          <Text>quadro elettrico, cassette di derivazione, collettori, botole di ispezione fancoil, griglie di mandata e ripresa dell’aria, termostati.</Text>
          <Text>Inoltre, gli impianti saranno soggetti a valutazione da parte della direzione lavori e potrebbero subire variazioni.</Text>
        </View>
      </>
    );
};

const SingleStepPage = ({
  apartmentType,
  building,
  floor,
  quadrant,
  answer,
  electricPlanimetrySrc,
  bagniRooms
}) => {
    let axoImg
    let planImg

    const bagno = bagniRooms?.find((bagno) => bagno.id === answer.roomId)

    const bagnoSuffix = bagno ? bagno.name : false;

    const isKitchen = answer.key === 'Cucina_Cucina';
    if(isKitchen) {
        axoImg = answer.fileLabels.filter((el) => el.isAxo)[0]?.url;
        planImg = answer.fileLabels.filter((el) => el.isPlan)[0]?.url;
    }
    const phaseName = answer.phase.name;
    const isFirstTwoPhases = (phaseName === 'LAYOUT' || phaseName === 'TECNOLOGIA') && !isKitchen;

    let title = electricPlanimetrySrc ?  'Impianto elettrico capitolato' : answer.step.name === answer.substep.name
        ? answer.substep.name
        : `${answer.step.name}: ${answer.substep.name}`;
    
    if (bagnoSuffix) title = bagnoSuffix + ': ' + title;
    let justTechInfo = false;

    justTechInfo = [
      'PAVIMENTOLIVINGECAMERE_FORMATO',
      'PORTE_COLORE',
      'PORTE_MANIGLIA',
      'CARTAPARATI_CARTAPARATI',
      'CARTAPARATI_PLANIMETRIA',
      'ALTREDOTAZIONI_PLACCHETTEELETTRICHE',
      'RIVESTIMENTI_FORMATO',
      'RIVESTIMENTI_PAVIMENTO', // -> uno per pavimenti e l'altro per pareti (primo e secondo prodotto)
      'MOBILELAVABO_FINITURA',
      'MOBILELAVABO_MODELLO',
      'SANITARI_FINITURA',
      'SANITARI_MODELLO',
      'DOCCIA_FINITURA',
      'VASCA_FINITURA',
      'RUBINETTERIA_FINITURA',
      'ALTREDOTAZIONI_SCALDASALVIETTEEPLACCADISCARICO',
      'PAVIMENTOCUCINA_FORMATO',
      'ACCESSORIDOCCIA_FINITURAVETRO',
      'ACCESSORIVASCA_SOFFIONE',
      'ACCESSORIVASCA_BOX',
    ].includes(answer.key);

    // let productsInfos = [{titles: [], info: []}, {titles: [], info: []}]
    let productsInfos = []
    let indexProduct = -1;
    let indexInfos = 0;
    let needPlanimetry = false

    if(['PAVIMENTOCUCINA_RELAZIONELIVINGCUCINA'].includes(answer.key) && answer.isCucinaSame) {
      justTechInfo = true;
      indexInfos = 1
      title = 'PAVIMENTO CUCINA'
    }

    if (justTechInfo) {
      if(['PAVIMENTOCUCINA_FORMATO'].includes(answer.key)){
        indexInfos = 1
        title = 'PAVIMENTO CUCINA'
      }
      
      if(['PORTE_COLORE'].includes(answer.key)) {
        indexInfos = 1
      }
      if(['ALTREDOTAZIONI_PLACCHETTEELETTRICHE'].includes(answer.key)) {
        indexInfos = 1
      }
      if(['PAVIMENTOLIVINGECAMERE_FORMATO'].includes(answer.key)) {
        indexInfos = 1
        title = 'PAVIMENTO LIVING E CAMERE'
      }
      if(['PAVIMENTOCUCINA_FORMATO'].includes(answer.key)) {
        indexInfos = 1
        title = 'PAVIMENTO CUCINA'
      }
      if(['RIVESTIMENTI_FORMATO'].includes(answer.key)) {
        title = bagnoSuffix + ': RIVESTIMENTI: PARETI'
        indexProduct = 1
        indexInfos = 1
      }
      if(['RIVESTIMENTI_PAVIMENTO'].includes(answer.key)) {
        title = bagnoSuffix + ': RIVESTIMENTI: PAVIMENTO'
        indexProduct = 0
        indexInfos = 1
      }
      if(['SANITARI_FINITURA'].includes(answer.key)) title = bagnoSuffix + ': SANITARI'
      if(['DOCCIA_FINITURA'].includes(answer.key)) {
        title = bagnoSuffix + ': DOCCIA'
        indexInfos = 1
      }
      if(['VASCA_FINITURA'].includes(answer.key)) {
        title = bagnoSuffix + ': VASCA'
        indexInfos = 1
      }
      if(['RUBINETTERIA_FINITURA'].includes(answer.key)) title = bagnoSuffix + ': RUBINETTERIA'
      if(['ACCESSORIDOCCIA_FINITURAVETRO'].includes(answer.key)) {
        title = bagnoSuffix + ': BOX DOCCIA'
        indexInfos = 1
      }
      if(['ACCESSORIVASCA_BOX'].includes(answer.key)) {
        indexInfos = 1
      }
      if(['CARTAPARATI_PLANIMETRIA'].includes(answer.key)) {
        needPlanimetry = true
        title = 'CARTA DA PARATI ' + answer.roomNumber
      }
      if(['CARTAPARATI_CARTAPARATI'].includes(answer.key)) {
        indexInfos = 1;
        title = 'CARTA DA PARATI ' + answer.roomNumber
      }

      answer.products?.forEach((product) => {
          const titleAndInfos = {
              titles: [],
              infos: []
          }
          Object.keys(product).forEach((key) => {
              if(key.toLowerCase().includes('techinfo')){
                  if(key.toLowerCase().includes('title')){
                      product[key] && titleAndInfos.titles.push(product[key])
                  } else {
                      product[key] && titleAndInfos.infos.push(product[key])
                  }
              }
          })
          productsInfos.push(titleAndInfos);
      })
    }

  return (
    <Page size='A4' style={[commonStyles.page, styles.page]} orientation={'portrait'}>
      <View style={styles.logoContainer}>
        <Image style={styles.logo} src={`${process.env.PUBLIC_URL}/assets/images/logo.png`} />
      </View>
      <View style={styles.titleContainer}>
        <View style={styles.title}>
          <Text style={styles.bold}>{title}</Text>
        </View>
      </View>
      <View style={styles.bodyContainer}>
        {isKitchen && (
            <KitchenViewBody axoImg={axoImg} planImg={planImg}/>
        )}
        {isFirstTwoPhases && (
            <PlanimetryBody planimetrySrc={electricPlanimetrySrc ? electricPlanimetrySrc : answer.planimetrySrc} showElectricLegend={electricPlanimetrySrc} />
        )}
         {justTechInfo && !needPlanimetry && productsInfos[0].titles.length > 0 && (
            <TechInfoBody productsInfos={productsInfos} indexInfos={indexInfos} indexProduct={indexProduct} />
         )}
         {needPlanimetry && justTechInfo && (
            <PlanimetryBody planimetrySrc={answer.planimetrySrc} />
         )}
      </View>
      <View style={styles.footer}>
        <View style={styles.flexColumn}>
          <Text style={styles.bold}>{apartmentType}</Text>
        </View>
        <View style={styles.flexColumn}>
          <View style={styles.flexRow}>
            <Text>Progetto: </Text>
            <Text style={styles.bold}>{building}</Text>
          </View>
          <View style={styles.flexRow}>
            <Text>Piano: </Text>
            <Text style={styles.bold}>{floor}</Text>
            <Text style={styles.borderLeftDivider}>Quadrante: </Text>
            <Text style={styles.bold}>{quadrant}</Text>
          </View>
        </View>
      </View>
    </Page>
  )
};

export default SingleStepPage;
