import * as React from "react";

function SvgTime(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 46 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M29.652 23c-6.243 0-11.148 4.865-11.148 11.057s4.905 11.058 11.148 11.058c6.242 0 11.147-4.866 11.147-11.058S35.894 23 29.652 23zM15.16 40.913C12.708 34.278 13.377 28.086 18.504 23c5.128-5.087 11.37-5.75 18.059-3.318v-.663-2.433c0-.442-.223-.663-.669-.663H5.128c-.446 0-.67.221-.67.663V40.25c0 .443.224.664.67.664H15.16zM0 24.99V9.288c0-2.211.892-3.76 2.898-4.423.67-.22 1.56-.22 2.453-.22 1.337 0 1.337 0 1.337-1.549V.663C6.911.221 6.911 0 7.358 0h3.566c.446 0 .446.221.446.442v3.539c0 .442.223.442.446.442h17.167c.446 0 .446-.221.446-.442V.663c0-.442.223-.663.669-.663h3.344c.446 0 .669.221.669.663v3.318c0 .442.223.663.668.663.892 0 2.007 0 2.899.221 1.56.443 2.675 1.548 3.12 3.096.224.664.224 1.327.224 1.99v12.606c0 .443.223.664.446 1.106 1.783 1.99 2.898 3.98 3.567 6.413.669 2.212.669 4.202.446 6.414-.67 3.98-2.453 7.298-5.574 9.73a19.962 19.962 0 01-6.465 3.318c-2.23.442-4.46.663-6.689.22-3.12-.663-5.573-1.99-7.803-3.98-.223-.221-.668-.221-.892-.221H4.905c-2.007 0-3.567-.885-4.46-2.875C.224 42.02 0 41.355 0 40.692V24.99z"
        fill={props.color}
      />
      <path
        d="M27.426 31.396v-3.76c0-.442 0-.663.669-.663h2.23c.445 0 .668.22.445.663v5.308c0 .442.223.663.669.884l4.682 2.654c.223.221.445.442.223.664-.446.663-.892 1.326-1.115 1.99-.223.221-.446.442-.669.221-2.23-1.327-4.236-2.433-6.465-3.76-.223-.22-.446-.22-.446-.663-.223-.884-.223-2.211-.223-3.538z"
        fill={props.color}
      />
    </svg>
  );
}

export default SvgTime;
