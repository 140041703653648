import { useState, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Document, Page, pdfjs } from 'react-pdf';
import PdfViewer from './PdfViewer';
import LoadingAnimation from './LoadingAnimation';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  documentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    width: '100%',
  },
  document: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    maxWidth: '80vw',
    maxHeight: '80vh',
    color: theme.palette.color.white,
  },
  page: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& canvas': {
      boxShadow: '0 0 0.5rem 0 rgba(0, 0, 0, 0.3)',
    },
  },
}));

const PdfReader = ({ urlPdf }) => {
  const classes = useStyles();

  const [showPdf, setShowPdf] = useState(false);
  const [pdfToShow, setPdfToShow] = useState();
  const [pageHeight, setPageHeight] = useState(0);

  const handleCloseViewer = () => setShowPdf(false);

  useEffect(() => {
    setPageHeight(window.document.querySelector('#pdf-viewer > div').clientHeight);
    setPdfToShow(urlPdf);
  }, []);

  return (
    <>
      <Grid
        container
        direction='column'
        alignItems='center'
        justifyContent='center'
        className={classes.root}
      >
        <Grid item xs className={classes.documentContainer} id='pdf-viewer'>
          <Document file={pdfToShow} loading={<LoadingAnimation />} className={classes.document}>
            <Page
              pageNumber={1}
              height={pageHeight}
              loading={<LoadingAnimation />}
              className={classes.page}
              onClick={() => setShowPdf(true)}
            />
          </Document>
        </Grid>
      </Grid>
      {showPdf && <PdfViewer document={pdfToShow} handleClose={handleCloseViewer} />}
    </>
  );
};

export default PdfReader;
