import * as React from 'react';

function SvgIlluminazioneStudio(props) {
  return (
    <svg viewBox='0 0 59 53' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#illuminazione-studio_svg__clip0_2758_24055)' fill='#C4C4C4'>
        <path d='M2.02 18.75c0-.78.63-1.41 1.4-1.41h20.24v-1.82H3.4c-1.86 0-3.34 1.54-3.4 3.39v25.4c0 1.86 1.54 3.33 3.4 3.39h17.17v2.5h-4.62v1.87h11.51V50.2h-4.73v-2.5h17.21c1.86 0 3.34-1.53 3.4-3.39v-3.44H2.02V18.75zm39.3 24.13v1.63c0 .78-.63 1.41-1.4 1.41H3.42c-.77 0-1.4-.63-1.4-1.41v-1.63h39.3z' />
        <path d='M44.369 33.93h-8.06c-.69 0-1.25.57-1.25 1.26 0 .69.56 1.26 1.25 1.26h8.06c.69 0 1.25-.56 1.25-1.26s-.56-1.26-1.25-1.26zM47.852 10.97c-.34-.33-.71-.64-1.09-.91-1.83-1.35-4.06-2.07-6.39-2.07-2.83 0-5.49 1.05-7.49 2.98-2.01 1.93-3.12 4.5-3.12 7.24 0 2.54.97 4.97 2.72 6.84 1.78 2.11 2.61 6.05 2.61 6.08l.17.82h10.3l.02-.99c.07-2.88 2.81-6.06 2.93-6.19 1.59-1.84 2.47-4.17 2.47-6.56 0-1.62-.39-3.18-1.12-4.58-.51-.98-1.18-1.87-2.01-2.66zm-.88 12.46c-.29.33-2.8 3.29-3.33 6.48h-6.74c-.35-1.34-1.26-4.28-2.91-6.23a7.95 7.95 0 01-2.2-5.47c0-4.51 3.85-8.19 8.58-8.19s8.57 3.68 8.57 8.19c0 1.9-.7 3.76-1.97 5.22zM41.5 0h-2.27v4.64h2.27V0zM52.67 3.93l-2.979 3.556 1.74 1.458 2.98-3.557-1.74-1.458zM58.742 16.24h-4.64v2.27h4.64v-2.27z' />
        <path d='M40.459 23.52l-2.92-2.91-1.41 1.41 4.33 4.33 4.09-4.1-1.41-1.41-2.68 2.68z' />
      </g>
      <defs>
        <clipPath id='illuminazione-studio_svg__clip0_2758_24055'>
          <path fill='#fff' d='M0 0h58.74v52.07H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgIlluminazioneStudio;
