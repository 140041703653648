import * as React from 'react';

function SvgUsbAntibagno(props) {
  return (
    <svg viewBox='0 0 42 43' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.24 28.75H26v2H14.382a1.008 1.008 0 01-.284 0H9.14a1 1 0 110-2h4.1v-5.68h-1.869a2.219 2.219 0 01-.971.971v1.179a1 1 0 11-2 0v-1.179a2.22 2.22 0 01-.971-.971H6.35a1 1 0 110-2h1.079a2.22 2.22 0 01.971-.971V18.93a1 1 0 112 0v1.169c.418.212.759.553.971.971h1.869v-6.55a3.38 3.38 0 013.38-3.38h.004A3.39 3.39 0 0120 14.516v1.454a1 1 0 11-2 0v-1.448a1.39 1.39 0 00-1.382-1.382 1.38 1.38 0 00-1.378 1.38v14.23zM1 0a1 1 0 00-1 1v35.95a1 1 0 001 1h6.78l16.404 4.711a1 1 0 00.552-1.922l-16.54-4.75a1.003 1.003 0 00-.276-.039H2V2h5.779l16.404 4.721a1 1 0 10.554-1.922L8.197.039A1 1 0 007.92 0H1zm19.983 19.81a1 1 0 01.863.5c.169.293.33.547.484.79l.022.037c.143.224.29.456.416.682.278.498.502 1.047.502 1.751v.023a2.27 2.27 0 01-2.302 2.217 2.26 2.26 0 01-2.238-2.224v-.016c0-.685.199-1.226.468-1.733.129-.242.28-.486.43-.728l.053-.087c.138-.222.282-.453.435-.716a1 1 0 01.867-.496zm-.003 5l.016-1a.27.27 0 00.274-.255c-.003-.23-.065-.434-.248-.761a5.057 5.057 0 00-.035-.061l-.023.043c-.17.32-.232.534-.234.784a.26.26 0 00.258.25l-.008 1zM39.057 8.098H42v3.164h-1.006v2.45c0 .255-.045.507-.136.742-.091.235-.224.448-.392.627l-4.117 4.458h-.038v5.83c.649.127 1.23.509 1.63 1.072.4.564.59 1.268.53 1.974a2.922 2.922 0 01-.847 1.841 2.539 2.539 0 01-1.78.744c-.66 0-1.295-.265-1.782-.744a2.922 2.922 0 01-.847-1.841c-.059-.706.13-1.41.53-1.974.4-.563.982-.945 1.63-1.071v-2.375l-3.883-4.18a1.932 1.932 0 01-.392-.628 2.046 2.046 0 01-.136-.741v-2.757a1.459 1.459 0 01-.763-.67 1.637 1.637 0 01-.181-1.04 1.57 1.57 0 01.487-.92c.258-.236.586-.365.925-.365.338 0 .666.13.924.364.259.235.431.562.487.92.056.36-.008.728-.18 1.042-.173.313-.444.55-.764.669v2.752c0 .122.022.243.066.356a.942.942 0 00.187.303l3.223 3.471V5.144H34.16l.842-1.574.842-1.57.847 1.57.842 1.574H36.33V18.13l3.495-3.758a.972.972 0 00.233-.66v-2.45h-1V8.098z'
        fill='#C4C4C4'
      />
    </svg>
  );
}

export default SvgUsbAntibagno;
