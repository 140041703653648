import * as React from 'react';

function SvgDoccia(props) {
  return (
    <svg viewBox='0 0 97 119' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M97 119V0H0v119h97zm-2.345-2.241h-27.53V2.379h27.53v114.38zM65.392 2.379v114.38H2.345V2.379h63.047z'
        fill='#5E5E5E'
      />
      <path
        d='M59.476 72.91c.884 0 1.598-.586 1.598-1.31V55.88c0-.724-.714-1.31-1.598-1.31-.883 0-1.597.586-1.597 1.31V71.6c0 .724.714 1.31 1.597 1.31zM72.765 72.91c.884 0 1.598-.586 1.598-1.31V55.88c0-.724-.714-1.31-1.598-1.31-.883 0-1.597.586-1.597 1.31V71.6c0 .724.714 1.31 1.597 1.31zM23.35 39.23c-.577 0-1.053.345-1.053.793v9.55c0 .447.476.792 1.053.792.578 0 1.054-.345 1.054-.793v-9.549c0-.448-.476-.793-1.053-.793zM27.19 44.092c-.578 0-1.053.345-1.053.793v9.549c0 .448.476.793 1.053.793.578 0 1.054-.345 1.054-.793v-9.55c0-.447-.476-.792-1.054-.792zM30.999 38.059c-.578 0-1.054.344-1.054.793V48.4c0 .449.476.793 1.054.793.578 0 1.054-.344 1.054-.793v-9.548c0-.449-.476-.793-1.054-.793zM34.839 44.092c-.578 0-1.054.345-1.054.793v9.549c0 .448.476.793 1.054.793.578 0 1.053-.345 1.053-.793v-9.55c0-.447-.475-.792-1.053-.792zM38.644 38.059c-.578 0-1.054.344-1.054.793V48.4c0 .449.476.793 1.053.793.578 0 1.054-.344 1.054-.793v-9.548c0-.449-.476-.793-1.053-.793zM42.487 43.506c-.578 0-1.053.345-1.053.793v9.549c0 .448.475.793 1.053.793s1.054-.345 1.054-.793v-9.55c0-.447-.476-.792-1.054-.792zM20.39 34.888c0-6.274 5.608-11.376 12.542-11.376 6.933 0 12.541 5.102 12.541 11.376H20.391z'
        fill='#5E5E5E'
      />
      <path
        d='M33.782 24.27h-1.699v-4.758c0-2.137-1.7-3.86-3.806-3.86H1.902v-1.724h26.34c3.025 0 5.506 2.516 5.506 5.584v4.758h.035z'
        fill='#5E5E5E'
      />
    </svg>
  );
}

export default SvgDoccia;
