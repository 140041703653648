import * as React from 'react';

function SvgPreseCucina(props) {
  return (
    <svg viewBox='0 0 70 55' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#prese-cucina_svg__clip0_2758_23657)' fill='#C4C4C4'>
        <path d='M28.71 4.54a9.935 9.935 0 012.49 4.02c.04.25.18.46.4.59.24.14.53.17.8.08.25-.05.48-.22.62-.47.11-.21.13-.46.05-.65-.31-1.07-.83-2.13-1.57-3.16.73-.23 1.55-.34 2.44-.34 4.17-.08 7.58 3.16 7.65 7.2 0 1.47-.47 2.84-1.29 3.99h2.23c.99-1.91 1.25-4.06.74-6.07 0-.13-.03-.19-.1-.27-1.15-4-4.86-6.77-9.08-6.77-1.27 0-2.55.22-3.9.7-4.39-4.34-11.7-4.55-16.38-.42l-.5.49C8.54 1.51 3.07 3.54.8 8.15c-.99 2.2-1.06 4.64-.19 6.87.88 2.26 2.62 4.04 4.9 5.01l.09.03s.09.04.15.05c.4.17.77.29 1.23.43L9.3 33.57v6.71c0 .57.49.93.95.93h12.38v-1.85H11.26v-4.94h11.37v-9.5c-.17-.3-.5-.47-.81-.47-.56 0-.95.38-.95.93v7.2h-3.88l-.68-8.66c-.04-.27-.2-.51-.41-.66-.2-.14-.42-.19-.6-.15-.46 0-.87.43-.87.92l.67 8.62h-4.02L8.76 19.67a.998.998 0 00-.76-.72 7.332 7.332 0 01-4.8-3.09 7.112 7.112 0 01-1.16-5.39c.93-3.94 4.96-6.45 8.99-5.6 3.47.65 6.04 3.62 6.1 7.07 0 .57.49.93.95.93.55 0 .94-.38.94-.93 0-2.98-1.52-5.73-4.07-7.42 3.92-3.51 9.91-3.53 13.76.02zM47.45 34.12h-2.47v6.18h2.47v-6.18zM40.05 34.12h-2.46v6.16h2.46v-6.16z' />
        <path d='M54.851 27.63c0-.72-.29-1.42-.8-1.93a2.71 2.71 0 00-1.93-.81h-19.19c-.73 0-1.43.29-1.94.81-.51.51-.8 1.2-.8 1.93v19.19c0 .73.29 1.42.8 1.93.52.51 1.21.8 1.94.8h19.19c.72 0 1.42-.29 1.93-.8s.8-1.2.8-1.93V27.63zm-4.13 15.07a9.799 9.799 0 01-4.43 3.64c-1.8.74-3.78.94-5.7.56a9.87 9.87 0 01-7.75-7.75c-.38-1.92-.18-3.9.56-5.7.31-.75.71-1.45 1.19-2.09.67-.92 1.49-1.71 2.45-2.34a9.89 9.89 0 015.48-1.66c1.29 0 2.58.25 3.77.75 1.2.49 2.29 1.22 3.21 2.14.91.91 1.64 2 2.13 3.2.5 1.19.75 2.48.75 3.77 0 1.95-.57 3.86-1.66 5.48zM66.46 35.92h1.11c.14 0 .25-.11.25-.25v-4.74c0-.11 0-.22-.05-.33a.75.75 0 00-.18-.28.75.75 0 00-.47-.22.169.169 0 00-.1-.03c-.11-.01-.22 0-.33.05-.1.04-.2.1-.28.18a.75.75 0 00-.18.28c-.04.1-.05.22-.05.3v4.79c0 .14.11.25.25.25h.03zM60.18 35.89h1.05c.14 0 .25-.11.25-.25V30.9a.75.75 0 00-.22-.6.412.412 0 00-.13-.1c-.04-.04-.1-.06-.15-.08-.1-.04-.21-.05-.32-.04-.09 0-.18.02-.26.05a1 1 0 00-.26.18c-.08.08-.13.17-.17.28-.04.1-.05.2-.04.29v4.76c0 .14.11.25.25.25zM69.878 37.94a.858.858 0 00-.77-.97h-10.58a.907.907 0 00-.6.32.87.87 0 00-.16.3c-.03.12-.04.23-.03.32v6.01c-.01.11 0 .23.03.34.03.11.09.21.16.3a.819.819 0 00.62.31h10.59c.11 0 .22-.05.32-.1a.881.881 0 00.4-.52c.03-.11.04-.22.03-.3v-6.01h-.01zM65.422 46.06h-3.17c-.14 0-.25.11-.25.25v1.06c0 .13.11.25.25.25h.81v3.3a2.52 2.52 0 01-.74 1.67 2.576 2.576 0 01-1.65.74h-5.82c-.14 0-.25.11-.25.25v1.06c0 .14.11.25.25.25h5.82c2.45 0 3.95-2 3.95-3.43v-3.84h.8c.14 0 .25-.12.25-.25v-1.06c0-.14-.11-.25-.25-.25z' />
      </g>
      <defs>
        <clipPath id='prese-cucina_svg__clip0_2758_23657'>
          <path fill='#fff' d='M0 0h69.89v54.89H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgPreseCucina;
