import * as React from 'react';

function SvgStudio(props) {
  return (
    <svg viewBox='0 0 44 37' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#studio_svg__clip0_2448_23030)'>
        <path
          d='M39.94 0H3.4A3.46 3.46 0 000 3.39v25.4a3.45 3.45 0 003.4 3.39h17.17v2.5h-4.62v1.87h11.51v-1.87h-4.73v-2.5h17.21a3.45 3.45 0 003.4-3.39V3.33A3.41 3.41 0 0039.94 0zm1.38 29a1.41 1.41 0 01-1.4 1.41H3.42A1.41 1.41 0 012.02 29v-1.63h39.3V29zm0-3.63H2.02V3.23a1.41 1.41 0 011.4-1.41h36.5a1.41 1.41 0 011.4 1.41v22.14z'
          fill='#C4C4C4'
        />
      </g>
      <defs>
        <clipPath id='studio_svg__clip0_2448_23030'>
          <path fill='#fff' d='M0 0h43.34v36.55H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgStudio;
