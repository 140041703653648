import { Box, Grid, makeStyles, Popover } from '@material-ui/core';
import FurnitureCounterContainer from '../../components/Layout/FurnitureCounterContainer';
import PlanimetryZoom from '../../components/PlanimetryZoom';
import {
  Camera,
  Cart,
  ChiusuraArmadio,
  ChiusuraCucinaNew,
  OutlinedStar,
  Plus,
  Ruler,
} from '../../components/Icons';
import clsx from 'clsx';
import IconButton from '../../components/Buttons/IconButton';
import SvgAlert from '../../components/Icons/Alert';
import AlertBox from '../../components/AlertBox';
import ImageZoom from '../../components/ImageZoom';
import { useEffect, useRef, useState } from 'react';
import { prepareRoomsInfo } from '../../utils/layoutUtils';
import InfoPopup, { InfoPopupRichText, PhaseInfoContent } from '../InfoPopup';
import ImageZoomButton from '../Buttons/ImageZoomButton';
import { getLayoutImagesById } from '../../services/layoutApi';
import OutlineIconButton from '../Buttons/OutlineIconButton';
import RichTextPopover from '../popovers/RichTextPopover';

const useStyles = makeStyles((theme) => ({
  layoutCardRoot: {
    position: 'relative',
    padding: '8px',
    height: '100%',
    overflow: 'hidden auto',
    '& .chiusure-arredo': {
      padding: '15px 0 0',
      fontSize: '11px',
      lineHeight: '13px',
      '& > div:first-child': {
        paddingRight: '10px',
        paddingLeft: '0 !important',
      },
      '& > div:last-child': {
        paddingLeft: '10px',
      },

      '& .chiusura-arredo-icon': {
        height: '47px',
        width: '47px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '15px',
        position: 'relative',
        '& > svg': {
          height: '100%',
          width: 'auto',
        },
      },

      '& .chiusure-arredo-element': {
        backgroundColor: '#F8F7F7',
        padding: '8px 16px',
        '& > div:first-child': {
          marginBottom: '8px',
        },
      },
      '& .chiusura-arredo-model': {
        '& > div:first-child': {
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: 11,
          lineHeight: '13px',
          letterSpacing: '0.04em',
          color: '#F08219',
        },
        '& > div:last-child': {
          color: 'rgba(0, 0, 0, 0.5)',
        },
      },
      '& .chiusura-arredo-button': {
        '& span': {
          fontSize: '11px',
        },
        '&.no-border-top': {
          borderTop: 0,
        },
        '&.no-border-bottom': {
          borderBottom: 0,
        },
      },
    },
  },
  recommended: {
    backgroundColor: '#F0D80A',
    fontFamily: 'miller',
    fontStyle: 'italic',
    fontWeight: 'normal',
    fontSize: 13,
    lineHeight: '16px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px',
    whiteSpace: 'nowrap',
    '& svg': {
      marginRight: '8px',
    },
    '& path:nth-child(2)': {
      fill: '#F0D80A',
    },
    [theme.breakpoints.up('xs')]: {
      fontSize: 11,
      padding: '0 3px',
      '& svg': {
        marginRight: '3px',
      },
    },
  },
  contentRow: {
    backgroundColor: '#EEEEEE',
  },
  planimetryContainer: {
    position: 'relative',
    borderRight: '0',
    minHeight: 'calc(50% - 40px)',
  },
  firstRow: {
    // height: '56px',
    // borderBottom: '0.5px solid #CDC7C1',
  },
  layoutName: {
    fontFamily: 'miller',
    fontStyle: 'italic',
    fontSize: 18,
    color: '#F08219',
    padding: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: '0.5px solid #CDC7C1',
  },
  apartmentInfo: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 16px 0',
    textTransform: 'uppercase',
    fontSize: '21px',
    // '& .type': {
    //   textTransform: 'uppercase',
    //   fontSize: '13px',
    // },
    // '& .type2': {
    //   fontSize: '15px',
    //   '& span': {
    //     fontWeight: 600,
    //   },
    // },
  },
  layoutInfo: {
    padding: '8px 0 0',
  },
  extraInfo: {
    fontSize: 10,
    lineHeight: '12px',
    paddingRight: '40px',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '15px',
    },
  },
  price: {
    borderTop: '0.5px solid #C4C4C4',
    height: '30px',
    color: '#A15117',
    fontWeight: 800,
    padding: '2px 10px 0',
    marginTop: '8px',
    '& .box': {
      cursor: 'pointer',
    },
  },
  infoBar: {
    position: 'absolute',
    bottom: '-11px',
    left: '50%',
    transform: 'translateX(-50%)',
    whiteSpace: 'nowrap',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  layoutInfoHeader: {
    padding: '8px 0 8px',
    borderBottom: '1px solid #C4C4C4',
    '& .info-button': {
      cursor: 'pointer',
      textDecoration: 'underline',
      fontSize: '12px',
      minWidth: '20%',
      textAlign: 'right',
      transition: 'color 100ms',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  },
  popover: {
    backgroundColor: 'rgba(0,0,0,0) !important',
    borderTopLeftRadius: '90px',
  },
}));

const LayoutCard = ({
  id,
  chiusure,
  Tags,
  Note,
  onClick,
  isSelected,
  rooms,
  price,
  tags,
  longDescription,
  priceDescription,
}) => {
  const classes = useStyles();

  const [roomsInfo, setRoomsInfo] = useState([]);
  const [showInfo, setShowInfo] = useState(false);
  const [images, setImages] = useState([]);
  const [chiusuraCucina, setChiusuraCucina] = useState();
  const [chiusuraCabinaArmadio, setChiusuraCabinaArmadio] = useState();
  const [showQuotedPlanimetry, setShowQuotedPlanimetry] = useState(false);
  const [imagesWithQuotes, setImagesWithQuotes] = useState([]);
  const [showRichText, setShowRichText] = useState(false);

  const infoRef = useRef();
  const infoRichText = useRef();

  useEffect(() => {
    const roomsInfo = prepareRoomsInfo(rooms);
    setRoomsInfo(roomsInfo);
  }, [rooms]);

  const prepareLayoutImages = async (layerChiusure = [], quoteLayer = []) => {
    try {
      const imagesResponse = await getLayoutImagesById(id);
      const images = imagesResponse.data?.map((el) => el.fileUrl);

      setImages([...images, ...layerChiusure]);

      setImagesWithQuotes([...images, ...layerChiusure, ...quoteLayer]);
    } catch (error) {
      console.log(error);
    }
  };

  const infoData = {
    phase: 'VALORE AGGIUNTO',
    firstRow: 'Puoi monitorare le tue aggiunte nella sezione Riepilogo',
    secondRow: priceDescription ? (
      <span dangerouslySetInnerHTML={{ __html: priceDescription }} />
    ) : (
      "Selezionandolo aumenterà il prestigio del tuo preventivo. Avrai sempre la possibilità di monitorare le tue aggiunte con valore superiore nella sezione 'Recap scelte'"
    ),
    thirdRow: 'I prezzi sono da intendersi iva esclusa.',
  };

  const checkRoomType = (roomId, roomValue) => {
    const roomData = rooms.find((room) => room.id === roomId);
    if (roomData?.roomType?.name?.toLowerCase().includes(roomValue)) {
      return true;
    }

    return false;
  };

  const showChiusuraCucina = chiusure.some((el) => {
    return checkRoomType(el.roomId, 'cucina');
  });
  const showChiusuraCabinaArmadio = chiusure.some((el) => {
    return checkRoomType(el.roomId, 'cabina armadio');
  });

  useEffect(() => {
    const chiusuraCucina = chiusure.find((el) => {
      return checkRoomType(el.roomId, 'cucina');
    });
    const chiusuraCabinaArmadio = chiusure.find((el) => {
      return checkRoomType(el.roomId, 'cabina armadio');
    });

    let chiusureLayer = [];
    if (chiusuraCucina) {
      const chiusuraCucinaTags = tags?.find((el) => el.key === chiusuraCucina.key);
      chiusuraCucinaTags?.fileLabels.forEach((el) => chiusureLayer.push(el.url));
    }
    if(chiusuraCabinaArmadio){
      const chiusuraCabinaArmadioTags = tags?.find((el) => el.key === chiusuraCabinaArmadio.key);
      chiusuraCabinaArmadioTags?.fileLabels.forEach((el) => chiusureLayer.push(el.url));
    }

    const quoteLayer = tags?.find((el) => el.key === 'QUOTE')?.fileLabels.map((el) => el.url);
    prepareLayoutImages(chiusureLayer, quoteLayer);

    setChiusuraCucina(chiusuraCucina);
    setChiusuraCabinaArmadio(chiusuraCabinaArmadio);
  }, []);

  const handleShowQuotedPlanimetry = () => {
    setShowQuotedPlanimetry(true);
  };

  return (
    <>
      <Grid container className={classes.layoutCardRoot} direction='column' wrap='nowrap'>
        {Tags?.includes('Recommended') && (
          <>
            <Grid item className={classes.recommended} xs={4}>
              <OutlinedStar color='#000' width='15px' height='15px' />
              Consigliato dall'architetto
            </Grid>
            <Grid item xs={8}></Grid>
          </>
        )}
        <Grid item className={clsx(classes.planimetryContainer, classes.contentRow)}>
          <PlanimetryZoom images={images} />
          <OutlineIconButton
            className={'planimetry-button'}
            onClick={handleShowQuotedPlanimetry}
            text={'Visualizza planimetria quotata'}
            icon={<Camera color='#878787' width='15px' height='15px' />}
          />
        </Grid>
        <Grid item className={clsx(classes.contentRow)} xs>
          {longDescription && (
            <Grid container className={classes.layoutInfoHeader} justifyContent='flex-end'>
              <Grid
                ref={infoRichText}
                item
                xs='auto'
                className={'info-button'}
                onClick={() => setShowRichText(true)}
              >
                Scopri le qualità di questo layout
              </Grid>
              <RichTextPopover
                show={showRichText}
                onClose={() => setShowRichText(false)}
                anchorEl={infoRichText.current}
                infoRichText={longDescription}
              />
            </Grid>
          )}

          <div className={classes.layoutInfo}>
            <FurnitureCounterContainer rooms={roomsInfo} bigScreenItemFill />
          </div>
          {((chiusuraCucina || showChiusuraCucina) || (chiusuraCabinaArmadio || showChiusuraCabinaArmadio)) && (
            <Grid container className={'chiusure-arredo'} alignItems='center'>
              {chiusuraCucina && (
                <Grid item xs={12} sm={6}>
                  <div className={'chiusure-arredo-element'}>
                    <Grid container wrap='nowrap' alignItems='center'>
                      <Grid item xs='auto'>
                        <div className='chiusura-arredo-icon'>
                          <ChiusuraCucinaNew color='currentColor' width='24px' height='24px' />
                        </div>
                      </Grid>
                      <Grid>Il layout proposto include un sistema di chiusura vetrato.</Grid>
                    </Grid>
                    <Grid container wrap='nowrap'>
                      <Grid item xs={4} className='chiusura-arredo-model'>
                        <div>PORTA</div>
                        <div>D’ARREDO</div>
                      </Grid>
                      <Grid item xs={8}>
                        <ImageZoomButton
                          imageSrc={chiusuraCucina?.imageFileUrl}
                          label={'Foto'}
                          icon={<Camera color='#878787' width='15px' height='15px' />}
                          className='chiusura-arredo-button no-border-top'
                        />
                        <ImageZoomButton
                          imageSrc={[chiusuraCucina?.dataSheetFileUrl]}
                          label={'Scheda Tecnica'}
                          isPdf
                          icon={<Ruler color='#878787' width='15px' height='15px' />}
                          className='chiusura-arredo-button no-border-bottom'
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              )}

              {showChiusuraCabinaArmadio && (
                <Grid item xs='12' sm={6}>
                  <div className={'chiusure-arredo-element'}>
                    <Grid container wrap='nowrap' alignItems='center'>
                      <Grid item xs='auto'>
                        <div className='chiusura-arredo-icon'>
                          <ChiusuraArmadio color='currentColor' width='24px' height='24px' />
                        </div>
                      </Grid>
                      <Grid xs>Il layout proposto include un sistema di chiusura vetrato.</Grid>
                    </Grid>
                    <Grid container wrap='nowrap'>
                      <Grid item xs='4' className='chiusura-arredo-model'>
                        <div>PORTA </div>
                        <div>D’ARREDO</div>
                      </Grid>
                      <Grid xs='8'>
                        <ImageZoomButton
                          imageSrc={[chiusuraCabinaArmadio?.imageFileUrl]}
                          label={'Foto'}
                          icon={<Camera color='#878787' width='15px' height='15px' />}
                          className='chiusura-arredo-button no-border-top'
                        />
                        <ImageZoomButton
                          imageSrc={[chiusuraCabinaArmadio?.dataSheetFileUrl]}
                          label={'Scheda Tecnica'}
                          isPdf
                          icon={<Ruler color='#878787' width='15px' height='15px' />}
                          className='chiusura-arredo-button no-border-bottom'
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>

        <Grid item>
          <Grid
            container
            className={classes.layoutInfo}
            alignItems='center'
            justifyContent='flex-end'
          >
            {/* <Grid item md={12} lg={6} className={classes.extraInfo}>
            <Grid container wrap='nowrap' spacing={1}>
              <Grid item xs='auto'>
                <SvgPhoto />
              </Grid>
              <Grid item>Guarda i sistemi di chiusura proposti e il loro posizionamento.</Grid>
            </Grid>
          </Grid> */}
            <Grid item md='auto' lg={6} xl='auto' className={classes.buttonContainer}>
              <IconButton
                text={isSelected ? 'SELEZIONATO' : 'SELEZIONA'}
                icon={<Plus color='currentColor' width='10px' height='10px' />}
                onClick={() => {
                  onClick(id);
                }}
                isSelected={isSelected}
              />
            </Grid>
          </Grid>
          {price ? (
            <Grid container className={classes.price} alignItems='center' justifyContent='flex-end'>
              <Box
                marginRight='7px'
                display='inline-flex'
                marginTop='-2px'
                className='box'
                onClick={() => {
                  setShowInfo(true);
                }}
                ref={infoRef}
              >
                <SvgAlert color='#F08219' />
              </Box>{' '}
              + € {price}
            </Grid>
          ) : null}
        </Grid>

        {Note && <AlertBox className={classes.infoBar} text={Note} infoData={infoData} />}
        <Popover
          open={showInfo}
          anchorEl={infoRef.current}
          onClose={() => setShowInfo(false)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          PaperProps={{ classes: { root: classes.popover } }}
        >
          <InfoPopup
            body={<PhaseInfoContent {...infoData} />}
            isPopover
            handleClose={() => setShowInfo(false)}
          />
        </Popover>
      </Grid>
      {showQuotedPlanimetry && (
        <ImageZoom
          images={imagesWithQuotes}
          isPlanimetry
          handleClose={() => setShowQuotedPlanimetry(false)}
          allowDownload
        />
      )}
    </>
  );
};

export default LayoutCard;
