import * as React from "react";

function SvgCamera(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 53 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.46 0l-4.763 5.013H5.36C2.382 5.013 0 7.372 0 10.32v30.667C0 43.936 2.382 46 5.36 46h42.28c2.978 0 5.36-2.359 5.36-5.013V10.32c0-2.948-2.382-5.012-5.36-5.012h-8.337L34.242 0H18.46zm8.04 38.333c-7.146 0-12.803-5.897-12.803-12.974s5.955-12.68 13.1-12.68c7.147 0 12.804 5.898 12.804 12.68 0 7.372-5.955 13.27-13.101 12.974z"
        fill={props.color}
      />
    </svg>
  );
}

export default SvgCamera;
