import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  newConfButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  newConfButton: {
    maxWidth: 'unset !important',
  },
  meters: {
    border: '2px solid #F08219',
    paddingTop: '3px',
    width: 111,
    height: 48,
    fontSize: 18,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& span': {
      fontWeight: 'bold',
      fontSize: 18,
      marginRight: '5px',
    },
  },
  label: {
    paddingTop: 6,
    fontWeight: 750,
    fontSize: 12,
    textTransform: 'uppercase',
    '&.smallText': {
      fontSize: 9,
    },
  },
}));

const SurfaceMeters = ({ meters = 0, label = 'label', smallText }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.meters}>
        <span>{meters}</span> m<sup>2</sup>
      </div>
      <div className={clsx(classes.label, smallText && 'smallText')}>{label}</div>
    </>
  );
};

export default SurfaceMeters;
