
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { getHcUserData, setHcUserData } from '../../utils/localStorageUtils';
import { getBoxJwt } from '../boxSign/requestJWT';

const BoxAPI = axios.create({
  baseURL: process.env.REACT_APP_CONFIG_BOX_INTEGRATION_BASEURL,
});

BoxAPI.interceptors.request.use(
  (request) => {
    const jwt = getHcUserData()?.boxJwtToken;
    request.headers.authorization = `Bearer ${jwt}`;
    return request;
  },
  (error) => {
    console.log('error refreshing token...');
    return Promise.reject(error);
  },
);

const refreshAuthLogic = async (failedRequest) => {
  return getBoxJwt().then(async (res) => {
    console.log('refreshAuthLogic', res.status);
    const HcUserData = getHcUserData();
    if (res.status === 200) {
      if (res?.data?.accessToken) {
        HcUserData.boxJwtToken = res?.data?.accessToken;
      } else {
        HcUserData.boxJwtToken = '';
      }
      setHcUserData(HcUserData);
      failedRequest.response.config.headers.authorization = 'Bearer ' + res?.data?.accessToken;
      return Promise.resolve();
    }
  })
  .catch((e) => {
    console.log('error refreshAuthLogic');
    document.location.href = '/';
    return Promise.reject(e);
  });
};

createAuthRefreshInterceptor(BoxAPI, refreshAuthLogic);

export default BoxAPI;
