import * as React from 'react';

function SvgAntibagno(props) {
  return (
    <svg viewBox='0 0 38 43' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1 0a1 1 0 00-1 1v35.95a1 1 0 001 1h6.78l16.404 4.711a1 1 0 00.552-1.922l-16.54-4.75a1.003 1.003 0 00-.276-.039H2V2h5.779l16.404 4.721a1 1 0 10.554-1.922L8.197.039A1 1 0 007.92 0H1zm29.44 0a1 1 0 100 2h5.06v33.68h-5.06a1 1 0 100 2h6.06a1 1 0 001-1V1a1 1 0 00-1-1h-6.06zm-9.457 19.81a1 1 0 01.863.5c.169.293.33.547.484.79l.022.037c.143.224.29.456.416.682.278.498.502 1.047.502 1.751v.023a2.27 2.27 0 01-2.302 2.217 2.26 2.26 0 01-2.238-2.224v-.016c0-.685.199-1.226.468-1.733.129-.242.28-.486.43-.728l.053-.087c.138-.222.282-.453.435-.716a1 1 0 01.867-.496zm-.003 5l.016-1a.27.27 0 00.274-.255c-.003-.23-.065-.434-.248-.761a5.057 5.057 0 00-.035-.061l-.023.043c-.17.32-.232.534-.234.784a.26.26 0 00.258.25l-.008 1zM9.14 28.75a1 1 0 100 2h4.958a1.008 1.008 0 00.284 0H29.74a1 1 0 100-2h-14.5V14.52a1.38 1.38 0 011.378-1.38A1.39 1.39 0 0118 14.522v1.448a1 1 0 102 0v-1.454a3.39 3.39 0 00-3.376-3.376h-.004a3.38 3.38 0 00-3.38 3.38v6.55h-1.869a2.219 2.219 0 00-.971-.971V18.93a1 1 0 10-2 0v1.169a2.22 2.22 0 00-.971.971H6.35a1 1 0 100 2h1.079c.212.418.553.759.971.971v1.179a1 1 0 102 0v-1.179c.418-.212.759-.553.971-.971h1.869v5.68h-4.1z'
        fill='#C4C4C4'
      />
    </svg>
  );
}

export default SvgAntibagno;
