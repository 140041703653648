import { Page, StyleSheet, Text, View, Image } from '@react-pdf/renderer';
import CurrencyFormatter from '../../../utils/CurrencyFormatter';

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    fontFamily: 'Helvetica',
    padding: '1.27cm',
    fontSize: '12px',
  },
	logoContainer: {
    height: '5%',
    width: '100%',
  },
  logo: {
    height: '100%',
    width: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
	titleContainer: {
    height: '5%', 
    width: '100%',
  },
  title: {
    fontSize: '18px',
  },
	bodyContainer: {
    height: '85%', 
    width: '100%', 
  },
  footer: {
    height: '5%', 
    width: '100%', 
    display: 'flex', 
    flexDirection:'row', 
    justifyContent: 'space-between', 
    alignItems: 'center'
  },
	flexRow: {
    display: 'flex', 
    flexDirection:'row'
  },
  flexColumn: {
    display: 'flex', 
    flexDirection:'column'
  },
  borderLeftDivider: {
    borderLeft: '1px solid #000', 
    paddingLeft: '8px', 
    marginLeft: '8px'
  },

  phaseTitle: {
    fontSize: '18pt',
    marginTop: '27pt',
    marginBottom: '9pt',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginBottom: '5.5pt',
    borderBottom: '1px solid black',
  },
  bigTotalRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: '11pt',
  },
  step: { width: '30%' },
  totalStep: { width: '30%', fontFamily: 'Helvetica-Bold' },
  bigTotalStep: { width: '80%', fontFamily: 'Helvetica-Bold', fontSize: '16pt' },
  option: { width: '60%' },
  price: { width: '10%', textAlign: 'right' },
  totalPrice: { width: '10%', textAlign: 'right', fontFamily: 'Helvetica-Bold' },
  bigTotalPrice: {
    width: '20%',
    textAlign: 'right',
    fontFamily: 'Helvetica-Bold',
    fontSize: '16pt',
  },
  platesContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '4pt',
  },
  plate: {
    backgroundColor: '#991914',
    color: '#FFF',
    borderRadius: 20,
    padding: '3px 5px',
    fontWeight: 'bold',
    fontSize: '9pt',
  },
  disclaimer: {
    marginTop: '80px',
  },
  bold: {
    fontWeight: 700
  },
});

const SimplePriceDescriptionRow = ({ title, description, price, isTotal, isBigTotal }) => {
  return (
    <View style={isBigTotal ? styles.bigTotalRow : styles.row}>
      <View style={isTotal ? styles.totalStep : isBigTotal ? styles.bigTotalStep : styles.step}>
        <Text>{title}</Text>
      </View>
      {!isBigTotal && (
        <View style={styles.option}>
          <Text>{description}</Text>
        </View>
      )}
      <View style={isTotal ? styles.totalPrice : isBigTotal ? styles.bigTotalPrice : styles.price}>
        <Text>{price}</Text>
      </View>
    </View>
  );
};

const PlatePriceDescriptionRow = ({ title, description, price, plates }) => {
  return (
    <View style={styles.row}>
      <View style={styles.step}>
        <Text>{title}</Text>
      </View>
      <View style={styles.option}>
        {description && <Text>{description}</Text>}
        <View style={styles.platesContainer}>
          {plates?.map((el) => (
            <Text style={styles.plate} key={el}>
              P-{el}
            </Text>
          ))}
        </View>
      </View>
      <View style={styles.price}>
        <Text>{price}</Text>
      </View>
    </View>
  );
};

const Row = ({ answer, title }) => {
  const plates = answer?.numbers?.map((el) => el.number).sort((a, b) => a - b);
  const price = CurrencyFormatter(answer.price);

  switch (answer.key) {
    case 'Porte_TipologiaPorte':
      return (
        <PlatePriceDescriptionRow
          title={title}
          description={'Scorrevoli'}
          price={price}
          plates={plates}
        />
      );
    case 'Porte_StilePorte':
      return (
        <PlatePriceDescriptionRow
          title={title}
          description={'Rasomuro'}
          price={price}
          plates={plates}
        />
      );
    case 'Porte_AltezzaPorte':
      return (
        <PlatePriceDescriptionRow
          title={title}
          description={'240cm'}
          price={price}
          plates={plates}
        />
      );
    case 'Porte_PorteGrezze':
      return (
        <PlatePriceDescriptionRow
          title={title}
          description={'Grezze'}
          price={price}
          plates={plates}
        />
      );
    case 'Finestre_OpacitaFinestre':
      return <PlatePriceDescriptionRow title={title} price={price} plates={plates} />;
    case 'Finestre_DimensioneFinestre':
      return <PlatePriceDescriptionRow title={title} price={price} plates={plates} />;
    case 'Finestre_ZanzariereFinestre':
      return <SimplePriceDescriptionRow title={title} description={answer.description} price={price} />
    case 'Cassaforte_Cassaforte':
      return <PlatePriceDescriptionRow title={title} price={price} plates={plates} />;
    case 'Loggia_LavaboEsterno':
      return <PlatePriceDescriptionRow title={title} price={price} plates={plates} />;
    case 'Luci_PuntiLuceExtra':
      return <PlatePriceDescriptionRow title={title} price={price} plates={answer.positions} />;
    case 'Luci_LuciComodinoAltreCamere':
      return <PlatePriceDescriptionRow title={title} price={price} plates={plates} />;
    case 'Luci_GoleLuminose':
      return <PlatePriceDescriptionRow title={title} price={price} plates={plates} />;
    case 'Luci_IlluminazioneArmadi':
      return <PlatePriceDescriptionRow title={title} price={price} plates={plates} />;
    case 'Luci_PreseComandate':
      return <PlatePriceDescriptionRow title={title} price={price} plates={plates} />;
    case 'Prese_PresePianoCottura':
      return <PlatePriceDescriptionRow title={title} price={price} plates={plates} />;
    case 'Prese_PreseLavanderia':
      return <PlatePriceDescriptionRow title={title} price={price} plates={plates} />;
    case 'Prese_PreseLoggia':
      return <PlatePriceDescriptionRow title={title} price={price} plates={plates} />;
    case 'Prese_PreseUsb':
      return <PlatePriceDescriptionRow title={title} price={price} plates={plates} />;
    case 'Prese_PreseTv':
      return <PlatePriceDescriptionRow title={title} price={price} plates={plates} />;
    case 'Temperatura_Temperatura':
      return <PlatePriceDescriptionRow title={title} price={price} plates={plates} />;

    default:
      return (
        <SimplePriceDescriptionRow title={title} description={answer.description} price={price} />
      );
  }
};

const PriceDescriptionsPage = ({ answers, bagniRooms, apartmentType, building, floor, quadrant }) => {
	let bagniAnswersGrouped = {};
	bagniRooms?.forEach((room, index) => {
		bagniAnswersGrouped['bagno' + (index + 1)] = answers?.filter(
			(answer) => answer.phase?.key?.toLowerCase() === 'bagno' && answer.roomId === room.id
		)
	});

	const answersByPhase = {
		'Layout': answers?.filter(
			(answer) => answer.phase?.key?.toLowerCase() === 'layout'
		),
		'Tecnologia': answers?.filter(
			(answer) => answer.phase?.key?.toLowerCase() === 'tecnologia'
		),
		'Giorno e Notte': answers?.filter(
			(answer) => answer.phase?.key?.toLowerCase() === 'giornoenotte'
		),
		'Bagno': bagniAnswersGrouped
	};

  let totalPhasePrice;
  let totalPrice = 0;

	return (
		<Page size='A4' style={styles.page} wrap>
			{Object.keys(answersByPhase)?.map(function(key, index) {
				totalPhasePrice = 0;
				if(key !== 'Bagno') {
					return (
						<View key={index} style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>
							<View style={styles.logoContainer}>
        				<Image style={styles.logo} src={`${process.env.PUBLIC_URL}/assets/images/logo.png`} />
      				</View>
							<View style={styles.titleContainer}>
                <Text style={styles.title}>{key}</Text>
              </View>
							<View style={styles.bodyContainer}>
								{
									answersByPhase[key]?.map(function(el, i) {
										totalPhasePrice += el.price;
										totalPrice += el.price;
										const title = el.step.name === el.substep.name ?
											el.substep.name
											: `${el.step.name}: ${el.substep.name}`;
										return (<Row answer={el} title={title} />)
									})
								}
								<SimplePriceDescriptionRow
									title={'TOTALE'}
									price={CurrencyFormatter(totalPhasePrice)}
									isTotal
								/>
								<View style={styles.disclaimer}>
									<Text>I prezzi sono da intendersi IVA esclusa.</Text>
								</View>
							</View>
							<View style={styles.footer}>
								<View style={styles.flexColumn}>
									<Text style={styles.bold}>{apartmentType}</Text>
								</View>
								<View style={styles.flexColumn}>
									<View style={styles.flexRow}>
										<Text>Progetto: </Text>
										<Text style={styles.bold}>{building}</Text>
									</View>
									<View style={styles.flexRow}>
										<Text>Piano: </Text>
										<Text style={styles.bold}>{floor}</Text>
										<Text style={styles.borderLeftDivider}>Quadrante: </Text>
										<Text style={styles.bold}>{quadrant}</Text>
									</View>
								</View>
							</View>
						</View>
					)
				} else {
					return(
					Object.keys(answersByPhase[key])?.map(function(key2, index2) {
						totalPhasePrice = 0;
						return(
							<View key={index} style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>
								<View style={styles.logoContainer}>
        					<Image style={styles.logo} src={`${process.env.PUBLIC_URL}/assets/images/logo.png`} />
      					</View>
								<View style={styles.titleContainer}>
                	<Text style={styles.title}>{key + (index2 + 1)}</Text>
              	</View>
								<View style={styles.bodyContainer}>
									{
										answersByPhase[key][key2]?.map((bagno) => {
											totalPhasePrice += bagno.price;
											totalPrice += bagno.price;
											const title = bagno.step.name === bagno.substep.name ?
												bagno.substep.name
												: `${bagno.step.name}: ${bagno.substep.name}`;
											return (<Row answer={bagno} title={title} />)
										})
									}
									<SimplePriceDescriptionRow
										title={'TOTALE'}
										price={CurrencyFormatter(totalPhasePrice)}
										isTotal
									/>
									{ index2 === Object.keys(answersByPhase[key]).length - 1 && 
										(<SimplePriceDescriptionRow
											title={'TOTALE CONFIGURAZIONE'}
											price={CurrencyFormatter(totalPrice)}
											isBigTotal
										/>)
                  }
                  <View style={styles.disclaimer}>
										<Text>I prezzi sono da intendersi IVA esclusa.</Text>
									</View>
								</View>
								<View style={styles.footer}>
									<View style={styles.flexColumn}>
										<Text style={styles.bold}>{apartmentType}</Text>
									</View>
									<View style={styles.flexColumn}>
										<View style={styles.flexRow}>
											<Text>Progetto: </Text>
											<Text style={styles.bold}>{building}</Text>
										</View>
										<View style={styles.flexRow}>
											<Text>Piano: </Text>
											<Text style={styles.bold}>{floor}</Text>
											<Text style={styles.borderLeftDivider}>Quadrante: </Text>
											<Text style={styles.bold}>{quadrant}</Text>
										</View>
									</View>
								</View>
							</View>
						)
					}))
				}
			})}
		</Page>
	)
}

export default PriceDescriptionsPage;
