import { Box, Grid, makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useWinstonLogger } from 'winston-react/dist/hooks';
import InfoApartment from '../components/InfoApartment';
import FurnitureCounterContainer from '../components/Layout/FurnitureCounterContainer';
import PlanimetryZoom from '../components/PlanimetryZoom';
import SummaryContainer from '../components/SummaryContainer';
import TwoColumnsLayout from '../layouts/TwoColumnsLayout';
import { getAnswer, getAnswerDetail } from '../services/answerApi';
import { confirmConfiguration, getConfigurationDetail } from '../services/configurationApi';
import { getPhases } from '../services/phaseApi';
import { useStore } from '../store/storeUtils';
import {
  checkRoomType,
  getAllImpiantoElettricoLayers,
  prepareRoomsInfo,
} from '../utils/layoutUtils';
import { groupRowsByKey } from '../utils/optionsUtils';
import HelpIcon from '@material-ui/icons/Help';
import LoadingAnimation from '../components/LoadingAnimation';
import { getLayoutImagesByConfigurationId } from '../services/layoutApi';
import { useReactToPrint } from 'react-to-print';
import { getConfiguratorPhases } from './ConfiguratorPage';
import ConfigurationConfirmationDialog from '../components/dialogs/ConfigurationConfirmationDialog';
import { usePDF } from '@react-pdf/renderer';
import { ConfigurationResumePDF, mergePDFs, mergePDFs2 } from '../services/pdf/pdfUtiles';
import { saveAs } from 'file-saver';
import { getBlobByImages } from '../utils/imageUtils';
import AreYouSureConfirmationDialog from '../components/dialogs/AreYouSureConfirmationDialog';
import { getAnswerPackageDetail } from '../services/answerApi';
import { listSignRequest } from '../services/boxSign/listSignRequest';
import { uploadFile, uploadFileVersion } from '../services/boxSign/uploadFile';
import { createFolder } from '../services/boxSign/createFolder';
import { listFolder } from '../services/boxSign/listFolder';
import { createSignRequest } from '../services/boxSign/createSignRequest';
import { folderExist } from '../utils/boxSignUtils';
import _ from 'lodash';
import { getHcUserData } from '../utils/localStorageUtils';

const useStyles = makeStyles((theme) => ({
  newConfButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  secondColumn: {
    height: '100%',
  },
  apartmentInfoColumn: {
    height: '100%',
    paddingRight: '10px',
    '& > div:first-child': {
      height: '100%',
      overflow: 'hidden auto',
      background: '#FFFFFF',
      padding: '20px',
    },
    '& .info-title': {
      fontWeight: 750,
      textTransform: 'uppercase',
      fontSize: 20,
      marginBottom: '15px',
    },
  },
  planimetryContainer: {
    position: 'relative',
    borderBottom: '0.5px solid #999999',
    marginBottom: '20px',
    minHeight: '220px',
  },
  roomInfo: {
    '& > div:after': {
      content: '',
      flex: 'auto',
    },
  },
  apartmentResume: {
    height: '100%',
    background: '#FFFFFF',
    padding: '25px !important',
    overflow: 'hidden auto',
  },
}));

const SecondColumn = ({
  apartment,
  handleResumeConfiguration,
  handleSendConfiguration,
  answers,
  answersLoading,
  layout,
  layoutLoading,
  images,
  isAllPhasesCompleted,
  editable,
  isConfirmed,
  resumePdfLoading,
  downloadConfirmed,
  signing,
  getSigningRequest,
}) => {
  const classes = useStyles();

  const roomInfo = prepareRoomsInfo(layout?.rooms);

  return (
    <Grid container className={classes.secondColumn}>
      <Grid item xs={4} className={classes.apartmentInfoColumn}>
        {layoutLoading ? (
          <LoadingAnimation msg={'Caricamento Planimetria...'} />
        ) : layout ? (
          <Grid container direction='column' wrap='nowrap'>
            <Grid item xs='auto' className='info-title'>
              <div>{apartment}</div>
            </Grid>
            <Grid item xs className={classes.planimetryContainer}>
              <PlanimetryZoom images={images} />
            </Grid>
            <Grid item xs='auto' className={classes.roomInfo}>
              <FurnitureCounterContainer hideIcon rooms={roomInfo} whiteBackgroundMode centerMode />
            </Grid>
          </Grid>
        ) : (
          <Box
            height={'100%'}
            width='100%'
            display='flex'
            alignItems={'center'}
            justifyContent='center'
            flexDirection={'column'}
          >
            <div>
              <HelpIcon />
            </div>
            NO USER SELECTION
          </Box>
        )}
      </Grid>
      <Grid item xs={8} className={classes.apartmentResume}>
        {answersLoading ? (
          <LoadingAnimation msg={'Caricamento Riepilogo Scelte...'} />
        ) : (
          <SummaryContainer
            onClickResume={handleResumeConfiguration}
            onClickSend={handleSendConfiguration}
            answers={answers}
            isAllPhasesCompleted={isAllPhasesCompleted}
            editable={editable}
            notAllowedFinish={false}
            isConfirmed={isConfirmed}
            resumePdfLoading={resumePdfLoading}
            downloadConfirmed={() => downloadConfirmed()}
            signing={signing}
            getSigningRequest={getSigningRequest}
          />
        )}
      </Grid>
    </Grid>
  );
};

const isSignedConfiguration = async (store, setIsConfigurationSigned) => {
  const nameNoExt = `${store.currentProject?.shortName} - ${store.currentApartment?.codiceAppartamento} - ${store.userData.firstName} ${store.userData.lastName} - ${store.currentConfiguration?.name}(${store.currentConfiguration?.id})`;
  // const nameNoExt = `${store.currentProject?.shortName} - ${store.currentApartment?.apartment?.value} - ${store.userData.firstName} ${store.userData.lastName} - ${store.currentConfiguration?.name}`;
  const signRequestList = await listSignRequest();
  const userSignRequest = signRequestList?.data?.entries?.find((signReq) =>
    signReq.name?.includes(`${nameNoExt}`),
  );
  setIsConfigurationSigned(
    userSignRequest?.status === 'signed' || userSignRequest?.status === 'finalizing',
  );
  store.setIsOneConfigurationConfirmed(
    userSignRequest?.status === 'signed' || userSignRequest?.status === 'finalizing',
  );
  return userSignRequest?.status === 'signed' || userSignRequest?.status === 'finalizing';
};

const isConfirmedConfiguration = async (store) => {
  const nameNoExt = `${store.currentProject?.shortName} - ${store.currentApartment?.codiceAppartamento} - ${store.userData.firstName} ${store.userData.lastName} - ${store.currentConfiguration?.name}(${store.currentConfiguration?.id})`;
  // const nameNoExt = `${store.currentProject?.shortName} - ${store.currentApartment?.apartment?.value} - ${store.userData.firstName} ${store.userData.lastName} - ${store.currentConfiguration?.name}`;
  const getMainListFolder = await listFolder(process.env.REACT_APP_BOX_INTEGRATION_FOLDERID);
  const mainFolderList = getMainListFolder?.data?.item_collection?.entries;
  const folderName = `${store.userData?.firstName}_${store.userData?.lastName}`;
  const folder = folderExist(mainFolderList, folderName);
  const folderId = folder?.id;
  let fileYetExist = false;
  if (folderId) {
    const getUserListFolder = await listFolder(folderId);
    const userFolderList = getUserListFolder?.data?.item_collection?.entries;
    fileYetExist =
      userFolderList.length > 0 &&
      userFolderList.some((fileInFolder) => fileInFolder.name === `${nameNoExt}.pdf`);
  }
  store.setIsOneConfigurationConfirmed(fileYetExist);
  return fileYetExist;
};

const downloadConfirmed = async (store, setDownloadingConfirmed) => {
  setDownloadingConfirmed(true);
  const nameNoExt = `${store.currentProject?.shortName} - ${store.currentApartment?.codiceAppartamento} - ${store.userData.firstName} ${store.userData.lastName} - ${store.currentConfiguration?.name}(${store.currentConfiguration?.id})`;
  // const nameNoExt = `${store.currentProject?.shortName} - ${store.currentApartment?.apartment?.value} - ${store.userData.firstName} ${store.userData.lastName} - ${store.currentConfiguration?.name}`;
  const getMainListFolder = await listFolder(process.env.REACT_APP_BOX_INTEGRATION_FOLDERID);
  const mainFolderList = getMainListFolder?.data?.item_collection?.entries;
  const folderName = `${store.userData?.firstName}_${store.userData?.lastName}`;
  const folder = folderExist(mainFolderList, folderName);
  const folderId = folder?.id;
  if (folderId) {
    const getUserListFolder = await listFolder(folderId);
    const userFolderList = getUserListFolder?.data?.item_collection?.entries;
    const postFiles = userFolderList.filter(
      (fileInFolder) => fileInFolder.name === `${nameNoExt}.pdf`,
    );
    const postFile =
      postFiles.length > 0
        ? postFiles.reduce(
            (current, largest) => (current.sequence_id > largest.sequence_id ? current : largest),
            postFiles[0].sequence_id,
          )
        : null;
    const fileId = postFile?.id;
    const link = document.createElement('a');
    const BoxJWTToken = getHcUserData().boxJwtToken;
    link.href = `https://api.box.com/2.0/files/${fileId}/content?access_token=${BoxJWTToken}`;
    link.click();
  }
  setDownloadingConfirmed(false);
};

const getSigningRequest = async (
  store, 
  setIsConfigurationSigned, 
  setSigningRequest, 
  configuration,
  apartment
) => {
  setSigningRequest(true)
  const nameNoExt = `${store.currentProject?.shortName} - ${store.currentApartment?.codiceAppartamento} - ${store.userData.firstName} ${store.userData.lastName} - ${store.currentConfiguration?.name}(${store.currentConfiguration?.id})`;
  // const nameNoExt = `${store.currentProject?.shortName} - ${store.currentApartment?.apartment?.value} - ${store.userData.firstName} ${store.userData.lastName} - ${store.currentConfiguration?.name}`;
  const signRequestList = await listSignRequest();
  const userSignRequest = signRequestList?.data?.entries?.find((signReq) =>
    signReq.name?.includes(`${nameNoExt}`),
  );
  const isSignedConf = await isSignedConfiguration(store, setIsConfigurationSigned);
  setIsConfigurationSigned(isSignedConf);
  const getMainListFolder = await listFolder(process.env.REACT_APP_BOX_INTEGRATION_FOLDERID);
  const mainFolderList = getMainListFolder?.data?.item_collection?.entries;
  let signFolderId = 0;
  let folderId = 0;
  const folderName = `${store.userData?.firstName}_${store.userData?.lastName}`;
  const folder = folderExist(mainFolderList, folderName);
  if (!folder) {
    const createNewUserFolder = await createFolder(
      `${store.userData?.firstName}_${store.userData?.lastName}`,
      process.env.REACT_APP_BOX_INTEGRATION_FOLDERID,
    );
    folderId = createNewUserFolder?.data?.id;
    const createNewSignFilte = await createFolder(
      'Sign',
      createNewUserFolder?.data?.id
    );
    const newFolder = createNewSignFilte?.data;
    signFolderId = newFolder?.id;
  } else {
    folderId = folder?.id;
    const getUserListFolder = await listFolder(folder?.id);
    const userFolderList = getUserListFolder?.data?.item_collection?.entries;
    const userFolder = folderExist(userFolderList, 'Sign');
    if(!userFolder){
      const createNewSignFilte = await createFolder(
        'Sign',
        folder?.id
      );
      const newFolder = createNewSignFilte?.data;
      signFolderId = newFolder?.id;
    } else {
      signFolderId = userFolder?.id;
    }
  }
  if (folderId) {
    const getUserListFolder = await listFolder(folderId);
    const userFolderList = getUserListFolder?.data?.item_collection?.entries;
    const postFiles = userFolderList.filter(
      (fileInFolder) => fileInFolder.name === `${nameNoExt}.pdf`,
    );
    const postFile =
      postFiles.length > 0
        ? postFiles.reduce(
            (current, largest) => (current.sequence_id > largest.sequence_id ? current : largest),
            postFiles[0].sequence_id,
          )
        : null;
    const fileId = postFile?.id;
    if (!userSignRequest) {
      const embed_url_external_user_id = `${store.userData?.firstName}_${store.userData?.lastName}`;
      const redirectUrl = `${window.location.origin}/${store.currentProject?.shortName}?confirmed=${configuration}&apartment=${apartment}`;
      const signRequest = await createSignRequest(embed_url_external_user_id, fileId, signFolderId, redirectUrl, nameNoExt);
      const embedUrl = signRequest.data?.signers?.[1]?.embed_url;
      const link = document.createElement('a');
      link.href = embedUrl;
      link.click();
    } else {
      if (userSignRequest.status !== 'signed' && userSignRequest.status !== 'finalizing') {
        const embedUrl = userSignRequest?.signers?.[1]?.embed_url;
        const link = document.createElement('a');
        link.href = embedUrl;
        link.click();
      }
    }
  }
  setSigningRequest(false);
};

const downloadSigned = async (store) => {
  const nameNoExt = `${store.currentProject?.shortName} - ${store.currentApartment?.codiceAppartamento} - ${store.userData.firstName} ${store.userData.lastName} - ${store.currentConfiguration?.name}(${store.currentConfiguration?.id})`;
  // const nameNoExt = `${store.currentProject?.shortName} - ${store.currentApartment?.apartment?.value} - ${store.userData.firstName} ${store.userData.lastName} - ${store.currentConfiguration?.name}`;
  const signRequestList = await listSignRequest();
  const userSignRequest = signRequestList?.data?.entries?.find((signReq) =>
    signReq.name?.includes(`${nameNoExt}`),
  );
  const signedFile = userSignRequest.sign_files?.files.find((signedFile) =>
    signedFile.name?.includes(nameNoExt),
  );
  const link = document.createElement('a');
  const BoxJWTToken = getHcUserData().boxJwtToken;
  link.href = `https://api.box.com/2.0/files/${signedFile?.id}/content?access_token=${BoxJWTToken}`;
  link.click();
};


const ConfigurationResumePage = observer(() => {
  let { apartment, configuration } = useParams();
  const history = useHistory();

  const store = useStore();
  const logger = useWinstonLogger();
  const params = useParams();

  const handlePrint = useReactToPrint({
    content: () => document.getElementById('configuration-resume'),
  });

  const [answers, setAnswers] = useState([]);
  const [layout, setLayout] = useState({});
  const [layoutLoading, setLayoutLoading] = useState(true);
  const [answersLoading, setAnswersLoading] = useState(true);
  const [resumePdfLoading, setResumePdfLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [imagesPose, setImagesPose] = useState([]);
  const [imagesQuote, setImagesQuote] = useState([]);
  const [isAllPhaseCompleted, setIsAllPhaseCompleted] = useState();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [showAreYouSureDialog, setShowAreYouSureDialog] = useState(false);
  const [configurationResumePdfData, setConfigurationResumePdfData] = useState({});
  const [isConfigurationConfirmed, setIsConfigurationConfirmed] = useState(
    store.isOneConfigurationConfirmed,
  );
  const [isConfigurationSigned, setIsConfigurationSigned] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [downloadingConfirmed, setDownloadingConfirmed] = useState(false);
  const [signingRequest, setSigningRequest] = useState(false);

  const elementToPrint = useRef();
  const configurationNameRef = useRef();
  const preparedAnswersRef = useRef();

  const bagniRooms = store?.currentConfiguration?.layout?.rooms?.filter(
    (room) => room?.roomType?.name === 'BAGNO',
  );

  const [instance, updateInstance] = usePDF({
    document: <ConfigurationResumePDF {...configurationResumePdfData} bagniRooms={bagniRooms} />,
  });

  useEffect(() => {
    if (!store.currentApartment) {
      history.replace('/apartments');
    } else if (!store.currentConfiguration) {
      history.replace('/apartments');
    }
  }, []);

  useEffect(() => {
    setLayoutLoading(true);
    setAnswersLoading(true);
    prepareData();
  }, [configuration]);

  const handleDownloadConfigurationPdf = async () => {
    try {
      setResumePdfLoading(true);
      // Creazione Planimetria Quotata
      const quotedPlanimetrySrc = URL.createObjectURL(
        await getBlobByImages(imagesQuote?.map((el) => (el.fileUrl ? el.fileUrl : el))),
      );
      const posedPlanimetrySrc = URL.createObjectURL(
        await getBlobByImages(imagesPose.map((el) => (el.fileUrl ? el.fileUrl : el))),
      );

      let preparedAnswers = [];

      const answersKeysToIgnore = [
        'SceltaLayout',
        'Bagno_Bagno',
        'Temperatura_Temperatura',
        'MOBILELAVABO_MODELLO',
      ];

      const basePlanimetryLayers = images.filter((el) => el.fileUrl).map((el) => el.fileUrl);
      // Creazione Planimetria con impianto elettrico di capitolato

      const impiantoElettricoLayers = await getAllImpiantoElettricoLayers(
        store.currentConfiguration?.layout?.tags,
        params.configuration,
      );

      const electricPlanimetrySrc = URL.createObjectURL(
        await getBlobByImages([
          ...basePlanimetryLayers,
          ...impiantoElettricoLayers.map((el) => (Array.isArray(el) ? el[0] : el)),
        ]),
      );

      for (const answer of answers) {
        for (const row of answer.rows) {
          let planimetrySrc;
          if (row.key === 'CARTAPARATI_CARTAPARATI') {
            const rooms = store.currentConfiguration?.layout?.rooms;
            const currentRoom = rooms?.find((room) => room.id === row.roomId);
            row.roomNumber = 'P-' + currentRoom?.roomTypeProgNumber;
            const currentRoomTag = currentRoom?.tags?.filter((tag) => tag.value.includes('R_'));
            const roomTagCartaParati = currentRoomTag?.map((current) => {
              return current?.fileLabels?.[0]?.url?.replace(/\s/g, '%20');
            });
            planimetrySrc = URL.createObjectURL(
              await getBlobByImages([...basePlanimetryLayers, ...roomTagCartaParati]),
            );
          }
          if (row.fileLabels) {
            let srcLayers = [];
            let tagLayers;
            switch (row.key) {
              case 'Cucina_Cucina':
                srcLayers = row.fileLabels
                  ?.filter((el) => !el.isPlan && !el.isElettrico && !el.isAxo)
                  .map((el) => el.url);
                break;

              case 'Finestre_ZanzariereFinestre':
                const loggiaTag = store.currentConfiguration?.layout?.tags?.find(el => el.key === 'LOGGIA')?.fileLabels.map((el) => el.url);
                const finestreTag = store.currentConfiguration?.layout?.tags?.find(el => el.key === 'FINESTRE')?.fileLabels.map((el) => el.url);
                if(row.name.toLowerCase().includes('finestre')) {
                  tagLayers = finestreTag
                }
                if(row.name.toLowerCase().includes('loggia')) {
                  tagLayers = loggiaTag
                }
                break;

              default:
                srcLayers = row.fileLabels?.map((el) => el.url);
                tagLayers = row?.tags?.map((tag) => {
                  return tag?.fileLabels?.map((el) => el.url);
                });
                break;
            }
            planimetrySrc = URL.createObjectURL(
              await getBlobByImages(
                tagLayers
                  ? [...basePlanimetryLayers, ...srcLayers, ...tagLayers]
                  : [...basePlanimetryLayers, ...srcLayers],
              ),
            );
          }
          preparedAnswers.push({
            ...row,
            planimetrySrc,
          });
        }
      }

      preparedAnswersRef.current = preparedAnswers;
      const preparedAnswersOld = _.cloneDeep(preparedAnswers);
      preparedAnswers = preparedAnswers.filter(
        (el) =>
          (!answersKeysToIgnore.includes(el.key) && !el.isDefault) || el.key === 'Cucina_Cucina',
      );

      setConfigurationResumePdfData({
        quotedPlanimetrySrc: quotedPlanimetrySrc,
        posedPlanimetrySrc: posedPlanimetrySrc,
        electricPlanimetrySrc: electricPlanimetrySrc,
        answers: preparedAnswers,
        answersForPrices: preparedAnswersOld.filter((el) => !el.isDefault),
        apartment: store.currentApartment,
        configurationName: store.currentConfiguration?.name,
        building: store.currentProject?.longName,
      });
    } catch (error) {
      console.log(error);
      setResumePdfLoading(false);
    }
  };

  useEffect(() => {
    updateInstance();
  }, [configurationResumePdfData]);

  const handleDownloadConfigurationPdfLastPart = async () => {
    try {
      const pdfFiles = [instance.url];

      const chiusuraCucina = layout?.chiusure.find((el) => {
        return checkRoomType(layout?.rooms, el.roomId, 'cucina');
      });
      if (chiusuraCucina) {
        pdfFiles.push(chiusuraCucina?.dataSheetFileUrl);
      }

      let layoutBagno;
      answers.forEach((answer) => {
        const bagnoRow = answer.rows.find(
          (el) => el.key === 'Bagno_Bagno' && el.description.includes('Incasso'),
        );
        layoutBagno = bagnoRow || layoutBagno;
      });
      if (layoutBagno) {
        layoutBagno.products.forEach((product) => {
          if (product.dataSheetFileUrl) {
            pdfFiles.push(product.dataSheetFileUrl);
          }
        });
      }

      let mobileBagnoAnswer = [];
      answers.forEach((answer) => {
        const mobileBagnoRow = answer.rows.find((el) => el.key === 'MOBILELAVABO_MODELLO');
        if(mobileBagnoRow) mobileBagnoAnswer.push(mobileBagnoRow);
      });
      if (mobileBagnoAnswer.length > 0) {
        mobileBagnoAnswer.forEach((mobile) => {
          mobile.products.forEach((product) => {
            if (product.datasheetFileLabel) {
              pdfFiles.push(product.datasheetFileLabel);
            }
          });
          
        })
      }

      if (isConfirming) {
        const nameNoExt = `${store.currentProject?.shortName} - ${store.currentApartment?.codiceAppartamento} - ${store.userData.firstName} ${store.userData.lastName} - ${store.currentConfiguration?.name}(${store.currentConfiguration?.id})`;
        // const nameNoExt = `${store.currentProject?.shortName} - ${store.currentApartment?.apartment?.value} - ${store.userData.firstName} ${store.userData.lastName} - ${store.currentConfiguration?.name}`;
        const getMainListFolder = await listFolder(process.env.REACT_APP_BOX_INTEGRATION_FOLDERID);
        const mainFolderList = getMainListFolder?.data?.item_collection?.entries;
        // controllo sempre che la cartella esista o meno e in caso creo nuova
        let folderId = 0;
        const folderName = `${store.userData?.firstName}_${store.userData?.lastName}`;
        const folder = folderExist(mainFolderList, folderName);
        if (!folder) {
          const createNewFolder = await createFolder(
            `${store.userData?.firstName}_${store.userData?.lastName}`,
            process.env.REACT_APP_BOX_INTEGRATION_FOLDERID,
          );
          const newFolder = createNewFolder?.data;
          folderId = newFolder?.id;
        } else {
          folderId = folder?.id;
        }
        // una volta ottenuto l'identificativo della cartella devo controllare
        // se il file è già stato caricato, allora dovrò caricare una nuova versione
        // in caso controario faccio upload del file nella cartella
        if (folderId) {
          const getUserListFolder = await listFolder(folderId);
          const userFolderList = getUserListFolder?.data?.item_collection?.entries;
          const fileYetExist =
            userFolderList.length > 0 &&
            userFolderList.some((fileInFolder) => fileInFolder.name === `${nameNoExt}.pdf`);
          const postFiles = userFolderList.filter(
            (fileInFolder) => fileInFolder.name === `${nameNoExt}.pdf`,
          );
          const postFile =
            postFiles.length > 0
              ? postFiles.reduce(
                  (current, largest) =>
                    current.sequence_id > largest.sequence_id ? current : largest,
                  postFiles[0].sequence_id,
                )
              : null;
          const fileId = postFile?.id;
          if (fileYetExist) {
            await uploadFileVersion(
              await mergePDFs2(pdfFiles),
              `${nameNoExt}.pdf`,
              folderId,
              fileId,
            );
          } else {
            await uploadFile(await mergePDFs2(pdfFiles), `${nameNoExt}.pdf`, folderId);
          }
        }
        setIsConfigurationConfirmed(true);
        store.setIsOneConfigurationConfirmed(true);
        // await confirmConfigurationUploaded(store);
        setIsConfirming(false);
        await getSigningRequest(store, setIsConfigurationSigned, setSigningRequest, configuration, apartment);
        setShowConfirmationDialog(false);
      } else {
        saveAs(
          await mergePDFs(pdfFiles),
          `${store.currentProject?.shortName} - ${store.currentApartment?.codiceAppartamento} - ${store.userData.firstName} ${store.userData.lastName} - ${store.currentConfiguration?.name}(${store.currentConfiguration?.id})`,
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setResumePdfLoading(false);
    }
  };

  useEffect(() => {
    if (!instance.loading && resumePdfLoading) {
      handleDownloadConfigurationPdfLastPart();
    }
    if (instance.error && resumePdfLoading) {
      console.log(instance.error);
      setResumePdfLoading(false);
    }
  }, [instance]);

  const prepareData = async () => {
    await isSignedConfiguration(store, setIsConfigurationSigned);
    const isConf = await isConfirmedConfiguration(store)
    setIsConfigurationConfirmed(isConf);
    const layoutData = await prepareLayoutData();
    await prepareAnswersData(layoutData);

    const answersResponse = await getAnswer(params.configuration);
    const answersData = answersResponse.data;
    store.setAnswers(answersData);

    const phases = await getConfiguratorPhases(params.configuration, store);

    store.setPhases(phases);
    setIsAllPhaseCompleted(store.isAllPhasesCompleted());
  };

  const prepareAnswersData = async (layoutData) => {
    try {
      // Recupero le fasi per controllare se è presente quella per il secondo bagno
      // quest'informazione mi tornerà utile per sapere se mostrare o meno
      // l'opzione del secondo bagno dalla lista delle risposte
      const phasesResponse = await getPhases(configuration);
      let phasesData = phasesResponse.data;
      let orderedPhases = [];
      const layoutIndex = phasesData.findIndex((el) => el.key === 'Layout');
      if (layoutIndex !== -1) orderedPhases = [...orderedPhases, phasesData[layoutIndex]];
      const tecnologiaIndex = phasesData.findIndex((el) => el.key === 'Tecnologia');
      if (tecnologiaIndex !== -1) orderedPhases = [...orderedPhases, phasesData[tecnologiaIndex]];
      const giornoenotteIndex = phasesData.findIndex((el) => el.key === 'GIORNOENOTTE');
      if (giornoenotteIndex !== -1) phasesData[giornoenotteIndex].order = 30;
      if (giornoenotteIndex !== -1)
        orderedPhases = [...orderedPhases, phasesData[giornoenotteIndex]];
      const bagnoIndex = phasesData.findIndex((el) => el.key === 'BAGNO');
      if (bagnoIndex !== -1) orderedPhases = [...orderedPhases, phasesData[bagnoIndex]];
      phasesData = orderedPhases;

      const hasSecondoBagnoSubstep = phasesData.some((phase) =>
        phase.steps.some(
          (step) =>
            step.key === 'Bagno' && step.substeps.some((substep) => substep.key === 'Bagno'),
        ),
      );

      const answerResponse = await getAnswerDetail(configuration);
      const answerResponsePackage = await getAnswerPackageDetail(configuration);
      const answerDataPackage = answerResponsePackage.data;
      const answerData = answerResponse.data;

      const answersTemp = {};
      if (answerData) {
        answerData.forEach((el) => {
          el.optionGroupPrefixes?.forEach((optionGroupPrefix) => {
            if (optionGroupPrefix?.id !== 0) {
              const isMultipleCoiche = optionGroupPrefix.isMultipleChoice;

              optionGroupPrefix.optionGroups.forEach((optionGroup) => {
                const isDefault = optionGroup.isDefault;

                optionGroup.options?.forEach((option) => {
                  const newKey = `${option.step.key}_${option.substep.key}`;
                  let positions = [];

                  if (option.numbers?.length > 0) {
                    option.numbers.forEach((number) => {
                      positions.push(number.number);
                    });
                  } else if (option.position) {
                    positions.push(option.position);
                  }
                  let optionData = {}

                  optionData = {
                    key: newKey,
                    isDefault: isDefault,
                    isMultipleCoiche: isMultipleCoiche,
                    ...option,
                    positions,
                  };
                  if (answersTemp[optionData.phase.id]) {
                    answersTemp[optionData.phase.id].options.push(_.cloneDeep(optionData));
                  } else {
                    const answer = {
                      phase: optionData.phase,
                      options: [optionData],
                    };
                    answersTemp[answer.phase.id] = answer;
                  }
                });
              });
            }
          });
        });
      }

      if (answerDataPackage?.length > 0) {
        answerDataPackage.forEach((el) => {
          const phaseFromPackage = phasesData?.find((phase) => phase?.id === el.phaseId);
          const stepFromPackage = phaseFromPackage?.steps?.find((step) => step?.id === el.stepId);
          const subStepFromPackage = stepFromPackage?.substeps?.find(
            (subStep) => subStep?.id === el.substepId,
          );
          const packageData = {
            isMultipleChoice: false,
            isDefault: false,
            key: `${stepFromPackage?.key}_${subStepFromPackage?.key}`,
            name: el?.packages?.[0]?.title,
            description: el?.packages?.[0]?.title,
            price: el?.packages?.[0]?.priceDiff,
            roomId: el.roomId,
            products: el?.packages?.[0]?.products,
            phase: {
              id: phaseFromPackage?.id,
              key: phaseFromPackage?.key,
              name: phaseFromPackage?.name,
              order: phaseFromPackage?.order,
              steps: phaseFromPackage?.steps,
            },
            step: {
              id: stepFromPackage?.id,
              key: stepFromPackage?.key,
              name: stepFromPackage?.name,
              order: stepFromPackage?.order,
            },
            substep: {
              id: subStepFromPackage?.id,
              key: subStepFromPackage?.key,
              name: subStepFromPackage?.name,
              order: subStepFromPackage?.order,
            },
          };
          if (el.packages?.[0]?.code === 'MOODN_CUC_FPAV') {
            packageData.isCucinaSame = true;
          }
          if (answersTemp[packageData?.phase?.id]) {
            answersTemp[packageData.phase.id].options.push(packageData);
          } else {
            const answer = {
              phase: packageData?.phase,
              options: [packageData],
            };
            answersTemp[answer?.phase?.id] = answer;
          }
        });
      }

      let layoutStepAnswer = null;
      if (layoutData) {
        const description = `Layout ${layoutData.layoutCode}${
          layoutData.layoutVariantCode ? `${layoutData.layoutVariantCode}` : ''
        }`;

        const price = layoutData?.price;

        layoutStepAnswer = {
          description,
          isDefault: layoutData.isDefaultLayout,
          price,
          key: 'SceltaLayout',
          substep: { id: 0, name: 'Distribuzione Interna' },
          step: { id: 0, name: 'Distribuzione Interna' },
          phase: { name: 'LAYOUT', id: 1, key: 'Layout', order: 0 },
        };
      }

      let answers = [];
      for (const key in answersTemp) {
        answers.push({
          title: answersTemp[key].phase.key,
          rows: [...answersTemp[key].options],
        });
      }

      const layoutAnswerIndex = answers.findIndex((answer) => answer.title === 'Layout');
      const layoutAnswer = answers[layoutAnswerIndex];

      if (layoutAnswer) {
        let layoutAnswersRows = layoutAnswer.rows;

        if (layoutStepAnswer) {
          layoutAnswersRows = [layoutStepAnswer, ...layoutAnswer.rows];
        }

        // Se non ho uno step per il bagno, filtro la sua risposta
        if (!hasSecondoBagnoSubstep) {
          layoutAnswersRows = layoutAnswersRows.filter((el) => el.key !== 'Bagno_Bagno');
        }

        layoutAnswersRows = groupRowsByKey(layoutAnswersRows, 'Porte_TipologiaPorte');
        layoutAnswersRows = groupRowsByKey(
          layoutAnswersRows,
          'Porte_StilePorte',
          false,
          'Rasomuro',
          true,
          false
        );
        layoutAnswersRows = groupRowsByKey(
          layoutAnswersRows,
          'Porte_StilePorte',
          false,
          'Rasomuro',
          false,
          true
        );
        layoutAnswersRows = groupRowsByKey(
          layoutAnswersRows,
          'Porte_AltezzaPorte',
          false,
          'Extra altezza',
          true,
          false,
        );
        layoutAnswersRows = groupRowsByKey(
          layoutAnswersRows,
          'Porte_AltezzaPorte',
          false,
          'Extra altezza',
          false,
          true,
        );
        layoutAnswersRows = groupRowsByKey(
          layoutAnswersRows,
          'Porte_PorteGrezze',
          false,
          'Porta Grezza',
          false,
          true,
        );
        layoutAnswersRows = groupRowsByKey(
          layoutAnswersRows,
          'Porte_PorteGrezze',
          false,
          'Porta Grezza',
          true,
          false,
        );
        layoutAnswersRows = groupRowsByKey(layoutAnswersRows, 'Cassaforte_Cassaforte');
        layoutAnswersRows = groupRowsByKey(layoutAnswersRows, 'Finestre_OpacitaFinestre', true);
        layoutAnswersRows = groupRowsByKey(layoutAnswersRows, 'Finestre_ZanzariereFinestre', true);
        layoutAnswersRows = groupRowsByKey(layoutAnswersRows, 'Finestre_DimensioneFinestre', true);
        layoutAnswersRows = groupRowsByKey(layoutAnswersRows, 'Loggia_LavaboEsterno');
        answers[layoutAnswerIndex].rows = layoutAnswersRows;
      } else if (layoutStepAnswer) {
        answers.push({
          title: 'Layout',
          rows: [layoutStepAnswer],
        });
      }

      const tecnologiaAnswerIndex = answers.findIndex((answer) => answer.title === 'Tecnologia');

      const tecnologiaAnswer = answers[tecnologiaAnswerIndex];

      if (tecnologiaAnswer) {
        let tecnologiaAnswersRows = tecnologiaAnswer.rows;

        tecnologiaAnswersRows = groupRowsByKey(
          tecnologiaAnswersRows,
          'Luci_LuciComodinoAltreCamere',
        );
        tecnologiaAnswersRows = groupRowsByKey(tecnologiaAnswersRows, 'Luci_GoleLuminose');
        tecnologiaAnswersRows = groupRowsByKey(tecnologiaAnswersRows, 'Luci_PuntiLuceExtra');
        tecnologiaAnswersRows = groupRowsByKey(tecnologiaAnswersRows, 'Luci_IlluminazioneArmadi');
        tecnologiaAnswersRows = groupRowsByKey(tecnologiaAnswersRows, 'Prese_PreseUsb');
        tecnologiaAnswersRows = groupRowsByKey(tecnologiaAnswersRows, 'Prese_PresePianoCottura');
        tecnologiaAnswersRows = groupRowsByKey(tecnologiaAnswersRows, 'Prese_PreseLoggia');
        tecnologiaAnswersRows = groupRowsByKey(tecnologiaAnswersRows, 'Prese_PreseTv');
        tecnologiaAnswersRows = groupRowsByKey(tecnologiaAnswersRows, 'Prese_PreseLavanderia');
        tecnologiaAnswersRows = groupRowsByKey(tecnologiaAnswersRows, 'Luci_PreseComandate');
        tecnologiaAnswersRows = groupRowsByKey(
          tecnologiaAnswersRows,
          'Temperatura_Temperatura',
          true,
        );
        answers[tecnologiaAnswerIndex].rows = tecnologiaAnswersRows;
      }

      const configurationResponse = await getConfigurationDetail(params.configuration);
      const configurationData = configurationResponse.data;
      const bagniRooms = configurationData?.layout?.rooms?.filter(
        (room) => room?.roomType?.name === 'BAGNO',
      );
      const bagniRoomsId = bagniRooms.map((room) => room.id);
      const bagnoAnswerIndex = answers?.findIndex((answer) => answer.title === 'BAGNO');

      const compareFunction = (a, b) => {
        if (a.substep.order > b.substep.order) return 1;
        if (a.substep.order < b.substep.order) return -1;
        else return 0;
      };

      if (bagnoAnswerIndex !== -1) {
        const bagnoAnswer = answers[bagnoAnswerIndex];
        const bagniAnswersSplitted = [];
        bagniRoomsId?.forEach((element, index) => {
          const filteredBagnoAnswers = bagnoAnswer?.rows
            ?.filter((answer) => answer.roomId === element)
            .sort(compareFunction);
          bagniAnswersSplitted?.push({
            title: bagniRooms[index]?.name,
            rows: [...filteredBagnoAnswers],
          });
        });
        answers.splice(bagnoAnswerIndex, 1);
        bagniAnswersSplitted.forEach((answer) =>
          answers.push({
            title: answer.title,
            rows: [...answer.rows],
          }),
        );
      }

      setAnswers(answers);
      setAnswersLoading(false);
    } catch (e) {
      logger.error('prepareAnswersData', e);
    }
  };

  const prepareLayoutData = async () => {
    try {
      const configurationResponse = await getConfigurationDetail(configuration);
      store.setCurrentConfiguration(configurationResponse.data);
      const layoutData = configurationResponse.data.layout;
      setLayout(layoutData);
      configurationNameRef.current = configurationResponse.data.name;

      // Imposto i layer da far vedere
      const layoutImagesResponse = await getLayoutImagesByConfigurationId(params.configuration);
      const layoutImages = layoutImagesResponse.data;

      setImages([...layoutImages]);

      setImagesQuote([
        ...layoutImages,
        {
          fileUrl: layoutData?.tags.find((el) => el.key === 'QUOTE')?.fileLabels[0]?.url,
          order: 3,
        },
      ]);

      setImagesPose([
        ...layoutImages,
        { fileUrl: layoutData?.tags.find((el) => el.key === 'POSA')?.fileLabels[0]?.url, order: 3 },
      ]);

      setLayoutLoading(false);

      return layoutData;
    } catch (e) {
      logger.error('prepareLayoutData', e);
    }
  };

  const handleResumeConfiguration = () => {
    history.push(`/apartment/${apartment}/${configuration}/configurator`);
  };

  /*
  const handleSendConfiguration = async () => {
    try {
      await confirmConfiguration(configuration);
      store.setIsOneConfigurationConfirmed(true);
      history.push(`/apartment/${apartment}`);
    } catch (e) {
      console.log('handleSendConfiguration', e);
    } finally {
      setShowConfirmationDialog(false);
    }
  };
  */

  // TODO: verificare man mano che vanno avanti le api
  let answersWithDisabledPhases = [];

  answersWithDisabledPhases = [...answers];
  if (!answersWithDisabledPhases.some((el) => el.title.toLowerCase().includes('layout'))) {
    answersWithDisabledPhases.push({ title: 'LAYOUT', status: 'disabled' });
  }
  if (!answersWithDisabledPhases.some((el) => el.title.toLowerCase().includes('tecnologia'))) {
    answersWithDisabledPhases.push({ title: 'TECNOLOGIA', status: 'disabled' });
  }
  if (!answersWithDisabledPhases.some((el) => el.title.toLowerCase().includes('giornoenotte'))) {
    answersWithDisabledPhases.push({ title: 'GIORNOENOTTE', status: 'disabled' });
  }
  if (!answersWithDisabledPhases.some((el) => el.title.toLowerCase().includes('bagno'))) {
    answersWithDisabledPhases.push({ title: 'BAGNO', status: 'disabled' });
  }

  const handlePrintResume = () => {
    handlePrint();
  };

  return (
    <>
      <TwoColumnsLayout
        firstColumn={
          <InfoApartment
            firstTitleRow={'La tua Configurazione'}
            secondTitleRow={store.currentConfiguration?.name}
            thirdTitleRow={store.currentApartment?.apartment.value}
            apartment={store.currentApartment}
            handleDownload={!layoutLoading && !answersLoading ? isConfigurationConfirmed ? () => downloadConfirmed(store, setDownloadingConfirmed) : handleDownloadConfigurationPdf : null}
            isDownloadLoading={resumePdfLoading || downloadingConfirmed || signingRequest}
            handlePrint={handlePrintResume}
            notAllowedFinish={process.env.REACT_APP_HIDE_DOWNLOAD === 'true'}
            hideConfigurations={isConfigurationConfirmed || isConfigurationSigned}
          />
        }
        secondColumn={
          <SecondColumn
            answers={answersWithDisabledPhases}
            answersLoading={answersLoading}
            layout={layout}
            layoutLoading={layoutLoading}
            apartment={store.currentApartment?.apartment.value}
            handleResumeConfiguration={handleResumeConfiguration}
            handleSendConfiguration={() => setShowConfirmationDialog(true)}
            superficieTotalePostVendita={store.currentApartment?.superficieTotalePostVendita}
            superficieTerrazzoPostVendita={store.currentApartment?.superficieTerrazzoPostVendita}
            images={imagesQuote}
            elementToPrintRef={elementToPrint}
            isAllPhasesCompleted={isAllPhaseCompleted}
            editable={!store.isOneConfigurationConfirmed && !isConfigurationConfirmed && !isConfigurationSigned}
            signing={isConfigurationConfirmed && !isConfigurationSigned}
            isConfirmed={isConfigurationConfirmed}
            resumePdfLoading={resumePdfLoading || downloadingConfirmed || signingRequest}
            downloadConfirmed={() => downloadSigned(store)}
            getSigningRequest={() => getSigningRequest(store, setIsConfigurationSigned, setSigningRequest, configuration, apartment)}
          />
        }
      />
      {isAllPhaseCompleted && (
        <>
          <AreYouSureConfirmationDialog
            isOpen={showAreYouSureDialog}
            isLoading={resumePdfLoading}
            configurationName={store.currentConfiguration?.name}
            handleClose={() => setShowAreYouSureDialog(false)}
            handleDownload={!layoutLoading && !answersLoading && handleDownloadConfigurationPdf}
            handleConfirm={() => {
              setShowAreYouSureDialog(false);
              setShowConfirmationDialog(true);
            }}
          />
          <ConfigurationConfirmationDialog
            configurationName={store.currentConfiguration?.name}
            apartmentName={store.currentApartment?.apartment.value}
            isOpen={showConfirmationDialog}
            isLoading={resumePdfLoading}
            handleClose={() => resumePdfLoading ? null : setShowConfirmationDialog(false)}
            handleConfirm={handleDownloadConfigurationPdf}
            handleDownloadConfirmed={() => downloadConfirmed(store)}
            setIsConfirming={setIsConfirming}
          />
        </>
      )}
    </>
  );
});

export default ConfigurationResumePage;
