import { Grid, makeStyles } from '@material-ui/core';
import ConfigurationLayout from '../../../../components/Layout/ConfigurationLayout';
import FurnitureCounter from '../../../../components/Layout/FurnitureCounter';

const useStyles = makeStyles((theme) => ({
  text: {
    fontFamily: 'miller',
    fontStyle: 'italic',
    fontSize: '16px',
    lineHeight: '18px',
    textAlign: 'center',
    padding: '0 16px',
    marginBottom: '8px',
  },
}));

const DefaultChoiceRoomBalloons = ({
  text,
  onClick,
  isSelected,
  rooms,
  planimetryImages,
  planimetryButtonLabel = "Guarda la planimetria dell'impianto elettrico",
  legendImg,
}) => {
  const classes = useStyles();

  return (
    <ConfigurationLayout
      title='la '
      boldTitle='configurazione di partenza'
      buttonText='CONFERMA QUESTA SCELTA'
      onClick={onClick}
      isSelected={isSelected}
      planimetryImages={planimetryImages}
      planimetryButtonLabel={planimetryButtonLabel}
      legendImg={legendImg}
      noBottomBorder
    >
      <div className={classes.text}>{text}</div>
      <Grid container spacing={2} justifyContent='center'>
        {rooms.map((room, i) => (
          <Grid item xs={3} lg={2} key={i}>
            <FurnitureCounter
              icon={room.icon}
              counter={room.number}
              text={room.name}
              roundedBorderedIcon
            />
          </Grid>
        ))}
      </Grid>
    </ConfigurationLayout>
  );
};

export default DefaultChoiceRoomBalloons;
