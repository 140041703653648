import * as React from 'react';

function SvgPreseAntibagno(props) {
  return (
    <svg viewBox='0 0 69 54' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#prese-antibagno_svg__clip0_2758_23737)' fill='#C4C4C4'>
        <path d='M30.441 2h5.06v15.65h2V1c0-.55-.45-1-1-1h-6.06c-.55 0-1 .45-1 1s.45 1 1 1zM19.121 19.81c-.36 0-.69.19-.87.5-.15.26-.29.49-.43.71l-.06.09c-.15.24-.3.49-.43.73-.26.5-.46 1.04-.46 1.73v.02c0 .59.25 1.15.66 1.56a2.28 2.28 0 003.18.03c.43-.42.68-.99.7-1.59v-.02c0-.7-.23-1.25-.51-1.75-.12-.23-.27-.46-.41-.68l-.02-.04c-.16-.24-.32-.5-.49-.79-.17-.31-.5-.5-.86-.5zm.2 3.93c-.05.04-.12.07-.19.07-.06 0-.13-.03-.18-.08a.241.241 0 01-.07-.17c0-.25.06-.46.23-.78l.02-.05s.03.04.04.06c.18.33.24.54.25.77-.01.06-.04.13-.09.18h-.01z' />
        <path d='M24.74 4.8L8.2.04A.903.903 0 007.92 0H1C.45 0 0 .45 0 1v35.95c0 .55.45 1 1 1h6.78l14.67 4.21v-2.08L8.2 35.99a.903.903 0 00-.28-.04H2V2h5.78l16.4 4.72c.53.15 1.09-.15 1.24-.68.15-.53-.15-1.09-.68-1.24z' />
        <path d='M15.242 14.52c0-.37.15-.72.4-.98.26-.25.61-.4.98-.4s.71.15.97.41.41.61.41.97v1.45c0 .55.45 1 1 1s1-.45 1-1v-1.45c0-.9-.36-1.75-.99-2.39a3.4 3.4 0 00-2.39-.99 3.383 3.383 0 00-3.38 3.38v6.55h-1.87c-.21-.42-.55-.76-.97-.97v-1.17c0-.55-.45-1-1-1s-1 .45-1 1v1.17c-.42.21-.76.55-.97.97h-1.08c-.55 0-1 .45-1 1s.45 1 1 1h1.08c.21.42.55.76.97.97v1.18c0 .55.45 1 1 1s1-.45 1-1v-1.18c.42-.21.76-.55.97-.97h1.87v5.68h-4.1c-.55 0-1 .45-1 1s.45 1 1 1h4.96s.09.01.14.01c.05 0 .1 0 .14-.01h8.07v-2h-7.21V14.52zM46.222 33.05h-2.46v6.18h2.46v-6.18zM36.59 33.05h-.23v6.16h2.46v-6.16h-2.23z' />
        <path d='M53.62 26.55c0-.72-.29-1.42-.8-1.93s-1.21-.8-1.93-.81H31.7c-.73.01-1.42.3-1.94.81-.51.51-.8 1.21-.8 1.93v19.2c0 .72.29 1.41.8 1.93.33.33.74.57 1.18.69.25.07.5.11.76.11h19.19c.72 0 1.42-.29 1.93-.81.51-.51.8-1.2.8-1.92v-19.2zm-4.13 15.08a9.841 9.841 0 01-4.43 3.63 9.87 9.87 0 01-10.75-2.14c-.11-.11-.22-.22-.32-.34-.4-.43-.75-.9-1.06-1.4a9.62 9.62 0 01-1.32-3.31c-.38-1.91-.18-3.9.56-5.7.16-.37.33-.73.54-1.08.35-.63.77-1.22 1.26-1.75.54-.6 1.16-1.14 1.84-1.6a9.89 9.89 0 019.26-.91c1.19.5 2.28 1.22 3.2 2.14.91.92 1.64 2 2.13 3.2.5 1.2.76 2.48.75 3.78 0 1.95-.57 3.85-1.66 5.48zM65.24 34.84h1.11c.14 0 .25-.11.25-.25v-4.74c0-.11 0-.22-.05-.33a.75.75 0 00-.18-.28.75.75 0 00-.47-.22.169.169 0 00-.1-.03c-.11-.01-.22 0-.33.05-.1.04-.2.1-.28.18a.75.75 0 00-.18.28c-.04.1-.05.22-.05.3v4.79c0 .14.11.25.25.25h.03zM58.952 34.81h1.05c.14 0 .25-.11.25-.25v-4.74c.01-.11 0-.22-.04-.32s-.1-.2-.18-.28a.898.898 0 00-.28-.18c-.1-.03-.2-.04-.31-.04-.1 0-.18.02-.27.06-.1.04-.19.1-.26.18a.77.77 0 00-.17.27c-.03.1-.05.21-.04.3v4.75c0 .14.11.25.25.25zM68.652 36.86a.834.834 0 00-.17-.63.826.826 0 00-.6-.33h-10.58a.842.842 0 00-.6.31c-.07.09-.12.2-.16.31-.03.11-.04.22-.03.31v6.01c-.01.11 0 .23.03.34.04.11.09.22.16.31.08.09.17.16.27.21.11.06.22.09.35.1h10.59c.11-.02.22-.05.32-.11.1-.05.18-.13.25-.22s.12-.19.15-.3c.03-.11.04-.22.03-.3v-6.01h-.01zM64.191 44.98h-3.17c-.14 0-.25.11-.25.25v1.06c0 .14.11.25.25.25h.81v3.3a2.5 2.5 0 01-.74 1.67l-.03.03c-.44.43-1.02.68-1.62.71h-5.82c-.14 0-.25.11-.25.25v1.06c0 .14.11.25.25.25h5.82c.6 0 1.15-.12 1.62-.32 1.47-.63 2.33-2.03 2.33-3.11v-3.84h.8c.14 0 .25-.11.25-.25v-1.06c0-.14-.11-.25-.25-.25z' />
      </g>
      <defs>
        <clipPath id='prese-antibagno_svg__clip0_2758_23737'>
          <path fill='#fff' d='M0 0h68.67v53.81H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgPreseAntibagno;
